import { map } from 'lodash';
import {
  createTableFromSearchJSON,
  rearrangeArray,
  getSelectedRecords,
  loadSubmissionsAction,
  ONLY_ACTIVE_SUBMISSIONS,
} from 'utils/submissionsManager/tableHelper';
import {getMAID, getUID} from 'utils/auth';
import { getEmptyFilter, getSubmissionFiltersForServer } from 'utils/submissionsManager/submissionFilters';
import * as types from 'constants/types/submissionsTableActionTypes';
import api from 'actions/api';
import { SEARCH_DEBOUNCE_RATE_MS, UNEXPORTABLE_COLUMNS } from 'constants/tableConsts';
import { assignStages, getStagesOfForm, unassignStages } from 'actions/stages';
import { assignTagsToMultipleSubmissions, unassignTagsToMultipleSubmissions } from 'actions/tags';
import { assignUsersToMultipleSubmissions, unassignUsersToMultipleSubmissions } from 'actions/assignments';
import { Action } from 'types/api';
// import type { FormId } from 'types/forms';
// import type { SelectedRowDataType } from 'types/submissionsManager/tableTypes';
// import type {
//   NewTableActionCreator,
//   UpdateTableActionCreator,
//   InitializeActionCreator,
// } from 'types/submissionsManager/actionTypes';
// import type {
//   Body,
//   CheckTableRow,
//   PageArgs,
//   ResetSelectedRows,
//   ResetTableSnackbar,
//   SelectAll,
//   UseCachedTable,
//   ChangeSidebarStatus,
//   RemoveSubmissionAction,
//   RequestInitialSubmissionLoadAction,
//   RequestSubsequentSubmissionLoadAction,
// } from 'types/submissionsManager/tableApi';
// import type { MakeSortable } from 'types/submissionsManager/shared';
// import type { ExportPayload } from 'types/shared';
import {
  ResetFiltersStateAction,
  ResetFiltersStateActionCreator,
  OpenFiltersModalAction,
  OpenFiltersModalActionCreator,
  CloseFiltersModalAction,
  CloseFiltersModalActionCreator,
  AddFilterAction,
  AddFilterActionCreator,
  RemoveFilterAction,
  RemoveFilterActionCreator,
  SelectFilterColumnAction,
  SelectFilterColumnActionCreator,
  SelectFilterOperatorAction,
  SelectFilterOperatorActionCreator,
  ChangeFilterExpectationAction,
  ChangeFilterExpectationActionCreator,
  ApplyFiltersAction,
  ApplyFiltersActionCreator,
  ApplyStageFilterAction,
  ApplyStageFilterActionCreator,
  RemoveStageFiltersAction,
  RemoveStageFiltersActionCreator,
  ResetFiltersAction,
  ResetFiltersActionCreator,
  ResetSortingAction,
  ResetSortingActionCreator,
  Operator,
  Expectation,
  SubmissionFilter,
  SubmissionFilterForServer,
} from 'types/submissionsManager/submissionFilters';
import {
  SortingForServer,
  SortSubmissionsAction,
  SortSubmissionsActionCreator,
  UnsortSubmissionsAction,
  UnsortSubmissionsActionCreator,
} from 'types/submissionsManager/submissionSorting';

export const initialize = (formId, selectedFilter) => ({
  formId,
  selectedFilter,
  type: types.INITIALIZE,
});

export function requestInitialSubmissionLoad(formId, isArchiveView = false) {
  return {
    formId,
    isArchiveView,
    type: types.REQUEST_INITIAL_SUBMISSION_LOAD,
  };
}

export function requestSubsequentSubmissionLoad(formId, isArchiveView = false) {
  return {
    formId,
    isArchiveView,
    type: types.REQUEST_SUBSEQUENT_SUBMISSION_LOAD,
  };
}

export const allSubmissionsFilter = (
  baseType: string,
  formId: string,
  paginationToken: string | null | undefined = null,
  offset: number = 0,
  archiveOptions: string | undefined = undefined,
  filtering?: SubmissionFilterForServer[],
  ordering?: SortingForServer,
): Action => loadSubmissionsAction(baseType, 'filteredSubmissions/all.json', formId, paginationToken, offset, archiveOptions, filtering, ordering);

export const allSubmissionsInitialLoad = (
  formId: string,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  allSubmissionsFilter(
    types.ALL_SUBMISSION_FILTER_INITIAL_LOAD,
    formId,
    undefined,
    undefined,
    undefined,
    submissionFilters,
    sorting,
  );

export const allSubmissionsSubsequentLoad = (
  formId: string,
  paginationToken: string | null | undefined,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  allSubmissionsFilter(
    types.ALL_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
    formId,
    paginationToken,
    offset,
    undefined,
    submissionFilters,
    sorting,
  );

export const archivedSubmissionsInitialLoad = (
  formId: string,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  allSubmissionsFilter(
    types.ARCHIVED_SUBMISSION_FILTER_INITIAL_LOAD,
    formId,
    null,
    0,
    'OnlyArchived',
    submissionFilters,
    sorting,
  );

export const archivedSubmissionsSubsequentLoad = (
  formId: string,
  paginationToken: string | null | undefined,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  allSubmissionsFilter(
    types.ARCHIVED_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
    formId,
    paginationToken,
    offset,
    'OnlyArchived',
    submissionFilters,
    sorting,
  );

function page({ baseType, endpoint, onSuccess, formId, offset, query, sample }:
  { baseType: string,
    endpoint: string,
    onSuccess: Function,
    formId: string,
    offset?: number,
    query?: string,
    sample?: any }) {
  const data: any = {
    formId,
    archiveOptions: ONLY_ACTIVE_SUBMISSIONS,
    includeFieldOrdering: true,
  };
  if (offset) {
    data.offset = offset;
  }
  if (query) {
    data.query = query;
  }
  const meta = { offset: offset || 0, requestTime: new Date(), isSearching: true };
  return api.postWithAuth({
    baseType,
    endpoint,
    data,
    pending: { isSearching: true },
    sample,
    success: (state, { response }) => (response ? onSuccess(Object.assign(response, meta)) : meta),
    failure: (state, error) => ({ ...meta, error }),
  });
}

export const removeSubmission = submissionId => ({
  submissionId,
  type: types.REMOVE_SUBMISSION,
});

export const searchSubmissionsInitial = (formId, query) =>
  page({
    baseType: types.NEW_TABLE_SEARCH_API,
    endpoint: 'submissions/search.json',
    onSuccess: createTableFromSearchJSON,
    formId,
    query,
    sample: SEARCH_DEBOUNCE_RATE_MS,
  });

export const searchSubmissionsSubsequent = (offset: number, formId: string, query: string) =>
  page({
    baseType: types.UPDATE_TABLE_SEARCH_API,
    endpoint: 'submissions/search.json',
    onSuccess: createTableFromSearchJSON,
    formId,
    offset,
    query,
  });

export function recordDetails(formId, submissionId) {
  return api.postWithAuth({
    endpoint: 'submissionDetail.json',
    data: { formId, submissionId, maid: getMAID() },
    baseType: types.RECORD_DETAILS,
    success: (state, { response }) => response,
    failure: (state, error) => ({ error }),
  });
}

export function formDetails(formId) {
  return api.postWithAuth({
    endpoint: 'formData.json',
    data: { formId },
    baseType: types.FORM_DETAILS,
    success: (state, { response }) => response,
    failure: (state, error) => ({ error }),
  });
}


export function loadSubmissionsStats(formId) {
  return api.postWithAuth({
    endpoint: 'forms/submissions/stats.json',
    data: { formId },
    baseType: types.SUBMISSIONS_STATS,
    success: (state, { response }) => response.result,
    failure: (state, error) => ({ error }),
  });
}

export function checkTableRow(submission, isChecked, index) {
  return {
    type: types.SELECT_TABLE_RECORD,
    submission,
    isChecked,
    index,
  };
}

export function selectAll(isAllSelected) {
  return {
    type: types.SELECT_ALL_RECORDS,
    isAllSelected,
  };
}

export const sortTableColumns = (prevIndex, nextIndex, sorting) => {
  const columnOrder = rearrangeArray(sorting, prevIndex, nextIndex);
  return {
    type: types.SORT_COLUMNS,
    columnOrder,
  };
};

export const saveTableSortOrder = (formId, oldSorting, newSorting) =>
  api.postWithAuth({
    baseType: types.COLUMN_ORDER_API,
    endpoint: 'updateFormColumnOrder.json',
    data: {
      formId,
      columnOrder: newSorting,
    },
    pending: { sorting: newSorting },
    success: () => ({}),
    failure: (state, error) => ({ error, sorting: oldSorting }),
  });

export const makeSortable = (sortingTargetIndex = 0, headerLabel = '', showSortingArrow = false) => ({
  type: types.MAKE_SORTABLE,
  payload: {
    sortingTargetIndex,
    headerLabel,
    showSortingArrow,
  },
});

export const deleteSubmissionsRequest = (
  selectedRowData,
  selectedRecords,
  selectedRowIndex,
  selectedRecordIndices,
  formId,
  includeAllSubmissions = false,
  excludedSubmissionIds = [],
) => {
  // getSelectedRecords can return an empty array - this is implicitly blocked
  // in the UI, because the button shouldn't show up if getSelectedRecords
  // would return the empty array.
  const submissions = getSelectedRecords(selectedRowData, selectedRecords);
  const submissionIds = map(submissions, 'submissionId');

  return api.postWithAuth({
    baseType: types.ARCHIVE_SUBMISSION_API,
    endpoint: 'deleteSubmission.json',
    data: {
      submissionIds,
      formId,
      isActive: false,
      includeAllSubmissions,
      excludedSubmissionIds,
    },
    pending: {
      selectedRowIndex,
      selectedRecordIndices,
    },
    success: (state, response) => {
      if (response.status === 200) {
        return {
          selectedRowData,
          selectedRecords,
          formId,
          snackbarMessage: 'Deleted successfully',
        };
      }
      return {
        error: 'archiving submission failed',
      };
    },
    failure: (state, error) => ({ error }),
  });
};

export const deleteArchivedSubmissionRequest = (
  selectedRowData,
  selectedRecords,
  selectedRowIndex,
  selectedRecordIndices,
  formId,
  includeAllSubmissions = false,
  excludedSubmissionIds = [],
) => {
  // getSelectedRecords can return an empty array - this is implicitly blocked
  // in the UI, because the button shouldn't show up if getSelectedRecords
  // would return the empty array.
  const submissions = getSelectedRecords(selectedRowData, selectedRecords);
  const submissionIds = map(submissions, 'submissionId');

  return api.postWithAuth({
    baseType: types.DELETE_ARCHIVED_SUBMISSION_API,
    endpoint: 'deleteArchivedSubmission.json',
    data: {
      submissionIds,
      formId,
      isActive: false,
      includeAllSubmissions,
      excludedSubmissionIds,
    },
    pending: {
      selectedRowIndex,
      selectedRecordIndices,
    },
    success: (state, response) => {
      if (response.status === 200) {
        return {
          selectedRowData,
          selectedRecords,
          formId,
          snackbarMessage: 'Deleted successfully',
        };
      }
      return {
        error: 'Deleting submission failed',
      };
    },
    failure: (state, error) => ({ error }),
  });
};

export const archiveSubmissionRequest = (
  selectedRowData,
  selectedRecords,
  selectedRowIndex,
  selectedRecordIndices,
  formId,
  includeAllSubmissions = false,
  excludedSubmissionIds = [],
) => {
  const submissions = getSelectedRecords(selectedRowData, selectedRecords);
  const submissionIds = map(submissions, 'submissionId');

  return api.postWithAuth({
    baseType: types.ARCHIVE_SUBMISSION_API,
    endpoint: 'archiveSubmission.json',
    data: {
      submissionIds,
      formId,
      includeAllSubmissions,
      excludedSubmissionIds,
    },
    pending: {
      selectedRowIndex,
      selectedRecordIndices,
    },
    success: (state, response) => {
      if (response.status === 200) {
        return {
          selectedRowData,
          selectedRecords,
          formId,
          snackbarMessage: 'Archived successfully',
        };
      }
      return {
        error: 'archiving submission failed',
      };
    },
    failure: (state, error) => ({ error }),
  });
};

export const unarchiveSubmissionRequest = (
  selectedRowData,
  selectedRecords,
  selectedRowIndex,
  selectedRecordIndices,
  formId,
  includeAllSubmissions = false,
  excludedSubmissionIds = [],
) => {
  const submissions = getSelectedRecords(selectedRowData, selectedRecords);
  const submissionIds = map(submissions, 'submissionId');

  return api.postWithAuth({
    baseType: types.ARCHIVE_SUBMISSION_API,
    endpoint: 'unarchiveSubmission.json',
    data: {
      submissionIds,
      formId,
      includeAllSubmissions,
      excludedSubmissionIds,
    },
    pending: {
      selectedRowIndex,
      selectedRecordIndices,
    },
    success: (state, response) => {
      if (response.status === 200) {
        return {
          selectedRowData,
          selectedRecords,
          formId,
          snackbarMessage: 'Restored successfully',
        };
      }
      return {
        error: 'unarchiving submission failed',
      };
    },
    failure: (state, error) => ({ error }),
  });
};

export const decryptionRequest = (value, decryptKey, rowIndex, inputName) =>
  api.postWithAuth({
    baseType: types.DECRYPT_FIELD_API,
    endpoint: 'decryptField.json',
    data: {
      value,
      decryptKey,
    },
    success: (state, { response: { result } }) => ({
      result,
      rowIndex,
      inputName,
    }),
    failure: (state, error) => ({ error }),
  });

export const reNotifyRequest = (formId, submissionId, signerKey, email) =>
  api.postWithAuth({
    baseType: types.RENOTIFY_API,
    endpoint: 'signatureReminder.json',
    data: {
      alternateEmail: email,
      formId,
      submissionId,
      signerKey,
    },
    success: () => ({}),
    failure: (state, error) => ({ error }),
  });

export const exportSubmissions = (
  selectedRowData,
  selectedRecords,
  exportParams,
  fieldOrdering,
  submissionFilters: SubmissionFilter[],
) => {
  const {
    formId,
    withAttachments,
    withFullyRenderedPdfs,
    withAccessCode,
    withCsvData,
    exportCsvOptions,
    recipientEmail,
    zipEncryptionPassphrase,
    isExportingAll,
  } = exportParams;

  // If this is true, the chekboxes are checked and use them for export
  // otherwise, a row is clicked on so use it
  const hasSelectedRecords = !!selectedRecords && selectedRecords.length > 0;

  let selectedSubmissionIds;
  if (isExportingAll) {
    // Sending an empty array to BE means exporting all submissions
    selectedSubmissionIds = [];
  } else {
    if (hasSelectedRecords) {
      selectedSubmissionIds = selectedRecords.map(submission => submission.submissionId);
    } else {
      // The filter on the next line is... problematic. Exporting after executing a search resulted in
      // selectedSubmissionIds being populated with `undefined`, leading to a 400 error (CUST-1757).
      // Given that we can't test/replicate this in a non-prod environment, this is a band-aid fix.
      selectedSubmissionIds = [(selectedRowData || {}).submissionId].filter(id => !!id);
    }
  }

  const exportableFieldsOrder = fieldOrdering.filter(field => !UNEXPORTABLE_COLUMNS.includes(field));

  const filterOptions = getSubmissionFiltersForServer(submissionFilters);

  return api.postWithAuth({
    endpoint: 'submissions/export.json',
    data: {
      limitSubmissionIds: selectedSubmissionIds,
      formId,
      fieldIdOrdering: exportableFieldsOrder,
      zipEncryptionPassphrase,
      withAttachments,
      withFullyRenderedPdfs,
      withAccessCode,
      withCsvData,
      exportCsvOptions,
      recipientEmail,
      filterOptions,
    },
    baseType: types.EXPORT_SUBMISSION_API,
    success: () => ({
      isExportSuccess: true,
      recipientEmail,
    }),
    failure: (state, error) => ({ error }),
  });
};

export const resetTableSnackbar = () => ({
  type: types.RESET_TABLE_SNACKBAR,
});

export const resetSelectedRows = () => ({
  type: types.RESET_SELECTED_ROWS,
});

export const useCachedTable = () => ({
  type: types.USE_CACHED_TABLE,
});

export const getAssigneesOfSubmissions = submissionIds =>
  api.postWithAuth({
    endpoint: 'grm/viewAssignees.json',
    baseType: types.GET_ASSIGNEES_OF_SUBMISSIONS,
    data: { submissionIds },
    success: (state, { response: { submissionsToUsers } }) => submissionsToUsers,
    failure: (_, error) => ({ error }),
  });

export const getTagsOfSubmissions = submissionIds =>
  api.postWithAuth({
    endpoint: 'grm/tags/viewAssociations.json',
    baseType: types.GET_TAGS_OF_SUBMISSIONS,
    data: { submissionIds },
    success: (state, { response: submissionsToTags }) => ({submissionsToTags, submissionIds}),
    failure: (_, error) => ({ error }),
  });

export const getStageOfSubmissions = submissionIds =>
  api.postWithAuth({
    endpoint: 'grm/stages/viewAssociations.json',
    baseType: types.GET_STAGE_OF_SUBMISSIONS,
    data: { submissionIds },
    success: (state, { response: submissionsToStage }) => ({submissionsToStage, submissionIds}),
    failure: (_, error) => ({ error }),
  });

export const getPermissionsOfSubmissions = submissions =>
  api.postWithAuth({
    endpoint: 'submissions/mutatingCapabilities.json',
    baseType: types.GET_PERMISSIONS_OF_SUBMISSIONS,
    data: { submissions },
    success: (
      state,
      {
        response: {
          result: { capabilitiesBySubmissionId },
        },
      },
    ) => capabilitiesBySubmissionId,
    failure: (_, error) => ({ error }),
  });

export const getPaymentsOfSubmissions = submissions =>
  api.postWithAuth({
    endpoint: 'payments/transactions/view.json',
    baseType: types.GET_PAYMENTS_OF_SUBMISSIONS,
    data: { submissions },
    success: (state, { response: { result } }) => result,
    pending: state => state,
    failure: (_, error) => ({ error }),
  });

export const getGovOsPaymentsOfSubmissions = submissions =>
  api.postWithAuth({
    endpoint: 'payments/transactions/govOsPay/view.json',
    baseType: types.GET_GOVOS_PAYMENTS_OF_SUBMISSIONS,
    data: { submissions },
    success: (state, { response: { result } }) => result?.transactions || {},
    pending: state => state,
    failure: (_, error) => ({ error }),
  });

export const getVerificationIdStatusOfSubmissions = (submissionIds: string[]) =>
  api.postWithAuth({
    endpoint: 'IdVerification/getStatusAndDetails.json',
    baseType: types.GET_VERIFICATION_ID_STATUS,
    data: { submissionIds },
    success: (state, { response: { result } }) => result || [],
    pending: state => state,
    failure: (_, error) => ({ error }),
  });

export const expandStageSidebar = () => ({
  type: types.OPEN_SIDEBAR,
});

export const toggleStageSidebar = () => ({
  type: types.TOGGLE_SIDEBAR,
});

export const toggleDropdown = (dropdownId: string, value?: boolean) => ({
  type: types.TOGGLE_DROPDOWN,
  dropdownId,
  value,
});

export const setBulkActionState = ({isOpened, modalType = '', entityType = ''}) => ({
  type: types.SET_BULK_ACTION_STATE,
  payload: {isOpened, modalType, entityType},
});

export const resetFiltersState: ResetFiltersStateActionCreator = (): ResetFiltersStateAction => ({
  type: types.RESET_FILTERS_STATE,
});

export const openFiltersModal: OpenFiltersModalActionCreator = (): OpenFiltersModalAction => ({
  type: types.OPEN_FILTERS_MODAL,
});

export const closeFiltersModal: CloseFiltersModalActionCreator = (): CloseFiltersModalAction => ({
  type: types.CLOSE_FILTERS_MODAL,
});

export const addFilter: AddFilterActionCreator = (columnKey?: string): AddFilterAction => ({
  type: types.ADD_FILTER,
  payload: columnKey ? { ...getEmptyFilter(), columnKey } : undefined,
});

export const removeFilter: RemoveFilterActionCreator = (
  filterId: string,
): RemoveFilterAction => ({
  type: types.REMOVE_FILTER,
  payload: filterId,
});

export const selectFilterColumn: SelectFilterColumnActionCreator = (
  id: string,
  columnKey: string,
): SelectFilterColumnAction => ({
  type: types.SELECT_FILTER_COLUMN,
  payload: { id, columnKey },
});

export const selectFilterOperator: SelectFilterOperatorActionCreator = (
  id: string,
  operator: Operator,
): SelectFilterOperatorAction => ({
  type: types.SELECT_FILTER_OPERATOR,
  payload: { id, operator },
});

export const changeFilterExpectation: ChangeFilterExpectationActionCreator = (
  id: string,
  expectation: Expectation,
): ChangeFilterExpectationAction => ({
  type: types.CHANGE_FILTER_EXPECTATION,
  payload: { id, expectation },
});

export const applyFilters: ApplyFiltersActionCreator = (filters: SubmissionFilter[]): ApplyFiltersAction => ({
  type: types.APPLY_FILTERS,
  payload: filters,
});

export const applyStageFilter: ApplyStageFilterActionCreator = (stageName: string): ApplyStageFilterAction => ({
  type: types.APPLY_STAGE_FILTER,
  payload: { stageName },
});

export const removeStageFilters: RemoveStageFiltersActionCreator = (): RemoveStageFiltersAction => ({
  type: types.REMOVE_STAGE_FILTERS,
});

export const resetFilters: ResetFiltersActionCreator = (): ResetFiltersAction => ({
  type: types.RESET_FILTERS,
});

export const sortSubmissions: SortSubmissionsActionCreator = (
  columnKeyBeingSorted: string,
  isAscending: boolean
): SortSubmissionsAction => ({
  type: types.SORT_SUBMISSIONS,
  newSortingState: { columnKeyBeingSorted, isAscending },
});

export const unsortSubmissions: UnsortSubmissionsActionCreator = (): UnsortSubmissionsAction => ({
  type: types.UNSORT_SUBMISSIONS,
});

export const resetSorting: ResetSortingActionCreator = (): ResetSortingAction => ({
  type: types.RESET_SORTING,
});

export const setHasSubmissionStatusError = isError => ({
  isError,
  type: types.SET_HAS_SUBMISSION_STATUS_ERROR,
});

export const processRefund = (submissionId, transaction, amount, gatewayName, paymentType, dispatch) =>
  api.postWithAuth({
    baseType: types.PROCESS_REFUND,
    endpoint: 'payments/transactions/partial/refund.json',
    data: {
      amount,
      submissionId,
      transactionToken: transaction,
      paymentType,
    },
    success: (state, { response }) => {
      dispatch(getPaymentsOfSubmissions([submissionId]));
      response.gatewayName = gatewayName;
      response.amount = amount;
      return response;
    },
    failure: (state, error) => {
      dispatch(setHasSubmissionStatusError(true));
      setTimeout(() => {
        dispatch(setHasSubmissionStatusError(false));
      }, 4000);
      return { error };
    },
  });


export const processPayment = (submissionId, token, amount, gatewayName, paymentType, dispatch) =>
  api.postWithAuth({
    baseType: types.PROCESS_PAYMENT,
    endpoint: 'payments/transactions/partial/capture.json',
    data: {
      amount,
      submissionId,
      transactionToken: token,
      paymentType,
    },
    success: (state, { response }) => {
      dispatch(getPaymentsOfSubmissions([submissionId]));
      response.gatewayName = gatewayName;
      response.amount = amount;
      return response;
    },
    failure: (state, error) => {
      dispatch(setHasSubmissionStatusError(true));
      setTimeout(() => {
        dispatch(setHasSubmissionStatusError(false));
      }, 4000);
      return { error };
    },
  });

export const setIsArchiveView = isArchiveView => ({
  isArchiveView,
  type: types.ARCHIVE_VIEW,
});

export const moveToStage = ({
  submissionIds,
  selectedStageName,
  includeAllSubmissions,
  excludedSubmissionIds,
  formId,
  dispatch,
  onError,
}) => {
  const assignStagesAction =
    assignStages({
      submissionIds,
      selectedStageName,
      includeAllSubmissions,
      excludedSubmissionIds,
      formId,
      onSuccess: () => {
        dispatch(getStageOfSubmissions(submissionIds));
        dispatch(getStagesOfForm(formId));
      },
      onError,
    });
  return unassignStages({
    submissionIds,
    includeAllSubmissions,
    excludedSubmissionIds,
    formId,
    onSuccess: () => dispatch(assignStagesAction),
    onError,
    snackbarMessage: 'Moved successfully',
  });
};

export const removeStages = ({
  submissionIds,
  dispatch,
  formId,
  onError,
  includeAllSubmissions,
  excludedSubmissionIds,
}) => unassignStages({
  submissionIds,
  formId,
  onSuccess: () => {
    dispatch(getStageOfSubmissions(submissionIds));
    dispatch(getStagesOfForm(formId));
  },
  onError,
  includeAllSubmissions,
  excludedSubmissionIds,
});

export const findAndReplaceStages = ({
  formId,
  stageToFind,
  stageToReplace,
  submissionIds,
  includeAllSubmissions,
  excludedSubmissionIds,
  onError,
}, dispatch) => api.postWithAuth({
  baseType: types.FIND_AND_REPLACE_STAGES,
  endpoint: 'stages/findAndReplace.json',
  data: {
    maid: getMAID(),
    userId: getUID(),
    formId,
    stageToFind,
    stageToReplace,
    submissionIds,
    includeAllSubmissions,
    excludedSubmissionIds,
  },
  success: (state, { response }) => {
    dispatch(getStageOfSubmissions(submissionIds));
    dispatch(getStagesOfForm(formId));
    return {
      ...response,
      snackbarMessage: 'All instances replaced successfully',
      numOfSelected: submissionIds.length,
      triggeredRules: response.result.triggeredRules,
      submissionIds,
    };
  },
  failure: (state, error) => {
    onError && onError();
    return error;
  },
  pending: {
    numOfSelected: submissionIds.length,
  },
});

export const addAssignee = ({
  submissionIds,
  userIds,
  formId,
  dispatch,
  onError,
  includeAllSubmissions,
  excludedSubmissionIds,
}) => assignUsersToMultipleSubmissions({
  submissionIds,
  userIds,
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  onError,
  onSuccess: () => dispatch(getAssigneesOfSubmissions(submissionIds))});

export const replaceAssignees = ({
  userIds,
  submissionIds,
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  onError,
}, dispatch) => api.postWithAuth({
  baseType: types.REPLACE_ASSIGNEES,
  endpoint: 'users/replace.json',
  data: {
    userIds,
    submissionIds,
    formId,
    includeAllSubmissions,
    excludedSubmissionIds,
  },
  success: (state, { response }) => {
    dispatch(getAssigneesOfSubmissions(submissionIds));
    return {
      ...response,
      snackbarMessage: 'Assignees replaced successfully',
      numOfSelected: submissionIds.length,
      triggeredRules: response.result.triggeredRules,
      submissionIds,
    };
  },
  failure: (state, error) => {
    onError && onError();
    return error;
  },
  pending: {
    numOfSelected: submissionIds.length,
  },
});

export const removeAssignees = ({
  submissionIds,
  dispatch,
  onError,
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  unassignAllUsers,
  userIds,
}) =>
  unassignUsersToMultipleSubmissions({
    submissionIds,
    formId,
    userIds,
    onSuccess: () => dispatch(getAssigneesOfSubmissions(submissionIds)),
    onError,
    includeAllSubmissions,
    excludedSubmissionIds,
    unassignAllUsers,
  });

export const findAndReplaceAssignees = ({
  assigneeToFind,
  assigneeToReplace,
  submissionIds,
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  onError,
}, dispatch) => api.postWithAuth({
  baseType: types.FIND_AND_REPLACE_ASSIGNEES,
  endpoint: 'users/findAndReplace.json',
  data: {
    formId,
    assigneeToFind,
    assigneeToReplace,
    submissions: submissionIds,
    includeAllSubmissions,
    excludedSubmissionIds,
  },
  success: (state, { response }) => {
    dispatch(getAssigneesOfSubmissions(submissionIds));
    return {
      ...response,
      snackbarMessage: 'All instances replaced successfully',
      numOfSelected: submissionIds.length,
      triggeredRules: response.result.triggeredRules,
      submissionIds,
    };
  },
  failure: (state, error) => {
    onError && onError();
    return error;
  },
  pending: {
    numOfSelected: submissionIds.length,
  },
});

export const addTag = ({
  submissionIds,
  tagNames,
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  dispatch,
  onError,
}) => assignTagsToMultipleSubmissions({
  submissionIds,
  tagNames,
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  onError,
  onSuccess: () => dispatch(getTagsOfSubmissions(submissionIds))});

export const replaceTags = ({
  tagsToReplace,
  submissionIds,
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  onError,
}, dispatch) => api.postWithAuth({
  baseType: types.REPLACE_TAGS,
  endpoint: 'tags/replace.json',
  data: {
    tagsToReplace,
    submissionIds,
    formId,
    includeAllSubmissions,
    excludedSubmissionIds,
  },
  success: (state, { response }) => {
    dispatch(getTagsOfSubmissions(submissionIds));
    return {
      ...response,
      snackbarMessage: 'Tags replaced successfully',
      numOfSelected: submissionIds.length,
      triggeredRules: response.result.triggeredRules,
      submissionIds,
    };
  },
  failure: (state, error) => {
    onError && onError();
    return error;
  },
  pending: {
    numOfSelected: submissionIds.length,
  },
});

export const removeTags = ({submissionIds, formId, includeAllSubmissions, excludedSubmissionIds, dispatch, onError}) =>
  unassignTagsToMultipleSubmissions({
    submissionIds,
    formId,
    includeAllSubmissions,
    excludedSubmissionIds,
    onSuccess: () => dispatch(getTagsOfSubmissions(submissionIds)),
    onError,
  });

export const findAndReplaceTags = ({
  tagToFind,
  tagToReplace,
  submissionIds,
  formId,
  onError,
  includeAllSubmissions,
  excludedSubmissionIds,
}, dispatch) => api.postWithAuth({
  baseType: types.FIND_AND_REPLACE_TAGS,
  endpoint: 'tags/findAndReplace.json',
  data: {
    maid: getMAID(),
    formId,
    tagToFind,
    tagToReplace,
    submissionIds,
    includeAllSubmissions,
    excludedSubmissionIds,
  },
  success: (state, { response }) => {
    dispatch(getTagsOfSubmissions(submissionIds));
    return {
      ...response,
      snackbarMessage: 'All instances replaced successfully',
      numOfSelected: submissionIds.length,
      triggeredRules: response.result.triggeredRules,
      submissionIds,
    };
  },
  failure: (state, error) => {
    onError && onError();
    return error;
  },
  pending: {
    numOfSelected: submissionIds.length,
  },
});

export const toggleSnackbar = (snackbarOpen, snackbarMessage) => ({
  type: types.TOGGLE_SNACKBAR,
  snackbarOpen,
  snackbarMessage,
});
