/* eslint-disable react/no-danger */
import React, { forwardRef } from 'react';
import { sanitize } from 'dompurify';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';
import { LabelAlignment } from 'types/liveView';

export type Props = {
  id: string,
  specialSettings?: {
    content: string,
    align: LabelAlignment;
  }
}

const HTMLBox = ({
  id,
  specialSettings = {
    content: '',
    align: 'auto',
  },
}: Props) => {
  const contentAlignment: LabelAlignment = specialSettings ? specialSettings.align : 'auto';
  const content: string = specialSettings ? specialSettings.content : '';

  const alignment: string = getAlignmentClass(contentAlignment, sharedStyle);

  return (
    <div id={`container_${id}`} className={'form_control_group'}>
      <fieldset className={classNames(sharedStyle.Fieldset, alignment)} id={`html_box_${id}`}>
        <div dangerouslySetInnerHTML={{ __html: sanitize(content, {FORCE_BODY: true}) }} />
      </fieldset>
    </div>
  );
};

export default React.memo(forwardRef(HTMLBox));
