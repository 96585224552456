import { CircularProgress } from '@material-ui/core';
import React from 'react';

const ProgressBar = ({ className }) => (<div className={className}>
  <CircularProgress
    thickness={3}
    size={80}
    style={{color: '#3874CB'}} />
</div>);

export default ProgressBar;
