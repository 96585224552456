import React from 'react';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import injectRouter from '../decorators/injectRouter';
import { AUTH0_CALLBACK, ROOT, SSO_FAILURE } from 'constants/route';
import * as authActions from 'actions/auth';
import * as utils from 'utils/auth';

interface Props {
  dispatch: Function,
  location: any,
  router: any,
  isAuthenticated: boolean,
  error: string,
}

class RedirectOnLogin extends React.Component<Props> {
  componentDidMount() {
    if (this.props.error && location.pathname === AUTH0_CALLBACK) {
      this.props.router.replace(SSO_FAILURE);
    }
    if (utils.isLoggedIn()) {
      this.redirect();
    } else {
      const { dispatch } = this.props;
      dispatch(authActions.getAccountKey(dispatch));
    }
  }

  UNSAFE_componentWillReceiveProps({ isAuthenticated, error }) {
    // isAuthenticated should sync with isLoggedIn, except when
    // token expires; we don't have a periodic check for expired token yet
    if (isAuthenticated && utils.isLoggedIn()) {
      this.redirect();
    }
    if (error && location.pathname === AUTH0_CALLBACK) {
      this.props.router.replace(SSO_FAILURE);
    }
  }

  redirect = () => {
    const search = parse(window.location.search);
    if (search.redirect_url) {
      window.location.href = search.redirect_url;
      return;
    }

    const { location, router } = this.props;
    if (location.state && location.state.nextPathname) {
      router.replace(location.state.nextPathname);
    } else if (location.query && location.query.state) {
      router.replace(`${location.query.state}`);
    } else {
      router.replace(ROOT);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({ auth: { isAuthenticated, error } }) => ({ isAuthenticated, error });

export default connect(mapStateToProps)(injectRouter(RedirectOnLogin));
