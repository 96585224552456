import {Observable} from 'rxjs/Observable';
import {success} from './utils/filters';
import * as types from 'constants/types/authActionTypes';
import {loginUser, getActiveDirectoryConnections} from 'actions/auth';


export default function loginEpic(action$) {
  const auth0LoginSuccess$ = action$
    .ofType(types.AUTH0_LOGIN_SUCCESS);

  const getAccountKeySuccess$ = action$
    .ofType(types.GET_ACCOUNT_KEY)
    .filter(success);

  return Observable.combineLatest(
    auth0LoginSuccess$,
    getAccountKeySuccess$,
    ({idToken, profile}, {payload: {accountKey}}) =>
      loginUser(idToken, profile, accountKey));
}

export const activeDirectoryConnectionsEpic = action$ =>
  action$
    .ofType(types.GET_ACCOUNT_KEY)
    .filter(success)
    .map(({payload: {accountKey}}) => getActiveDirectoryConnections(accountKey));
