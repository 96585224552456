import React from 'react';
import { debounce } from 'lodash';
import MaterialUIPopover from '@material-ui/core/Popover';
import styles from './Popover.css';
import { joinClassNames } from 'utils/strings';
import { DOWN_KEY, UP_KEY, SPACE_KEY, ENTER_KEY, _getKeyboardCode } from 'utils/keyboard';


class Popover extends React.Component<any, any> {
  static defaultProps = {
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    hasPeak: false,
    isEnabled: true,
    requestOpen: () => { },
    shouldOpenOnHover: false,
    shouldRepositionForMobile: true,
    transformOrigin: { vertical: 'top', horizontal: 'left' },
    widthClassName: '',
    triggerButtonRef: null,
  };

  constructor(props) {
    super(props);
    this.debouncedRequestOpen = debounce(this.handleRequestOpen, 200);
    this.triggerButtonRef = props.triggerButtonRef || React.createRef();
  }

  actions
  debouncedRequestOpen
  triggerButtonRef;

  handleActions = actions => {
    this.actions = actions;
    if (!this.props.shouldRepositionForMobile) {
      // This does what `canAutoPosition` did in V0.
      actions.updatePosition();
    }
  }

  // This prevents the parent/outside click handlers to fire up when the backdrop is clicked
  handleOnClick = event => event.stopPropagation();
  handleOnKeyDown = event => {
    const key = _getKeyboardCode(event);
    switch (key) {
      case UP_KEY:
      case DOWN_KEY:
      case SPACE_KEY:
      case ENTER_KEY:
        this.handleRequestOpen(event);
        break;
      default:
        return;
    }
  }

  handleRequestOpen = event => {
    const {
      isEnabled,
      requestOpen,
    } = this.props;
    if (isEnabled) {
      requestOpen(event);
    }
  }

  render() {
    const {
      anchorOrigin,
      children,
      hasPeak,
      isOpen,
      shouldOpenOnHover,
      renderTriggerButton,
      requestClose,
      tabIndex,
      transformOrigin,
      widthClassName,
    } = this.props;
    const { triggerButtonRef } = this;
    const positionClassName = anchorOrigin.horizontal === 'right' ? styles.anchorRight : styles.anchorLeft;
    const popoverClasses = joinClassNames(styles.popoverBody, widthClassName, positionClassName, hasPeak ? styles.withPeak : '');
    return (
      <div className={styles.container} onClick={this.handleOnClick}>
        <div
          className={isOpen ? styles.buttonIsOpen : undefined}
          onClick={this.handleRequestOpen}
          ref={triggerButtonRef}
          onKeyDown={this.handleOnKeyDown}
          onMouseEnter={shouldOpenOnHover ? this.debouncedRequestOpen : undefined}
          onMouseLeave={shouldOpenOnHover ? this.debouncedRequestOpen.cancel : undefined}
          tabIndex={tabIndex}>
          {renderTriggerButton()}
        </div>
        {isOpen && (
          <MaterialUIPopover
            action={this.handleActions}
            anchorEl={triggerButtonRef.current}
            onClose={requestClose}
            open={isOpen}
            classes={{
              paper: popoverClasses,
            }}
            ModalClasses={{
              root: styles.placePopoverAboveMuiModal,
            }}
            PaperProps={{
              onMouseLeave: shouldOpenOnHover ? requestClose : undefined,
            }}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}>
            {children}
          </MaterialUIPopover>
        )}
      </div>
    );
  }
}

export default Popover;
