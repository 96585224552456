import React from 'react';
import styles from './TypeaheadInput.css';
import classNames from 'classnames';


function TypeaheadInput({
  onBlur = null as Function | null,
  onFocus = null as Function | null,
  shouldAutoFocus = true,
  value = '',
  onChange,
  placeholder,
}) {
  const handleBlur = event => {
    onBlur && onBlur(event);
  };

  const handleFocus = event => {
    onFocus && onFocus(event);
  };

  return (
    <div className={styles.container}>
      <input
        autoFocus={shouldAutoFocus}
        className={classNames(styles.input, 'sd-typeahead')}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        value={value}
        type='text' />
    </div>
  );
}

export default TypeaheadInput;
