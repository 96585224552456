import React from 'react';
import { noop } from 'lodash';
import styles from './DropdownOption.css';
import { joinClassNames } from 'utils/strings';
import checkmarkIcon from 'icons/icon128.svg';


export type Props = {
  children: React.ReactNode;
  isFocused: boolean;
  isSelected: boolean;
  onClick: () => void;
  showCheckmark?: boolean;
};


const DropdownOption = ({
  children,
  isFocused,
  isSelected,
  onClick,
  showCheckmark = true,
}: Props) => (
  <div
    className={joinClassNames(
      styles.optionAppearance,
      isSelected ? styles.isSelected : '',
      isFocused ? styles.isFocused : '',
    )}
    onClick={() => onClick()}>
    {isSelected && showCheckmark && <img src={checkmarkIcon} alt='selected' />}
    {children}
  </div>
);

DropdownOption.defaultProps = {
  isFocused: false,
  isSelected: false,
  onClick: noop,
};

export default DropdownOption;
