import React from 'react';
import Icon from '@material-ui/core/Icon';
import * as Colors from 'utils/colors';
import FieldItem from '../FieldItem';
import styles from './DetailRow.css';

type DetailRowProps = {
  fieldValue: string,
  icon: string,
  label: string,
};

const DetailRow = ({icon, label, fieldValue}: DetailRowProps) => (
  <div>
    <span className={styles.detailsIcon}>
      <Icon style={{color: Colors.textSecondary}}>{icon}</Icon>
    </span>
    <span className={styles.detailsData}>
      <FieldItem label={label} fieldValue={fieldValue}/>
    </span>
  </div>
);

export default DetailRow;
