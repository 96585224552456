import api from 'actions/api';
import {GET_FEATURE_FLAGS, databaseBackedFeatures} from 'constants/types/features';
import {Action} from 'types/api';
import {GetFeatureFlagsSuccessPayload} from 'types/features';


export const getFeatureFlags = (accountKey: string): Action =>
  api.postWithoutAuth({
    endpoint: 'featureFlags.json',
    baseType: GET_FEATURE_FLAGS,
    data: {
      subdomain: accountKey,
      features: Object.keys(databaseBackedFeatures),
    },
    success: (_state, {response: {features}}): GetFeatureFlagsSuccessPayload => ({features}),
    failure: (_state, error) => ({error}),
  });
