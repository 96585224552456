// import type {Role} from 'types/shared';

export const userRolesEnum = {
  OWNER: 'OWNER',
  DIRECTOR: 'DIRECTOR',
  PROCESSOR: 'PROCESSOR',
  ANALYST: 'ANALYST',
  VISITOR: 'VISITOR',
};

export const FullUserRoles = [userRolesEnum.OWNER, userRolesEnum.DIRECTOR, userRolesEnum.PROCESSOR];
export const LimitedUserRoles = [userRolesEnum.ANALYST];

export const PERMISSION_USER_ROLES = Object.keys(userRolesEnum).filter(
  key => key !== 'OWNER' && key !== 'VISITOR'
).sort();

export const userCapabilitiesEnum = {
  CREATE_FORMS: 'CREATE_FORMS',
  ACCESS_SUPPORT: 'ACCESS_SUPPORT',
  ACCESS_ACCOUNT_DETAILS: 'ACCESS_ACCOUNT_DETAILS',
  ACCESS_FOLDER_TREE: 'ACCESS_FOLDER_TREE',
  ACCESS_EDIT_FOLDER_TREE: 'ACCESS_EDIT_FOLDER_TREE',
  ACCESS_USER_LIST: 'ACCESS_USER_LIST',
  ACCESS_API_KEYS: 'ACCESS_API_KEYS',
  ACCESS_QUICK_GUIDE: 'ACCESS_QUICK_GUIDE',
  ACCESS_SD: 'ACCESS_SD',
  ACCESS_TAGS: 'ACCESS_TAGS',
  DELETE_SUBMISSIONS: 'DELETE_SUBMISSIONS',
  EXPORT_SUBMISSIONS: 'EXPORT_SUBMISSIONS',
  ACCESS_GRM: 'ACCESS_GRM',
  READ_COMPLETED_SUBMISSIONS: 'READ_COMPLETED_SUBMISSIONS',
  READ_ARCHIVED_FORMS: 'READ_ARCHIVED_FORMS',
  READ_ASSIGNED_SUBMISSIONS: 'READ_ASSIGNED_SUBMISSIONS',
  READ_SUBMISSION_DETAILS: 'READ_SUBMISSION_DETAILS',
};
