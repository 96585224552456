import React from 'react';
import { connect } from 'react-redux';
import AssetFetcher from './index';
import { fetchSubmissionPdfUrl } from 'actions/submissionsManager/submissionsActions';
// import type {FetchSubmissionPdfActionCreator} from 'types/assets';

type Props = {
  fetchSubmissionPdfUrl: any, // FetchSubmissionPdfActionCreator,
  params: {
    submissionId: string,
  },
};

const SubmissionPdfFetcher = (props: Props) =>
  <AssetFetcher assetId={props.params.submissionId} fetchAsset={props.fetchSubmissionPdfUrl} />;

export default connect(null, { fetchSubmissionPdfUrl })(SubmissionPdfFetcher);
