import React from 'react';
import Stage, { } from 'components/Stages/Stage';
import { DragSource, DropTarget } from 'react-dnd';
import { flow } from 'lodash';
import styles from './DraggableStage.css';


const DraggableStage = ({ connectDragSource, connectDropTarget, beingHovered, ...props }:
  { connectDragSource: any, connectDropTarget: any, beingHovered: boolean, props: any }) => {
  const result = connectDropTarget(connectDragSource(
    <div className={`${styles.wrapper} ${beingHovered ? styles.beingHovered : ''}`}>
      <Stage {...props} />
    </div>
  ));
  return result === undefined ? null : result;
};

const dropTarget = DropTarget(
  'stage',
  {
    drop: ({ index }) => ({ index }),
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    beingHovered: monitor.isOver(),
  })
);

const dragSource = DragSource(
  'stage',
  {
    beginDrag: ({ data, index }) => ({ data, index }),
    canDrag: (props: any) => props.canDrag && !props.isReadOnly,
    endDrag(props: any, monitor) {
      const destination = monitor.getDropResult();
      if (!destination) return;
      props.onReorder(props.index, destination.index);
    },
  },
  connect => ({
    connectDragSource: connect.dragSource(),
  }),
);

export default flow(dropTarget, dragSource)(DraggableStage);
