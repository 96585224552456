import moment from 'moment';
import {
  CalendarEvent,
  EventObjectOutput,
  Google,
  NormalizedCalendarEvent,
  Outlook,
  Yahoo,
  TimeFormatsObj,
} from './calendarEventHelper.types';

export const TimeFormats:TimeFormatsObj = {
  dateTimeLocal: 'YYYY-MM-DD[T]HH:mm:ss',
  dateTimeUTC: 'YYYYMMDD[T]HHmmss[Z]',
  allDay: 'YYYYMMDD',
};

export const arePropsValid = (
  startDate: string | undefined,
  endDate: string | undefined,
  startTime: string | undefined,
  endTime: string | undefined,
  dateFormat: string,
  timeFormat: string
): boolean => {
  if (!startDate || !endDate || !startTime || !endTime) {
    return false;
  }

  return (
    moment(startDate, dateFormat, true).isValid() &&
    moment(endDate, dateFormat, true).isValid() &&
    moment(startTime, timeFormat, true).isValid() &&
    moment(endTime, timeFormat, true).isValid()
  );
};

export const timeFormatToAMOrPM = (timeStr: string | null): string => {
  if (timeStr === null) {
    return ''; // Or any default value you prefer when input is null
  }
  return moment(timeStr, 'HH:mm:ss').format('hh:mm A');
};

export const concatDateAndTimePropsToISO = (
  date: string | null,
  time: string | null
): string | null => {
  if (!date || !time) {
    return null;
  }

  const [month, day, year] = date.split('-').map(Number);
  const [hours, minutes, seconds] = time.split(':').map(Number);

  if (
    isNaN(month) ||
    isNaN(day) ||
    isNaN(year) ||
    isNaN(hours) ||
    isNaN(minutes) ||
    isNaN(seconds)
  ) {
    return null;
  }

  return new Date(year, month - 1, day, hours, minutes, seconds).toISOString();
};

export const formatTimes = ({ startTime, endTime }: NormalizedCalendarEvent, dateTimeFormat: keyof typeof TimeFormats,
): { start: string; end: string } => {
  const format = TimeFormats[dateTimeFormat];
  return {
    start: moment(startTime).format(format),
    end: moment(endTime).format(format),
  };
};

const formatDateStamp = (date: Date, dateTimeFormat: string): string => {
  const momentDate = moment(date).utc();
  return momentDate.format(dateTimeFormat);
};

const eventObjectHandler = (event: CalendarEvent, toUtc: boolean = true): EventObjectOutput => {
  const { start, end, ...rest } = event;
  const startTime = toUtc ? moment.utc(start) : moment(start);
  const endTime = toUtc ? moment.utc(end) : moment(end);
  return {
    ...rest,
    startTime,
    endTime,
  };
};

export const generateGoogleEventURL = (calendarEvent: CalendarEvent): string => {
  const event = eventObjectHandler(calendarEvent);
  const { start, end } = formatTimes(event, 'dateTimeUTC');
  const details: Google = {
    action: 'TEMPLATE',
    text: event.title,
    details: event.description,
    location: event.location,
    trp: event.busy,
    dates: start + '/' + end,
    recur: event.rRule ? 'RRULE:' + event.rRule : undefined,
  };
  if (event.guests && event.guests.length) {
    details.add = event.guests.join();
  }

  const params = new URLSearchParams(details as Record<string, string>);
  return `https://calendar.google.com/calendar/render?${params.toString()}`;
};

export const generateOutlookEventURL = (calendarEvent: CalendarEvent): string => {
  const event = eventObjectHandler(calendarEvent, false);
  const { start, end } = formatTimes(event, 'dateTimeLocal');
  const details: Outlook = {
    path: '/calendar/action/compose',
    rru: 'addevent',
    startdt: start,
    enddt: end,
    subject: event.title,
    body: event.description,
    location: event.location,
    allday: event.allDay || false,
  };
  const params = new URLSearchParams(details as Record<string, string>);
  return `https://outlook.office.com/calendar/0/action/compose?${params.toString()}`;
};

export const generateYahooEventURL = (calendarEvent: CalendarEvent): string => {
  const event = eventObjectHandler(calendarEvent);
  const { start, end } = formatTimes(event, 'dateTimeUTC');


  const details: Yahoo = {
    v: 60,
    title: event.title,
    st: start,
    et: end,
    desc: event.description,
    in_loc: event.location,
    dur: false,
  };

  const params = new URLSearchParams(details as Record<string, string>);
  return `https://calendar.yahoo.com/?${params.toString()}`;
};

export const iCalFileUrl = (calendarEvent: CalendarEvent): string => {
  const event = eventObjectHandler(calendarEvent);
  const formattedDescription: string = (event.description || '')
    .replace(/,/gm, ',')
    .replace(/;/gm, ';')
    .replace(/\r\n/gm, '\n')
    .replace(/\n/gm, '\\n')
    .replace(/(\\n)[\s\t]+/gm, '\\n');

  const formattedLocation: string = (event.location || '')
    .replace(/,/gm, ',')
    .replace(/;/gm, ';')
    .replace(/\r\n/gm, '\n')
    .replace(/\n/gm, '\\n')
    .replace(/(\\n)[\s\t]+/gm, '\\n');

  const { start, end } = formatTimes(event, 'dateTimeUTC');
  const dateStamp = formatDateStamp(new Date(), TimeFormats.dateTimeUTC);

  const calendarChunks = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    `PRODID:${event.title}`,
    'BEGIN:VEVENT',
    `URL:${event.url}`,
    `DTSTART:${start}`,
    `DTEND:${end}`,
    `DTSTAMP:${dateStamp}`,
    `RRULE:${event.rRule}`,
    `SUMMARY:${event.title}`,
    `DESCRIPTION:${formattedDescription}`,
    `LOCATION:${formattedLocation}`,
    `UID:${Math.floor(Math.random() * 100000).toString().replace('.', '')}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ];

  return calendarChunks.join('\r\n');
};
