import React from 'react';
import { FormTitle } from '../../form-comps/FormLayout/FormLayout';
import { FormData, LiveViewCoverLetter } from 'types/liveView';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FormCoverLetter from '../../form-comps/FormCoverLetter/FormCoverLetter';

export const renderFormErrorMessage = (errorMessage: string, formStyle: any): JSX.Element => (
  <div
    className={formStyle.FormErrorMessage}>
    <ErrorOutlineIcon className={formStyle.FormErrorIcon} />
    {errorMessage}
  </div>
);

export const renderFormTitle = (
  formData: FormData,
  logo: any,
): JSX.Element | null => {
  if (!formData) {
    return null;
  }
  return (<FormTitle logoUrl={logo} formName={formData?.form?.formName || ''} />);
};

export const renderCoverLetter = (
  coverLetter: LiveViewCoverLetter,
  onCoverLetterContinue: React.MouseEventHandler<Element>,
): JSX.Element => {
  const {
    structure,
    settings: { acknowledgementText, legacyHtml, title, requireAcknowledgement } } = coverLetter;
  return (
    <FormCoverLetter
      ackText={acknowledgementText}
      structure={structure}
      legacyHtml={legacyHtml || ''}
      requireAck={requireAcknowledgement}
      title={title || ''}
      onContinue={onCoverLetterContinue}
    />
  );
};
