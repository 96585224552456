import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Registration from 'components/Registration';
import AuthLayout from 'components/AuthLayout';
import AuthSidebar from 'components/AuthLayout/AuthSidebar';
import { RegistrationHeader } from 'components/AuthLayout/AuthHeader';
import RegistrationSubmission from 'components/Registration/RegistrationSubmission';
import RedirectOnLogin from 'containers/RedirectOnLogin';
import { registerUser } from 'actions/auth';
import { getFormPublicInfo } from 'actions/forms';
import { getRegistrationForServer } from 'utils/auth';
import { loginInterstitialPath, redirectToLogin } from 'utils/routing';
import { BLANK_FORM } from 'constants/forms';
import { registrationErrorsEnum } from 'constants/auth';
import { INSTRUCTIONS_ENUM } from 'constants/liveViewGating';
import { getCurrentPredicateInstruction, getAuthSubmissionState, getFormPublicInfoById } from '../../reducers';


const transformErrorMessage = error => {
  const message = registrationErrorsEnum[error];
  return !!message ? message : 'Something went wrong. Please try again later.';
};

export class RegistrationInterstitial extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      passwordError: '',
    };
  }

  componentDidMount() {
    const { form_id: formId, maid } = this.props.router.location.query;

    // Necessary for flow, though we wouldn't have gotten here if these didn't exist (onEnter hook handles that logic).
    if (!(formId && maid)) return;
    this.props.getFormPublicInfo(formId, maid);
  }

  componentDidUpdate() {
    if (this.props.featureFlagLoaded && !this.props.visitorRegistrationEnabled) redirectToLogin(this.props.router);
  }

  renderRegistrationComponent() {
    const { registrationError, registrationSuccess, processingSubmission } = this.props;
    const error = registrationError ? transformErrorMessage(registrationError) : this.state.passwordError;
    return registrationSuccess ? (
      <RegistrationSubmission />
    ) : (
      <Registration errors={error} onSubmit={this.onSubmit} processingSubmission={processingSubmission} />
    );
  }

  parseState = state => getRegistrationForServer(state);

  validatePassword(password, passwordConfirm) {
    let passwordError = '';
    if (password !== passwordConfirm) {
      passwordError = 'Passwords must match';
    } else if (password.includes(' ') || password.length < 8) {
      passwordError =
        'Password must be at least 8 characters long. Password can contain letters, numbers and punctuation.';
    }

    this.setState({ passwordError });
    return !passwordError;
  }

  onSubmit = formData => {
    if (this.validatePassword(formData.password, formData.passwordConfirm)) {
      // use router.location instead of location because Flow is unhappy with possible null values for these params
      // if we got this far, we know that these values are not null/undefined (but Flow doesn't)
      const { redirect_url, maid, form_id } = this.props.router.location.query;
      const submissionId = redirect_url.substring(redirect_url.lastIndexOf('/') + 1);
      this.props.registerUser(redirect_url, maid, form_id, this.parseState(formData), submissionId);
    }
  };

  render() {
    const { form, isFetching, loginPath, location } = this.props;

    const sidebar = (
      <AuthSidebar
        {...form.letterheadInfo}
        labelText='Already have an account?'
        buttonText='Log In'
        linkHref={loginPath}
      />
    );
    const mainComponent = this.renderRegistrationComponent();
    const header = <RegistrationHeader />;
    return (
      <React.Fragment>
        <RedirectOnLogin location={location} />
        <AuthLayout
          fetching={isFetching}
          formName={form.formName}
          mainComponent={mainComponent}
          header={header}
          sidebar={sidebar}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, { router }) => {
  const {
    auth: { isFetching, registrationError, registrationSuccess },
  } = state;
  let form;
  let featureFlagLoaded = false;

  if (typeof router.location.query.form_id === 'string') {
    form = getFormPublicInfoById(state, router.location.query.form_id);
  }
  !!form ? (featureFlagLoaded = true) : (form = BLANK_FORM);

  const loginPath =
    getCurrentPredicateInstruction(state) === INSTRUCTIONS_ENUM.REQUIRES_LOGIN
      ? loginInterstitialPath(router.location.search, 'ng/liveViewGate')
      : loginInterstitialPath(router.location.search);
  return {
    form,
    loginPath,
    registrationError,
    registrationSuccess,
    isFetching,
    featureFlagLoaded,
    processingSubmission: getAuthSubmissionState(state),
    visitorRegistrationEnabled: !!form.publicFormSettings.allowVisitorRegistration,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators({ getFormPublicInfo, registerUser }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationInterstitial);
