import React, { useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import { joinClassNames, formatPaymentAmount } from 'utils/strings';
import * as dateHelper from 'utils/dateHelper';
import paymentsIcon from 'icons/paymentsIcon.svg';
import FieldItem from 'components/FieldItem';
import ExpansionField from 'components/ExpansionField/ExpansionField';
import { PaymentTypeLabel } from 'constants/payments';
import { getHasStatusError, getIsProcessPaymentPending } from '../../reducers';
import styles from './SubmissionPaymentStyles.css';
import { GovOSPaymentType } from 'types/submissionsManager/tableTypes';
import { State as Features } from 'types/features';
import { DEFAULT_GOVOS_PAY_CONVENIENCE_FEE_LABEL } from 'constants/payments';
import { getIsArchivedView } from 'reducers/submissionsManager/submissionTable';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';

type PropsFromState = {
  hasStatusError: boolean,
  isPaymentProcessing: boolean,
  isSubmissionsLoading: boolean,
  isArchiveView: boolean,
  isFortePaymentDisabled: boolean,
};

type Props =
  & PropsFromState
  & {
  features: Features,
  paymentDetails: GovOSPaymentType[],
  isSubmissionArchived: boolean,
};


const renderPaymentHeader = () => (
  <div className={styles.headerWrapper}>
    <img alt='headerIcon' src={paymentsIcon} className={styles.headerIcon} />
    <span className={styles.headerLabel}>Payment Transaction Details</span>
  </div>
);

const getExpansionItems = transactionInfo =>
  transactionInfo.feeTotal
    ? [
      { label: 'Subtotal', value: formatPaymentAmount(transactionInfo.baseAmount) },
      {
        label: transactionInfo.customFeeName || DEFAULT_GOVOS_PAY_CONVENIENCE_FEE_LABEL,
        value: formatPaymentAmount(transactionInfo.feeTotal || 0),
      },
    ]
    : [];

const GovOSPaymentContent = (props: Props) => {
  const [isExpanded, toggleExpandedValue] = useState(true);

  const activePayment = props.paymentDetails.filter(item => item.transactionStatus === 'SUCCESS').pop();


  const renderTransactionHistory = () => (
    <>
      {activePayment && (
        <>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <ExpansionField
                label='Processed Amount'
                value={formatPaymentAmount(activePayment.amount)}
                expansionItems={getExpansionItems(activePayment)}
                isOpenByDefault={false}
              />
            </div>
          </li>
        </>
      )}
    </>);

  return (
    <div>
      {renderPaymentHeader()}
      <span>
        <ul>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={classNames(styles.boxListContent, styles.boxListContent_totalValue)}>
              <FieldItem
                label='Total Collected'
                fieldValue={formatPaymentAmount(activePayment?.amount || 0)}
                isRowView
              />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Payment Method' fieldValue={PaymentTypeLabel[activePayment?.paymentType || '']} isRowView />
            </div>
          </li>
          {isExpanded && (
            <>
              <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
                <div className={styles.boxListContent}>
                  <FieldItem label='Transaction ID' fieldValue={activePayment?.platformTransactionId || ''} isRowView />
                </div>
              </li>
              <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
                <div className={styles.boxListContent}>
                  <FieldItem
                    label='Timestamp'
                    fieldValue={dateHelper.getFormattedTime(
                      activePayment?.createdTs,
                      dateHelper.TIME_FORMAT_LONG
                    )}
                    isRowView
                  />
                </div>
              </li>
              {renderTransactionHistory()}
            </>
          )}

          <li>
            <div className={classNames(styles.expansionBtnContainer)}>
              <IconButton
                onClick={() => {
                  toggleExpandedValue(!isExpanded);
                }}
                classes={{ root: styles.expansionBtn }}
                aria-label='Expansion Button'
              >
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          </li>
        </ul>
      </span>
    </div>
  );
};

const mapStateToProps = (state): PropsFromState => ({
  hasStatusError: getHasStatusError(state),
  isPaymentProcessing: getIsProcessPaymentPending(state),
  isSubmissionsLoading: state.submissionTable.isSubmissionsLoading,
  isArchiveView: getIsArchivedView(state),
  isFortePaymentDisabled: selectTreatmentValue(state.splitio, 'FORTE_PAYMENT_DISABLED') === 'on',
});

export default connect(mapStateToProps)(GovOSPaymentContent);
