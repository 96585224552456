import React from 'react';
import LinkToNewTab from 'components/LinkToNewTab';
import styles from './styles.css';

export default class BrowserCard extends React.Component<any, any> {
  render() {
    const { company, imageURL, linkURL, name, recommended } = this.props;
    return (
      <LinkToNewTab href={linkURL} className={styles.browserCard}>
        {recommended && <span className={styles.recommended}>RECOMMENDED</span>}
        <img alt='' src={imageURL} className={styles.browserImage} />
        <span className={styles.browserName}>{name}</span>
        <span className={styles.browserCompany}>{company}</span>
      </LinkToNewTab>
    );
  }
}
