import * as types from 'constants/types/dataDictionaryActionTypes';
import api from 'reducers/api';

export type State = {
  isModalVisible: boolean,
};

const initialState = {
  isModalVisible: false,
};

export default (state: State = initialState, action): State => {
  switch (action.type) {
    case types.TOGGLE_MODAL:
      return {
        ...state,
        isModalVisible: !state.isModalVisible,
      };
    case types.SAVE_MAPPINGS:
      return api(action, state, {
        success: () => ({
          ...state,
          isModalVisible: false,
        }),
      });
    default:
      return state;
  }
};

export const isDataDictionarySidebarVisible = (state: State) => state.isModalVisible;
