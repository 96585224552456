import {Dir} from 'utils/vfs';
export const ASSIGNED_TO_ME_FOLDER_ID = 'VIRTUAL_FOLDER_ASSIGNED_TO_ME';
const ASSIGNED_TO_ME_NAME = 'Assigned to Me';

export const assignedToMePath = ['', ASSIGNED_TO_ME_NAME];
export const assignedToMe = new Dir(ASSIGNED_TO_ME_NAME, {collapsed: true, folderId: ASSIGNED_TO_ME_FOLDER_ID});
export const FOLDERS = [
  ASSIGNED_TO_ME_FOLDER_ID,
];
export const isInVirtualFolder = (folderId: string) => !!folderId && FOLDERS.includes(folderId);
