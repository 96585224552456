import React from 'react';
import Box from './Box';
import { DropTarget } from 'react-dnd';
import _ from 'lodash';
import dragDropContext from 'containers/dragDropContext';
import * as Colors from 'utils/colors';
import Icon from '@material-ui/core/Icon';
import { boxTarget, moveBoxHelper, handleHoverHelper, getColumnHeaderOffsetLeft } from './helper';


/*
 * Hardcoded TABLE_CELL_WIDTH; notice that it's of the most importance
 * that the cell width here always matches the actual table cell width
 */
import { ItemTypes, TABLE_CELL_WIDTH } from 'constants/tableConsts';

// This is from React DnD https://gaearon.github.io/react-dnd/

/*
 * Note: in case you're looking for a dragTarget function
 * required by React DnD -
 * (boxTarget) is refactored out to the TableSort/helper
 */
const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver({ shallow: true }),
});

// End of React DnD helper function definitions


const dragIconStyles = {
  color: Colors.black,
  paddingLeft: 8,
  fontSize: 34,
};

class TableSort extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      boxLeft: (parseInt(props.sortingTargetIndex, 10) * TABLE_CELL_WIDTH) - props.scrollLeft +
        getColumnHeaderOffsetLeft(props),
      hoverOverIndex: null,
      dragScrollingRight: false,
      dragScrollingLeft: false,
    };
    this.handleScrollOnDragThrottled = _.throttle(this.handleScrollOnDrag, 500);
    this.handleScrollOnDragStopDebounced = _.debounce(this.handleScrollOnDragStop, 500, { leading: true });
  }

  // this (boxLeft) puts the box in correct position when a column header is clicked
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      boxLeft: (parseInt(nextProps.sortingTargetIndex, 10) * TABLE_CELL_WIDTH) - nextProps.scrollLeft +
        getColumnHeaderOffsetLeft(this.props),
      hoverOverIndex: null,
      dragScrollingRight: false,
      dragScrollingLeft: false,
    });
  }

  // this prevents this component from re-rendering when not in view
  shouldComponentUpdate(nextProps) {
    return (nextProps.isTableSortable || this.props.isTableSortable) ? true : false;
  }

  // we dispatch column sorting action to Redux here
  // refactored to helper function for unit testing
  moveBox = nextIndex => {
    moveBoxHelper(nextIndex, this, getColumnHeaderOffsetLeft(this.props));
  };

  handleScrollOnDragThrottled;
  handleScrollOnDragStopDebounced;

  handleScrollOnDrag = (index, isScrollingRight) => {
    this.props.scrollOnDrag(index, isScrollingRight);
  };

  handleScrollOnDragStop = () => {
    this.props.scrollOnDragStop();
  };

  // this controls the target drop zone left border line (see Line 136)
  handleSetHoverIndex = index => {
    this.setState({ hoverOverIndex: index });
  };

  // *refactored to helper function for unit testing
  // this handles what's happening when box is in drag
  // we set drop zone index here
  // & activates scroll on drag when box dragged to the ends
  handleHover = (index, scrollOffsetIndex, deltaX) => {
    handleHoverHelper(index, scrollOffsetIndex, deltaX, this);
  };

  render() {
    const {
      connectDropTarget,
      isTableSortable,
      scrollLeft,
      headerLabel,
      sortingTargetIndex,
      source,
      tableSortTop,
      makeSortable,
    } = this.props;

    const {
      boxLeft,
      hoverOverIndex,
      dragScrollingLeft,
      dragScrollingRight,
    } = this.state;

    // This is the boxes that provide the left border line as drop zone guide lines
    const columnFrames = source.map((element, index) => {
      const columnFrameStyle = {
        width: TABLE_CELL_WIDTH,
        height: '100%',
        position: 'absolute',
        borderLeft: hoverOverIndex === index ? `4px solid ${Colors.black}` : '',
        left: (index * TABLE_CELL_WIDTH) - (scrollLeft % TABLE_CELL_WIDTH) + getColumnHeaderOffsetLeft(this.props),
        top: 0,
      };

      return (index < 10) ? <div key={element} style={columnFrameStyle as any} /> : null;
    });

    const tableSortStyle = {
      width: '100%',
      height: '54px',
      position: 'absolute',
      opacity: 0.3,
      zIndex: isTableSortable ? 10 : -10,
      top: tableSortTop,
    };

    const result = connectDropTarget(
      <div
        onClick={() => makeSortable(0)}
        style={tableSortStyle as any}>
        {columnFrames}
        <Box
          left={boxLeft}
          headerLabel={headerLabel}
          hideSourceOnDrag
          makeSortable={makeSortable}
          sortingTargetIndex={sortingTargetIndex} />
        {dragScrollingLeft && <Icon style={dragIconStyles}>
          arrow_back
        </Icon>}
        {dragScrollingRight && <Icon style={{ ...dragIconStyles, float: 'right' }}>
          arrow_forward
        </Icon>}
      </div>
    );
    return result === undefined ? null : result;
  }
}

export default dragDropContext(DropTarget(ItemTypes.BOX, boxTarget, collect)(TableSort));
