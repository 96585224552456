import {createContext} from 'react';


export const initialValues = {
  search: {
    value: '',
    onChange: null,
    onSubmit: null,
  },
  placeHolder: 'Search...',
  displayClearIcon: true,
  buttons: [],
};

export const initialState = {
  values: initialValues,
  resetToolbar: (): void => undefined,
  setSearchCallbacks: (): void => undefined,
  setSearchValue: (): void => undefined,
  setButtons: (): void => undefined,
};

const store = createContext(initialState);

export default store;
