import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { MAIN_SITE_URL } from 'constants/links';
import { LOBBY } from 'constants/route';
import { SUPPORT_EMAIL } from 'constants/emailAddresses';
import verticalErrorImage from 'images/errors/404/404-vertical.png';
import horizontalErrorImage from 'images/errors/404/404-horizontal.png';
import brandImage from 'images/brand-logo.svg';
import styles from './404.css';

interface Props {
  isSubdomainExists: boolean,
  errorTitle?: string,
  errorDescription?: string,
  horizontalErrorImage?: {
    src: any,
    alt: string,
    className: string,
  },
  verticalErrorImage?: {
    src: any,
    alt: string,
    className: string,
  },
}

class NotFoundPage extends React.PureComponent<Props, any> {
  render() {
    return (
      <main className={styles.pageContainer}>
        <nav className={styles.navigation}>
          <a href={MAIN_SITE_URL} target='_blank'>
            <img src={brandImage} alt='SeamlessDocs' className={styles.brandImg} />
          </a>
          <div className='help-btn-container'>
            <a href={`mailto:${SUPPORT_EMAIL}`} className={styles.helpBtn}>
              Help
            </a>
          </div>
        </nav>
        <section className={styles.errorInfoContainer}>
          <div className={styles.errorImgContainer}>
            <img
              className={classNames(
                styles.errorImg,
                styles.errorImg_vertical,
                this.props.verticalErrorImage?.className,
              )}
              src={this.props.verticalErrorImage?.src || verticalErrorImage}
              alt={this.props.verticalErrorImage?.alt || '404'}
            />
            <img
              className={classNames(
                styles.errorImg,
                styles.errorImg_horizontal,
                this.props.horizontalErrorImage?.className,
              )}
              src={this.props.horizontalErrorImage?.src || horizontalErrorImage}
              alt={this.props.horizontalErrorImage?.alt || '404'}
            />
          </div>
          <div className={styles.errorDescriptionContainer}>
            <h1 className={styles.errorTitle}>{this.props.errorTitle || 'Oops.'}</h1>
            <p className={styles.errorDescription}>{this.props.errorDescription || 'This page doesn’t exist or we can’t find it.'}</p>
            <a className={styles.goHomeBtn} href={!!this.props.isSubdomainExists ? LOBBY : MAIN_SITE_URL}>
              Go Home
            </a>
          </div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      isSubdomainExists,
    },
  } = state;

  return {
    isSubdomainExists,
  };
};

export default connect(mapStateToProps)(NotFoundPage);
