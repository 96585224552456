import React, { useMemo} from 'react';
import Select from 'react-select';

import { FormField, FormFields } from 'types/formFields';

const ALL_FIELDS_ID = '*';

const nonInputFieldTypes = new Set(['heading', 'sub_heading', 'spacer', 'other', 'rich_text', 'label']);
interface Props {
  fieldIds: string[],
  onFieldsChange: (fields: FormFields) => void,
  formFields: FormFields,
}

const FormFieldSelect = ({
  fieldIds = [],
  onFieldsChange,
  formFields = [],
}: Props) => {
  const internalFormFields: Omit<FormField, 'options'>[] = useMemo(() => {
    const decoded = formFields
      .filter(f => !nonInputFieldTypes.has(f.type))
      // The options property must be removed or react-select makes them selectable
      // https://github.com/JedWatson/react-select/issues/3706
      .map(({options, ...f}) => ({...f}));

    return [
      {id: ALL_FIELDS_ID, label: 'All Fields', type: ALL_FIELDS_ID, signerKey: null},
      ...decoded,
    ];
  },
  [formFields]);

  const selectedFields = internalFormFields.filter(f => fieldIds.includes(f.id));

  const onFieldsChanged = (s: FormFields) => {
    const allFieldsOption = (s || []).find(f => f.id === ALL_FIELDS_ID);
    onFieldsChange(allFieldsOption ? [allFieldsOption] : (s || []));
  };

  const getOptionValue = (o: FormField): string => o.id;
  const isOptionDisabled = (op: FormField): boolean =>
    op.id !== ALL_FIELDS_ID && !!selectedFields.find(o => o.id === ALL_FIELDS_ID);

  return (
    <Select
      placeholder='Select fields to include in email.'
      value={selectedFields}
      options={internalFormFields}
      getOptionValue={getOptionValue}
      isMulti
      closeMenuOnSelect={false}
      onChange={onFieldsChanged}
      isOptionDisabled={isOptionDisabled}
      isClearable
      isSearchable
    />
  );
};

export default FormFieldSelect;
