
import api from '../api';
import {
  ARCHIVE_FORM_API,
  CREATE_FOLDER_MODAL_VISIBILITY,
  DELETE_FORM,
  LOADER_VISIBILITY,
  RESET_SCROLL_TO_ROW,
  SCROLL_TO_ROW,
  SEARCHBAR_INPUT_CHANGE,
  SELECT_ROW,
  SET_FORM_DETAILS,
  SHOW_LOBBY_WITH_FORM,
  TOOLBAR_MOBILE_MENU_VISIBILITY,
} from 'constants/types/lobby';


const initialState = {
  createFolderOpen: false,
  loaderBody: '',
  loaderStatus: 'loading',
  loaderTitle: '',
  loaderVisibile: false,
  scrollToRow: null,
  searchbarInput: [],
  searchbarOpen: false,
  selectedRowData: null,
  selectedRowIndex: null,
  showLobbyWithFormId: null,
  toolbarMobileMenuVisible: false,
};

export default function lobby(state = initialState, action) {
  switch (action.type) {
    case LOADER_VISIBILITY:
      return {
        ...state,
        loaderVisibile: action.visibility,
        loaderStatus: action.status || '',
        loaderBody: action.body || '',
        loaderTitle: action.title || '',
      };
    case SEARCHBAR_INPUT_CHANGE:
      return {...state, searchbarInput: action.searchbarInput};
    case SHOW_LOBBY_WITH_FORM:
      return {...state, showLobbyWithFormId: action.formId};
    case SCROLL_TO_ROW:
      return {...state, scrollToRow: action.rowIndex};
    case RESET_SCROLL_TO_ROW:
      return {...state, scrollToRow: null};
    case SELECT_ROW:
      const {selectedRowData, selectedRowIndex} = action;
      return {
        ...state,
        selectedRowData,
        selectedRowIndex,
      };
    case CREATE_FOLDER_MODAL_VISIBILITY:
      return {...state, createFolderOpen: action.open};
    case SET_FORM_DETAILS:
      return state;
    case ARCHIVE_FORM_API:
    case DELETE_FORM:
      return api(action, state, {
        success: () => ({
          ...state,
          selectedRowData: null,
          selectedRowIndex: null,
        }),
      });
    case TOOLBAR_MOBILE_MENU_VISIBILITY:
      return {...state, toolbarMobileMenuVisible: action.toolbarMobileMenuVisible};
    default:
      return state;
  }
}

export const getSearchbarInput = state => state.searchbarInput;
