import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import Select from 'react-select';
import ReactInputDateMask from 'react-input-date-mask';

import { expectationFieldEnum, paymentStatuses, signatureStatuses } from 'constants/submissionFilters';
import { userRolesEnum } from 'constants/user';
import AssigneesSelect from 'containers/SubmissionsManager/Components/AssigneesSelect';
import TagsSelect from 'containers/SubmissionsManager/Components/TagsSelect';

import { getUserMap } from '../../../../reducers';
import { getTags } from 'reducers/tags';

import { Expectation, ExpectationField as ExpectationFieldType } from 'types/submissionsManager/submissionFilters';
import { Tag } from 'types/tags';
import { User } from 'types/users';

import { joinClassNames } from 'utils/strings';

import styles from './styles.css';
import { FieldOption, FieldOptions } from 'types/formFields';


export type Props = {
  inputType: ExpectationFieldType;
  toShow: boolean;
  expectation: Expectation | undefined;
  onChangeExpectation: (expectation: Expectation) => void;
  expectationFieldOptions: FieldOptions,
  tags: Tag[];
  users: User[],
};


export const ExpectationField = ({
  inputType = expectationFieldEnum.input,
  toShow = true,
  expectation = undefined,
  onChangeExpectation,
  expectationFieldOptions,
  tags,
  users,
}: Props) => {
  const selectedAssignees = useMemo(() => (inputType === expectationFieldEnum.assigneesInput)
    ? users.filter(user => user.email === expectation)
    : [], [inputType, expectation, users]);

  const selectedTags = useMemo(() => (inputType === expectationFieldEnum.tagsInput)
    ? tags.filter(t => t.name === expectation)
    : [], [inputType, tags, expectation]);

  const setSelectedAssignees = (assignees: User[]) => onChangeExpectation(assignees[0]?.email || '');
  const setSelectedTags = (ts: Tag[]) => onChangeExpectation(ts[0]?.name || '');

  const renderInputfield = () => {
    switch (inputType) {
      case expectationFieldEnum.input:
        return (<input
          className={joinClassNames(styles.rightColumn, styles.expectationInput)}
          onChange={e => onChangeExpectation(e.target.value)}
          value={expectation || ''}
          data-testid='expectationInput'
        />);
      case expectationFieldEnum.dateInput:
        return (<ReactInputDateMask
          mask='mm/dd/yyyy'
          onChange={e => onChangeExpectation(e)}
          className={joinClassNames(styles.rightColumn, styles.expectationInput)}
          data-testid='expectationDateInput'
        />);
      case expectationFieldEnum.tagsInput:
        return (<TagsSelect
          options={tags}
          selected={selectedTags}
          setSelected={setSelectedTags}
          createTag={() => {}}
          shouldCreateTag={false}
          data-testid='expectationTagInput'
          customClassName={styles.expectationFieldSelect}
        />);
      case expectationFieldEnum.assigneesInput:
        return (<AssigneesSelect
          // @ts-ignore
          options={users}
          selected={selectedAssignees}
          setSelected={setSelectedAssignees}
          data-testid='expectationAssigneeInput'
          // @ts-ignore
          customClassName={styles.expectationFieldSelect}
        />);
      case expectationFieldEnum.select:
        return (<div onClick={e => e.stopPropagation()}>
          <Select
            options={expectationFieldOptions}
            isSearchable
            className={styles.select}
            value={expectationFieldOptions.find((option: FieldOption) => option.id === expectation)}
            onChange={(option: FieldOption) => onChangeExpectation(option.id)}
          />
        </div>);
      case expectationFieldEnum.signatureStatusSelect:
        return (<div onClick={e => e.stopPropagation()}>
          <Select
            options={signatureStatuses}
            isSearchable
            className={styles.select}
            value={signatureStatuses.find(option => option.value === expectation)}
            onChange={option => onChangeExpectation(option.value)}
          />
        </div>);
      case expectationFieldEnum.paymentStatusSelect:
        return (<div onClick={e => e.stopPropagation()}>
          <Select
            options={paymentStatuses}
            isSearchable
            className={styles.select}
            value={paymentStatuses.find(option => option.value === expectation)}
            onChange={option => onChangeExpectation(option.value)}
          />
        </div>);
      default:
        return <select />;
    }
  };

  return toShow
    ? renderInputfield()
    : <div className={styles.rightColumn} data-testid='emptyExpectationField' />;
};

const mapStateToProps = state => {
  const users = getUserMap(state);
  const tags = getTags(state);

  return {
    tags: Object.keys(tags.tags).map(key => tags.tags[key]),
    users: Object.keys(users)
      .map(key => users[key])
      .filter(user => user.role !== userRolesEnum.VISITOR && user.isActive),
  };
};

export default connect(mapStateToProps)(ExpectationField);
