import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as tableApi from 'actions/submissionsManager/tableApi';
import NotificationLoaderModal from '../NotificationLoaderModal';

const BulkActionProcessingModal = ({
  isBulkProcessing,
  handleNotificationModalClose,
  actions: { toggleSnackbar },
  snackbarState,
  bulkProcessingState,
}) => {
  useEffect(() => {
    if (!isBulkProcessing && snackbarState.snackbarMessage) {
      toggleSnackbar(true, snackbarState.snackbarMessage);
    }
  }, [isBulkProcessing]);

  return (
    <NotificationLoaderModal
      title={bulkProcessingState.title}
      open={isBulkProcessing}
      status='bulkProcessing'
      bodyText={bulkProcessingState.bodyText}
      handleNotificationModalClose={handleNotificationModalClose}
    />
  );
};

const mapStateToProps = state => {
  const {
    submissionTable: { snackbarState },
    submissions: { bulkProcessingState },
  } = state;
  return {
    snackbarState,
    bulkProcessingState,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(tableApi, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionProcessingModal);
