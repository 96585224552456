import api from 'actions/api';
import {
  COMPLETE_COREGISTRATION,
  RESEND_INVITE,
} from 'constants/types/coregistration';
import * as actions from 'actions/auth';

// import type {
//   CompleteCoregistrationActionCreator,
//   ResendInviteActionCreator,
// } from 'types/coregistration';

export const completeCoregistration = (
  coregistrationData: any,
  isAutoLogin = false,
  subdomain: string,
  dispatch: Function,
) =>
  api.postWithCoregAuth({
    baseType: COMPLETE_COREGISTRATION,
    endpoint: 'coregistration/completeCoregistration.json.coreg',
    data: coregistrationData,
    success: (_, { response }) => {
      if (isAutoLogin) {
        dispatch(
          actions.requestUsernamePasswordLogin(
            coregistrationData.email,
            coregistrationData.password,
            subdomain,
            '/ng/',
            dispatch
          )
        );
      }

      return response;
    },
    failure: (_, error) => ({ error }),
  });

export const resendInvite = (data: any) =>
  api.postWithoutAuth({
    baseType: RESEND_INVITE,
    endpoint: 'coregistration/resendInvite.json',
    data,
    success: (_, { response }) => response,
    failure: (_, error) => ({ error }),
  });
