import {LOBBY} from 'constants/route';
import {LOG_SUBMISSION_NAVIGATION} from 'constants/types/routingActionTypes';
import {push} from 'react-router-redux';
// import type {
//   LogSubmissionNavigationAction,
//   SubmissionNavigationSource,
// } from 'types/routing';

export const logSubmissionNavigation = (source: any, submissionsCount: number) => ({
  payload: {
    source,
    submissionsCount,
  },
  type: LOG_SUBMISSION_NAVIGATION,
});

export const gotoLobby = () => push(LOBBY);
