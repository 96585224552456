import {convertToStructure} from './draft_to_api';
import {convertToEditorState} from './api_to_draft';

export const editorStateToStructure = (editorState: any) => {
  if (editorState == null) {
    return [];
  }
  return convertToStructure(editorState);
};

export const structureToEditorState = (structure: any[]) => {
  if (structure == null || structure.length === 0) {
    return null;
  }
  return convertToEditorState(structure);
};
