import React from 'react';
import style from 'containers/Login/Login/Login.css';
import { TERMS_OF_USE_URL } from 'constants/links';

const TermsOfUse = () => (
  <div className={style.termsContainer}>
    By creating an account, you agree to our{' '}
    <a
      className={style.termsLink}
      href={TERMS_OF_USE_URL}
      target='_blank'
    >
      Terms of Use
    </a>.
  </div>
);

export default TermsOfUse;
