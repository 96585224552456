// Modified to *NOT* parse emails from https://github.com/tasti/react-linkify/
import React from 'react';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

const linkify = new LinkifyIt();
linkify.tlds(tlds);
// this is the only line added to modify the original library
linkify.add('mailto:', null);


export default class Linkify extends React.Component<any, any> {
  static MATCH = 'LINKIFY_MATCH';

  parseCounter = 0;

  getMatches(string) {
    return linkify.match(string);
  }

  parseString(string) {
    const elements: any[] = [];
    if (string === '') {
      return elements;
    }

    const matches = this.getMatches(string);
    if (!matches) {
      return string;
    }

    let lastIndex = 0;
    let idx = 0;
    for (const match of matches) {
      // Push the preceding text if there is any
      if (match.index > lastIndex) {
        elements.push(string.substring(lastIndex, match.index));
      }
      // Shallow update values that specified the match
      const props = { href: match.url, key: `match${++idx}` };
      const properties = this.props.properties || {};
      for (const key in properties) {
        if (key) {
          let val = properties[key];
          if (val === Linkify.MATCH) {
            val = match.url;
          }

          props[key] = val;
        }
      }
      elements.push(React.createElement(
        this.props.component || 'a',
        props,
        match.text
      ));
      lastIndex = match.lastIndex;
    }

    if (lastIndex < string.length) {
      elements.push(string.substring(lastIndex));
    }

    return (elements.length === 1) ? elements[0] : elements;
  }

  parse(children) {
    let parsed = children;

    if (typeof children === 'string') {
      parsed = this.parseString(children);
    } else if (React.isValidElement(children) && (children.type !== 'a') && (children.type !== 'button')) {
      parsed = React.cloneElement(
        children,
        { key: `parse${++this.parseCounter}` },
        this.parse((children.props as any).children)
      );
    } else if (children instanceof Array) {
      parsed = children.map(child => this.parse(child));
    }

    return parsed;
  }

  render() {
    this.parseCounter = 0;
    const parsedChildren = this.parse(this.props.children);

    return <span className='Linkify'>{parsedChildren}</span>;
  }
}
