import React, { useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';

import { getAppliedSubmissionFilters, getSortingState, getFormFieldsForForm, getSubmissionManagerFormId } from 'reducers/index';
import {
  addFilter as addFilterAction,
  openFiltersModal as openFiltersModalAction,
  sortSubmissions as sortSubmissionsAction,
  unsortSubmissions as unsortSubmissionsAction,
} from 'actions/submissionsManager/tableApi';
import { joinClassNames } from 'utils/strings';
import { isColumnKeyEnabled, isColumnSortableAndFilterable } from 'utils/submissionsManager/submissionFilters';

import Dropdown, { Item } from 'components/Dropdown';
import DropdownOption from 'components/Dropdown/DropdownOption';

import icon17 from 'icons/icon17.svg';
import icon23 from 'icons/icon23.svg';
import filter from 'icons/submissions/filter.svg';

import styles from './SubmissionTable.css';

import { SubmissionFilter } from 'types/submissionsManager/submissionFilters';
import { getSelectedColumnType } from 'utils/submissionsManager/submissionFilters';
import { FormFields } from 'types/formFields';


type PropsFromState = {
  columnKeyBeingSorted: string | null,
  isAscending: boolean,
  appliedFilters: SubmissionFilter[],
  formFields: FormFields,
  isSortingAndFilteringEnabled: boolean,
};
type PropsFromDispatch = {
  addFilter: typeof addFilterAction,
  openFiltersModal: typeof openFiltersModalAction,
  sortSubmissions: typeof sortSubmissionsAction,
  unsortSubmissions: typeof unsortSubmissionsAction,
};
export type Props =
  & PropsFromState
  & PropsFromDispatch
  & {
  columnKey: string,
};

type DropdownItemProps = {
  data: { label: string },
  onClick?: () => void,
}


const DropdownItem = ({
  data,
  onClick,
}: DropdownItemProps) => (
  <DropdownOption onClick={onClick}>
    <div className={styles.dropdownOption}>{data ? data.label : 'Choose'}</div>
  </DropdownOption>
);

export const SortFilterDropdown = ({
  columnKey = '',
  columnKeyBeingSorted = null,
  isAscending = true,
  appliedFilters = [],
  formFields,
  isSortingAndFilteringEnabled = false,
  addFilter,
  openFiltersModal,
  sortSubmissions,
  unsortSubmissions,
}: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  const isSorted = useMemo(() => columnKeyBeingSorted === columnKey, [columnKeyBeingSorted, columnKey]);
  const isFiltered = useMemo(
    () => appliedFilters.some(f => f.columnKey === columnKey),
    [appliedFilters, columnKey],
  );

  const gethandleClick = (type: 'unsort' | 'sortAsc' | 'sortDesc' | 'filter' | 'showFilters') =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsOpened(false);
      switch (type) {
        case 'unsort':
          unsortSubmissions();
          break;
        case 'sortAsc':
          sortSubmissions(columnKey, true);
          break;
        case 'sortDesc':
          sortSubmissions(columnKey, false);
          break;
        case 'filter':
          addFilter(columnKey);
          break;
        case 'showFilters':
          openFiltersModal();
          break;
        default:
          break;
      }
      // Stopping event propagation to prevent triggering the outside click handler and closing the Filters modal
      e?.stopPropagation();
    };

  const getDropdownItem = (type: 'unsort' | 'sortAsc' | 'sortDesc' | 'filter') => {
    const onClick = gethandleClick(type);
    switch (type) {
      case 'unsort':
        return ({ data: { label: 'Unsort' }, onClick });
      case 'sortAsc':
        return ({
          data: { label: 'Sort by Asc' }, onClick });
      case 'sortDesc':
        return ({
          data: { label: 'Sort by Desc' }, onClick });
      case 'filter':
      default:
        return ({ data: { label: 'Filter' }, onClick });
    }
  };

  const items: Item<{ label: string }>[] = useMemo(() => {
    const options: any[] = [];
    if (isSortingAndFilteringEnabled) {
      if (isSorted) options.push(getDropdownItem('unsort'));
      options.push(getDropdownItem('sortAsc'));
      options.push(getDropdownItem('sortDesc'));
    }
    if (isSortingAndFilteringEnabled) {
      options.push(getDropdownItem('filter'));
    }
    return options.map(option => ({ props: option, Component: DropdownItem }));
  }, [isSorted]);

  const renderTriggerButton = useMemo(() => () => (
    <button className={joinClassNames(styles.sortFilterDropdownTrigger, isOpened && styles.sortFilterDropdownOpen)}>
      <img src={icon23} className={joinClassNames(styles.sortFilterIcon, styles.hamburgerIcon)} alt='Sort and filter' />
    </button>
  ), [isOpened]);

  const renderSortArrowButton = useMemo(() => (
    <button
      className={styles.sortFilterButton}
      onClick={isAscending ? gethandleClick('sortDesc') : gethandleClick('sortAsc')}
      aria-label={isAscending ? 'Switch from ascending to descending order' : 'Switch from descending to ascending order'}
      data-testid='sort-arrow-button'
    >
      <img
        src={icon17}
        alt={isAscending ? 'Sorted Ascending' : 'Sorted Descending'}
        className={joinClassNames(styles.sortFilterIcon, isAscending && styles.upArrowIcon)}
      />
    </button>
  ), [isAscending]);

  const renderFilterButton = useMemo(() => (
    <button
      className={styles.sortFilterButton}
      onClick={gethandleClick('showFilters')}
      aria-label='Filters'
      data-testid='filter-button'
    >
      <img src={filter} alt='Currently Filtered' className={styles.filterIcon} />
    </button>
  ), []);

  const selectedColumnType = useMemo(() => getSelectedColumnType(formFields, columnKey),
    [formFields, columnKey]);

  const isDropdownEnabled = useMemo<boolean>(() =>
    isSortingAndFilteringEnabled
    && isColumnKeyEnabled(columnKey)
    && isColumnSortableAndFilterable(selectedColumnType),
  [isSortingAndFilteringEnabled, columnKey, selectedColumnType]);
  if (!isDropdownEnabled) return null;

  // Stopping event propagation is needed to prevent triggering the column drag event
  return (
    <div onMouseDown={e => e.stopPropagation()} className={styles.sortFilterDropdownWrapper}>
      {isSorted && renderSortArrowButton}
      {isFiltered && renderFilterButton}
      <Dropdown
        isEnabled
        isOpen={isOpened}
        items={items}
        requestClose={() => setIsOpened(false)}
        requestOpen={() => setIsOpened(true)}
        renderTriggerButton={renderTriggerButton}
        tabIndex='-1'
      />
    </div>
  );
};

const mapStateToProps = (state): PropsFromState => ({
  columnKeyBeingSorted: getSortingState(state).columnKeyBeingSorted,
  isAscending: getSortingState(state).isAscending,
  appliedFilters: getAppliedSubmissionFilters(state),
  formFields: getFormFieldsForForm(state, getSubmissionManagerFormId(state)),
  isSortingAndFilteringEnabled: selectTreatmentValue(state.splitio, 'SORT_AND_FILTER_SUBMISSION_MANAGER') === 'on',
});

const mapDispatchToProps = (dispatch): PropsFromDispatch => ({
  addFilter: bindActionCreators(addFilterAction, dispatch),
  openFiltersModal: bindActionCreators(openFiltersModalAction, dispatch),
  sortSubmissions: bindActionCreators(sortSubmissionsAction, dispatch),
  unsortSubmissions: bindActionCreators(unsortSubmissionsAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SortFilterDropdown);
