import React, {useState} from 'react';
import { connect } from 'react-redux';
import AssigneesSelect from 'containers/SubmissionsManager/Components/AssigneesSelect';
import styles from 'containers/SubmissionsManager/Modals/BulkActionWrapper.css';

const FindAndReplaceAssignees = ({
  submissionIds,
  findAndReplaceAssignees,
  dispatch,
  options = [],
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  loaderModalToggle,
  errorModalToggle,
}) => {
  const [findValue, setFindValue] = useState<any[]>([]);
  const [replaceValue, setReplaceValue] = useState<any[]>([]);

  const handleReplaceAssignees = () => {
    findAndReplaceAssignees({
      assigneeToFind: findValue[0]?.userId,
      assigneeToReplace: replaceValue[0]?.userId,
      submissionIds,
      formId,
      includeAllSubmissions,
      excludedSubmissionIds,
      onError: () => errorModalToggle(true),
    }, dispatch);
  };

  const onReplaceClick = () => {
    const submissionsNumber = includeAllSubmissions ? 'all the' : `the ${submissionIds.length} selected`;
    loaderModalToggle(
      true,
      'fnrAssignees',
      'Find and Replace Assignee',
      `Are you sure you want to conduct a find and replace ${submissionsNumber} submission(s)?`,
      handleReplaceAssignees,
    );
  };

  return (<div className={styles.fnrContainer}>
    <div className={styles.InputWrapper}>
      <label htmlFor='find'>Find</label>
      <AssigneesSelect options={options} selected={findValue} setSelected={setFindValue}/>
    </div>
    <div className={styles.InputWrapper}>
      <label htmlFor='replace'>Replace</label>
      <AssigneesSelect options={options} selected={replaceValue} setSelected={setReplaceValue}/>
    </div>
    <button type='button' className={styles.replaceButton} onClick={onReplaceClick} disabled={!(findValue.length && replaceValue.length)}>Replace</button>
  </div>);
};

export default connect(null, dispatch => ({
  dispatch,
}))(FindAndReplaceAssignees);
