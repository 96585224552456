import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce, every } from 'lodash';
import { checkFormCapability, getAppliedSubmissionFilters, getFeatureState, getIsSubmissionFilterApplied, getSelectedSubmissionRow, getSortedFilteredColumns, getUserCapabilitiesLoadingState } from '../../reducers';
import injectPermissions from 'decorators/injectPermissions';
import { withNavBarContext } from 'components/OneNavBar';
import DownloadModal from 'components/Modals/DownloadModal';
import FormDetailsDropdown from 'components/Toolbar/FormDetailsDropdown';
import { setApbVisibility } from 'actions/automatedProcessBuilder';
import * as submissionsActions from 'actions/submissionsManager/submissionsActions';
import * as tableApi from 'actions/submissionsManager/tableApi';
import { logSubmissionNavigation } from 'actions/routing';
import PermissionsModal from 'containers/Permissions';
import FormInfo from 'components/OneNavBar/FormInfo';
import { initializePermissions, toggleModal } from 'actions/permissions';
import * as applicationCopy from 'constants/applicationCopy';
import { formCapabilitiesEnum } from 'constants/tableConsts';
import { userRolesEnum } from 'constants/user';
import { bulkActionTypes, submissionManagerPermissions, bulkActions } from 'constants/submissionManager';
import * as search from 'utils/search';
import { getSelectedRecords, isSupportedWithSelectAllAndFiltering } from 'utils/submissionsManager/tableHelper';
import { textWithEllipsis } from 'utils/textHelper';
import * as routing from 'utils/routing';
import Icon from '@material-ui/core/Icon';
import Toolbar from 'components/Toolbar';
import { MenuItem } from 'components/Toolbar/RightNav';
import * as route from 'constants/route';
import * as Colors from 'utils/colors';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import ShareSubmissionsModal from 'containers/SubmissionsManager/Modals/ShareSubmissions';
import { navBarSearchInputChange } from 'actions/navBarSearch';

const propsToCompareToolbarUpdate = [
  'selectedRecords', 'selectedRowData', 'selectedFilesCount',
  'sidebarOpen', 'formDetails', 'features', 'userCapabilitiesLoaded',
  'canUserEditForm', 'canUserEditPermissions', 'isStagesBulkActionsDropdownVisible',
  'isAssigneesBulkActionsDropdownVisible', 'isTagsBulkActionsDropdownVisible',
];

const isReadOnly = (records, capability) => !records.find(record =>
  !record.isIncomplete && record.permissions && record.permissions.includes(capability));

class ManagerControlBar extends React.Component<any, any> {
  componentDidMount() {
    this.props.navBarContext.resetToolbar();
  }

  componentDidUpdate(prevProps) {
    // Check if any of the specified props have changed
    const hasToolbarPropsChanged = propsToCompareToolbarUpdate.some(
      prop => prevProps[prop] !== this.props[prop],
    );

    if (hasToolbarPropsChanged) {
      this.updateToolbarIcons();
    }

    // Set search callbacks if not already set and not in archive view
    const shouldSetSearchCallbacks =
      !this.props.navBarContext.values.search.onChange &&
      !this.props.isArchiveView;

    if (shouldSetSearchCallbacks) {
      const handleInputChange = value =>
        this.handleSearchbarInputChange(search.parse(value));
      this.props.navBarContext.setSearchCallbacks({
        onChange: handleInputChange,
      });
    }

    // Handle search if search inputs have changed
    const currentSearchInput = this.props.navBarSearch?.searchBarInput;
    const previousSearchInput = prevProps.navBarSearch?.searchBarInput;

    const isSearchInputChanged =
      currentSearchInput &&
      previousSearchInput &&
      !search.parsedIsEquivalent(currentSearchInput, previousSearchInput);

    if (isSearchInputChanged) {
      this.handleSearch(currentSearchInput, previousSearchInput);
    }
  }

  openBulkActionModal = (modalType, entityType) => {
    if (modalType === bulkActions.Remove) {
      // Bulk removal actions are handled by a different modal than other bulk actions
      if (entityType === bulkActionTypes.Stages) {
        this.openRemoveStagesBulkActionModal();
      }
      if (entityType === bulkActionTypes.Assignees) {
        this.openRemoveAssigneesBulkActionModal();
      }
      if (entityType === bulkActionTypes.Tags) {
        this.openRemoveTagsBulkActionModal();
      }
    } else {
      this.props.setBulkActionState({ isOpened: true, modalType, entityType });
    }
    this.closeBulkActionDropdowns();
  };

  closeBulkActionDropdowns = () => {
    const {
      isStagesBulkActionsDropdownVisible,
      isAssigneesBulkActionsDropdownVisible,
      isTagsBulkActionsDropdownVisible,
    } = this.props;

    if (isStagesBulkActionsDropdownVisible) {
      this.toggleStagesDropdown();
    }
    if (isAssigneesBulkActionsDropdownVisible) {
      this.toggleAssigneesDropdown();
    }
    if (isTagsBulkActionsDropdownVisible) {
      this.toggleTagsDropdown();
    }
  };

  stagesDropdownOptions = [
    {
      label: 'Move to stage...',
      onClick: () =>
        this.openBulkActionModal(bulkActions.Add, bulkActionTypes.Stages),
    },
    {
      label: 'Remove existing stages',
      onClick: () =>
        this.openBulkActionModal(bulkActions.Remove, bulkActionTypes.Stages),
    },
    {
      label: 'Find and replace...',
      onClick: () =>
        this.openBulkActionModal(
          bulkActions.FindAndReplace,
          bulkActionTypes.Stages,
        ),
    },
  ];

  assigneesDropdownOptions = [
    {
      label: 'Add assignees...',
      onClick: () =>
        this.openBulkActionModal(bulkActions.Add, bulkActionTypes.Assignees),
    },
    {
      label: 'Replace existing assignees...',
      onClick: () =>
        this.openBulkActionModal(
          bulkActions.Replace,
          bulkActionTypes.Assignees,
        ),
    },
    {
      label: 'Remove existing assignees',
      onClick: () =>
        this.openBulkActionModal(bulkActions.Remove, bulkActionTypes.Assignees),
    },
    {
      label: 'Find and replace...',
      onClick: () =>
        this.openBulkActionModal(
          bulkActions.FindAndReplace,
          bulkActionTypes.Assignees,
        ),
    },
  ];

  tagsDropdownOptions = [
    {
      label: 'Add tags...',
      onClick: () =>
        this.openBulkActionModal(bulkActions.Add, bulkActionTypes.Tags),
    },
    {
      label: 'Replace existing tags...',
      onClick: () =>
        this.openBulkActionModal(bulkActions.Replace, bulkActionTypes.Tags),
    },
    {
      label: 'Remove existing tags',
      onClick: () =>
        this.openBulkActionModal(bulkActions.Remove, bulkActionTypes.Tags),
    },
    {
      label: 'Find and replace...',
      onClick: () =>
        this.openBulkActionModal(
          bulkActions.FindAndReplace,
          bulkActionTypes.Tags,
        ),
    },
  ];

  updateToolbarIcons() {
    const {
      accountHasGRM,
      canUserAccessGRM,
      canUserEditForm,
      canUserEditPermissions,
      canUserExportSubmissions,
      canUserArchiveSubmissions,
      features,
      formDetails,
      isUserVisitor,
      selectedFilesCount,
      sidebarOpen,
      isArchiveView,
      navBarContext: { setButtons },
      totalSubmissions,
      deletedCount,
      isStagesBulkActionsDropdownVisible,
      isAssigneesBulkActionsDropdownVisible,
      isTagsBulkActionsDropdownVisible,
      selectedRecords,
      isNgLiveViewEnabled,
      isAllSelected,
      isSubmissionFilterApplied,
    } = this.props;

    if (!formDetails) return;
    const { alias, authorName, id, itemType } = formDetails;

    const buttons: any[] = [];

    if (selectedFilesCount > 0 && !isArchiveView) {
      if (
        features.STAGES_IN_SUBMISSION_MANAGER_SUPPORT &&
        !isReadOnly(
          selectedRecords,
          submissionManagerPermissions.canEditStages,
        ) &&
        isSupportedWithSelectAllAndFiltering(
          'stages',
          isAllSelected,
          isSubmissionFilterApplied,
        )
      ) {
        buttons.push({
          label: 'Stages',
          icon: 'stages',
          dropdownOptions: this.stagesDropdownOptions,
          isVisible: isStagesBulkActionsDropdownVisible,
          onClick: this.toggleStagesDropdown,
        });
      }
      if (
        !isReadOnly(
          selectedRecords,
          submissionManagerPermissions.canEditTags,
        ) &&
        isSupportedWithSelectAllAndFiltering(
          'tags',
          isAllSelected,
          isSubmissionFilterApplied,
        )
      ) {
        buttons.push({
          label: 'Tags',
          icon: 'tags',
          dropdownOptions: this.tagsDropdownOptions,
          isVisible: isTagsBulkActionsDropdownVisible,
          onClick: this.toggleTagsDropdown,
        });
      }
      if (
        !isReadOnly(
          selectedRecords,
          submissionManagerPermissions.canEditAssignees,
        ) &&
        isSupportedWithSelectAllAndFiltering(
          'assignees',
          isAllSelected,
          isSubmissionFilterApplied,
        )
      ) {
        buttons.push({
          label: 'Assignees',
          icon: 'assignees',
          dropdownOptions: this.assigneesDropdownOptions,
          isVisible: isAssigneesBulkActionsDropdownVisible,
          onClick: this.toggleAssigneesDropdown,
        });
      }
    }

    if (
      canUserExportSubmissions() &&
      (sidebarOpen || selectedFilesCount > 0) &&
      !isArchiveView &&
      isSupportedWithSelectAllAndFiltering(
        'export',
        isAllSelected,
        isSubmissionFilterApplied,
      )
    ) {
      buttons.push({
        label: 'Export',
        icon: 'download',
        onClick: this.handleDownloadButtonOnClick,
      });
    }

    if (
      selectedFilesCount > 0 &&
      !isArchiveView &&
      !isReadOnly(
        selectedRecords,
        submissionManagerPermissions.canShareSubmissions,
      ) &&
      isSupportedWithSelectAllAndFiltering(
        'share',
        isAllSelected,
        isSubmissionFilterApplied,
      )
    ) {
      buttons.push({
        label: 'Share',
        icon: 'share',
        onClick: this.handleShareSubmissionsClick,
      });
    }

    if (
      canUserArchiveSubmissions &&
      (sidebarOpen || selectedFilesCount > 0) &&
      !isArchiveView &&
      isSupportedWithSelectAllAndFiltering(
        'archive',
        isAllSelected,
        isSubmissionFilterApplied,
      )
    ) {
      buttons.push({
        label: 'Archive',
        icon: 'archiveBox',
        onClick: this.onArchiveIconClick,
      });
    }

    if (
      !isUserVisitor &&
      (sidebarOpen || selectedFilesCount > 0) &&
      isArchiveView
    ) {
      buttons.push({
        label: 'Restore',
        icon: 'restore',
        onClick: this.onRestoreIconClick,
      });
    }

    if (
      this.shouldShowDeleteButton() &&
      ((!isArchiveView &&
        isSupportedWithSelectAllAndFiltering(
          'delete',
          isAllSelected,
          isSubmissionFilterApplied,
        )) ||
        isArchiveView)
    ) {
      buttons.push({
        label: 'Delete',
        icon: 'trash',
        onClick: this.onDeleteIconClick,
      });
    }

    if (!isUserVisitor && !isArchiveView && selectedFilesCount === 0) {
      buttons.push({
        label: 'Info',
        content: <FormInfo form={formDetails} />,
        icon: 'info',
      });

      const viewUrl = routing.getLiveViewUrl(formDetails, isNgLiveViewEnabled);
      buttons.push({ label: 'View Form', icon: 'view', url: viewUrl });
    }

    if (
      canUserEditForm &&
      !!authorName &&
      !isArchiveView &&
      selectedFilesCount === 0
    ) {
      const editUrl = routing.formatLegacyEditFormUrl({
        formId: id,
        itemType,
        alias,
      });
      buttons.push({ label: 'Edit Form', icon: 'edit', url: editUrl });
    }

    if (canUserEditPermissions && !isArchiveView && selectedFilesCount === 0) {
      buttons.push({
        label: 'Permissions',
        icon: 'permissions',
        onClick: this.handleOpenPermissionsModal,
      });
    }

    if (
      canUserEditForm &&
      features.AUTOMATED_PROCESS_BUILDER_SUPPORT &&
      !isArchiveView &&
      selectedFilesCount === 0
    ) {
      buttons.push({
        label: 'Triggers',
        icon: 'lightning',
        onClick: this.handleApbButtonClick,
      });
    }

    if (
      accountHasGRM &&
      canUserAccessGRM() &&
      !isArchiveView &&
      (totalSubmissions || deletedCount) &&
      selectedFilesCount === 0
    ) {
      buttons.push({ label: 'GRM', icon: 'grm', url: routing.grmPipeline(id) });
    }

    setButtons(buttons.length ? [buttons] : null);
  }

  shouldShowDeleteButton() {
    const {
      canUserDeleteSubmissions,
      selectedRecords,
      selectedRowData,
      selectedFilesCount,
      sidebarOpen,
    } = this.props;
    if (!canUserDeleteSubmissions()) return false;
    const recordsToCheck = selectedRowData
      ? getSelectedRecords(selectedRowData, selectedRecords)
      : selectedRecords;
    const canArchiveAllSelectedRecords = every(
      recordsToCheck,
      record =>
        !!(
          record.permissions &&
          record.permissions.includes(submissionManagerPermissions.canArchive)
        ),
    );
    return (
      canArchiveAllSelectedRecords && (sidebarOpen || selectedFilesCount > 0)
    );
  }

  renderTitleContent = () => {
    const { formDetails, isMobile, isPhone } = this.props;
    const formName = formDetails ? formDetails.name : '';
    let formNameLength;
    if (isMobile) {
      formNameLength = isPhone ? 12 : 50;
    } else {
      formNameLength = 70;
    }
    return (
      <>
        {textWithEllipsis(formName, formNameLength)}
        {formDetails && (
          <FormDetailsDropdown
            formDetails={formDetails}
            canUserEditForm={this.props.canUserEditForm}
          />
        )}
      </>
    );
  };

  toggleStagesDropdown = () => {
    this.props.toggleDropdown(bulkActionTypes.Stages);
  };

  toggleAssigneesDropdown = () => {
    this.props.toggleDropdown(bulkActionTypes.Assignees);
  };

  toggleTagsDropdown = () => {
    this.props.toggleDropdown(bulkActionTypes.Tags);
  };

  openRemoveStagesBulkActionModal = () => {
    const {
      bulkActionErrorModalToggle,
      dispatch,
      loaderModalToggle,
      selectedFilesCount,
      isAllSelected,
      excludedSubmissionIds,
      submissionIds,
      removeStages,
      formDetails,
    } = this.props;
    const submissionsNumber = isAllSelected
      ? 'all the'
      : `the ${selectedFilesCount} selected`;
    loaderModalToggle(
      true,
      'deleteStagesBulk',
      'Remove Existing Stages',
      `Are you sure you want to remove the stages from ${submissionsNumber} submission(s)?`,
      () =>
        removeStages({
          submissionIds,
          dispatch,
          formId: formDetails.id,
          includeAllSubmissions: isAllSelected,
          excludedSubmissionIds,
          onError: () => bulkActionErrorModalToggle(true),
        }),
    );
  };

  openRemoveTagsBulkActionModal = () => {
    const {
      bulkActionErrorModalToggle,
      dispatch,
      loaderModalToggle,
      selectedFilesCount,
      isAllSelected,
      excludedSubmissionIds,
      submissionIds,
      formDetails,
      removeTags,
    } = this.props;
    const submissionsNumber = isAllSelected
      ? 'all the'
      : `the ${selectedFilesCount} selected`;
    loaderModalToggle(
      true,
      'deleteTagsBulk',
      'Remove Existing Tags',
      `Are you sure you want to remove the tags from ${submissionsNumber} submission(s)?`,
      () =>
        removeTags({
          submissionIds,
          formId: formDetails.id,
          dispatch,
          includeAllSubmissions: isAllSelected,
          excludedSubmissionIds,
          onError: () => bulkActionErrorModalToggle(true),
        }),
    );
  };

  openRemoveAssigneesBulkActionModal = () => {
    const {
      bulkActionErrorModalToggle,
      dispatch,
      loaderModalToggle,
      selectedFilesCount,
      isAllSelected,
      excludedSubmissionIds,
      submissionIds,
      removeAssignees,
      formDetails,
    } = this.props;
    const submissionsNumber = isAllSelected
      ? 'all the'
      : `the ${selectedFilesCount} selected`;
    loaderModalToggle(
      true,
      'deleteAssigneesBulk',
      'Remove Existing Assignees',
      `Are you sure you want to remove the assignees from ${submissionsNumber} submission(s)?`,
      () =>
        removeAssignees({
          submissionIds,
          dispatch,
          formId: formDetails.id,
          includeAllSubmissions: isAllSelected,
          excludedSubmissionIds,
          unassignAllUsers: true,
          onError: () => bulkActionErrorModalToggle(true),
        }),
    );
  };

  onToolbarMobileMenuToggle = () => {
    this.props.toolbarMobileMenuVisibility(
      !this.props.toolbarMobileMenuVisible,
    );
  };

  onDeleteIconClick = () => {
    const {
      selectedFilesCount,
      loaderModalToggle,
      toolbarMobileMenuVisibility,
    } = this.props;
    if (selectedFilesCount < 2) {
      loaderModalToggle(
        true,
        'deleteSubmission',
        applicationCopy.DELETE_SM_RECORD_TITLE,
        applicationCopy.DELETE_SM_RECORD_BODY,
      );
    } else {
      loaderModalToggle(
        true,
        'deleteSubmission',
        applicationCopy.DELETE_SM_RECORD_TITLE_PLURAL,
        applicationCopy.DELETE_SM_RECORD_BODY_PLURAL,
      );
    }
    toolbarMobileMenuVisibility(false);
  };

  onArchiveIconClick = () => {
    const { loaderModalToggle } = this.props;
    loaderModalToggle(
      true,
      'archiveSubmission',
      'Archive Submission',
      'Are you sure you want to archive the selected submission(s)?',
    );
  };

  onRestoreIconClick = () => {
    const { loaderModalToggle } = this.props;
    loaderModalToggle(
      true,
      'restoreSubmission',
      'Restore Submission',
      'Are you sure you want to restore the selected submission(s)?',
    );
  };

  handleOpenPermissionsModal = () => {
    const { formDetails, permissions } = this.props;
    if (!formDetails) return;
    const { id, pdfId } = formDetails;
    this.props.initializePermissions({ formId: id, isPdf: !!pdfId });
    this.props.toggleModal(!permissions.modalOpen);
  };

  handleApbButtonClick = () => {
    this.props.setApbVisibility(true);
    this.props.toolbarMobileMenuVisibility(false);
  };

  handleDownloadOnCancelClick = () => {
    this.props.downloadVisibility(false);
  };

  handleDownloadButtonOnClick = () => {
    this.props.downloadVisibility(true);
    this.props.toolbarMobileMenuVisibility(false);
  };

  handleShareSubmissionsClick = () => {
    this.props.shareVisibility(true);
  };

  handleDownloadOnClick = payload => {
    const {
      downloadVisibility,
      downloadVisible,
      exportRecipientEmail,
      exportSubmissions,
      formDetails,
      selectedRecords,
      selectedRowData,
      sortedFilteredColumns,
      submissionFilters,
    } = this.props;

    const {
      isExportingAll,
      recipientEmail,
      withAttachments,
      withCsvData,
      withFullyRenderedPdfs,
      withAccessCode,
      exportCsvOptions,
    } = payload;

    const exportParams = {
      formId: formDetails ? formDetails.id : '',
      withAttachments,
      withFullyRenderedPdfs,
      withAccessCode,
      withCsvData,
      exportCsvOptions,
      isExportingAll,
      recipientEmail: recipientEmail
        ? recipientEmail
        : exportRecipientEmail || '',
      zipEncryptionPassphrase: '',
    };
    downloadVisibility(!downloadVisible);
    exportSubmissions(
      selectedRowData,
      selectedRecords,
      exportParams,
      sortedFilteredColumns,
      submissionFilters,
    );
  };

  handleSearchbarInputChange = (
    input: { start: number; end: number; type: string; value: string }[],
  ): void => {
    this.props.navBarSearchInputChange(input);
  };

  //  debounce search to avoid extra calls
  handleSearch = debounce((input, previousInput, force = false) => {
    const {
      formID,
      hideSidebar,
      searchSubmissionsInitial,
      selectRow,
      useCachedTable: exeCachedTable, // eslint thinks useCachedTable is a hook
    } = this.props;

    if (force || !search.parsedIsEquivalent(input, previousInput)) {
      selectRow(null);
      hideSidebar();
      const query = search.parsedToString(input);
      if (query !== '') {
        searchSubmissionsInitial(formID, query);
      } else {
        exeCachedTable();
      }
    }
  }, 500);

  render() {
    const {
      cachedTotalSubmissions,
      canUserExportSubmissions,
      downloadVisible,
      selectedFilesCount,
      selectedRowData,
      sidebarOpen,
      totalSubmissions,
      totalSubmissionsForFilter,
      submissionFilters,
      canUserEditForm,
      features: { AUTOMATED_PROCESS_BUILDER_SUPPORT },
      isMobile,
      isAllSelected,
      submissionIds,
      searchbarInput,
      searchbarOpen,
      toolbarMobileMenuVisible,
      windowWidth,
      maid,
    } = this.props;

    const selectedRowCount =
      (selectedRowData || {}).submissionId && sidebarOpen ? 1 : 0;
    const downloadFilesCount =
      selectedFilesCount > 0 ? selectedFilesCount : selectedRowCount;

    const showDeleteButton = this.shouldShowDeleteButton();
    const showExportButton = canUserExportSubmissions();
    const showApbButton = canUserEditForm && AUTOMATED_PROCESS_BUILDER_SUPPORT;
    const { deployment } = routing;
    return (
      <div>
        {canUserExportSubmissions() && (
          <DownloadModal
            cachedTotalSubmissions={cachedTotalSubmissions}
            downloadOptions={this.props.downloadOptions}
            onCancelClick={this.handleDownloadOnCancelClick}
            onOkClick={this.handleDownloadOnClick}
            open={downloadVisible}
            selectedFilesCount={downloadFilesCount}
            totalSubmissions={totalSubmissions}
            totalSubmissionsForFilter={totalSubmissionsForFilter}
            isAllSelected={isAllSelected}
            submissionFilters={submissionFilters}
          />
        )}

        <ShareSubmissionsModal
          submissionIds={submissionIds}
          isAllSelected={isAllSelected}
          selectedFilesCount={downloadFilesCount}
          totalSubmissions={totalSubmissions}
        />

        <SplitTreatments names={['ONE_NAV']} attributes={{ maid, deployment }}>
          {({ treatments: { ONE_NAV }, isReady }) => {
            if (!isReady || ONE_NAV.treatment === 'on') {
              return null;
            }

            return (
              <Toolbar
                backIcon={<Icon>arrow_back</Icon>}
                backIconBackgroundColor={Colors.orange500}
                backIconLink={route.LOBBY}
                isMobile={isMobile}
                onToolbarMobileMenuToggle={this.onToolbarMobileMenuToggle}
                searchbarInput={searchbarInput}
                searchbarInputChange={this.handleSearchbarInputChange}
                searchbarOpen={searchbarOpen}
                shouldShowSearchbar
                showDeleteButton={showDeleteButton}
                titleContent={this.renderTitleContent()}
                toolbarMobileMenuVisible={toolbarMobileMenuVisible}
                windowWidth={windowWidth}
              >
                {showApbButton && (
                  <MenuItem
                    icon='flash_on'
                    key='apb'
                    onClick={this.handleApbButtonClick}
                    text='Triggers'
                    windowWidth={windowWidth}
                  />
                )}
                {showExportButton && (
                  <MenuItem
                    icon='file_download'
                    key='export'
                    onClick={this.handleDownloadButtonOnClick}
                    text='Export'
                    textIsMobileOnly
                    windowWidth={windowWidth}
                  />
                )}
                {showDeleteButton && (
                  <MenuItem
                    icon={'delete' as any}
                    key='delete'
                    onClick={this.onDeleteIconClick}
                    text='Delete'
                    windowWidth={windowWidth}
                  />
                )}
              </Toolbar>
            );
          }}
        </SplitTreatments>

        <PermissionsModal />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  const {
    searchSubmissionsInitial,
    useCachedTable,
    exportSubmissions,
    toggleDropdown,
    setBulkActionState,
    removeStages,
    removeTags,
    removeAssignees,
  } = tableApi;
  return bindActionCreators(
    {
      ...submissionsActions,
      navBarSearchInputChange,
      initializePermissions,
      toggleModal,
      searchSubmissionsInitial,
      useCachedTable,
      exportSubmissions,
      logSubmissionNavigation,
      setApbVisibility,
      toggleDropdown,
      setBulkActionState,
      removeStages,
      removeTags,
      removeAssignees,
    },
    dispatch,
  );
};

const mapStateToProps = (state, { formID }) => {
  const {
    auth: { profile, role },
    features: { GRM_WITH_SC, GRM_WITHOUT_SC, WEBFORM_NG_LIVE_VIEW },
    submissionTable: {
      cachedTotalSubmissions,
      formDetails,
      selectedRecords,
      totalSubmissions,
      totalSubmissionsForFilter,
      isAllSelected,
      archivedCount,
      deletedCount,
      isStagesBulkActionsDropdownVisible,
      isAssigneesBulkActionsDropdownVisible,
      isTagsBulkActionsDropdownVisible,
      table: { records },
    },
    submissions: {
      downloadOptions,
      downloadVisible,
      searchbarInput,
      searchbarOpen,
      sidebarOpen,
      toolbarMobileMenuVisible,
    },
    folderNavigation: { currentNode },
    navBarSearch,
    permissions,
  } = state;

  const submissionIds = selectedRecords.map(s => s.submissionId);

  return {
    accountHasGRM: GRM_WITH_SC || GRM_WITHOUT_SC,
    cachedTotalSubmissions,
    canUserEditForm: checkFormCapability(
      state,
      formID,
      formCapabilitiesEnum.Edit,
    ),
    canUserEditPermissions: checkFormCapability(
      state,
      formID,
      formCapabilitiesEnum.EditPermissions,
    ),
    canUserArchiveSubmissions: checkFormCapability(
      state,
      formID,
      formCapabilitiesEnum.ArchiveSubmissions,
    ),
    folderId: currentNode.meta.folderId,
    downloadOptions,
    downloadVisible,
    exportRecipientEmail: profile && profile.email,
    isUserVisitor: !!role && role === userRolesEnum.VISITOR,
    isAllSelected,
    features: getFeatureState(state),
    formDetails,
    permissions,
    searchbarInput,
    searchbarOpen,
    selectedFilesCount: selectedRecords.length,
    selectedRecords,
    selectedRowData: getSelectedSubmissionRow(state),
    submissionIds,
    sidebarOpen,
    sortedFilteredColumns: getSortedFilteredColumns(state),
    toolbarMobileMenuVisible,
    totalSubmissions,
    totalSubmissionsForFilter,
    userCapabilitiesLoaded: !getUserCapabilitiesLoadingState(state),
    maid: state.auth.profile && state.auth.profile.maid,
    archivedCount,
    deletedCount,
    isStagesBulkActionsDropdownVisible,
    isAssigneesBulkActionsDropdownVisible,
    isTagsBulkActionsDropdownVisible,
    excludedSubmissionIds: records
      .map(record => record.submissionId)
      .filter(id => !submissionIds.includes(id)),
    isNgLiveViewEnabled: WEBFORM_NG_LIVE_VIEW,
    submissionFilters: getAppliedSubmissionFilters(state),
    isSubmissionFilterApplied: getIsSubmissionFilterApplied(state),
    navBarSearch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withNavBarContext(injectPermissions(ManagerControlBar))
);
