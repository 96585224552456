import React from 'react';
import * as Colors from 'utils/colors';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import styles from './modalShared.css';


export default class Modal extends React.Component<any, any> {
  static defaultProps = {
    disableBackdropClick: false,
  };

  render() {
    const {
      title,
      titleIcon,
      titleSVGIcon,
      actions,
      disableBackdropClick,
      open,
      onClose,
      desktopWidth,
      children,
      hideTitle,
      footerInlineStyle,
      toolbarInlineStyle,
      headerInlineStyle,
      style,
    } = this.props;
    const modalStyle = { maxWidth: desktopWidth, width: '100%', ...style };
    const headerIconStyle = {
      paddingLeft: '16px',
      marginRight: '16px',
      cursor: 'default',
      color: 'rgba(0, 0, 0, 0.4)',
    };
    const headerStyle = { fontSize: '16px', color: Colors.textPrimary, fontWeight: 400, ...headerInlineStyle };
    const footerStyle = {
      display: 'flex',
      justifyContent: 'flex-end',
      ...footerInlineStyle,
    };
    const toolbarGroupStyle = {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-16px',
      backgroundColor: Colors.grey150,
      ...toolbarInlineStyle,
    };
    const toolbarStyle = {
      padding: '0 16px',
      backgroundColor: Colors.grey150,
      minHeight: '56px',
      height: '0',
      ...toolbarInlineStyle,
    };

    const header = (
      <Toolbar style={toolbarStyle}>
        <div style={toolbarGroupStyle}>
          {titleIcon && <Icon style={headerIconStyle}>{titleIcon}</Icon>}
          {titleSVGIcon}
          <h2 style={headerStyle}>{title}</h2>
        </div>
      </Toolbar>
    );

    return (
      <Dialog
        classes={{ container: styles.modalContainer, root: styles.bodyStyle, paper: styles.paper }}
        disableBackdropClick={disableBackdropClick}
        open={open}
        PaperProps={{ style: modalStyle }}
        onClose={onClose}
        aria-labelledby={title}
        role='dialog'
      >
        {!hideTitle && <div>{header}</div>}
        <div className={styles.modalContent} role='document'>
          {children}
        </div>
        <div
          style={{
            borderTop: '1px solid rgb(224, 224, 224)',
            padding: '16px 16px 16px 24px',
            marginTop: '16px',
          }}
        >
          <div key='footer' style={footerStyle}>
            {actions}
          </div>
        </div>
      </Dialog>
    );
  }
}
