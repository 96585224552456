import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as utils from 'utils/auth';
import AuthLayout from 'components/AuthLayout';
import { LoginInterstitialHeader } from 'components/AuthLayout/AuthHeader';
import AuthSidebar from 'components/AuthLayout/AuthSidebar';
import Login from 'components/Login';
import RedirectOnLogin from 'containers/RedirectOnLogin';
import { getFormPublicInfo } from 'actions/forms';
import { BLANK_FORM } from 'constants/forms';
import { getFormPublicInfoById, getAuthSubmissionState } from '../../reducers';
import { getDecodedPredicateTokenFromLocalStorage } from 'utils/liveViewGating';


const buildSidebarProps = queryParams => {
  const { form_id: formId, maid, redirect_url: redirectUrl } = queryParams;
  const queryString = `?form_id=${formId}&maid=${maid}&redirect_url=${redirectUrl}`;
  return {
    buttonText: 'Register now',
    labelText: "Don't have an account?",
    linkHref: `/ng/registration${queryString}`,
  };
};

class LoginInterstitial extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.defaultSidebarProps = buildSidebarProps({ ...props.location.query, redirect_url: props.liveViewUrl });
  }

  componentDidMount() {
    const { form_id: formId, maid } = this.props.location.query;
    if (!(formId && maid)) return;
    this.props.getFormPublicInfo(formId, maid);
  }

  defaultSidebarProps

  shouldShowADButtons() {
    return !!this.props.activeDirectoryConnections.length;
  }

  onSubmit = formData => {
    const { email, password } = formData;
    const subdomain = this.props.accountKey ? this.props.accountKey : '';
    utils.signIn({
      username: email,
      password,
      subdomain,
    }, this.props.dispatch);
  }

  handleActiveDirectoryLogin = connection => {
    utils.activeDirectorySignIn(connection, this.props.dispatch);
  }

  render() {
    const {
      activeDirectoryConnections,
      error,
      form,
      isFetchingOrganizationInfo,
      location,
      processingSubmission,
      visitorRegistrationEnabled,
      maid,
    } = this.props;

    const showADButtons = this.shouldShowADButtons();
    const sidebarProps = visitorRegistrationEnabled ? this.defaultSidebarProps : {};
    const sidebar = <AuthSidebar {...form.letterheadInfo} {...sidebarProps} />;
    const mainComponent = (
      <Login
        activeDirectoryConnections={activeDirectoryConnections}
        showADButtons={showADButtons}
        handleActiveDirectoryLogin={this.handleActiveDirectoryLogin}
        onSubmit={this.onSubmit}
        processingSubmission={processingSubmission}
        error={utils.processLoginError(error)}
        maid={maid}
      />
    );
    const header = <LoginInterstitialHeader />;

    return (
      <React.Fragment>
        <RedirectOnLogin location={location} />
        <AuthLayout
          fetching={isFetchingOrganizationInfo}
          formName={form.formName}
          mainComponent={mainComponent}
          header={header}
          sidebar={sidebar} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, { location: { query } }) => {
  const { auth: { accountKey, isFetchingOrganizationInfo, error, activeDirectoryConnections, maid } } = state;

  let form;
  if (typeof query.form_id === 'string') form = getFormPublicInfoById(state, query.form_id);
  if (!form) form = BLANK_FORM;

  let liveViewUrl = '';
  if (typeof query.redirect_url === 'string') {
    liveViewUrl = query.redirect_url;
  } else {
    const token = getDecodedPredicateTokenFromLocalStorage();
    if (token) liveViewUrl = (token as any).liveViewUrl;
  }
  return {
    accountKey,
    activeDirectoryConnections,
    error,
    form,
    isFetchingOrganizationInfo: !!isFetchingOrganizationInfo,
    liveViewUrl,
    processingSubmission: getAuthSubmissionState(state),
    visitorRegistrationEnabled: form.publicFormSettings.allowVisitorRegistration,
    maid,
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ getFormPublicInfo }, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginInterstitial);
