import GoogleTranslateImg from 'icons/google-translate.png';

export const css = `
    div#google_translate_element {
        display: flex;
        justify-content: flex-end;
        gap: 1rem
    }

    div#google_translate_element div.goog-te-gadget {
        border: none;
        display: flex;
        align-items: center;

    }

    div#google_translate_element div.goog-te-gadget > div {
          margin-right: 1rem;
    }

    div#google_translate_element div.goog-te-gadget img {
        content: url("${GoogleTranslateImg}");
        background: none!important;
        width: 52px;height:auto;
    }
`;
