import React, {useContext} from 'react';
import store from './store';

function withNavBarContext(WrappedComponent) {
  return props => {
    const contextState = useContext(store);
    return <WrappedComponent {...props} navBarContext={contextState} />;
  };
}

export default withNavBarContext;
