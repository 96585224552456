import React from 'react';
import classNames from 'classnames';
import { Field } from 'formik';

import styles from './FieldContainer.css';

const getFieldId = (name: string) => `${name}-input`;

const withId = (fieldObject: Record<any, any>, name: string) => ({
  ...fieldObject,
  id: getFieldId(name),
});

type Props = {
  name: string,
  label: string,
  isRequired: boolean,
  children: (field: any, meta: any) => any,
};

const FieldContainer = ({ name, label, isRequired, children }: Props) => (
  <Field name={name}>
    {({ field, meta }) => (
      <div className={classNames(styles.fieldContainer)}>
        <div className={styles.fieldName}>
          <label htmlFor={getFieldId(name)}>
            {label}
            {isRequired && <span className={styles.requiredFieldIcon}> *</span>}
          </label>
        </div>
        <div className={styles.fieldContent}>{children((withId(field, name)), meta)}</div>
        {meta.touched && meta.error && (
          <div className={styles.errorMsg}>{meta.error}</div>
        )}
      </div>
    )}
  </Field>
);

FieldContainer.defaultProps = {
  isRequired: false,
};

export default FieldContainer;
