import api from 'reducers/api';
import {REQUEST_INSTRUCTION} from 'constants/types/liveViewGatingActionTypes';


const initialState = {
  fetchingInstruction: true,
  instruction: null,
  liveViewUrl: '',
};

export default (state = initialState, _action) => {
  const action = _action;
  switch (action.type) {
    case REQUEST_INSTRUCTION:
      return api(action, state, {
        success: () => ({
          fetchingInstruction: false,
          instruction: action.payload.nextCommand,
          liveViewUrl: action.payload.liveViewUrl,
        }),
        pending: () => initialState,
        failure: () => initialState,
      });
    default:
      return state;
  }
};


export const getCurrentPredicateInstruction = state => state.instruction;

export const getPredicateFetchingState = state => state.fetchingInstruction;

export const getGatedLiveViewUrl = state => state.liveViewUrl;
