import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import ErrorContainer from '../ErrorContainer/ErrorContainer';

const defaultDisclaimer = 'I agree to electronically sign and to create a legally binding contract between the other party and myself, or the entity I am authorized to represent.';

const AgreementCheckbox = ({id, onChange, checked, customLegalText = defaultDisclaimer, required = false}, ref) => {
  const [error, setError] = useState('');
  const [touched, setTouched] = useState(false);
  const inputRef = useRef(null);

  const selfValidate = () => {
    if (required && !checked) {
      setError('This field is required.');
      return false;
    }

    setError('');
    return true;
  };

  useEffect(() => {
    touched && selfValidate();
  }, [checked]);

  useImperativeHandle(
    ref,
    () => ({
      // @ts-ignore
      focus: () => inputRef.current.focus(),
      validate: selfValidate,
    }));

  const handleChange = event => {
    onChange && onChange(event);
    setTouched(true);
  };

  return (
    <ErrorContainer error={error}>
      <input id={`cb_agree_${id}`} type='checkbox' checked={checked} onChange={handleChange} required/>
      <label htmlFor={`cb_agree_${id}`}>&nbsp;{!!customLegalText ? customLegalText : defaultDisclaimer}</label>
    </ErrorContainer>
  );
};

export default React.memo(forwardRef(AgreementCheckbox));
