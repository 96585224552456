import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import style from './FormDropDown.css';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import Tooltip from 'components/Tooltip';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';
import {
  ControlState,
  LabelAlignment,
} from 'types/liveView';
import useDynamicStyle from 'hooks/useDynamicStyle';

type Props = {
  id: string,
  label?: string,
  hoverText?: string,
  options: any[],
  fieldState?: ControlState | null,
  size?: number,
  cssClass?: string,
  labelCssClass?: string,
  selectCssStyle?: string,
  onChange?: Function | null,
  updateForm?: Function | null,
  validationType?: string,
  showHideCondition?: any,
  specialSettings?: {
    isReadonly?: boolean,
    showLabel?: boolean,
    showChooseOne?: boolean,
    size: string,
    labelAlign: LabelAlignment,
  },
  properties?: any,
  required?: boolean,
  isDisabled: boolean,
}

function FormDropDown({
  id,
  label = 'Select',
  hoverText = '',
  options = [],
  fieldState = null,
  cssClass = sharedStyle.FormControlGroup,
  labelCssClass = sharedStyle.FormLabel,
  selectCssStyle = style.FormSelect,
  onChange = null,
  updateForm = null,
  validationType,
  showHideCondition,
  specialSettings = {
    labelAlign: 'auto',
    showChooseOne: true,
    size: '1',
    isReadonly: false,
    showLabel: true,
  },
  required = false,
  isDisabled = false,
  properties = {},
  ...props }: Props & Partial<any>, ref) {
  const first = options[0] || '';
  const optionsAreStrings = typeof first === 'string';
  const [selected, setSelected] = useState(fieldState?.fields?.selected);
  const [error, setError] = useState('');
  const [touched, setTouched] = useState(false);

  useDynamicStyle(id, properties);
  useEffect(() => {
    // If "show choose one" is turned off, we automatically select the first option
    if (specialSettings?.showChooseOne === false && options.length && !selected) {
      const firstOption = options[0].value || '';
      const firstCalculation = options[0].calculation;
      setSelected(firstOption);
      updateForm && updateForm({
        fields: { selected: firstOption },
        calculations: firstCalculation || firstOption,
      });
    }
  }, [specialSettings]);

  const selfValidate = () => {
    if (required && !selected) {
      setError('This field is required.');
      return false;
    }

    setError('');
    return true;
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  useEffect(() => {
    touched && selfValidate();
  }, [selected]);

  const handleSelectionChanged = event => {
    const sel = event.target.value || '';
    setTouched(true);
    setSelected(sel);
    onChange && onChange(event);
    updateForm && updateForm({
      fields: { selected: sel },
      calculations: (event.target.options &&
        event.target.options[event.target.selectedIndex]?.getAttribute('data-calc-value')) ||
        sel,
    });
  };

  const renderOptions = () => {
    if (optionsAreStrings) {
      return options.map((o, i) => (<option key={`${o}_${i}`} value={o}>{o}</option>));
    }
    return options.map(
      ({ value, label: l, calculation }, i) =>
        (<option key={`${value}_${i}`} value={value} data-calc-value={calculation}>{l}</option>));
  };

  const renderEmptyOption = () => {
    if (!options[0] || !options[0].value) {
      return null;
    }
    // only render empty option if option array does not contain one already
    return (<option value='' data-testid='choose-one-option'>Choose One</option>);
  };


  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <div id={`container_${id}`} className={classNames(cssClass, 'form_control_group')}>
        {specialSettings?.showLabel ?
          <label
            htmlFor={id}
            aria-label={label}
            className={classNames(labelCssClass, 'select_label', { [sharedStyle.Required]: required }, getAlignmentClass(specialSettings.labelAlign, sharedStyle))}>
            {label}
          </label> :
          null
        }
        <ErrorContainer error={error}>
          <select
            value={selected}
            className={classNames(selectCssStyle, 'select_input', `${id}-font-settings`, { [style.readonly]: specialSettings?.isReadonly })}
            onChange={handleSelectionChanged}
            id={id}
            name={id}
            size={Number.parseInt(specialSettings?.size, 10) || 1}
            required={required}
            disabled={isDisabled || specialSettings.isReadonly}
            {...props}>
            {specialSettings?.showChooseOne && renderEmptyOption()}
            {renderOptions()}
          </select>
          {/* This input is required to mimic readonly behaviour*/}
          <input type='hidden' name={id} value={selected} />
        </ErrorContainer>
      </div>
    </Tooltip>
  );
}

export default React.memo(forwardRef(FormDropDown));
