import React from 'react';

const PermissionsIcon = ({ color, className = '' }) => (
  <svg width='24' height='24' viewBox='0 0 53.1 53.1' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <g>
      <g>
        <circle fill={color} cx='17.9' cy='27.6' r='2.5' />
        <path
          fill={color}
          d='M26.5,0C11.9,0,0,11.9,0,26.5c0,14.6,11.9,26.5,26.5,26.5c14.6,0,26.5-11.9,26.5-26.5
          C53.1,11.9,41.2,0,26.5,0z M39.8,29.1H23.2c-0.7,2.3-2.8,4-5.3,4c-3,0-5.5-2.5-5.5-5.5c0-3,2.5-5.5,5.5-5.5c2.5,0,4.6,1.7,5.3,4
          h13.2v-2.5c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v2.5h0.5c0.8,0,1.5,0.7,1.5,1.5S40.7,29.1,39.8,29.1z'/>
      </g>
    </g>
  </svg>
);

export default PermissionsIcon;
