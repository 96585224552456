import api from 'reducers/api';
import {GET_EMAIL_FIELDS_OF_FORM} from 'constants/types/automatedProcessBuilderActionTypes';


const initialState = {};

export default function emailFields(state = initialState, action) {
  switch (action.type) {
    case GET_EMAIL_FIELDS_OF_FORM:
      return api(action, state, {
        pending: () => ({
          ...state,
          [action.payload.formId]: [],
        }),
        success: () => ({
          ...state,
          [action.payload.formId]: action.payload.emailFields,
        }),
      });
    default:
      return state;
  }
}

export const getEmailFieldsState = state => state;
