import {connect} from 'react-redux';
import QuickFilters from 'components/QuickFilters';
import injectPermissions from 'decorators/injectPermissions';
import {
  quickFiltersEnum,
  QUICK_FILTER_METADATA,
} from 'constants/quickFilters';
import {getSelectedQuickFilter} from '../../reducers';
import {selectQuickFilter} from 'actions/quickFilters';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';


const mapDispatchToProps = {
  selectQuickFilter,
};

const filterToAvailableQuickFilters = (features, canUserReadAssignedSubmissions, isSignedByMeEnabled) =>
  QUICK_FILTER_METADATA.filter(quickFilter => {
    const isAssignedToMeFilter = quickFilter.text === quickFiltersEnum.ASSIGNED_TO_ME;
    const isDraftsFilter = quickFilter.text === quickFiltersEnum.DRAFTS;
    const isSignedByMeFilter = quickFilter.text === quickFiltersEnum.SIGNED_BY_ME;

    const isAssignmentsFeatureFlagOn = features.ASSIGNMENTS_IN_SUBMISSION_MANAGER_SUPPORT;
    const isDraftsFeatureFlagOn = features.SAVE_AND_CONTINUE;

    return (
      (!isAssignedToMeFilter || (isAssignmentsFeatureFlagOn && canUserReadAssignedSubmissions)) &&
      (!isDraftsFilter || isDraftsFeatureFlagOn) &&
      (!isSignedByMeFilter || isSignedByMeEnabled)
    );
  });

const mapStateToProps = (state, {canUserReadAssignedSubmissions}) => ({
  availableQuickFilters: filterToAvailableQuickFilters(
    state.features,
    canUserReadAssignedSubmissions(),
    selectTreatmentValue(state.splitio, 'SIGNED_BY_ME_FILTER') === 'on'
  ),
  selectedFilter: getSelectedQuickFilter(state),
});

export default injectPermissions(connect(mapStateToProps, mapDispatchToProps)(QuickFilters));

