import React from 'react';
import styles from 'components/Typeaheads/Typeahead/Typeahead.css';

import { UP_KEY, DOWN_KEY, TAB_KEY, DEL_KEY, BACK_SPACE_KEY, _getKeyboardCode } from 'utils/keyboard';
import { joinClassNames } from 'utils/strings';


export default class SelectionsWrapper extends React.Component<any, any> {
  state = { selectedItemIndex: 0 }

  componentDidMount() {
    if (this.selectionsWrapperRef.current) (this.selectionsWrapperRef.current as any).focus();
  }

  selectionsWrapperRef = React.createRef()

  handleOnKeyDown = event => {
    const key = _getKeyboardCode(event);
    const { selectedItemIndex } = this.state;
    const { selections, requestClose, onRemove } = this.props;
    switch (key) {
      case UP_KEY:
        const previousItemIndex = (selectedItemIndex - 1 + selections.length) % selections.length;
        this.setState({ selectedItemIndex: previousItemIndex });
        break;
      case DOWN_KEY:
        const nextItemIndex = (selectedItemIndex + 1) % selections.length;
        this.setState({ selectedItemIndex: nextItemIndex });
        break;
      case DEL_KEY:
      case BACK_SPACE_KEY:
        onRemove(selections[selectedItemIndex]);
        break;
      case TAB_KEY:
        requestClose();
        break;
      default:
        break;
    }
  }

  render() {
    const { renderSelection, selections, className, requestClose, ...selectionProps } = this.props;
    const { selectedItemIndex } = this.state;
    return (
      <div
        className={joinClassNames(styles.results, this.props.className)}
        onKeyDown={this.handleOnKeyDown}
        ref={this.selectionsWrapperRef as any}
        tabIndex={-1}>
        {selections.map((selectionData, index) =>
          renderSelection({
            selectionData,
            key: index,
            selected: true,
            inPopover: true,
            isSelectedByKeyboard: index === selectedItemIndex,
            ...selectionProps,
          }))
        }
      </div>
    );
  }
}
