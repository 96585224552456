import * as types from 'constants/types/lobbyFolderNavigationActionTypes';
import {LOBBY_ROOT_FOLDER} from 'constants/tableConsts';
import {Dir} from 'utils/vfs';
import {newTable} from 'actions/lobby/tableApiActions';
import store from '../../store';
import api from 'actions/api';
import {createNewRootFromData, processGetFolderBreadcrumbsPayload, injectDataByDirFolderId, isAncestor} from 'utils/folderHelper';
import { GetFolderBreadcrumbsResponse } from 'types/lobby/folderNavigation';
// import type {Action} from 'types/api';
// import type {
//   CollapseDir,
//   ExpandDir,
//   SelectFolder,
//   UpsertArgs,
//   UpsertData,
// } from 'types/lobby/folderNavigationAction';
// import type {Path} from 'utils/vfs';
export function getDirJSON(dir?: any) {
  const folderId = dir && dir.meta.folderId;
  const isRoot = !dir || (folderId === LOBBY_ROOT_FOLDER);
  if (dir) {
    dir.meta.loading = true;
  }

  return api.postWithAuth({
    endpoint: 'folders.json',
    data: {folderId: isRoot ? null : folderId},
    baseType: types.GET_DIR_JSON_API,
    success: (state, {response}) => {
      if (isRoot || !dir) { // redundant check for (!dir) is to satisfy Flow
        return createNewRootFromData(response);
      }
      dir.meta.loading = false;
      return injectDataByDirFolderId(dir, response);
    },
    failure: (state, error) => {
      if (dir) dir.meta.loading = false;
      return {error};
    },
  });
}

export function selectFolder(path = [''], node: any) {
  return {
    type: types.SELECT_FOLDER,
    path,
    node,
  };
}

export function expandDir(dir: any) {
  dir.meta.loading = false;
  dir.meta.collapsed = false;
  return {
    type: types.EXPAND_FOLDER,
  };
}

export function collapseDir(dir) {
  dir.meta.loading = false;
  dir.meta.collapsed = true;
  return {
    type: types.COLLAPSE_FOLDER,
  };
}

const prepareUpsertData = ({folder, folderName, parentFolder}) => {
  const data: any = {folderName};
  if (folder && folder.meta.folderId) {
    data.folderId = folder.meta.folderId;
  }

  if (parentFolder && parentFolder.meta.folderId !== LOBBY_ROOT_FOLDER) {
    data.parentFolderId = parentFolder.meta.folderId;
  }
  return data;
};

const prepareNewDir = ({folder, folderName, parentFolder}) => {
  let newDir: any;
  if (folder) {
    newDir = folder;
    newDir.unmount();
    newDir.name = folderName;
  } else {
    newDir = new Dir(folderName, {
      collapsed: true,
      loading: false,
      // @ts-ignore
      folderId: null,
    });
  }

  if (parentFolder) {
    parentFolder.meta.collapsed = false;
    newDir.meta.loading = true;
    newDir.mount(parentFolder);
  }
  return newDir;
};

const isValidOperation = (moving, target) => !(moving && target && isAncestor(moving, target));

export const upsertFolder = options => {
  if (!isValidOperation(options.folder, options.parentFolder)) {
    throw new Error('Invalid folder operation attempted');
  }
  const {isCreatingFolder} = options;
  const parentFolder = options.parentFolder || options.folder && options.folder.parent;
  const data = prepareUpsertData({...options, parentFolder});
  const newDir = prepareNewDir({...options, parentFolder});

  return api.postWithAuth({
    endpoint: 'upsertFolder.json',
    data,
    baseType: types.UPSERT_FOLDER,
    pending: {newDir},
    success: (state, {response}) => {
      newDir.meta.folderId = response.folderId;
      newDir.meta.loading = false;
      store.dispatch(getDirJSON(parentFolder));
      store.dispatch(newTable(response.folderId));
      return {isCreatingFolder};
    },
    failure: (state, error) => {
      store.dispatch(getDirJSON());
      return {error, isCreatingFolder};
    },
  });
};

export function moveDirectory(dir, to) {
  return upsertFolder({
    folder: dir,
    folderName: dir.name,
    parentFolder: to,
  });
}

export function renameDirectory(dir, newName) {
  return upsertFolder({
    folder: dir,
    folderName: newName,
  });
}

export function createDirectory(name, parent) {
  return upsertFolder({
    folderName: name,
    parentFolder: parent,
    isCreatingFolder: true,
  });
}

export function moveFormIntoFolder(requestedFormIndex, formID, destinationFolderID) {
  return api.postWithAuth({
    endpoint: 'updateFormLocation.json',
    baseType: types.FORM_INTO_FOLDER,
    data: {
      formId: formID,
      destinationFolder: destinationFolderID,
    },
    pending: {requestedFormIndex},
    success: () => ({requestedFormIndex}),
    failure: (_, error) => ({error: error || 'Moving Form Failed'}),
  });
}

export function deleteFolder(folderId, selectParentFolder) {
  return api.postWithAuth({
    endpoint: 'deleteFolder.json',
    data: {folderId},
    baseType: types.DELETE_FOLDER,
    success: () => {
      selectParentFolder();
      return {};
    },
    failure: (_, error) => ({error}),
  });
}

export function getFolderBreadcrumbs(folderId: string) {
  return api.postWithAuth({
    endpoint: 'getFolderBreadcrumbs.json',
    data: {folderId},
    baseType: types.GET_FOLDER_BREADCRUMBS,
    success: (_, { response }: GetFolderBreadcrumbsResponse) => {
      const [rootFromBreadcrumbs, newCurrentNode, path] = processGetFolderBreadcrumbsPayload(response);
      return {rootFromBreadcrumbs, newCurrentNode, path};
    },
    failure: (_, error) => ({error}),
  });
}
