import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { memoize, values, omit, size } from 'lodash';
import TagMultiSelector from 'components/Tags/TagMultiSelector';
import * as tagsActions from 'actions/tags';
import { getTags } from '../../reducers';
import { SM_DETAILS_SOURCE } from 'constants/forms';


export class TagsCell extends React.PureComponent<any, any> {
  static defaultProps = {
    allTags: {},
    noTagsInAccountMessage: null,
    notTaggedMessage: null,
    tagNames: [],
  }

  state = {
    footerText: '',
    modalOpen: false,
    typeaheadResults: [],
  }

  getTagsAvailableToSelect = memoize(
    (selectedTagNames, allTags) => values(omit(allTags, selectedTagNames)),
    (selectedTagNames, allTags) => `${selectedTagNames.join()}-${size(allTags)}`
  )

  getTagsFromName = memoize(
    (tagNames, allTags) => tagNames.map(tagName => allTags[tagName]).filter(Boolean),
    (tagNames, allTags) => `${tagNames.join()}-${size(allTags)}`
  );

  removeTag = tagName => {
    const { actions, source, submissionId } = this.props;
    actions.unassignTags({ source, submissionId, tagNames: [tagName] });
  }

  filterTags = searchValue => {
    const { tagNames, allTags } = this.props;
    const tagsAvailableToSelect = this.getTagsAvailableToSelect(tagNames, allTags);
    const searchRegExp = RegExp(searchValue, 'i');
    return tagsAvailableToSelect.filter((current: any) =>
      searchRegExp.test(current.name)
    );
  }

  tagExists = tagName => !!this.props.allTags[tagName];

  handleAddTags = tags => {
    const { actions, source, submissionId } = this.props;
    actions.assignTags({ source, submissionId, tagNames: tags.map(tag => tag.name) });
  }

  handleCreateTag = name => {
    this.props.actions.createTags([name], newTagNames => {
      if (newTagNames.includes(name)) {
        this.handleAddTags([{ name }]);
        this.setState({ footerText: '' });
      }
    });
  };

  handleRemoveTag = ({ name }) => this.removeTag(name);

  handleInputTypeahead = searchValue => {
    const trimmedValue = searchValue.trim();
    const typeaheadResults = trimmedValue ? this.filterTags(trimmedValue) : [];
    const footerText = trimmedValue && !this.tagExists(trimmedValue) ? searchValue : '';
    this.setState({
      typeaheadResults,
      footerText,
    });
  };

  render() {
    const {
      allTags,
      isReadOnly,
      maxElements,
      noTagsInAccountMessage,
      notTaggedMessage,
      source,
      tagNames,
    } = this.props;
    if (isReadOnly && !tagNames.length) {
      return (
        !size(allTags) ? noTagsInAccountMessage : notTaggedMessage
      ) || null;
    }
    const { footerText, typeaheadResults } = this.state;
    const tags = this.getTagsFromName(tagNames, allTags);

    return (
      <TagMultiSelector
        footerText={footerText}
        isReadOnly={isReadOnly}
        maxElements={maxElements}
        onAddTags={this.handleAddTags}
        onCreateTag={this.handleCreateTag}
        onInputTypeahead={this.handleInputTypeahead}
        onRemoveTag={this.handleRemoveTag}
        hidePeak={source === SM_DETAILS_SOURCE}
        tags={tags}
        typeaheadResults={typeaheadResults} />
    );
  }
}

const mapStateToProps = state => ({
  allTags: getTags(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(tagsActions, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsCell);
