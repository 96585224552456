import creditCardIcon from 'icons/submissions/creditCard.svg';
import refundIcon from 'icons/submissions/refund.svg';

export const Type = {
  REFUND: 'refund',
  PROCESS_PAYMENT: 'payment',
};

export const ModalData = {
  [Type.REFUND]: {
    title: 'Issue a refund',
    icon: refundIcon,
    totalLabel: 'Total Refund',
    btnText: 'Issue Refund',
    inputLabel: 'Original Payment',
  },
  [Type.PROCESS_PAYMENT]: {
    title: 'Process Payment',
    icon: creditCardIcon,
    totalLabel: 'Total Payment',
    btnText: 'Process Payment',
    inputLabel: 'Pre-Authorized Amount',
  },
};

export const DropDownValue = {
  AMOUNT: 'amount',
  PERCENTAGE: 'percentage',
};
