/*
 * A wrapper to make it easy to avoid the target="_blank" vulnerability.
 * See: https://dev.to/ben/the-targetblank-vulnerability-by-example
 */
import React from 'react';

interface Props {
  children?: React.ReactNode,
  className?: string,
  href: string,
  onClick?: React.MouseEventHandler,
}

export default class LinkToNewTab extends React.Component<Props> {
  render() {
    const { children, href, className, onClick } = this.props;

    return (
      <a
        href={href}
        className={className}
        onClick={onClick}
        target='_blank'
        rel='noopener noreferrer'>
        {children}
      </a>
    );
  }
}
