import React from 'react';
import MaterialUIMenuItem from '@material-ui/core/MenuItem';
import DarkIconButton from '../buttons/DarkIconButton';
import PermissionsIcon from 'components/permissions/Body/PermissionsIcon';
import { shouldContract } from './shared';

import * as Colors from 'utils/colors';
import * as inlineStyles from '../inlineStyles';


export default class PermissionsMenuItem extends React.Component<any, any> {
  render() {
    const { text, onClick, windowWidth } = this.props;

    const leftIcon = (
      <span style={inlineStyles.toolbarSvgIcon}>
        <PermissionsIcon color={Colors.grey600} />
      </span>
    );

    if (shouldContract(windowWidth)) {
      return (
        <MaterialUIMenuItem
          onClick={onClick}>
          {leftIcon}
          {text}
        </MaterialUIMenuItem>
      );
    }

    return (
      <span>
        <DarkIconButton
          iconOverride={<PermissionsIcon color={Colors.white} />}
          onClick={onClick}
          tooltip={text} />
      </span>
    );
  }
}
