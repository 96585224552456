import * as sharedInputStyles from 'styles/shared/input';

const _textField = {
  height: 'initial',
  minHeight: '160px',
  padding: '12px',
  position: 'relative',
  resize: 'both',
  overflowY: 'auto',
  maxWidth: '100%',
  minWidth: '100%',
  marginBottom: 0,
};

export const textField = {
  ...sharedInputStyles.inputField,
  ..._textField,
};

export const textFieldFocused = {
  ...sharedInputStyles.inputFieldFocused,
  ..._textField,
};
