// import type {EditorState} from 'draft-js';
import {get, isArray, isString} from 'lodash';
import api from 'actions/api';
import {convertRulesForClient, convertRulesForServer} from 'utils/automatedProcessBuilder';
import * as types from 'constants/types/automatedProcessBuilderActionTypes';
// import type {Action as ApiAction} from 'types/api';
// import type {
//   AddEmptyRuleAction,
//   ApbRule,
//   ApbTrigger,
//   ApbVisibilityAction,
//   ChangeRuleAssignmentsAction,
//   ChangeRuleEmailBodyAction,
//   ChangeRuleEmailRecipientsAction,
//   ChangeRuleEmailSubjectAction,
//   ChangeRuleStageAction,
//   ChangeRuleWebhookAction,
//   ChangeRuleStatusAction,
//   ChangeRuleTagsAction,
//   ChangeRuleTriggerAction,
//   DeleteRuleAction,
//   EmailVisibilityAction,
//   SetFormIdAction,
//   TriggerType,
//   Recipients,
// } from 'types/automatedProcessBuilder';

export function loadRules(formId: string) {
  return api.postWithAuth({
    baseType: types.LOAD_RULES,
    endpoint: 'processbuilder/getApbRules.json',
    data: {
      formId,
    },
    pending: {formId},
    success: (
      state,
      {
        response: {
          result: {rules},
        },
      }
    ) => ({
      clientRules: convertRulesForClient(rules),
      formId,
    }),
    failure: (state, error) => {
      const rules = get(error, ['xhr', 'response', 'message', 'rules'], []);
      return {
        error,
        clientRules: convertRulesForClient(rules),
        formId,
      };
    },
  });
}

export function saveRulesToServer(formId: string, rules: any[]) {
  const rulesForServer = convertRulesForServer(formId, rules);
  return api.postWithAuth({
    baseType: types.SAVE_RULES,
    endpoint: 'processbuilder/saveApbRules.json',
    data: {
      formId,
      rules: rulesForServer,
    },
    success: (state, {response: {result: successMessage}}) => ({successMessage}),
    failure: (state, error) => {
      const message = get(error, ['xhr', 'response', 'message']);
      const errorMessage = isString(message) ? message : '';
      const clientRules = isArray(message) ? convertRulesForClient(message) : state.rules[formId];

      return {
        error,
        errorMessage,
        clientRules,
        formId,
      };
    },
  });
}

export function setApbVisibility(isVisible: boolean) {
  return {
    type: types.SET_APB_VISIBILITY,
    isVisible,
  };
}

export function setEmailVisibility(visibleEmailRuleId: string) {
  return {
    type: types.SET_EMAIL_VISIBILITY,
    visibleEmailRuleId,
  };
}

export function addEmptyRule(formId: string) {
  return {
    type: types.ADD_EMPTY_RULE,
    formId,
  };
}

export function getEmailFieldsOfForm(formId: string) {
  return api.postWithAuth({
    baseType: types.GET_EMAIL_FIELDS_OF_FORM,
    endpoint: 'recipients/getEmailFields.json',
    data: {formId},
    pending: {formId},
    success: (state, {response}) => ({
      formId,
      emailFields: response.result,
    }),
    failure: (state, error) => ({error}),
  });
}


export const getSignersFieldsOfForm = (formId: string) =>
  api.postWithAuth({
    baseType: types.GET_SIGNERS_FIELDS_OF_FORM,
    endpoint: 'recipients/getSignersFields.json',
    data: {formId},
    pending: {formId},
    success: (state, {response}) => ({
      formId,
      signersFields: response.result,
    }),
    failure: (state, error) => ({error}),
  });

export const getFormFieldsForEmail = (formId: string) =>
  api.postWithAuth({
    baseType: types.GET_FORM_FIELDS,
    endpoint: 'forms/fields/allSupported.json',
    data: {formId},
    pending: {formId},
    success: (state, {response}) => ({
      formId,
      formFields: response.result,
    }),
    failure: (state, error) => ({error}),
  });

export function changeRuleAssignments(
  formId: string,
  ruleId: string,
  triggerType: string,
  assigneeIds: string[]
) {
  return {
    assigneeIds,
    formId,
    ruleId,
    triggerType,
    type: types.CHANGE_RULE_ASSIGNMENTS,
  };
}

export function changeRuleEmailBody(formId: string, ruleId: string, triggerType: string, emailChanges: any) {
  return {
    emailChanges,
    formId,
    ruleId,
    triggerType,
    type: types.CHANGE_RULE_EMAIL_BODY,
  };
}

export function changeRuleEmailAttachedPdf(formId: string, ruleId: string, triggerType: string, emailChanges: any) {
  return {
    emailChanges,
    formId,
    ruleId,
    triggerType,
    type: types.CHANGE_RULE_EMAIL_ATTACHED_PDF,
  };
}

export function changeRuleEmailIncludeSMLink(formId: string, ruleId: string, triggerType: string, emailChanges: any) {
  return {
    emailChanges,
    formId,
    ruleId,
    triggerType,
    type: types.CHANGE_RULE_EMAIL_INCLUDE_SM_LINK,
  };
}
export function changeRuleEmailIncludeFields(formId: string, ruleId: string, triggerType: string, emailChanges: any) {
  return {
    emailChanges,
    formId,
    ruleId,
    triggerType,
    type: types.CHANGE_RULE_EMAIL_INCLUDE_FIELDS,
  };
}

export function changeRuleEmailRecipients(formId: string, ruleId: string, triggerType: string, emailChanges: any) {
  return {
    emailChanges,
    formId,
    ruleId,
    triggerType,
    type: types.CHANGE_RULE_EMAIL_RECIPIENTS,
  };
}

export function changeRuleEmailSubject(formId: string, ruleId: string, triggerType: string, emailChanges: any) {
  return {
    emailChanges,
    formId,
    ruleId,
    triggerType,
    type: types.CHANGE_RULE_EMAIL_SUBJECT,
  };
}

export function changeRuleStage(
  formId: string,
  ruleId: string,
  triggerType: string,
  stageName: string,
) {
  return {
    type: types.CHANGE_RULE_STAGE,
    formId,
    ruleId,
    triggerType,
    stageName,
  };
}

export function changeRuleWebhook(
  formId: string,
  ruleId: string,
  triggerType: string,
  webhookUrl: string,
) {
  return {
    type: types.CHANGE_RULE_WEBHOOK,
    formId,
    ruleId,
    triggerType,
    webhookUrl,
  };
}

export function changeRuleStatus(formId: string, ruleId: string, isEnabled: boolean) {
  return {
    type: types.CHANGE_RULE_STATUS,
    formId,
    isEnabled,
    ruleId,
  };
}

export function changeRuleTags(
  formId: string,
  ruleId: string,
  triggerType: string,
  tagNames: string[],
) {
  return {
    type: types.CHANGE_RULE_TAGS,
    formId,
    ruleId,
    triggerType,
    tagNames,
  };
}

export function changeRuleTrigger(
  formId: string,
  ruleId: string,
  trigger: any,
  triggerType: string,
) {
  return {
    type: types.CHANGE_RULE_TRIGGER,
    formId,
    ruleId,
    trigger,
    triggerType,
  };
}

export function deleteRule(formId: string, ruleId: string) {
  return {
    type: types.DELETE_RULE,
    formId,
    ruleId,
  };
}

export function setFormId(formId: string) {
  return {
    formId,
    type: types.SET_LEGACY_FORM_ID,
  };
}
