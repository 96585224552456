import React, { useMemo } from 'react';

import { filterDropdownConfig } from 'constants/submissionFilters';
import {
  SubmissionFilter,
  ColumnItem,
  Operator,
  OperatorItem,
  Expectation,
} from 'types/submissionsManager/submissionFilters';
import { FormFields } from 'types/formFields';
import { getExpectationFieldOptions, getSelectedColumnType } from 'utils/submissionsManager/submissionFilters';

import FilterDropdown from './FilterDropdown';
import ExpectationField from './ExpectationField';

import styles from './styles.css';

import icon36 from 'icons/icon36.svg';


export type Props = {
  formFields: FormFields,
  columnItems: ColumnItem[],
  filter: SubmissionFilter,
  onSelectColumn: (filterId: string, columnKey: string) => void,
  onSelectOperator: (filterId: string, operator: Operator) => void,
  onChangeExpectation: (filterId: string, expectation: Expectation) => void,
  onRemoveFilter: (filterId: string) => void,
};


export const Filter = ({
  columnItems,
  filter,
  onSelectColumn,
  onSelectOperator,
  onChangeExpectation,
  onRemoveFilter,
  formFields,
}: Props) => {
  const {
    id,
    columnKey,
    operator,
    expectation,
  } = filter;

  // @ts-ignore
  const selectedColumnType = getSelectedColumnType(formFields, columnKey);
  const filterDropdownConfigForType = selectedColumnType ? filterDropdownConfig[selectedColumnType] : null;

  const expectationFieldOptions = useMemo(
    // @ts-ignore
    () => getExpectationFieldOptions(formFields, columnKey),
    [formFields, columnKey]
  );

  const selectedColumn: ColumnItem | undefined = useMemo(
    () => columnItems.find(c => c.value === columnKey),
    [columnItems, columnKey],
  );

  const operatorItems: OperatorItem[] =
    useMemo(() => filterDropdownConfigForType ? [...filterDropdownConfigForType.operators] : [], [selectedColumnType]);

  const selectedOperator: OperatorItem | undefined = useMemo(
    () => operatorItems.find(o => o.value === operator),
    [operatorItems, operator],
  );

  const handleRemoveFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onRemoveFilter(id);
    // Removing the filter causes useOutsideClick to be triggered since it can't find the element anymore
    e?.stopPropagation();
  };

  const handleSelectColumn = (columnItem: ColumnItem) => onSelectColumn(filter.id, columnItem.value);

  const handleSelectOperator = (operatorItem: OperatorItem) => onSelectOperator(filter.id, operatorItem.value);

  const handleChangeExpectation = (e: Expectation) => onChangeExpectation(filter.id, e);

  const expectationFieldConfig = filterDropdownConfigForType?.expectationField;
  const toDisplayExpectationField: boolean = expectationFieldConfig?.displayWhen?.includes(operator);

  return (
    <>
      <button
        className={styles.removeButton}
        onClick={handleRemoveFilterClick}
        data-testid='removeFilter'
      >
        <img className={styles.removeButton} src={icon36} alt='Remove' />
      </button>
      <FilterDropdown
        items={columnItems}
        selected={selectedColumn}
        onSelect={handleSelectColumn}
      />
      <FilterDropdown
        items={operatorItems}
        selected={selectedOperator}
        onSelect={handleSelectOperator}
      />
      <ExpectationField
        inputType={expectationFieldConfig?.type}
        toShow={toDisplayExpectationField}
        expectation={expectation}
        onChangeExpectation={handleChangeExpectation}
        expectationFieldOptions={expectationFieldOptions}
      />
    </>
  );
};

export default Filter;
