import Modernizr from 'modernizr';

const makeMockStorage = () => {
  let store = {};
  class MockStorage {
    setItem(key: string, value: any) {
      store[key] = value || '';
    }
    getItem(key: string) {
      return store[key] || null;
    }
    removeItem(key: string) {
      delete store[key];
    }
    key(i: number) {
      const keys = Object.keys(store);
      return keys[i] || null;
    }
    clear() {
      store = {};
    }
  }
  return new MockStorage();
};

const mockStorage = makeMockStorage();

export default function storage() {
  if (Modernizr.localstorage) {
    return localStorage; // eslint-disable-line no-restricted-globals
  }
  return mockStorage;
}
