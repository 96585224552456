import React from 'react';


export default class TimezoneSelect extends React.Component<any, any> {
  render() {
    return (
      <select
        className={this.props.className}
        name={this.props.name || 'timezone'}
        onChange={this.props.onChange}
        value={this.props.value}>
        <option value='America/New_York'>Eastern Time (EST)</option>
        <option value='America/Chicago'>Central Time (CST)</option>
        <option value='America/Boise'>Mountain Time (MST)</option>
        <option value='America/Los_Angeles'>Pacific Time (PST)</option>
        <option value='America/Anchorage'>Alaska Time (AKST)</option>
        <option value='Pacific/Honolulu'>Hawaii (HST)</option>
      </select>);
  }
}
