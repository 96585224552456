import React from 'react';
import AssignmentTrigger from './AssignmentTrigger';
import EditEmail from './EditEmail';
import EditWebhookUrl from './EditWebhookUrl';
import StageTrigger from './StageTrigger';
import TagTrigger from './TagTrigger';
import styles from './Rule.css';
import { triggersEnum, triggerTypeEnum } from 'constants/triggers';
import { getIsThenValueValid } from 'utils/automatedProcessBuilder';
import { joinClassNames } from 'utils/strings';


const SelectedTrigger = props => {
  const trigger = props.rule[`${props.triggerType.toLowerCase()}Trigger`]; // i.e. ifTrigger || thenTrigger
  if (trigger === undefined) throw new Error('Invalid Trigger Type');

  const getContent = () => {
    switch (trigger) {
      case triggersEnum.ASSIGNMENT:
        return <AssignmentTrigger {...props} />;
      case triggersEnum.STAGE:
        return <StageTrigger {...props} />;
      case triggersEnum.TAG:
        return <TagTrigger {...props} />;
      case triggersEnum.NOTIFICATION:
        return <EditEmail rule={props.rule} ruleId={props.rule.ruleId} />;
      case triggersEnum.WEBHOOK:
        return <EditWebhookUrl {...props} />;
      default:
        return null;
    }
  };
  const getPlaceholderText = () => {
    if (props.triggerType === triggerTypeEnum.THEN && props.rule.thenTrigger === triggersEnum.NOTIFICATION) {
      return '';
    }
    return props.triggerType === triggerTypeEnum.IF ? 'Choose' : 'Add';
  };

  const content = getContent();
  if (content) {
    const placeholderText = getPlaceholderText();
    const shouldShowPlaceholder =
      props.triggerType === triggerTypeEnum.IF && !props.rule.ifValue ||
      props.triggerType === triggerTypeEnum.THEN && !getIsThenValueValid(props.rule.thenValue, props.entities);
    return (
      <React.Fragment>
        {shouldShowPlaceholder &&
          <React.Fragment>
            <span className={styles.placeholderAppearance}>{placeholderText}</span>
            <span className={joinClassNames(styles.isRequired, styles.placeholderLayout)}>*</span>
          </React.Fragment>
        }
        {content}
      </React.Fragment>
    );
  }
  return null;
};

SelectedTrigger.defaultProps = {
  isReadOnly: false,
};

export default SelectedTrigger;
