import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import DraftPluginsEditor from 'draft-js-plugins-editor';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { values } from 'lodash';
import { DEFAULT_IMAGE_WIDTH_PIXELS } from 'constants/richTextEditor';
import Controls from './Controls';
import styleMaps from './styleMaps';
import * as bodyStyles from './styles';
import { shimTypeIsEnumerable } from 'embed/shims';
import { createDecorator, decorators } from 'utils/richTextEditor/draft';
import createPlugins, { addImage } from './plugins';
import {
  blockRenderMap,
  blockStyleFn,
  correctSelection,
  createLinkEntity,
  createLinkText,
  currentLastLinkOffsets,
  currentLinkText,
  currentLinkURL,
  forEachCurrentLinkEntity,
  getLinkEntityData,
  getLinkEntityKey,
  isSpecificLinkSelected,
  isUseableNonLinkTextSelected,
  maxTabDepth,
  removeLinkFromText,
  toggleColorStyle,
  toggleHeaderStyle,
  updateLinkEntity,
  updateLinkText,
} from './draft';

import { convertFilepickerUrlToS3 } from 'utils/filepicker';
import * as filestack from 'filestack-js';
import { FILEPICKER_KEY } from '../../env';
import { IMAGE_EXTENSIONS, RICH_TEXT_IMAGE_UPLOAD_DIR, SOURCES } from 'constants/filepicker';


import 'draft-js-focus-plugin/lib/plugin.css';

const customDecorator = createDecorator();


export default class RichTextEditor extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      imageAlignmentSetter: null,
      isFontColorPickerToggled: false,
      isLinkToggled: false,
      isPromptingLinkInput: false,
    };
    this.fileStackClient = filestack.init(FILEPICKER_KEY);
  }

  UNSAFE_componentWillMount() {
    shimTypeIsEnumerable();
    this.plugins = createPlugins({
      onImageBlur: this.handleImageOnBlur,
      onImageFocus: this.handleImageOnFocus,
    });
    this.pluginsArray = values(this.plugins);
  }

  fileStackClient;
  plugins;
  pluginsArray;

  getEditorState = () => this.props.editorState || EditorState.createEmpty(customDecorator);

  _setPromptingLinkInput = (isPromptingLinkInput, isLinkToggled?: boolean) => {
    this.setState({
      isLinkToggled: isLinkToggled == null ? isPromptingLinkInput : isLinkToggled,
      isPromptingLinkInput,
      isFontColorPickerToggled: false,
    });
  }

  _toggleFontColorPicker = isFontColorPickerToggled => {
    this.setState({
      isFontColorPickerToggled,
      isLinkToggled: false,
      isPromptingLinkInput: false,
    });
  }

  _onChange = editorState => this.props.onChange(editorState);

  _toggleNonLinkText = editorState => {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const block = content.getBlockForKey(selection.getStartKey());
    const text = block.getText().slice(selection.getStartOffset(), selection.getEndOffset());
    this._onChange(EditorState.set(editorState, {
      currentContent: Modifier.applyEntity(content, selection, createLinkEntity(content, { display: text })),
    }));
    this._setPromptingLinkInput(true);
    setTimeout((this.refs.controls as any).doFocusOnLinkURL);
  }

  _toggleAddImage = () => {
    const options = {
      accept: IMAGE_EXTENSIONS,
      fromSources: SOURCES,
      storeTo: {
        location: 'S3',
        path: RICH_TEXT_IMAGE_UPLOAD_DIR,
      },
      uploadInBackground: false,
      onUploadDone: ({ filesUploaded }) => {
        if (filesUploaded.length > 0) {
          const filepickerResponse = filesUploaded[0];
          const fileUrl = convertFilepickerUrlToS3(filepickerResponse);
          this.handleControlsOnImageURLChange(fileUrl);
        }
      },
      onFileUploadFailed: error => {
        if (error.code >= 400) {
          datadogLogs.logger.error('Rich Text Editor - File Upload Error', new Error(JSON.stringify(error)) as any);
        }
      },
    };
    this.fileStackClient.picker(options).open();
  }

  _toggleLink = () => {
    const editorState = this.getEditorState();
    const selection = editorState.getSelection();
    if (isSpecificLinkSelected(editorState)) {
      this._setPromptingLinkInput(false);
      const { start, end } = currentLastLinkOffsets(editorState);
      if (start != null && end != null) {
        this._onChange(removeLinkFromText(editorState, start, end));
      }
    } else if (selection.isCollapsed()) {
      this._setPromptingLinkInput(true);
      setTimeout((this.refs.controls as any).doFocusOnLinkText);
    } else if (isUseableNonLinkTextSelected(editorState)) {
      this._toggleNonLinkText(correctSelection(editorState));
    }
  };

  handleImageOnBlur = () => {
    this.setState({ imageAlignmentSetter: null });
  };

  handleImageOnFocus = imageAlignmentSetter => {
    this.setState({ imageAlignmentSetter });
  };

  handleOnClick = () => {
    this._setPromptingLinkInput(isSpecificLinkSelected(this.getEditorState()));
    (this.refs.editor as any).focus();
  };

  handleEditorOnFocus = () => {
    this.setState({ focused: true });
    this._setPromptingLinkInput(isSpecificLinkSelected(this.getEditorState()));
  }

  handleEditorOnBlur = () => this.setState({ focused: false });

  handleEditorOnChange = _editorState => {
    /*
     * This verbose change handler exists to manage the editing of text in the
     * case that there is an associated link entity.
     *
     * We need to check whether a link entity mapping will potentially be affected
     * by the edit, and if so determine if the edit is being applied at the end or
     * in the middle.
     *  - If the edit is at the end, we want to block Draft's default behavior of
     *   extending the link entity's text range, and turn the new text into non-
     *   link text.
     *  - If the edit is in the middle, Draft's default behavior is fine, but we
     *   need to update the link entity's text metadata.
     */
    let newEditorState = _editorState;
    const newSelection = newEditorState.getSelection();
    const newEndOffset = newSelection.getEndOffset();
    const editorState = this.getEditorState();
    const selection = editorState.getSelection();
    const endOffset = selection.getEndOffset();
    if (newSelection.getStartOffset() !== selection.getStartOffset() || newEndOffset !== endOffset) {
      let shouldPromptLinkInput = isSpecificLinkSelected(newEditorState);
      if (shouldPromptLinkInput) {
        forEachCurrentLinkEntity(newEditorState, (newStart, newEnd, block) => {
          const { end } = currentLastLinkOffsets(editorState);
          if (end != null
            && newEnd === newEndOffset // Is the end of the new link at the end of the new selection?
            && newEnd > endOffset // Is the end of the new link ahead of the end of the old selection?
            // @ts-ignore
            && newEnd > end // Is the end of the new link ahead of the end of the old link?
          ) {
            // Text was added at the end of a link
            newEditorState = removeLinkFromText(newEditorState, endOffset, newEndOffset);
            shouldPromptLinkInput = false;
          } else {
            // Text was added in the middle of a link
            const key = block.getEntityAt(newEnd - 1);
            const text = block.getText().slice(newStart, newEnd);
            const contentState = editorState.getCurrentContent();
            const data = getLinkEntityData(contentState, key).display ? { display: text } : { URL: text };
            updateLinkEntity(contentState, key, data);
          }
        });
      }
      this._setPromptingLinkInput(shouldPromptLinkInput);
    }
    this._onChange(newEditorState);
  };

  handleEditorOnTab = event => {
    this._onChange(RichUtils.onTab(event, this.getEditorState(), maxTabDepth));
  };

  handleColorChange = color => {
    this._onChange(toggleColorStyle(this.getEditorState(), color));
  }

  handleControlsOnLinkConfirm = () => {
    (this.refs.editor as any).focus();
    this._setPromptingLinkInput(false, true);
  }

  handleControlsOnImageURLChange = url => {
    this._onChange(addImage(this.getEditorState(), url, DEFAULT_IMAGE_WIDTH_PIXELS));
  }

  handleControlsOnLinkTextChange = value => {
    const editorState = this.getEditorState();
    const selection = editorState.getSelection();
    if (selection.isCollapsed() || isSpecificLinkSelected(editorState)) {
      const key = getLinkEntityKey(editorState);
      if (key) {
        const text = value || getLinkEntityData(editorState.getCurrentContent(), key).URL;
        this._onChange(updateLinkText(editorState, key, text, { display: value }));
      } else {
        this._onChange(createLinkText(editorState, value, currentLinkURL(editorState)));
      }
    }
  };

  handleControlsOnLinkURLChange = value => {
    const editorState = this.getEditorState();
    const selection = editorState.getSelection();
    if (selection.isCollapsed() || isSpecificLinkSelected(editorState)) {
      const key = getLinkEntityKey(editorState);
      if (key) {
        const data = { URL: value };
        const contentState = editorState.getCurrentContent();
        if (getLinkEntityData(contentState, key).display) {
          updateLinkEntity(contentState, key, data);
          this.forceUpdate();
        } else {
          this._onChange(updateLinkText(editorState, key, value, data));
        }
      } else {
        this._onChange(createLinkText(editorState, null, value));
      }
    }
  };

  handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(this.getEditorState(), command);
    if (newState) {
      this._onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  handleToggleBlockType = (event, type) => {
    event.preventDefault();

    if (type === 'IMAGE') {
      this._toggleAddImage();
    } else {
      this._onChange(
        RichUtils.toggleBlockType(this.getEditorState(), type)
      );
    }
  };

  handleToggleImageAlignment = (event, type) => {
    event.preventDefault();
    const { imageAlignmentSetter } = this.state;
    if (imageAlignmentSetter) {
      if (
        type === 'center'
        || type === 'default'
        || type === 'left'
        || type === 'right'
      ) {
        imageAlignmentSetter(type);
      }
    }
  };

  handleToggleInlineType = (event, type) => {
    event.preventDefault();
    if (type === 'LINK') {
      this._toggleLink();
    } else if (type === 'FONT_COLOR') {
      this._toggleFontColorPicker(!this.state.isFontColorPickerToggled);
    } else if (type in styleMaps.headerStyleMap) {
      this._onChange(toggleHeaderStyle(this.getEditorState(), type));
    } else {
      this._onChange(RichUtils.toggleInlineStyle(this.getEditorState(), type));
    }
  };

  render() {
    const editorState = this.getEditorState();
    const {
      ariaLabelledBy,
      tabIndex,
    } = this.props;
    const {
      focused,
      imageAlignmentSetter,
      isFontColorPickerToggled,
      isLinkToggled,
      isPromptingLinkInput,
    } = this.state;

    return (
      <div>
        <Controls
          ref='controls'
          editorState={editorState}
          isFontColorPickerToggled={isFontColorPickerToggled}
          imageFocused={!!imageAlignmentSetter}
          isLinkToggled={isLinkToggled}
          linkInputText={currentLinkText(editorState)}
          linkInputURL={currentLinkURL(editorState)}
          linkInputVisible={isPromptingLinkInput}
          onFontColorChange={this.handleColorChange}
          onLinkTextChange={this.handleControlsOnLinkTextChange}
          onLinkURLChange={this.handleControlsOnLinkURLChange}
          onLinkURLConfirm={this.handleControlsOnLinkConfirm}
          onToggleBlockType={this.handleToggleBlockType}
          onToggleImageAlignment={this.handleToggleImageAlignment}
          onToggleInlineType={this.handleToggleInlineType} />
        <div
          onClick={this.handleOnClick}
          style={(focused ? bodyStyles.textFieldFocused : bodyStyles.textField) as any}>
          <DraftPluginsEditor
            ariaLabelledBy={ariaLabelledBy}
            tabIndex={tabIndex}
            customStyleMap={styleMaps.combinedStyleMap}
            blockStyleFn={blockStyleFn}
            blockRenderMap={blockRenderMap}
            decorators={decorators}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onBlur={this.handleEditorOnBlur}
            onChange={this.handleEditorOnChange}
            onFocus={this.handleEditorOnFocus}
            onTab={this.handleEditorOnTab}
            // We get a siteimprove violation if we don't include a placeholder
            // https://github.com/facebook/draft-js/issues/1739
            placeholder={'Enter your text here'}
            plugins={this.pluginsArray}
            ref='editor' />
        </div>
      </div>
    );
  }
}
