import React, {useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import withKeyboardFunctionality from 'components/MultiSelector/withKeyboardFunctionality';
import TagComponent from 'components/Tags/Tag';
import Typeahead from 'components/Typeaheads/Typeahead';
import CreateTagFooter from 'components/MultiSelector/shared/CreateTagFooter';
import crossIcon from '../../../../assets/icons/submissions/cross.svg';
import styles from './TagsSelect.css';
import { filterTags } from 'utils/tags';
import { Tag } from 'types/tags';

type Props = {
  selected: Tag[],
  setSelected: any,
  createTag: any,
  options: Tag[],
  multiple?: boolean,
  shouldCreateTag?: boolean,
  customClassName?: string,
}

const TagsSelect = ({
  selected,
  setSelected,
  createTag,
  options = [],
  multiple = false,
  shouldCreateTag = true,
  customClassName,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');

  const filteredOptions = useMemo(() => {
    if (selected.length === 0) return options;
    const selectedTagNames = new Set<string>(selected.map(tag => tag.name));
    return options.filter(tag => !selectedTagNames.has(tag.name));
  }, [selected.length, options.length]);

  const renderTag = props => {
    const TagWithKeyBoard = withKeyboardFunctionality(TagComponent);
    return (
      <TagWithKeyBoard
        inPopover={props.inPopover}
        isMultiplePicked={props.isMultiplePicked}
        isReadOnly={props.isReadOnly}
        isSelectedByKeyboard={props.isSelectedByKeyboard}
        key={props.key}
        onRemove={props.onRemove}
        onSelect={props.onSelect}
        selected={props.selected}
        selectionData={props.selectionData} />
    );
  };

  const renderFooter = props => {
    if (!shouldCreateTag) {
      return null;
    }

    const handleOnClick = tagName => {
      createTag(tagName);
      props.onClick();
    };
    return !options.find(option => option.name === searchValue) && (
      <CreateTagFooter
        isSelected={!!props.isSelected}
        onClick={handleOnClick}
        text={searchValue}
        footeref={props.ref} />
    );
  };

  const onAddSelections = selectionData => {
    setSearchValue('');
    setSelected(multiple ? [...selected, ...selectionData] : selectionData);
  };

  const removeSelection = key => {
    setSelected([
      ...selected.slice(0, key),
      ...selected.slice(key + 1),
    ]);
  };

  const handleChange = ({target: {value}}) => {
    if (ref.current) {
      ref.current.style.width = value.length + 'ch';
    }
    setSearchValue(value);
  };

  const handleRemove = (key, e) => {
    e.stopPropagation();
    removeSelection(key);
  };

  const renderSelections = () =>
    <div className={classNames(styles.Selections, {[styles.MultipleSelections]: multiple && selected.length > 0})}>
      <span
        className={classNames(
          {[styles.hidden]: selected.length > 0 || searchValue.length}
        )}>Tag name...</span>
      {selected.map((selection, key) => <div key={key} className={multiple ? styles.SelectedOption : styles.Selection}>
        <span>{selection.name}</span>
        <span className={styles.SelectionRemove} onClick={e => handleRemove(key, e)}><img src={crossIcon} alt='x'/></span>
      </div>)}
      <input
        ref={ref}
        value={searchValue}
        onChange={handleChange}
        className={classNames(
          styles.Input,
          {[styles.hidden]: !multiple && selected.length > 0},
          {[styles.placeholder]: !selected.length && !searchValue.length}
        )}
      />
    </div>;

  return (<>
    <div className={customClassName ? customClassName : styles.Select} onClick={() => ref.current?.focus()}>
      {renderSelections()}
    </div>
    {!!searchValue && <div className={styles.Results}>
      <Typeahead
        allowPickingMultipleItems={false}
        isLoading={false}
        onAddSelections={onAddSelections}
        results={filterTags(filteredOptions, searchValue)}
        renderSelection={renderTag}
        requestPopoverClose={() => {}}
        renderFooter={renderFooter}
        resultsListClassName={styles.resultsListClassName}
        shouldAutoFocusInput={false}
      />
    </div>}
  </>);
};

export default TagsSelect;
