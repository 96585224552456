import React from 'react';
import { connect } from 'react-redux';
import * as utils from 'utils/auth';
import NewLogin from 'containers/Login/Login/Login';
import { getAuthSubmissionState } from '../../reducers';


import { redirectToNotFound } from '../../utils/routing';


class LoginContainer extends React.Component<any, any> {
  redirectIfSubdomainDoesNotExists(isSubdomainExists) {
    if (isSubdomainExists === false) redirectToNotFound();
  }

  onSubmit = formData => {
    const { email, password } = formData;
    const subdomain = this.props.accountKey ? this.props.accountKey : '';
    utils.signIn({
      username: email,
      password,
      subdomain,
    }, this.props.dispatch);
  };

  render() {
    const {
      location,
      isSubdomainExists,
    } = this.props;
    this.redirectIfSubdomainDoesNotExists(isSubdomainExists);

    return <NewLogin location={location} />;
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      accountKey,
      isSubdomainExists,
      maid,
    },
  } = state;
  return {
    accountKey,
    processingSubmission: getAuthSubmissionState(state),
    isSubdomainExists,
    maid,
  };
};

export default connect(mapStateToProps)(LoginContainer);
