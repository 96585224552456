import React from 'react';
import styles from './FormLiveView.css';
import errorIcon from 'icons/warningIcon.svg';

const DataSyncErrorPage = ({errorMessage}) => <div className={styles.ErrorPage}>
  <img alt='error' src={errorIcon} />
  <span>{errorMessage}</span>
</div>;

export default DataSyncErrorPage;
