import React, {useEffect, useRef, useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Select from 'react-select';

import FieldContainer from '../../FieldContainer/FieldContainer';

import styles from './Form.css';

const INVALID_PASSWORD =
  'Your password must have at least: 1 uppercase, 1 lowercase, 1 number, 8 characters.';
const PASSWORD_PATTERN = /^\S*(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w\d@%+\\\/'!#$^?:,(){}\[\]~\-_.]{8,}$/;

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('This can’t be blank.'),
  lastName: Yup.string().required('This can’t be blank.'),
  email: Yup.string()
    .email('Please supply a valid email.')
    .required('This can’t be blank.'),
  password: Yup.string()
    .required('This can’t be blank.')
    .matches(PASSWORD_PATTERN, INVALID_PASSWORD),
  confirmPassword: Yup.string()
    .required('This can’t be blank.')
    .oneOf([Yup.ref('password'), null], 'Your passwords don’t seem to match.'),
  hpValue: Yup.string().matches(/^(?![\s\S])/, { message: 'should be empty '}), // honeypot
});

const TIME_ZONES = [
  { value: 'America/New_York', label: 'America/New York' },
  { value: 'America/Chicago', label: 'America/Chicago' },
  { value: 'America/Boise', label: 'America/Boise' },
  { value: 'America/Los_Angeles', label: 'America/Los Angeles' },
  { value: 'America/Anchorage', label: 'America/Anchorage' },
  { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu' },
];

const SELECT_CUSTOM_STYLES = {
  control: provided => ({
    ...provided,
    border: '2px solid #e2eaec',
    boxShadow: 'none',
    borderRadius: '5px',
    height: '45px',
    paddingLeft: '15px',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    color: '#2a2a2a',
  }),
  placeholder: provided => ({ ...provided, margin: '0' }),
  valueContainer: provided => ({ ...provided, padding: '0' }),
  indicatorContainer: provided => ({ ...provided, padding: '0' }),
  menuList: provided => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    color: '#2a2a2a',
  }),
};


const SignUpForm = ({ onCreate, isProcessing, email, error }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const form = useRef(null);

  useEffect(() => {
    if (error && form.current) {
      // @ts-ignore
      form.current.setErrors({
        email: error,
      });
    }
  });

  return (
    <Formik
      innerRef={form}
      initialValues={{
        firstName: '',
        lastName: '',
        email,
        phoneNumber: '',
        timeZone: { value: 'America/New_York', label: 'America/New York' },
        password: '',
        confirmPassword: '',
        hpValue: '',
      }}
      validationSchema={FormSchema}
      onSubmit={values => {
        const { confirmPassword, timeZone, ...rest } = values;

        onCreate({
          ...rest,
          timeZone: timeZone.value,
        });
      }}
    >
      {({ isValid, setFieldValue, setFieldTouched }) => (
        <Form>
          <div className={styles.form}>
            <div className={styles.userNameContainer}>
              <div className={styles.firstNameContainer}>
                <FieldContainer name='firstName' label='First Name' isRequired>
                  {(field, meta) => (
                    <input
                      className={classNames(styles.input, {
                        [styles.invalidInput]: meta.touched && meta.error,
                      })}
                      type='text'
                      {...field}
                    />
                  )}
                </FieldContainer>
              </div>

              <div className={styles.secondNameContainer}>
                <FieldContainer name='lastName' label='Last Name' isRequired>
                  {(field, meta) => (
                    <input
                      className={classNames(styles.input, {
                        [styles.invalidInput]: meta.touched && meta.error,
                      })}
                      type='text'
                      {...field}
                    />
                  )}
                </FieldContainer>
              </div>
            </div>

            <div className={styles.emailContainer}>
              <FieldContainer name='email' label='Email Address' isRequired>
                {(field, meta) => (
                  <input
                    className={classNames(styles.input, {
                      [styles.invalidInput]: meta.touched && meta.error,
                    })}
                    disabled={!!email}
                    type='text'
                    {...field}
                  />
                )}
              </FieldContainer>
            </div>

            <div className={styles.optionalFieldsContainer}>
              <div className={styles.phoneNumberContainer}>
                <FieldContainer name='phoneNumber' label='Phone Number'>
                  {(field, meta) => (
                    <input
                      className={classNames(styles.input, {
                        [styles.invalidInput]: meta.touched && meta.error,
                      })}
                      type='text'
                      {...field}
                    />
                  )}
                </FieldContainer>
              </div>

              <div className={styles.timezoneContainer}>
                <FieldContainer name='timeZone' label='Timezone'>
                  {field => (
                    <Select
                      styles={SELECT_CUSTOM_STYLES}
                      value={field.value}
                      placeholder=''
                      onChange={option => {
                        setFieldTouched('timeZone');
                        setFieldValue('timeZone', option);
                      }}
                      options={TIME_ZONES}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  )}
                </FieldContainer>
              </div>
            </div>

            <div className={styles.passwordContainer}>
              <FieldContainer
                name='password'
                label='Pick a Password'
                isRequired
              >
                {(field, meta) => (
                  <div className={styles.passwordField}>
                    <input
                      className={classNames(styles.input, {
                        [styles.invalidInput]: meta.touched && meta.error,
                      })}
                      type={showPassword ? 'text' : 'password'}
                      {...field}
                    />

                    <IconButton
                      className={styles.showPassword}
                      role='password visibility'
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <Icon>
                        {showPassword ? 'visibility_off' : 'visibility'}
                      </Icon>
                    </IconButton>
                  </div>
                )}
              </FieldContainer>
            </div>

            <div className={styles.confirmPasswordContainer}>
              <FieldContainer
                name='confirmPassword'
                label='Confirm Password'
                isRequired
              >
                {(field, meta) => (
                  <div className={styles.passwordField}>
                    <input
                      className={classNames(styles.input, {
                        [styles.invalidInput]: meta.touched && meta.error,
                      })}
                      type={showConfirmPassword ? 'text' : 'password'}
                      {...field}
                    />

                    <IconButton
                      className={styles.showPassword}
                      role='password visibility'
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    >
                      <Icon>
                        {showConfirmPassword ? 'visibility_off' : 'visibility'}
                      </Icon>
                    </IconButton>
                  </div>
                )}
              </FieldContainer>
            </div>

            <div className={styles.hpValue}>
              <FieldContainer name='hpValue' label='If you are a human, do not fill in this field'>
                {(field, meta) => (
                  <input
                    className={classNames(styles.input, {
                      [styles.invalidInput]: meta.touched && meta.error,
                    })}
                    type='text'
                    {...field}
                  />
                )}
              </FieldContainer>
            </div>

            <div className={styles.submitBtnContainer}>
              <button
                className={classNames(styles.submitBtn, {
                  [styles.submitBtnInvalid]: !isValid || isProcessing,
                })}
                type='submit'
                disabled={!isValid || isProcessing}
              >
                Create Account
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SignUpForm.defaultProps = {
  email: '',
  error: '',
};

export default SignUpForm;
