import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { memoize, differenceBy } from 'lodash';
import StageSelector from 'components/Stages/StageSelector';
import { selectStage, unassignStage } from 'actions/stages';
import { formCapabilitiesEnum } from 'constants/tableConsts';

import { getSelectedStageName } from 'reducers/ui';
import { checkFormCapability } from '../../reducers';


export class StagesCell extends React.PureComponent<any, any> {
  static defaultProps = {
    allStages: [],
    noStagesMessage: '',
    notInStageMessage: '',
  }

  getStageFromName = memoize(
    (stageName, allStages) => allStages.find(stage => stage.name === stageName),
    (stageName, allStages) =>
      `${stageName || 'none'}-${JSON.stringify(allStages.find(({ name }) => name === stageName))}`,
  );

  getStagesAvailableToSelect = memoize(
    (stage, allStages) => stage ? differenceBy(allStages, [stage], 'name') : allStages,
    (stage, allStages) => `${stage ? stage.name : 'none'}-${allStages.map(stageObj => JSON.stringify(stageObj)).join()}`
  )

  handleClearStage = () => {
    const { source, submissionId, stageName, selectedStageName, formId } = this.props;
    this.props.unassignStage({ source, submissionId, stageName, formId }, selectedStageName);
  }

  handleSelectStage = ({ name: stageName }) => {
    const { source, submissionId, stageName: currentStageName, selectedStageName, formId, dispatch } = this.props;
    this.props.selectStage({ source, submissionId, stageName, currentStageName, formId }, selectedStageName, dispatch);
  }

  render() {
    const {
      canCreate,
      isReadOnly,
      allStages,
      maxWidth,
      noStagesMessage,
      notInStageMessage,
      stageName,
    } = this.props;
    if (!allStages.length && (!canCreate || isReadOnly)) return noStagesMessage;
    if (isReadOnly && !stageName) return notInStageMessage;
    const selectedStage = this.getStageFromName(stageName, allStages);
    const stages = this.getStagesAvailableToSelect(selectedStage, allStages);
    return (
      <StageSelector
        canCreate={canCreate}
        isReadOnly={isReadOnly}
        maxWidth={maxWidth}
        onClearStage={this.handleClearStage}
        onSelectStage={this.handleSelectStage}
        selectedStage={selectedStage}
        stages={stages} />
    );
  }
}

const mapStateToProps = state => {
  const { stages, submissionTable, ui } = state;
  return {
    allStages: stages[submissionTable.formId] || [],
    canCreate: checkFormCapability(state, submissionTable.formId, formCapabilitiesEnum.OrganizeStages),
    formId: submissionTable.formId,
    selectedStageName: getSelectedStageName(ui),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators({ selectStage, unassignStage }, dispatch),
});

// we are chicken-egged here, as far as I can tell our react-redux typing for
// connected components with defaultProps is incorrect.  As  of flow 0.85 this produces an error.
// Upgrading to 0.89 and using connect<*, *, *, *, *, *> resolves it but 0.89 has a ton
// of other errors that I would like to defer solving for now.
export default connect(mapStateToProps, mapDispatchToProps)(StagesCell);
