import { SUBMIT_FORM_LIVE_VIEW, SUBMIT_FORM_LIVE_VIEW_RESET } from 'constants/types/formActionTypes';
import api from '../api';

const initialState = Object.freeze({});
// TODO: change according to actual req/res format
export default (state = initialState, action_) => {
  const action = action_;
  switch (action.type) {
    case SUBMIT_FORM_LIVE_VIEW:
      return api(action, state, {
        success: () => {
          const { payload: { submitResult } } = action;
          return {
            ...state,
            submitResult,
            submitError: null,
          };
        },
        failure: () => {
          const { payload: { error }} = action;
          return {
            ...state,
            submitResult: null,
            submitError: error,
          };
        },
      });
    case SUBMIT_FORM_LIVE_VIEW_RESET:
      return {
        ...state,
        submitResult: null,
        submitError: null,
      };
    default:
      return state;
  }
};
