import React from 'react';
import Typeahead from 'components/Typeaheads/Typeahead';
import UserTypeaheadInput from './UserTypeaheadInput';


import { filterToAssigneesBySearchString, renderUser } from 'utils/assignees';


class UserTypeahead extends React.Component<any, any> {
  static defaultProps = {
    initialValue: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.initialValue,
    };
  }

  filterTypeaheadResults() {
    const {
      filter,
      users,
    } = this.props;
    const eligibleUsers = filter(users);
    const typeaheadResults = filterToAssigneesBySearchString(eligibleUsers, this.state.inputValue);
    return typeaheadResults;
  }

  handleOnAddSelection = users => {
    // TODO: Temporarily picking the first (and only) user in the selected user list. See SA-795
    const user = users[0];
    if (!user) return;
    this.setState({
      inputValue: '',
    });
    this.props.handleSelectUser(user);
  }

  handleTypeaheadInput = inputValue => {
    const {
      onTypeaheadInput,
    } = this.props;
    this.setState({
      inputValue,
    });
    onTypeaheadInput(inputValue);
  }


  render() {
    const {
      initialValue,
      shouldAutoFocusInput,
    } = this.props;
    const typeaheadResults = this.filterTypeaheadResults();
    return (
      <Typeahead
        // TODO: We should enable this as part of SA-795
        allowPickingMultipleItems={false}
        initialValue={initialValue}
        Input={UserTypeaheadInput}
        isLoading={false}
        onAddSelections={this.handleOnAddSelection}
        onInput={this.handleTypeaheadInput}
        renderSelection={renderUser()}
        results={typeaheadResults}
        shouldAutoFocusInput={shouldAutoFocusInput}
        showResultsInPopover />
    );
  }
}

export default UserTypeahead;
