import React, { PureComponent } from 'react';
import styles from './ColorItem.css';
import StageDot from 'components/Stages/StageDot';
import { SPACE_KEY, _getKeyboardCode } from 'utils/keyboard';


export default class ColorItem extends PureComponent<any, any> {
  handleOnSelect = () => this.props.onSelect(this.props.color);
  handleOnKeyDown = e => {
    const key = _getKeyboardCode(e);
    if (key === SPACE_KEY) this.handleOnSelect();
  }

  render() {
    return (
      <li
        className={styles.item}
        tabIndex={0}
        onKeyDown={this.handleOnKeyDown}
        onClick={this.handleOnSelect}>
        <StageDot
          className={styles.dot}
          color={this.props.color}
          radiusInPx={8.5}
          includeTitle
          isSelected={this.props.isSelected} />
      </li>
    );
  }
}
