import React from 'react';
import documentIcon from 'icons/icon25.svg';
import formIcon from 'icons/icon26.svg';
import styles from './FormsTable.css';

interface Props {
  rowData: any,
}

export default class FormDragPreview extends React.Component<Props, any> {
  render() {
    const {
      rowData,
    } = this.props;

    const icon = rowData.itemType === 'Doc' ? documentIcon : formIcon;

    return (
      <div className={styles.previewForm}>
        <img src={icon} alt={rowData.itemType} />
        <span>{rowData.formName}</span>
      </div>
    );
  }
}
