import {keyBy} from 'lodash';
import {
  GET_USERS,
} from 'constants/types/userActionTypes';


const initialState = {};

export default function users(state = initialState, _action) {
  const action = _action;
  switch (action.type) {
    case GET_USERS:
      return keyBy(action.payload.users, 'userId');
    default:
      return state;
  }
}

export const getUserMap = state => state;
