import * as Colors from 'utils/colors';
import * as sharedInputStyles from 'styles/shared/input';

const _inputField = {
  fontSize: '13px',
  height: '32px',
  marginBottom: '4px',
  width: '100%',
};
export const inputField = {...sharedInputStyles.inputField, ..._inputField, borderColor: Colors.grey250};
export const inputFieldFocused = {...sharedInputStyles.inputFieldFocused, ..._inputField, borderColor: Colors.grey250};

export const linkInputField = {
  border: 'none',
  color: 'inherit',
  fontSize: 'inherit',
  height: '100%',
  marginLeft: '8px',
  padding: 0,
  width: 'calc(100% - 40px)',
};

const linkURLIcon = {
  verticalAlign: 'middle',
  width: '20px',
};

export const linkURLLinkButton = {
  ...linkURLIcon,
  color: 'inherit',
  height: '20px',
  marginLeft: '-28px',
  padding: 0,
};

export const linkURLLinkIconWithURL = {
  color: 'inherit',
  height: '20px',
  width: '20px',
};

export const linkURLLinkIconWithoutURL = {
  color: Colors.textSecondary,
};

export const linkURLDoneText = {
  color: 'inherit',
  fill: 'currentColor',
  padding: '6px 8px',
  display: 'inline-block',
};

export const controlsContainer = {
  backgroundColor: Colors.grey150,
  borderColor: Colors.grey250,
  borderStyle: 'solid',
  borderWidth: '1px 1px 0',
  padding: '5px 8px 8px',
};
