import React from 'react';
import SmallModal from 'components/Modals/SmallModal';
import Action from 'components/Modals/Action';
import styles from '../modalShared.css';


export default class DecryptionModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      setTimeout(() => {
        if (this._input == null) return;
        this._input.focus();
      });
    }
  }

  _valueFromEvent = event =>
    event.target.value;

  _isValid = () => !!this.state.value;

  _onDecrypt = () => {
    this.setState({ value: '' });
    this.props.onDecrypt(this.state.value);
  };

  _input;

  handleActionOnDecrypt = () => {
    this._onDecrypt();
  };

  handleInputOnChange = event => {
    this.setState({ value: this._valueFromEvent(event) });
  };

  handleInputOnKeyDown = event => {
    if (event.key === 'Enter') {
      if (this._isValid()) {
        this._onDecrypt();
      }
    }
    if (event.key === 'Escape') {
      this.props.onClose();
    }
  }

  render() {
    const {
      onClose,
      open,
    } = this.props;
    const { value } = this.state;

    const modalActions = [
      <Action
        key='cancel'
        label='Cancel'
        secondary
        onClick={onClose} />,
      <Action
        key='decrypt'
        label='Decrypt'
        disabled={!this._isValid()}
        primary
        onClick={this.handleActionOnDecrypt} />,
    ];

    return (
      <SmallModal
        title='Decrypt Field Value'
        titleIcon='enhanced_encryption'
        actions={modalActions}
        onClose={onClose}
        open={open}>
        <label
          className={styles.folderNameLabel}
          htmlFor='decryptPassword'>
          Password for decryption
        </label>
        <div className={styles.hiddenInput}>
          <input type='text' tabIndex={-1} />
        </div>
        <input
          ref={c => { this._input = c; }}
          onKeyDown={this.handleInputOnKeyDown}
          onChange={this.handleInputOnChange}
          id='decryptPassword'
          value={value}
          className={styles.folderNameInput}
          autoComplete='new-password'
          type='password' />
      </SmallModal>
    );
  }
}
