import React from 'react';
import SmallModal from 'components/Modals/SmallModal';
import Action from 'components/Modals/Action';
import styles from './ConfirmationModal.css';


const ConfirmationModal = ({ children, primaryButton, secondaryButton, onClose, open, isClassic }) => (
  <SmallModal
    title='Warning'
    titleIcon='warning'
    actions={[
      <Action
        key={secondaryButton.text}
        label={secondaryButton.text}
        secondary={!isClassic}
        secondaryBlack={isClassic}
        onClick={onClose} />,
      <Action
        key={primaryButton.text}
        label={primaryButton.text}
        primary={!isClassic}
        primaryBlue={isClassic}
        onClick={primaryButton.onClick} />,
    ]}
    onClose={onClose}
    open={open}>
    <div className={styles.container}>
      <p>{children}</p>
    </div>
  </SmallModal>
);

ConfirmationModal.defaultProps = {
  isClassic: false,
  secondaryButton: {
    text: 'Cancel',
  },
};

export default ConfirmationModal;
