import React, { useMemo } from 'react';
import { joinClassNames } from 'utils/strings';
import styles from './FormImage.css';

type Props = {
  id: string,
  specialSettings?: {
    alt?: string,
    fileUrl?: string,
    href?: string,
    imageAlign?: string,
  },
};

const FormImage = ({ id, specialSettings }: Props) => {
  const hasLink: boolean = !!specialSettings?.href;
  const image = useMemo(() => <img src={specialSettings?.fileUrl} alt={specialSettings?.alt || 'image'} />, []);
  const alignment = useMemo(() => {
    switch (specialSettings?.imageAlign) {
      case 'right':
        return styles.AlignRight;
      case 'center':
        return styles.AlignCenter;
      case 'left':
      default:
        return styles.AlignLeft;
    }
  }, []);

  return (
    <div id={`form_image_${id}`} className={joinClassNames(styles.Container, alignment)}>
      {hasLink
        ? <a href={specialSettings?.href} target='_blank'>{image}</a>
        : image
      }
    </div>
  );
};

export default FormImage;
