import React from 'react';
import Loader from './index';
import styles from './loader.css';

export default class SearchLoader extends React.Component {
  render() {
    return (
      <div className={styles.tableLoader}>
        <Loader />
      </div>
    );
  }
}
