import { isEqual } from 'lodash';
import { ConditionalRule, TrackConditionalsAction } from 'types/conditionals';
import { FormFields } from 'types/formFields';
import { TRACK_CONDITIONAL_SETTINGS } from 'constants/types/conditionalsActionTypes';
import { actionValues } from 'constants/conditionals';

const CONDITIONALS_CATEGORY = 'Conditionals';

const sortAndfilterConditionalsForParticipant = (
  conditionals: ConditionalRule[],
  frontendId: string,
): ConditionalRule[] =>
  conditionals
    .filter(conditional => conditional.frontendId === frontendId)
    .sort((a, b) => a.orderId - b.orderId);

const getUniqueOrderIds = (
  prevConditionals: ConditionalRule[],
  currentConditionals: ConditionalRule[]
): number[] =>
  [...prevConditionals, ...currentConditionals]
    .reduce((acc: number[], curr: ConditionalRule) =>
      acc.includes(curr.orderId) ? acc : [...acc, curr.orderId], []);

const getEventsFromRuleDifferences = (
  primaryRule: ConditionalRule,
  secondaryRule: ConditionalRule | undefined,
  formFields: FormFields,
): string[] => {
  const ruleDifferences: string[] = [];
  if (primaryRule.ruleInputName !== secondaryRule?.ruleInputName) {
    const inputType: string | undefined = formFields.find(field => field.id === primaryRule.ruleInputName)?.type;
    ruleDifferences.push(`Participants Rules: ${inputType} field selected`);
  }
  if (primaryRule.actionValue !== secondaryRule?.actionValue) {
    switch (primaryRule.actionValue) {
      case actionValues.requireSignature:
        ruleDifferences.push('Participants Rules: Require Signature selected');
        break;
      case actionValues.skipSignature:
        ruleDifferences.push('Participants Rules: Don\'t Require Signature selected');
        break;
      default:
        break;
    }
  }
  return ruleDifferences;
};

const getRuleEvents = (
  currentVersion: ConditionalRule | undefined,
  prevVersion: ConditionalRule | undefined,
  formFields: FormFields,
) => {
  const ruleEvents: string[] = [];
  if (!currentVersion) {
    ruleEvents.push('Participants Rules: Deleted rule');
  } else if (!prevVersion) {
    ruleEvents.push('Participants Rules: Created rule');
    ruleEvents.push(...getEventsFromRuleDifferences(currentVersion, undefined, formFields));
  } else {
    ruleEvents.push('Participants Rules: Modified rule');
    ruleEvents.push(...getEventsFromRuleDifferences(currentVersion, prevVersion, formFields));
  }
  return ruleEvents;
};


const getAllRuleEvents = (
  currentConditionals: ConditionalRule[],
  prevConditionals: ConditionalRule[],
  formFields: FormFields,
): string[] => {
  // Get unique order ids which represent unique rules
  const allOrderIds: number[] = getUniqueOrderIds(prevConditionals, currentConditionals);

  // Check each rule for changes
  const ruleEvents: string[] = allOrderIds.reduce((acc: string[], orderId: number) => {
    const currentVersion: ConditionalRule | undefined =
      currentConditionals.find(conditional => conditional.orderId === orderId);
    const prevVersion: ConditionalRule | undefined =
      prevConditionals.find(conditional => conditional.orderId === orderId);

    // If there are no changes between the version, move on to the next orderId
    if (isEqual(currentVersion, prevVersion)) return acc;
    return [...acc, ...getRuleEvents(currentVersion, prevVersion, formFields)];
  }, []);
  return ruleEvents;
};

const trackConditionalsChange = (action: TrackConditionalsAction): void => {
  const { currentConditionals, previousConditionals, currentParticipant, formFields } = action;

  const participantCurrentConditionals =
    sortAndfilterConditionalsForParticipant(currentConditionals, currentParticipant.frontendId);
  const participantPrevConditionals =
    sortAndfilterConditionalsForParticipant(previousConditionals, currentParticipant.frontendId);

  const eventsToTrack: string[] =
    getAllRuleEvents(participantCurrentConditionals, participantPrevConditionals, formFields);
  if (eventsToTrack?.length > 0) {
    eventsToTrack.forEach(event => {
      window.analytics && window.analytics.track(event, {
        category: CONDITIONALS_CATEGORY,
      });
    });
  }
};

export const trackConditionalRuleEditing = action$ =>
  action$.ofType(TRACK_CONDITIONAL_SETTINGS)
    .do(action => {
      trackConditionalsChange(action);
    }).ignoreElements();

export default [
  trackConditionalRuleEditing,
];
