import * as React from 'react';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import { requestInstruction } from 'actions/liveViewGating';
import { INSTRUCTIONS_ENUM } from 'constants/liveViewGating';
import { getPredicateTokenFromLocalStorage } from 'utils/liveViewGating';
import { getCurrentPredicateInstruction, getPredicateFetchingState, getGatedLiveViewUrl } from '../../reducers';


/*
This component initiates checks against the backend for instructions to determine the next action to take as a user is
attempting to access a form Live View. While it is rendered, it will either show a Loader if we're waiting for
instructions, or it will render children (defined by nested routes).
*/


class LiveViewGate extends React.Component<any, any> {
  componentDidMount() {
    this.props.requestInstruction(this.props.token);
  }

  componentDidUpdate() {
    if (this.props.currentInstruction === INSTRUCTIONS_ENUM.REDIRECT_TO_LIVE_VIEW) {
      const token = this.props.token || '';
      window.location.href = `${this.props.liveViewUrl}?predicateToken=${token}`;
    }
  }

  render() {
    if (this.props.fetchingInstruction) {
      return <Loader />;
    }
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  currentInstruction: getCurrentPredicateInstruction(state),
  fetchingInstruction: getPredicateFetchingState(state),
  liveViewUrl: getGatedLiveViewUrl(state),
  token: getPredicateTokenFromLocalStorage(),
});

export default connect(mapStateToProps, { requestInstruction })(LiveViewGate);
