import React, {memo, forwardRef, useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import styles from './FormToggle.css';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import Tooltip from 'components/Tooltip';
import {getAlignmentClass} from 'utils/formLiveView/formLiveView';
import {LabelAlignment} from 'types/liveView';
export const FormToggleSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#fff',
      '& + $bar': {
        backgroundColor: '#1093f5',
      },
    },
    '&$checked + $bar': {
      backgroundColor: '#1093f5',
      opacity: '1',
    },
  },
  checked: {},
  bar: {},
  colorPrimary: {},
})(Switch);

export interface FormToggleProps {
  id: string;
  label: string;
  isDisabled: boolean;
  required: boolean;
  showOtherOption: boolean;
  elementType: string;
  excludeFromPreview: boolean;
  hoverText: string | null;
  specialSettings: {
    hoverText: string;
    isDisabled: boolean;
    isReadOnly: boolean;
    isRequired: false;
    label: string;
    labelAlign: LabelAlignment;
    option1: string;
    option2: string;
    optionalTextLabel: string;
    selectedOption: string;
    showLabel: boolean;
    showOptionalText: boolean;
    showOtherOption: boolean;
  };
  validationType: null;
  updateForm?: (s: {fields: {[p: string]: string | null}}) => void;
  extraData: {
    alignment: string;
    labelAlign: string;
    option1: string;
    option2: string;
    readOnly: string;
    showLabel: string;
  };
  fieldState?: any;
}

const FormToggle = forwardRef((props: FormToggleProps, ref) => {
  const {
    id,
    label,
    isDisabled,
    required,
    specialSettings: {showOptionalText, optionalTextLabel, hoverText, option1, option2, labelAlign, showLabel},
    fieldState,
    updateForm,
  } = props;
  const [isChecked, setIsChecked] = useState<boolean>(fieldState?.fields[label] === option2);

  useEffect(() => {
    const s = {
      fields: {
        [label]: isChecked ? option2 : option1,
      },
    };
    updateForm && updateForm(s);
  }, [isChecked]);
  const toggleChecked = () => setIsChecked(prevState => !prevState);
  return (
    <Tooltip title={hoverText || ''} disabled={!hoverText} placement='top' className={styles.ToggleTooltip}>
      <div ref={ref as React.RefObject<HTMLDivElement>} className={styles.ToggleContainer} id={`container_${id}`}>
        {showLabel && (
          <label
            id={label}
            aria-label={label}
            htmlFor={id}
            className={classNames(sharedStyle.FormLabel, {[sharedStyle.Required]: required}, 'field_label', getAlignmentClass(labelAlign, sharedStyle))}
          >
            {label}
          </label>
        )}

        <div className={styles.ToggleSwitchWrapper} id={`toggle_switch_${id}`}>
          <FormToggleSwitch name={label} onChange={toggleChecked} disabled={isDisabled} />
          <span id={'toggle_switch_label'} className={styles.ToggleSwitchLabelTypography}>{isChecked ? option2 : option1}</span>
        </div>

        {isChecked && showOptionalText && (
          <div className={styles.OtherOptionsWrapper}>
            <label id={'optional_text_label'} className={styles.ToggleLabelTypography}>{optionalTextLabel}</label>
            <textarea id={'optional_text_area'} className={styles.OptionalText} name={'Optional text Textarea'} />
          </div>
        )}
      </div>
    </Tooltip>
  );
});

export default memo(FormToggle);
