import React from 'react';
import style from 'styles/Login.css';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';


const buttonStyles = {
  position: 'absolute',
  backgroundColor: 'transparent',
  top: '50%',
};

export default class ShowPasswordField extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { showPassword: false };
  }

  toggleShowPassword = e => {
    e.preventDefault();
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { showPassword } = this.state;
    return (
      <div className={style.showPasswordField}>
        <input
          id={this.props.id || 'passwordInput'}
          type={this.state.showPassword ? 'text' : 'password'}
          name={this.props.name || 'password'}
          placeholder={this.props.placeholder || 'Password'}
          className={this.props.inputClassName || style.showPasswordFieldInput}
          onChange={this.props.onChange}
          value={this.props.value}
          tabIndex={this.props.tabIndex}
          autoComplete='current-password'
          aria-label='Password'
        />
        <IconButton role='password visibility' style={buttonStyles as any} onClick={this.toggleShowPassword}>
          <Icon>{showPassword ? 'visibility_off' : 'visibility'}</Icon>
        </IconButton>
      </div>
    );
  }
}
