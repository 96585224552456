import api from 'reducers/api';
import {GET_FORM_FIELDS} from 'constants/types/automatedProcessBuilderActionTypes';

import { State } from 'types/formFields';

const initialState: State = {};

export default function formFields(state = initialState, action): State {
  switch (action.type) {
    case GET_FORM_FIELDS:
      return api(action, state, {
        pending: () => ({
          ...state,
          [action.payload.formId]: [],
        }),
        success: () => ({
          ...state,
          [action.payload.formId]: action.payload.formFields,
        }),
      });
    default:
      return state;
  }
}

export const getFormFieldsState = (state: State): State => state;
export const getFormFieldsForForm = (state: State, formId: string) => state[formId] || [];
