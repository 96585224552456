import React from 'react';
import styles from './Accordion.css';

interface Props {
  children: React.ReactNode,
  isOpen: boolean,
  onToggle: React.MouseEventHandler,
  renderHeader: (isOpen: boolean) => React.ReactNode,
}

const AccordionItem = ({children, isOpen, onToggle, renderHeader}: Props) => (
  <React.Fragment>
    <button className={styles.accordionHeader} onClick={onToggle}>
      {renderHeader(isOpen)}
    </button>
    {isOpen && children}
  </React.Fragment>
);

export default AccordionItem;
