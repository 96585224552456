import { Observable } from 'rxjs/Observable';

import { GET_GOVOS_PAYMENT_SETTINGS } from 'constants/types/govOsPayTypes';
import { pending } from './utils/filters';
import { getProviderOptions } from 'actions/govOsPayModal';
import { getGovOsPaymentProviders } from 'embed/reducers';

import type { ActionsObservable } from 'redux-observable';
import type { MiddlewareAPI } from 'redux';
import type { State } from 'types/shared';

export default function govOsPaySideloadEpic(
  action$: ActionsObservable<any>,
  store: MiddlewareAPI<State>,
): Observable<any> {
  const loadPaymentConfigAction$ = action$
    .ofType(GET_GOVOS_PAYMENT_SETTINGS)
    .filter(pending);

  const loadProviderOptionsAction$ = loadPaymentConfigAction$
    .filter(() => {
      const state = store.getState();
      const providers = getGovOsPaymentProviders(state);
      return providers.length === 0;
    })
    // This is a hack to prevent the epic from firing multiple times
    // until we can update rxjs to support throttle with an action type
    .throttleTime(5000)
    .mergeMap(() => Observable.of(getProviderOptions()));

  return loadProviderOptionsAction$;
}
