import React, { forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import styles from './StyledText.css';

interface Props{
  id: string,
  label: string,
  cssClass: string,
}

function StyledText({id, label, cssClass}: Props, ref: React.Ref<unknown>) {
  useImperativeHandle(ref, () => ({ validate: () => true }));
  return (
    // eslint-disable-next-line react/no-danger
    <div id={`form_input_${id}`} className={classNames(styles.StyledText, cssClass)} dangerouslySetInnerHTML={{__html: label}}/>
  );
}

export default React.memo(forwardRef(StyledText));
