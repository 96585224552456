import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import {bulkActions, bulkActionTypes} from 'constants/submissionManager';
import FindAndReplaceStages from 'containers/SubmissionsManager/Modals/FindAndReplaceStages';
import FindAndReplaceTags from 'containers/SubmissionsManager/Modals/FindAndReplaceTags';
import MoveToStage from 'containers/SubmissionsManager/Modals/MoveToStage';
import AddTag from 'containers/SubmissionsManager/Modals/AddTag';
import FindAndReplaceAssignees from 'containers/SubmissionsManager/Modals/FindAndReplaceAssignees';
import {bindActionCreators} from 'redux';
import * as tableApi from 'actions/submissionsManager/tableApi';
import styles from './BulkActionWrapper.css';
import * as submissionsActions from 'actions/submissionsManager/submissionsActions';
import AddAssignee from './AddAssignee';
import ReplaceAssignees from './ReplaceAssignees';
import ReplaceTags from 'containers/SubmissionsManager/Modals/ReplaceTags';
import { userRolesEnum } from 'constants/user';

const BulkActionWrapper = ({
  isOpened,
  modalType,
  entityType,
  stages,
  tags,
  users,
  submissionIds,
  includeAllSubmissions,
  tableApiActions,
  allSubmissionIds,
  submissionsActions: { loaderModalToggle, bulkActionErrorModalToggle },
  formId,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [excludedSubmissionIds, setExcludedSubmissionIds] = useState([]);

  useEffect(() => {
    setExcludedSubmissionIds(allSubmissionIds.filter(id => !submissionIds.includes(id)));
  }, [submissionIds]);

  const onOutsideClick = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      tableApiActions.setBulkActionState({isOpened: false});
    }
  };

  useEffect(() => {
    window.addEventListener('click', onOutsideClick, true);

    return () => {
      window.removeEventListener('click', onOutsideClick, true);
    };
  }, []);

  const handleSubmit = submitFn => (...props) => {
    submitFn(...props);
    tableApiActions.setBulkActionState({isOpened: false});
  };

  const renderBulkActionModal = () => {
    switch (modalType) {
      case bulkActions.Add:
        switch (entityType) {
          case bulkActionTypes.Stages:
            return (<MoveToStage
              options={stages}
              submissionIds={submissionIds}
              includeAllSubmissions={includeAllSubmissions}
              excludedSubmissionIds={excludedSubmissionIds}
              moveToStage={handleSubmit(tableApiActions.moveToStage)}
              loaderModalToggle={loaderModalToggle}
              errorModalToggle={bulkActionErrorModalToggle}
              formId={formId}
            />);
          case bulkActionTypes.Assignees:
            return (<AddAssignee
              options={users}
              submissionIds={submissionIds}
              excludedSubmissionIds={excludedSubmissionIds}
              includeAllSubmissions={includeAllSubmissions}
              addAssignee={handleSubmit(tableApiActions.addAssignee)}
              loaderModalToggle={loaderModalToggle}
              errorModalToggle={bulkActionErrorModalToggle}
              formId={formId}
            />);
          case bulkActionTypes.Tags:
            return (<AddTag
              options={tags}
              submissionIds={submissionIds}
              excludedSubmissionIds={excludedSubmissionIds}
              includeAllSubmissions={includeAllSubmissions}
              addTag={handleSubmit(tableApiActions.addTag)}
              loaderModalToggle={loaderModalToggle}
              errorModalToggle={bulkActionErrorModalToggle}
              formId={formId}
            />);
          default:
            return null;
        }
      case bulkActions.FindAndReplace:
        switch (entityType) {
          case bulkActionTypes.Stages:
            return (<FindAndReplaceStages
              formId={formId}
              options={stages}
              submissionIds={submissionIds}
              excludedSubmissionIds={excludedSubmissionIds}
              includeAllSubmissions={includeAllSubmissions}
              findAndReplaceStages={handleSubmit(tableApiActions.findAndReplaceStages)}
              loaderModalToggle={loaderModalToggle}
              errorModalToggle={bulkActionErrorModalToggle}
            />);
          case bulkActionTypes.Tags:
            return (<FindAndReplaceTags
              formId={formId}
              options={tags}
              submissionIds={submissionIds}
              excludedSubmissionIds={excludedSubmissionIds}
              includeAllSubmissions={includeAllSubmissions}
              findAndReplaceTags={handleSubmit(tableApiActions.findAndReplaceTags)}
              loaderModalToggle={loaderModalToggle}
              errorModalToggle={bulkActionErrorModalToggle}
            />);
          case bulkActionTypes.Assignees:
            return (<FindAndReplaceAssignees
              formId={formId}
              options={users}
              submissionIds={submissionIds}
              excludedSubmissionIds={excludedSubmissionIds}
              includeAllSubmissions={includeAllSubmissions}
              findAndReplaceAssignees={handleSubmit(tableApiActions.findAndReplaceAssignees)}
              loaderModalToggle={loaderModalToggle}
              errorModalToggle={bulkActionErrorModalToggle}
            />);
          default:
            return null;
        }
      case bulkActions.Replace:
        switch (entityType) {
          case bulkActionTypes.Assignees:
            return (<ReplaceAssignees
              options={users}
              formId={formId}
              submissionIds={submissionIds}
              excludedSubmissionIds={excludedSubmissionIds}
              includeAllSubmissions={includeAllSubmissions}
              replaceAssignees={handleSubmit(tableApiActions.replaceAssignees)}
              loaderModalToggle={loaderModalToggle}
              errorModalToggle={bulkActionErrorModalToggle}
            />);
          case bulkActionTypes.Tags:
            return (<ReplaceTags
              options={tags}
              formId={formId}
              submissionIds={submissionIds}
              excludedSubmissionIds={excludedSubmissionIds}
              includeAllSubmissions={includeAllSubmissions}
              replaceTags={handleSubmit(tableApiActions.replaceTags)}
              loaderModalToggle={loaderModalToggle}
              errorModalToggle={bulkActionErrorModalToggle}
            />);
          default:
            return null;
        }
      default:
        return null;
    }
  };

  return isOpened && <div ref={wrapperRef} className={styles.Container}>
    {renderBulkActionModal()}
  </div>;
};

const mapStateToProps = state => {
  const {
    submissionTable: {
      bulkActionState: {
        isOpened,
        modalType,
        entityType,
      },
      table: {records},
      formId,
    },
    stages,
    tags,
    users,
  } = state;

  return {
    isOpened,
    modalType,
    entityType,
    formId,
    allSubmissionIds: records.map(record => record.submissionId),
    stages: stages[formId]?.map(stage => ({label: stage.name, value: stage.name})),
    tags: Object.keys(tags.tags).map(key => tags.tags[key]),
    users: Object.keys(users)
      .map(key => users[key])
      .filter(user => user.role !== userRolesEnum.VISITOR && user.isActive),
  };
};

const mapDispatchToProps = dispatch => ({
  tableApiActions: bindActionCreators(tableApi, dispatch),
  submissionsActions: bindActionCreators(submissionsActions, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionWrapper);
