import { CHECK_FORM_PASSWORD } from 'constants/types/formActionTypes';
import api from 'reducers/api';

export type State = {
  checkPasswordError: string | null;
  hasEnteredCorrectPassword: boolean;
  isCheckingPassword: boolean;
};

const initialState: State = {
  checkPasswordError: null,
  hasEnteredCorrectPassword: false,
  isCheckingPassword: false,
};

export default (state: State = initialState, action_): State => {
  const action = action_;
  switch (action.type) {
    case CHECK_FORM_PASSWORD:
      return api(action, state, {
        pending: () => ({
          ...state,
          checkPasswordError: null,
          isCheckingPassword: true,
          hasEnteredCorrectPassword: false,
        }),
        success: () => ({
          ...state,
          checkPasswordError: null,
          isCheckingPassword: false,
          hasEnteredCorrectPassword: true,
        }),
        failure: () => {
          const { payload: { error } } = action;
          const statusCode = error?.status;
          if (statusCode === 401) {
            return {
              ...state,
              isCheckingPassword: false,
              hasEnteredCorrectPassword: false,
              checkPasswordError: 'Incorrect password.',
            };
          }
          return {
            ...state,
            isCheckingPassword: false,
            hasEnteredCorrectPassword: false,
            checkPasswordError: error?.message || 'An unknown error has occurred.',
          };
        },
      });
    default:
      return state;
  }
};

export const getHasEnteredCorrectPassword = (state: State): boolean => state.hasEnteredCorrectPassword;

export const getIsCheckingPassword = (state: State): boolean => state.isCheckingPassword;

export const getCheckPasswordError = (state: State): string | null => state.checkPasswordError;
