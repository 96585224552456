import { GET_FORM_LIVE_VIEW } from 'constants/types/formActionTypes';
import api from '../api';

const initialState = Object.freeze({});

export default (state = initialState, action_) => {
  const action = action_;
  switch (action.type) {
    case GET_FORM_LIVE_VIEW:
      return api(action, state, {
        success: () => {
          const { payload: { form } } = action;
          return {
            ...state,
            form,
            error: null,
          };
        },
        failure: () => {
          const { payload: { error } } = action;
          return {
            ...state,
            form: null,
            error,
          };
        },
      });
    default:
      return state;
  }
};

export const getFormLiveViewById = (state, formId) => state.liveView[formId];
