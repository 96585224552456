import {Observable} from 'rxjs/Observable';
import {getIdToken, getCoregistrationToken, getUID, getMAID, isLoggedIn, hasValidCoregistrationToken} from 'utils/auth';
import {requestLogout} from 'actions/auth';
import {failureActionWith, pendingActionWith, successActionWith} from '../middleware/callAPI';
import samplingEpic from './sampling';
import {CALL_API, SERVER_URL} from '../env';
import {authTypes} from 'constants/api';
import {AUTHORIZATION_CHECK_SUCCESS} from 'constants/types/authActionTypes';


const shouldIncludeCredentials = requireAuth => (
  requireAuth === authTypes.WithAuth || requireAuth === authTypes.CoregAuth
);

const isFormData = headers => !headers['Content-Type'];

const tryGetCredentials = (
  requireAuth,
  state
) => {
  if (requireAuth === authTypes.WithAuth) {
    return {
      uid: getUID() || '',
      maid: getMAID() || '',
    };
  }
  return {
    uid: state.partialCoregistrationProfile ? state.partialCoregistrationProfile.auid : '',
    maid: state.partialCoregistrationProfile ? state.partialCoregistrationProfile.maid : '',
  };
};

const ensureCredentials = (data, requireAuth, state, headers) => {
  if (requireAuth === authTypes.WithAuth || requireAuth === authTypes.CoregAuth) {
    const credentials = tryGetCredentials(requireAuth, state);
    if (credentials.uid && credentials.maid) {
      if (isFormData(headers)) {
        data.append('credentials', credentials);

        return data;
      }
      return {
        ...data,
        credentials,
      };
    }
  }
  return isFormData(headers) ? data : {...data};
};

const getAndValidateIdTokenForRequest = requireAuth => {
  if (requireAuth === authTypes.WithAuth && isLoggedIn()) {
    return getIdToken();
  } else if (requireAuth === authTypes.CoregAuth && hasValidCoregistrationToken()) {
    return getCoregistrationToken();
  }
  return '';
};

const ensureHeaders = (headers, requireAuth, store) => {
  if (shouldIncludeCredentials(requireAuth)) {
    const idTokenForRequest = getAndValidateIdTokenForRequest(requireAuth);
    if (idTokenForRequest) {
      return {...headers, Authorization: `Bearer ${idTokenForRequest}`};
    }
    store.dispatch(requestLogout());
    return {};
  }
  return headers || {};
};

function fetchObservable(request) {
  return Observable.ajax(request);
}

function fetchResponse(
  request,
  baseType,
  store,
  payloads
) {
  return fetchObservable(request)
    .map(response => successActionWith(baseType, store, payloads.success, response))
    .catch(error =>
      Observable.of(failureActionWith(baseType, store, payloads.failure, error))
    );
}

export default function apiEpic(action$, store) {
  const authSuccess$ = action$.ofType(AUTHORIZATION_CHECK_SUCCESS)
    .take(1);

  const pendingApi$ = action$
    .ofType(CALL_API)
    .letBind(samplingEpic)
    .do(({baseType, payloads}) => {
      store.dispatch(pendingActionWith(baseType, payloads.pending, null));
    });

  const withAuth$ = pendingApi$.filter(({requireAuth}) => requireAuth === authTypes.WithAuth);
  const withoutAuth$ = pendingApi$.filter(({requireAuth}) => requireAuth === authTypes.NoAuth);
  const coregAuth$ = pendingApi$.filter(({requireAuth}) => requireAuth === authTypes.CoregAuth);

  return Observable
    .combineLatest(withAuth$, authSuccess$, action => action)
    .merge(withoutAuth$)
    .merge(coregAuth$)
    .mergeMap(action => {
      const {baseType, body, endpoint, headers = {}, method, payloads, requireAuth} = (action as any);

      const state = store.getState().auth;
      const data = ensureCredentials(body, requireAuth, state, headers);
      const request = {
        body: isFormData(headers) ? data : JSON.stringify(data),
        headers: ensureHeaders(headers, requireAuth, store),
        method,
        url: `${SERVER_URL}${endpoint}`,
      };
      return fetchResponse(request, baseType, store, payloads);
    });
}
