import {combineReducers} from 'redux';
import auth, * as authUiSelectors from './auth';
import automatedProcessBuilder, * as apbUiSelectors from './automatedProcessBuilder';
import dataSync, * as dataSyncSelectors from './dataSync';
import stages, * as stageUiSelectors from './stages';
import liveViewGating, * as liveViewGatingSelectors from './liveViewGating';
import quickFilters, * as quickFilterSelectors from './quickFilters';
import assets, * as assetSelectors from './assets';


export default combineReducers({
  assets,
  auth,
  automatedProcessBuilder,
  liveViewGating,
  stages,
  quickFilters,
  dataSync,
});

/* SELECTORS */

/* STAGES */
export const getSelectedStageName = state =>
  stageUiSelectors.getSelectedStageName(state.stages);

/* LIVE VIEW GATING */
export const getCurrentPredicateInstruction = state =>
  liveViewGatingSelectors.getCurrentPredicateInstruction(state.liveViewGating);

export const getPredicateFetchingState = state =>
  liveViewGatingSelectors.getPredicateFetchingState(state.liveViewGating);

export const getGatedLiveViewUrl = state =>
  liveViewGatingSelectors.getGatedLiveViewUrl(state.liveViewGating);

/* AUTH */
export const getAuthSubmissionState = state => authUiSelectors.getAuthSubmissionState(state.auth);

/* QUICK FILTERS */
export const getSelectedQuickFilter = state =>
  quickFilterSelectors.getSelectedQuickFilter(state.quickFilters);

export const getSelectedSubmissionQuickFilter = state =>
  quickFilterSelectors.getSelectedSubmissionQuickFilter(state.quickFilters);

/* ASSETS */

export const getSignedUrlError = state => assetSelectors.getSignedUrlError(state.assets);

export const getFetchingAssetState = state =>
  assetSelectors.getFetchingAssetState(state.assets);

export const getSignedUrl = state =>
  assetSelectors.getSignedUrl(state.assets);

/* AUTOMATED PROCESS BUILDER */

export const isApbVisible = state => apbUiSelectors.isApbVisible(state.automatedProcessBuilder);

export const getVisibleEmailRuleId = state => apbUiSelectors.getVisibleEmailRuleId(state.automatedProcessBuilder);

export const isApbLoading = state => apbUiSelectors.isApbLoading(state.automatedProcessBuilder);

export const getInitiations = state => dataSyncSelectors.getInitiations(state.dataSync);
export const getIsLoading = state => dataSyncSelectors.getIsLoading(state.dataSync);
