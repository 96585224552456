import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import * as filestack from 'filestack-js';
import { FILEPICKER_KEY } from '../../env';
import * as Colors from 'utils/colors';
import { findDOMNode } from 'utils/reactDOM';
import { DOC_UPLOAD_EXTENSIONS, SOURCES, DOCUMENT_UPLOAD_DIR } from 'constants/filepicker';
import { LEGACY_NEW_WEBFORM, LEGACY_BROWSE_GALLERY } from 'constants/route';
import { datadogLogs } from '@datadog/browser-logs';

import style from './header.css';
import Button from 'components/Button';
import NotificationLoaderModal from 'components/Modals/NotificationLoaderModal';
import injectFeatures from 'decorators/injectFeatures';


const inlineStyle = {
  headerIcon: {
    fontSize: '22px',
    color: Colors.white,
    marginRight: '16px',
    verticalAlign: 'top',
  },
  menuItemIcon: {
    fontSize: '20px',
    color: Colors.grey600,
    height: '24px',
    margin: '12px',
    position: 'absolute',
    top: 0,
    left: '4px',
    width: '24px',
  },
  menuItemTextPrimary: {
    color: Colors.textPrimary,
    fontSize: '14px',
    margin: 0,
    lineHeight: '20px',
  },
  menuItemTextSecondary: {
    color: Colors.textSecondary,
    fontSize: '12px',
    margin: 0,
    lineHeight: '20px',
    whiteSpace: 'pre-line',
  },
};


class CreateMenuHeader extends React.Component {
  render() {
    return (
      <div className={style.menuHead}>
        <Icon style={inlineStyle.headerIcon}>add_circle</Icon>
        <span className={style.menuTitle}>Create</span>
      </div>
    );
  }
}

export class CreateForm extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      openCreateForm: false,
      overlayOpen: false,
      showNewFormTransition: false,
    };
    this.fileStackClient = filestack.init(FILEPICKER_KEY);
  }

  componentDidMount() {
    this.createButtonContainerEl = findDOMNode(this.refs.createButtonContainer);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.overlayOpen && this.state.overlayOpen) {
      const {
        onUploadNewDocumentSuccess,
        onUploadNewDocumentFailure,
      } = this.props;
      const options = {
        accept: DOC_UPLOAD_EXTENSIONS,
        fromSources: SOURCES,
        storeTo: {
          location: 'S3',
          path: DOCUMENT_UPLOAD_DIR,
        },
        uploadInBackground: false,
        onUploadDone: ({ filesUploaded }) => {
          if (filesUploaded.length > 0) {
            const fileUrl = filesUploaded[0].url;
            const path = `/build/upload?file_url=${encodeURIComponent(fileUrl)}`;

            onUploadNewDocumentSuccess(filesUploaded[0]);
            this.handleNewFormTransitionToggle();
            window.location.href = path;
          } else {
            onUploadNewDocumentFailure('An error has occurred with your upload. Please try again or contact customer support.');
          }
        },
        onFileUploadFailed: error => {
          if (error.code >= 400) {
            onUploadNewDocumentFailure('An error has occurred with your upload. Please try again or contact customer support.');
            datadogLogs.logger.error('Create Form - File Upload Error', new Error(JSON.stringify(error)) as any);
          }
        },
        onClose: () => {
          this.setState({ overlayOpen: false });
        },
      };
      this.fileStackClient.picker(options).open();
    }
  }

  createButtonContainerEl;
  fileStackClient;

  renderMenu() {
    const { DISABLE_DOC_UPLOAD } = this.props.features;
    return (
      <Menu
        anchorEl={this.createButtonContainerEl}
        getContentAnchorEl={null} // We get a warning and cannot use anchorOrigin without this
        open={this.state.openCreateForm}
        classes={{
          paper: style.createFormPopover,
        }}
        onClose={this.handleCloseCreateForm}
        MenuListProps={{
          disablePadding: true,
        }}>
        <CreateMenuHeader />
        {!DISABLE_DOC_UPLOAD && <MenuItem
          classes={{ root: style.menuItemChildWrap }}
          onClick={this.onDocumentUpload}>
          {/* @ts-ignore*/}
          <Icon style={{ ...inlineStyle.menuItemIcon, fontSize: '24px' }}>file_upload</Icon>
          <p style={inlineStyle.menuItemTextPrimary}>
            Upload Document
          </p>
          {/* @ts-ignore*/}
          <p style={inlineStyle.menuItemTextSecondary}>
            Upload a document to convert it into a Doc
          </p>
        </MenuItem>
        }
        <MenuItem
          classes={{ root: style.menuItemChildWrap }}
          onClick={this.onNewWebform}>
          {/* @ts-ignore*/}
          <Icon style={inlineStyle.menuItemIcon}>build</Icon>
          <p style={inlineStyle.menuItemTextPrimary}>
            Build a Web Form
          </p>
          {/* @ts-ignore*/}
          <p style={inlineStyle.menuItemTextSecondary}>
            Create a custom web form with the Form Builder
          </p>
        </MenuItem>
        <MenuItem
          classes={{ root: style.menuItemChildWrap }}
          onClick={this.onBrowseGallery}>
          {/* @ts-ignore*/}
          <Icon style={inlineStyle.menuItemIcon}>format_paint</Icon>
          <p style={inlineStyle.menuItemTextPrimary}>
            Browse Templates
          </p>
          {/* @ts-ignore*/}
          <p style={inlineStyle.menuItemTextSecondary}>
            Select from a range of pre-made documents and forms
          </p>
        </MenuItem>
      </Menu>
    );
  }

  onDocumentUpload = event => {
    event.preventDefault();

    this.handleCloseCreateForm();
    this.setState({ overlayOpen: true });
  }

  onNewWebform = event => {
    event.preventDefault();
    this.handleCloseCreateForm();
    this.handleNewFormTransitionToggle();
    window.location.href = LEGACY_NEW_WEBFORM;
  }

  onBrowseGallery = event => {
    event.preventDefault();
    this.handleCloseCreateForm();
    window.location.href = LEGACY_BROWSE_GALLERY;
  }

  handleOpenCreateForm = () => {
    this.setState({
      openCreateForm: true,
    });
  }

  handleCloseCreateForm = () => {
    this.setState({
      openCreateForm: false,
    });
  }

  handleNewFormTransitionToggle = () => {
    this.setState(state => ({
      showNewFormTransition: !state.showNewFormTransition,
    }));
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.overlayOpen &&
          <div className={style.overlay} />
        }
        <span ref='createButtonContainer'>
          <div className={style.createFormContainer}>
            <Button
              label='Create'
              primary
              onClick={this.handleOpenCreateForm} />
            {this.renderMenu()}
          </div>
          <NotificationLoaderModal
            open={this.state.showNewFormTransition}
            loading
            handleNotificationModalClose={this.handleNewFormTransitionToggle}
            onClose={this.handleNewFormTransitionToggle}
            status='loadingNewDoc'
            title='Loading Builder Interface'
            bodyText='This will only take a moment...' />
        </span>
      </React.Fragment>
    );
  }
}

export default injectFeatures(CreateForm);
