import api from 'actions/api';
import {
  ADD_EMPTY_RULE,
  DELETE_RULE,
  MODIFY_RULE,
  LOAD_RULES,
  SAVE_RULES,
  TRACK_CONDITIONAL_SETTINGS,
} from 'constants/types/conditionalsActionTypes';
import {
  AddEmptyRuleActionCreator,
  ConditionalRule,
  ConditionalRuleForServer,
  DeleteRuleActionCreator,
  ModifyRuleActionCreator,
  TrackConditionalsActionCreator,
} from 'types/conditionals';
import { FormFields } from 'types/formFields';
import { CurrentParticipant, Participant, Submitter } from 'types/participants';
import { convertRulesForServer } from 'utils/conditionals';

export const addEmptyRule: AddEmptyRuleActionCreator = (
  formId: string,
  frontendId: string,
) => ({
  type: ADD_EMPTY_RULE,
  formId,
  frontendId,
});

export const deleteRule: DeleteRuleActionCreator = (
  formId: string,
  frontendId: string,
) => ({
  type: DELETE_RULE,
  formId,
  frontendId,
});

export const modifyRule: ModifyRuleActionCreator = (
  formId: string,
  rule: ConditionalRule,
) => ({
  type: MODIFY_RULE,
  formId,
  rule,
});

export function loadRules(formId: string, existingParticipants: Participant[]) {
  return api.postWithAuth({
    baseType: LOAD_RULES,
    endpoint: 'conditionalSignatureRules/get.json',
    data: {
      formId,
    },
    pending: { formId },
    success: (state, { response: { result } }) => ({ formId, rules: result, existingParticipants }),
    failure: (state, error) => {
      console.error(error); // eslint-disable-line no-console

      return {
        error,
        formId,
        rules: error?.xhr?.response?.message ? error?.xhr?.response?.message : [],
        existingParticipants,
      };
    },
  });
}

export function saveRules(
  formId: string,
  rules: ConditionalRule[],
  existingParticipants: Participant[],
  submitter: Submitter | Participant,
) {
  const rulesForServer: ConditionalRuleForServer[] = convertRulesForServer(rules, existingParticipants, submitter);
  return api.postWithAuth({
    baseType: SAVE_RULES,
    endpoint: 'conditionalSignatureRules/save.json',
    data: {
      formId,
      rules: rulesForServer,
    },
    success: (state, { response: { result } }) => ({ formId, result }),
    failure: (state, error) => {
      console.error(error); // eslint-disable-line no-console
      return {
        error,
        formId,
      };
    },
  });
}

export const trackConditionalsChanges: TrackConditionalsActionCreator = (
  currentConditionals: ConditionalRule[],
  previousConditionals: ConditionalRule[],
  currentParticipant: CurrentParticipant,
  formFields: FormFields,
) => ({
  type: TRACK_CONDITIONAL_SETTINGS,
  currentConditionals,
  previousConditionals,
  currentParticipant,
  formFields,
});
