import React, {useState, useRef, forwardRef, useImperativeHandle, useEffect} from 'react';
import moment from 'moment';
import FormFullName from '../FormFullName/FormFullName';
import FormInput from '../FormInput/FormInput';
import AgreementCheckbox from '../AgreementCheckbox/AgreementCheckbox';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import SignatureCanvas from './SignatureCanvas';
import style from './FormSignature.css';
import sharedStyle from '../shared.css';

const blank = document.createElement('canvas');
blank.width = 400;
blank.height = 150;

function FormSignature(
  {
    id,
    updateForm,
    required,
    extraData: {showLabel, label, hideDisclaimer, customLegalText},
  }, ref) {
  const canvasRef = useRef<any>(null);
  const [typedSig, setTypedSig] = useState('');
  const [signatureError, setSignatureError] = useState('');
  const [sigChoiceDraw, setChoiceDraw] = useState(true);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const fullNameRef = useRef<any>(null);
  const agreementRef = useRef<any>(null);
  const emailRef = useRef<any>(null);
  const typeRef = useRef<any>(null);
  const [dataUrl, setDataUrl] = useState(blank.toDataURL());
  const [timestamp, setTimestamp] = useState('');
  const [email, setEmail] = useState('');
  const [fullNameData, setFullNameData] = useState({firstName: '', lastName: ''});

  useEffect(() => {
    const now = new Date();
    const dt = new Date(now.getTime() + now.getTimezoneOffset() * 60 * 1000);
    updateForm({
      fields: {
        first_name: fullNameData.firstName,
        last_name: fullNameData.lastName,
        email,
        image: !!dataUrl ? dataUrl : blank.toDataURL(),
        timestamp: !!timestamp ? timestamp : moment(dt).format('MM/DD/YYYY h:mmA'),
      },
    });
  }, [dataUrl, fullNameData, email, timestamp]);

  const clearSignature = event => {
    event && event.preventDefault();
    setDataUrl('');
    canvasRef.current && canvasRef.current.clearCanvas();
  };

  const handleTypedSignatureChange = event => {
    event.preventDefault();
    setTypedSig(event.target.value);
  };

  const handleSigTypeChoice = draw => () => {
    setChoiceDraw(draw);
    canvasRef.current?.clearCanvas();
    setTypedSig('');
  };

  const handleEmailChange = e => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleSignatureChange = data => {
    setDataUrl(data);
    setSignatureError('');
    const now = new Date();
    const dt = new Date(now.getTime() + now.getTimezoneOffset() * 60 * 1000);
    setTimestamp(moment(dt).format('MM/DD/YYYY h:mmA'));
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: () => {
        const isValid = fullNameRef.current.validate() &
          (hideDisclaimer === 'f' ? agreementRef.current.validate() : true) &
          (typeRef.current ? typeRef.current.validate() : true) &
          emailRef.current.validate();

        if ((!dataUrl || dataUrl === blank.toDataURL())) {
          setSignatureError('Signature is required');
          return false;
        }
        setSignatureError('');

        return isValid;
      },
    }));

  return (
    <fieldset className={sharedStyle.Fieldset} id={`container_${id}`}>
      {showLabel === 't' && <legend>{label}</legend>}
      <FormFullName
        id={id} ref={fullNameRef}
        updateForm={data => setFullNameData(data.fields)}
        fullNameSettings={required ?
          {isFirstNameRequired: true, isLastNameRequired: true, isInitialRequired: false, showMiddleInitial: false} :
          {isFirstNameRequired: false, isLastNameRequired: false, isInitialRequired: false, showMiddleInitial: false}}
        data-testid='sig_fullname'
      />
      <FormInput ref={emailRef} type='email' id={id} label='Email' value={email} onChange={handleEmailChange} required={required} autoComplete='email' validationType='email' data-testid='sig_email'/>
      <div>
        <input type='radio' checked={sigChoiceDraw} onChange={handleSigTypeChoice(true)} name='sig_choice' id={`rad_draw_${id}`} /><label htmlFor={`rad_draw_${id}`}>&nbsp;Draw</label>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <input type='radio' checked={!sigChoiceDraw} onChange={handleSigTypeChoice(false)} name='sig_choice' id={`rad_type_${id}`} /><label htmlFor={`rad_type_${id}`}>&nbsp;Type</label>
        {sigChoiceDraw ? (<div style={{ height: 30 }} />) : (<FormInput ref={typeRef} id={`sig_type_${id}`} placeholder='Type here...' value={typedSig} onChange={handleTypedSignatureChange} required={required} />)}
      </div>
      <ErrorContainer error={signatureError}>
        <div className={style.SignatureWrapper}>
          <SignatureCanvas
            allowDrawing={sigChoiceDraw}
            typedSignature={typedSig}
            ref={canvasRef}
            id={`sig_canvas_${id}`}
            onChange={handleSignatureChange}
            width='400'
            height='150'
            className={style.Canvas}
          />
          <button onClick={clearSignature} className={style.Button}>Clear Signature</button>
        </div>
      </ErrorContainer>
      {hideDisclaimer === 'f' && <AgreementCheckbox
        id={id}
        ref={agreementRef}
        onChange={() => {
          setIsAgreementChecked(!isAgreementChecked);
        }}
        customLegalText={customLegalText}
        checked={isAgreementChecked}
        required
      />}
      <br /><br /><br />
    </fieldset>
  );
}

export default React.memo(forwardRef(FormSignature));
