import {GET_FORM_PUBLIC_INFO} from 'constants/types/formActionTypes';
import api from '../api';


const initialState = Object.freeze({});

export default (state = initialState, action_) => {
  const action = action_;
  switch (action.type) {
    case GET_FORM_PUBLIC_INFO:
      return api(action, state, {
        success: () => {
          const {payload: {formId, form}} = action;
          return {
            ...state,
            [formId]: form,
          };
        },
      });
    default:
      (action);
      return state;
  }
};

export const getFormPublicInfoById = (state, formId) => state[formId];
