import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NewAuthLayout from '../../components/AuthLayout/NewAuthLayout';
import NewShowPasswordField from '../../components/Login/NewShowPasswordField';
import * as authActions from 'actions/auth';
import * as authUtils from 'utils/auth';
import style from '../../components/AuthLayout/newAuthStyles.css';
import { Link } from 'react-router';
import { resetPassword } from 'utils/auth';


import jwtDecode from 'jwt-decode';


const INVALID_PASSWORD = 'Your password must have at least: 1 uppercase, 1 lowercase, 1 number, 8 characters.';
const INVALID_CONFIRM = 'Your passwords don\'t seem to match.';
const PASSWORD_PATTERN = '^\\S*(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\w\\d@%+\\\\\\/\'!#$^?:,(){}\\[\\]~\\-_.]{8,}$';

function QuickLogin({ username, password, subdomain, dispatch }) {
  useEffect(() => {
    authUtils.signIn({
      username,
      password,
      subdomain,
    }, dispatch);
    // can't tell when this is done and router redirect
    // doesn't work as expected from useEffect or componentDidMount
    // really need to replace this state management system
    setTimeout(() => {
      location.href = '/ng/login'; // let normal login flow take it from here
    }, 3000);
  }, []);
  return (<></>);
}

class ResetPassword extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      newPasswordError: '',
      confirmPasswordError: '',
      jwt: { exp: 0, error: '' },

    };
  }

  componentDidMount() {
    const { dispatch, maid } = this.props;
    if (!maid) { // if we have a maid, the state has already been filled
      dispatch(authActions.getAccountKey(dispatch));
    }
    this.attemptTokenValidation();
  }

  attemptTokenValidation() {
    const { params: { token }, router, location } = this.props;
    try {
      const jwt = jwtDecode(token);
      const expired = (jwt.exp * 1000) < Date.now();
      if (expired) {
        // redirect
        router.replace(location.pathname.replace(new RegExp(token + '$'), 'expired'));
      }
      this.setJwt(jwt);
    } catch (e) {
      this.setJwt({
        error: 'The provided token is invalid.',
      });
    }
  }

  setJwt = jwt => {
    this.setState({ jwt });
  }

  resetPasswordClicked = event => {
    event.preventDefault();
    const { newPassword, confirmPassword } = this.state;
    const form = event.target;
    const confirmPasswordError = newPassword === confirmPassword ? '' : INVALID_CONFIRM;
    const newPasswordError = form.checkValidity() ? '' : INVALID_PASSWORD;

    if (confirmPasswordError || newPasswordError) {
      this.setState({
        confirmPasswordError,
        newPasswordError,
      });
    } else {
      const { params: { token }, dispatch } = this.props;
      resetPassword(newPassword, token, dispatch);
    }
  }

  newPasswordChanged = event => {
    event.preventDefault();
    const nState = {
      newPassword: event.target.value,
      newPasswordError: event.target.validity.valid ? '' : INVALID_PASSWORD,
    };
    this.setState(nState);
  }

  confirmPasswordChanged = event => {
    event.preventDefault();
    const confirmPassword = event.target.value;
    const { newPassword } = this.state;
    const nState = {
      confirmPassword,
      confirmPasswordError: event.target.validity.valid && newPassword === confirmPassword ? '' : INVALID_CONFIRM,
    };
    this.setState(nState);
  };

  renderResetPasswordForm() {
    const {
      isFetching,
      resetPasswordError,
    } = this.props;
    const {
      newPassword,
      confirmPassword,
      newPasswordError,
      confirmPasswordError,
    } = this.state;
    const errMsg = newPasswordError || resetPasswordError;
    return (
      <form onSubmit={this.resetPasswordClicked} noValidate>
        <NewShowPasswordField
          className={style.fieldContainer}
          onChange={this.newPasswordChanged}
          label='Pick a Password *'
          value={newPassword}
          id='newPassword'
          name='newPassword'
          inputClassName={errMsg ? 'invalid' : ''}
          errorMessage={errMsg}
          inputProps={{
            required: true,
            pattern: PASSWORD_PATTERN,
            tabIndex: 1,
            placeholder: 'New Password',
            'aria-label': 'password',
            autoComplete: 'new-password',
            autoFocus: true,
          }}
        />
        <NewShowPasswordField
          className={style.fieldContainer}
          onChange={this.confirmPasswordChanged}
          id='confirmPassword'
          name='confirmPassword'
          inputClassName={confirmPasswordError ? 'invalid' : ''}
          label='Confirm Password *'
          value={confirmPassword}
          errorMessage={confirmPasswordError}
          inputProps={{
            required: true,
            tabIndex: 2,
            placeholder: 'Confirm Password',
            'aria-label': 'password',
            autoComplete: 'new-password',
          }}
        />
        <br />
        <button tabIndex={3} className='authButton' disabled={isFetching}>Set Password</button>
      </form>
    );
  }

  renderSuccessMessage() {
    const { dispatch, accountKey } = this.props;
    const { jwt, newPassword } = this.state;
    return (<p>
      <QuickLogin
        dispatch={dispatch}
        username={jwt.email}
        password={newPassword}
        subdomain={accountKey}
      />
      Your password has been successfully reset. <br />
      You will be logged in in a few moments.<br />
    </p>);
  }

  renderPage() {
    const { resetPasswordSuccess } = this.props;
    if (resetPasswordSuccess) return this.renderSuccessMessage();
    return this.renderResetPasswordForm();
  }

  render() {
    const {
      isFetchingOrganizationInfo,
      isSubdomainExists,
      companyName,
      organizationLogo,
      resetPasswordSuccess,
    } = this.props;
    const { jwt: { error } } = this.state;
    const subtitle = 'Create and confirm a new password for your account.';
    const fetching = (isFetchingOrganizationInfo || !isSubdomainExists) && !resetPasswordSuccess;
    return (
      <NewAuthLayout
        title='Reset Your Password'
        subtitle={error ? '' : subtitle}
        fetching={fetching}
        companyName={companyName}
        logo={organizationLogo}
        error={error}
      >
        <div>
          <div className='sub-container'>
            {error ? null : this.renderPage()}
            <Link to='/ng/login'>Login</Link>
          </div>
        </div>
      </NewAuthLayout >
    );
  }
}


const mapStateToProps = state => {
  const {
    auth: {
      accountKey,
      companyName,
      error,
      isFetching,
      isFetchingOrganizationInfo,
      isSubdomainExists,
      maid,
      organizationLogo,
      resetPasswordError,
      resetPasswordSuccess,
    },
  } = state;
  return {
    accountKey,
    companyName,
    error,
    isFetching,
    isFetchingOrganizationInfo,
    isSubdomainExists,
    maid,
    organizationLogo,
    resetPasswordError,
    resetPasswordSuccess,
  };
};

export default connect(mapStateToProps)(ResetPassword);
