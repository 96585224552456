import jwtDecode from 'jwt-decode';

export const isLinkExpired = (token: string | null): boolean => {
  if (!token) return false;
  const decodedToken = jwtDecode(token);
  if (!decodedToken.exp) return false;
  return decodedToken.exp * 1000 < Date.now();
};

export const shouldDisplayDownloadLink = (
  pdfUrl: string | null,
  pdfUrlClicked: boolean,
  token: string | null,
): boolean => Boolean(pdfUrl && token && !pdfUrlClicked && !isLinkExpired(token));

export const shouldDisplayExpiredLinkMessage = (
  pdfUrl: string | null,
  pdfUrlClicked: boolean,
  token: string | null,
) => Boolean(pdfUrl && token && (pdfUrlClicked || isLinkExpired(token)));
