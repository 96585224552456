import {TOOLTIP_IO_API_KEY, TOOLTIP_LOBBY_START_CAMPAIGN_ID} from '../env';
import {once} from 'lodash';

const ensureTooltipAvailable = (cb: Function) =>
  (window as any).Tooltip ? cb() : setTimeout(() => ensureTooltipAvailable(cb), 1000);

export const loadTooltip = once(() => {
  const t = document.createElement('script');
  t.type = 'text/javascript';
  document.body?.appendChild(t);
  t.async = true;
  t.src = `https://cdn.tooltip.io/static/player.js?apiKey=${TOOLTIP_IO_API_KEY}`;
});

export const startLobbyTour =
  () => ensureTooltipAvailable(() => (window as any).Tooltip.API.show(TOOLTIP_LOBBY_START_CAMPAIGN_ID, {}));
