import { NAV_BAR_SEARCH_INPUT } from 'types/navBarSearch';

const initialState = {
  searchBarInput: [],
};

export default function navBarSearch(state = initialState, action) {
  switch (action.type) {
    case NAV_BAR_SEARCH_INPUT: {
      return {
        ...state,
        searchBarInput: action.searchBarInput,
      };
    }
    default:
      return state;
  }
}
