import * as vfs from './vfs';
import {
  FOLDER_TREE_ROOT_NAME,
  QUICK_FILTERS_NAME,
} from 'constants/applicationCopy';
import {LOBBY_ROOT_FOLDER} from 'constants/tableConsts';

export function isAncestor(maybeAncestor: any, maybeDescendant: any) {
  return maybeAncestor.isAncestorOf(
    maybeDescendant,
    (ancestor, descendant) => ancestor.meta.folderId === descendant.meta.folderId
  );
}

export function createNewRootFromData(data: any[]) {
  const root = new vfs.Dir(FOLDER_TREE_ROOT_NAME, {
    folderId: LOBBY_ROOT_FOLDER,
    collapsed: false,
    loading: false,
  });
  data.map(node => {
    if ('folderId' in node) {
      root.add(new vfs.Dir(node.name, {...node, collapsed: true, loading: false}));
    }
  });
  return root;
}

/** Receives breadcrumbs from server in format
 * [
 *  { folderId: '1', folderName: 'folder'},
 *  { folderId: '2', folderName: 'subfolder1'},
 *  { folderId: '3', folderName: 'subfolder2'},
 * ]
 * and transforms it into new tree, new current node (the last element of input's array) and path to this node. */
export const processGetFolderBreadcrumbsPayload = (
  data: { folderName: string, folderId: string }[],
): [vfs.Dir, vfs.Dir, string[]] => {
  const root: vfs.Dir = new vfs.Dir(FOLDER_TREE_ROOT_NAME, {
    folderId: LOBBY_ROOT_FOLDER,
    collapsed: false,
    loading: false,
  });
  let path: string[] = [''];
  let newCurrentNode: vfs.Dir = root;
  if (data.length) {
    let prevNode: vfs.Dir = root;
    for (let i = 0; i < data.length; i++) {
      const { folderName, folderId } = data[i];
      prevNode.add(new vfs.Dir(folderName, { folderId }));
      prevNode = prevNode.getChild(folderName);
    }
    path = ['', ...data.map(node => node.folderName)];
    newCurrentNode = prevNode;
  }
  return [root, newCurrentNode, path];
};

export function injectDataByDirFolderId(dir: any, data: any[]) {
  const newDir = new vfs.Dir(dir.name, {...dir.meta, collapsed: false, loading: false});
  data.map(node => {
    if ('folderId' in node) {
      newDir.add(new vfs.Dir(node.name, {...node, collapsed: true}));
    }
  });
  if (dir.parent) newDir.mount(dir.parent);
  return vfs.findRoot(newDir).copy();
}

export function isChild(child: any, parent: any) {
  return child.parent ? (child.parent.meta.folderId === parent.meta.folderId) : false;
}

export function isNameCollision(child: any, parent: any) {
  return !!parent.getChild(child.name);
}

export function getPathForUI(selectedFilter: string, path: string[]) {
  if (selectedFilter) {
    return [QUICK_FILTERS_NAME].concat(selectedFilter);
  }
  const fullPath = [FOLDER_TREE_ROOT_NAME].concat(path.filter(Boolean));
  return fullPath;
}
