import React from 'react';
import styles from './CollapsibleSidebar.css';
import { ArrowIcon, HamburgerIcon, CrossIcon } from './icons';
import { joinClassNames } from 'utils/strings';


export const COLLAPSIBLE_SIDEBAR_COLLAPSED_WIDTH = 64;
export const COLLAPSIBLE_SIDEBAR_EXPANDED_WIDTH = 225;

export default class CollapsibleSidebar extends React.PureComponent<any, any> {
  renderHamburgerSidebarHeader = () => {
    const { hamburgerHeader, isExpanded } = this.props;
    if (isExpanded) {
      return (
        <div className={styles.flexContainer}>
          <p>{hamburgerHeader}</p>
          <CrossIcon onClick={this.handleOnClick} />
        </div>
      );
    }
    return <HamburgerIcon onClick={this.handleOnClick} />;
  }

  handleOnClick = () => this.props.onClick();

  render() {
    const { children: SidebarDetails, isHamburger, isExpanded } = this.props;
    const sidebarClass = `${styles.sidebar} ${isExpanded ? styles.expandedSidebar : ''}`;

    if (isHamburger) {
      return (
        <div className={styles.sidebarContainer}>
          <div className={sidebarClass}>
            <div className={styles.hamburgerHeader}>{this.renderHamburgerSidebarHeader()}</div>
            {SidebarDetails}
          </div>
          {isExpanded && <div className={styles.overlay} onClick={this.handleOnClick} />}
        </div>
      );
    }

    const sidebarButtonClass = joinClassNames(
      styles.sidebarButton,
      isExpanded ? styles.buttonExpanded : styles.buttonCollapsed);

    return (
      <div>
        <div className={sidebarClass}>
          {SidebarDetails}
        </div>
        <button className={sidebarButtonClass} onClick={this.handleOnClick}>
          <ArrowIcon title={isExpanded ? 'Collapse sidebar' : 'Expand sidebar'} />
        </button>
      </div>
    );
  }
}

