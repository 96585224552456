import React from 'react';
import Modal from './Modal';


const desktopWidths = {
  large: '700px',
};

const LargeModal = props => <Modal {...props} desktopWidth={desktopWidths.large} />;

LargeModal.defaultProps = {
  disableBackdropClick: false,
};

export default LargeModal;
