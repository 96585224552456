// We have to use explicit annotations to force Flow into typing these
// as string literals rather than strings.

export const EDIT_ACKNOWLEDGEMENT
                                                     =
  'EMBED_COVER_LETTER_SETTINGS_EDIT_ACKNOWLEDGEMENT';
export const EDIT_COVER_LETTER
                                                  =
  'EMBED_COVER_LETTER_SETTINGS_EDIT_COVER_LETTER';
export const GET_FORM_SETTINGS = 'EMBED_COVER_LETTER_SETTINGS_GET_FORM_SETTINGS';
export const SAVE_FORM_SETTINGS
                                                   =
  'EMBED_COVER_LETTER_SETTINGS_SAVE_FORM_SETTINGS';
export const SET_FORM_ID
                                            =
  'EMBED_COVER_LETTER_SETTINGS_SET_FORM_ID';
export const TOGGLE_MODAL
                                             =
  'EMBED_COVER_LETTER_SETTINGS_TOGGLE_MODAL';
export const TOGGLE_REQUIRE_ACKNOWLEDGEMENT
                                                               =
  'EMBED_COVER_LETTER_SETTINGS_TOGGLE_REQUIRE_ACKNOWLEDGEMENT';
export const TOGGLE_SNACKBAR
                                                =
  'EMBED_COVER_LETTER_SETTINGS_TOGGLE_SNACKBAR';
export const TOGGLE_USE_COVERLETTER
                                                       =
  'EMBED_COVER_LETTER_SETTINGS_TOGGLE_USE_COVERLETTER';
