export const TOGGLE_MODAL = 'EMBED_DATA_SYNC_SIDEBAR_TOGGLE_MODAL';
export const LOAD_DATA_SYNC_CONFIG = 'LOAD_DATA_SYNC_CONFIG';
export const SAVE_DATA_SYNC_CONFIG = 'SAVE_DATA_SYNC_CONFIG';
export const SAVE_DATA_SYNC_INITIATIONS = 'SAVE_DATA_SYNC_INITIATIONS';
export const GET_PARAMS = 'GET_PARAMS';
export const SET_CURRENT_CONFIG = 'SET_CURRENT_CONFIG';
export const SET_IS_CONNECTION_SUCCESSFUL = 'SET_IS_CONNECTION_SUCCESSFUL';
export const SET_MESSAGE = 'SET_MESSAGE';
export const GET_FORM_DETAILS = 'GET_FORM_DETAILS';
export const DELETE_CONFIGURATION = 'DELETE_CONFIGURATION';
export const LOADER_VISIBILITY = 'DATA_SYNC_LOADER_VISIBILITY';
