import React, { useState } from 'react';

import Button from 'components/Button';
import Loader from 'components/Loader';

import style from './FormPasswordUI.css';


type Props = {
  checkPassword: (password: string) => void,
  error: string | null,
  isLoading: boolean,
}

function FormPasswordUI({
  checkPassword,
  isLoading = false,
  error = '',
}: Props) {
  const [passwordInput, setPasswordInput] = useState<string>('');

  const handleClickSubmit = e => {
    e.preventDefault();
    checkPassword(passwordInput);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={style.UIWrapper}>
      <div className={style.textContainer}>
        Please enter a password to proceed.
      </div>
      <div className={style.passwordFieldContainer}>
        <label htmlFor='form-password'>Password</label>
        <input
          id='form-password'
          type='password'
          className={style.formInput}
          value={passwordInput}
          onChange={e => setPasswordInput(e.target.value)}
        />
      </div>
      <div className={style.buttonContainer}>
        <Button
          onClick={handleClickSubmit}
          label='Submit'
          primary={false}
          primaryBlue
        />
      </div>
      {error && <div className={style.errorContainer}>{error}</div>}
    </div>
  );
}

export default FormPasswordUI;
