import {Observable} from 'rxjs/Observable';
import {ARCHIVE_SUBMISSION_API, NEW_TABLE_SEARCH_API} from 'constants/types/submissionsTableActionTypes';
import {clearSelectedStage, getStagesOfForm} from 'actions/stages';
import {success} from './utils/filters';


import {
  QUICK_FILTER_INITIAL_SUBMISSION_LOADS,
} from 'constants/quickFilters';


export default [
  function clearSelectedStageEpic(action$) {
    return action$
      .ofType(NEW_TABLE_SEARCH_API, ...QUICK_FILTER_INITIAL_SUBMISSION_LOADS)
      .filter(success)
      .mergeMap(() => Observable.of(clearSelectedStage()));
  },
  function refreshStagesEpic(
    action$, store
  ) {
    return action$
      .ofType(ARCHIVE_SUBMISSION_API)
      .filter(success)
      .map(action => getStagesOfForm(action.payload.formId, store.getState().submissionTable.isArchiveView));
  },
];
