import React, { lazy, Suspense } from 'react';
import { GOVOS_PAY_TRANSACTION_URL, GOVOS_PAY_APPLICATION_NAME } from 'constants/govOsPay';
import OverlayModalContainer from '../OverlayModalContainer';
import { convertFloatToInteger } from 'utils/govOSPay/govOsPay';
import { FormDataGovOSPaymentConfig, PaymentAmounts } from 'types/govOsPay/govOsPaymentSettings';
import { GovOSPaymentData } from 'types/liveView';

const CardPayment = lazy(() => import('@kofile/platform-react-payrix').then(module => ({ default: module.CardPayment })));


export type CardPaymentModalProps = {
  open: boolean;
  closeModal: () => void;
  amounts: PaymentAmounts;
  paymentCorrelationId: string;
  paymentConfig: FormDataGovOSPaymentConfig | null | undefined;
  onSuccess: (transactionData: GovOSPaymentData) => void;
}

const CardPaymentModal = (props: CardPaymentModalProps) => {
  const { open, closeModal, amounts, paymentCorrelationId, paymentConfig, onSuccess } = props;
  const {providerAccount} = paymentConfig || {};
  const { totalAmount, baseAmount, feeTotal } = amounts;

  const onSuccessHandler = () => {
    onSuccess({
      correlationId: paymentCorrelationId,
      paymentsConfigId: paymentConfig?.paymentsConfigId || '',
      amount: totalAmount,
      baseAmount,
      feeTotal,
      feeAmount: paymentConfig?.ccFeeStructure?.fees[0].amount || 0,
      feePercentage: paymentConfig?.ccFeeStructure?.fees[0].percentage || 0,
      paymentType: 'credit_card',
    });
  };

  if (!open) return null;

  const paymentApplicationConfig = providerAccount;
  const amountAsInteger = convertFloatToInteger(totalAmount);

  return (
    <OverlayModalContainer open={open} closeModal={closeModal}>
      <div>Card Payment</div>
      <Suspense fallback={<div>{''}</div>}>
        <CardPayment
          lineItems={[]}
          providerConfig={paymentApplicationConfig}
          applicationName={GOVOS_PAY_APPLICATION_NAME}
          transactionUrl={GOVOS_PAY_TRANSACTION_URL}
          correlationId={paymentCorrelationId}
          amount={amountAsInteger}
          onSuccess={onSuccessHandler}
        />
      </Suspense>
    </OverlayModalContainer>
  );
};

export default CardPaymentModal;
