import React from 'react';
import FlashOn from '@material-ui/icons/FlashOn';
import AutomatedProcessBuilder, { AutomatedProcessBuilderEmbed } from 'containers/AutomatedProcessBuilder';
import Sidebar from 'components/Sidebar';
import ConfirmationModal from 'components/ConfirmationModal';
import * as colors from 'utils/colors';
import styles from './AutomatedProcessBuilder.css';

interface Props {
  isEmbed: boolean,
  isTablet: boolean,
  isVisible: boolean,
  onSidebarToggle: () => any,
  open: boolean,
  usableWindowWidth: number,
}

interface State {
  haveRulesChanged: boolean,
  isConfirmationOpen: boolean,
}

const MAX_SIZE_AT_WHICH_50_PERCENT_LOOKS_GOOD = 1440;
export default class ApbSidebar extends React.PureComponent<Props, State> {
  state = {
    haveRulesChanged: false,
    isConfirmationOpen: false,
  };

  getSidebarWidthPercentage = () => {
    const { isTablet, usableWindowWidth } = this.props;
    if (isTablet) return 100;
    if (usableWindowWidth < MAX_SIZE_AT_WHICH_50_PERCENT_LOOKS_GOOD) return 50;
    return 40;
  };

  renderHeaderContent = () => <div className={styles.headerText}>Triggers</div>;

  setConfirmationState = state => {
    this.setState({ isConfirmationOpen: state });
  };

  setHaveRulesChanged = haveChanged => {
    this.setState({ haveRulesChanged: haveChanged });
  };

  continueWithoutSaving = () => {
    this.setHaveRulesChanged(false);
    this.setConfirmationState(false);
    this.props.onSidebarToggle();
  };

  onSidebarToggle = () => {
    if (this.state.haveRulesChanged === false) {
      this.props.onSidebarToggle();
    } else {
      this.setConfirmationState(true);
    }
  };

  render() {
    const { isEmbed, isVisible, open, usableWindowWidth } = this.props;
    const sidebarWidthPercentage = this.getSidebarWidthPercentage();
    const headerIcon = <FlashOn style={{ color: colors.grey400 }} />;
    return (
      <>
        <Sidebar
          dockerPoint={usableWindowWidth}
          headerIcon={headerIcon}
          onSidebarToggle={this.onSidebarToggle}
          open={open}
          renderHeaderContent={this.renderHeaderContent}
          shouldRenderClose
          shouldRenderHeader
          showBackdrop
          trapFocus
          visible={isVisible}
          widthPercentage={sidebarWidthPercentage}>
          {isEmbed ? (
            <AutomatedProcessBuilderEmbed
              setHaveRulesChanged={this.setHaveRulesChanged}
              haveUnsavedRules={this.state.haveRulesChanged}
              showConfirmation={this.setConfirmationState}
            />
          ) : (
            <AutomatedProcessBuilder
              setHaveRulesChanged={this.setHaveRulesChanged}
              haveUnsavedRules={this.state.haveRulesChanged}
              showConfirmation={this.setConfirmationState}
            />
          )}
        </Sidebar>
        {this.state.isConfirmationOpen && <ConfirmationModal
          title='Cancel Changes'
          description='You have unsaved changes. Do you want to proceed?'
          disagreeText='No'
          agreeText='Yes'
          onCancel={() => this.setConfirmationState(false)}
          onApprove={this.continueWithoutSaving} />}
      </>
    );
  }
}
