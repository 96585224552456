import React from 'react';

import Icon from '@material-ui/core/Icon';
import * as Colors from 'utils/colors';
import styles from './SidebarDetails.css';

const inlineStyles = {
  materialIcons: {
    fontSize: 20,
    color: Colors.textSecondary,
  },
};


const ActivityRow = ({ icon, activityType, name, date }) => (
  <div className={styles.activityRow}>
    <span className={styles.activityIcon}>
      <Icon style={inlineStyles.materialIcons}>{icon}</Icon>
    </span>
    <span className={styles.activityData}>
      <span className={styles.activity}>
        {activityType}
        <span className={styles.activityName}> {name}</span>
      </span>
      <span className={styles.activityDate}>
        {date}
      </span>
    </span>
  </div>
);

export default ActivityRow;
