import React, { useMemo, useCallback } from 'react';
import AddIcon from '@material-ui/icons/AddRounded';
import AddSelectionButton from 'components/MultiSelector/shared/AddSelectionButton';
import MultiSelectorBase from 'components/MultiSelector';
import Item from '../shared/Item';
import ShowOverflowButtonBase from '../shared/ShowOverflowButton';
import TypeaheadInputBase from './TypeaheadInput';
import withKeyboardFunctionality from 'components/MultiSelector/withKeyboardFunctionality';
import { immutableRemoveElementInArray } from 'utils/reduxHelper';
import { validateEmailAddress, containsInvalidEmail } from 'utils/email';
import styles from '../../RecipientsSelector.css';

interface MultiSelectorProps {
  values: any[],
  removeCaption: string,
  onChange: Function,
  addButtonCaption?: string,
  items?: any[]
}

const MultiSelector = ({ values, removeCaption, onChange }: MultiSelectorProps) => {
  const handleRemoveSelection = useCallback(item => {
    const index = values.findIndex(current => current === item.id);
    onChange(immutableRemoveElementInArray(values, index));
  }, [onChange, values]);

  const handleAddEmail = useCallback(addedEmail => (
    onChange([...values, addedEmail])
  ), [onChange, values]);

  const selections = useMemo(() => (
    values.map(value => ({ id: value, label: value }))
  ), [values]);

  const renderSelection = props => {
    const ItemWithKeyboard = withKeyboardFunctionality(Item);
    return (
      <ItemWithKeyboard
        {...props}
        removeCaption={removeCaption}
        invalidLabel='Invalid email'
        invalid={!validateEmailAddress(props.selectionData.label)} />
    );
  };

  const ShowTypeaheadButton = () => (
    <span className={styles.addSelectionsButton}>
      {!selections.length && <span className={styles.triggerLabel}>ADD</span>}
      <AddSelectionButton Icon={AddIcon} caption='Add email' />
    </span>
  );

  const ShowOverflowButton = props => {
    const invalid = containsInvalidEmail(values.slice(-props.overflowCount));
    return <ShowOverflowButtonBase {...props} invalid={invalid} />;
  };

  const TypeaheadInput = props => (
    <TypeaheadInputBase {...props} onEnter={handleAddEmail} />
  );

  return (
    <div className={styles.multiSelectorContainer}>
      <MultiSelectorBase
        hidePeak
        onRemoveSelection={handleRemoveSelection}
        selections={selections}
        renderSelection={renderSelection}
        ShowTypeaheadButton={ShowTypeaheadButton}
        ShowOverflowButton={ShowOverflowButton}
        resultsListClassName={styles.resultListContainer}
        maxElements={2}
        onAddSelections={() => { }}
        TypeaheadInput={TypeaheadInput}
        typeaheadResults={[]}
        popoverOrigin={{ vertical: 'top', horizontal: 'right' }}
        overflowButtonCountsAsElement />
    </div>
  );
};

export default MultiSelector;
