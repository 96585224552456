import React from 'react';
import Tooltip from 'components/Tooltip';
import styles from './AddSelectionButton.css';


export default ({ caption, Icon }) => (
  <Tooltip title={caption}>
    <span className={styles.bubble} aria-label={caption}>
      <Icon className={styles.icon} />
    </span>
  </Tooltip>
);

