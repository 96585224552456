export const HIDE_SIDEBAR = 'SM_HIDE_SIDEBAR';
export const DOWNLOAD_VISIBILITY = 'SM_DOWNLOAD_VISIBILITY';
export const SEARCHBAR_INPUT_CHANGE = 'SM_SEARCHBAR_INPUT_CHANGE';
export const REFUND_VISIBILITY = 'SM_REFUND_VISIBILITY';
export const LOADER_VISIBILITY = 'SM_LOADER_VISIBILITY';
export const EDIT_SIGNER_VISIBILITY = 'SM_EDIT_SIGNER_VISIBILITY';
export const SIGNER_DETAILS_VISIBILITY = 'SM_SIGNER_DETAILS_VISIBILITY';
export const DECRYPT_MODAL_VISIBILITY = 'SM_DECRYPT_MODAL_VISIBILITY';
export const SHARE_VISIBILITY = 'SHARE_VISIBILITY';
export const SHARE_SUBMISSIONS = 'SHARE_SUBMISSIONS';

/* submission constants */
export const SELECT_ROW = 'SM_SELECT_ROW';
export const SCROLL_TO_ROW = 'SM_SCROLL_TO_ROW';
export const RESET_SCROLL_TO_ROW = 'SM_RESET_SCROLL_TO_ROW';

export const TRACK_DOWNLOAD_FILE = 'SM_TRACK_DOWNLOAD_FILE';
export const TOOLBAR_MOBILE_MENU_VISIBILITY = 'SM_TOOLBAR_MOBILE_MENU_VISIBILITY';

export const FETCH_ASSET = 'FETCH_ASSET';
export const REQUEST_SIGN_URL = 'REQUEST_SIGN_URL';
