import React, { memo } from 'react';
import styles from '../IdVerification.css';
import WithFocusTrap from '../WithFocustrap';

const Loader = ({ open }: { open: boolean }) => (
  <WithFocusTrap open={open} isContentLoaded>
    <div className={styles.loaderContainer}>
      <div className={styles.loader} />
      <span tabIndex={0} className={styles.loaderText}>Loading...</span>
    </div>
  </WithFocusTrap>

);

export default memo(Loader);
