import { authTypes } from 'constants/api';
import {postFetch} from './postFetch';
import {isLoggedIn} from 'utils/auth';

export async function getSubmissionDetails(formId: string, submissionId: string, accessCode: string, maid: string) {
  const endpoint = 'submissionDetail.json';
  const response = await postFetch({
    auth: accessCode ? authTypes.NoAuth : authTypes.WithAuth,
    endpoint,
    data: {formId, submissionId, accessCode, maid}}
  );
  return response.json();
}
export async function getSubmissionUrl(submissionId: string, accessCode: string, maid: string) {
  const endpoint = 'signedS3url/submissionPDF.json';
  const response = await postFetch({
    auth: accessCode ? authTypes.NoAuth : authTypes.WithAuth,
    endpoint,
    data: {submissionId, accessCode, maid}}
  );
  return response.json();
}

export async function logSession(submissionId: string) {
  const endpoint = 'submissions/viewer/logSession.json';
  const response = await postFetch({
    auth: isLoggedIn() ? authTypes.WithAuth : authTypes.NoAuth,
    endpoint,
    data: {submissionId}}
  );
  return response.json();
}
