import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './BulkActionWrapper.css';
import StagesSelect from '../Components/StagesSelect';

const MoveToStage = props => {
  const { options } = props;
  const [selectedStage, setSelectedStage] = useState('');

  const handleChange = event => {
    const {target: { value }} = event;
    setSelectedStage(value);
  };

  const handleMoveToStage = () => {
    const {
      dispatch,
      submissionIds,
      moveToStage,
      errorModalToggle,
      includeAllSubmissions,
      formId,
      excludedSubmissionIds,
    } = props;
    moveToStage({
      submissionIds,
      selectedStageName: selectedStage,
      includeAllSubmissions,
      excludedSubmissionIds,
      formId,
      dispatch,
      onError: () => errorModalToggle(true),
    });
  };

  const onMoveToStageButtonClick = () => {
    const { includeAllSubmissions, loaderModalToggle, submissionIds } = props;
    const submissionsNumber = includeAllSubmissions ? 'all the' : `the ${submissionIds.length} selected`;
    loaderModalToggle(
      true,
      'moveToStage',
      'Move to Stage',
      `Are you sure you want to move ${submissionsNumber} submission(s)?`,
      handleMoveToStage,
    );
  };

  return (
    <div className={styles.addToContainer}>
      <label className={styles.label} htmlFor='move'>Move selected submissions to</label>
      <div className={styles.controlsContainer}>
        <StagesSelect selectedStage={selectedStage} handleChange={handleChange} options={options}/>
        <button type='button' className={styles.button} onClick={onMoveToStageButtonClick} disabled={!selectedStage}>Move</button>
      </div>
    </div>);
};


export default connect(null, dispatch => ({dispatch}))(MoveToStage);
