import { cloneDeep, isEqual } from 'lodash';
import { ContentState, EditorState } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';

import validate from 'internal-tools/utils/validator';
import { convertRuleForServerV2 } from 'utils/conditionals';
import { convertToEditorState } from 'utils/richTextEditor/api_to_draft';
import { convertToStructure } from 'utils/richTextEditor/draft_to_api';
import { emailTypeEnum, KNOWN_LIST_USER_LIMIT, participantTypeEnum, SIGNER_KEY_RESPONSE_SEPARATOR, SUBMITTER_PARTICIPATION_ORDER } from 'constants/participantsSidebar';

import { ConditionalRule } from 'types/conditionals';
import { FormField, FormFields } from 'types/formFields';
import {
  CurrentParticipant,
  EmailSetting,
  EmailSettings,
  EmailType,
  KnownList,
  KnownParticipant,
  Participant,
  Submitter,
  UnknownParticipant,
  ParticipantFromServer,
  EmailSettingForServer,
  ParticipantForServer,
  UserRecord,
  EmailConfigurations,
  Identifier,
  ServerParticipantErrors,
  ApiConfig,
  ApiConfigErrors,
  ParallelGroup,
  ParallelGroupForServer,
} from 'types/participants';

export const getRandomHexColor = () => {
  let hex = '';
  do {
    const randomNumber = Math.floor(Math.random() * 16777215);
    hex = randomNumber.toString(16).toUpperCase();
  } while (hex.length < 6);
  return `#${hex}`;
};

export const getParticipantFromCurrentParticipant = (currentParticipant: CurrentParticipant): Participant => {
  switch (currentParticipant.participantType) {
    case participantTypeEnum.unknownParticipant:
      const unknownParticipant: UnknownParticipant = {
        id: currentParticipant.id,
        frontendId: currentParticipant.frontendId,
        participantType: participantTypeEnum.unknownParticipant,
        label: currentParticipant.label,
        identifier: currentParticipant.identifier,
        apiConfig: currentParticipant.apiConfig,
        submissionAssociation: currentParticipant.submissionAssociation,
        signerKey: currentParticipant.signerKey,
        signerColor: currentParticipant.signerColor,
        dontDisplaySignature: currentParticipant.dontDisplaySignature,
        sameScreenSigning: currentParticipant.sameScreenSigning,
        emailSettings: cloneDeep(currentParticipant.emailSettings),
        participationOrder: currentParticipant.participationOrder,
      };
      return unknownParticipant;
    case participantTypeEnum.knownParticipant:
      const knownParticipant: KnownParticipant = {
        id: currentParticipant.id,
        frontendId: currentParticipant.frontendId,
        participantType: participantTypeEnum.knownParticipant,
        label: currentParticipant.label,
        email: currentParticipant.email,
        userId: currentParticipant.userId,
        userRecordId: currentParticipant.userRecordId,
        submissionAssociation: currentParticipant.submissionAssociation,
        signerKey: currentParticipant.signerKey,
        signerColor: currentParticipant.signerColor,
        dontDisplaySignature: currentParticipant.dontDisplaySignature,
        requireLogin: currentParticipant.requireLogin,
        canDelegate: currentParticipant.canDelegate,
        emailSettings: cloneDeep(currentParticipant.emailSettings),
        sameScreenSigning: currentParticipant.sameScreenSigning,
        participationOrder: currentParticipant.participationOrder,
      };
      return knownParticipant;
    case participantTypeEnum.knownList:
      const knownList: KnownList = {
        id: currentParticipant.id,
        frontendId: currentParticipant.frontendId,
        participantType: participantTypeEnum.knownList,
        label: currentParticipant.label,
        identifier: currentParticipant.identifier,
        apiConfig: currentParticipant.apiConfig,
        users: currentParticipant.users,
        submissionAssociation: currentParticipant.submissionAssociation,
        signerKey: currentParticipant.signerKey,
        signerColor: currentParticipant.signerColor,
        dontDisplaySignature: currentParticipant.dontDisplaySignature,
        canDelegate: currentParticipant.canDelegate,
        emailSettings: cloneDeep(currentParticipant.emailSettings),
        sameScreenSigning: currentParticipant.sameScreenSigning,
        participationOrder: currentParticipant.participationOrder,
      };
      return knownList;
    default:
      throw new Error('Unknown participant type');
  }
};

export const getPossibleIdentifiers = (
  submitter: Submitter | Participant,
  existingParticipants: Participant[],
  participationOrderOfIdentified: number,
): (Participant | Submitter)[] =>
  [submitter, ...existingParticipants].filter(identifier =>
    // Only participants that come earlier in the workflow can identify later participants
    identifier.participationOrder < participationOrderOfIdentified
  );

export const isParticipantInGroup = (
  participant: Participant | CurrentParticipant,
  groups: ParallelGroup[],
): boolean => groups.some(g => g.participationOrder === participant.participationOrder);

export const getLabelErrors = (label: string): string | null => {
  if (!label) {
    return 'Label is required';
  }
  if (label.trim().length === 0) {
    return 'Label cannot be empty';
  }
  if (label.length > 100) {
    return 'Label must be 100 characters or less';
  }
  return null;
};

const getSameScreenSigningErrors = (
  participant: CurrentParticipant | Participant,
  groups: ParallelGroup[],
): string | null =>
  participant.sameScreenSigning && isParticipantInGroup(participant, groups)
    ? 'Same screen signing is not allowed in a parallel group'
    : null;

export const getApiConfErrors = (apiConfig: ApiConfig): ApiConfigErrors => ({
  endpointError: apiConfig.isActive && !apiConfig.endpoint ? 'Endpoint is required' : null,
  apiKeyError: apiConfig.isActive && !apiConfig.apiKey ? 'Api key is required' : null,
  apiSecretError: apiConfig.isActive && !apiConfig.apiSecret ? 'Api secret is required' : null,
});

export const getIdentifierErrors = (
  identifier: Identifier | null,
  participationOrder: number,
  submitter: Submitter | Participant,
  participants: Participant[],
): string | null => {
  // Submitter does not need to have an identifier
  if (participationOrder === SUBMITTER_PARTICIPATION_ORDER) {
    return null;
  }
  if (identifier === null) {
    return 'Identifier is required';
  }
  const possibleIdentifiers = getPossibleIdentifiers(submitter, participants, participationOrder);
  if (!possibleIdentifiers.some(possIdentifier => possIdentifier.frontendId === identifier.frontendId)) {
    return 'Identifier is invalid';
  }
  return null;
};

export const getEmailErrors = (email: string): string | null => {
  if (!email) {
    return 'Email is required';
  }
  if (!validate('email', email)) {
    return 'Email is invalid';
  }
  return null;
};

export const getUserIdErrors = (
  userId: string,
  userMap: { [key: string]: any },
): string | null => {
  if (!userId) {
    return 'User is required';
  }
  if (!userMap[userId]) {
    return 'User is invalid';
  }
  if (!userMap[userId].isActive) {
    return 'User is disabled';
  }
  return null;
};

export const getUsersErrors = (
  users: UserRecord[],
): string | null => {
  if (!users || users.length === 0) {
    return 'Emails are required';
  }
  if (users.length > KNOWN_LIST_USER_LIMIT) {
    return `Max number of users is ${KNOWN_LIST_USER_LIMIT}`;
  }

  if (users.some(user => !user.emailAddress || !validate('email', user.emailAddress))) {
    return 'Email is invalid';
  }
  return null;
};

export function getParticipantWithErrors(
  initialParticipant: Participant,
  submitter: Submitter | Participant,
  participants: Participant[],
  groups: ParallelGroup[],
  userMap: { [key: string]: any },
): Participant;
export function getParticipantWithErrors(
  initialParticipant: CurrentParticipant,
  submitter: Submitter | Participant,
  participants: Participant[],
  groups: ParallelGroup[],
  userMap: { [key: string]: any },
): CurrentParticipant
export function getParticipantWithErrors(
  initialParticipant: Participant | CurrentParticipant,
  submitter: Submitter | Participant,
  participants: Participant[],
  groups: ParallelGroup[],
  userMap: { [key: string]: any },
) {
  // First removes any existing errors
  const { errors: initialErrors, ...participant } = initialParticipant;

  const errors: any = {};

  // Labels are required for all participant types
  const labelError = getLabelErrors(participant.label);
  labelError && (errors.label = labelError);

  // Same screen signing is not allowed for participants in a parallel group
  const sameScreenSigningError = getSameScreenSigningErrors(participant, groups);
  sameScreenSigningError && (errors.sameScreenSigning = sameScreenSigningError);

  switch (participant.participantType) {
    case participantTypeEnum.unknownParticipant:
      const identifierError = getIdentifierErrors(
        participant.identifier,
        participant.participationOrder,
        submitter,
        participants,
      );
      identifierError && (errors.identifier = identifierError);

      if (participant.apiConfig) {
        const apiConfErrors = getApiConfErrors(participant.apiConfig);
        apiConfErrors.endpointError && (errors.endpoint = apiConfErrors.endpointError);
        apiConfErrors.apiKeyError && (errors.apiKey = apiConfErrors.apiKeyError);
        apiConfErrors.apiSecretError && (errors.apiSecret = apiConfErrors.apiSecretError);
      }
      break;

    case participantTypeEnum.knownParticipant:
      // If required login is enabled, userId must be set
      if (participant.requireLogin) {
        // @ts-ignore
        const userIdError = getUserIdErrors(participant.userId, userMap);
        userIdError && (errors.userId = userIdError);
      } else {
        // @ts-ignore
        const emailError = getEmailErrors(participant.email);
        emailError && (errors.email = emailError);
      }
      break;

    case participantTypeEnum.knownList:
      const identifierErrorKL = getIdentifierErrors(
        participant.identifier,
        participant.participationOrder,
        submitter,
        participants,
      );
      identifierErrorKL && (errors.identifier = identifierErrorKL);
      const usersError = getUsersErrors(participant.users);
      usersError && (errors.users = usersError);

      if (participant.apiConfig) {
        const apiConfErrors = getApiConfErrors(participant.apiConfig);
        apiConfErrors.endpointError && (errors.endpoint = apiConfErrors.endpointError);
        apiConfErrors.apiKeyError && (errors.apiKey = apiConfErrors.apiKeyError);
        apiConfErrors.apiSecretError && (errors.apiSecret = apiConfErrors.apiSecretError);
      }
      break;

    default:
      break;
  }
  return Object.keys(errors).length > 0
    ? { ...participant, errors }
    : { ...participant };
}

export const getExistingParticipantsWithErrors = (
  existingParticipants: Participant[],
  submitter: Submitter | Participant,
  userMap: { [key: string]: any },
  groups: ParallelGroup[],
): Participant[] =>
  existingParticipants.map(participant =>
    getParticipantWithErrors(participant, submitter, existingParticipants, groups, userMap));

export const getSubmitterWithErrors = (
  submitter: Submitter | Participant,
  userMap: { [key: string]: any },
  groups: ParallelGroup[],
): Submitter | Participant =>
  // If the submitter is not a signer, then there is no need to validate it
  // Also, there is no need for the other participants when validating the submitter
  (submitter.participantType !== participantTypeEnum.submitter)
    ? getParticipantWithErrors(submitter, submitter, [], groups, userMap)
    : submitter;

export const getRequiredParticipantErrors = (
  group: ParallelGroup,
  participants: Participant[],
): string | null => {
  const usersInGroup = participants.filter(p => p.participationOrder === group.participationOrder);
  if (usersInGroup.length < 2) {
    return 'At least two participants are required';
  }
  return null;
};

export const getGroupWithErrors = (
  initialGroup: ParallelGroup,
  participants: Participant[],
): ParallelGroup => {
  // First removes any existing errors
  const { errors: initialErrors, ...group } = initialGroup;

  const errors: any = {};

  // Labels are required for groups
  const labelError = getLabelErrors(initialGroup.label);
  labelError && (errors.label = labelError);

  const requiredParticipantError = getRequiredParticipantErrors(group, participants);
  requiredParticipantError && (errors.participants = requiredParticipantError);

  return Object.keys(errors).length > 0
    ? { ...group, errors }
    : { ...group };
};

export const getGroupsWithErrors = (
  initialGroups: ParallelGroup[],
  participants: Participant[],
): ParallelGroup[] =>
  initialGroups.map(group => getGroupWithErrors(group, participants));

export const isGroupValid = (group: ParallelGroup): boolean =>
  !group.errors && !group.serverErrors;

export const getEmailTypePropName = (emailType: EmailType): keyof EmailSettings => {
  switch (emailType) {
    case emailTypeEnum.request:
      return 'requestEmail';
    case emailTypeEnum.completion:
      return 'completionEmail';
    case emailTypeEnum.signatureConfirmation:
      return 'signatureConfirmationEmail';
    default:
      throw new Error('Unknown email type');
  }
};

export const checkIfEmailSettingHasChanged = (setting1: EmailSetting, setting2: EmailSetting): boolean =>
  setting1.isEnabled !== setting2.isEnabled
  || setting1.subject !== setting2.subject
  || setting1.includeLinkToSubmissionViewer !== setting2.includeLinkToSubmissionViewer
  || setting1.includePdfAsAttachment !== setting2.includePdfAsAttachment
  || !isEqual(setting1.fieldIds, setting2.fieldIds)
  || setting1.body.getCurrentContent().getPlainText('\u0001')
    !==
    setting2.body.getCurrentContent().getPlainText('\u0001');

const convertEmailSubjectForServer = (subject: string, formFields: FormFields): string => {
  if (!subject) {
    return '';
  }
  // checking if there is valid field mapping
  const userReadableFieldsArray = subject.match(/(\{(?:\{??[^\{]*?\}))/g);
  if (!userReadableFieldsArray || !userReadableFieldsArray.length) {
    return subject;
  }

  let result: string = subject;

  // getting labels without curly brackets
  const fieldLabels = userReadableFieldsArray.map(field => field.replace(/\{|\}/g, ''))
    .filter(field => !!field);

  // replace labels with ids
  fieldLabels.forEach(label => {
    const id = formFields.find(field => field.label === label)?.id;
    if (id) {
      result = result.replace(label, id);
    }
  });

  return result;
};

export const convertEmailSubjectForClient = (subject: string, formFields: FormFields): string => {
  if (!subject) {
    return '';
  }
  // checking if there is valid field mapping
  const userReadableFieldsArray = subject.match(/(\{(?:\{??[^\{]*?\}))/g);
  if (!userReadableFieldsArray || !userReadableFieldsArray.length) {
    return subject;
  }

  let result: string = subject;

  // getting ids without curly brackets
  const fieldIds = userReadableFieldsArray.map(field => field.replace(/\{|\}/g, ''))
    .filter(field => !!field);

  // replace ids with labels
  fieldIds.forEach(id => {
    const label = formFields.find(field => field.id === id)?.label;
    if (label) {
      result = result.replace(id, label);
    }
  });

  return result;
};

const emailSettingForServerToEmailSetting = (
  emailSettingFromServer: EmailSettingForServer | null,
): EmailSetting => {
  if (!emailSettingFromServer) {
    return {
      isEnabled: false,
      // @ts-ignore
      subject: null,
      body: EditorState.createWithContent(ContentState.createFromText('')),
      fieldIds: [],
      includeLinkToSubmissionViewer: false,
      includePdfAsAttachment: false,
    };
  }
  const emailSetting: EmailSetting = {
    isEnabled: true,
    // @ts-ignore
    subject: emailSettingFromServer.subject,
    fieldIds: emailSettingFromServer.fieldIds,
    includeLinkToSubmissionViewer: emailSettingFromServer.linkToSubmissionManager,
    includePdfAsAttachment: emailSettingFromServer.attachPdf,
    body: emailSettingFromServer.body
      ? convertToEditorState(emailSettingFromServer.body) as EditorState
      : EditorState.createWithContent(ContentState.createFromText('')),
  };
  return emailSetting;
};

const emailSettingToEmailSettingForServer = (
  emailSetting: EmailSetting,
  formFields: FormFields,
): EmailSettingForServer | null => {
  if (!emailSetting.isEnabled) {
    return null;
  }

  return {
    subject: convertEmailSubjectForServer(emailSetting.subject, formFields) || null,
    body: emailSetting.body ? convertToStructure(emailSetting.body) : null,
    fieldIds: emailSetting.fieldIds,
    linkToSubmissionManager: emailSetting.includeLinkToSubmissionViewer,
    attachPdf: emailSetting.includePdfAsAttachment,
  };
};

export const convertParticipantsForClient = (
  participantsFromServer: ParticipantFromServer[],
): (Participant | Submitter)[] => {
  const sortedParticipantsFromServer =
    participantsFromServer.sort((a, b) => a.participationOrder - b.participationOrder);
  // @ts-ignore
  return sortedParticipantsFromServer
    .map(participantFromServer => {
      const {
        participantId: id,
        label,
        identifiedBy,
        apiConfig,
        requireLogin,
        subAssociation: submissionAssociation,
        signer,
        users,
        emailConfig,
        participationOrder,
      } = participantFromServer;

      const frontendId = uuidv4();
      const identifier = identifiedBy
        ? { id: identifiedBy, frontendId: null }
        : null;
      const emailSettings: EmailSettings = {
        // @ts-ignore
        requestEmail: emailSettingForServerToEmailSetting(emailConfig?.signatureRequest),
        // @ts-ignore
        completionEmail: emailSettingForServerToEmailSetting(emailConfig?.documentCompletion),
        // @ts-ignore
        signatureConfirmationEmail: emailSettingForServerToEmailSetting(emailConfig?.signatureConfirmation),
      };
      const participantType = (signer === null)
        ? participantTypeEnum.submitter
        : signer.signerType;
      switch (participantType) {
        case participantTypeEnum.unknownParticipant:
          return {
            id,
            frontendId,
            participantType,
            label,
            identifier,
            apiConfig,
            requireLogin,
            submissionAssociation,
            signerKey: signer?.signerKey,
            signerColor: signer?.color,
            dontDisplaySignature: signer?.hideSignature,
            sameScreenSigning: signer?.allowHotseat,
            emailSettings,
            participationOrder,
          };
        case participantTypeEnum.knownParticipant:
          return {
            id,
            frontendId,
            participantType,
            label,
            email: users[0]?.emailAddress,
            userId: users[0]?.userId,
            userRecordId: users[0]?.id,
            requireLogin,
            submissionAssociation,
            signerKey: signer?.signerKey,
            signerColor: signer?.color,
            dontDisplaySignature: signer?.hideSignature,
            sameScreenSigning: signer?.allowHotseat,
            canDelegate: signer?.canDelegate,
            emailSettings,
            participationOrder,
          };
        case participantTypeEnum.knownList:
          return {
            id,
            frontendId,
            participantType,
            label,
            identifier,
            apiConfig,
            users,
            requireLogin,
            submissionAssociation,
            signerKey: signer?.signerKey,
            signerColor: signer?.color,
            dontDisplaySignature: signer?.hideSignature,
            sameScreenSigning: signer?.allowHotseat,
            canDelegate: signer?.canDelegate,
            emailSettings,
            participationOrder,
          };
        case participantTypeEnum.submitter:
          return {
            id,
            frontendId,
            participantType,
            participationOrder: 0,
            requireLogin,
            submissionAssociation,
          } as Submitter;
        default:
          throw new Error('Unknown participant type');
      }
    })
    .map((convertedParticipant, _, convertedParticipants) => {
      if (convertedParticipant.participantType === participantTypeEnum.submitter) {
        return convertedParticipant;
      }
      if (!convertedParticipant.identifier) {
        return convertedParticipant;
      }
      // @ts-ignore
      const identifierFrontendId = convertedParticipants
        .find(possibleIdentifier => possibleIdentifier.id === convertedParticipant.identifier?.id).frontendId;
      return {
        ...convertedParticipant,
        identifier: {
          ...convertedParticipant.identifier,
          frontendId: identifierFrontendId,
        },
      };
    });
};

export const convertSubmitterForServer = (
  submitter: Submitter,
  maid: string,
  formId: string,
): ParticipantForServer => ({
  participant: {
    participantId: submitter.id,
    frontendId: submitter.frontendId,
    formId,
    maid,
    identifiedByFrontend: null,
    label: null,
    participationOrder: 0,
    requireLogin: false,
    associateSubmissions: false,
  },
  users: [],
  signer: null,
  emailConfig: null,
});

export const convertGroupForClient = (
  { participationOrder, label, errors }: ParallelGroupForServer,
): ParallelGroup => ({
  participationOrder,
  label,
  frontendId: uuidv4(),
  participantType: participantTypeEnum.parallelGroup,
  ...(errors ? { serverErrors: errors } : null),
});

export const convertGroupsForClient = (
  groupsFromServer: ParallelGroupForServer[],
): ParallelGroup[] => groupsFromServer.map(convertGroupForClient);

export const convertParticipantsForServer = (
  maid: string,
  formId: string,
  submitter: Participant | Submitter,
  participants: Participant[],
  formFields: FormFields,
  conditionals?: ConditionalRule[], // Optional because only used for v2 endpoint
): ParticipantForServer[] =>
  // @ts-ignore
  [submitter, ...participants].map(participant => {
    if (participant.participantType === participantTypeEnum.submitter) {
      return convertSubmitterForServer(participant, maid, formId);
    }
    const {
      id,
      label,
      submissionAssociation,
      signerKey,
      signerColor,
      dontDisplaySignature,
      sameScreenSigning,
      frontendId,
      participationOrder,
    } = participant;
    const sharedParticipantObject = {
      participantId: id,
      label,
      frontendId,
      formId,
      maid,
      participationOrder,
      associateSubmissions: submissionAssociation,
    };
    const sharedSignerObject = {
      signerKey,
      signerType: participant.participantType,
      allowHotseat: sameScreenSigning,
      hideSignature: dontDisplaySignature,
      color: signerColor,
    };
    const emailConfig: EmailConfigurations = {
      signatureRequest: emailSettingToEmailSettingForServer(participant.emailSettings.requestEmail, formFields),
      documentCompletion: emailSettingToEmailSettingForServer(participant.emailSettings.completionEmail, formFields),
      signatureConfirmation:
        emailSettingToEmailSettingForServer(participant.emailSettings.signatureConfirmationEmail, formFields),
    };

    // only used for the v2 endpoint
    const conditionalRule = conditionals?.find(rule => rule.frontendId === frontendId);
    const conditionalRuleForServer = conditionalRule ? convertRuleForServerV2(conditionalRule) : null;

    switch (participant.participantType) {
      case participantTypeEnum.unknownParticipant:
        return {
          participant: {
            ...sharedParticipantObject,
            identifiedByFrontend: participant.identifier?.frontendId,
            requireLogin: false,
          },
          apiConfig: participant.apiConfig,
          users: [],
          signer: {
            ...sharedSignerObject,
            canDelegate: false,
          },
          emailConfig,
          ...(conditionals ? { rule: conditionalRuleForServer } : null),
        };
      case participantTypeEnum.knownParticipant:
        return {
          participant: {
            ...sharedParticipantObject,
            identifiedByFrontend: null,
            requireLogin: participant.requireLogin,
          },
          users: [{
            id: participant.userRecordId,
            emailAddress: participant.email,
            label: null,
            userId: participant.userId,
          }],
          signer: {
            ...sharedSignerObject,
            canDelegate: participant.canDelegate,
          },
          emailConfig,
          ...(conditionals ? { rule: conditionalRuleForServer } : null),
        };
      case participantTypeEnum.knownList:
        return {
          participant: {
            ...sharedParticipantObject,
            identifiedByFrontend: participant.identifier?.frontendId,
            requireLogin: false,
          },
          apiConfig: participant.apiConfig,
          users: participant.users,
          signer: {
            ...sharedSignerObject,
            canDelegate: participant.canDelegate,
          },
          emailConfig,
          ...(conditionals ? { rule: conditionalRuleForServer } : null),
        };
      default:
        throw new Error('Unknown participant type');
    }
  });

const convertGroupForServer = (
  { participationOrder, label }: ParallelGroup,
  participants: Participant[],
): ParallelGroupForServer => ({
  participationOrder,
  label,
  numberRequired: participants.filter(p => p.participationOrder === participationOrder).length,
});

export const convertGroupsForServer = (
  groups: ParallelGroup[],
  participants: Participant[],
): ParallelGroupForServer[] => groups.map(group => convertGroupForServer(group, participants));

const parseUserFromInput = (input: string): UserRecord | null => {
  const trimmedInput = input.trim();
  // if input contains arrow brackets, it contains a label
  if (trimmedInput.includes('<') && trimmedInput.includes('>')) {
    const label = trimmedInput.split('<')[0]?.trim();
    const email = trimmedInput.split('<')[1]?.split('>')[0]?.trim();
    if (validate('email', email) && label) {
      return {
        id: null,
        emailAddress: email,
        label,
        userId: null,
      };
    }
    // else it is just an email
  } else if (validate('email', trimmedInput)) {
    return {
      id: null,
      emailAddress: trimmedInput,
      label: null,
      userId: null,
    };
  }
  return null;
};

export const parseUsersFromInput = (input: string): [UserRecord[], string[]] => {
  const validUsers: UserRecord[] = [];
  const invalidUsers: string[] = [];
  input.split(',').forEach(user => {
    const trimmedUser = user.trim();
    const parsedUser = parseUserFromInput(trimmedUser);
    if (parsedUser) {
      validUsers.push(parsedUser);
    } else {
      invalidUsers.push(trimmedUser);
    }
  });
  return [validUsers, invalidUsers];
};

export const getGenericGroup = (): ParallelGroup => ({
  // @ts-ignore
  participationOrder: null,
  label: '',
  participantType: participantTypeEnum.parallelGroup,
  frontendId: uuidv4(),
});

export const getGenericCurrentParticipant = (): CurrentParticipant => ({
  id: null,
  frontendId: uuidv4(),
  // @ts-ignore
  participationOrder: null,
  participantType: '',
  email: '',
  userId: null,
  userRecordId: null,
  users: [],
  label: '',
  identifier: null,
  apiConfig: null,
  submissionAssociation: false,
  signerKey: null,
  signerColor: getRandomHexColor(),
  requireLogin: false,
  canDelegate: false,
  dontDisplaySignature: false,
  sameScreenSigning: false,
  emailSettings: {
    requestEmail: {
      isEnabled: true,
      subject: '',
      body: EditorState.createWithContent(ContentState.createFromText('')),
      fieldIds: [],
      includeLinkToSubmissionViewer: false,
      includePdfAsAttachment: false,
    },
    completionEmail: {
      isEnabled: true,
      subject: '',
      body: EditorState.createWithContent(ContentState.createFromText('')),
      fieldIds: [],
      includeLinkToSubmissionViewer: true,
      includePdfAsAttachment: true,
    },
    signatureConfirmationEmail: {
      isEnabled: true,
      subject: '',
      body: EditorState.createWithContent(ContentState.createFromText('')),
      fieldIds: [],
      includeLinkToSubmissionViewer: false,
      includePdfAsAttachment: false,
    },
  },
});


export const getGenericSubmitter = (): Submitter => ({
  id: null,
  frontendId: uuidv4(),
  participantType: participantTypeEnum.submitter,
  participationOrder: 0,
  submissionAssociation: true,
  requireLogin: false,
});

export const duplicateParticipant = (participant: Participant): Participant => ({
  ...cloneDeep(participant),
  frontendId: uuidv4(),
  id: null,
  signerKey: null,
  signerColor: getRandomHexColor(),
  ...(participant.participantType === participantTypeEnum.knownList)
    ? { users: participant.users.map(u => ({ ...u, id: null, userId: null })) }
    : {},
  ...(participant.participantType === participantTypeEnum.knownParticipant)
    ? { userRecordId: null }
    : {},
});

export const getIdentifierDropdownProps = (possibleIdentifiers: (Participant | Submitter)[]) =>
  possibleIdentifiers.map((participant => ({
    identifier: {
      id: participant.id,
      frontendId: participant.frontendId,
    },
    label: participant.participantType === participantTypeEnum.submitter
      ? 'Submitter'
      : participant.label,
  })));

export const getIdentifierLabel = (
  identifier: Identifier | null,
  submitter: Submitter | Participant,
  existingParticipants: Participant[],
): string => {
  if (!identifier) {
    return '';
  }
  const { id, frontendId } = identifier;
  const participants = [submitter, ...existingParticipants];
  let identifierParticipant: Participant | Submitter | undefined;
  if (id) {
    identifierParticipant = participants.find(participant => participant.id === id);
  } else {
    identifierParticipant = participants.find(participant => participant.frontendId === frontendId);
  }
  if (identifierParticipant) {
    return identifierParticipant.participantType === participantTypeEnum.submitter
      ? 'Submitter'
      : identifierParticipant.label;
  }
  return '';
};

const getParticipantWithServerErrors = (
  participant: Submitter | Participant,
  participantValidationErrors: ServerParticipantErrors,
): Submitter | Participant => {
  const participantServerErrors = participantValidationErrors[participant.frontendId] || [];
  const participantErrors = participantServerErrors.map(err => err.error);
  return {
    ...participant,
    ...(participantErrors.length > 0 ? {serverErrors: participantErrors} : {}),
  };
};

export const getParticipantsWithServerErrors = (
  submitter: Submitter | Participant,
  existingParticipants: Participant[],
  participantValidationErrors: ServerParticipantErrors,
): {
  submitterWithServerErrors: Submitter | Participant,
  existingParticipantsWithServerErrors: Participant[],
} => {
  const submitterWithServerErrors = getParticipantWithServerErrors(submitter, participantValidationErrors);
  const existingParticipantsWithServerErrors = existingParticipants
    .map(participant =>
      getParticipantWithServerErrors(participant, participantValidationErrors)) as Participant[];

  return {
    submitterWithServerErrors,
    existingParticipantsWithServerErrors,
  };
};

export const getIsParticipantValid = (participant: Participant | CurrentParticipant): boolean => {
  // Check whether there are validation errors
  const { errors } = participant;
  return errors === undefined;
};

export const getFrontendIdByParticipantId = (participantId: number, participants: Participant[]): string =>
  // @ts-ignore
  participants.find(participant =>
    participant.id === participantId)?.frontendId;

export const assignSignerKeysByFrontendIds =
(submitter: Submitter | Participant, existingParticipants: Participant[], participantsResponse: any) => {
  const newParticipants: Participant[] = existingParticipants.map(participant => ({
    ...participant,
    signerKey: participantsResponse.find(p => p.frontendId === participant.frontendId)?.signer.signerKey,
  }));
  const newSubmitter = submitter.participantType === participantTypeEnum.submitter ? submitter
    : {
      ...submitter,
      signerKey: participantsResponse.find(p => p.frontendId === submitter.frontendId)?.signer.signerKey,
    };
  return [newSubmitter, newParticipants];
};

export const getFieldsAssignedToEarlierParticipants = (
  formFields: FormFields,
  participationOrder: number,
  submitter: Submitter | Participant,
  existingParticipants: Participant[],
): FormFields => {
  // If the participant is the submitter, there are no earlier participants
  if (participationOrder === SUBMITTER_PARTICIPATION_ORDER) return [];

  const allParticipants = [submitter, ...existingParticipants];
  const earlierParticipants = allParticipants.filter(p => p.participationOrder < participationOrder);

  // Get the signer keys for the prior participants
  const priorSignerKeys = earlierParticipants.map(participant => {
    if (participant.participantType === participantTypeEnum.submitter) {
      // if submitter is not a signer and was assigned to the field,
      // allSupported.json returns participantId as signerKey
      return participant.id ? participant.id.toString() : null;
    }
    return participant.signerKey;
  });

  // Filter out fields that are not unassigned (signerKey === null) or assigned to prior signers
  return formFields.filter((field: FormField) => {
    if (field.signerKey === null) {
      return true;
    }
    const signerKeys: string[] = field.signerKey.split(SIGNER_KEY_RESPONSE_SEPARATOR);
    return signerKeys.every((key: string) => priorSignerKeys.includes(key));
  });
};

export const updateParticipationOrder = <T extends { participationOrder: number }>(
  item: T,
  newOrder: number,
): T => ({ ...item, participationOrder: newOrder });

export const maxParticipationOrder = (input: (Participant | ParallelGroup)[]): number =>
  input.length
    ? Math.max(...input.map(item => item.participationOrder))
    : 0;

export const getOrderGap = (input: (Participant | ParallelGroup)[]): number | null => {
  const maxOrder = maxParticipationOrder(input);
  // We are only checking for non-submitter participants
  for (let i = SUBMITTER_PARTICIPATION_ORDER + 1; i <= maxOrder; i += 1) {
    if (!input.find(item => item.participationOrder === i)) {
      return i;
    }
  }
  return null;
};

export const removeOrderGap = (
  participants: Participant[],
  groups: ParallelGroup[],
): [Participant[], ParallelGroup[]] => {
  const gapInOrder: number | null = getOrderGap([...participants, ...groups]);
  if (gapInOrder === null) {
    return [participants, groups];
  }
  const updatedParticipants = participants.map(p => (p.participationOrder > gapInOrder)
    ? updateParticipationOrder(p, p.participationOrder - 1)
    : p);
  const updatedGroups = groups.map(g => (g.participationOrder > gapInOrder)
    ? updateParticipationOrder(g, g.participationOrder - 1)
    : g);
  return [updatedParticipants, updatedGroups];
};

export const removeOrderGaps = (
  participants: Participant[],
  groups: ParallelGroup[],
): [Participant[], ParallelGroup[]] => {
  let updatedParticipants = participants;
  let updatedGroups = groups;
  let gapInOrder: number | null = getOrderGap([...updatedParticipants, ...updatedGroups]);
  while (gapInOrder !== null) {
    [updatedParticipants, updatedGroups] = removeOrderGap(updatedParticipants, updatedGroups);
    gapInOrder = getOrderGap([...updatedParticipants, ...updatedGroups]);
  }
  return [updatedParticipants, updatedGroups];
};

export const handleReorder = <T extends { participationOrder: number }>(
  items: T[],
  prevOrder: number,
  newOrder: number,
): T[] => {
  const isMovingDown = newOrder > prevOrder;
  const changeToOthers = isMovingDown ? -1 : 1;
  return items.map(item =>
    item.participationOrder >= Math.min(prevOrder, newOrder)
    && item.participationOrder <= Math.max(prevOrder, newOrder)
      ? updateParticipationOrder(item, item.participationOrder + changeToOthers)
      : item);
};

export const handleMoveParticipant = (
  participants: Participant[],
  groups: ParallelGroup[],
  participantFrontendId: string,
  newOrder: number,
): [Participant[], ParallelGroup[]] => {
  const participant = participants.find(p => p.frontendId === participantFrontendId);
  if (!participant) return [participants, groups];
  const prevOrder = participant.participationOrder;
  const movedParticipant = updateParticipationOrder(participant, newOrder);
  const otherParticipants = participants.filter(p => p.frontendId !== participantFrontendId);
  const otherParticipantsAfterMove = handleReorder(otherParticipants, prevOrder, newOrder);
  const groupsAfterMove = handleReorder(groups, prevOrder, newOrder);
  return removeOrderGaps(
    [...otherParticipantsAfterMove, movedParticipant],
    groupsAfterMove,
  );
};

export const handleMoveGroup = (
  participants: Participant[],
  groups: ParallelGroup[],
  groupFrontendId: string,
  newOrder: number,
): [Participant[], ParallelGroup[]] => {
  const group = groups.find(g => g.frontendId === groupFrontendId);
  if (!group) return [participants, groups];
  const prevOrder = group.participationOrder;
  const movedGroup = updateParticipationOrder(group, newOrder);
  const groupParticipants = participants.filter(p => p.participationOrder === prevOrder);
  const groupParticipantsAfterMove = groupParticipants.map(p => updateParticipationOrder(p, newOrder));
  const otherParticipants = participants.filter(p => p.participationOrder !== prevOrder);
  const otherParticipantsAfterMove = handleReorder(otherParticipants, prevOrder, newOrder);
  const otherGroups = groups.filter(g => g.frontendId !== groupFrontendId);
  const otherGroupsAfterMove = handleReorder(otherGroups, prevOrder, newOrder);
  return [
    [...otherParticipantsAfterMove, ...groupParticipantsAfterMove],
    [...otherGroupsAfterMove, movedGroup],
  ];
};

export const handleInsert = <T extends { participationOrder: number }>(
  items: T[],
  newOrder: number,
): T[] => {
  const minToShift = newOrder;
  return items.map(item =>
    item.participationOrder >= minToShift
      ? updateParticipationOrder(item, item.participationOrder + 1)
      : item);
};

export const handleMoveParticipantFromGroup = (
  participants: Participant[],
  groups: ParallelGroup[],
  participantFrontendId: string,
  newOrder: number,
): [Participant[], ParallelGroup[]] => {
  const participant = participants.find(p => p.frontendId === participantFrontendId);
  if (!participant) return [participants, groups];
  const movedParticipant = updateParticipationOrder(participant, newOrder);
  const otherParticipants = participants.filter(p => p.frontendId !== participantFrontendId);
  const otherParticipantsAfterMove = handleInsert(otherParticipants, newOrder);
  const groupsAfterMove = handleInsert(groups, newOrder);
  return removeOrderGaps(
    [...otherParticipantsAfterMove, movedParticipant],
    groupsAfterMove,
  );
};

export const handleGroupDelete = (
  participants: Participant[],
  groups: ParallelGroup[],
  groupParticipationOrder: number,
): [Participant[], ParallelGroup[]] => {
  if (groupParticipationOrder < 0) {
    return [participants, groups];
  }

  const sortedParticipants: Participant[] =
    participants.sort((p1, p2) => p1.participationOrder - p2.participationOrder);
  const updatedParticipants: Participant[] = [sortedParticipants[0]];
  const updatedGroups: ParallelGroup[] = [];
  const amountOfGroupMembers: number =
    sortedParticipants.filter(p => p.participationOrder === groupParticipationOrder).length;
  for (let i = 1; i < sortedParticipants.length; i++) {
    const order: number = sortedParticipants[i].participationOrder;
    const prevParticipantOrder: number = updatedParticipants[i - 1].participationOrder;
    if (order < groupParticipationOrder) {
      // we don't need to change participation order for previous participants
      updatedParticipants.push(sortedParticipants[i]);
    } else if (order === groupParticipationOrder) {
      // group members should become regular participants and have orders like: n, n+1, n+2...
      updatedParticipants.push(updateParticipationOrder(sortedParticipants[i], prevParticipantOrder + 1));
    } else {
      // following participants' orders should be increased to the group members length
      updatedParticipants.push(updateParticipationOrder(sortedParticipants[i], order + amountOfGroupMembers - 1));
    }
  }
  for (let i = 0; i < groups.length; i++) {
    const order: number = groups[i].participationOrder;
    if (order < groupParticipationOrder) {
      updatedGroups.push(groups[i]);
    } else if (order > groupParticipationOrder) {
      updatedGroups.push(updateParticipationOrder(groups[i], order + amountOfGroupMembers - 1));
    }
  }
  return [updatedParticipants, updatedGroups];
};


export const filterSubmitterFromParticipants = (
  participants: (Submitter | Participant)[]
): Participant[] =>
  participants.filter((p): p is Participant =>
    p.participantType !== participantTypeEnum.submitter
  );
