import React from 'react';
import { connect } from 'react-redux';

import { changeRuleWebhook } from 'actions/automatedProcessBuilder';
// import type {ApbRule} from 'types/automatedProcessBuilder';

// type Props = {|
//     formId: string,
//     isReadOnly: boolean,
//     rule: ApbRule,
//     triggerType: TriggerType,
//     changeRuleWebhook: typeof changeRuleWebhook,
// |};

const EditWebhookUrl = props => {
  const { formId, rule, triggerType } = props;
  const { thenValue, ruleId } = rule;
  return (<input
    type='url'
    name='webhookUrl'
    placeholder='https://'
    value={thenValue?.webhook}
    onChange={e => props.changeRuleWebhook(formId, ruleId, triggerType, e.target.value)} />);
};

const mapDispatchToProps = {
  changeRuleWebhook,
};

export default connect(null, mapDispatchToProps)(EditWebhookUrl);
