import React from 'react';
import raf from 'raf';
import styles from './loader.css';
import * as Colors from 'utils/colors';
import { getPixelRatio, setCanvasSize } from 'utils/canvas';


const CIRCLE = Math.PI * 2;
const QUARTER = Math.PI / 2;
const BACKGROUND_COLOR = Colors.spinnerLightBlue;
const FILL_COLOR = Colors.seamlessDocsBlue;

// Base values which may be modified based on pixel configuration:
const BASE_RADIUS = 20;
const BASE_LINE_WIDTH = 8;

export default class Loader extends React.Component<any, any> {
  static defaultProps = {
    top: false,
  };

  componentDidMount() {
    if (this.loader == null) return;
    const loader = this.loader;
    const context = loader.getContext('2d');
    if (context != null) {
      const pixelRatio = getPixelRatio(context);
      setCanvasSize(loader, pixelRatio);
      context.lineWidth = BASE_LINE_WIDTH * pixelRatio;
      this.radius = BASE_RADIUS * pixelRatio;
      this.animate(loader, context, 0, BACKGROUND_COLOR, FILL_COLOR);
    }
  }

  loader
  radius

  animate = (
    canvas,
    context,
    current,
    fillColor,
    backgroundColor
  ) => {
    if (canvas == null) return;
    this.drawCircle(canvas, context, current, fillColor, backgroundColor);

    const incremented = current + ((current > 20 && current < 80) ? 6 : 2);

    if (incremented <= 100) {
      raf(() => this.animate(canvas, context, incremented, fillColor, backgroundColor));
    } else {
      // Switch the colors of the circles and starts over
      setTimeout(() => {
        raf(() => this.animate(canvas, context, 0, backgroundColor, fillColor));
      }, 500);
    }
  };

  drawCircle = (
    canvas,
    context,
    current,
    fillColor,
    backgroundColor
  ) => {
    if (canvas == null) return;
    const x = canvas.width / 2;
    const y = canvas.height / 2;

    context.clearRect(0, 0, canvas.width, canvas.height);
    const currentFraction = (CIRCLE * current / 100) - QUARTER;

    // Draw the background circle
    this.drawArc(context, backgroundColor, x, y, currentFraction, QUARTER * 3);

    // Draw the percent circle
    this.drawArc(context, fillColor, x, y, -QUARTER, currentFraction);
  };

  drawArc = (
    context,
    color,
    x,
    y,
    startAngle,
    endAngle
  ) => {
    context.beginPath();
    context.strokeStyle = color;
    context.arc(x, y, this.radius, startAngle, endAngle, false);
    context.stroke();
  };

  render() {
    const { top, style } = this.props;
    return (
      <div className={top ? styles.top : styles.center} style={{ ...style }}>
        <canvas
          ref={canvas => { this.loader = canvas; }}
          width='90px'
          height='90px' />
      </div>
    );
  }
}
