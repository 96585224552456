import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { values } from 'lodash';
import styles from './StageEditor.css';
import StageInput from 'components/Stages/StageInput';
import ColorPicker from 'components/Stages/ColorPicker';
import { ALL_VIRTUAL_STAGE_NAME } from 'constants/stages';


import crossIcon from 'icons/stages/editorCross.svg';


export class StageEditor extends PureComponent<any, any> {
  state = {
    inputValue: this.props.stage.name,
    errorMessage: '',
    color: this.props.stage.color,
  }

  updateStage = () => {
    const { props: { stages, updateStage, stage }, state: { inputValue, color } } = this;
    const value = inputValue.trim();
    if (!value) {
      this.setState({ errorMessage: inputValue ? 'Invalid stage name.' : 'Give the stage a name.' });
      return;
    }
    const names = [ALL_VIRTUAL_STAGE_NAME, ...stages.map(({ name }) => name)];
    const alreadyExists = names.some(name => name.toLowerCase() === value.toLowerCase());
    if (value !== stage.name && alreadyExists) {
      this.setState({ errorMessage: 'Name already exists.' });
      return;
    }
    updateStage({ name: value, color });
    this.setState({ inputValue: '' });
  }

  onChangeName = ({ target: { value } }) => {
    this.setState({ inputValue: value, errorMessage: '' });
  }

  onChangeColor = color => {
    this.setState({ color });
  }

  render() {
    const {
      props: { closeEditor },
      state: { errorMessage, inputValue, color },
      onChangeName, updateStage,
    } = this;
    return (
      <div className={styles.editorContainer}>
        <img className={styles.crossIcon} src={crossIcon} alt='Close Editor' onClick={closeEditor} />
        <p className={styles.name}>Name</p>
        <StageInput
          errorMessage={errorMessage}
          value={inputValue}
          onChange={onChangeName}
          onSubmit={updateStage}
          className={styles.stageInput} />
        <ColorPicker value={color} onChange={this.onChangeColor} />
        <div className={styles.buttonsContainer}>
          <button className={styles.buttonSave} onClick={updateStage}>Save</button>
          <button className={styles.buttonCancel} onClick={closeEditor}>Cancel</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ stages, submissionTable: { formId } }) => {
  let arrayFromStages: any[] = [];
  if (formId) {
    arrayFromStages = values(stages[formId]);
  }
  return { stages: arrayFromStages };
};

export default connect(mapStateToProps)(StageEditor);
