import React from 'react';
import { noop } from 'lodash';
import DirectorIcon from 'icons/roles/director.svg';
import ProcessorIcon from 'icons/roles/processor.svg';
import AnalystIcon from 'icons/roles/analyst.png';
import VisitorIcon from 'icons/roles/visitor.svg';
import { capitalizeFirstLetter } from 'utils/strings';
import styles from './Role.css';

const iconMap = {
  DIRECTOR: DirectorIcon,
  OWNER: null,
  ANALYST: AnalystIcon,
  PROCESSOR: ProcessorIcon,
  VISITOR: VisitorIcon,
};

class Role extends React.Component<any, any> {
  static defaultProps = {
    isSelectedByKeyboard: false,
    onSelect: noop,
  }

  getContainerClassNames() {
    const classNames = [
      styles.container,
      this.props.isSelectedByKeyboard ? styles.isHover : null,
    ];
    const truthyClassNames = classNames.filter(className => !!className);
    return truthyClassNames.join(' ');
  }

  renderDisplayText() {
    const allCapsRoleName = this.props.selectionData;
    return `All ${capitalizeFirstLetter(allCapsRoleName)}s`;
  }

  handleOnClick = () => {
    const {
      onSelect,
      selectionData: roleName,
    } = this.props;
    onSelect(roleName);
  }

  render() {
    const {
      selectionData,
    } = this.props;
    return (
      <div className={this.getContainerClassNames()} onClick={this.handleOnClick}>
        <img className={styles.roleIcon} src={iconMap[selectionData]} alt='application role' />

        <div className={styles.displayNameContainer}>
          {this.renderDisplayText()}
        </div>
      </div>
    );
  }
}

export default Role;
