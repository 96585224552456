const stageDarkGrey = '#6D7D8F';
const stageLightGreen = '#8EBE84';
const stageMagenta = '#D13E67';
const stageYellow = '#F2C14F';
const stageBlue = '#4253AF';
const stageBlueGrey = '#78A4B2';
const stageCyan = '#6CD3CF';
const stageGreen = '#457B3B';
const stageTeal = '#419488';
const stageSalmon = '#F09084';
const stageGrey = '#9E9E9E';
const stageOrange = '#E33700';
const stagePurple = '#94416E';

export const colors: string[] = [
  stageDarkGrey,
  stageLightGreen,
  stageMagenta,
  stageYellow,
  stageBlue,
  stageBlueGrey,
  stageCyan,
  stageGreen,
  stageTeal,
  stageSalmon,
  stageGrey,
  stageOrange,
  stagePurple,
];

export const ensureValidColorNumber = (colorNumber: number) => {
  if (!colorNumber || colorNumber >= colors.length || colorNumber < 0) return 0;
  return colorNumber;
};

export const isColorSelectable = (colorNumber: number) => colorNumber !== 0;

export const getColor = (colorNumber: number) =>
  colors[ensureValidColorNumber(colorNumber)];

export const getNumber = (hexColor: string) =>
  ensureValidColorNumber(colors.findIndex(hex => hexColor === hex));

export const convertServerStageToClient = ({color, ...rest}) => ({
  ...rest,
  color: ensureValidColorNumber(Number(color)),
});

export const convertClientStageToServer = ({color, ...rest}) => ({
  ...rest,
  color: `${ensureValidColorNumber(color)}`,
});
