import {invert} from 'lodash';
import submitted from 'icons/lobby/submitted.svg';
import saved from 'icons/lobby/saved.svg';
import shared from 'icons/lobby/shared.svg';
import assigned from 'icons/lobby/assigned.svg';
import signedByMe from 'icons/lobby/signedByMe.svg';
import {
  ALL_SUBMISSION_FILTER_INITIAL_LOAD,
  ALL_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
} from 'constants/types/submissionsTableActionTypes';
import * as types from 'constants/types/quickFilterActionTypes';
// import type {
//   QuickFilterMetadata,
// } from 'types/quickFilters';

const ALL = 'All';
const ASSIGNED_TO_ME = 'Assigned to me';
const DRAFTS = 'Incomplete';
const SHARED = 'Shared with me';
const SUBMITTED = 'Submitted';
const SIGNED_BY_ME = 'Signed by me';

export const ARCHIVED = 'Archived';

export const QUICK_FILTER_METADATA = [
  // {src: allSubmissions, text: ALL},
  {src: submitted, text: SUBMITTED},
  {src: saved, text: DRAFTS},
  {src: shared, text: SHARED},
  {src: assigned, text: ASSIGNED_TO_ME},
  {src: signedByMe, text: SIGNED_BY_ME},
];

export const URL_TO_FILTER = {
  assigned: ASSIGNED_TO_ME,
  [DRAFTS.toLowerCase()]: DRAFTS, // NEXT-220 - last-minute product decision to change the name of this filter
  shared: SHARED,
  submitted: SUBMITTED,
};

export const FILTER_TO_URL = invert(URL_TO_FILTER);

export const quickFiltersEnum = {
  ALL,
  ASSIGNED_TO_ME,
  DRAFTS,
  SHARED,
  SUBMITTED,
  SIGNED_BY_ME,
};

export const QUICK_FILTER_INITIAL_LOADS = [
  types.ALL_FILTER_INITIAL_LOAD,
  types.ASSIGNED_TO_ME_FILTER_INITIAL_LOAD,
  types.DRAFTS_FILTER_INITIAL_LOAD,
  types.SHARED_FILTER_INITIAL_LOAD,
  types.SUBMITTED_FILTER_INITIAL_LOAD,
  types.SIGNED_BY_ME_FILTER_INITIAL_LOAD,
];

export const QUICK_FILTER_SUBSEQUENT_LOADS = [
  types.ALL_FILTER_SUBSEQUENT_LOAD,
  types.ASSIGNED_TO_ME_FILTER_SUBSEQUENT_LOAD,
  types.DRAFTS_FILTER_SUBSEQUENT_LOAD,
  types.SHARED_FILTER_SUBSEQUENT_LOAD,
  types.SUBMITTED_FILTER_SUBSEQUENT_LOAD,
  types.SIGNED_BY_ME_FILTER_SUBSEQUENT_LOAD,
];

export const QUICK_FILTER_INITIAL_SUBMISSION_LOADS = [
  ALL_SUBMISSION_FILTER_INITIAL_LOAD,
  types.ASSIGNED_TO_ME_SUBMISSION_FILTER_INITIAL_LOAD,
  types.DRAFTS_SUBMISSION_FILTER_INITIAL_LOAD,
  types.SHARED_SUBMISSION_FILTER_INITIAL_LOAD,
  types.SUBMITTED_SUBMISSION_FILTER_INITIAL_LOAD,
  types.ARCHIVED_SUBMISSION_FILTER_INITIAL_LOAD,
  types.SIGNED_BY_ME_SUBMISSION_FILTER_INITIAL_LOAD,
];

export const QUICK_FILTER_SUBSEQUENT_SUBMISSION_LOADS = [
  ALL_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
  types.ASSIGNED_TO_ME_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
  types.DRAFTS_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
  types.SHARED_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
  types.SUBMITTED_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
  types.SIGNED_BY_ME_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
];
