export const GET_STAGES_OF_FORM = 'GET_STAGES_OF_FORM';
export const CREATE_STAGES = 'CREATE_STAGES';
export const ASSIGN_STAGE = 'ASSIGN_STAGE';
export const ASSIGN_STAGES = 'ASSIGN_STAGES';
export const UNASSIGN_STAGE = 'UNASSIGN_STAGE';
export const UNASSIGN_STAGES = 'UNASSIGN_STAGES';
export const REORDER_STAGES = 'REORDER_STAGES';
export const DELETE_STAGE = 'DELETE_STAGE';
export const UPDATE_STAGE = 'UPDATE_STAGE';
export const CLEAR_SELECTED_STAGE = 'CLEAR_SELECTED_STAGE';
export const SELECT_ALL_VIRTUAL_STAGE = 'SELECT_ALL_VIRTUAL_STAGE';
