import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import PermissionLevels from './PermissionLevels';
import Role from 'components/Users/Role';
import { userRolesEnum } from 'constants/user';

import styles from './styles.css';
import inlineStyles from './inlineStyles';


export default class RolePermissionRow extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { showDelete: false };
  }

  handleHover(hoverState) {
    return () => this.setState({ showDelete: hoverState });
  }

  handleRolePermissionChange = (isChecked, permissionLevel) => {
    const { changeRolePermission, roleName } = this.props;
    changeRolePermission(roleName, isChecked, permissionLevel);
  };

  handlePermissionRemoval = () => {
    const { removeRole, roleName } = this.props;
    removeRole(roleName);
  };

  render() {
    const { permissionLevels, roleName } = this.props;

    const rowStyles = styles.tableRow;
    return (
      <tr
        className={`${styles.permissionsRow} ${rowStyles}`}
        onMouseOver={this.handleHover(true)}
        onMouseLeave={this.handleHover(false)}
      >
        <td>
          <div className={styles.permissionUserGroup}>
            <Role selectionData={roleName} />
            {this.state.showDelete && (
              <IconButton classes={{ root: styles.permissionRowDelete }} onClick={this.handlePermissionRemoval}>
                <Clear style={inlineStyles.clearIconStyle} />
              </IconButton>
            )}
          </div>
        </td>
        <PermissionLevels
          isAuthor={false}
          onCheck={this.handleRolePermissionChange}
          permissionLevels={permissionLevels}
          showCanEditCheckbox={![userRolesEnum.VISITOR, userRolesEnum.ANALYST].includes(roleName)}
          showСanProcessSubmissionsCheckbox={![userRolesEnum.VISITOR].includes(roleName)}
        />
      </tr>
    );
  }
}
