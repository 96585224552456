export const tokenTypes = {
  WithAuth: 'id_token',
  CoregAuth: 'coregistration_token',
};

export const registrationErrorsEnum = {
  PASSWORDS_DO_NOT_MATCH: 'Passwords must match.',
  USER_IS_ALREADY_ACTIVE_AND_COMPLETE: 'This email address is already in use.',
  USER_NEEDS_TO_VERIFY_EMAIL: 'Please verify your email before logging in.',
  JWT_SERIALIZATION_FAILURE: 'We\'re sorry, your email verification has failed.',
};
