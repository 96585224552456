import React from 'react';
import FlashOnIcon from '@material-ui/icons/FlashOnRounded';
import WarningIcon from '@material-ui/icons/WarningRounded';

import TriggerDropdown from 'components/AutomatedProcessBuilder/TriggerDropdown';
import Tooltip from 'components/Tooltip';
import SelectedTrigger from './SelectedTrigger';
import {ruleStatusEnum} from 'constants/automatedProcessBuilder';
import {triggerTypeEnum} from 'constants/triggers';
import {isRuleEnabled} from 'utils/automatedProcessBuilder';
import {joinClassNames} from 'utils/strings';
import styles from './Rule.css';
import apbRule from 'icons/submissions/apbRule.svg';
import trashCan from 'icons/submissions/apbTrashCan.svg';


export default function Rule({changeRuleStatus, deleteRule, formId, index, rule, entities}) {
  function handleChangeStatus() {
    changeRuleStatus(formId, rule.ruleId, !isRuleEnabled(rule));
  }

  function handleDeleteRule() {
    deleteRule(formId, rule.ruleId);
  }

  function getStatusTooltip() {
    if (rule.errorMessages.length) {
      return 'Rule has become invalid';
    } else if (isRuleEnabled(rule)) {
      return 'Deactivate rule';
    }
    return 'Activate rule';
  }

  function renderErrors() {
    if (rule.errorMessages.length > 0) {
      return (
        <div className={styles.errorsLayout}>
          {rule.errorMessages.map(error => (
            <div key={error}>{error}. This rule is no longer active.</div>
          ))}
        </div>
      );
    }
    return null;
  }

  function renderRuleContent() {
    const isThenEnabled = !!rule.ifValue;
    return (
      <div className={styles.conditionalLayout}>
        <div className={styles.rulePhraseLayout}>
          <div className={styles.triggerType}>
            <div className={styles.ruleCopyAppearance}>When</div>
            <div>
              <TriggerDropdown
                formId={formId}
                ruleId={rule.ruleId}
                isNew={rule.isNew}
                shouldAutoFocus
                trigger={rule.ifTrigger}
                triggerType={triggerTypeEnum.IF}/>
            </div>
          </div>
          <div className={styles.valueLayout}>
            <SelectedTrigger
              formId={formId}
              rule={rule}
              triggerType={triggerTypeEnum.IF}
              entities={entities}/>
          </div>
        </div>
        <div className={joinClassNames(styles.rulePhraseLayout, styles.rulePhraseGrey)}>
          <div className={styles.triggerType}>
            <div className={styles.ruleCopyAppearance}>Then</div>
            <div>
              <TriggerDropdown
                formId={formId}
                isEnabled={isThenEnabled}
                isNew={rule.isNew}
                ruleId={rule.ruleId}
                trigger={rule.thenTrigger}
                triggerType={triggerTypeEnum.THEN}/>
            </div>
          </div>
          <div className={styles.valueLayout}>
            <SelectedTrigger
              entities={entities}
              formId={formId}
              isReadOnly={!isThenEnabled}
              rule={rule}
              triggerType={triggerTypeEnum.THEN}/>
          </div>
        </div>
      </div>
    );
  }

  function renderStatusButton() {
    const title = getStatusTooltip();
    const Icon = rule.errorMessages.length ? WarningIcon : FlashOnIcon;
    return (
      <Tooltip title={title}>
        <button
          className={joinClassNames(styles.statusButtonAppearance, styles.statusButtonLayout)}
          onClick={handleChangeStatus}>
          <Icon style={{fontSize: '16px'}}/>
        </button>
      </Tooltip>
    );
  }

  const message = rule.status === ruleStatusEnum.DISABLED ? 'Inactive' : '';
  return (
    <div
      className={joinClassNames(
        rule.errorMessages.length ? styles.isInvalid : '',
        rule.status === ruleStatusEnum.DISABLED ? styles.isDisabled : '',
        styles.ruleWrapperLayout
      )}>
      <div className={styles.ruleHeader}>
        <div>
          <span className={styles.ruleHeaderTitle}>Rule #{index}</span>
          <span className={joinClassNames(styles.ruleMessageLayout, styles.ruleMessageAppearance)}>{message}</span>
        </div>
        <button aria-label='Delete this rule' className={styles.deleteRuleButton} onClick={handleDeleteRule}>
          <img src={trashCan} alt=''/>
        </button>
      </div>
      <div className={styles.ruleLayout}>
        <div className={styles.ruleContentLayout}>
          <div className={styles.ruleButtonAndGraphics}>
            {renderStatusButton()}
            <img src={apbRule} alt=''/>
          </div>
          {renderRuleContent()}
        </div>
      </div>
      {renderErrors()}
    </div>
  );
}
