import React from 'react';
import { connect } from 'react-redux';
import { memoize } from 'lodash';
import { changeRuleStage } from 'actions/automatedProcessBuilder';
import StageSelector from 'components/Stages/StageSelector';
import {
  checkFormCapability,
  getStagesList,
} from '../../../reducers';
import { formCapabilitiesEnum } from 'constants/tableConsts';
import { getStageFromRule, getOtherStageFromRule } from 'utils/automatedProcessBuilder';


class StageTrigger extends React.Component<any, any> {
  state = {
    stageSearch: '',
  }

  filterOutSelectedStage = memoize(
    (stages, selectedStage) => stages.filter(stage => stage !== selectedStage),
    (stages, selectedStage) => `${selectedStage ? selectedStage.name : 'none'}-${stages.length}`
  )

  handleClearStage = () => {
    const {
      formId,
      rule,
      triggerType,
    } = this.props;
    this.props.changeRuleStage(formId, rule.ruleId, triggerType, null);
  }

  handleSelectStage = stage => {
    const {
      formId,
      rule,
      triggerType,
    } = this.props;
    const stageName = stage ? stage.name : null;
    this.props.changeRuleStage(formId, rule.ruleId, triggerType, stageName);
  }

  render() {
    const {
      canCreate,
      isReadOnly,
      selectedStage,
      otherSelectedStage,
      stages,
    } = this.props;
    return (
      <StageSelector
        canCreate={canCreate}
        isReadOnly={isReadOnly}
        onClearStage={this.handleClearStage}
        onSelectStage={this.handleSelectStage}
        selectedStage={selectedStage}
        stages={this.filterOutSelectedStage(stages, otherSelectedStage)} />
    );
  }
}

const mapStateToProps = (state, { formId, rule, triggerType }) => {
  const stages = getStagesList(state, formId);
  return {
    canCreate: checkFormCapability(state, formId, formCapabilitiesEnum.OrganizeStages),
    selectedStage: getStageFromRule(rule, triggerType, stages),
    otherSelectedStage: getOtherStageFromRule(rule, triggerType, stages),
    stages,
  };
};

const mapDispatchToProps = {
  changeRuleStage,
};

export default connect(mapStateToProps, mapDispatchToProps)(StageTrigger);
