import React from 'react';
import styles from './StageInput.css';
import { ENTER_KEY, _getKeyboardCode } from 'utils/keyboard';


export default class StageInput extends React.PureComponent<any, any> {
  static defaultProps = {
    className: '',
    inputRef: React.createRef(),
    errorMessage: '',
    legend: '',
  }

  componentDidMount() {
    if (this.props.inputRef.current) this.props.inputRef.current.focus();
  }

  handleOnKeyUp = e => {
    const key = _getKeyboardCode(e);
    if (key !== ENTER_KEY) return;
    e.stopPropagation();
    this.props.onSubmit();
  }

  render() {
    const { errorMessage, value, className, legend, onChange, inputRef } = this.props;
    const message = errorMessage || legend;
    const containerClass = `${className} ${errorMessage ? styles.error : ''}`;

    return (
      <div className={containerClass}>
        <input
          id='stageInput'
          ref={inputRef}
          className={styles.stageInput}
          value={value}
          onChange={onChange}
          onKeyUp={this.handleOnKeyUp} />
        {message && <span className={styles.message}>{message}</span>}
      </div>
    );
  }
}

