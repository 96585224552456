import React, { useMemo, useCallback } from 'react';
import MultiSelectorBase from 'components/MultiSelector';
import Item from './Item';
import ShowOverflowButton from './ShowOverflowButton';
import AddSelectionButton from 'components/MultiSelector/shared/AddSelectionButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import withKeyboardFunctionality from 'components/MultiSelector/withKeyboardFunctionality';
import { immutableRemoveElementInArray } from 'utils/reduxHelper';
import styles from '../../RecipientsSelector.css';


const MultiSelector = ({ items, values, addButtonCaption, removeCaption, onChange }) => {
  const handleRemoveSelection = useCallback(item => {
    const index = values.findIndex(current => current === item.id);
    onChange(immutableRemoveElementInArray(values, index));
  }, [onChange, values]);

  const handleAddSelections = useCallback(addedSelections => {
    const newItems = addedSelections.map(({ id }) => id);
    onChange([...values, ...newItems]);
  }, [onChange, values]);

  const { selections, missingFieldsId } = useMemo(() => (
    values.reduce((accum, id) => {
      const hydrated = items.find(field => field.id === id);
      if (!hydrated) accum.missingFieldsId.push(id);
      accum.selections.push(hydrated || { id, label: 'Missing field' });
      return accum;
    }, { selections: [], missingFieldsId: [] })
  ), [values, items]);

  const dropdownItems = useMemo(() => (
    items.filter(({ id }) => !values.includes(id))
  ), [values, items]);

  const ShowTypeaheadButton = () => {
    const Icon = !selections.length ? KeyboardArrowDownIcon : AddIcon;
    return (
      <span className={styles.addSelectionsButton}>
        {!selections.length && <span className={styles.triggerLabel}>CHOOSE</span>}
        <AddSelectionButton Icon={Icon} caption={addButtonCaption} />
      </span>
    );
  };

  const renderSelection = props => {
    const ItemWithKeyboard = withKeyboardFunctionality(Item);
    return (
      <ItemWithKeyboard
        {...props}
        invalid={missingFieldsId.includes(props.selectionData.id)}
        invalidLabel='Selected field has been deleted'
        removeCaption={removeCaption} />
    );
  };

  return (
    <div className={styles.multiSelectorContainer}>
      <MultiSelectorBase
        avoidForcedPopoverReposition
        hidePeak
        onRemoveSelection={handleRemoveSelection}
        selections={selections}
        onAddSelections={handleAddSelections}
        renderSelection={renderSelection}
        ShowTypeaheadButton={ShowTypeaheadButton}
        ShowOverflowButton={ShowOverflowButton}
        typeaheadResults={dropdownItems}
        resultsListClassName={styles.resultListContainer}
        maxElements={2}
        popoverOrigin={{ vertical: 'top', horizontal: 'right' }}
        overflowButtonCountsAsElement />
    </div>
  );
};

export default MultiSelector;
