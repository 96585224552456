import {SET_IS_PAYMENT_SYSTEM_PENDING, SET_IS_SUCCESS_MESSAGE_NEEDED} from 'constants/paymentSystem';

export const initialState = {
  isPending: false,
  shouldRenderSuccessMessage: false,
};

export default function paymentSystem(state = initialState, _action) {
  const action = _action;
  switch (action.type) {
    case SET_IS_PAYMENT_SYSTEM_PENDING:
      return {...state, isPending: action.isPending};
    case SET_IS_SUCCESS_MESSAGE_NEEDED:
      return {...state, shouldRenderSuccessMessage: action.shouldRenderSuccessMessage};
    default:
      return state;
  }
}

export const getIsPending = state => state.isPending;

export const getShouldRenderSuccessMessage = state => state.shouldRenderSuccessMessage;
