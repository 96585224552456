import {FETCH_ASSET} from 'constants/types/submissionsActionTypes';
import api from 'reducers/api';


const initialState = {
  hasError: false,
  fetchingAsset: false,
  signedUrl: null,
};

export default function fetchAssetReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ASSET:
      return api(action, state, {
        success: () => ({
          ...initialState,
          signedUrl: action.payload.signedUrl,
        }),
        failure: () => ({
          ...initialState,
          hasError: true,
        }),
        pending: () => ({
          ...initialState,
          fetchingAsset: true,
        }),
      });
    default:
      return state;
  }
}

export const getSignedUrlError = state => state.hasError;

export const getFetchingAssetState = state => state.fetchingAsset;

export const getSignedUrl = state => state.signedUrl;
