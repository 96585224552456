import * as React from 'react';
import injectPermissions from 'decorators/injectPermissions';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import MenuIcon from '@material-ui/icons/Menu';
import * as Colors from 'utils/colors';
import style from './header.css';
import AccountMenuPopover from 'components/Header/AccountMenuPopover';
import { WindowSize, getWindowSizeData } from 'components/WindowSize';


const arrowStyle = {
  color: Colors.textPrimary,
  opacity: 0.5,
  fontSize: '18px',
  verticalAlign: 'middle',
};


class AccountButton extends React.Component<any, any> {
  static defaultProps = {
    name: 'unknown',
  };

  constructor(props) {
    super(props);
    const { isPhone } = getWindowSizeData();
    this.state = { isMenuOpen: false, isPhone };
    this.buttonContainerEl = React.createRef();
  }

  buttonContainerEl;

  _closeMenu = () => this.setState({ isMenuOpen: false });

  _closeMenuAndCall = callback => event => {
    this._closeMenu();
    callback(event);
  };

  handleOpenAccountMenu = () => {
    this.setState({ isMenuOpen: true });
  };

  handleCloseAccountMenu = () => {
    this._closeMenu();
  };

  handleWindowSizeOnChange = ({ isPhone }) => {
    this.setState({ isPhone });
  };

  handleOnLogout = this._closeMenuAndCall(this.props.onLogout);

  render() {
    const { name, role, subdomain } = this.props;
    const { isMenuOpen, isPhone } = this.state;
    const containerClassName = isPhone ? '' : style.accountContainer;

    return (
      <span>
        <WindowSize onChange={this.handleWindowSizeOnChange} />
        <div className={containerClassName}>
          <span ref={this.buttonContainerEl}>
            {
              isPhone ?
                <IconButton onClick={this.handleOpenAccountMenu} style={{ margin: 8 }}>
                  {/* @ts-ignore*/}
                  <MenuIcon color={Colors.orange500} />
                </IconButton>
                :
                <div onClick={this.handleOpenAccountMenu}>
                  <span className={style.userName}>{name}</span>
                  <Icon style={arrowStyle}>keyboard_arrow_down</Icon>
                </div>
            }
          </span>
          <AccountMenuPopover
            anchorEl={this.buttonContainerEl.current}
            onFormCenter={this.handleCloseAccountMenu}
            onLogout={this.handleOnLogout}
            onRequestClose={this.handleCloseAccountMenu}
            open={isMenuOpen}
            name={name}
            role={role}
            subdomain={subdomain}
          />
        </div>
      </span>
    );
  }
}

export default injectPermissions(AccountButton);
