import React from 'react';
import dragDropContext from 'containers/dragDropContext';
import DraggableStage from './DraggableStage';
import AllVirtualStage from './AllVirtualStage';
import {Stage as StageType, SelectedStageName} from 'types/stages';
import {reorderStages} from 'actions/stages';
import {
  applyStageFilter as applyStageFilterAction,
  removeStageFilters as removeStageFiltersAction,
} from 'actions/submissionsManager/tableApi';
import {Action} from 'types/api';

type Props = {
  expandSidebar: () => void,
  formId: string,
  isReadOnly: boolean,
  onSelectAllVirtualStage: () => Action,
  reorderStages: typeof reorderStages,
  applyStageFilter: typeof applyStageFilterAction,
  removeStageFilter: typeof removeStageFiltersAction,
  selectedStageName: SelectedStageName,
  sidebarExpanded: boolean,
  stages: StageType[],
  totalSubmissions: number,
  cachedTotalSubmissions: number,
};

class StagesList extends React.Component<Props, any> {
  handleReorder = (origin, destination) => {
    const stageOrder = this.props.stages.map(stage => stage.name);
    const movedStage = stageOrder.splice(origin, 1)[0];
    stageOrder.splice(destination, 0, movedStage);
    this.props.reorderStages(this.props.formId, stageOrder);
  }

  render() {
    const {
      sidebarExpanded,
      stages,
      isReadOnly,
      selectedStageName,
      totalSubmissions,
      cachedTotalSubmissions,
      expandSidebar,
    } = this.props;
    return (
      <React.Fragment>
        <AllVirtualStage
          count={cachedTotalSubmissions > totalSubmissions ? cachedTotalSubmissions : totalSubmissions}
          onClick={this.props.removeStageFilter}
          selectedStageName={selectedStageName}
          isExpanded={sidebarExpanded} />
        {stages.map((stage, index) => (
          <DraggableStage
            canDrag={sidebarExpanded}
            key={stage.name}
            data={stage}
            onClick={() => this.props.applyStageFilter(stage.name)}
            onReorder={this.handleReorder}
            inSidebar
            showDetails={sidebarExpanded}
            selected={selectedStageName === stage.name}
            index={index}
            isReadOnly={isReadOnly}
            expandSidebar={expandSidebar} />
        ))}
      </React.Fragment>
    );
  }
}

export default dragDropContext(StagesList);
