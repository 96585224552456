import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './BulkActionWrapper.css';
import TagsSelect from '../Components/TagsSelect';
import { bindActionCreators } from 'redux';
import { createTags } from 'actions/tags';
import { Tag } from 'types/tags';

const AddTag = props => {
  const { options, createTag, addTag } = props;
  const [value, setValue] = useState<Tag[]>([]);

  const handleCreateTag = name => {
    createTag([name], () => {});
  };

  const handleAddTag = () => {
    const { dispatch, submissionIds, formId, errorModalToggle, includeAllSubmissions, excludedSubmissionIds } = props;
    addTag({
      submissionIds,
      tagNames: value.map(val => val.name),
      formId,
      dispatch,
      includeAllSubmissions,
      excludedSubmissionIds,
      onError: () => errorModalToggle(true),
    });
  };

  const onAddTagButtonClick = () => {
    const { includeAllSubmissions, loaderModalToggle, submissionIds } = props;
    const submissionsNumber = includeAllSubmissions ? 'all the' : `the ${submissionIds.length} selected`;
    loaderModalToggle(
      true,
      'addTag',
      'Add Tags',
      `Are you sure you want to add tags for ${submissionsNumber} submission(s)?`,
      handleAddTag,
    );
  };

  return (
    <div className={styles.addToContainer}>
      <label className={styles.label} htmlFor='addTag'>Add tags:</label>
      <div className={styles.controlsContainer}>
        <div className={styles.tagsInput}>
          <TagsSelect
            options={options}
            selected={value}
            setSelected={setValue}
            createTag={handleCreateTag}
            shouldCreateTag={false}
            multiple/>
        </div>
        <button type='button' className={styles.button} onClick={onAddTagButtonClick} disabled={!value.length}>Add</button>
      </div>
    </div>);
};

export default connect(null, dispatch => ({
  createTag: bindActionCreators(createTags, dispatch),
  dispatch,
}))(AddTag);
