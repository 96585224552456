import React from 'react';
import { Motion, spring } from 'react-motion';
import styles from './ErrorNotification.css';
import errorIcon from 'icons/icon52.svg';


export default class ErrorNotification extends React.Component<any, any> {
  constructor(props) {
    super(props);

    let hasErrorMessage = false;
    let errorMessage = null;

    if (props.location && props.location.query && props.location.query.errorMessage) {
      hasErrorMessage = true;
      errorMessage = props.location.query.errorMessage;
    }

    this.state = {
      open: hasErrorMessage,
      errorMessage,
    };
  }

  componentDidMount() {
    const { open } = this.state;

    if (open) {
      this.setAutoHideTimer();
    }
  }

  setAutoHideTimer() {
    const { autoHideDuration } = this.props;

    setTimeout(() => {
      this.setState({ open: false });
      this.updateUrlPath();
    }, autoHideDuration);
  }

  updateUrlPath() {
    const { location, router } = this.props;
    router.replace(location.pathname);
  }

  render() {
    const { open, errorMessage } = this.state;

    return (
      <Motion style={{ padding: spring(open ? 12 : 0), maxHeight: spring(open ? 46 : 0) }}>
        {({ padding, maxHeight }) =>
          <div className={styles.errorWrapper} style={{ padding, maxHeight, display: maxHeight > 1 ? 'block' : 'none' }}>
            <img className={styles.notificationImage} src={errorIcon} width='20' alt='Error' />
            <p className={styles.errorMessage}>{errorMessage}</p>
          </div>
        }
      </Motion>
    );
  }
}
