import {map} from 'lodash';
import * as types from 'constants/types/lobbyTableActionTypes';
import {FORM_INTO_FOLDER, DELETE_FOLDER, UPSERT_FOLDER} from 'constants/types/lobbyFolderNavigationActionTypes';
import {ARCHIVE_FORM_API, DELETE_FORM} from 'constants/types/lobby';
import * as quickFilterTypes from 'constants/types/quickFilterActionTypes';
import api from '../api';

interface State {
  archivingSelectedIndex: number | null,
  error: any,
  failure: boolean,
  fetching: boolean,
  fetchingMoreResults: boolean,
  folderId: string | null,
  isSnackbarOpen: boolean,
  snackbarMessage: string,
  tableData: any[],
  nextPageToken: string,
  isSearchPage: boolean,
}

export const initialState: State = {
  archivingSelectedIndex: null,
  error: {},
  failure: false,
  fetching: false,
  fetchingMoreResults: false,
  folderId: null,
  isSnackbarOpen: false,
  snackbarMessage: '',
  tableData: [],
  nextPageToken: '',
  isSearchPage: false,
};

const handleFailure = (state, action) => ({
  ...state,
  error: action.error,
  failure: true,
  fetching: false,
  fetchingMoreResults: false,
});

const handleSubsequentPending = state => ({
  ...state,
  fetchingMoreResults: true,
  fetching: true,
});

const removeFormRow = (rows, formId) => rows.filter(rowFormId => rowFormId !== formId);

export default function lobbyTable(state = initialState, action) {
  switch (action.type) {
    case types.NEW_TABLE_API:
      return api(action, state, {
        pending: () => ({
          ...initialState,
          fetching: true,
          nextPageToken: state.nextPageToken,
        }),
        success: () => {
          if (action.error) {
            return {
              ...initialState,
              fetching: false,
              failure: true,
              error: action.error,
            };
          }
          const table = action.payload;
          return {
            ...state,
            fetching: false,
            failure: false,
            folderId: table.folderId,
            tableData: map(table.tableData, 'formId'),
            isSearchPage: false,
          };
        },
        failure: () => ({
          ...initialState,
          error: action.error,
          fetching: false,
          failure: true,
        }),
      });
    case types.UPDATE_TABLE_API:
      return api(action, state, {
        pending: () => handleSubsequentPending(state),
        success: () => {
          if (action.error) {
            return {
              ...state,
              fetching: false,
              failure: true,
              error: action.error,
              fetchingMoreResults: false,
            };
          }
          const payloadTableData = map(action.payload.tableData, 'formId');
          // @ts-ignore
          const newTableData = state.tableData.concat(payloadTableData);
          return {
            ...state,
            tableData: newTableData,
            failure: false,
            fetching: false,
            fetchingMoreResults: false,
            isSearchPage: false,
          };
        },
        failure: () => handleFailure(state, action),
      });
    case quickFilterTypes.ALL_FILTER_INITIAL_LOAD:
    case quickFilterTypes.ASSIGNED_TO_ME_FILTER_INITIAL_LOAD:
    case quickFilterTypes.DRAFTS_FILTER_INITIAL_LOAD:
    case quickFilterTypes.SHARED_FILTER_INITIAL_LOAD:
    case quickFilterTypes.SUBMITTED_FILTER_INITIAL_LOAD:
    case quickFilterTypes.SIGNED_BY_ME_FILTER_INITIAL_LOAD:
      return api(action, state, {
        pending: () => ({
          ...state,
          fetching: true,
          nextPageToken: '',
        }),
        success: () => {
          const {tableData, nextPageToken} = action.payload;
          const formIds = tableData.map(form => form.formId);
          return {
            ...state,
            tableData: formIds,
            nextPageToken,
            fetching: false,
          };
        },
        failure: () => handleFailure(state, action),
      });
    case quickFilterTypes.ALL_FILTER_SUBSEQUENT_LOAD:
    case quickFilterTypes.ASSIGNED_TO_ME_FILTER_SUBSEQUENT_LOAD:
    case quickFilterTypes.DRAFTS_FILTER_SUBSEQUENT_LOAD:
    case quickFilterTypes.SHARED_FILTER_SUBSEQUENT_LOAD:
    case quickFilterTypes.SUBMITTED_FILTER_SUBSEQUENT_LOAD:
    case quickFilterTypes.SIGNED_BY_ME_FILTER_SUBSEQUENT_LOAD:
      return api(action, state, {
        pending: () => handleSubsequentPending(state),
        success: () => {
          const {tableData, nextPageToken} = action.payload;
          const formIds = state.tableData.concat(tableData.map(form => form.formId));
          return {
            ...state,
            tableData: formIds,
            nextPageToken,
            fetching: false,
            fetchingMoreResults: false,
          };
        },
        failure: () => handleFailure(state, action),
      });
    case types.NEW_TABLE_SEARCH_API:
      return api(action, state, {
        pending: () => ({
          ...initialState,
          fetching: true,
        }),
        success: () => {
          if (action.error) {
            return {
              ...initialState,
              fetching: false,
              failure: true,
              error: action.error,
            };
          }
          const table = action.payload;
          return {
            ...state,
            fetching: false,
            failure: false,
            folderId: null,
            tableData: map(table.tableData, 'formId'),
            isSearchPage: true,
          };
        },
        failure: () => ({
          ...initialState,
          error: action.error,
          fetching: false,
          failure: true,
        }),
      });
    case types.UPDATE_TABLE_SEARCH_API:
      return api(action, state, {
        pending: () => handleSubsequentPending(state),
        success: () => {
          if (action.error) {
            return {
              ...state,
              fetching: false,
              failure: true,
              error: action.error,
              fetchingMoreResults: false,
            };
          }
          const payloadTableData = map(action.payload.tableData, 'formId');
          // @ts-ignore
          const newTableData = state.tableData.concat(payloadTableData);
          return {
            ...state,
            tableData: newTableData,
            failure: false,
            fetching: false,
            fetchingMoreResults: false,
            isSearchPage: true,
          };
        },
        failure: () => handleFailure(state, action),
      });
    case DELETE_FORM:
      return api(action, state, {
        success: () => {
          const {formId} = action.payload;
          const {tableData} = state;
          return {
            ...state,
            tableData: removeFormRow(tableData, formId),
          };
        },
        failure: () => {
          const snackbarMessage = ~[401, 403].indexOf(action.payload.error.status)
            ? 'You don\'t have permission to delete this form'
            : 'Form deletion failed';
          return {
            ...state,
            isSnackbarOpen: true,
            snackbarMessage,
          };
        },
      });
    case ARCHIVE_FORM_API:
      return api(action, state, {
        pending: () => ({
          ...state,
          archivingSelectedIndex: action.payload.selectedRowIndex,
        }),
        success: () => {
          const {formId} = action.payload.selectedRowData;
          const {isUnarchiving} = action.payload;
          return {
            ...state,
            tableData: removeFormRow(state.tableData, formId),
            archivingSelectedIndex: null,
            isSnackbarOpen: true,
            snackbarMessage: isUnarchiving ? 'Restored successfully' : 'Archived successfully',
          };
        },
      });
    case types.RESET_TABLE_SNACKBAR:
      return {
        ...state,
        isSnackbarOpen: false,
        snackbarMessage: '',
      };
    case FORM_INTO_FOLDER:
      return api(action, state, {
        success: () => ({
          ...state,
          tableData: state.tableData.filter((element, index) => index !== action.payload.requestedFormIndex),
          isSnackbarOpen: true,
          snackbarMessage: 'Form moved successfully',
        }),
        failure: () => ({
          ...state,
          isSnackbarOpen: true,
          snackbarMessage: 'Form move failed. Please try again.',
        }),
      });
    case DELETE_FOLDER:
      return api(action, state, {
        success: () => ({
          ...state,
          isSnackbarOpen: true,
          snackbarMessage: 'Folder deleted successfully',
        }),
        failure: () => ({
          ...state,
          isSnackbarOpen: true,
          snackbarMessage: 'Folder deletion failed. Please try again.',
        }),
      });
    case UPSERT_FOLDER:
      return api(action, state, {
        success: () => {
          const {isCreatingFolder} = action.payload;
          return {
            ...state,
            isSnackbarOpen: true,
            snackbarMessage: `Folder ${isCreatingFolder ? 'created' : 'moved'} successfully`,
          };
        },
        failure: () => {
          const {isCreatingFolder} = action.payload;
          return {
            ...state,
            isSnackbarOpen: true,
            snackbarMessage: `Folder ${isCreatingFolder ? 'creation' : 'move'} failed. Please try again.`,
          };
        },
      });
    case quickFilterTypes.CLEAR_QUICK_FILTER:
      return {
        ...state,
        tableData: [],
        nextPageToken: '',
      };
    default:
      return state;
  }
}

export const getIsSearchPage = (state: State): boolean => state.isSearchPage;
