import React from 'react';
import styles from './SubmissionTable.css';
import closedLock from 'icons/icon83.svg';
import openLock from 'icons/icon33.svg';


class EncryptedCell extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isFocusedOrHover: false,
    };
  }

  handleEnter = () => this.setState({ isFocusedOrHover: true });
  handleLeave = () => this.setState({ isFocusedOrHover: false });

  render() {
    const src = this.state.isFocusedOrHover ? openLock : closedLock;
    return (
      <button
        className={styles.decryptButton}
        onBlur={this.handleLeave}
        onClick={this.props.onClick}
        onFocus={this.handleEnter}
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}>
        <img alt='' className={styles.iconLock} src={src} />
        Encrypted Field
      </button>
    );
  }
}

export default EncryptedCell;
