import React from 'react';
import {Link} from 'react-router';
import Button from '@material-ui/core/Button';
import withLogoFallback from './WithLogoFallback';
import styles from './styles.css';
// import type {Thunk} from 'types/shared';

// type Props = {
//   address: string,
//   buttonText?: string,
//   companyName: string,
//   email: string,
//   ensureLogoFallback: Thunk,
//   handleLogoOnLoad: Thunk,
//   labelText?: string,
//   linkHref?: string,
//   logo: string,
// };

const AuthSidebar = ({companyName, logo, email, address, buttonText, labelText, linkHref, ...callbacks}) => {
  const showButton = buttonText && labelText && linkHref;
  return (
    <div className={styles.sidebar}>
      <div className={styles.accountInfo}>
        {
          companyName && (
            <React.Fragment>
              <img
                onError={callbacks.ensureLogoFallback}
                onLoad={callbacks.handleLogoOnLoad}
                className={styles.logo}
                src={logo}
                alt=''/>
              <h3 className={styles.companyName}>{companyName}</h3>
            </React.Fragment>
          )
        }
        {email && <p className={styles.contactInfo}>{email}</p>}
        {address && <p className={styles.contactInfo}>{address.split(' | ').join(', ')}</p>}
      </div>
      {
        showButton && (
          <div className={styles.switchAuthForm}>
            <p>{labelText}</p>
            <Link to={linkHref} className={styles.formSwitchLink}>
              <Button fullWidth classes={{root: styles.sidebarButton, label: styles.sidebarButtonLabel}}>
                {buttonText || null}
              </Button>
            </Link>
          </div>
        )
      }
    </div>
  );
};

export default withLogoFallback(AuthSidebar);
