/* eslint no-shadow: 0 */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SubmissionTable from 'components/SubmissionTable';
import * as submissionsActions from 'actions/submissionsManager/submissionsActions';
import * as tableApi from 'actions/submissionsManager/tableApi';
import _ from 'lodash';
import Loader from 'components/Loader';
import SearchLoader from 'components/Loader/SearchLoader';
import TableLoader from 'components/Loader/TableLoader';
import CurrentFilterBar, { FILTER_BAR_HEIGHT } from 'containers/SubmissionsManager/CurrentFilterBar';
import { INFINITE_SCROLL_TRIGGER } from 'constants/tableConsts';
import {
  SUBMISSIONS_BLANK_STATE,
  SUBMISSIONS_BLANK_STATE_FILTERED_BY_STAGE,
} from 'constants/applicationCopy';
import { replace } from 'react-router-redux';
import * as search from 'utils/search';
import {
  getLoadedSubmissionCount,
  getSelectedStage,
  getSortedFilteredColumns,
  getSubmissionSearchBarInput,
  getSubmissionTable,
  getTotalSubmissionsForFilterCount,
  isCurrentFilterBarRendered,
} from '../../reducers';
import BulkActionWrapper from 'containers/SubmissionsManager/Modals/BulkActionWrapper';
import FiltersModal from './Modals/Filters';
import { getFormFieldsForEmail } from 'actions/automatedProcessBuilder';


class SubmissionsList extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.disableOpenSidebarFromHash = false;
    this.state = {};
  }

  componentDidMount() {
    const { location } = this.props;

    this.initialLoad();

    if (location && !location.hash) {
      this.disableOpenSidebarFromHash = true;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { table, totalSubmissions, loadedSubmissions, searchbarInput, selectedStage } = nextProps;
    const { submissionsActions, location, params, isArchiveView } = this.props;
    if (!this.disableOpenSidebarFromHash && location && location.hash && table.records.length > 0) {
      const subIdFromHash = location.hash.replace('#', '');
      const submissionIndex = table.records.findIndex(record => record.submissionId === subIdFromHash);
      if (~submissionIndex) {
        this.handleRowClick(null, submissionIndex, table.records[submissionIndex]);
        /*
        * Intentionally over-scroll below because the default scrolled to element
        * is at the bottom and overscroll bings it up to a better line of sight for user
        * I don't need to protect index going out of bound since the table will auto
        * scroll to the last element when it does
        */
        submissionsActions.scrollToRow(submissionIndex + 5);
        setTimeout(() => {
          submissionsActions.resetScrollToRow();
        }, 0);
        this.disableOpenSidebarFromHash = true;

        // TODO(HSIN): when the selected submission comes in a later request amid infinite
        // scrolling, it will not show up on initial page landing because data is absent
        // No need for PT ticket, I am solving this RN in the next revision
      }
    }
    // If a user deletes all but a handful items, the table needs to load more automatically
    // because otherwise the items are too few to scroll, and hence not triggering any loading action
    // the magic number 25 gets the job done given a typical viewport height (hence table height)
    if (table.records.length < 25 &&
      (totalSubmissions - loadedSubmissions) > 0 &&
      loadedSubmissions !== 0 &&
      !search.parsedToString(searchbarInput) &&
      !selectedStage) {
      this.props.tableApi.requestSubsequentSubmissionLoad(params.formID, !!isArchiveView);
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      features,
      table,
      selected,
      width,
      height,
      isAllSelected,
      isTableSortable,
      selectedRowIndex,
      fetching,
      archivingType,
      isSearching,
      selectedStage,
    } = this.props;

    const condition = (
      !_.isEqual(table, nextProps.table) ||
      !_.isEqual(selected, nextProps.selected) ||
      isAllSelected !== nextProps.isAllSelected ||
      isTableSortable !== nextProps.isTableSortable ||
      width !== nextProps.width ||
      height !== nextProps.height ||
      isSearching !== nextProps.isSearching ||
      selectedRowIndex !== nextProps.selectedRowIndex ||
      archivingType !== nextProps.archivingType ||
      !_.isEqual(fetching, nextProps.fetching) ||
      !_.isEqual(features, nextProps.features) ||
      !_.isEqual(selectedStage, nextProps.selectedStage)
    );
    return condition;
  }

  componentWillUnmount(): void {
    this.props.tableApi.resetFiltersState();
  }

  // placed here to please eslint...
  disableOpenSidebarFromHash;

  initialLoad = () => {
    const {
      params,
      tableApi,
      isArchiveView,
    } = this.props;

    tableApi.requestInitialSubmissionLoad(params && params.formID, !!isArchiveView);
    if (this.shouldLoadFormDetails()) {
      tableApi.formDetails(params && params.formID);
    }
    tableApi.loadSubmissionsStats(params && params.formID);

    this.props.getFormFieldsForEmail(params && params.formID);
  }

  shouldLoadFormDetails() {
    const {
      params,
      formDetails,
    } = this.props;
    // The submissions.json endpoint now returns some basic form metadata. modifiedTime is one of the attributes
    // it doesn't return, so use that as an indicator to see if we need to load the rest of it.
    if (!formDetails || !formDetails.modifiedTime || formDetails.id !== params.formID) {
      return true;
    }
    return false;
  }

  rowClassNameGetter = index => {
    const { selectedRowIndex, archivingType, archivingCheckedIndex, archivingSelectedIndex } = this.props;
    if (_.isNumber(selectedRowIndex) && _.isEqual(selectedRowIndex, index)) {
      return 'selectedRow';
    }
    if (archivingType) {
      if (archivingType === 'checkbox') {
        if (archivingCheckedIndex && ~archivingCheckedIndex.indexOf(index)) {
          return 'archiving';
        }
      } else if (archivingType === 'select') {
        if (archivingSelectedIndex === index) {
          return 'archiving';
        }
      }
    }
    return '';
  };


  updateRecords() {
    const {
      tableApi,
      isArchiveView,
    } = this.props;

    tableApi.requestSubsequentSubmissionLoad(this.props.params.formID, !!isArchiveView);
  }

  onScrollY = scrollY => {
    this.setState({ scrollY });
    this.onScrollUpdate(this.state.maxScrollY, scrollY);
  }

  onContentHeightChange = contentHeight => {
    const maxScrollY = contentHeight - this.props.height;
    this.setState({ maxScrollY });
    this.onScrollUpdate(maxScrollY, this.state.scrollY);
  }

  onScrollUpdate = (maxScrollY, scrollY) => {
    const {
      totalSubmissions,
      loadedSubmissions,
      fetching,
    } = this.props;
    if (maxScrollY != null && scrollY != null && !fetching) {
      const pixelToBottom = maxScrollY - scrollY;
      if (pixelToBottom < INFINITE_SCROLL_TRIGGER && (totalSubmissions - loadedSubmissions) > 0) {
        this.updateRecords();
      }
    }
  };

  onCheck = (row, index, checked) => {
    this.props.tableApi.checkTableRow(row, checked, index);
  };

  handleRowClick = (event, index, rowData) => {
    const { submissionsActions, tableApi, sidebarOpen, selectedRowIndex, router, location, params } = this.props;
    submissionsActions.selectRow(index);
    if (selectedRowIndex !== index) {
      tableApi.recordDetails(params && params.formID, rowData.submissionId);
    }
    setTimeout(() => {
      const path = location ? location.pathname : '';
      if (sidebarOpen && selectedRowIndex === index) {
        router.replace(`${path}#`);
      } else {
        router.replace(`${path}#${rowData.submissionId}`);
      }
    }, 0);
  };

  render() {
    const {
      fetching,
      fetchingMoreResults,
      headerLabel,
      height,
      isAllSelected,
      isAllVisible,
      isFilterBarRendered,
      isSearching,
      isTableSortable,
      mounted,
      openDecryptModal,
      params,
      scrollToRow,
      selected,
      selectedStage,
      showSortingArrowIndex,
      sortedFilteredColumns,
      sortingTargetIndex,
      submissionsActions,
      table,
      tableApi,
      tableSortTop,
      width,
    } = this.props;

    /**
     * If the table is mounted AND its fetching more submissions
     * OR its not fetching the initial submissions
     */
    if (mounted && (!fetching || fetchingMoreResults)) {
      const emptyStateMessage = selectedStage
        ? SUBMISSIONS_BLANK_STATE_FILTERED_BY_STAGE
        : SUBMISSIONS_BLANK_STATE;
      return (
        <div style={{ width: '100%' }}>
          <BulkActionWrapper submissionIds={selected.map(s => s.submissionId)} includeAllSubmissions={isAllSelected}/>
          <FiltersModal />
          {
            isSearching &&
            <SearchLoader />
          }
          <CurrentFilterBar/>
          <SubmissionTable
            formId={params && params.formID}
            handleDecrypt={openDecryptModal}
            headerLabel={headerLabel}
            emptyStateMessage={emptyStateMessage}
            height={isFilterBarRendered ? height - FILTER_BAR_HEIGHT : height}
            isAllSelected={isAllSelected}
            isAllVisible={isAllVisible}
            isTableSortable={isTableSortable}
            makeSortable={tableApi.makeSortable}
            onCheck={this.onCheck}
            onContentHeightChange={this.onContentHeightChange}
            onRowClick={this.handleRowClick}
            onScrollY={_.debounce(this.onScrollY, 200)}
            rowClassNameGetter={this.rowClassNameGetter}
            scrollToRow={scrollToRow}
            selectAllOnCheck={tableApi.selectAll}
            selected={selected}
            showSortingArrowIndex={showSortingArrowIndex}
            sortedFilteredColumns={sortedFilteredColumns}
            sortTableColumns={tableApi.sortTableColumns}
            sortingTargetIndex={sortingTargetIndex}
            table={table}
            tableSortTop={tableSortTop}
            trackDownloadFile={submissionsActions.trackDownloadFile}
            width={width}
          />
          {fetchingMoreResults && <TableLoader />}
        </div>
      );
    }
    return (
      <div style={{ width: '100%' }}>
        <Loader top />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  router: bindActionCreators({ replace }, dispatch),
  submissionsActions: bindActionCreators(submissionsActions, dispatch),
  tableApi: bindActionCreators(tableApi, dispatch),
  getFormFieldsForEmail: bindActionCreators(getFormFieldsForEmail, dispatch),
});

const mapStateToProps = state => {
  const { features, submissions, submissionTable } = state;
  const selectedStage = getSelectedStage(state);
  const searchbarInput = getSubmissionSearchBarInput(state);

  return {
    archivingCheckedIndex: submissionTable.archivingCheckedIndex,
    archivingSelectedIndex: submissionTable.archivingSelectedIndex,
    archivingType: submissionTable.archivingType,
    failure: submissionTable.failure,
    features,
    fetching: submissionTable.fetching,
    fetchingMoreResults: submissionTable.fetchingMoreResults,
    formDetails: submissionTable.formDetails,
    headerLabel: submissionTable.headerLabel,
    isAllSelected: submissionTable.isAllSelected,
    isAllVisible: !search.parsedToString(searchbarInput) && !location.pathname.includes('filteredSubmissions'),
    isFilterBarRendered: isCurrentFilterBarRendered(state),
    isSearching: submissionTable.isSearching,
    isTableSortable: submissionTable.isTableSortable,
    loadedSubmissions: getLoadedSubmissionCount(state),
    scrollToRow: submissions.scrollToRow,
    searchbarInput,
    selected: submissionTable.selectedRecords,
    selectedRowIndex: submissions.selectedRowIndex,
    selectedStage,
    showSortingArrowIndex: submissionTable.showSortingArrowIndex,
    sidebarOpen: submissions.sidebarOpen,
    sortedFilteredColumns: getSortedFilteredColumns(state),
    sortingTargetIndex: submissionTable.sortingTargetIndex,
    table: getSubmissionTable(state),
    tableSortTop: isCurrentFilterBarRendered(state) ? 54 : 0,
    totalSubmissions: getTotalSubmissionsForFilterCount(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionsList);
