import React from 'react';
import { Cell } from 'fixed-data-table-2';
import Checkbox from 'components/Checkbox';


export default class CheckboxCell extends React.Component<any, any> {
  _onCheck = (index, checked) => {
    const { records, selected, onCheck } = this.props;
    if (selected) {
      onCheck(records[index], index, checked);
    } else {
      onCheck();
    }
  }

  _onClick = e => {
    e.stopPropagation();
  }

  render() {
    const { records, rowIndex, disabled, selected, height, width, columnKey } = this.props;
    let checked = !!this.props.checked;
    const recordInSelected = selected ?
      selected.find(record => record.submissionId === records[rowIndex].submissionId) : null;
    if (selected && selected.length > 0) {
      checked = !!(!disabled && recordInSelected);
    }
    return (
      <Cell
        height={height}
        width={width}
        columnKey={columnKey}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={this._onClick}>
        <Checkbox
          style={{ paddingLeft: 8 }}
          disabled={disabled}
          checked={checked}
          onCheck={((e, ck) => { this._onCheck(rowIndex, ck); }) as any} />
      </Cell>
    );
  }
}
