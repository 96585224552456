// This should not be used on proper nouns, since it doesn't
// handle cases like 'McCarthy'
export function capitalizeFirstLetter(input: string) {
  if (!input) {
    return '';
  }
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

export function joinClassNames(...classNames: (string | null | void | false)[]) {
  return classNames.filter(Boolean).join(' ');
}

export const formatPaymentAmount = (value: number) => `$${value.toFixed(2)}`;

export const generateUniqueString = (len: number): string => {
  let result: string = '';
  const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength: number = characters.length;
  let counter: number = 0;
  while (counter < len) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
