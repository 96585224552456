import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import { splitReducer } from '@splitsoftware/splitio-redux';
import {createSelector} from 'reselect';
import auth, * as authSelectors from './auth';
import coregistration from './coregistration/coregistration';
import features, * as featureSelectors from './features';
import submissions, * as submissionsSelectors from './submissionsManager/submissions';
import submissionTable, * as submissionTableSelectors from './submissionsManager/submissionTable';
import lobby from './lobby/lobby';
import folderNavigation, * as folderNavigationSelectors from './lobby/folderNavigation';
import lobbyTable, * as lobbyTableSelectors from './lobby/lobbyTable';
import permissions from './permissions';
import rules, * as ruleSelectors from './rules';
import users, * as userSelectors from './users';
import tags, * as tagSelectors from './tags';
import stages, * as stageSelectors from './stages';
import forms, * as formSelectors from './forms';
import emailFields, * as emailFieldSelectors from './emailFields';
import signerFields, * as signerFieldSelectors from './signersFields';
import formFields, * as formFieldSelectors from './formFields';
import ui, * as uiSelectors from './ui';
import {getPathForUI} from 'utils/folderHelper';
import {getFilteredColumnsFromServerColumns, getTableColumnsWithLabels} from 'utils/submissionsManager/tableHelper';
import {quickFiltersEnum} from 'constants/quickFilters';
import {recipientTypeEnum} from 'constants/triggers';
import {getFilteredSubmissionManagerRoute} from 'utils/quickFilters';
import {isInVirtualFolder} from 'utils/virtualFolders';
import { Path } from 'utils/vfs';
import { QUICK_FILTER_COLUMNS, LOBBY_SEARCH_COLUMNS, LOBBY_COLUMNS } from 'constants/formColumns';
import navBarSearch from './navBarSearch';

const rootReducer = combineReducers({
  routing: routerReducer,
  splitio: splitReducer,
  auth,
  navBarSearch,
  coregistration,
  emailFields,
  signerFields,
  formFields,
  features,
  forms,
  permissions,
  rules,
  submissions,
  submissionTable,
  lobby,
  lobbyTable,
  folderNavigation,
  users,
  tags,
  stages,
  ui,
});

export default rootReducer;

/* SELECTORS */

/* AUTH */
export const getUserCapabilities = state => authSelectors.getUserCapabilities(state.auth);

export const getUserCapabilitiesLoadingState = state =>
  authSelectors.getUserCapabilitiesLoadingState(state.auth);

export const getUserRole = state => authSelectors.getUserRole(state.auth);

export const getAuthenticationState = state => authSelectors.getAuthenticationState(state.auth);

export const getLoggedInUserId = state => authSelectors.getLoggedInUserId(state.auth);

export const getSubdomain = state => authSelectors.getSubdomain(state.auth);

export const getOrganizationBrand = state => authSelectors.getOrganizationBrand(state.auth);

/* USERS */

export const getUserMap = state => userSelectors.getUserMap(state.users);

/* EMAIL FIELDS */

export const getEmailFieldMap = state =>
  emailFieldSelectors.getEmailFieldsState(state.emailFields);

/* SIGNER FIELDS */

export const getSignerFieldMap = state =>
  signerFieldSelectors.getSignerFieldsState(state.signerFields);

/* FORM FIELDS */
export const getFormFieldMap = state =>
  formFieldSelectors.getFormFieldsState(state.formFields);

export const getFormFieldsForForm = (state: any, formId: string) =>
  formFieldSelectors.getFormFieldsForForm(state.formFields, formId);

/* FORMS */

export const getFormPublicInfoById = (state, formId) =>
  formSelectors.getFormPublicInfoById(state.forms, formId);

export const getFormsForLobbyById = (
  state,
  formIds
) => formSelectors.getFormsForLobbyById(state.forms, formIds);

export const checkFormCapability = (state, formId, capability) => {
  // This is a hack for when this is called from ng-embed, which has a different state shape
  // TODO: need a better generalized solution for this kind of issue
  if (!state.forms) return false;
  return formSelectors.checkFormCapability(state.forms, formId, capability);
};

export const getHasEnteredCorrectFormPassword = (state): boolean =>
  formSelectors.getHasEnteredCorrectFormPassword(state.forms);

export const getIsCheckingFormPassword = (state): boolean =>
  formSelectors.getIsCheckingFormPassword(state.forms);

export const getCheckFormPasswordError = (state): string | null =>
  formSelectors.getCheckFormPasswordError(state.forms);

export const getIsThankYouPageShareModalOpen = (state): boolean =>
  formSelectors.getIsThankYouPageShareModalOpen(state.forms);

export const getIsShareSubmissionLoading = (state): boolean =>
  formSelectors.getIsShareSubmissionLoading(state.forms);

export const getShareSubmissionError = (state): string | null =>
  formSelectors.getShareSubmissionError(state.forms);

export const getThankYouPagePdfUrl = (state): string | null =>
  formSelectors.getThankYouPagePdfUrl(state.forms);

/* LOBBY */
export const getCurrentFolderId = (state: any): string =>
  folderNavigationSelectors.getCurrentFolderId(state.folderNavigation);

export const getCurrentFolderPath = (state: any): Path =>
  folderNavigationSelectors.getCurrentFolderPath(state.folderNavigation);

export const getFormSearchBarInput = state => state.navBarSearch.searchBarInput;

/* SUBMISSION TABLE */

export const getSubmissionManagerFormId = state =>
  submissionTableSelectors.getSubmissionManagerFormId(state.submissionTable);

export const getSubmissionManagerFormDetails = state =>
  submissionTableSelectors.getSubmissionManagerFormDetails(state.submissionTable);

export const getTotalSubmissionsCount = state =>
  submissionTableSelectors.getTotalSubmissionsCount(state.submissionTable);

export const getLoadedSubmissionCount = state =>
  submissionTableSelectors.getLoadedSubmissionCount(state.submissionTable);

export const getTotalSubmissionsForFilterCount = state =>
  submissionTableSelectors.getTotalSubmissionsForFilterCount(state.submissionTable);

export const getSubmissionTable = state =>
  submissionTableSelectors.getSubmissionTable(state.submissionTable);

export const getSortingFromServer = state =>
  submissionTableSelectors.getSortingFromServer(state.submissionTable);

export const getSubmissionTableLabels = state =>
  submissionTableSelectors.getSubmissionTableLabels(state.submissionTable);

export const getHasStatusError = state =>
  submissionTableSelectors.getHasStatusError(state.submissionTable);

export const checkSubmissionsCapability = (state, capability) =>
  submissionTableSelectors.checkSubmissionsCapability(state.submissionTable, capability);

export const getIsArchivedView = state => submissionTableSelectors.getIsArchivedView(state.submissionTable);

export const getFiltersState = state =>
  submissionTableSelectors.getFiltersState(state.submissionTable);

export const getSubmissionFilters = state =>
  submissionTableSelectors.getSubmissionFilters(state.submissionTable);

export const getAppliedSubmissionFilters = state =>
  submissionTableSelectors.getAppliedSubmissionFilters(state.submissionTable);

export const getIsFiltersStateValid = state =>
  submissionTableSelectors.getIsFiltersStateValid(state.submissionTable);

export const getIsSubmissionFilterApplied = state =>
  submissionTableSelectors.getIsSubmissionFilterApplied(state.submissionTable);

export const getSelectedStageName = state =>
  submissionTableSelectors.getSelectedStageName(state.submissionTable);

export const getSortingState = state =>
  submissionTableSelectors.getSortingState(state.submissionTable);

export const getSubmissionPaginationToken = state =>
  submissionTableSelectors.getSubmissionPaginationToken(state.submissionTable);

export const getIsSearchPage = state =>
  lobbyTableSelectors.getIsSearchPage(state.lobbyTable);

/* SUBMISSIONS */
export const getSubmissionSearchBarInput = state => state.navBarSearch.searchBarInput;

export const getSelectedSubmissionRowIndex = state =>
  submissionsSelectors.getSelectedSubmissionRowIndex(state.submissions);

export const getIsProcessPaymentPending = state =>
  submissionsSelectors.getIsPaymentProcessPending(state.submissions);

/* FEATURES */

export const getFeatureState = state => featureSelectors.getFeatureState(state.features);

/* RULES */
export const getRuleState = state => ruleSelectors.getRuleState(state.rules);

/* STAGES */

export const getStageState = state => stageSelectors.getStageState(state.stages);

export const getStagesList = (state, formId) => stageSelectors.getStagesList(state.stages, formId);

export const getStage = (state, formId, stageName) =>
  stageSelectors.getStage(state.stages, formId, stageName);


/* TAGS */

export const getTags = state => tagSelectors.getTags(state.tags);

export const getHaveTagsLoaded = state => tagSelectors.getHaveTagsLoaded(state.tags);


/* UI */

export const getCurrentPredicateInstruction = state =>
  uiSelectors.getCurrentPredicateInstruction(state.ui);

export const getPredicateFetchingState = state =>
  uiSelectors.getPredicateFetchingState(state.ui);

export const getGatedLiveViewUrl = state =>
  uiSelectors.getGatedLiveViewUrl(state.ui);

export const getAuthSubmissionState = state => uiSelectors.getAuthSubmissionState(state.ui);

export const getSelectedQuickFilter = state =>
  uiSelectors.getSelectedQuickFilter(state.ui);

export const getSelectedSubmissionQuickFilter = state =>
  uiSelectors.getSelectedSubmissionQuickFilter(state.ui);

export const getSignedUrlError = state => uiSelectors.getSignedUrlError(state.ui);

export const getFetchingAssetState = state => uiSelectors.getFetchingAssetState(state.ui);

export const getSignedUrl = state => uiSelectors.getSignedUrl(state.ui);

export const isApbVisible = state => uiSelectors.isApbVisible(state.ui);

export const getVisibleEmailRuleId = state => uiSelectors.getVisibleEmailRuleId(state.ui);

export const areApbRulesLoading = state => uiSelectors.isApbLoading(state.ui);

export const getCurrentFormEntities = state => {
  const formId = getSubmissionManagerFormId(state);
  return {
    emailFields: state.emailFields[formId],
    signerFields: state.signerFields[formId],
    formFields: state.formFields[formId],
    users: state.users,
  };
};

/* COMPOSED SELECTORS */

export const getSelectedStage = createSelector(
  [getStageState, getSubmissionManagerFormId, getSelectedStageName],
  stageSelectors.getStage
);

// TODO: NEXT-37 - the isInVirtualFolder check can be removed once the
// assigned to me one off is replaced by the assigned to me quick filter
export const isQuickFilterSelected = createSelector(
  [
    getSelectedQuickFilter,
    getCurrentFolderId,
  ],
  (selectedFilter, folderId) => !!selectedFilter || isInVirtualFolder(folderId),
);

export const getSubmissionManagerRoute = createSelector(
  [getSelectedQuickFilter],
  selectedFilter => getFilteredSubmissionManagerRoute(selectedFilter)
);

export const getPath = createSelector(
  [
    getSelectedQuickFilter,
    getCurrentFolderPath,
  ],
  getPathForUI
);

export const getSelectedSubmissionRow = createSelector(
  [
    getSubmissionTable,
    getSelectedSubmissionRowIndex,
  ],
  ({records}, index) => {
    if (typeof index === 'number') {
      return records[index];
    }
    return null;
  }
);

export const getSortedFilteredColumns = createSelector(
  [
    getSortingFromServer,
    getSubmissionTable,
    getFeatureState,
    getUserCapabilities,
  ],
  getFilteredColumnsFromServerColumns,
);

export const getSortedFilteredColumnsWithLabels = createSelector(
  [
    getSortedFilteredColumns,
    getSubmissionTableLabels,
  ],
  getTableColumnsWithLabels,
);

export const isCurrentFilterBarRendered = createSelector(
  [
    getFeatureState,
    getSelectedQuickFilter,
  ],
  ({STAGES_IN_SUBMISSION_MANAGER_SUPPORT}, selectedQuickFilter) => {
    const hasQuickFilterToShow = !!selectedQuickFilter && selectedQuickFilter !== quickFiltersEnum.ALL;
    return STAGES_IN_SUBMISSION_MANAGER_SUPPORT || hasQuickFilterToShow;
  },
);

export const getRulesForCurrentForm = createSelector(
  [
    getRuleState,
    getSubmissionManagerFormId,
  ],
  ruleSelectors.getRulesForForm
);

export const getRuleForEmailEditor = createSelector(
  [
    getRulesForCurrentForm,
    getVisibleEmailRuleId,
  ],
  (rulesForForm, visibleRuleId) => rulesForForm.find(rule => rule.ruleId === visibleRuleId),
);

export const isApbLoading = createSelector(
  [
    areApbRulesLoading,
    getFeatureState,
    getStageState,
    getHaveTagsLoaded,
    getUserMap,
    getEmailFieldMap,
    getSignerFieldMap,
    getFormFieldMap,
    getSubmissionManagerFormId,
  ],
  (
    isApbRulesLoading,
    {
      ASSIGNMENTS_IN_SUBMISSION_MANAGER_SUPPORT,
      TAGS_IN_SUBMISSION_MANAGER_SUPPORT,
      STAGES_IN_SUBMISSION_MANAGER_SUPPORT,
    },
    stageMap,
    haveTagsLoaded,
    userMap,
    emailFieldMap,
    signerFieldMap,
    formFieldMap,
    currentFormId
  ) => {
    if (isApbRulesLoading) return true;
    if (!emailFieldMap[currentFormId]) return true;
    if (!signerFieldMap[currentFormId]) return true;
    if (!formFieldMap[currentFormId]) return true;
    if (ASSIGNMENTS_IN_SUBMISSION_MANAGER_SUPPORT && Object.keys(userMap).length === 0) return true;
    if (TAGS_IN_SUBMISSION_MANAGER_SUPPORT && !haveTagsLoaded) return true;
    if (STAGES_IN_SUBMISSION_MANAGER_SUPPORT && !stageMap[currentFormId]) return true;
    return false;
  },
);

export const getAvailableRecipientTypes = (
  {emailFields: efields, signerFields: sfields},
  formId,
) => {
  const types = [];
  if (sfields[formId] && sfields[formId].length) {
    // @ts-ignore
    types.push({type: recipientTypeEnum.SIGNER, label: 'Form signers'});
  }
  types.push(
    // @ts-ignore
    {type: recipientTypeEnum.SYSTEM_USER, label: 'System users'},
    {type: recipientTypeEnum.SUBMITTER, label: 'Submitter'},
    {type: recipientTypeEnum.ASSIGNED_USER, label: 'Assigned users'},
  );
  if (efields[formId] && efields[formId].length) {
    // @ts-ignore
    types.push({type: recipientTypeEnum.EMAIL_FIELD, label: 'Email fields'});
  }
  // @ts-ignore
  types.push({type: recipientTypeEnum.STATIC, label: 'Custom emails'});
  return types;
};

export const getVisibleFormColumns = createSelector(
  [getSelectedQuickFilter, getIsSearchPage],
  (selectedQuickFilter: string, isSearchPage: boolean): string[] => {
    if (selectedQuickFilter) return QUICK_FILTER_COLUMNS;
    else if (isSearchPage) return LOBBY_SEARCH_COLUMNS;
    return LOBBY_COLUMNS;
  }
);
