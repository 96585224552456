import {
  SET_IS_PAYMENT_ACTIVE,
  GET_PROVIDER_OPTIONS,
  SAVE_GOVOS_PAYMENT_SETTINGS,
  GET_GOVOS_PAYMENT_SETTINGS,
} from 'constants/types/govOsPayTypes';
import api from 'reducers/api';
import { GovOSPaymentConfigurationState as State, PaymentProvider } from 'types/govOsPay/govOsPaymentConfiguration';
import { GovOSPaymentConfig } from 'types/govOsPay/govOsPaymentSettings';

export const defaultPaymentConfig: GovOSPaymentConfig = {
  status: 'disabled',
  providerId: '',
  description: '',
  amountSource: '',
  amountSourceType: '',
  formId: '',
  paymentOptional: false,
  paymentsConfigId: '',
  ccEnabled: true,
  achEnabled: false,
  ccFeeStructure: {
    amount: 0,
    customFeeName: '',
    feeType: '',
    percentage: 0,
    state: 'disabled',
    isOffsetEnabled: false,
  },
  achFeeStructure: {
    amount: 0,
    customFeeName: '',
    feeType: '',
    percentage: 0,
    state: 'disabled',
    isOffsetEnabled: false,
  },
};

export const initialState: State = {
  isConfigLoading: false,
  isProviderListLoading: false,
  providers: [],
  paymentsConfig: defaultPaymentConfig,
};

export default function paymentConfiguration(state: State = initialState, _action) {
  const action = _action;
  switch (action.type) {
    case SET_IS_PAYMENT_ACTIVE:
      return { ...state, paymentsConfig: { ...state.paymentsConfig, status: action.status } };
    case GET_PROVIDER_OPTIONS:
      return api(action, state, {
        pending: () => ({ ...state, isProviderListLoading: true }),
        failure: () => ({ ...state, isProviderListLoading: false }),
        success: () => ({ ...state, isProviderListLoading: false, providers: action?.payload?.providers || [] }),
      });
    case SAVE_GOVOS_PAYMENT_SETTINGS:
      return api(action, state, {
        pending: () => ({ ...state, isConfigLoading: true }),
        failure: () => ({ ...state, isConfigLoading: false }),
        success: () => ({ ...state, isConfigLoading: false }),
      });
    case GET_GOVOS_PAYMENT_SETTINGS:
      return api(action, state, {
        pending: () => ({ ...state, isConfigLoading: true }),
        failure: () => ({ ...state, isConfigLoading: false }),
        success: () => ({ ...state, isConfigLoading: false, paymentsConfig: action.payload.config }),
      });
    default:
      return state;
  }
}

export const getPaymentConfiguration = (state: State): GovOSPaymentConfig => state.paymentsConfig;

export const getIsConfigReady = (state: State): boolean => !state.isConfigLoading;

export const getIsProviderListReady = (state: State): boolean => !state.isProviderListLoading;

export const getPaymentProviders = (state: State): PaymentProvider[] => state.providers;
