import React from 'react';
import Button from '@material-ui/core/Button';
import style from 'styles/Login.css';

class ActiveDirectoryButton extends React.Component<any, any> {
  handleButtonOnClick = () => {
    const { connection, onClick } = this.props;
    onClick(connection);
  }

  render() {
    const { connection } = this.props;

    return (
      <Button
        onClick={this.handleButtonOnClick}
        classes={{ root: style.activeDirectoryButton, label: style.activeDirectoryButtonLabel }}>
        {connection.label}
      </Button>
    );
  }
}

export default class ActiveDirectoryAccounts extends React.Component<any, any> {
  render() {
    const { connections, onConnectionChoice } = this.props;

    return (
      <div>
        <div className={style.activeDirectoryButtonList}>
          {connections.map(connection =>
            <ActiveDirectoryButton
              connection={connection}
              key={connection.name}
              onClick={onConnectionChoice} />)}
        </div>
      </div>
    );
  }
}
