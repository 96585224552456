import * as React from 'react';
import { connect } from 'react-redux';
import * as dateHelper from 'utils/dateHelper';
import ActivityRow from './ActivityRow';
import styles from './SidebarDetails.css';
import { getFullname } from 'utils/textHelper';
import { recipientsFrom } from 'utils/submissionsManager/signatures';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';


import injectFeatures from 'decorators/injectFeatures';

import DetailsIcon from '@material-ui/icons/List';
import OpenInNewTabIcon from '@material-ui/icons/OpenInNew';
import Link from '@material-ui/core/Link';
import { grmSubmission, deployment } from 'utils/routing';
import { SplitTreatments } from '@splitsoftware/splitio-react';


const DETAILS = 'Details';
const FULL_VIEW = 'Full View';

const TAB_ICONS = {
  [DETAILS]: DetailsIcon,
  [FULL_VIEW]: OpenInNewTabIcon,
};


const TabLabel = ({ name, url = '' }) => {
  const Icon = TAB_ICONS[name];

  return (
    <div className={styles.tabLabel}>
      <div className={styles.tabIcon}>
        <Icon fontSize='small' />
      </div>

      {url ? (
        <Link href={url} color='inherit'>
          {name}
        </Link>
      ) : (
        <div>{name}</div>
      )}
    </div>
  );
};

class SidebarDetails extends React.Component<any, any> {
  state = { selectedTab: DETAILS };
  createActivityFeed = recordDetails => {
    if (!recordDetails) {
      return [];
    }
    const { createdTs, signatureHistory } = recordDetails;
    const creation = {
      activityType: 'Submission Created',
      name: '',
      icon: 'note_add',
      date: dateHelper.getFormattedTime(createdTs || new Date(), dateHelper.TIME_FORMAT_LONG),
    };

    const activities = recipientsFrom(signatureHistory)
      .filter(sig => !!sig.datesigned)
      .map(sig => {
        const activityType = sig.status === 'declined' ? 'Declined by' : 'Signed by';
        return {
          activityType,
          date: dateHelper.getFormattedTimeFromUnix(sig.datesigned, dateHelper.TIME_FORMAT_LONG),
          icon: 'gesture',
          name: getFullname(sig),
        };
      });

    return [creation, ...activities].sort((activity1, activity2) => (activity1.date <= activity2.date ? 1 : -1));
  };

  handleTabChange = (e, selectedTab) => {
    this.setState({ selectedTab });
  };

  render() {
    const { recordDetails, maid, isArchiveView } = this.props;
    const activityFeed = this.createActivityFeed(recordDetails).map((feed, index) => (
      <ActivityRow key={index} icon={feed.icon} activityType={feed.activityType} name={feed.name} date={feed.date} />
    ));
    return (
      <div className={styles.container}>
        <SplitTreatments names={['ONE_NAV']} attributes={{ maid, deployment }}>
          {({ treatments: { ONE_NAV }, isReady }) => {
            const isOneNavEnable = isReady && ONE_NAV.treatment === 'on';

            return (
              <React.Fragment>
                {this.props.features.SUBMISSION_DETAILS_FULL_VIEW && (
                  <Tabs
                    value={this.state.selectedTab}
                    onChange={this.handleTabChange}
                    variant='fullWidth'
                    classes={{
                      indicator: styles.tabIndicator,
                      root: styles.tabsRoot,
                    }}
                  >
                    <Tab
                      disableRipple
                      value={DETAILS}
                      fullWidth
                      className={styles.tab}
                      classes={{ selected: styles.tabSelected }}
                      label={<TabLabel name={DETAILS} />}
                    />
                    {!isArchiveView && !recordDetails.isArchived && <Tab
                      disableRipple
                      value={FULL_VIEW}
                      fullWidth
                      className={styles.tab}
                      classes={{ selected: styles.tabSelected }}
                      label={
                        <TabLabel
                          name={FULL_VIEW}
                          url={isOneNavEnable ? grmSubmission(this.props.formId, this.props.submissionId) : undefined}
                        />
                      }
                    />}
                  </Tabs>
                )}

                {isOneNavEnable && this.props.children}

                {!isOneNavEnable && this.state.selectedTab === DETAILS && this.props.children}

                {!isOneNavEnable &&
                  this.props.features.SUBMISSION_DETAILS_FULL_VIEW &&
                this.state.selectedTab === FULL_VIEW && (
                  <div className={styles.activityContainer}>{activityFeed}</div>
                )}
              </React.Fragment>
            );
          }}
        </SplitTreatments>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { submissionTable: { isArchiveView } } = state;
  return {
    isArchiveView,
  };
};

export default connect(
  mapStateToProps,
)(injectFeatures(SidebarDetails));
