import {success} from './utils/filters';
import {requestInstruction} from 'actions/liveViewGating';
import {REQUEST_INSTRUCTION} from 'constants/types/liveViewGatingActionTypes';
import {LOGIN_SUCCESS} from 'constants/types/authActionTypes';
import {getCurrentPredicateInstruction} from '../reducers';
import {
  getPredicateTokenFromLocalStorage,
  setPredicateTokenInLocalStorage,
  getRouteForInstruction,
} from 'utils/liveViewGating';
import {replace} from 'react-router-redux';


export function liveViewGatingInstructionEpic(action$) {
  return action$
    .ofType(REQUEST_INSTRUCTION)
    .filter(success)
    .map(action => {
      setPredicateTokenInLocalStorage(action.payload.token);
      return replace(getRouteForInstruction(action.payload.nextCommand));
    });
}

export function nextInstructionEpic(action$, store) {
  return action$
    .ofType(LOGIN_SUCCESS)
    .filter(action => {
      const state = store.getState();
      return (action.type === LOGIN_SUCCESS || success(action)) && !!getCurrentPredicateInstruction(state);
    })
    .map(() => {
      const state = store.getState();
      const instruction = getCurrentPredicateInstruction(state) || '';
      const token = getPredicateTokenFromLocalStorage();
      return requestInstruction(token, instruction);
    });
}
