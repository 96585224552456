import {REGISTER_USER, LOGIN_REQUEST, LOGIN_FAILURE} from 'constants/types/authActionTypes';
import api from 'reducers/api';


const initialState = {
  processingSubmission: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        processingSubmission: true,
      };
    case LOGIN_FAILURE:
      return initialState;
    case REGISTER_USER:
      return api(action, state, {
        success: () => initialState,
        failure: () => initialState,
        pending: () => ({
          processingSubmission: true,
        }),
      });
    default:
      return state;
  }
};

export const getAuthSubmissionState = state => state.processingSubmission;
