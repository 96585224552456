import React, { useEffect, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { Context } from '@datadog/browser-core/cjs/tools/context';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { markBeginMount as markBeginMountAction } from 'actions/measurePerformance';


type PropsFromDispatch = {
  markBeginMount: typeof markBeginMountAction,
}

type Props = PropsFromDispatch & {
  children: React.ReactNode,
  name: string,
}


export const MeasurePerformance = (props: Props) => {
  const { children, name, markBeginMount } = props;
  const [mounted, setMounted] = useState<boolean>(false);

  if (!mounted) {
    window.performance.mark(`${name}MountStart`);
    markBeginMount(name);
  }

  useEffect(() => {
    setMounted(true);
    window.performance.mark(`${name}MountEnd`);
    window.performance.measure(`${name}Mount`, `${name}MountStart`, `${name}MountEnd`);

    const performanceArray: PerformanceEntryList = window.performance.getEntriesByType('measure');
    const measure: PerformanceEntry = performanceArray[performanceArray.length - 1];

    const info: Context = {};
    info[`${name}.load`] = measure.duration;

    datadogLogs.logger.log(`${name}.load`, info);
  }, []);

  return <>{children}</>;
};

const mapDispatchToProps = (dispatch): PropsFromDispatch => ({
  markBeginMount: bindActionCreators(markBeginMountAction, dispatch),
});

export default connect(null, mapDispatchToProps)(MeasurePerformance);
