import { safeObjectValues } from 'utils/flow';
import api from 'reducers/api';
import {
  SET_PAYMENT_CONFIGURATION,
  SET_CHOSEN_GATEWAY,
  SET_PAYMENT_TOKEN,
  GET_PAYMENT_CONFIGURATION,
  SET_PAYMENT_STATUS,
  SET_IS_PAYMENT_ACTIVE,
  RESTORE_CHOSEN_GATEWAY,
  DELETE_GATEWAY,
} from 'constants/paymentConfiguration';
import { CONNECT_PAYMENT_ACCOUNT } from 'constants/paymentConfigurationCredentials';
import { PaymentConfigurationState as State, PaymentGatewayData } from 'types/paymentConfiguration';
import { PaymentsConfig } from 'types/paymentSettings';

export const initialState = {
  config: {},
  chosenGateway: {
    api: '',
    cards: [],
    company: '',
    credentials: [],
    fields: [],
    homepage: '',
    identifier: null,
    methods: [],
    name: 'Select One',
    operations: [],
    provider: '',
  },
  isConfigLoading: false,
  isGatewaysLoading: false,
  paymentToken: '',
  hasAuthenticatingError: false,
  paymentsConfig: {
    status: '',
    description: '',
    gatewayName: '',
    gatewayType: '',
    amountSource: '',
    amountSourceType: '',
    authorizeOnly: false,
    isDebitAuthEnabled: false,
    currency: '',
    formId: '',
    isPaymentOptional: false,
    paymentsConfigId: '',
    token: '',
    recipientSource: '',
    isCreditCardEnable: true,
    isDebitCardEnable: false,
    isACHEnable: false,
    ccFeeStructure: {
      fees: [
        {
          amount: 0,
          customFeeName: '',
          feeType: '',
          percentage: 0,
          state: 'disabled',
          isOffsetEnabled: false,
        },
      ],
    },
    dcFeeStructure: {
      fees: [
        {
          amount: 0,
          customFeeName: '',
          feeType: '',
          percentage: 0,
          state: 'disabled',
          isOffsetEnabled: false,
        },
      ],
    },
    achFeeStructure: {
      fees: [
        {
          amount: 0,
          customFeeName: '',
          feeType: '',
          percentage: 0,
          state: 'disabled',
          isOffsetEnabled: false,
        },
      ],
    },
  },
};

export default function paymentConfiguration(state: State = initialState, _action) {
  const action = _action;
  switch (action.type) {
    case SET_PAYMENT_CONFIGURATION:
      return api(action, state, {
        success: () => {
          const newConfig = {};
          action.payload.config.forEach(gateway => {
            newConfig[gateway.gatewayType] = {
              ...gateway,
              identifier: gateway.gatewayType,
              name: gateway.gatewayName,
            };
          });
          return { ...state, config: newConfig, chosenGateway: initialState.chosenGateway, isGatewaysLoading: false };
        },
        pending: () => ({ ...state, chosenGateway: initialState.chosenGateway, isGatewaysLoading: true }),
        failure: () => ({ ...state, isGatewaysLoading: false }),
      });
    case SET_CHOSEN_GATEWAY:
      return { ...state, chosenGateway: action.gateway, hasAuthenticatingError: false };
    case RESTORE_CHOSEN_GATEWAY:
      return { ...state, chosenGateway: state.config[action.gatewayType] };

    case SET_PAYMENT_TOKEN:
      return { ...state, paymentToken: action.paymentToken };
    case SET_PAYMENT_STATUS:
      return { ...state, paymentsConfig: action.paymentsConfig };
    case CONNECT_PAYMENT_ACCOUNT:
      return api(action, state, {
        success: () => ({ ...state, paymentToken: action.payload.gatewayToken, hasAuthenticatingError: false }),
        pending: () => ({ ...state, hasAuthenticatingError: false }),
        failure: () => ({ ...state, hasAuthenticatingError: true }),
      });
    case GET_PAYMENT_CONFIGURATION:
      return api(action, state, {
        pending: () => ({ ...state, isConfigLoading: true }),
        success: () => ({
          ...state,
          isConfigLoading: false,
          paymentsConfig: action.payload,
          paymentToken: action.payload.token,
        }),
        failure: () => ({ ...state, isConfigLoading: false }),
      });
    case SET_IS_PAYMENT_ACTIVE:
      return { ...state, paymentsConfig: { ...state.paymentsConfig, status: action.status } };

    case DELETE_GATEWAY:
      return { ...state, chosenGateway: initialState.chosenGateway };

    default:
      return state;
  }
}

export const getPaymentGateways = (state: State): PaymentGatewayData[] => safeObjectValues(state.config);

export const getChosenGateway = (state: State): PaymentGatewayData => state.chosenGateway;

export const getPaymentToken = (state: State): string => state.paymentToken;

export const getIsPaymentConfigurationApproved = (state: State): boolean =>
  !!state.paymentsConfig.status || !!state.paymentToken;

export const getPaymentConfiguration = (state: State): PaymentsConfig => state.paymentsConfig;

export const getIsConfigReady = (state: State): boolean => !state.isConfigLoading;

export const getAuthenticatingError = (state: State): boolean => state.hasAuthenticatingError;
