import { useState, useEffect } from 'react';

interface CountdownResult {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const useCountdown = (endDate: string): CountdownResult => {
  const [countdown, setCountdown] = useState<CountdownResult>({
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const endDateParsed = new Date(endDate).getTime();
      const timeDifference = endDateParsed - new Date().getTime();

      if (timeDifference <= 0) {
        setCountdown({
          years: 0,
          months: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        clearInterval(interval);
        return;
      }

      const seconds = Math.floor((timeDifference / 1000) % 60);
      const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
      const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      const months = Math.floor(days / 30);
      let years = Math.floor(days / 365);
      if (years < 0) years = 0;

      const remainingDays = days - months * 30;
      const remainingMonths = months - Math.floor(days / 365) * 12;
      const remainingHours = Math.abs(hours - ((days * 24) % 24));
      const remainingMinutes = Math.abs(minutes - ((hours * 60) % 60));

      setCountdown({
        years,
        months: remainingMonths,
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes,
        seconds,
      });
    }, 1000);

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [endDate]);

  return countdown;
};

export default useCountdown;
