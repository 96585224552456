import React from 'react';
import { connect } from 'react-redux';
import Tooltip from 'components/Tooltip';
import documentBentCornerIcon from 'icons/icon60.svg';
import documentIncomplete from 'icons/icon149.svg';
import { SUBMISSION_PDF } from 'constants/trackDownloadTypes';
import LinkToNewTab from 'components/LinkToNewTab';
import styles from './SubmissionTable.css';
import features from '../../features'; // NEXT-338 - Remove this feature flag

const inlineStyles = {
  completeDocumentIcon: {
    verticalAlign: 'bottom',
    cursor: 'pointer',
  },
  incompleteDocumentIcon: {
    verticalAlign: 'bottom',
    cursor: 'not-allowed',
  },
};

interface Props {
  submissionRecord: any,
  trackDownloadFile: any,
  formId?: string,
  isArchiveView: boolean,
}

class SubmissionCellIcon extends React.Component<Props> {
  trackDownloadFile = event => {
    const { submissionRecord, trackDownloadFile } = this.props;
    const { submissionPDFUrl } = submissionRecord;

    if (submissionPDFUrl) {
      event.stopPropagation();
      trackDownloadFile(SUBMISSION_PDF, submissionPDFUrl);
    }
  }

  getDefaultFormId = () => {
    const frags = location.pathname.split('/');
    return frags[frags.length - 1];
  }

  render() {
    const { submissionRecord, formId = this.getDefaultFormId(), isArchiveView } = this.props;
    const { submissionId, submissionPDFUrl, isIncomplete } = submissionRecord;
    const incompleteAltText = 'This is an incomplete submission';
    const completeAltText = 'View submission';

    if (isIncomplete) {
      return (
        <span>
          <Tooltip className={styles.tooltip} title={incompleteAltText} placement='right'>
            <img
              alt={incompleteAltText}
              src={documentIncomplete}
              style={inlineStyles.incompleteDocumentIcon} />
          </Tooltip>
        </span>
      );
    }
    if (submissionPDFUrl) {
      if (isArchiveView || submissionRecord.isArchived) {
        return (
          <img alt={completeAltText} src={documentBentCornerIcon} style={inlineStyles.completeDocumentIcon} />
        );
      }

      return (
        <LinkToNewTab
          href={features.signedS3Urls.enabled ? `/ng/submissions/viewer/${formId}/${submissionId}` : submissionPDFUrl}
          onClick={this.trackDownloadFile}>
          <img alt={completeAltText} src={documentBentCornerIcon} style={inlineStyles.completeDocumentIcon} />
        </LinkToNewTab>);
    }
    return null;
  }
}

const mapStateToProps = state => {
  const { submissionTable: { isArchiveView } } = state;
  return {
    isArchiveView,
  };
};

export default connect(
  mapStateToProps,
)(SubmissionCellIcon);
