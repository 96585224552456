import React from 'react';
import EmailMultiSelector from './EmailMultiSelector';


const CustomEmailPicker = ({values, onChange}) => (
  <EmailMultiSelector
    values={values}
    onChange={onChange}
    addButtonCaption='Add email'
    removeCaption='Remove email'/>
);

export default CustomEmailPicker;
