import {
  CREATE_FOLDER_MODAL_VISIBILITY,
  LOADER_VISIBILITY,
  RESET_SCROLL_TO_ROW,
  SCROLL_TO_ROW,
  SELECT_ROW,
  SET_FORM_DETAILS,
  SHOW_LOBBY_WITH_FORM,
  TOOLBAR_MOBILE_MENU_VISIBILITY,
} from 'constants/types/lobby';
// import type {LobbyTableDataType} from 'types/lobby/tableTypes';
// import type {Parsed} from 'types/search';
// import type {
//   CreateFolderModalVisibleAction,
//   LoaderModalToggleAction,
//   ResetScrollToRowAction,
//   ScrollToRowAction,
//   SearchbarInputChangeAction,
//   SelectRowAction,
//   SetFormDetailsAction,
//   SetToolbarMobileMenuAction,
//   ShowLobbyWithFormAction,
// } from 'types/lobby';
export function loaderModalToggle(visibility: boolean, status: string, title: string, body: string) {
  return {
    type: LOADER_VISIBILITY,
    visibility,
    status,
    title,
    body,
  };
}

export function selectRow(selectedRowIndex: number, selectedRowData: any) {
  return {
    type: SELECT_ROW,
    selectedRowIndex,
    selectedRowData,
  };
}

export const showLobbyWithForm = (formId: string) => ({
  type: SHOW_LOBBY_WITH_FORM,
  formId,
});

export const scrollToRow = (rowIndex: number) => ({
  type: SCROLL_TO_ROW,
  rowIndex,
});

export const resetScrollToRow = () => ({
  type: RESET_SCROLL_TO_ROW,
});

export const createFolderModalVisible = (open: boolean) => ({
  type: CREATE_FOLDER_MODAL_VISIBILITY,
  open,
});

export const setFormDetails = (selectedRowData: any) => {
  const pdfId = selectedRowData.itemType === 'Doc' ? selectedRowData.formId : null;
  const formAuthorName = selectedRowData.formAuthorName ? selectedRowData.formAuthorName : '';

  return {
    type: SET_FORM_DETAILS,
    payload: {
      name: selectedRowData.formName,
      id: selectedRowData.formId,
      authorName: formAuthorName,
      createdTime: selectedRowData.createdTs,
      modifiedTime: selectedRowData.modifiedTs,
      alias: selectedRowData.alias,
      pdfId,
    },
  };
};

export const toolbarMobileMenuVisibility = (toolbarMobileMenuVisible: boolean) => ({
  type: TOOLBAR_MOBILE_MENU_VISIBILITY,
  toolbarMobileMenuVisible,
});
