export const PREFIX = 'ng';
export const ROOT = '/' + PREFIX;
export const LOGIN = ROOT + '/login';
export const LOBBY = ROOT + '/lobby';
export const SUBMISSIONS_MANAGER = ROOT + '/submissions';
export const SUBMISSIONS_MANAGER_ARCHIVED = ROOT + '/submissions/archived';
export const FILTERED_SUBMISSIONS_MANAGER = `${ROOT}/filteredSubmissions`;
export const AUTH0_CALLBACK = ROOT + '/callback';
export const SSO_FAILURE = ROOT + '/ssofailure';

export const LEGACY_LOGOUT = '/logout';
export const LEGACY_ACCOUNT_OVERVIEW = '/account_overview';
export const LEGACY_USERS = '/users';
export const LEGACY_API_KEYS = '/account/api_management';
export const LEGACY_COMPANY = '/company';
export const LEGACY_PROFILE = '/profile';
export const LEGACY_UNIVERSITY = '/university';

export const LEGACY_NEW_WEBFORM = '/forms/new';
export const LEGACY_BROWSE_GALLERY = '/forms/collections';

export const ACCESS_CODE = ROOT + '/accesscode';
