import React, {useState, useLayoutEffect, useMemo, useRef} from 'react';
import {connect} from 'react-redux';
import Dropdown from 'components/Dropdown';
import DropdownOption from 'components/Dropdown/DropdownOption';
import ClosedDropdown from 'components/Dropdown/ClosedDropdown';
import {getAvailableRecipientTypes} from '../../../../reducers';


import styles from './RecipientsSelector.css';


const TypeDropdown = ({type, onChange, itemsData = [] as any[]}) => {
  const [isOpen, setIsOpen] = useState(false);
  const closedDropdownRef = useRef() as React.MutableRefObject<HTMLElement>;

  useLayoutEffect(() => {
    if (isOpen || !closedDropdownRef.current) return;
    closedDropdownRef.current.focus();
  }, [isOpen]);

  const openDropdown = () => setIsOpen(true);
  const closeDropdown = () => setIsOpen(false);

  const items = useMemo(() => (
    itemsData.map((item, index) => ({
      Component: DropdownOption,
      props: {
        key: index,
        data: item,
        children: item.label,
        isSelected: item.type === type,
        showCheckmark: false,
        onClick: () => {
          onChange(item.type);
          closeDropdown();
        },
      },
    }))
  ), [itemsData, onChange, type]);

  const renderTriggerButton = useMemo(() => {
    const selectedType = itemsData.find(item => item.type === type);
    return () => (
      <ClosedDropdown
        className={styles.dropdownTrigger}
        closedDropdownRef={closedDropdownRef}>
        <DropdownOption>{selectedType ? selectedType.label : 'Choose'}</DropdownOption>
      </ClosedDropdown>
    );
  }, [itemsData, type]);

  return (
    <Dropdown
      isOpen={isOpen}
      requestOpen={openDropdown}
      requestClose={closeDropdown}
      items={items}
      renderTriggerButton={renderTriggerButton}
      tabIndex='-1'/>
  );
};

const mapStateToProps = (state, {formId}) => ({
  itemsData: getAvailableRecipientTypes(state, formId),
});

export default connect(mapStateToProps)(TypeDropdown);
