import React from 'react';
import classNames from 'classnames';
import styles from './Sidebar.css';
import Clear from '@material-ui/icons/Clear';
import HeaderIcon from './HeaderIcon';


const SidebarHeader = (
  { children, headerIcon, onSideBarToggle, headerIconColor, open, shouldRenderClose, customHeaderStyle = null }
) => {
  if (!open) {
    return null;
  }

  function onKeyDown(e) {
    if (e.key === 'Enter' || e.key === 'Space') {
      onSideBarToggle();
    }
  }

  return (
    <div className={classNames(styles.headerContainer, customHeaderStyle)}>
      <HeaderIcon icon={headerIcon} color={headerIconColor} />
      {children}
      {shouldRenderClose && <Clear
        tabIndex={0}
        className={styles.toggleIcon}
        onKeyDown={onKeyDown}
        onClick={onSideBarToggle} />}
    </div>
  );
};

export default SidebarHeader;
