import React, {useState, useCallback} from 'react';
import {connect} from 'react-redux';
import AssigneeMultiSelector from 'components/Assignees/AssigneeMultiSelector';
import AddAssigneeButtonBase from 'components/Assignees/AssigneeMultiSelector/AddAssigneeButton';
import {
  filterOutSelectedAssignees,
  filterToAssigneesBySearchString,
  filterToEligibleAssignees,
  hydrateAssignees,
} from 'utils/assignees';
import {immutableRemoveElementInArray} from 'utils/reduxHelper';
import styles from '../RecipientsSelector.css';
// import type {User, UserMap} from 'types/users';
// import type {ListChangeHandler} from '../index';

// type PropsFromState = {
//   users: UserMap,
// };

// type Props = {
//   onChange: ListChangeHandler,
//   values: string[],
// } & PropsFromState;

const SystemUserPicker = ({values, onChange, users}) => {
  const [typeaheadResults, setTypeaheadResults] = useState<any[]>([]);
  const assignees = hydrateAssignees(values, users, true);

  const handleRemoveUser = useCallback(removedUser => {
    const index = values.indexOf(removedUser.userId);
    onChange(immutableRemoveElementInArray(values, index));
  }, [onChange, values]);

  const handleAddUser = useCallback(addedUsers => {
    const newItems = addedUsers.map(({userId}) => userId);
    onChange([...values, ...newItems]);
  }, [onChange, values]);

  const handleInputTypeahead = useCallback(searchValue => {
    const assigneesAvailableToSelect = filterOutSelectedAssignees(values, users);
    const searchResults = filterToAssigneesBySearchString(assigneesAvailableToSelect, searchValue);
    setTypeaheadResults(searchResults);
  }, [values, users]);

  const AddAssigneeButton = () => (
    <span className={styles.addUserButton}>
      {!values.length && <span className={styles.triggerLabel}>CHOOSE</span>}
      <AddAssigneeButtonBase/>
    </span>
  );

  return (
    <div className={styles.multiSelectorContainer}>
      <AssigneeMultiSelector
        assignees={assignees}
        wideBubbles
        maxElements={2}
        CustomAddAssigneeButton={AddAssigneeButton}
        popoverOrigin={{vertical: 'top', horizontal: 'right'}}
        overflowButtonCountsAsElement={false}
        onAddAssignees={handleAddUser}
        onInputTypeahead={handleInputTypeahead}
        onRemoveAssignee={handleRemoveUser}
        typeaheadOffset={0}
        typeaheadResults={typeaheadResults}/>
    </div>
  );
};

const mapStateToProps = ({users}) => ({
  users: filterToEligibleAssignees(users),
});

export default connect(mapStateToProps)(SystemUserPicker);
