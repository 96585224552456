// Adding individual operators to rxjs/Observable, per https://github.com/ReactiveX/rxjs#installation-and-usage
import 'rxjs/add/observable/dom/ajax';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/groupBy';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/let';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/mergeAll';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/sampleTime';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/throttleTime';

import {combineEpics} from 'redux-observable';
import analyticsEpic from './analytics';
import apiEpic from './api';
import authorizationCheckEpic from './authorizationCheck';
import automatedProcessBuilderEpic from './automatedProcessBuilder';
import columnSortingEpic from './columnSorting';
import formsEpic from './forms';
import {liveViewGatingInstructionEpic, nextInstructionEpic} from './liveViewGating';
import lobbySideloadEpic from './lobby';
import loginEpic, {activeDirectoryConnectionsEpic} from './login';
import logoutEpic from './logout';
import participantsEpic from './participants';
import paymentsEpic from './payments';
import conditionalsEpic from './conditionals';
import roleEpic from './role';
import featuresEpic from './features';
import submissionsEpic from './submissions';
import submissionManagerSideloadEpic from './submissionManagerSideload';
import stagesEpic from './stages';
import eventsTrackerEpics from './events/events';
import bulkActionsTrackerEpics from './events/bulkActionEvents';
import conditionalsTrackerEpics from './events/conditionalsEvents';
import participantsTrackerEpics from './events/participantsEvents';
import performanceMeasurementEpics from './performanceMeasurement';
import organizationInfoEpic from './organizationInfo';

const epics = [
  activeDirectoryConnectionsEpic,
  analyticsEpic,
  apiEpic,
  authorizationCheckEpic,
  automatedProcessBuilderEpic,
  columnSortingEpic,
  featuresEpic,
  ...formsEpic,
  liveViewGatingInstructionEpic,
  nextInstructionEpic,
  lobbySideloadEpic,
  loginEpic,
  logoutEpic,
  participantsEpic,
  paymentsEpic,
  conditionalsEpic,
  roleEpic,
  ...submissionsEpic,
  submissionManagerSideloadEpic,
  ...stagesEpic,
  ...eventsTrackerEpics,
  ...bulkActionsTrackerEpics,
  ...conditionalsTrackerEpics,
  ...participantsTrackerEpics,
  organizationInfoEpic,
  ...performanceMeasurementEpics,
];

export default combineEpics(...epics);
