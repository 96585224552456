import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UnassignSelfModal from 'components/Modals/UnassignSelfModal';
import AssigneeMultiSelector from 'components/Assignees/AssigneeMultiSelector';
import * as assignmentsActions from 'actions/assignments';
import {
  filterOutSelectedAssignees,
  filterToAssigneesBySearchString,
  filterToEligibleAssignees,
  hydrateAssignees,
} from 'utils/assignees';
import { SM_DETAILS_SOURCE } from 'constants/forms';
// import type {Role} from 'types/shared';
// import type {
//   User,
//   UserMap,
// } from 'types/users';
import { userRolesEnum } from 'constants/user';

// type PropsFromState = {
//   eligibleAssignees: UserMap,
// };

// type PropsFromDispatch = {
//   actions: typeof assignmentsActions,
// };

// type Props = PropsFromState & PropsFromDispatch & {
//   assigneeIds: string[],
//   authUser: {
//     id: string,
//     role: Role,
//   },
//   isReadOnly: boolean,
//   maxElements: number,
//   noAssigneesMessage: ?string,
//   source: string,
//   submissionId: string,
// };

// type State = {
//   modalOpen: boolean,
//   typeaheadResults: User[],
// };

export class AssignmentsCell extends React.PureComponent<any, any> {
  static defaultProps = {
    assigneeIds: [],
    eligibleAssignees: {},
    noAssigneesMessage: null,
  }

  state = {
    modalOpen: false,
    typeaheadResults: [],
  }

  removeAssignee = userId => {
    const { actions, source, submissionId } = this.props;
    actions.unassignUsers({ source, submissionId, userIds: [userId] });
  }

  modalClose = () => this.setState({ modalOpen: false })

  modalProceed = () => {
    this.removeAssignee(this.props.authUser.id);
    this.modalClose();
  }

  handleAddAssignees = users => {
    const { actions, source, submissionId } = this.props;
    actions.assignUsers({
      source, submissionId,
      userIds: users.map(user => user.userId),
      shouldSendEmailNotification: true,
    });
  }

  handleRemoveAssignee = ({ userId }) => {
    const { authUser } = this.props;
    if (authUser.role !== userRolesEnum.OWNER && userId === authUser.id) {
      return this.setState({ modalOpen: true });
    }
    return this.removeAssignee(userId);
  }

  handleInputTypeahead = searchValue => {
    const { assigneeIds, eligibleAssignees } = this.props;
    const assigneesAvailableToSelect = filterOutSelectedAssignees(assigneeIds, eligibleAssignees);
    const searchResults = filterToAssigneesBySearchString(assigneesAvailableToSelect, searchValue);
    this.setState({
      typeaheadResults: searchResults,
    });
  };

  render() {
    const {
      assigneeIds,
      eligibleAssignees,
      isReadOnly,
      maxElements,
      noAssigneesMessage,
      source,
    } = this.props;
    const { typeaheadResults, modalOpen } = this.state;
    if (isReadOnly && !assigneeIds.length) return noAssigneesMessage || null;

    const assignees = hydrateAssignees(assigneeIds, eligibleAssignees);
    return (
      <React.Fragment>
        <AssigneeMultiSelector
          wideBubbles={source === SM_DETAILS_SOURCE}
          assignees={assignees}
          isReadOnly={isReadOnly}
          maxElements={maxElements}
          onAddAssignees={this.handleAddAssignees}
          onInputTypeahead={this.handleInputTypeahead}
          onRemoveAssignee={this.handleRemoveAssignee}
          typeaheadResults={typeaheadResults} />
        {modalOpen && <UnassignSelfModal
          open={modalOpen}
          onClose={this.modalClose}
          onProceed={this.modalProceed} />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth, users }) => ({
  authUser: {
    id: auth.profile.auid,
    role: auth.role,
  },
  eligibleAssignees: filterToEligibleAssignees(users),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(assignmentsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsCell);
