import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ToggleCheckbox from '@material-ui/icons/CheckBox';
import ToggleCheckboxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { permissionLevelsEnum } from 'constants/permissions';

import inlineStyles from './inlineStyles';
import styles from './styles.css';
import * as Colors from 'utils/colors';


const checkedStyles = { ...inlineStyles.iconStyle, fill: Colors.blue500 };
const checkedAndDisabledStyles = { ...inlineStyles.iconStyle, fill: Colors.grey400 };
const uncheckedStyles = { ...inlineStyles.iconStyle, fill: Colors.grey400 };

class PermissionLevels extends React.Component<any, any> {
  handleCheck = (isChecked, permissionLevel) => {
    const { onCheck } = this.props;
    onCheck(isChecked, permissionLevel);
  };

  render() {
    const { isAuthor, permissionLevels, showCanEditCheckbox, showСanProcessSubmissionsCheckbox } = this.props;

    const canViewForm = isAuthor || permissionLevels.includes(permissionLevelsEnum.ShareForm);
    const canViewSubmissions = isAuthor || permissionLevels.includes(permissionLevelsEnum.ShareAllFormSubmissions);
    const canEditForm = isAuthor || permissionLevels.includes(permissionLevelsEnum.EditForm);

    const canProcessSubmissionsForm = permissionLevels.includes(permissionLevelsEnum.ProcessSubmissionsForm);

    return (
      <React.Fragment>
        <td>
          <Checkbox
            classes={{ root: styles.checkbox }}
            checked={canViewForm}
            disabled={isAuthor || canProcessSubmissionsForm}
            checkedIcon={
              <ToggleCheckbox
                style={isAuthor || canProcessSubmissionsForm ? checkedAndDisabledStyles : checkedStyles}
              />
            }
            icon={<ToggleCheckboxOutlineBlank style={uncheckedStyles} />}
            onChange={(e, isChecked) => {
              this.handleCheck(isChecked, permissionLevelsEnum.ShareForm);
            }}
          />
        </td>
        <td>
          <Checkbox
            classes={{ root: styles.checkbox }}
            checked={canViewSubmissions}
            disabled={isAuthor || canProcessSubmissionsForm}
            checkedIcon={
              <ToggleCheckbox
                style={isAuthor || canProcessSubmissionsForm ? checkedAndDisabledStyles : checkedStyles}
              />
            }
            icon={<ToggleCheckboxOutlineBlank style={uncheckedStyles} />}
            onChange={(e, isChecked) => {
              this.handleCheck(isChecked, permissionLevelsEnum.ShareAllFormSubmissions);
            }}
          />
        </td>
        <td>
          {showCanEditCheckbox && (
            <Checkbox
              classes={{ root: styles.checkbox }}
              checked={canEditForm}
              disabled={isAuthor}
              checkedIcon={<ToggleCheckbox style={checkedStyles} />}
              icon={<ToggleCheckboxOutlineBlank style={uncheckedStyles} />}
              onChange={(e, isChecked) => {
                this.handleCheck(isChecked, permissionLevelsEnum.EditForm);
              }}
            />
          )}
        </td>
        <td>
          {showСanProcessSubmissionsCheckbox && (
            <Checkbox
              classes={{ root: styles.checkbox }}
              checked={canProcessSubmissionsForm}
              disabled={isAuthor}
              checkedIcon={<ToggleCheckbox style={checkedStyles} />}
              icon={<ToggleCheckboxOutlineBlank style={uncheckedStyles} />}
              onChange={(e, isChecked) => {
                this.handleCheck(isChecked, permissionLevelsEnum.ProcessSubmissionsForm);
              }}
            />
          )}
        </td>
      </React.Fragment>
    );
  }
}

export default PermissionLevels;
