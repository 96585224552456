import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import * as actions from 'actions/auth';
import { gotoLobby } from 'actions/routing';
import brandLogo from 'images/brand-logo.svg';
import brandLogoFull from 'images/brand-logo-full.svg';
import { COMPANY_NAME } from 'constants/applicationCopy';
import injectPermissions from 'decorators/injectPermissions';
import AccountButton from 'components/Header/AccountButton';
import CreateForm from 'components/Header/CreateForm';
import { getUserCapabilitiesLoadingState, getFeatureState } from '../../reducers';
import style from './header.css';


class Header extends React.Component<any, any> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.isAuthenticated && this.props.userCapabilitiesLoading) {
      this.props.getUserProfile(this.props.dispatch);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isAuthenticated && this.props.isAuthenticated && this.props.userCapabilitiesLoading) {
      this.props.getUserProfile(this.props.dispatch);
    }
  }

  onLogoClick = event => {
    event.preventDefault();
    this.props.gotoLobby();
  };

  handleLogout = () => {
    this.props.requestLogout();
  };

  handleResetSnackbar = () => {
    this.props.resetHeaderSnackbar();
  };

  render() {
    const {
      canUserAccessSD,
      canUserCreateForms,
      isAuthenticated,
      profile,
      role,
      uploadNewDocumentFailure,
      uploadNewDocumentSuccess,
      isSnackbarOpen,
      snackbarMessage,
      userCapabilitiesLoading,
      visitorAccessEnabled,
    } = this.props;

    if (userCapabilitiesLoading || (!canUserAccessSD() && !visitorAccessEnabled)) return null;

    let loginButton;
    // redundant check on profile but flow wants this;
    if (isAuthenticated && profile) {
      loginButton = (
        <AccountButton
          email={profile.email}
          name={profile.name}
          onLogout={this.handleLogout}
          role={role}
          subdomain={profile.subd}
        />
      );
    }

    return (
      <span>
        <header className={style.headerContainer} style={{ width: '100%' }}>
          <button className={style.logoContainer} onClick={this.onLogoClick}>
            <img alt={COMPANY_NAME} className={style.logoDesktop} src={brandLogoFull} />
            <img alt={COMPANY_NAME} className={style.logoMobile} src={brandLogo} />
          </button>

          <div className={style.navigation}>
            <ul />
          </div>

          <div className={style.rightContainer}>
            {loginButton}
            {isAuthenticated && canUserCreateForms() && (
              <CreateForm
                onUploadNewDocumentSuccess={uploadNewDocumentSuccess}
                onUploadNewDocumentFailure={uploadNewDocumentFailure}
              />
            )}
          </div>
        </header>
        <Snackbar
          open={isSnackbarOpen || false}
          message={snackbarMessage || ''}
          autoHideDuration={3500}
          onClose={this.handleResetSnackbar}
        />
      </span>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    ...auth,
    userCapabilitiesLoading: getUserCapabilitiesLoadingState(state),
    visitorAccessEnabled: getFeatureState(state).VISITOR_ACCESS_SUPPORT,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ ...actions, gotoLobby }, dispatch),
    dispatch,
  };
}

export default injectPermissions(connect(mapStateToProps, mapDispatchToProps)(Header));
