import { nanoid } from 'nanoid';
import { columnTypes, DISABLED_COLUMN_KEYS, operatorsEnum } from 'constants/submissionFilters';
import { STAGES_COLUMN_LABEL } from 'constants/tableConsts';
import { ALL_VIRTUAL_STAGE_NAME } from 'constants/stages';

import { FieldOptions, FormField, FormFields } from 'types/formFields';
import {
  SubmissionFilter,
  SubmissionFilterForServer,
  State,
  Column,
  ColumnItem,
} from 'types/submissionsManager/submissionFilters';

export const getEmptyFilter = (): SubmissionFilter => ({
  id: nanoid(),
  columnKey: undefined,
  operator: undefined,
  expectation: undefined,
});

export const findFilterIndex = (id: string, state: State) =>
  state.filters.findIndex(filter => filter.id === id);

export const getSubmissionFiltersForServer = (
  filters: SubmissionFilter[]
): SubmissionFilterForServer[] | undefined =>
  // @ts-ignore
  (filters.length <= 0)
    ? undefined
    : filters.map(({ columnKey, operator, expectation }) =>
      ({
        filterBy: columnKey,
        operator,
        value: expectation || '',
      }));

const getSelectedColumnTypeForFormField = (formField: FormField): string | null => {
  switch (formField.type) {
    case 'line':
      if (formField.formFieldContentType === 'number') {
        return columnTypes.singleLineInputNumber;
      }
      return columnTypes.singleLineInput;
    case 'multiline':
      return columnTypes.multiLineInput;
    case 'checkbox':
      return columnTypes.checkbox;
    case 'radio':
      return columnTypes.radioButton;
    case 'dropdown':
      return columnTypes.dropdown;
    case 'date_picker':
    case 'full_date':
      return columnTypes.date;
    default:
      return null;
  }
};

const getSelectedColumnTypeForNonFormFields = (columnKey: string | null): string | null => {
  switch (columnKey) {
    case 'tags':
      return columnTypes.tag;
    case 'assignments':
      return columnTypes.assignee;
    case 'signatureStatus':
      return columnTypes.signatureStatus;
    case 'createdTs':
      return columnTypes.submissionCreated;
    case 'stages':
      return columnTypes.singleLineInput;
    case 'paymentAmount':
      return columnTypes.singleLineInputNumber;
    case 'paymentStatus':
      return columnTypes.paymentStatus;
    default:
      return null;
  }
};

export const getSelectedColumnType = (
  formFields: FormFields,
  columnKey: string | null,
): string | null => {
  const formField: FormField | undefined = formFields?.find(field => field.id === columnKey);

  if (formField) {
    return getSelectedColumnTypeForFormField(formField);
  }

  return getSelectedColumnTypeForNonFormFields(columnKey);
};

export const getExpectationFieldOptions = (
  formFields: FormFields,
  columnKey: string,
): FieldOptions => {
  const formField: FormField | undefined = formFields?.find(field => field.id === columnKey);

  if (formField) {
    return formField.options;
  }

  return [];
};

export const removeStageFilters = (appliedFilters: SubmissionFilter[]): SubmissionFilter[] =>
  appliedFilters.filter(filter => filter.columnKey !== STAGES_COLUMN_LABEL);

export const getStageFilter = (stageName: string): SubmissionFilter => ({
  ...getEmptyFilter(),
  columnKey: columnTypes.stage,
  operator: operatorsEnum.equals,
  expectation: stageName,
});

export const addOrReplaceStageFilter = (
  appliedFilters: SubmissionFilter[],
  stageName: string
): SubmissionFilter[] =>
  removeStageFilters(appliedFilters)
    .concat(getStageFilter(stageName));

export const getStageNameFromFilters = (appliedFilters: SubmissionFilter[]): string =>
  appliedFilters.find(filter => filter.columnKey === STAGES_COLUMN_LABEL)?.expectation || ALL_VIRTUAL_STAGE_NAME;

export const isColumnKeyEnabled = (columnKey: string): boolean =>
  !DISABLED_COLUMN_KEYS.includes(columnKey);

export const filterDisabledColumns = (columns: Column[]): Column[] =>
  columns.filter(column => isColumnKeyEnabled(column.key));

export const isColumnSortableAndFilterable = (columnType: string | null): boolean =>
  Object.keys(columnTypes).find(key => columnTypes[key] === columnType) !== undefined;

export const getFilterColumnItems = (
  columns: Column[],
  formFields: FormField[],
): ColumnItem[] => {
  const columnKeys = new Set<string>();

  const itemsFromColumns = columns.map(column => {
    columnKeys.add(column.key);
    return { label: column.label, value: column.key };
  });
  const itemsFromFormFields = formFields
    .filter(formField => !columnKeys.has(formField.id))
    .map(field => ({ label: field.label, value: field.id }));

  return itemsFromColumns
    .concat(itemsFromFormFields)
    .filter(column =>
      isColumnSortableAndFilterable(getSelectedColumnType(formFields, column.value))
      && isColumnKeyEnabled(column.value)
    );
};
