import React from 'react';
import styles from './styles.css';
import emailIcon from 'images/email.svg';

const RegistrationSubmission = () => (
  <div className={styles.registrationSubmission}>
    <img src={emailIcon} alt='' />
    <h2>One more step:</h2>
    <p>
      We sent you a link via email to confirm your account.
      You will then easily be able to access your account and any saved forms.
    </p>
  </div>
);

export default RegistrationSubmission;
