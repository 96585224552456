import React from 'react';
import transitions from 'utils/transitions';
import styles from './Sidebar.css';
import Loader from 'components/Loader';
import FocusTrap from 'components/FocusTrap';
import Header from './SidebarHeader';
import { joinClassNames } from 'utils/strings';


export default class Sidebar extends React.Component<any, any> {
  state = {
    transitionEnded: false,
  };

  static defaultProps = {
    visible: false,
    open: false,
    headerIcon: null,
    headerIconColor: '',
    lastActivity: '',
    showBackdrop: false,
    trapFocus: false,
    minWidth: 0,
  };

  root = React.createRef<HTMLDivElement>();

  renderBackdrop() {
    const { onSidebarToggle, open, showBackdrop, visible } = this.props;
    if (!open || !visible || !showBackdrop) {
      return null;
    }
    return <div className={styles.backdrop} onClick={onSidebarToggle} />;
  }

  onTransitionEnd = () => {
    this.setState(state => ({ transitionEnded: !state.transitionEnded }));
  };

  render() {
    const {
      children,
      dockerPoint,
      headerIcon,
      headerIconColor,
      onSidebarToggle,
      open,
      renderHeaderContent,
      trapFocus,
      visible,
      widthPercentage,
      minWidth,
      customHeaderStyle,
      className,
    } = this.props;

    /**
     * widthPercentage should be a whole number between 0 - 100.
     * This determines the width of the sidebar and defaults
     * to 35%
     */
    const sidebarWidth = widthPercentage ? widthPercentage / 100 : 0.35;
    const sidebarWidthPixels = open ? dockerPoint * sidebarWidth : 0;
    const sidebarHeight = open ? '100%' : '0%';

    const width = minWidth && sidebarWidthPixels < minWidth ? minWidth : sidebarWidthPixels;

    const renderStyle = {
      transition: (transitions as any).easeOut(null, 'transform', null),
      width,
      top: 0,
      height: sidebarHeight,
      left: dockerPoint,
      transform: `translate3d(${open ? -width : 0}px, 0, 0)`,
    };
    return (
      <React.Fragment>
        <div
          onTransitionEnd={this.onTransitionEnd}
          tabIndex={visible && open && this.state.transitionEnded ? 0 : undefined}
          className={joinClassNames(styles.sideBar, className)}
          style={renderStyle}
          ref={this.root}
          aria-hidden
        >
          {this.props.shouldRenderHeader && (
            <Header
              headerIcon={headerIcon}
              onSideBarToggle={onSidebarToggle}
              headerIconColor={headerIconColor}
              open={open}
              shouldRenderClose={this.props.shouldRenderClose}
              customHeaderStyle={customHeaderStyle}
            >
              {renderHeaderContent()}
            </Header>
          )}
          {visible ? open && children : <Loader />}
        </div>
        {visible && open && trapFocus && this.state.transitionEnded && <FocusTrap root={this.root.current} />}
        {this.renderBackdrop()}
      </React.Fragment>
    );
  }
}
