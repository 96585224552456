import React from 'react';
import _ from 'lodash';
import MaterialUIMenu from '@material-ui/core/Menu';
import DarkIconButton from '../buttons/DarkIconButton';
import { shouldContract } from './shared';
import toolbarStyles from '../Toolbar.css';


export default class Menu extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.showMenuRef = React.createRef();
  }

  showMenuRef

  _hasChildren = children => {
    if (_.isArray(children)) {
      return children.some(Boolean);
    }
    return !!children;
  };

  render() {
    const { children, windowWidth, toolbarMobileMenuVisible, onToolbarMobileMenuToggle } = this.props;
    if (shouldContract(windowWidth)) {
      return (
        this._hasChildren(children) ?
          <React.Fragment>
            <span ref={this.showMenuRef}>
              <DarkIconButton
                onClick={onToolbarMobileMenuToggle}
                icon='more_horiz' />
            </span>
            <MaterialUIMenu
              anchorEl={this.showMenuRef.current}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              classes={{
                paper: toolbarStyles.mobileMenuPopover,
              }}
              getContentAnchorEl={null} // We get a warning and cannot use anchorOrigin without this
              marginThreshold={0}
              onClose={onToolbarMobileMenuToggle}
              open={toolbarMobileMenuVisible}>
              {children}
            </MaterialUIMenu>
          </React.Fragment>
          :
          null
      );
    }
    return <span>{children}</span>;
  }
}
