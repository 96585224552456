import {EXCHANGE_FORM_DATA, LOAD_DATA_SYNC_INITIATIONS} from 'constants/types/formActionTypes';
import api from 'reducers/api';


export const initialState = {
  isLoading: false,
  initiations: [],
};

export default function dataSyncSidebar(state = initialState, _action) {
  switch (_action.type) {
    case LOAD_DATA_SYNC_INITIATIONS:
      return api(_action, state, {
        success: () => ({
          ...state,
          initiations: _action.payload.initiations,
          isLoading: false,
        }),
        pending: () => ({...state, isLoading: true}),
        failure: () => ({...state, isLoading: false}),
      });
    case EXCHANGE_FORM_DATA:
      return api(_action, state, {
        success: () => ({...state, isLoading: false}),
        pending: () => ({...state, isLoading: true}),
        failure: () => ({...state, isLoading: false}),
      });
    default:
      return state;
  }
}

export const getInitiations = state => state.initiations;
export const getIsLoading = state => state.isLoading;
