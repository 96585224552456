import api from 'reducers/api';
import * as types from 'constants/types/automatedProcessBuilderActionTypes';


const initialState = {
  legacyFormId: null,
  isLoading: false,
  isModalVisible: false,
  visibleEmailRuleId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_RULES:
      return api(action, state, {
        failure: () => ({
          ...state,
          isLoading: false,
        }),
        pending: () => ({
          ...state,
          isLoading: true,
        }),
        success: () => ({
          ...state,
          isLoading: false,
        }),
      });
    case types.SAVE_RULES:
      return api(action, state, {
        success: () => ({
          ...state,
          isModalVisible: false,
        }),
      });
    case types.SET_APB_VISIBILITY:
      return {
        ...state,
        isModalVisible: action.isVisible,
        visibleEmailRuleId: null,
      };
    case types.SET_EMAIL_VISIBILITY:
      return {
        ...state,
        visibleEmailRuleId: action.visibleEmailRuleId,
      };
    case types.SET_LEGACY_FORM_ID:
      return {
        ...state,
        legacyFormId: action.formId,
      };
    default:
      return state;
  }
};

export const isApbVisible = state => state.isModalVisible;

export const getVisibleEmailRuleId = state => state.visibleEmailRuleId;

export const isApbLoading = state => state.isLoading;

export const getLegacyFormId = state => state.legacyFormId;
