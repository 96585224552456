export const emptyConfig = {
  dataSystem: '',
  recordType: '',
  host: '',
  username: '',
  password: '',
  initiations: [],
};

export const emptyMapping = {
  sourceField: '',
  targetField: '',
};

export const emptyInitiation = {
  initiationStep: '',
  isEnabled: false,
  isRequired: false,
  errorMessage: 'We were unable to locate a record that matches the data you entered. Please verify the information you entered is correct and try again.',
  lookupFields: [],
  formDataMappings: [emptyMapping],
  requestMappings: [emptyMapping],
};

export const DataSystemOptions = [
  { label: 'CountyFusion', value: 'county_fusion' },
];

export const RecordTypeOptions = [
  { label: 'Marriage Certificate Request', value: 'eMarriage' },
  { label: 'Birth Certificate Request', value: 'eBirth' },
  { label: 'Death Certificate Request', value: 'eDeath' },
  { label: 'Marriage Affidavit Request', value: 'eMarriageAffidavit' },
  { label: 'Attorney Request (York)', value: 'eAttorney' },
  { label: 'Attorney Request (Lawrence)', value: 'eAttorneyLawrence' },
  { label: 'Attorney Request (Cumberland)', value: 'eAttorneyCumberland' },
];
