import AssigneeIcon from 'components/Icons/AssigneeIcon';
import EmailIcon from 'components/Icons/EmailIcon';
import StageIcon from 'components/Icons/StageIcon';
import TagIcon from 'components/Icons/TagIcon';
import WebhooksIcon from 'components/Icons/WebhooksIcon';


const ASSIGNMENT = 'assignment';
const COREGISTRATION = 'coregistration';
const NOTIFICATION = 'notification';
const WEBHOOK = 'webhook';
const STAGE = 'stage';
const TAG = 'tag';
const IF = 'IF';
const THEN = 'THEN';
const STATIC = 'static';
const EMAIL_FIELD = 'emailField';
const SUBMITTER = 'submitter';
const SIGNER = 'signer';
const ASSIGNED_USER = 'assignee';
const SYSTEM_USER = 'user';

export const triggersEnum = {
  ASSIGNMENT,
  COREGISTRATION,
  NOTIFICATION,
  WEBHOOK,
  STAGE,
  TAG,
};

export const triggerTypeEnum = {
  IF,
  THEN,
};

export const recipientTypeEnum = {
  STATIC,
  EMAIL_FIELD,
  SUBMITTER,
  SIGNER,
  ASSIGNED_USER,
  SYSTEM_USER,
};

export const triggerCopyLookup = {
  [ASSIGNMENT]: {
    [IF]: 'Assignment',
    [THEN]: 'Assign',
  },
  [TAG]: {
    [IF]: 'Tag',
    [THEN]: 'Tag',
  },
  [STAGE]: {
    [IF]: 'Stage',
    [THEN]: 'Set Stage',
  },
  [WEBHOOK]: {
    [IF]: '',
    [THEN]: 'Trigger webhook',
  },
  [NOTIFICATION]: {
    [IF]: '',
    [THEN]: 'Send email',
  },
};

export const triggerIconLookup = {
  [ASSIGNMENT]: AssigneeIcon,
  [TAG]: TagIcon,
  [STAGE]: StageIcon,
  [NOTIFICATION]: EmailIcon,
  [WEBHOOK]: WebhooksIcon,
};
