import api from 'actions/api';
import {getAllUsers} from 'actions/users';
import {
  ADD_ROLE,
  CHANGE_USER,
  CHANGE_ALLOW_VISITOR_REGISTRATION,
  CHANGE_ACCESS_CODE_ENABLED,
  CHANGE_AUTHOR,
  CHANGE_PERMISSION_LEVEL,
  CHANGE_REQUIRE_PASSWORD,
  CHANGE_REQUIRE_VISITOR_REGISTRATION,
  CHANGE_ROLE_PERMISSION_LEVEL,
  GET_FORM_PERMISSIONS,
  INITIALIZE_PERMISSIONS,
  REMOVE_PERMISSION,
  REMOVE_ROLE,
  SAVE_PERMISSIONS,
  TOGGLE_MODAL,
  TOGGLE_SNACKBAR,
  SAVE_UPDATING_PERMISSIONS_FORM_ID,
  DELETE_UPDATING_PERMISSIONS_FORM_ID,
} from 'constants/permissions';

// import type {
//   AddRoleActionCreator,
//   ChangeAllowVisitorRegistrationAction,
//   ChangeAuthorActionCreator,
//   ChangePermissionLevelActionCreator,
//   ChangeRequirePasswordAction,
//   ChangeRequireVisitorRegistrationAction,
//   ChangeRolePermissionLevelActionCreator,
//   ChangeUserActionCreator,
//   InitializePermissionsAction,
//   InitializePermissionArgs,
//   Permission,
//   RemovePermissionActionCreator,
//   RemoveRoleActionCreator,
//   SavePermissionsArgs,
//   ToggleModalAction,
//   ToggleSnackbarAction,
//   SaveUpdatingPermissionsFormIdAction,
//   DeleteUpdatingPermissionsFormIdAction,
// } from 'types/permissions';
// import type {Role} from 'types/shared';

// import type {Action as ApiAction} from 'types/api';

const getFormPermissions = (formId: string) =>
  api.postWithAuth({
    baseType: GET_FORM_PERMISSIONS,
    endpoint: 'forms/settings/get.json',
    data: {
      formId,
    },
    success: (_, {response}) => {
      const payload = {
        ...response.result,
      };
      return payload;
    },
    failure: (state, error) => ({error}),
  });

export const getUsers = (
  dispatch: Function,
  formId: string,
) => {
  const onSuccess = (_, {response: {users}}) => {
    dispatch(getFormPermissions(formId));
    return {users};
  };
  return getAllUsers(onSuccess);
};

export const initializePermissions = (args: any) => ({
  type: INITIALIZE_PERMISSIONS,
  payload: args,
});

export const toggleModal = (modalOpen: boolean) => ({
  type: TOGGLE_MODAL,
  modalOpen,
});

export const changeAuthor = (userId: string) => ({
  userId,
  type: CHANGE_AUTHOR,
});

export const changePermissionLevel = (
  userId: string,
  isAddingPermission: boolean,
  permissionName: string,
) => ({
  isAddingPermission,
  permissionName,
  userId,
  type: CHANGE_PERMISSION_LEVEL,
});

export const changeRequirePassword = (requirePassword: boolean) => ({
  requirePassword,
  type: CHANGE_REQUIRE_PASSWORD,
});

export const changeRequireVisitorRegistration = (requireVisitorRegistration: boolean) => ({
  requireVisitorRegistration,
  type: CHANGE_REQUIRE_VISITOR_REGISTRATION,
});

export const changeAllowVisitorRegistration = (allowVisitorRegistration: boolean) => ({
  allowVisitorRegistration,
  type: CHANGE_ALLOW_VISITOR_REGISTRATION,
});

export const changeAccessCodeEnabled = (accessCodeEnabled: boolean) => ({
  accessCodeEnabled,
  type: CHANGE_ACCESS_CODE_ENABLED,
});

export const changeUser = (userId: string) => ({
  userId,
  type: CHANGE_USER,
});

export const addRolePermission = (roleName: string) => ({
  roleName,
  type: ADD_ROLE,
});

export const removeRole = (roleName: string) => ({
  roleName,
  type: REMOVE_ROLE,
});

export const changeRolePermission = (
  roleName: string,
  isAddingPermission: boolean,
  permissionName: string,
) => ({
  isAddingPermission,
  permissionName,
  roleName,
  type: CHANGE_ROLE_PERMISSION_LEVEL,
});

export const toggleSnackbar = (snackbarOpen: boolean, snackbarMessage: string) => ({
  type: TOGGLE_SNACKBAR,
  snackbarOpen,
  snackbarMessage,
});

const saveFormId = (formId: string) => ({
  type: SAVE_UPDATING_PERMISSIONS_FORM_ID,
  formId,
});

const deleteFormId = (formId: string) => ({
  type: DELETE_UPDATING_PERMISSIONS_FORM_ID,
  formId,
});

export const savePermissions = (dispatch: Function, args: any, authorName: string) => {
  dispatch(saveFormId(args.formId));
  return api.postWithAuth({
    baseType: SAVE_PERMISSIONS,
    endpoint: 'forms/settings/update.json',
    data: args,
    success: () => {
      dispatch(toggleSnackbar(true, 'Your permission settings have been saved.'));
      dispatch(toggleModal(false));
      dispatch(deleteFormId(args.formId));
      return {
        formId: args.formId,
        authorName,
      };
    },
    failure: (state, error) => {
      dispatch(toggleSnackbar(true, 'There was an error saving your permission settings'));
      dispatch(deleteFormId(args.formId));
      return { error };
    },
  });
};

export const removePermission = (userId: string) => ({
  type: REMOVE_PERMISSION,
  userId,
});

