import {TABLE_CELL_WIDTH, ROW_NUMBER_CELL_WIDTH, CHECKBOX_WIDTH, SUBMISSIONS_ICON_WIDTH} from 'constants/tableConsts';
import flow from 'utils/flow';
// import type {Thunk} from 'types/shared';
// import type {DropTargetMonitor} from 'types/react-dnd';
// import type {
//   HandleSetHoverIndex,
//   HandleScrollOnDrag,
//   TableSortProps,
//   TableSortState,
// } from 'types/submissionsManager/tableTypes';

// type TableSort = {
//   handleScrollOnDragStopDebounced: Thunk,
//   handleScrollOnDragThrottled: HandleScrollOnDrag,
//   handleSetHoverIndex: HandleSetHoverIndex,
//   props: TableSortProps,
//   setState(partialState: $Shape<TableSortState>, callback?: Thunk): void,
//   state: TableSortState,
// };

/**
 * This moves the box to the right, since the content is
 * aligned 'center' we want to put the left side of box along center
 * This is also the offset of the combined widths of the first 2
 * (3 if the form has any submissions with submissionPdfUrl or isIncomplete)
 * non-sortable rows
 */
export const getColumnHeaderOffsetLeft = props => {
  // The number 8 is based on 8px for the cell padding
  let columnHeaderOffsetLeft = ROW_NUMBER_CELL_WIDTH + CHECKBOX_WIDTH - 8;

  if (props.isIncompleteOrHasSubmissionPdfUrl) {
    columnHeaderOffsetLeft += SUBMISSIONS_ICON_WIDTH;
  }
  return columnHeaderOffsetLeft;
};


// This is a function required by React DnD https://gaearon.github.io/react-dnd/docs-drop-target.html
// refactored here for testing purposes
export const boxTarget = {
  drop(props, monitor, component) {
    if (monitor && component) {
      const item = monitor.getItem();
      const delta = monitor.getDifferenceFromInitialOffset();
      const left = Math.round(item.left + delta.x);
      const columnHeaderOffsetLeft = getColumnHeaderOffsetLeft(props);
      const nextIndex = Math.round((left + props.scrollLeft - columnHeaderOffsetLeft) / TABLE_CELL_WIDTH);
      flow.safeApplyMethod(component, 'moveBox', nextIndex);
    }
  },

  hover(props, monitor, component) {
    if (monitor && component) {
      const delta = monitor.getSourceClientOffset();
      const scrollOffsetIndex = Math.floor(props.scrollLeft / TABLE_CELL_WIDTH);
      const columnHeaderOffsetLeft = getColumnHeaderOffsetLeft(props);
      const index =
        Math.round((delta.x + props.scrollLeft - columnHeaderOffsetLeft) / TABLE_CELL_WIDTH) - scrollOffsetIndex;
      flow.safeApplyMethod(component, 'handleHover', index, scrollOffsetIndex, delta.x);
    }
  },
};


// we dispatch column sorting action to Redux here
export const moveBoxHelper = (nextIndex, _this, columnHeaderOffsetLeft) => {
  const {scrollLeft, source, sortTableColumns, formId} = _this.props;
  const {boxLeft} = _this.state;
  const prevIndex = Math.round((boxLeft + scrollLeft - columnHeaderOffsetLeft) / TABLE_CELL_WIDTH);
  // if prev and next indices are the same, don't sort
  if (nextIndex !== prevIndex && nextIndex >= 0) {
    if (nextIndex < source.length) {
      sortTableColumns(prevIndex, nextIndex, source, formId);
    } else {
      // if dropped to the right of the last header
      // sort with lastIndex = index of last header
      sortTableColumns(prevIndex, source.length - 1, source, formId);
    }
  }
};

// this handles what's happening when box is in drag
// we set drop zone index here
// & activates scroll on drag when box dragged to the ends
export const handleHoverHelper = (index, scrollOffsetIndex, deltaX, _this) => {
  _this.handleSetHoverIndex(index);
  const {width, source} = _this.props;
  const lastIndex = Math.floor(width / TABLE_CELL_WIDTH) - 1;
  const totalNumberOfColumns = source.length;
  if (lastIndex < totalNumberOfColumns) {
    if (deltaX < (TABLE_CELL_WIDTH / 2) && scrollOffsetIndex > 0) {
      _this.handleScrollOnDragThrottled(index + scrollOffsetIndex);
      _this.setState({dragScrollingLeft: true});
    } else if (deltaX > (width - (TABLE_CELL_WIDTH / 2)) && (index + scrollOffsetIndex) < totalNumberOfColumns - 1) {
      _this.handleScrollOnDragThrottled(index + scrollOffsetIndex, true);
      _this.setState({dragScrollingRight: true});
    } else {
      _this.handleScrollOnDragStopDebounced();
      _this.setState({dragScrollingLeft: false, dragScrollingRight: false});
    }
  }
};
