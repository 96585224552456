import React, { PureComponent } from 'react';
import AccessibleSVG from 'components/AccessibleSVG';
import { getColor } from 'utils/stageColors';


const PATH_BOUNDING_BOX = {
  x: 0.9924589991569519,
  height: 6.771560192108154,
  width: 9.356440544128418,
  y: 0.9750200510025024,
};

function getCenter(radiusInPx, size, position) {
  return (radiusInPx - size / 2) - position;
}

export default class StageDot extends PureComponent<any, any> {
  static defaultProps = { color: 0, isSelected: false, includeTitle: false }

  getPathCenteredCoordinates = () => {
    const { radiusInPx } = this.props;
    const { x, height, width, y } = PATH_BOUNDING_BOX;
    return `${getCenter(radiusInPx, width, x)}, ${getCenter(radiusInPx, height, y)}`;
  }

  render() {
    const { props: { color, isSelected, radiusInPx, includeTitle, ...rest }, getPathCenteredCoordinates } = this;
    const diameter = 2 * radiusInPx;
    const svgTitle = `color ${color} ${isSelected ? 'selected' : ''}`;
    return (
      <AccessibleSVG width={diameter} height={diameter} title={includeTitle ? svgTitle : undefined} {...rest}>
        <circle cx={radiusInPx} cy={radiusInPx} r={radiusInPx} fill={getColor(color)} />
        {isSelected && <path
          transform={`translate(${getPathCenteredCoordinates()})`}
          d='M4.63107 7.74658L0.992459 4.10788L2.03199 3.06838L4.63107 5.66731L9.29523 0.97502L10.3489 2.0287L4.63107 7.74658Z'
          fill='white' />}
      </AccessibleSVG>
    );
  }
}

