import React, {useState} from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import styles from './BulkActionWrapper.css';
import TagsSelect from 'containers/SubmissionsManager/Components/TagsSelect';
import {createTags} from 'actions/tags';

const FindAndReplaceTags = ({
  submissionIds,
  findAndReplaceTags,
  createTag,
  dispatch,
  options = [],
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  loaderModalToggle,
  errorModalToggle,
}) => {
  const [findValue, setFindValue] = useState<any[]>([]);
  const [replaceValue, setReplaceValue] = useState<any[]>([]);

  const handleReplaceTags = () => {
    findAndReplaceTags({
      formId,
      tagToFind: findValue[0].name,
      tagToReplace: replaceValue[0].name,
      submissionIds,
      includeAllSubmissions,
      excludedSubmissionIds,
      onError: () => errorModalToggle(true),
    }, dispatch);
  };

  const onReplaceClick = () => {
    const submissionsNumber = includeAllSubmissions ? 'all the' : `the ${submissionIds.length} selected`;
    loaderModalToggle(
      true,
      'fnrTags',
      'Find and Replace Tag',
      `Are you sure you want to conduct a find and replace ${submissionsNumber} submission(s)?`,
      handleReplaceTags,
    );
  };

  const handleCreateTag = name => {
    createTag([name], () => {});
  };

  return (<div className={styles.fnrContainer}>
    <div className={styles.InputWrapper}>
      <label htmlFor='find'>Find</label>
      <TagsSelect
        options={options}
        selected={findValue}
        setSelected={setFindValue}
        createTag={handleCreateTag}
        shouldCreateTag={false}/>
    </div>
    <div className={styles.InputWrapper}>
      <label htmlFor='replace'>Replace</label>
      <TagsSelect
        options={options}
        selected={replaceValue}
        setSelected={setReplaceValue}
        createTag={handleCreateTag}
        shouldCreateTag={false}/>
    </div>
    <button type='button' className={styles.replaceButton} onClick={onReplaceClick} disabled={!(findValue.length && replaceValue.length)}>Replace</button>
  </div>);
};

export default connect(null, dispatch => ({
  createTag: bindActionCreators(createTags, dispatch),
  dispatch,
}))(FindAndReplaceTags);
