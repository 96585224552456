import { useMemo } from 'react';
import { FormData, FormState, LiveViewPaymentConfig } from 'types/liveView';
import { FormDataGovOSPaymentConfig } from 'types/govOsPay/govOsPaymentSettings';
import { calculatePaymentForType } from 'utils/govOSPay/govOsPay';
import { calculatePayment } from 'utils/formLiveView/formLiveView';

export const useSubmitPaymentAmount = (formData: FormData, formState: FormState): [number, boolean] => useMemo(() => {
  const spreedlyPaymentConfig: LiveViewPaymentConfig | null | undefined = formData?.form?.paymentConfig;
  const govOsPayConfig: FormDataGovOSPaymentConfig | null | undefined = formData?.form?.govOsPayConfig;

  if (!spreedlyPaymentConfig && !govOsPayConfig) return [0, false];

  const calculateSpreedlyPayment = () => {
    if (spreedlyPaymentConfig) {
      const [spreedlyPaymentAmount] = calculatePayment(spreedlyPaymentConfig, formState, 'credit_card');
      return spreedlyPaymentAmount;
    }
    return 0;
  };

  const calculateGovOsPayment = () => {
    if (govOsPayConfig) {
      const [govOsPaymentAmount] = calculatePaymentForType(govOsPayConfig, formState, 'credit_card');
      return govOsPaymentAmount;
    }
    return 0;
  };

  const spreedlyPaymentAmount = calculateSpreedlyPayment();
  const govOsPaymentAmount = calculateGovOsPayment();

  const spreedlyPaymentOptional = spreedlyPaymentConfig?.paymentOptional ?? false;
  const govOsPaymentOptional = govOsPayConfig?.paymentOptional ?? false;
  const requiresPayment = !spreedlyPaymentOptional && !govOsPaymentOptional;


  let amountToPay = 0;
  if (spreedlyPaymentAmount > 0) {
    amountToPay = spreedlyPaymentAmount;
  } else if (govOsPaymentAmount > 0) {
    amountToPay = govOsPaymentAmount;
  }

  return [amountToPay, requiresPayment];
}, [formData, formState]);
