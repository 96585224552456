import { Operator, ColumnType, OperatorItem, ExpectationField } from 'types/submissionsManager/submissionFilters';
import { paymentStatusValuesEnum, signatureStatusValuesEnum } from 'types/submissionsManager/tableTypes';
import { ASSIGNMENTS_COLUMN_LABEL, TAGS_COLUMN_LABEL, STAGES_COLUMN_LABEL, SIGNATURE_STATUS_COLUMN_LABEL, PAYMENT_STATUS_COLUMN_LABEL } from './tableConsts';

export const columnTypes = {
  singleLineInput: 'line',
  singleLineInputNumber: 'line_number',
  multiLineInput: 'multiline',
  dropdown: 'dropdown',
  checkbox: 'checkbox',
  radioButton: 'radio',
  tag: TAGS_COLUMN_LABEL,
  assignee: ASSIGNMENTS_COLUMN_LABEL,
  stage: STAGES_COLUMN_LABEL,
  signatureStatus: SIGNATURE_STATUS_COLUMN_LABEL,
  paymentStatus: PAYMENT_STATUS_COLUMN_LABEL,
  date: 'date_picker',
  fullDate: 'full_date',
  submissionCreated: 'createdTs',
} as const;

const EQUALS = 'eq';
const NOT_EQUALS = 'neq';
const CONTAINS = 'contains';
const NOT_CONTAINS = 'does_not_contain';
const HAS_A_VALUE = 'has_value';
const HAS_NO_VALUE = 'has_no_value';
const GREATER_THAN = 'gt';
const LESS_THAN = 'lt';
const GREATER_THAN_OR_EQUAL_TO = 'gte';
const LESS_THAN_OR_EQUAL_TO = 'lte';

export const operatorsEnum = {
  equals: EQUALS,
  notEquals: NOT_EQUALS,
  contains: CONTAINS,
  notContains: NOT_CONTAINS,
  hasAValue: HAS_A_VALUE,
  hasNoValue: HAS_NO_VALUE,
  greaterThan: GREATER_THAN,
  lessThan: LESS_THAN,
  greaterThanOrEqualTo: GREATER_THAN_OR_EQUAL_TO,
  lessThanOrEqualTo: LESS_THAN_OR_EQUAL_TO,
} as const;

const equalsDropdownItem: OperatorItem = {
  label: 'is equal to',
  value: operatorsEnum.equals,
};

const notEqualsDropdownItem: OperatorItem = {
  label: 'is not equal to',
  value: operatorsEnum.notEquals,
};

const containsDropdownItem: OperatorItem = {
  label: 'contains',
  value: operatorsEnum.contains,
};

const notContainsDropdownItem: OperatorItem = {
  label: 'does not contain',
  value: operatorsEnum.notContains,
};

const hasAValueDropdownItem: OperatorItem = {
  label: 'has a value',
  value: operatorsEnum.hasAValue,
};

const hasNoValueDropdownItem: OperatorItem = {
  label: 'has no value',
  value: operatorsEnum.hasNoValue,
};

const greaterThanDropdownItem: OperatorItem = {
  label: 'is greater than',
  value: operatorsEnum.greaterThan,
};

const greaterThanOrEqualToDropdownItem: OperatorItem = {
  label: 'is greater than or equal to',
  value: operatorsEnum.greaterThanOrEqualTo,
};

const lessThanDropdownItem: OperatorItem = {
  label: 'is less than',
  value: operatorsEnum.lessThan,
};

const lessThanOrEqualToDropdownItem: OperatorItem = {
  label: 'is less than or equal to',
  value: operatorsEnum.lessThanOrEqualTo,
};

export const operatorsDropdownConfig = [
  equalsDropdownItem,
  notEqualsDropdownItem,
  containsDropdownItem,
  notContainsDropdownItem,
  hasAValueDropdownItem,
  hasNoValueDropdownItem,
  greaterThanDropdownItem,
  lessThanDropdownItem,
] as const;

const INPUT = 'input';
const SELECT = 'select';
const TAGS_INPUT = 'tagsInput';
const ASSIGNEES_INPUT = 'assigneeInput';
const SIGNATURE_STATUS_SELECT = 'signatureStatusSelect';
const PAYMENT_STATUS_SELECT = 'paymentStatusSelect';
const DATE_INPUT = 'dateInput';

export const expectationFieldEnum = {
  input: INPUT,
  select: SELECT,
  tagsInput: TAGS_INPUT,
  assigneesInput: ASSIGNEES_INPUT,
  signatureStatusSelect: SIGNATURE_STATUS_SELECT,
  paymentStatusSelect: PAYMENT_STATUS_SELECT,
  dateInput: DATE_INPUT,
} as const;

export const filterDropdownConfig: {
  [key in ColumnType]: {
    operators: OperatorItem[],
    expectationField: {
      type: ExpectationField,
      displayWhen: Operator[],
    }
  }
} = {
  [columnTypes.singleLineInput]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      containsDropdownItem,
      notContainsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
    ],
    expectationField: {
      type: 'input',
      displayWhen: [EQUALS, NOT_EQUALS, CONTAINS, NOT_CONTAINS],
    },
  },
  [columnTypes.singleLineInputNumber]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
      greaterThanDropdownItem,
      greaterThanOrEqualToDropdownItem,
      lessThanDropdownItem,
      lessThanOrEqualToDropdownItem,
    ],
    expectationField: {
      type: 'input',
      displayWhen: [EQUALS, NOT_EQUALS, GREATER_THAN, LESS_THAN, GREATER_THAN_OR_EQUAL_TO, LESS_THAN_OR_EQUAL_TO],
    },
  },
  [columnTypes.multiLineInput]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      containsDropdownItem,
      notContainsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
    ],
    expectationField: {
      type: 'input',
      displayWhen: [EQUALS, NOT_EQUALS, CONTAINS, NOT_CONTAINS],
    },
  },
  [columnTypes.dropdown]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
    ],
    expectationField: {
      type: 'select',
      displayWhen: [EQUALS, NOT_EQUALS],
    },
  },
  [columnTypes.checkbox]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      containsDropdownItem,
      notContainsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
      greaterThanDropdownItem,
      lessThanDropdownItem,
    ],
    expectationField: {
      type: 'select',
      displayWhen: [EQUALS, NOT_EQUALS, CONTAINS, NOT_CONTAINS, GREATER_THAN, LESS_THAN],
    },
  },
  [columnTypes.radioButton]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
      greaterThanDropdownItem,
      lessThanDropdownItem,
    ],
    expectationField: {
      type: 'select',
      displayWhen: [EQUALS, NOT_EQUALS, GREATER_THAN, LESS_THAN],
    },
  },
  [columnTypes.tag]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      containsDropdownItem,
      notContainsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
    ],
    expectationField: {
      type: 'tagsInput',
      displayWhen: [EQUALS, NOT_EQUALS, CONTAINS, NOT_CONTAINS],
    },
  },
  [columnTypes.assignee]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      containsDropdownItem,
      notContainsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
    ],
    expectationField: {
      type: 'assigneeInput',
      displayWhen: [EQUALS, NOT_EQUALS, CONTAINS, NOT_CONTAINS],
    },
  },
  [columnTypes.date]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      containsDropdownItem,
      notContainsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
      greaterThanDropdownItem,
      lessThanDropdownItem,
    ],
    expectationField: {
      type: 'dateInput',
      displayWhen: [EQUALS, NOT_EQUALS, CONTAINS, NOT_CONTAINS, GREATER_THAN, LESS_THAN],
    },
  },
  [columnTypes.fullDate]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      containsDropdownItem,
      notContainsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
      greaterThanDropdownItem,
      lessThanDropdownItem,
    ],
    expectationField: {
      type: 'dateInput',
      displayWhen: [EQUALS, NOT_EQUALS, CONTAINS, NOT_CONTAINS, GREATER_THAN, LESS_THAN],
    },
  },
  [columnTypes.submissionCreated]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
      greaterThanDropdownItem,
      lessThanDropdownItem,
    ],
    expectationField: {
      type: 'dateInput',
      displayWhen: [EQUALS, NOT_EQUALS, GREATER_THAN, LESS_THAN],
    },
  },
  [columnTypes.stage]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
    ],
    expectationField: {
      type: 'input',
      displayWhen: [EQUALS, NOT_EQUALS],
    },
  },
  [columnTypes.signatureStatus]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
    ],
    expectationField: {
      type: 'signatureStatusSelect',
      displayWhen: [EQUALS, NOT_EQUALS],
    },
  },
  [columnTypes.paymentStatus]: {
    operators: [
      equalsDropdownItem,
      notEqualsDropdownItem,
      hasAValueDropdownItem,
      hasNoValueDropdownItem,
    ],
    expectationField: {
      type: 'paymentStatusSelect',
      displayWhen: [EQUALS, NOT_EQUALS],
    },
  },
};

export const DISABLED_COLUMN_KEYS = [
  'payment_PaymentMunicipay',
  'payment_PaymentNIC',
];

export const signatureStatuses = [
  {
    value: signatureStatusValuesEnum.pending,
    label: 'Pending',
  },
  {
    value: signatureStatusValuesEnum.complete,
    label: 'Complete',
  },
  {
    value: signatureStatusValuesEnum.declined,
    label: 'Declined',
  },
];

export const paymentStatuses = [
  {
    value: paymentStatusValuesEnum.processed,
    label: 'Processed',
  },
  {
    value: paymentStatusValuesEnum.preAuthorized,
    label: 'Pre-authorized',
  },
  {
    value: paymentStatusValuesEnum.refunded,
    label: 'Refunded',
  },
];
