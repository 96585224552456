import api from 'reducers/api';
import { COMPLETE_COREGISTRATION } from 'constants/types/coregistration';
import { VERIFY_COREGISTRATION } from 'constants/types/authActionTypes';


const initialState = {
  didCoregistrationComplete: false,
  didCoregistrationFail: false,
  isCoregistrationProcessing: false,
  grmRedirectSubdomain: null,
  isFetchingCoregistrationVerification: false,
};

export default function submissionRuleBuilder(
  state = initialState,
  _action
) {
  const action = _action;
  switch (action.type) {
    case COMPLETE_COREGISTRATION:
      return api(action, state, {
        failure: () => ({
          ...state,
          isCoregistrationProcessing: false,
          didCoregistrationFail: true,
        }),
        pending: () => ({ ...state, isCoregistrationProcessing: true }),
        success: () => ({
          ...state,
          didCoregistrationComplete: true,
          grmRedirectSubdomain: action.payload.result.subdomain,
          isCoregistrationProcessing: false,
        }),
      });
    case VERIFY_COREGISTRATION:
      return api(action, state, {
        success: () => ({
          ...state,
          isFetchingCoregistrationVerification: false,
        }),
        pending: () => ({
          ...state,
          isFetchingCoregistrationVerification: true,
        }),
        failure: () => ({
          ...state,
          didCoregistrationFail: true,
          isFetchingCoregistrationVerification: false,
        }),
      });
    default:
      return state;
  }
}
