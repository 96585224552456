import {
  TOGGLE_MODAL,
  LOAD_DATA_SYNC_CONFIG,
  SAVE_DATA_SYNC_CONFIG,
  SET_CURRENT_CONFIG,
  SAVE_DATA_SYNC_INITIATIONS,
  SET_MESSAGE,
  GET_FORM_DETAILS,
  LOADER_VISIBILITY,
  SET_IS_CONNECTION_SUCCESSFUL,
  GET_PARAMS,
  DELETE_CONFIGURATION,
} from 'constants/dataSyncSidebar';
import api from 'reducers/api';
import {emptyInitiation} from 'containers/DataSyncSidebar/constants';

const fieldTypes = ['line', 'multiline', 'email'];

export const initialState = {
  isModalOpen: false,
  isLoading: false,
  isConnecting: false,
  isConnectionSuccessful: null,
  configurations: [],
  message: null,
  currentConfig: null,
  formDetails: {
    formName: '',
    initiationSteps: [],
    formFields: [],
  },
  requestParams: [],
  formDataParams: [],
  confirmationModal: {
    loaderVisibile: false,
    loaderBody: '',
    loaderTitle: '',
    agreeText: '',
    disagreeText: '',
    onClose: () => {},
    onSubmit: () => {},
  },
};

export default function dataSyncSidebar(state = initialState, _action) {
  switch (_action.type) {
    case TOGGLE_MODAL:
      return {...state, isModalOpen: !state.isModalOpen};
    case LOAD_DATA_SYNC_CONFIG:
      return api(_action, state, {
        success: () => ({
          ...state,
          configurations: _action.payload.configurations,
          isLoading: false,
        }),
        pending: () => ({...state, isLoading: true}),
        failure: () => ({...state, isLoading: false}),
      });
    case SAVE_DATA_SYNC_CONFIG:
      return api(_action, state, {
        success: () => {
          const currentConfig = {
            // @ts-ignore
            ...state.configurations[_action.payload.index],
            configurationId: _action.payload.configurationId,
            dataSystem: _action.payload.dataSystem,
            host: _action.payload.configuration.host,
            recordType: _action.payload.configuration.recordType,
            username: _action.payload.configuration.username,
            password: _action.payload.configuration.password,
            initiations: [emptyInitiation],
          };

          return ({
            ...state,
            configurations: [
              ...state.configurations.slice(0, _action.payload.index),
              {...currentConfig},
              ...state.configurations.slice(_action.payload.index + 1),
            ],
            currentConfig,
            requestParams: _action.payload.requestParams.map(param => ({label: param, value: param})),
            formDataParams: _action.payload.formDataParams.map(param => ({label: param, value: param})),
            isConnecting: false,
            isConnectionSuccessful: true,
          });
        },
        pending: () => ({...state, isConnecting: true, isConnectionSuccessful: null}),
        failure: () => ({...state, isConnecting: false, isConnectionSuccessful: false}),
      });
    case SAVE_DATA_SYNC_INITIATIONS:
      return api(_action, state, {
        success: () => ({...state, isLoading: false, currentConfig: null}),
        pending: () => ({...state, isLoading: true}),
        failure: () => ({...state, isLoading: false}),
      });
    case SET_CURRENT_CONFIG:
      return {...state, currentConfig: _action.config};
    case SET_IS_CONNECTION_SUCCESSFUL:
      return {...state, isConnectionSuccessful: _action.value};
    case SET_MESSAGE:
      return {...state, message: _action.message};
    case GET_PARAMS:
      return api(_action, state, {
        success: () => ({
          ...state,
          requestParams: _action.payload.requestParams.map(param => ({label: param, value: param})),
          formDataParams: _action.payload.formDataParams.map(param => ({label: param, value: param})),
        }),
        pending: () => ({...state}),
        failure: () => ({...state}),
      });
    case GET_FORM_DETAILS:
      return api(_action, state, {
        success: () => {
          const {formName, pages} = _action.payload.form;
          const pagesElements = pages.map(page => (page.rows.flatMap(row => row.elements.flatMap(el => el))));
          return {
            ...state,
            formDetails: {
              ...state.formDetails,
              formName,
              initiationSteps: [
                ...pagesElements.flatMap((pageElements, pageIndex) => ({
                  label: 'Completion of ' +
                      (pageElements
                        .find(el => el.elementType === 'heading' || el.elementType === 'sub_heading')
                        ?.label || `page ${(pageIndex + 1)}`),
                  value: `page_${pageIndex}`,
                  pageIndex,
                })).slice(0, -1),
                {label: `Completion of ${formName}`, value: 'submit'},
              ],
              formFields: pagesElements.flatMap((pageElements, pageIndex) =>
                pageElements.filter(el => fieldTypes.includes(el.elementType))
                  .map(el => ({label: el.label, value: el.id, pageIndex}))),
            },
          };
        },
        pending: () => ({...state}),
        failure: () => ({...state}),
      });
    case DELETE_CONFIGURATION:
      return api(_action, state, {
        success: () => ({...state}),
        pending: () => ({...state}),
        failure: () => ({...state}),
      });
    case LOADER_VISIBILITY:
      return {
        ...state,
        confirmationModal: {
          loaderVisibile: _action.visibility,
          loaderBody: _action.body,
          loaderTitle: _action.title,
          agreeText: _action.agreeText,
          disagreeText: _action.disagreeText,
          onClose: _action.onClose,
          onSubmit: _action.onSubmit,
        },
      };
    default:
      return state;
  }
}

export const getIsModalOpen = state => state.isModalOpen;
export const getInitialConfig = state => ({
  formFields: state.formDetails.formFields,
  initiationSteps: state.formDetails.initiationSteps,
  confirmationModal: state.confirmationModal,
  configurations: state.configurations,
  requestFields: state.requestParams,
  formDataFields: state.formDataParams,
  isLoading: state.isLoading,
  isConnecting: state.isConnecting,
  isConnectionSuccessful: state.isConnectionSuccessful,
  message: state.message,
});
export const getCurrentConfig = state => state.currentConfig;
