import React from 'react';
import { Link } from 'react-router';
import { Cell } from 'fixed-data-table-2';
import { WindowSize, getWindowSizeData } from 'components/WindowSize';
import styles from './FormsTable.css';
import { joinClassNames } from 'utils/strings';

import { FROM_CELL } from 'types/routing';

type Props = {
  folderId: string,
  formId: string,
  getFolderBreadcrumbs: any,
  isHovered: boolean,
  isSearchResult: boolean,
  // logSubmissionNavigation: LogSubmissionNavigationActionCreator,
  logSubmissionNavigation: any,
  // setFormDetails: Thunk,
  setFormDetails: any,
  submissionManagerRoute: string,
  submissionsCount: number,
};

class SubmissionCell extends React.Component<Props, {isMobile: boolean}> {
  constructor(props) {
    super(props);
    const { isMobile } = getWindowSizeData();
    this.state = { isMobile };
  }

  handleOnClick = submissionsCount => {
    this.props.setFormDetails();
    this.props.logSubmissionNavigation(FROM_CELL, submissionsCount);

    if (this.props.isSearchResult && this.props.folderId) {
      // We only need to get the breadcrumbs if the user navigates from search results and the form is in a folder
      this.props.getFolderBreadcrumbs(this.props.folderId);
    }
  }

  handleWindowSizeOnChange = ({ isMobile }) => {
    this.setState({ isMobile });
  };

  render() {
    const {
      formId,
      isHovered,
      submissionsCount,
      submissionManagerRoute,
    } = this.props;
    const { isMobile } = this.state;
    const submissionBlockClasses = joinClassNames(
      styles.submissionsBlock,
      isMobile ? '' : styles.submissionsBlockDesktop,
      isHovered ? styles.showSubmissionButton : ''
    );

    return (
      <Cell>
        <WindowSize onChange={this.handleWindowSizeOnChange} />
        <Link to={`${submissionManagerRoute}/${formId}`} className={submissionBlockClasses} onClick={() => this.handleOnClick(submissionsCount)}>
          <span
            className={submissionsCount > 0 ? styles.newSubmissions : styles.submissionsBlockZero}>
            {submissionsCount}
          </span>
          {!isMobile &&
          <div className={styles.submissionsLabelWrapper}>
            <span className={styles.submissionsLabel}>View</span>
          </div>
          }
        </Link>
      </Cell>
    );
  }
}

export default SubmissionCell;
