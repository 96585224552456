import React from 'react';
import _ from 'lodash';
import {sanitize} from 'dompurify';
import SignatureStatusCell from 'components/SubmissionTable/SignatureStatusCell';

export const textWithEllipsis = (textString: string, lengthBeforeEllipsis: number) =>
  (textString && textString.length > lengthBeforeEllipsis) ? textString.substring(0, lengthBeforeEllipsis) + '...' : (textString || '');

// being used in table cell: input type may not be string (maybe React element)
export const stripBrTags = (textString: string, separator: string = ' ') => {
  if (typeof textString !== 'string') {
    return textString;
  }
  let txt = textString;
  while (txt.includes('<br>') || txt.includes('<br />') || txt.includes('<br/>')) {
    txt = txt.replace(/\s*<br[^>]*>/, separator);
  }
  return txt;
};

export const extractSVGUrl = (fieldMap: any[]) => {
  if (
    fieldMap &&
    fieldMap[0] &&
    fieldMap[0].map &&
    fieldMap[0].map[0] &&
    fieldMap[0].map[0].input_name === 'png_url' &&
    fieldMap[0].map[0].raw_value) {
    return fieldMap[0].map[0].raw_value;
  }
  return '';
};

// check if  it's an SVG
export const isSVG = (textString: string): boolean => textString.indexOf('<svg ') === 0;
// check if it's a PNG
export const isPNG = (textString: string): boolean => textString.indexOf('data:image/png;base64,') === 0;

// This function sanitizes SVG/PNG content
export const sanitizeSVG = (svgContent: string) => sanitize(svgContent);
// This function handles SVG content
export const handlePNGOrSVGContent = (textString: string, isUsedInTableCell: boolean) => {
  const sanitizedSVG = sanitizeSVG(textString);
  if (!isSVG(textString) && sanitizedSVG) {
    return (
      <div style={{border: 'solid 1px black'}}>
        <img alt='' src={sanitizedSVG} width={isUsedInTableCell ? 56 : '100%'}/>
      </div>
    );
  }

  return (
    <div
      style={{
        width: `${isUsedInTableCell ? '60px' : '300px'}`,
        border: `${!isUsedInTableCell ? 'solid 1px black' : 'none'}`,
      }}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: sanitizedSVG }} />
    </div>
  );
};

// being used in table cell: input type may not be string (maybe React element)
export const cleanUpCellValue = (textString: string, fieldMap: any[], record) => {
  if (typeof textString !== 'string') {
    return textString;
  }
  // This avoids rendering a svg tag inside table for a canvas
  if (isSVG(textString)) {
    const canvasUrl = extractSVGUrl(fieldMap);
    if (canvasUrl) {
      return <img alt='' src={canvasUrl} height={56}/>;
    }
    const isTableCell = true;
    return handlePNGOrSVGContent(textString, isTableCell);
  }
  // This avoids rendering a span tag inside table for payment data
  if (textString.indexOf('<span class="Payment') === 0) {
    return '[payment]';
  }
  if (isPNG(textString)) {
    const sanitizedPNG = sanitizeSVG(textString);
    return <img alt='' src={sanitizedPNG} height={56}/>;
  }
  // This avoids rendering raw data:image data inside table for signature
  if (~textString.indexOf('data:image')) {
    return (
      <SignatureStatusCell
        signatureStatus='COMPLETE'
        totalCompletedSigners={record.signatureHistory.totalCompletedSigners}
        totalSigners={record.signatureHistory.totalSigners}/>
    );
  }
  // This avoids rendering raw signature json object inside table
  if (textString.indexOf('{"fullname":') === 0) {
    return '[signature]';
  }

  return textString;
};

export const hasSignatureData = textString =>
  (textString || '').indexOf('data:image') !== -1;

// for use in sidebar only (as opposed to being used in table cell)
// hence takes only string
export const normalizeSignatureData = textString => {
  const strippedOfBR = stripBrTags(textString, '|');
  if (typeof strippedOfBR === 'string') {
    const dataArray = strippedOfBR.split('|');
    if (dataArray && dataArray.length > 1) {
      return dataArray;
    }
  }
  return [];
};

interface Recipient {
  // eslint-disable-next-line camelcase
  decline_reason?: string,
  email?: string,
  fullname?: string,
  // eslint-disable-next-line camelcase
  recipient_info?: {email: string, fullname: string},
  // eslint-disable-next-line camelcase
  signer_key?: string,
}

export const getFullname = (recipient: Recipient) => decodeURIComponent(
  recipient.recipient_info && recipient.recipient_info.fullname
    ? recipient.recipient_info.fullname
    : (recipient.fullname || '')
);

export const getEmail = (recipient: Recipient) => decodeURIComponent(
  recipient.recipient_info && recipient.recipient_info.email
    ? recipient.recipient_info.email
    : (recipient.email || '')
);

export const getSignerKey = (recipient: Recipient) => (
  recipient.signer_key ? recipient.signer_key : null
);

export const getDeclineReason = (recipient: Recipient) => (
  recipient && recipient.decline_reason ? _.capitalize(recipient.decline_reason) : null
);
