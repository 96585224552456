import React from 'react';
import { noop } from 'lodash';
import User from 'components/Users/User';

import PopoverTypeahead from 'components/Typeaheads/PopoverTypeahead';
import TypeaheadInput from 'components/Users/UserTypeaheadInput';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  filterToAssigneesBySearchString,
  renderUser,
} from 'utils/assignees';
import classNames from './UserSelector.css';


const iconStyle = {
  cursor: 'pointer',
};
const addSelectionsButton = () => (
  <KeyboardArrowDownIcon style={iconStyle} />
);

class UserSelector extends React.Component<any, any> {
  static defaultProps = {
    shouldRepositionForMobile: true,
    placeholderText: 'Invalid User',
    isReadOnly: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      typeaheadResults: props.users,
    };
  }

  handleTypeaheadInput = query => {
    const {
      users,
    } = this.props;
    const typeaheadResults = filterToAssigneesBySearchString(users, query);
    this.setState({ typeaheadResults });
  }

  handleAddSelections = users => {
    const user = users[0];
    if (!user) return;
    this.props.onChangeUser(user);
  }

  render() {
    const {
      shouldRepositionForMobile,
      user,
      placeholderText,
      isReadOnly,
    } = this.props;
    return (
      <React.Fragment>
        {user && <User
          inPopover
          isReadOnly
          onClick={noop}
          selectionData={user} />}
        {!user && <span className={classNames.invalidUser}>{placeholderText}</span>}
        {!isReadOnly && <PopoverTypeahead
          allowPickingMultipleItems={false}
          isLoading={false}
          onAddSelections={this.handleAddSelections}
          onInputTypeahead={this.handleTypeaheadInput}
          renderSelection={renderUser()}
          shouldRepositionForMobile={shouldRepositionForMobile}
          ShowTypeaheadButton={addSelectionsButton}
          TypeaheadInput={TypeaheadInput}
          typeaheadResults={this.state.typeaheadResults} />
        }
      </React.Fragment>
    );
  }
}

export default UserSelector;
