import React from 'react';
// import {EditorState} from 'draft-js';
import ImageAlignCenter from 'icons/editor/image-align-center.raw.svg';
import ImageAlignDefault from 'icons/editor/image-align-default.raw.svg';
import ImageAlignLeft from 'icons/editor/image-align-left.raw.svg';
import ImageAlignRight from 'icons/editor/image-align-right.raw.svg';
import { IconButton } from './shared';


const imageAlignmentBlockTypes = [{
  Icon: props => <img src={ImageAlignDefault} alt='' {...props}/>,
  alignment: 'default',
  tooltip: 'Align Default',
}, {
  Icon: props => <img src={ImageAlignLeft} alt='' {...props}/>,
  alignment: 'left',
  tooltip: 'Align Left',
}, {
  Icon: props => <img src={ImageAlignCenter} alt='' {...props}/>,
  alignment: 'center',
  tooltip: 'Align Center',
}, {
  Icon: props => <img src={ImageAlignRight} alt='' {...props}/>,
  alignment: 'right',
  tooltip: 'Align Right',
}];

const getCurrentImageAlignment = editorState => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(selection.getStartKey());
  const entityKey = block.getEntityAt(0);
  if (entityKey) {
    const data = contentState.getEntity(entityKey).data || {};
    return data.alignment || null;
  }
  return null;
};

export default class ImageAlignment extends React.Component<any, any> {
  render() {
    const { editorState, onToggle } = this.props;
    const currentAlignment = getCurrentImageAlignment(editorState);
    return (
      <span>
        {
          imageAlignmentBlockTypes.map(({ Icon, tooltip, alignment }) => {
            const active = alignment === currentAlignment;
            const type = active ? 'default' : alignment;

            return (
              <IconButton
                key={alignment}
                active={active}
                Icon={Icon}
                onToggle={onToggle}
                tooltip={tooltip}
                type={type} />
            );
          })
        }
      </span>
    );
  }
}
