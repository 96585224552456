/*
* In SM, you can archive submissions with 2 actions:
* 1) click on a row and archive that submission
* 2) check the checkbox on one or multiple submissions, and archive it (or them)
* Hence, this archive function takes 2 arguments, 1) selectedRowData & 2) selectedRecords
* It checks whether 2) is null and if so, it assumes archiving by action #1, and vice versa
*/

export const archive = (
  recordIdKey: string,
  selectedRowData: any,
  activeData: any,
  selectedRecords: any[]
) => {
  let updatedData;
  const _selectedRecords = selectedRecords;
  if (_selectedRecords && _selectedRecords.length > 0) {
    updatedData = activeData.filter(data =>
      !_selectedRecords.some(record =>
        record[recordIdKey] === data[recordIdKey]));
  } else {
    updatedData = activeData.filter(data => data[recordIdKey] !== selectedRowData[recordIdKey]);
  }

  return {
    active: updatedData,
  };
};
