// These are not purposefully not exported because I don't want people to come
// along and find these and use them for non-assignee purposes (yet)
const assigneeGreen = '#5A9F49';
const assigneeGreenBackground = '#E2ECE0';
const assigneeBlue = '#498B9F';
const assigneeBlueBackground = '#E0EAED';
const assigneeBurntOrange = '#E09B15';
const assigneeBurntOrangeBackground = '#F7ECD8';
const assigneePurple = '#9F4987';
const assigneePurpleBackground = '#EDE0E8';
const assigneeBrown = '#D4825F';
const assigneeBrownBackground = '#F5EDE9';
const assigneeRoyalBlue = '#597FE1';
const assigneeRoyalBlueBackground = '#E2E8F7';
const assigneeRed = '#EA2E2E';
const assigneeRedBackground = '#F7DCDC';
const assigneeTeal = '#41C5BD';
const assigneeTealBackground = '#DFF3F1';
const assigneeBlack = '#2E4D6A';
const assigneeBlackBackground = '#E3E7EB';
const assigneeOrange = '#FC9962';
const assigneeOrangeBackground = '#FAFAFA';
const assigneeYellow = '#FFB906';
const assigneeYellowBackground = '#FAF1D6';
const assigneeForestGreen = '#43A37B';
const assigneeForestGreenBackground = '#DEECE7';
export const colors: string[] = [
  assigneeGreen,
  assigneeBlue,
  assigneeBurntOrange,
  assigneePurple,
  assigneeBrown,
  assigneeRoyalBlue,
  assigneeRed,
  assigneeTeal,
  assigneeBlack,
  assigneeOrange,
  assigneeYellow,
  assigneeForestGreen,
];
export const backgroundColors: string[] = [
  assigneeGreenBackground,
  assigneeBlueBackground,
  assigneeBurntOrangeBackground,
  assigneePurpleBackground,
  assigneeBrownBackground,
  assigneeRoyalBlueBackground,
  assigneeRedBackground,
  assigneeTealBackground,
  assigneeBlackBackground,
  assigneeOrangeBackground,
  assigneeYellowBackground,
  assigneeForestGreenBackground,
];
