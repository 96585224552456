import React from 'react';
import styles from './folder-tree.css';
import * as VFS from 'utils/vfs';

import folderIcon from 'icons/icon11.svg';

interface Props {
  tree: any,
}

export default class NodeDragPreview extends React.Component<Props, any> {
  render() {
    const {
      type,
      name,
    } = this.props.tree;

    let icon: JSX.Element | null = null;
    switch (type) {
      case VFS.VFS_DIR:
        icon = <img alt='' className={styles.icon} src={folderIcon} />;
        break;
      default:
        break;
    }

    return (
      <div className={styles.previewNode}>
        {icon}
        <span>{name}</span>
      </div>
    );
  }
}
