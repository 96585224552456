import React, {useState} from 'react';
import { connect } from 'react-redux';
import styles from 'containers/SubmissionsManager/Modals/BulkActionWrapper.css';
import AssigneesSelect from 'containers/SubmissionsManager/Components/AssigneesSelect';

const ReplaceAssignees = ({
  submissionIds,
  formId,
  replaceAssignees,
  dispatch,
  includeAllSubmissions,
  excludedSubmissionIds,
  options = [],
  loaderModalToggle,
  errorModalToggle,
}) => {
  const [replaceValue, setReplaceValue] = useState<any[]>([]);

  const handleReplaceAssignees = () => {
    replaceAssignees({
      submissionIds,
      formId,
      includeAllSubmissions,
      excludedSubmissionIds,
      userIds: replaceValue.map(user => user.userId),
      onError: () => errorModalToggle(true),
    }, dispatch);
  };

  const onReplaceClick = () => {
    const submissionsNumber = includeAllSubmissions ? 'all the' : `the ${submissionIds.length} selected`;
    loaderModalToggle(
      true,
      'replaceAssignees',
      'Replace Existing Assignees',
      `Are you sure you want to replace the existing assignees from ${submissionsNumber} submission(s)?`,
      handleReplaceAssignees,
    );
  };

  return (<div className={styles.replaceContainer}>
    <label htmlFor='find' className={styles.label}>Replace existing assignees with:</label>
    <div className={styles.controlsContainer}>
      <div className={styles.tagsInput}>
        <AssigneesSelect options={options} selected={replaceValue} setSelected={setReplaceValue} multiple/>
      </div>
      <button type='button' className={styles.replaceButton} onClick={onReplaceClick} disabled={!replaceValue.length}>Replace</button>
    </div>
  </div>);
};

export default connect(null, dispatch => ({
  dispatch,
}))(ReplaceAssignees);
