import { URL_REGEX } from 'constants/validations';

const validate = (type: string, value: string = '', regex: string = ''): boolean => {
  // eslint-disable-next-line max-len
  const email = val => val.length > 0 && val.match(/^[a-zA-Z0-9\.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
  const phone = val => val.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
  const number = val => val.match(/^[-+]?\d*\.?\d+$/);
  const dollar = val => val.match(/^\$(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$/);
  const digit = val => val.match(/^[-+]?[0-9]+$/);
  const alpha = val => val.match(/^[a-z ._-]+$/i);
  const alphanum = val => val.match(/^[a-z0-9 ._-]+$/i);
  const url = val => val.match(URL_REGEX);
  const custom = val => {
    // Handle regex with boundaries
    const pattern = regex[0] === '/' ? regex.slice(1, regex.lastIndexOf('/')) : regex;
    return val.match(new RegExp(pattern));
  };

  switch (type) {
    case 'email': return email(value);
    case 'phone': return phone(value);
    case 'number': return number(value);
    case 'dollar': return dollar(value);
    case 'digit': return digit(value);
    case 'alpha': return alpha(value);
    case 'alphanum': return alphanum(value);
    case 'url': return url(value);
    case 'custom': return custom(value);
    default: return false;
  }
};

export default validate;
