import React from 'react';
import _ from 'lodash';
// import * as VFS from 'utils/vfs';
import cssStyle from './folder-tree.css';
import qfStyles from 'components/QuickFilters/QuickFilters.css';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { LOBBY_ROOT_FOLDER } from 'constants/tableConsts';
import { isAncestor, isNameCollision, isChild } from 'utils/folderHelper';
import { joinClassNames } from 'utils/strings';
import * as FormTypeIcon from 'components/FormsTable/FormTypeIcon';

import folderIcon from 'icons/icon11.svg';
import switcherIcon from 'icons/icon79.svg';
import selectedFolderIcon from 'icons/icon12.svg';
import deleteIcon from 'icons/icon62.svg';
import archiveIcon from 'icons/archiveFolder.svg';

export const folderItemType = 'TREE_NODE';
export const formItemType = FormTypeIcon.itemType; // 'FORM_ITEM'

const onlyWhitespace = /^\s*$/;
const isOnlyWhiteSpace = string => onlyWhitespace.test(string);

const acceptedItemTypes = [
  folderItemType,
  formItemType,
];


const source = {
  beginDrag(props) {
    const { onClick, tree, path } = props;
    onClick && onClick(tree, path, true);
    return {
      tree,
      path,
      itemType: folderItemType,
      editable: false,
    };
  },
  canDrag(props) {
    const {
      loading,
      folderId,
    } = props.tree.meta;
    return props.draggable && !loading && folderId !== LOBBY_ROOT_FOLDER;
  },
};

const dropTarget = {
  drop(props, monitor) {
    if (monitor && !monitor.didDrop()) {
      // drop hasn't been handled already by a nested target
      const { onDrop } = props;
      onDrop && onDrop(monitor.getItem(), props);
    }
  },
  canDrop(props, monitor) {
    const targetDir = props.tree;

    if (monitor) {
      const _itemType = monitor.getItemType();

      switch (_itemType) {
        case folderItemType:
          const draggedDir = monitor.getItem().tree;
          if (
            isAncestor(draggedDir, targetDir) ||
            isChild(draggedDir, targetDir) ||
            isNameCollision(draggedDir, targetDir)
          ) {
            return false;
          }
          break;
        case formItemType:
          const draggedForm = monitor.getItem().rowData;
          const sourceFolderId = draggedForm.folderId;
          const targetFolderId = targetDir.meta.folderId;
          return !(sourceFolderId === targetFolderId);
        default:
          break;
      }
    }

    return true;
  },
};

const dragCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

function dropCollect(connect, monitor) {
  return {
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    itemType: monitor.getItemType(),
  };
}

class Node extends React.Component<any, any> {
  static defaultProps = {
    editable: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      newName: this.props.tree.name,
      hoverTreeName: false,
    };
  }

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isSelected !== this.props.isSelected) {
      this.setState({ editable: false });
    }
  }

  createFolderButton = () => (
    <button className={cssStyle.lobbyAction} onClick={this.onClickCreateFolder}>
      +
    </button>
  );

  editingFinished = () => {
    this.setState({ editable: false });
    const { newName } = this.state;
    if (!isOnlyWhiteSpace(newName)) {
      const { onNameChange, tree } = this.props;
      onNameChange && onNameChange(tree, newName);
    }
  }

  _input;

  onClick = () => {
    const {
      onClick,
      tree,
      path,
    } = this.props;

    this.setState({
      hoverTreeName: false,
    });

    onClick && onClick(tree, path, false);
  };

  onClickCreateFolder = e => {
    e.stopPropagation();
    this.props.onCreateFolder();
  }

  onDoubleClick = () => {
    this.props.editable && this.setState({ editable: true }, () => {
      this._input && this._input.select();
    });
  };

  onKeyPress = e => {
    if (e.key === 'Enter') this.onClick();
  }

  onNameChange = event => {
    this.setState({ newName: event.target.value });
  };

  onReturnToInitialState = () => {
    this.setState({
      editable: false,
      newName: this.props.tree.name,
    });
  };

  onEditKeyPress = event => {
    if (event.key === 'Tab' || event.key === 'Escape') {
      event.preventDefault();
      this.onReturnToInitialState();
    }
    if (event.key === 'Enter') {
      this.editingFinished();
    }
  };

  onExpand = e => {
    e.stopPropagation();
    const {
      onExpand,
      tree,
      path,
    } = this.props;
    onExpand && onExpand(tree, path);
  };

  onCollapse = e => {
    e.stopPropagation();
    const {
      onCollapse,
      tree,
      path,
    } = this.props;
    onCollapse && onCollapse(tree, path);
  };

  handleDeleteFolder = e => {
    e.stopPropagation();
    this.props.onDeleteFolder();
  };

  handleHoverNameTree = event => {
    const { top, bottom, left, right } = (this.refs.treeText as any).getBoundingClientRect();
    const mouseY = event.pageY;
    const mouseX = event.pageX;

    const inRangeY = _.inRange(mouseY, top, bottom);
    const inRangeX = _.inRange(mouseX, left, right);

    this.setState({
      hoverTreeName: inRangeX && inRangeY,
    });
  }

  handleHoverNameTreeLeave = () => {
    this.setState({
      hoverTreeName: false,
    });
  }

  render() {
    const {
      canDrop,
      className,
      connectDragSource,
      connectDropTarget,
      indent,
      isDragging,
      isOver,
      isSelected,
      tree,
    } = this.props;

    const style: any = {
      paddingLeft: indent,
      width: `calc(100% - ${indent}px - 1px)`,
    };

    if (isDragging) style.visibility = 'hidden';

    const {
      onClick,
      onExpand,
      onCollapse,
    } = this;

    let switcher: JSX.Element | null = null;
    if (tree.meta.loading) {
      switcher = <i className={joinClassNames(cssStyle.switcher, cssStyle.switchLoading)} />;
    } else {
      const hasSwitcher = tree.meta.subfolderCount || (tree.data && Object.keys(tree.data).length > 0);
      if (hasSwitcher) {
        if (tree.meta.collapsed) {
          switcher = (
            <button onClick={onExpand} className={joinClassNames(cssStyle.switcher, cssStyle.switcherClose)}>
              <img alt={`Expand subfolders for ${tree.name}`} src={switcherIcon} />
            </button>
          );
        } else {
          switcher = (
            <button onClick={onCollapse} className={joinClassNames(cssStyle.switcher, cssStyle.switcherOpen)}>
              <img alt={`Collapse subfolders for ${tree.name}`} src={switcherIcon} />
            </button>
          );
        }
      } else {
        switcher = <i className={cssStyle.switcher} />;
      }
    }

    let iconSrc;
    let altText;
    if (tree.meta.isArchive) {
      iconSrc = archiveIcon;
      altText = 'Archive';
    } else {
      iconSrc = isSelected ? selectedFolderIcon : folderIcon;
      altText = isSelected ? 'Currently selected' : 'Select this folder';
    }
    const icon = <img className={cssStyle.folderIcon} alt={altText} src={iconSrc} />;

    let text: JSX.Element | null = null;
    if (isSelected) {
      if (this.state.editable && tree.parent) {
        text = (
          <input
            tabIndex={-1}
            type='text'
            ref={c => { this._input = c; }}
            className={cssStyle.nodeSelected}
            onBlur={this.editingFinished}
            onKeyDown={this.onEditKeyPress}
            onChange={this.onNameChange}
            value={this.state.newName} />
        );
      } else {
        text = (
          <div
            className={joinClassNames(cssStyle.node, cssStyle.nodeSelected)}
            onDoubleClick={this.onDoubleClick}>
            {tree.name}
          </div>
        );
      }
    } else {
      /**
       * There is an issue with Chromium where the hover state
       * gets stuck after dropping an element. The styling and
       * mouse events below are a work around for that.
       */
      text = (
        <div
          className={cssStyle.node}
          onMouseOver={this.handleHoverNameTree}
          onMouseLeave={this.handleHoverNameTreeLeave}
          ref='treeText'>
          {tree.name}
        </div>
      );
    }

    const nodeClasses = joinClassNames(
      className,
      qfStyles.quickFilterAppearance,
      (isOver && canDrop ? cssStyle.dropOverNode : '')
    );

    const folderArchiveIcon = (
      <button
        className={joinClassNames(cssStyle.delete, cssStyle.iconSmall, cssStyle.iconDelete)}
        onClick={this.handleDeleteFolder}>
        <img alt='Delete folder' src={deleteIcon} />
      </button>
    );

    const result = connectDropTarget(
      connectDragSource(
        <div className={nodeClasses} style={style} onClick={onClick}>
          {switcher}
          <div tabIndex={0} className={cssStyle.folderLabel} onKeyPress={this.onKeyPress}>
            {icon}
            {text}
          </div>
          {this.props.onCreateFolder && this.props.canEditFolderTree && this.createFolderButton()}
          {(isSelected && !!tree.parent && this.props.canEditFolderTree) && folderArchiveIcon}
        </div>
      )
    );
    return result === undefined ? null : result;
  }
}

export default DropTarget(
  acceptedItemTypes,
  dropTarget,
  dropCollect
)(DragSource(folderItemType, source, dragCollect)(Node));
