import React, { FC, memo } from 'react';
import style from './ErrorContainer.css';
import classNames from 'classnames';

type Props = {
  error: string;
  children: React.ReactNode;
  id?: string;
};

const ErrorContainer: FC<Props> = memo(({ children, error, id }) => {
  const hasError = !!error;

  return (
    <div className={classNames({ [style.ErrorContainer]: hasError }, 'error_container')}>
      {children}
      {hasError && (
        <div
          className={classNames(style.Error, { [style.hide]: !hasError })}
          id={id}
          aria-live='polite' // Use 'polite' to avoid interrupting the screen reader
          aria-atomic='true'
          role='alert'
        >
          {error}
        </div>
      )}
    </div>
  );
});

export default ErrorContainer;

