import {
  keyBy,
  map,
  mapValues,
} from 'lodash';
import {
  defaultMemoize,
} from 'reselect';
import {
  NEW_TABLE_API,
  UPDATE_TABLE_API,
  GET_PER_FORM_CAPABILITIES,
  GET_CAN_UPDATE_SETTINGS,
  NEW_TABLE_SEARCH_API,
  UPDATE_TABLE_SEARCH_API,
} from 'constants/types/lobbyTableActionTypes';
import {
  ARCHIVE_FORM_API,
} from 'constants/types/lobby';
import {
  ALL_FILTER_INITIAL_LOAD,
  ALL_FILTER_SUBSEQUENT_LOAD,
  ASSIGNED_TO_ME_FILTER_INITIAL_LOAD,
  ASSIGNED_TO_ME_FILTER_SUBSEQUENT_LOAD,
  DRAFTS_FILTER_INITIAL_LOAD,
  DRAFTS_FILTER_SUBSEQUENT_LOAD,
  SHARED_FILTER_INITIAL_LOAD,
  SHARED_FILTER_SUBSEQUENT_LOAD,
  SIGNED_BY_ME_FILTER_INITIAL_LOAD,
  SUBMITTED_FILTER_INITIAL_LOAD,
  SUBMITTED_FILTER_SUBSEQUENT_LOAD,
} from 'constants/types/quickFilterActionTypes';
import {SAVE_PERMISSIONS} from 'constants/permissions';
import {ARCHIVE_SUBMISSION_API} from 'constants/types/submissionsTableActionTypes';

import api from 'reducers/api';


import {formCapabilitiesEnum} from 'constants/tableConsts';

const initialState = Object.freeze({});

export const checkFormCapability = defaultMemoize(
  (state, formId, capability) =>
    !!(state[formId] && state[formId].capabilities && state[formId].capabilities.includes(capability))
);

const updateCapabilities = (state, action) => {
  switch (action.type) {
    case GET_PER_FORM_CAPABILITIES:
      return api(action, state, {
        success: () => {
          // Per form capabilities and can update settings can return in any order.
          // Preserve 'EditPermissions' if it is there.
          const formsWithCapabilities = mapValues(action.payload, (
            capabilities,
            formId,
          ) => {
            const includeEditPermissions = checkFormCapability(state, formId, formCapabilitiesEnum.EditPermissions);
            const fullCapabilities = capabilities.concat(
              includeEditPermissions ? [formCapabilitiesEnum.EditPermissions] : []
            );
            return {
              ...state[formId],
              capabilities: fullCapabilities,
            };
          });
          return {
            ...state,
            ...formsWithCapabilities,
          };
        },
        pending: () => state,
        failure: () => state,
      });
    case GET_CAN_UPDATE_SETTINGS:
      return api(action, state, {
        success: () => {
          const formsWithCapabilities = map(action.payload, ({canUpdate, formId}) => {
            const alreadyHasEditPermission = checkFormCapability(state, formId, formCapabilitiesEnum.EditPermissions);
            const isNoop = (canUpdate === alreadyHasEditPermission);
            if (isNoop) {
              return state[formId];
            }

            const currentCapabilities = (state[formId] && state[formId].capabilities) ? state[formId].capabilities : [];
            if (canUpdate) {
              return {
                ...state[formId],
                capabilities: currentCapabilities.concat([formCapabilitiesEnum.EditPermissions]),
                formId,
              };
            }

            return {
              ...state[formId],
              capabilities: currentCapabilities.filter(
                capability => capability !== formCapabilitiesEnum.EditPermissions,
              ),
              formId,
            };
          });
          const formsKeyedById = keyBy(formsWithCapabilities, 'formId');
          return {
            ...state,
            ...formsKeyedById,
          };
        },
      });
    default:
      return state;
  }
};

const processLocalUpdates = (state, action) => {
  switch (action.type) {
    case ARCHIVE_FORM_API:
      return api(action, state, {
        success: () => {
          const {
            isUnarchiving,
            selectedRowData: {formId}} = action.payload;
          const formToUpdate = state[formId];
          if (formToUpdate) {
            const updatedForm = {
              ...state[formId],
              isActive: isUnarchiving,
            };
            return {
              ...state,
              [formId]: updatedForm,
            };
          }
          return state;
        },
      });
    case SAVE_PERMISSIONS:
      return api(action, state, {
        success: () => {
          const {
            formId,
            authorName,
          } = action.payload;
          const formToUpdate = state[formId];
          if (formToUpdate) {
            const updatedForm = {
              ...state[formId],
              formAuthorName: authorName,
            };
            return {
              ...state,
              [formId]: updatedForm,
            };
          }
          return state;
        },
      });
    case ARCHIVE_SUBMISSION_API:
      return api(action, state, {
        success: () => {
          const {selectedRecords, formId} = action.payload;
          const formToUpdate = state[formId];
          if (formToUpdate && formToUpdate.numSubmissions) {
            const delta = selectedRecords.length > 0 ? selectedRecords.length : 1;
            const updatedForm = {
              ...formToUpdate,
              numSubmissions: formToUpdate.numSubmissions - delta,
            };
            return {
              ...state,
              [formId]: updatedForm,
            };
          }
          return state;
        },
      });
    default:
      return state;
  }
};

const quickFilterLoad = (state, action) => {
  switch (action.type) {
    case ALL_FILTER_INITIAL_LOAD:
    case ALL_FILTER_SUBSEQUENT_LOAD:
    case ASSIGNED_TO_ME_FILTER_INITIAL_LOAD:
    case ASSIGNED_TO_ME_FILTER_SUBSEQUENT_LOAD:
    case DRAFTS_FILTER_INITIAL_LOAD:
    case SIGNED_BY_ME_FILTER_INITIAL_LOAD:
    case DRAFTS_FILTER_SUBSEQUENT_LOAD:
    case SHARED_FILTER_INITIAL_LOAD:
    case SHARED_FILTER_SUBSEQUENT_LOAD:
    case SUBMITTED_FILTER_INITIAL_LOAD:
    case SUBMITTED_FILTER_SUBSEQUENT_LOAD:
      return api(action, state, {
        success: () => {
          const payload = action.payload;
          const formsFromPayload = payload.tableData;
          const keyedById = keyBy(formsFromPayload, 'formId');
          const mergedWithState = mapValues(
            keyedById,
            (form, formId) => {
              if (state[formId]) {
                return {
                  ...state[formId],
                  ...form,
                };
              }
              return form;
            });
          return {
            ...state,
            ...mergedWithState,
          };
        },
      });
    default:
      return state;
  }
};

export default (state = initialState, action_) => {
  const action = action_;
  switch (action.type) {
    case GET_PER_FORM_CAPABILITIES:
    case GET_CAN_UPDATE_SETTINGS:
      return updateCapabilities(state, action);
    case NEW_TABLE_API:
    case UPDATE_TABLE_API:
    case NEW_TABLE_SEARCH_API:
    case UPDATE_TABLE_SEARCH_API:
      return api(action, state, {
        success: () => {
          const payload = action.payload;
          const formsFromPayload = payload.tableData;
          const keyedById = keyBy(formsFromPayload, 'formId');
          const mergedWithState = mapValues(keyedById, (form, formId) => {
            if (state[formId]) {
              return {
                ...state[formId],
                ...form,
              };
            }
            return form;
          });
          return {
            ...state,
            ...mergedWithState,
          };
        },
      });
    case ALL_FILTER_INITIAL_LOAD:
    case ALL_FILTER_SUBSEQUENT_LOAD:
    case ASSIGNED_TO_ME_FILTER_INITIAL_LOAD:
    case ASSIGNED_TO_ME_FILTER_SUBSEQUENT_LOAD:
    case DRAFTS_FILTER_INITIAL_LOAD:
    case SIGNED_BY_ME_FILTER_INITIAL_LOAD:
    case DRAFTS_FILTER_SUBSEQUENT_LOAD:
    case SHARED_FILTER_INITIAL_LOAD:
    case SHARED_FILTER_SUBSEQUENT_LOAD:
    case SUBMITTED_FILTER_INITIAL_LOAD:
    case SUBMITTED_FILTER_SUBSEQUENT_LOAD:
      return quickFilterLoad(state, action);
    case ARCHIVE_FORM_API:
    case ARCHIVE_SUBMISSION_API:
    case SAVE_PERMISSIONS:
      return processLocalUpdates(state, action);
    default:
      (action);
      return state;
  }
};

export const getFormsForLobbyById = defaultMemoize(
  (state, formIds) => {
    const forms = formIds.map(formId => state[formId]).filter(form => !!form);
    return forms;
  },
);
