import moment from 'moment-timezone';
import jstz from 'jstimezonedetect';
import {
  DATE_FORMAT_LONG,
  DATE_FORMAT_SHORT,
  TIME_FORMAT_LONG,
  TIME_FORMAT_SHORT,
} from 'constants/dateTime';

let userTimezone = jstz.determine().name();

export function getFormattedTime(date: moment.MomentInput, format: string) {
  return moment(date).tz(userTimezone).format(format);
}

const momentFromShortDate = (string: string) => moment(string, DATE_FORMAT_SHORT, true);

export function isValidShortDate(string: string) {
  return momentFromShortDate(string).isValid();
}

export function fromShortDate(string: string) {
  return momentFromShortDate(string).toDate();
}

// check if date2 is after date1 (or offset more time after date1)
export const compareDates = (date1: moment.MomentInput, date2: moment.MomentInput, offset: any) =>
  moment(date1) + (offset || 0) < moment(date2);

export const compareUnixDates = (date1: string, date2: string, offset: any) =>
  moment.unix(parseInt(date1, 10)) + (offset || 0) < moment.unix(parseInt(date2, 10));

export const getFormattedTimeFromUnix = (date: string, format: string) =>
  moment.unix(parseInt(date, 10)).tz(userTimezone).format(format);

export const setUserTimezone = (timezone: any) => {
  if (timezone) {
    userTimezone = timezone;
  }
};

export {
  DATE_FORMAT_LONG,
  DATE_FORMAT_SHORT,
  TIME_FORMAT_LONG,
  TIME_FORMAT_SHORT,
  userTimezone,
};
