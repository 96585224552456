const setInputValueNatively = (element: HTMLElement, sourceFieldText: string): void => {
  const nativeInputValueSetter = Object
    .getOwnPropertyDescriptor(element.constructor.prototype, 'value')?.set;
  nativeInputValueSetter?.call(element, sourceFieldText);
  element.dispatchEvent(new Event('input', { bubbles: true }));
};

export const fillDataSyncFields = (initiation, result = null, formState): any => {
  let targetFields = {};
  initiation.formDataMappings.forEach(formDataMapping => {
    const element: HTMLElement | null = document.getElementById(formDataMapping.targetField);
    const sourceFieldText: string = result ? result![formDataMapping.sourceField] : '';
    if (!!element) {
      setInputValueNatively(element, sourceFieldText);
    } else {
      targetFields = {
        ...targetFields, [formDataMapping.targetField]: {
          ...formState[formDataMapping.targetField],
          fields: {
            ...formState[formDataMapping.targetField]?.fields,
            text: sourceFieldText,
          },
        },
      };
    }
  });
  return targetFields;
};

export const getInitiationsForCurrentStep = (initiations, initiationStep) => initiations
  .filter(init => (init.initiationStep === initiationStep && init.isEnabled));
