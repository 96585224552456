import React from 'react';
import Tooltip from 'components/Tooltip';

import { joinClassNames } from 'utils/strings';
import Bubble from './Bubble';
import Details from './Details';
import styles from './User.css';


class User extends React.Component<any, any> {
  static defaultProps = {
    inPopover: false,
    isMultiplePicked: false,
    isReadOnly: false,
    onKeyDown: () => { },
    onRemove: () => { },
    onSelect: () => { },
    selected: false,
    isSelectedByKeyboard: false,
    wideBubble: false,
    tabIndex: '',
  };

  handleOnClick = e => {
    e.stopPropagation();
    const { selectionData, isReadOnly, onRemove, onSelect, selected } = this.props;
    if (isReadOnly) return;
    if (selected) {
      onRemove(selectionData);
      return;
    }
    onSelect(selectionData);
  };

  render() {
    const {
      selectionData: user,
      inPopover: detailed,
      isReadOnly,
      selected,
      isMultiplePicked,
      isSelectedByKeyboard,
      wideBubble,
      onKeyDown,
      tabIndex,
      customClassName,
    } = this.props;
    const wide = wideBubble && !detailed;
    const inTooltip = !detailed;
    const content = (
      <div
        className={
          joinClassNames(
            styles.container,
            detailed ? styles.detailed : '',
            isReadOnly ? styles.readonly : '',
            selected ? styles.selected : '',
            isMultiplePicked ? styles.isMultiplePicked : '',
            isSelectedByKeyboard ? styles.isHover : '',
            customClassName ? customClassName : ''
          )
        }
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
        onClick={!wide ? this.handleOnClick : undefined}
        aria-label={user.fullName}>
        <Bubble
          wide={wide}
          onCrossmarkClick={wide ? this.handleOnClick : undefined}
          user={user}
          hasHoverInteraction={!detailed} />
        {!inTooltip && <Details user={user} />}
      </div>
    );

    if (inTooltip) {
      return (
        <Tooltip title={<Details user={user} />}>
          {content}
        </Tooltip>
      );
    }
    return content;
  }
}

export default User;
