import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LinkToNewTab from 'components/LinkToNewTab';
import {
  LEGACY_ACCOUNT_OVERVIEW,
  LEGACY_USERS,
  LEGACY_API_KEYS,
  LEGACY_COMPANY,
  LEGACY_PROFILE,
  LEGACY_UNIVERSITY,
} from 'constants/route';
import * as routing from 'utils/routing';
import styles from 'styles/shared/links.css';
import headerStyles from './header.css';
import injectPermissions from 'decorators/injectPermissions';
import injectFeatures from 'decorators/injectFeatures';
import { loadTooltip, startLobbyTour } from 'utils/tooltipIo';
import { userRolesEnum } from 'constants/user';


class FormCenterMenuItem extends React.Component<any, any> {
  render() {
    const { onClick } = this.props;
    return (
      <LinkToNewTab href={routing.formCenter()} className={styles.unstyled} onClick={onClick}>
        <MenuItem classes={{ root: headerStyles.accountMenuItem }}>Form Center</MenuItem>
      </LinkToNewTab>
    );
  }
}


export class AccountMenuPopover extends React.Component<any, any> {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.features.TOOLTIP_IO_TOUR_SUPPORT && !(window as any).Tooltip) {
      loadTooltip();
    }
  }

  triggerLobbyTour = () => {
    const { onRequestClose } = this.props;
    startLobbyTour();
    onRequestClose();
  };

  render() {
    const { anchorEl, features, onFormCenter, onRequestClose, onLogout, open, name, role, subdomain } = this.props;

    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
        getContentAnchorEl={null} // We get a warning and cannot use anchorOrigin without this
        onClose={onRequestClose}
        open={open}
        classes={{
          paper: headerStyles.accountMenuPopover,
        }}
        MenuListProps={{
          disablePadding: true,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          classes={{
            root: headerStyles.accountMenuHeaderItem,
          }}
          disabled
        >
          {name}
        </MenuItem>
        {this.props.canUserAccessAccountDetails() && (
          <a key='accountOverview' href={LEGACY_ACCOUNT_OVERVIEW} className={styles.unstyled} onClick={onRequestClose}>
            <MenuItem classes={{ root: headerStyles.accountMenuItem }}>Account Overview</MenuItem>
          </a>
        )}
        {this.props.canUserAccessUserList() && (
          <a key='users' href={LEGACY_USERS} className={styles.unstyled} onClick={onRequestClose}>
            <MenuItem classes={{ root: headerStyles.accountMenuItem }}>Users</MenuItem>
          </a>
        )}
        {this.props.canUserAccessApiKeys() && (
          <a key='apikeys' href={LEGACY_API_KEYS} className={styles.unstyled} onClick={onRequestClose}>
            <MenuItem classes={{ root: headerStyles.accountMenuItem }}>API Keys</MenuItem>
          </a>
        )}
        {this.props.canUserAccessAccountDetails() && (
          <a key='company' href={LEGACY_COMPANY} className={styles.unstyled} onClick={onRequestClose}>
            <MenuItem classes={{ root: headerStyles.accountMenuItem }}>Company</MenuItem>
          </a>
        )}
        <a key='profile' href={LEGACY_PROFILE} className={styles.unstyled} onClick={onRequestClose}>
          <MenuItem classes={{ root: headerStyles.accountMenuItem }}>Profile</MenuItem>
        </a>
        {role !== userRolesEnum.VISITOR && (
          <a key='university' href={LEGACY_UNIVERSITY} className={styles.unstyled} onClick={onRequestClose}>
            <MenuItem classes={{ root: headerStyles.accountMenuItem }}>University</MenuItem>
          </a>
        )}

        {this.props.canUserAccessGRM() && (features.GRM_WITH_SC || features.GRM_WITHOUT_SC) && (
          <FormCenterMenuItem subdomain={subdomain} onClick={onFormCenter} />
        )}

        {features.TOOLTIP_IO_TOUR_SUPPORT && location.pathname === '/ng/lobby' && (
          <button key='tour' className={headerStyles.accountMenuButton} onClick={this.triggerLobbyTour}>
            <MenuItem classes={{ root: headerStyles.accountMenuItem }}>Quick Guide</MenuItem>
          </button>
        )}
        <MenuItem onClick={onLogout} classes={{ root: headerStyles.accountMenuItem }}>
          Log Out
        </MenuItem>
      </Menu>
    );
  }
}

export default injectPermissions(injectFeatures(AccountMenuPopover));
