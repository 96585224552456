import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';


const buttonStyles = {
  position: 'absolute',
  backgroundColor: 'transparent',
  bottom: '1.3rem',
  right: '0.2rem',
};

export default class NewShowPasswordField extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { showPassword: false };
  }

  toggleShowPassword = e => {
    e.preventDefault();
    this.setState(({ showPassword }) => ({ showPassword: !showPassword }));
  };

  render() {
    const { showPassword } = this.state;
    const {
      className,
      style,
      label,
      id = 'password',
      name = 'password',
      onChange,
      value,
      errorMessage = '',
      inputClassName = '',
      inputProps = {},
    } = this.props;
    return (
      <div className={className} style={style}>
        <label htmlFor={id}>{label}</label><br />
        <input
          className={inputClassName}
          value={value}
          onChange={onChange}
          type={showPassword ? 'text' : 'password'}
          id={id || 'password'}
          name={name || 'password'}
          {...inputProps}
        />
        <div className='inputError'>{errorMessage}</div>
        <IconButton tabIndex={-1} role='password visibility' style={buttonStyles as any} onClick={this.toggleShowPassword}>
          <Icon>{showPassword ? 'visibility_off' : 'visibility'}</Icon>
        </IconButton>
      </div>
    );
  }
}
