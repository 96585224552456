import React from 'react';
import SelectedStage from 'components/Stages/Stage/SelectedStage';
import SelectedQuickFilter from 'components/QuickFilters/SelectedQuickFilter';
import { ALL_VIRTUAL_STAGE_NAME, ALL_VIRTUAL_STAGE_COLOR } from 'constants/stages';
import {
  openFiltersModal as openFiltersModalAction,
  unsortSubmissions as unsortSubmissionsAction,
} from 'actions/submissionsManager/tableApi';
import icon17 from 'icons/icon17.svg';
import filter from 'icons/submissions/filter.svg';
import styles from './CurrentFilterBar.css';
// import type { ClearQuickFilterAction } from 'types/quickFilters';
import { Stage } from 'types/stages';

export type PropsFromState = {
  isFiltered: boolean;
  isSorted: boolean;
  selectedQuickFilter: string | null | undefined,
  shouldRender: boolean,
  stage: Stage | null | undefined,
  submissionCount: number,
  isSortingAndFilteringEnabled: boolean,
};

export type PropsFromDispatch = {
  // clearQuickFilter: () => ClearQuickFilterAction,
  clearQuickFilter: () => any,
  openFiltersModal: typeof openFiltersModalAction,
  unsortSubmissions: typeof unsortSubmissionsAction,
};

export type Props = PropsFromState & PropsFromDispatch;


class CurrentFilterBar extends React.PureComponent<Props, {}> {
  renderQuickFilter = (selectedFilter: string) => {
    const {
      clearQuickFilter,
    } = this.props;
    return (
      <SelectedQuickFilter
        onRemove={clearQuickFilter}
        selectedFilter={selectedFilter} />
    );
  }

  renderSelectedStage = () => {
    const {
      stage,
    } = this.props;
    const selectedStage = stage || {
      name: ALL_VIRTUAL_STAGE_NAME,
      color: ALL_VIRTUAL_STAGE_COLOR,
    };
    return (
      <SelectedStage
        stage={selectedStage} />
    );
  }

  renderFilterIcon = () =>
    <button
      className={styles.iconButton}
      onClick={this.handleClickFilter}
      aria-label='Click to see applied filters'
      title='Click to see applied filters'
    >
      <img src={filter} alt='Currently Filtered' className={styles.filterIcon} />
    </button>

  renderSortIcon = () =>
    <button
      className={styles.iconButton}
      onClick={() => this.props.unsortSubmissions()}
      aria-label='Click to remove sorting'
      title='Click to remove sorting'
    >
      <img src={icon17} alt='' className={styles.sortIcon} />
    </button>

  handleClickFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.props.openFiltersModal();
    e.stopPropagation();
  }

  render() {
    const {
      isFiltered,
      isSorted,
      selectedQuickFilter,
      shouldRender,
      submissionCount,
      isSortingAndFilteringEnabled,
    } = this.props;
    if (!shouldRender) return null;
    return (
      <div className={styles.filterBar}>
        {selectedQuickFilter ? this.renderQuickFilter(selectedQuickFilter) : this.renderSelectedStage()}
        <span className={styles.count}>{submissionCount}</span>
        {isSortingAndFilteringEnabled && isFiltered && this.renderFilterIcon()}
        {isSortingAndFilteringEnabled && isSorted && this.renderSortIcon()}
      </div>
    );
  }
}

export default CurrentFilterBar;
