import React from 'react';
import {connect} from 'react-redux';
import MultiSelector from './shared/MultiSelector';


const EmailFieldsPicker = ({values, onChange, emailFields}) => (
  <MultiSelector
    values={values}
    items={emailFields}
    onChange={onChange}
    addButtonCaption='Add email field'
    removeCaption='Remove email field'/>
);

const mapStateToProps = ({emailFields}, {formId}) => ({
  emailFields: emailFields[formId] || [],
});

export default connect(mapStateToProps)(EmailFieldsPicker);
