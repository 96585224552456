/*
 * This reducer is meant for embedding in other reducers that perform API actions
 */

export default function genApi(action, state: any,
  {pending, success, failure}: {pending?: Function, success?: Function, failure?: Function}) {
  switch (action.status) {
    case 'pending':
      return pending ? pending() : state;
    case 'success':
      return success ? success() : state;
    case 'failure':
      return failure ? failure() : state;
    default:
      return state;
  }
}
