import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import FormInput from '../FormInput/FormInput';
import style from './Entries.css';
import sharedStyle from '../shared.css';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { ControlState, LabelAlignment } from 'types/liveView';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';

export type Props = {
  id: string,
  label?: string,
  fieldState?: ControlState | null,
  updateForm?: Function | null,
  cssClass?: string,
  extraData?: {
    labelAlign: LabelAlignment,
  },
  specialSettings?: {
    label?: string,
    showLabel?: boolean,
    field1?: string,
    field2?: string,
    field3?: string,
  },
  required?: boolean,
  hoverText?: string,
  fieldsCount?: number,
};

function Entries({
  id,
  label = 'Double Entry',
  fieldState,
  updateForm = null,
  cssClass = sharedStyle.FormControlGroup,
  extraData = {
    labelAlign: 'auto',
  },
  specialSettings = {
    showLabel: true,
    field1: 'Field 1',
    field2: 'Field 2',
    field3: 'Field 3',
  },
  required = false,
  hoverText = '',
  fieldsCount = 2}: Props & Partial<any>, ref) {
  const refs: React.MutableRefObject<any[]> = useRef([]);
  refs.current = [];

  const entryWidthClass: string = `Entry_${fieldsCount}`;

  const selfValidate = (): boolean => {
    const isValid: boolean = refs.current.every(r => r.validate());
    return isValid;
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  const onFieldChanged = field => event => {
    const fs = {
      ...fieldState?.fields,
      [field]: event.target.value,
    };
    updateForm && updateForm({ fields: fs });
  };

  const addToRefs = (el): void => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <div className={classNames(cssClass, 'form_control_group')} id={`container_${id}`}>
        <fieldset id={id} className={sharedStyle.Fieldset}>
          { specialSettings?.showLabel &&
            <legend className={getAlignmentClass(extraData.labelAlign, sharedStyle)}>{label}</legend> }
          <div className={style.Container}>
            {Array.from({ length: fieldsCount }).map((_, index) => {
              const fieldName: string = `field${index + 1}`;
              return (<div key={index} className={style[entryWidthClass]}>
                <FormInput
                  ref={addToRefs}
                  id={`${fieldName}_${id}`}
                  label={specialSettings[fieldName]}
                  required={required}
                  onChange={onFieldChanged(fieldName)}
                  value={fieldState?.fields[fieldName] || ''}
                />
              </div>);
            }
            )}
          </div>
        </fieldset>
      </div>
    </Tooltip>
  );
}

export default React.memo(forwardRef(Entries));
