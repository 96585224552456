import {EditorState, convertFromRaw} from 'draft-js';
import {createDecorator} from './draft';
import {colorMapHex} from './colorsForPicker';

let __blockKey = 0;
export const resetBlockKeyGenerator = () => {
  // This is for tests
  __blockKey = 0;
};

const createBlock = (
  type,
  text,
  entityRanges,
  inlineStyleRanges,
  depth = 0
) => ({
  key: (__blockKey++).toString(),
  type,
  text,
  depth,
  inlineStyleRanges,
  entityRanges,
  data: {},
});

const createLinkEntity = data => ({
  data,
  mutability: 'MUTABLE',
  type: 'LINK',
});

const alignmentMap = {
  center: 'align-center',
  left: 'align-left',
  right: 'align-right',
};

const blockStyleToRawBlockType = (property, value) => {
  switch (property) {
    case 'textAlign':
      return alignmentMap[value];
    default:
      return null;
  }
};


const inlineTagMap = {
  h1: 'H1',
  h2: 'H2',
  h3: 'H3',
  h4: 'H4',
  h5: 'H5',
  h6: 'H6',
};

const styleMapper = (property, value) => {
  /*
   * Maps api styles to draft styles. This method seems like overkill at the moment,
   * but may become more complex in the future.
   */
  switch (property) {
    case 'fontWeight':
      if (value === 'bold') {
        return 'BOLD';
      }
      break;
    case 'fontStyle':
      if (value === 'italic') {
        return 'ITALIC';
      }
      break;
    case 'textDecoration':
      if (value === 'underline') {
        return 'UNDERLINE';
      }
      break;
    case 'color':
      if (value) {
        return colorMapHex[value];
      }
      break;
    default:
      break;
  }
  return value || '';
};

const inlineStyleRangesFromText = (offset, {styles, tagType, text}) => {
  const inlineStyleRanges: any[] = [];
  for (const key of Object.keys(styles)) {
    inlineStyleRanges.push({
      style: styleMapper(key, styles[key]),
      offset,
      length: text.length,
    });
  }
  if (tagType !== 'text' && inlineTagMap.hasOwnProperty(tagType)) {
    inlineStyleRanges.push({
      style: inlineTagMap[tagType],
      offset,
      length: text.length,
    });
  }
  return inlineStyleRanges;
};

let __entityKey = 0;
export const resetEntityKeyGenerator = () => {
  // This is for tests
  __entityKey = 0;
};

const collapseInlineStyleRanges = inlineStyleRanges => {
  inlineStyleRanges.sort(({offset: a}, {offset: b}) => {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    return 0;
  });
  const collapsedInlineStyleRanges: any[] = [];
  const styleRangesByStyle = {};
  for (const inlineStyleRange of inlineStyleRanges) {
    const last = styleRangesByStyle[inlineStyleRange.style];
    if (last && ((last.offset + last.length) === inlineStyleRange.offset)) {
      last.length += inlineStyleRange.length;
    } else {
      collapsedInlineStyleRanges.push(inlineStyleRange);
      styleRangesByStyle[inlineStyleRange.style] = inlineStyleRange;
    }
  }
  return collapsedInlineStyleRanges;
};

const textFromInline = inlines => {
  let text = '';
  const entityRanges: any[] = [];
  const inlineStyleRanges: any[] = [];
  const entityMap = {};
  for (const inline of inlines) {
    if (inline.tagType === 'link') {
      let linkText = '';
      for (const child of inline.text) {
        inlineStyleRanges.push(...inlineStyleRangesFromText(text.length + linkText.length, child));
        linkText += child.text;
      }
      entityMap[__entityKey] = createLinkEntity({display: linkText, URL: inline.linkTo});
      entityRanges.push({
        key: __entityKey,
        offset: text.length,
        length: linkText.length,
      });
      text += linkText;
      __entityKey++;
    } else {
      inlineStyleRanges.push(...inlineStyleRangesFromText(text.length, inline));
      text += inline.text;
    }
  }
  return {
    text,
    entityMap,
    entityRanges,
    inlineStyleRanges: collapseInlineStyleRanges(inlineStyleRanges),
  };
};

const tagTypes = {
  blockquote: 'blockquote',
  div: 'unstyled',
  h1: 'header-one',
  h2: 'header-two',
  h3: 'header-three',
  h4: 'header-four',
  h5: 'header-five',
  h6: 'header-six',
  orderedList: 'ordered-list-item',
  paragraph: 'paragraph',
  pre: 'code-block',
  unorderedList: 'unordered-list-item',
};


const imageToRaw = ({alignment, height, url, width}) => {
  const entityKey = __entityKey++;
  const entityRanges = [{
    key: entityKey,
    length: 1,
    offset: 0,
  }];
  const data: any = {src: url};
  if (height != null) {
    data.height = height;
  }
  if (width != null) {
    data.width = width;
  }
  if (alignment != null) {
    data.alignment = alignment;
  }
  return {
    blocks: [
      createBlock('atomic', ' ', entityRanges, []),
    ],
    entityMap: {
      [entityKey]: {
        data,
        mutability: 'IMMUTABLE',
        type: 'image',
      },
    },
  };
};

const structureBlockToRaw = ({children, styles, tagType}) => {
  let type;

  for (const property of Object.keys(styles)) {
    const value = styles[property];
    if (value != null) {
      type = blockStyleToRawBlockType(property, value);
      if (type != null) {
        break;
      }
    }
  }
  if (type == null) {
    type = tagTypes[tagType];
  }
  const {text, entityMap, entityRanges, inlineStyleRanges} = textFromInline(children);
  return {
    blocks: [createBlock(type, text, entityRanges, inlineStyleRanges)],
    entityMap,
  };
};

const structureListToRaw = ({tagType, children}, depth = 0) => {
  const blocks: any[] = [];
  const allEntityMap = {};
  for (const item of children) {
    switch (item.tagType || '') {
      case 'orderedList':
      case 'unorderedList':
        const list = (item); // Flow can't handle disambiguating between `List` and `ListItem`.
        const raw = structureListToRaw(list, depth + 1);
        Object.assign(allEntityMap, raw.entityMap);
        blocks.push(...raw.blocks);
        break;
      default:
        const inlines = item.children;
        const {text, entityMap, entityRanges, inlineStyleRanges} = textFromInline(inlines);
        Object.assign(allEntityMap, entityMap);
        blocks.push(createBlock(tagTypes[tagType], text, entityRanges, inlineStyleRanges, depth));
        break;
    }
  }
  return {blocks, entityMap: allEntityMap};
};

const breakToRaw = () => ({
  blocks: [createBlock('unstyled', '', [], [])],
  entityMap: {},
});

export const structureToRaw = structure => {
  const blocks: any[] = [];
  const entityMap = {};
  for (const structureEl of structure) {
    let raw;
    switch (structureEl.tagType) {
      case 'orderedList':
      case 'unorderedList':
        raw = structureListToRaw(structureEl);
        break;
      case 'br':
        raw = breakToRaw();
        break;
      case 'image':
        raw = imageToRaw(structureEl);
        break;
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
      case 'pre':
      case 'div':
      case 'paragraph':
      case 'blockquote':
        raw = structureBlockToRaw(structureEl);
        break;
      default:
        break;
    }
    if (raw) {
      Object.assign(entityMap, raw.entityMap);
      blocks.push(...raw.blocks);
    }
  }
  return {
    blocks,
    entityMap,
  };
};

export const convertToEditorState = structure => {
  const raw = structureToRaw(structure);
  return EditorState.createWithContent(convertFromRaw(raw), createDecorator());
};
