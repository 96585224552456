import React from 'react';
import FormInput from '../FormInput/FormInput';
import style from './DynamicList.css';

type Props = {
  label?: string,
  index: number,
  id: string,
  placeholder?: string,
  value: string,
  onRemove: (index: number) => void,
  onChangeValue: (newValue: string, index: number) => void,
}

const DynamicListElement = ({
  label = '',
  index,
  id,
  placeholder = '',
  value,
  onRemove,
  onChangeValue,
}: Props) => {
  const handleChangeValue = (e): void => onChangeValue(e.target.value, index);

  return (
    <div>
      <button onClick={() => onRemove(index)} type='button' className={style.removeInputButton}>Remove Field</button>
      <FormInput
        value={value}
        onChange={handleChangeValue}
        label={label}
        id={`dynamic_list_el_${id}_${index}`}
        type='text'
        extraData={{ placeholder }}
        labelCssClass={style.inputLabel}
      />
    </div>
  );
};

export default DynamicListElement;
