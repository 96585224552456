import React from 'react';
import {generateRandomTooltipId} from 'utils/assignees';
import {joinClassNames} from 'utils/strings';
import styles from './Item.css';
import CrossIcon from './CrossIcon';
import Tooltip from 'components/Tooltip';


class Item extends React.Component<any, any> {
  static defaultProps = {
    inPopover: false,
    onKeyDown: () => {},
    onRemove: () => {},
    onSelect: () => {},
    selected: false,
    isSelectedByKeyboard: false,
    tabIndex: '',
    removeCaption: 'Click to remove',
  };

  renderItemName() {
    const {inPopover, selectionData: item, invalid, invalidLabel} = this.props;
    const itemNameElement = (
      <p className={styles.itemName}>
        {item.label}
      </p>
    );
    return inPopover ?
      itemNameElement :
      <Tooltip className={styles.tooltip} title={invalid && invalidLabel ? invalidLabel : item.label}>
        {itemNameElement}
      </Tooltip>;
  }

  handleOnSelect = () => {
    const {selectionData, onSelect} = this.props;
    onSelect(selectionData);
  };

  handleOnRemove = () => {
    const {selectionData, onRemove} = this.props;
    onRemove(selectionData);
  };

  render() {
    const {
      selectionData: item,
      selected,
      isSelectedByKeyboard,
      inPopover,
      invalid,
      onKeyDown,
      tabIndex,
      removeCaption,
    } = this.props;
    let tooltipId;
    if (!inPopover) tooltipId = generateRandomTooltipId(item.label);
    return (
      <div className={styles.container}>
        <div
          onClick={this.handleOnSelect}
          className={
            joinClassNames(
              styles.body,
              inPopover ? styles.inPopover : '',
              invalid ? styles.invalid : '',
              selected ? styles.selected : '',
              isSelectedByKeyboard ? styles.isHover : ''
            )
          }
          aria-label={item.label}
          tabIndex={tabIndex}
          onKeyDown={onKeyDown}>
          {this.renderItemName()}
          {selected &&
            <CrossIcon
              tooltipId={tooltipId}
              onClick={this.handleOnRemove}
              className={styles.crossmark}
              caption={removeCaption}/>
          }
        </div>
      </div>
    );
  }
}

export default Item;
