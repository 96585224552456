import React from 'react';

import styles from './SelectedQuickFilter.css';
import { joinClassNames } from 'utils/strings';


const SelectedQuickFilter = ({ onRemove, selectedFilter }) => (
  <h2 className={joinClassNames(styles.filterAppearance, styles.filterLayout)}>
    {selectedFilter}
    <button
      aria-label='Remove filter'
      className={joinClassNames(styles.filterButtonAppearance, styles.filterButtonLayout)}
      onClick={onRemove}>
      X
    </button>
  </h2>
);

export default SelectedQuickFilter;
