import api from 'reducers/api';
import {
  removeRuleFromRules,
  updateSubmissionRuleCoregistrationSettings,
  updateSubmissionRuleEmailSettings,
  updateSubmissionRuleAssignees,
  updateSubmissionRules,
  updateSubmissionRuleStage,
  updateSubmissionRuleTags,
  updateSubmissionRuleTriggers,
} from 'embed/utils/data';
import {
  ADD_RULE,
  ASSIGNEES_UPDATE,
  UPDATE_EMAIL_BODY,
  UPDATE_EMAIL_RECIPIENTS,
  UPDATE_EMAIL_SUBJECT,
  TOGGLE_EMAIL_SUBMISSION_SETTINGS,
  UPDATE_FIELD,
  GET_FIELD_DATA,
  GET_FORM_RULES,
  GET_STAGES,
  MARK_ATTEMPTED_SAVE,
  REMOVE_RULE,
  UPDATE_RULE_TRIGGERS,
  SAVE_FORM_RULES,
  SET_CHANGED,
  SET_FORM_ID,
  TOGGLE_MODAL,
  TOGGLE_SNACKBAR,
  UPDATE_STAGE,
  UPDATE_TAGS,
  UPDATE_COREGISTRATION_SETTINGS,
} from 'embed/constants/submissionRuleBuilder';

const initialState = {
  changed: false,
  fetchingRules: false,
  fields: [],
  modalOpen: false,
  rules: [],
  snackbarMessage: null,
  snackbarOpen: false,
  stages: [],
  formId: null,
};

export default function submissionRuleBuilder(state = initialState, _action) {
  const action = _action;
  switch (action.type) {
    case GET_FIELD_DATA:
      return {...state, fields: action.fields};
    case GET_FORM_RULES:
      return api(action, state, {
        pending: () => ({...state, fetchingRules: true}),
        failure: () => ({...state, fetchingRules: false}),
        success: () => {
          const {rules} = action.payload;
          return rules ? {...state, fetchingRules: false, rules} : {...state, fetchingRules: false};
        },
      });
    case GET_STAGES:
      return api(action, state, {
        // @ts-ignore
        success: () => ({...state, stages: action.payload.stages[state.formId] || []}),
      } as any);
    case MARK_ATTEMPTED_SAVE:
      // @ts-ignore
      return {...state, rules: state.rules.map(rule => ({...rule}))};
    case SET_FORM_ID:
      return {...state, formId: action.formId};
    case TOGGLE_MODAL:
      return {...state, modalOpen: action.modalOpen};
    case ADD_RULE:
      const updatedRules = state.rules.concat(action.rule);
      return {...state, rules: updatedRules};
    case UPDATE_FIELD:
      return {...state, rules: updateSubmissionRules(state.rules, state.fields, action)};
    case UPDATE_EMAIL_BODY:
    case UPDATE_EMAIL_RECIPIENTS:
    case UPDATE_EMAIL_SUBJECT:
    case TOGGLE_EMAIL_SUBMISSION_SETTINGS:
      return {...state, rules: updateSubmissionRuleEmailSettings(state.rules, action)};
    case ASSIGNEES_UPDATE:
      return {...state, rules: updateSubmissionRuleAssignees(state.rules, action)};
    case UPDATE_STAGE:
      return {...state, rules: updateSubmissionRuleStage(state.rules, action)};
    case UPDATE_TAGS:
      return {...state, rules: updateSubmissionRuleTags(state.rules, action)};
    case UPDATE_COREGISTRATION_SETTINGS:
      return {...state, rules: updateSubmissionRuleCoregistrationSettings(state.rules, action)};
    case REMOVE_RULE:
      return {...state, rules: removeRuleFromRules(state.rules, action)};
    case UPDATE_RULE_TRIGGERS:
      return {...state, rules: updateSubmissionRuleTriggers(state.rules, action)};
    case TOGGLE_SNACKBAR:
      return {...state, snackbarOpen: action.snackbarOpen, snackbarMessage: action.snackbarMessage};
    case SAVE_FORM_RULES:
      return api(action, state, {
        pending: () => ({...state}),
        failure: () => ({...state, snackbarOpen: true, snackbarMessage: 'An error has occurred. Rules were not saved.'}),
        success: () => ({...state}),
      });
    case SET_CHANGED:
      return {...state, changed: action.changed};
    default:
      return state;
  }
}
