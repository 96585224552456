const KEY_RE = /^_attachment_(\d+)$/;

export const makeAttachmentKey = (index: number) => `_attachment_${index}`;

export const isAttachmentKey = (key: string) => KEY_RE.test(key);

export const attachmentIndexFromKey = (key: string) => {
  const match = key.match(KEY_RE);
  if (match) {
    return parseInt(match[1], 10);
  }
  return null;
};

const BAD_BUCKET_NAMES = ['seamlessdocs', '23e71f8f-0f25-43fe-90a9-da16108b0318'];
export const GOOD_BUCKET_NAME = '260129c1-3e0b-4614-a4a6-e2986d88c664';

const oldBucketFormat = (bucketName: string) => `${bucketName}.s3.amazonaws.com/`;
const newBucketFormat = (bucketName: string) => `s3.amazonaws.com/${bucketName}/`;

// Wait, but why?
// In December 2018 our ubiquitous s3 bucket `seamlessdocs` was blacklisted by Google as unsafe for attempted phishing.
// We migrated many things over to a new bucket, but attachments were stored in submission data.
// We added this as a quick fix as opposed to a massive migration over the entire database of submissions.
// If you are reading this in like 2026 or something and sobbing, please `git blame` and send hatemail to the person
// who did this.
// TODO: SA-608 - remove this once submission data no longer routes to the bad bucket
export const hackilyRewriteAttachmentUrl = (url: string) => {
  // eslint yells about let here because BAD_BUCKET_NAMES is of size 1, but let should be used for a loop
  for (let badBucketName of BAD_BUCKET_NAMES) { // eslint-disable-line prefer-const
    if (url.includes(newBucketFormat(badBucketName)) || url.includes(oldBucketFormat(badBucketName))) {
      return url.replace(badBucketName, GOOD_BUCKET_NAME);
    }
  }
  return url;
};
