import React, {memo, useCallback, MouseEvent} from 'react';
import styles from '../govOsPay.css';
import { DEFAULT_GOVOS_PAY_CONVENIENCE_FEE_LABEL } from 'constants/payments';
import { GovOsPaymentObject, GovOSPayModalOpenHandlers, PaymentType } from 'types/govOsPay/govOsPaymentSettings';
import { getFilteredPaymentObject } from 'utils/govOSPay/govOsPay';
import OverlayModalContainer from '../OverlayModalContainer';

type SummaryModalProps = {
  openGovOSPaymentModalHandlers: GovOSPayModalOpenHandlers
  paymentObjects: GovOsPaymentObject[]
  selectedPaymentSummaryType: PaymentType | null;
  closeModal: () => void;
}

const REFUND_POLICY_LINK = 'https://help.seamlessdocs.com/en/articles/9659753-refund-policy';
const SUPPORT_CHANNELS_LINK = 'https://help.seamlessdocs.com/en/articles/9659758-support-channels';
const SummaryModal = (props: SummaryModalProps) => {
  const { closeModal, selectedPaymentSummaryType, paymentObjects, openGovOSPaymentModalHandlers } = props;

  const { openGovOSACHPaymentModal, openGovOSCardPaymentModal } = openGovOSPaymentModalHandlers;
  const summary = getFilteredPaymentObject(paymentObjects, selectedPaymentSummaryType);

  const openPaymentModalHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (selectedPaymentSummaryType === 'ach') {
      openGovOSACHPaymentModal();
    }
    if (selectedPaymentSummaryType === 'credit_card') {
      openGovOSCardPaymentModal();
    }
  }, [selectedPaymentSummaryType, openGovOSACHPaymentModal, openGovOSCardPaymentModal]);

  if (!selectedPaymentSummaryType) return null;

  return (
    <OverlayModalContainer open={selectedPaymentSummaryType !== null} closeModal={closeModal} isSummaryModal>
      <h1 className={styles.modalHeader} id='modal-header'>Invoice Summary</h1>
      <h3>{`${summary?.name} Payment`}</h3>
      <div className={styles.paymentMethodIconContainer} style={{ height: '6rem' }}>
        <img src={summary?.icon} alt={`${summary?.name} icon`} />
      </div>
      <div className={styles.modalBody}>
        <div className={styles.paymentCardAmountContainer}>
          <div className={styles.paymentCardAmountLineItem}>
            <div>Document Fee</div>
            <div>${`${summary?.baseAmount}`}</div>
          </div>
          <div className={styles.paymentCardAmountLineItem}>
            <div>{`${summary?.feeName}` || DEFAULT_GOVOS_PAY_CONVENIENCE_FEE_LABEL}</div>
            <div>${summary?.feeTotal}</div>
          </div>
          <div className={styles.paymentCardAmountLineItem}>
            <div>Total</div>
            <div>${summary?.totalAmount}</div>
          </div>
          <div>
            <button
              className={styles.paymentCardPayButton}
              aria-label='proceed with payment method'
              onClick={openPaymentModalHandler}
            >
              Proceed with payment
            </button>
          </div>
          <div className={styles.summaryLinksContainer}>
            <a
              href={REFUND_POLICY_LINK}
              aria-label='Read our Refund Policy'
              target='_blank'
              rel='noopener noreferrer'
            >
              Refund Policy
            </a>
            <a
              href={SUPPORT_CHANNELS_LINK}
              aria-label='Visit our Support Channels'
              target='_blank'
              rel='noopener noreferrer'
            >
              Support Channels
            </a>
          </div>
        </div>
      </div>
    </OverlayModalContainer>
  );
};

export default memo(SummaryModal);
