import React from 'react';
import Stages from 'components/Stages';
import { SM_DETAILS_SOURCE } from 'constants/forms';
import { NOT_IN_A_STAGE_MESSAGE, NO_STAGES_IN_FORM_MESSAGE } from 'constants/stages';


export default props => (
  <Stages
    {...props}
    source={SM_DETAILS_SOURCE}
    maxWidth='calc(100% - 175px)'
    notInStageMessage={NOT_IN_A_STAGE_MESSAGE}
    noStagesMessage={NO_STAGES_IN_FORM_MESSAGE} />
);
