import {CALL_API} from '../env';
import {authTypes} from 'constants/api';
import {getMAID, isLoggedIn} from 'utils/auth';

interface Args {
  baseType: string,
  data?: {},
  endpoint: string,
  failure: any, // FailurePayload,
  pending?: any, // PendingPayload,
  sample?: number,
  success: any, // SuccessPayload,
  headers?: any
}

const post = ({
  baseType,
  data,
  endpoint,
  failure,
  pending,
  sample,
  success,
  headers,
}: Args, requireAuth: string) => {
  const maybeMaid = getMAID();

  return ({
    baseType,
    body: data,
    endpoint,
    headers: {
      ...(headers || {'Content-Type': 'application/json'}),
      ...(maybeMaid && process.env.NODE_ENV === 'production' ? {'X-MAID': maybeMaid} : {}),
    },
    method: 'POST',
    payloads: {
      failure,
      pending,
      success,
    },
    requireAuth,
    sample,
    type: CALL_API,
  });
};

interface ServerAPIActionCreator {
  (args: Args): any;
}
interface ServerAPI {
  postWithAuth: ServerAPIActionCreator,
  postWithoutAuth: ServerAPIActionCreator,
  postWithCoregAuth: ServerAPIActionCreator,
  postWithAuthIfPresent: ServerAPIActionCreator
}

const api: ServerAPI = {
  postWithAuth(args: Args) {
    return post(args, authTypes.WithAuth);
  },
  postWithoutAuth(args: Args) {
    return post(args, authTypes.NoAuth);
  },
  postWithCoregAuth(args: Args) {
    return post(args, authTypes.CoregAuth);
  },
  postWithAuthIfPresent(args: Args) {
    const authType = isLoggedIn() ? authTypes.WithAuth : authTypes.NoAuth;
    return post(args, authType);
  },
};

export default api;
