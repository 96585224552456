import React, {useState} from 'react';
import { connect } from 'react-redux';
import styles from './BulkActionWrapper.css';
import StagesSelect from '../Components/StagesSelect';

const FindAndReplaceStages = ({
  submissionIds,
  findAndReplaceStages,
  includeAllSubmissions,
  excludedSubmissionIds,
  dispatch,
  options = [],
  formId,
  loaderModalToggle,
  errorModalToggle,
}) => {
  const [findValue, setFindValue] = useState('');
  const [replaceValue, setReplaceValue] = useState('');

  const handleReplaceStage = () => {
    findAndReplaceStages({
      formId,
      stageToFind: findValue,
      stageToReplace: replaceValue,
      submissionIds,
      includeAllSubmissions,
      excludedSubmissionIds,
      onError: () => errorModalToggle(true),
    }, dispatch);
  };

  const onReplaceClick = () => {
    const submissionsNumber = includeAllSubmissions ? 'all the' : `the ${submissionIds.length} selected`;
    loaderModalToggle(
      true,
      'fnrStage',
      'Find and Replace Stage',
      `Are you sure you want to conduct a find and replace ${submissionsNumber} submission(s)?`,
      handleReplaceStage,
    );
  };

  return (<div className={styles.fnrContainer}>
    <div className={styles.InputWrapper}>
      <label htmlFor='find'>Find</label>
      <StagesSelect selectedStage={findValue} handleChange={e => setFindValue(e.target.value)} options={options}/>
    </div>
    <div className={styles.InputWrapper}>
      <label htmlFor='replace'>Replace</label>
      <StagesSelect selectedStage={replaceValue} handleChange={e => setReplaceValue(e.target.value)} options={options}/>
    </div>
    <button type='button' className={styles.replaceButton} onClick={onReplaceClick} disabled={!(findValue && replaceValue)}>Replace</button>
  </div>);
};

export default connect(null, dispatch => ({dispatch}))(FindAndReplaceStages);
