import React from 'react';
import { connect } from 'react-redux';
import { setEmailVisibility } from 'actions/automatedProcessBuilder';
import apbEdit from 'icons/submissions/apbEdit.svg';
import chevronIcon from 'icons/icon79.svg';
import styles from './EditEmail.css';

const EditEmail = props => {
  const text = props.rule.thenValue ? 'Edit' : 'Create';
  return (
    <button className={styles.editButtonAppearance} onClick={() => props.setEmailVisibility(props.ruleId)}>
      <img src={apbEdit} alt='' />
      <span className={styles.editButtonCopyLayout}>{text}</span>
      <img src={chevronIcon} alt='' />
    </button>
  );
};

const mapDispatchToProps = {
  setEmailVisibility,
};

export default connect(null, mapDispatchToProps)(EditEmail);
