import React from 'react';
import Tooltip from 'components/Tooltip';
import AccessibleSVG from 'components/AccessibleSVG';
import styles from './tag.css';


const CrossIcon = ({ className, onClick }) => (
  <Tooltip title='Click to remove tag' className={styles.tooltip}>
    <AccessibleSVG width='7px' height='14px' viewBox='2324 4 12 12' className={className} onClick={onClick} aria-label='Click to remove tag'>
      <polygon points='2336 5.20857143 2334.79143 4 2330 8.79142857 2325.20857 4 2324 5.20857143 2328.79143 10 2324 14.7914286 2325.20857 16 2330 11.2085714 2334.79143 16 2336 14.7914286 2331.20857 10' />
    </AccessibleSVG>
  </Tooltip>
);

export default CrossIcon;
