import React from 'react';
import { connect } from 'react-redux';
import styles from './SubmissionTable.css';
import incomplete from 'icons/submissions/incomplete.svg';
import completeArrow from 'icons/submissions/completeArrow.svg';
import { getSubmissionManagerFormDetails } from '../../reducers';
import { continueLink } from 'utils/routing';


const renderComplete = () => <span>Complete</span>;
const renderIncomplete = () => (
  <React.Fragment>
    <img
      alt=''
      className={styles.incompleteSubmissionIconLayout}
      src={incomplete} />
    <span>Incomplete</span>
  </React.Fragment>
);

const renderData = isComplete => (
  <React.Fragment>
    {isComplete ? renderComplete() : renderIncomplete()}
  </React.Fragment>
);

const renderButton = url => (
  <a
    className={styles.continueButtonAppearance}
    href={url}
    onClick={event => event.stopPropagation()}>
    Complete now
    <img className={styles.continueButtonIconLayout} src={completeArrow} alt='' />
  </a>
);

export const CompletionStatusCell = ({ isComplete, showButton, url }) => (
  <React.Fragment>
    {showButton && !isComplete ? renderButton(url) : renderData(isComplete)}
  </React.Fragment>
);

const mapStateToProps = (state, ownProps) => {
  const submissionManagerFormDetails = getSubmissionManagerFormDetails(state);
  return {
    url: submissionManagerFormDetails ? continueLink(submissionManagerFormDetails, ownProps.submissionId) : '',
  };
};

export default connect(mapStateToProps, null)(CompletionStatusCell);
