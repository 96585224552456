import React, { useState, useEffect, useRef } from 'react';
import SmallModal from 'components/Modals/SmallModal';
import Action from 'components/Modals/Action';
import styles from '../modalShared.css';

interface CreateFolderModalProps {
  onClose: () => void;
  onCreate: (folderName: string) => void;
  open: boolean;
}

const CreateFolderModal: React.FC<CreateFolderModalProps> = ({ onClose, onCreate, open }) => {
  const [value, setValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const isValid = (): boolean => !!value;

  const handleCreate = (): void => {
    if (isValid()) {
      onCreate(value);
      setValue('');
    }
  };

  const handleClose = (): void => {
    onClose();
    setValue('');
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && isValid()) {
      handleCreate();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };


  const modalActions: JSX.Element[] = [
    <Action
      key='cancel'
      label='Cancel'
      secondary
      onClick={handleClose}
    />,
    <Action
      key='create'
      label='Create'
      disabled={!isValid()}
      primary
      onClick={handleCreate}
    />,
  ];

  return (
    <SmallModal
      title='Create New Folder'
      titleIcon='folder'
      actions={modalActions}
      onClose={onClose}
      open={open}
    >
      <label
        className={styles.folderNameLabel}
        htmlFor='folderName'
      >
        Folder Name
      </label>
      <input
        aria-label='create folder input'
        ref={inputRef}
        onKeyPress={handleInputKeyDown}
        onChange={handleInputChange}
        id='folderName'
        value={value}
        className={styles.folderNameInput}
        type='text'
      />
    </SmallModal>
  );
};

export default CreateFolderModal;
