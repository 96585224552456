import * as env from '../env';
import { LOBBY_ROOT_FOLDER } from 'constants/tableConsts';
import { FormType, Form, getLiveViewUrlFormType } from 'types/routing';

export const formatSubdomain = (subdomain: string, environment = env.AUTH0_ENVIRONMENT) =>
  [subdomain, environment].filter(Boolean).join('.');


export const formatLegacyEditFormUrl = (form: Form | null): string | null => {
  if (!form) return null;

  switch (form.itemType) {
    case 'Webform':
      return `/forms/builder/${form.formId}`;
    case 'Wizard':
      return `/w/${form.alias}/admin`;
    case 'Doc':
    default:
      return `/f/${form.alias}/admin`;
  }
};

export const formatLegacyLiveviewUrl = (form: { alias: string, itemType?: FormType }): string => {
  if (!form) return '';

  if (form.itemType === 'Wizard') {
    return `/w/${form.alias}`;
  }
  return `/f/${form.alias}`;
};

export const formCenter = () => '/sc/forms?fromNG=true';

export const grmSignup = () => '/sc/signup';


export const GRM = (parentFolderId: string | null, formId: string) => {
  const folderId = parentFolderId == null || parentFolderId === LOBBY_ROOT_FOLDER ? 'root' : parentFolderId;
  return `/sc/${folderId}/${formId}?fromNG=true`;
};

export const grmPipeline = (formId: string) => `/sc/pipeline/${formId}`;

export const grmSubmission = (formId: string, submissionId: string) => `/sc/pipeline/${formId}/${submissionId}`;

export const grmLobby = () => '/sc/lobby/';

export const loginInterstitialPath = (queryString: string, basePath = 'ng') =>
  `/${basePath}/loginToViewForm${queryString}`;

export const registrationInterstitialPath = (queryString: string) => `/ng/registration${queryString}`;

export const redirectToLogin = (router: any) => router.replace('/ng/login');

export const redirectToNotFound = () => {window.location.href = '/ng/404';};

export const formatLiveviewUrl = (form: { alias: string}) => `/ng/fa/${form.alias}`;

export const deployment = window.location.hostname.split('.')[1];

export const continueLink = (form: { alias: string, itemType?: FormType; }, submissionId: string) =>
  `${formatLegacyLiveviewUrl(form)}/${btoa(submissionId)}`;

export const signNowLink = ({ signerKey, signeeType, fullname, email, submissionId, formId }: {
  signerKey: string,
  signeeType: string,
  fullname: string,
  email: string,
  submissionId: string,
  formId: string,
}): string => {
  const signerData = {
    application_id: submissionId,
    signer: {
      key: signerKey,
      recipient_type: signeeType ? signeeType.toLowerCase() : 'signer',
      recipient_info: {
        fullname,
        email,
      },
    },
  };

  return '/f/' + formId + '/' + btoa(JSON.stringify(signerData));
};


export const getLiveViewUrl = (
  formDataObj: getLiveViewUrlFormType | null | undefined,
  isNgLiveViewEnabled: boolean,
): string => {
  if (!formDataObj) {
    return '';
  }

  const { isNgLiveViewExcluded, isNgLiveViewIncompatible, itemType } = formDataObj;

  // If we don't have the necessary data to determine if we can use NG LiveView, default to legacy
  if (isNgLiveViewExcluded === undefined || isNgLiveViewIncompatible === undefined) {
    return formatLegacyLiveviewUrl(formDataObj);
  }

  //  determine if we can use NG LiveView
  const canUseNgLiveView =
    isNgLiveViewEnabled &&
    (itemType === 'Webform' || itemType === 'Wizard') &&
    !isNgLiveViewExcluded &&
    !isNgLiveViewIncompatible;

  return canUseNgLiveView
    ? formatLiveviewUrl(formDataObj)
    : formatLegacyLiveviewUrl(formDataObj);
};
