import React, { memo } from 'react';
import classNames from 'classnames';
import styles from '../IdVerification.css';
import warningTriangleIcon from 'icons/warning-triangle_grey.svg';
import WithFocusTrap from '../WithFocustrap';

const ERROR_HEADER_TEXT = 'Error';
const ERROR_BODY_TEXT = 'An error occurred while fetching verified data.';
const RETRY_TEXT = 'Retry';
const CANCEL_TEXT = 'Start Over';

type Props = {
  onRetry: () => void;
  onCancel: () => void;
  open: boolean;
};

const FetchError: React.FC<Props> = ({ onRetry, onCancel, open }) => (
  <WithFocusTrap open={open} isContentLoaded>
    <div className={styles.fetchErrorContainer}>
      <div className={styles.fetchErrorContentWrapper}>
        <img src={warningTriangleIcon} alt='Warning icon' />
        <div className={styles.fetchErrorHeader}>{ERROR_HEADER_TEXT}</div>
        <div className={styles.fetchErrorText}>{ERROR_BODY_TEXT}</div>
      </div>
      <div className={styles.fetchErrorButtonContainer}>
        <button
          onClick={onCancel}
          className={classNames(styles.fetchErrorButton, styles.fetchErrorCancelButton)}
        >
          {CANCEL_TEXT}
        </button>
        <button
          onClick={onRetry}
          className={classNames(styles.fetchErrorButton, styles.fetchErrorRetryButton)}
        >
          {RETRY_TEXT}
        </button>
      </div>
    </div>
  </WithFocusTrap>
);

export default memo(FetchError);
