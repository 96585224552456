import React, {useEffect, useRef} from 'react';
import styles from '../../RecipientsSelector.css';
// import type {SyntheticKeyboardInputEventHandler} from 'types/shared';

// type Props = {
//   onEnter: (value: string) => void,
//   requestPopoverClose: () => void,
// };

const TypeaheadInput = ({onEnter, requestPopoverClose}) => {
  const inputRef = useRef() as any;
  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, []);

  const handleKeyDown = event => {
    if (event.keyCode !== 13 || !event.target.value.length) return;
    onEnter(event.target.value);
    requestPopoverClose();
  };

  return (
    <div className={styles.typeahead}>
      <input
        id='typeaheadInput'
        className={styles.typeaheadInput}
        placeholder='Type some email...'
        ref={inputRef}
        onKeyDown={handleKeyDown}
        type='text'/>
    </div>
  );
};

export default TypeaheadInput;
