export const columnNamesEnum = {
  createdTs: 'createdTs',
  formAuthorName: 'formAuthorName',
  formName: 'formName',
  itemType: 'itemType',
  numSubmissions: 'numSubmissions',
  folder: 'folder',
  legacyOrNg: 'legacyOrNg',
};

// export type ColumnName = $Values<typeof columnNamesEnum>;

export const LOBBY_COLUMNS = [
  columnNamesEnum.createdTs,
  columnNamesEnum.formAuthorName,
  columnNamesEnum.formName,
  columnNamesEnum.itemType,
  columnNamesEnum.numSubmissions,
  columnNamesEnum.legacyOrNg,
];

export const LOBBY_SEARCH_COLUMNS = [
  columnNamesEnum.createdTs,
  columnNamesEnum.formAuthorName,
  columnNamesEnum.formName,
  columnNamesEnum.itemType,
  columnNamesEnum.numSubmissions,
  columnNamesEnum.folder,
];

export const QUICK_FILTER_COLUMNS = [
  columnNamesEnum.formName,
  columnNamesEnum.itemType,
  columnNamesEnum.numSubmissions,
];
