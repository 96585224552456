import * as Colors from 'utils/colors';
import styles from './Toolbar.css';

const toolbarHeight = styles.toolbarHeight;

export const toolBar = {
  backgroundColor: Colors.grey800,
  position: 'relative',
  padding: 0,
  height: toolbarHeight,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const rightToolbarGroup = {
  // This is a hack to prevent a glitch on mobile where the app overflows the screen
  // during the searchbar opening animation. This padding acts as a "fudge" space
  // for the searchbar to overflow into while animating.
  paddingLeft: '1px',
  float: 'right',
};

export const titleIcon = (backgroundColor = Colors.orange500) => ({
  backgroundColor,
});

export const titleText = () => ({
  color: Colors.white,
  position: 'relative',
  height: toolbarHeight,
  lineHeight: toolbarHeight,
  fontSize: 18,
});

export const toolbarSvgIcon = {
  marginRight: '17%',
  top: '-4px',
};
