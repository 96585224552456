import { useEffect } from 'react';

// Custom hook for injecting dynamic styles
const useDynamicStyle = (id: string, properties: any) => {
  useEffect(() => {
    const style = document.createElement('style');
    style.id = id;
    style.innerHTML = `
      .${id}-font-settings {
        font-family: ${properties?.fontFamily ?? ''};
        font-size: ${properties?.fontSize ?? ''}px;
        font-style: ${properties?.fontStyle ?? '' };
        font-weight: ${properties?.fontWeight ?? 'normal'};
        letter-spacing: ${properties?.letterSpacing?.match(/^\d+$/) ? properties?.letterSpacing + 'px' : properties?.letterSpacing};
        line-height: ${properties?.lineHeight?.match(/^\d+$/) ? properties?.lineHeight + 'px' : properties?.lineHeight};
        text-align: ${properties?.textAlign ?? ''};
        text-decoration: ${properties?.textDecoration ?? ''};
        word-spacing: ${properties?.wordSpacing?.match(/^\d+$/) ? properties?.wordSpacing + 'px' : properties?.wordSpacing};
        color: ${properties?.color ?? ''};
      }
    `;
    document.head.appendChild(style);

    // Cleanup: Remove the style when the component is unmounted or the properties change
    return () => {
      document.head.removeChild(style);
    };
  }, [id, properties]);
};

export default useDynamicStyle;
