import api from 'actions/api';
import { ANALYTICS_DATA } from 'constants/analytics';

export const getAnalyticsData = () => api.postWithAuth({
  endpoint: 'analytics/segmentSupported.json',
  baseType: ANALYTICS_DATA,
  success: (_state, {response}) => response,
  failure: (_state, error) => ({error}),
});

