function _samplingEpic(action$, scheduler?: any) {
  /*
   * Actions are separated by
   * - `sample` (debounce time in milliseconds)
   * - `baseType` (any string, typically used in API call actions)
   */
  return action$
    .groupBy(({sample}) => sample)
    .mergeMap(sampleGroup$ => {
      const sampleMilliseconds = sampleGroup$.key || null;
      if (typeof sampleMilliseconds === 'number') {
        return sampleGroup$
          .groupBy(({baseType}) => baseType)
          .mergeMap(baseTypeGroup$ => scheduler
            ? baseTypeGroup$.sampleTime(sampleMilliseconds, scheduler)
            : baseTypeGroup$.sampleTime(sampleMilliseconds));
      }
      return sampleGroup$;
    });
}


export function makeTestableSamplingEpic(scheduler) {
  return action$ =>
    _samplingEpic(action$, scheduler);
}

export default function samplingEpic(action$) {
  return _samplingEpic(action$);
}
