import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { push } from 'react-router-redux';
import ResizeObserver from 'resize-observer-polyfill';
import { unescape } from 'lodash';
import {
  checkFormCapability,
  getSelectedSubmissionRow,
  isApbVisible,
} from '../../reducers';
import SubmissionManagerSidebar from './SubmissionManagerSidebar';
import SubmissionManagerSnackbar from './SubmissionManagerSnackbar';
import ManagerControlBar from './ManagerControlBar';
import SubmissionsList from './SubmissionsList';
import ApbSidebar from 'components/AutomatedProcessBuilder/ApbSidebar';
import { getWindowSizeData, WindowSize } from 'components/WindowSize';
import NotificationLoaderModal from 'components/Modals/NotificationLoaderModal';
import DecryptionModal from 'components/Modals/DecryptionModal';
import { setApbVisibility } from 'actions/automatedProcessBuilder';
import { showLobbyWithForm } from 'actions/lobby/lobbyActions';
import {
  deleteSubmissionsRequest,
  deleteArchivedSubmissionRequest,
  decryptionRequest,
  initialize,
  resetSelectedRows,
  resetTableSnackbar,
  toggleStageSidebar,
  archiveSubmissionRequest,
  unarchiveSubmissionRequest,
  setIsArchiveView,
} from 'actions/submissionsManager/tableApi';
import * as submissionsActions from 'actions/submissionsManager/submissionsActions';
import styles from 'styles/shared/containerStyles.css';


import CollapsibleSidebar, { COLLAPSIBLE_SIDEBAR_COLLAPSED_WIDTH, COLLAPSIBLE_SIDEBAR_EXPANDED_WIDTH } from 'components/CollapsibleSidebar';
import StagesSidebarDetails from 'components/StagesSidebarDetails';
import BulkActionProcessingModal from 'components/Modals/BulkActionProcessingModal/BulkActionProcessingModal';
import { formCapabilitiesEnum } from 'constants/tableConsts';
import { COMPANY_NAME } from 'constants/applicationCopy';

class SubmissionsManager extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const { isMobile, isPhone, isTablet, width: windowWidth, height: wrapperHeight } = getWindowSizeData();

    this.state = {
      isMobile,
      isPhone,
      isTablet,
      windowWidth,
      wrapperHeight,
      mounted: false,
    };
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver(() => {
      const { isMobile, isPhone, isTablet, width: windowWidth } = getWindowSizeData();

      const wrapperHeight = (this.refs.wrapper as any).offsetHeight;

      if (!this.state.mounted || Math.abs(wrapperHeight - this.state.wrapperHeight) > 1) {
        // This is fine, we're in an event callback, not component did mount
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
          isMobile,
          isPhone,
          isTablet,
          wrapperHeight,
          windowWidth,
          mounted: !!wrapperHeight,
        });
      }
    });
    this.resizeObserver.observe(this.refs.wrapper);
    this.props.tableApi.setIsArchiveView(!!this.props.isArchiveView);
  }

  componentWillUnmount() {
    this.props.submissionsActions.hideSidebar();
    this.props.tableApi.resetSelectedRows();
    this.props.lobbyActions.showLobbyWithForm(this.props.params.formID);
    this.resizeObserver.unobserve(this.refs.wrapper);
    this.props.tableApi.setIsArchiveView(false);
  }

  resizeObserver;

  resizeContainer = () => {
    const { isMobile, isPhone, isTablet, width: windowWidth } = getWindowSizeData();
    this.setState({
      isMobile,
      isPhone,
      isTablet,
      windowWidth,
    });
  }

  renderSidebar = () => {
    const {
      shouldShowApb,
    } = this.props;
    const {
      isMobile,
      isTablet,
      mounted,
      windowWidth,
    } = this.state;
    const usableWindowWidth = windowWidth;
    return (
      <>
        {shouldShowApb && <ApbSidebar
          open={shouldShowApb}
          isEmbed={false}
          isTablet={isTablet}
          isVisible={mounted}
          onSidebarToggle={this.handleApbSidebarToggle}
          usableWindowWidth={usableWindowWidth} />
        }
        <SubmissionManagerSidebar
          isMobile={isMobile}
          isVisible={mounted}
          usableWindowWidth={usableWindowWidth} />
      </>
    );
  }

  handleNotificationModalClose = (status?: string) => {
    this.props.submissionsActions.loaderModalToggle(false, status);
  };

  handleApbSidebarToggle = () => {
    this.props.setApbVisibility(false);
  }

  handleDecrypt = password => {
    const { tableApi, submissions } = this.props;
    if (submissions.decryptionData) {
      const { rawValue, rowIndex, inputName } = submissions.decryptionData;
      tableApi.decryptionRequest(rawValue, password, rowIndex, inputName);
    }
    this.props.submissionsActions.decryptModalVisibility('', 0, '');
  };

  handleTableApiRequest = request => {
    const {
      location,
      params,
      router,
      selectedRecordIndices,
      selectedRecords,
      selectedRowData,
      submissions,
      isAllSelected,
      excludedSubmissionIds,
    } = this.props;

    if (selectedRowData || selectedRecords.length > 0) {
      request(
        selectedRowData,
        selectedRecords,
        submissions.selectedRowIndex,
        selectedRecordIndices,
        params.formID,
        isAllSelected,
        excludedSubmissionIds,
      );
      this.props.submissionsActions.hideSidebar();
      setTimeout(() => {
        router.push(`${location.pathname}#`);
      }, 0);
    }
  };

  handleArchive = () => {
    const { isArchiveView } = this.props;
    if (isArchiveView) {
      this.handleTableApiRequest(this.props.tableApi.deleteArchivedSubmissionRequest);
    } else {
      this.handleTableApiRequest(this.props.tableApi.deleteSubmissionsRequest);
    }
  };

  handleArchiveSubmission = () => {
    this.handleTableApiRequest(this.props.tableApi.archiveSubmissionRequest);
  };

  handleRestoreSubmission = () => {
    this.handleTableApiRequest(this.props.tableApi.unarchiveSubmissionRequest);
  };

  handleLoaderAction = submitFn => (...props) => {
    submitFn(...props);
    this.handleNotificationModalClose();
  };

  handleResetSnackbar = () => {
    this.props.tableApi.resetTableSnackbar();
  };

  handleCloseDecryptionModal = () => {
    this.props.submissionsActions.decryptModalVisibility('', 0, '');
  };

  handleOpenDecryptionModal = (rawValue, rowIndex, inputName) => {
    this.props.submissionsActions.decryptModalVisibility(rawValue, rowIndex, inputName);
  };

  handleOnClickStagesSidebar = () => { this.props.tableApi.toggleStageSidebar(); }

  render() {
    const {
      areStagesEnabled,
      formDetails,
      isStagesSidebarExpanded,
      location,
      params,
      snackbarState: {
        snackbarMessage,
        isSnackbarOpen,
        isChangeStageNotification,
        changeStage,
      },
      submissions,
      isArchiveView,
      canUserArchiveSubmissions,
    } = this.props;

    const {
      isMobile,
      isPhone,
      mounted,
      wrapperHeight,
      windowWidth,
    } = this.state;

    const hamburgerHeader = areStagesEnabled ? 'Stages' : '';
    const usableWindowWidth = windowWidth;
    let submissionListWidth = usableWindowWidth;
    if (areStagesEnabled) {
      submissionListWidth -=
        isStagesSidebarExpanded && !isMobile ?
          COLLAPSIBLE_SIDEBAR_EXPANDED_WIDTH :
          COLLAPSIBLE_SIDEBAR_COLLAPSED_WIDTH;
    }
    return (
      <div
        className={styles.Container}
        style={{ width: `${usableWindowWidth}px`, maxHeight: 'calc(100vh - 113px)' }}>
        <WindowSize onChange={this.resizeContainer} />
        <Helmet title={formDetails && formDetails.name ? `${COMPANY_NAME}: ${unescape(formDetails.name).replace('&#039;', "'")}` : `${COMPANY_NAME}: Submission Manager`} />
        <ManagerControlBar
          formID={params.formID}
          isMobile={isMobile}
          isPhone={isPhone}
          windowWidth={usableWindowWidth}
          isArchiveView={isArchiveView}
        />
        <div ref='wrapper' className={styles.Subcontainer}>
          { (canUserArchiveSubmissions || areStagesEnabled) && <CollapsibleSidebar
            hamburgerHeader={hamburgerHeader}
            isHamburger={isMobile}
            isExpanded={isStagesSidebarExpanded}
            onClick={this.handleOnClickStagesSidebar}>
            <StagesSidebarDetails
              isExpanded={isStagesSidebarExpanded}
              isHamburger={isMobile}
              areStagesEnabled={areStagesEnabled}
              canUserArchiveSubmissions={canUserArchiveSubmissions}
            />
          </CollapsibleSidebar> }
          <SubmissionsList
            params={params}
            location={location}
            mounted={mounted}
            openDecryptModal={this.handleOpenDecryptionModal}
            width={submissionListWidth}
            height={wrapperHeight}
            isArchiveView={isArchiveView}/>
          {this.renderSidebar()}
          <NotificationLoaderModal
            title={submissions.loaderTitle}
            status={submissions.loaderStatus}
            open={submissions.loaderVisibile}
            bodyText={submissions.loaderBody}
            handleNotificationModalClose={this.handleNotificationModalClose}
            actionHandler={this.handleLoaderAction(submissions.loaderActionHandler)}
            handleArchiveForm={this.handleArchive}
            archiveSubmission={this.handleArchiveSubmission}
            restoreSubmission={this.handleRestoreSubmission}
          />
          <DecryptionModal
            open={submissions.decryptModalVisible}
            onClose={this.handleCloseDecryptionModal}
            onDecrypt={this.handleDecrypt} />
          <BulkActionProcessingModal
            isBulkProcessing={submissions.isBulkProcessing}
            handleNotificationModalClose={this.handleNotificationModalClose}
          />
          <SubmissionManagerSnackbar
            changeStage={changeStage}
            isChangeStageNotification={isChangeStageNotification}
            open={isSnackbarOpen || false}
            message={snackbarMessage || ''}
            autoHideDuration={3500}
            onClose={this.handleResetSnackbar}
            stages={changeStage} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  lobbyActions: bindActionCreators({ showLobbyWithForm }, dispatch),
  router: bindActionCreators({ push }, dispatch),
  submissionsActions: bindActionCreators(submissionsActions, dispatch),
  tableApi: bindActionCreators({
    resetTableSnackbar,
    deleteSubmissionsRequest,
    deleteArchivedSubmissionRequest,
    decryptionRequest,
    initialize,
    resetSelectedRows,
    toggleStageSidebar,
    archiveSubmissionRequest,
    unarchiveSubmissionRequest,
    setIsArchiveView,
  }, dispatch),
  ...bindActionCreators({ setApbVisibility }, dispatch),
});

const mapStateToProps = state => {
  const {
    submissionTable: {
      isStagesSidebarExpanded,
      selectedRecords,
      formDetails,
      selectedRecordIndices,
      snackbarState,
      table: {records},
      isAllSelected,
    },
    features,
  } = state;

  const submissionIds = selectedRecords.map(s => s.submissionId);

  return {
    areStagesEnabled: !!features.STAGES_IN_SUBMISSION_MANAGER_SUPPORT,
    isStagesSidebarExpanded,
    selectedRowData: getSelectedSubmissionRow(state),
    submissions: state.submissions,
    formDetails,
    selectedRecords,
    selectedRecordIndices,
    shouldShowApb: isApbVisible(state),
    snackbarState,
    canUserArchiveSubmissions: checkFormCapability(state, formDetails?.id, formCapabilitiesEnum.ArchiveSubmissions),
    excludedSubmissionIds: records.map(record => record.submissionId).filter(id => !submissionIds.includes(id)),
    isAllSelected,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionsManager);
