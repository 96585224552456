import React from 'react';
import PropTypes from 'prop-types';

export default function injectRouter(Component) {
  return class extends React.Component {
    static contextTypes = {
      router: PropTypes.object.isRequired,
    }
    render() {
      return (
        <Component {...this.props} router={this.context.router}/>
      );
    }
  };
}
