import React, { ForwardedRef, forwardRef, memo } from 'react';
import { ShowLabelValue, LabelAlignment } from 'types/liveView';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import { getAlignmentClass, showLabelToBoolean } from 'utils/formLiveView/formLiveView';
import styles from './AddThis.css';
import googlePlusIcon from '../../../assets/icons/liveView/AddThis/googlePlusIcon.svg';
import facebookIcon from '../../../assets/icons/liveView/AddThis/facebookIcon.svg';
import twitterIcon from '../../../assets/icons/liveView/AddThis/twitterIcon.svg';
import linkedInIcon from '../../../assets/icons/liveView/AddThis/linkedInIcon.svg';

export interface AddThisProps {
  id: string;
  label: string;
  elementType: string;
  required: boolean;
  validationType: any;
  formula: any;

  extraData: {
    label: string;
    showLabel: ShowLabelValue;
    alignment: string;
    labelAlign: LabelAlignment;
  };

  properties: any;
  excludeFromPreview: boolean;
  isDisabled: boolean;
  hoverText: string | null;

  specialSettings: {
    showTwitter: boolean;
    showLinkedin: boolean;
    showFacebook: boolean;
    showGoogle: boolean;
  };

  showOtherOption: boolean;
}


const AddThis = forwardRef((props: AddThisProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    id,
    required,
    extraData: { showLabel, label, labelAlign },
    specialSettings: { showFacebook, showTwitter, showLinkedin, showGoogle },
  } = props;

  return (
    <div id={id} ref={ref}>
      {showLabelToBoolean(showLabel) && (
        <label
          aria-label={label}
          htmlFor={id}
          className={classNames(
            sharedStyle.FormLabel,
            { [sharedStyle.Required]: required },
            'field_label',
            getAlignmentClass(labelAlign, sharedStyle),
          )}
        >
          {label}
        </label>
      )}
      <div className={styles.add_this_icons_container}>
        {showFacebook &&
          <div
            id={`${id}_FaceBook_Icon`}
            className={classNames(styles.add_this_icons_container, styles.icon)}>
            <img src={facebookIcon} alt='Facebook Icon'/>
          </div>}
        {showTwitter &&
          <div
            id={`${id}_Twitter_Icon`}
            className={classNames(styles.add_this_icons_container, styles.icon)}>
            <img src={twitterIcon} alt='Twitter Icon'/>
          </div>}
        {showLinkedin &&
          <div
            id={`${id}_LinkedIn_Icon`}
            className={classNames(styles.add_this_icons_container, styles.icon)}>
            <img src={linkedInIcon} alt='LinkedIn Icon'/>
          </div>}
        {showGoogle &&
          <div
            id={`${id}_GooglePlus_Icon`}
            className={classNames(styles.add_this_icons_container, styles.icon)}>
            <img src={googlePlusIcon} alt='Google Plus Icon' />
          </div>}
      </div>
    </div>
  );
});

export default memo(AddThis);
