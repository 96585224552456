import React from 'react';
import { ENTER_KEY, SPACE_KEY, DEL_KEY, BACK_SPACE_KEY, _getKeyboardCode } from 'utils/keyboard';

export default function injectKeyboardFunctionality(
  Component
) {
  return class extends React.Component<any, any> {
    static defaultProps = {
      onRemove: () => { },
      onSelect: () => { },
    }

    handleOnKeyDown = e => {
      const key = _getKeyboardCode(e);
      switch (key) {
        case SPACE_KEY:
        case ENTER_KEY:
          this.props.onSelect(this.props.selectionData);
          break;
        case DEL_KEY:
        case BACK_SPACE_KEY:
          this.props.onRemove(this.props.selectionData);
          break;
        default:
          break;
      }
    }
    render() {
      return <Component {...this.props} tabIndex='0' onKeyDown={this.handleOnKeyDown} />;
    }
  };
}
