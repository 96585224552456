import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import Action from 'components/Modals/Action';
import {
  bulkActionErrorModalToggle,
  shareSubmissionsAction,
  shareVisibility,
} from 'actions/submissionsManager/submissionsActions';
import Highlighted from 'components/Text/Highlighted';
import styles from './ShareSubmissions.css';
import AssigneesSelect from 'containers/SubmissionsManager/Components/AssigneesSelect';
import RichTextEditor from 'components/RichTextEditor';
import {EditorState, ContentState} from 'draft-js';
import LargeModal from 'components/Modals/LargeModal';
import {editorStateToStructure} from 'utils/richTextEditor/conversion';

const ShareSubmissionsModal = ({
  formId,
  formDetails,
  isOpen,
  setIsOpen,
  submissionIds,
  allSubmissionIds,
  isAllSelected,
  selectedFilesCount,
  totalSubmissions,
  shareSubmissions,
  errorModalToggle,
  users,
}) => {
  const [emails, setEmails] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState(EditorState
    .createWithContent(ContentState.createFromText('I want to share this submission document with you.')));
  const [error, setError] = useState(false);
  const [excludedSubmissionIds, setExcludedSubmissionIds] = useState([]);

  useEffect(() => {
    if (!!formDetails?.name) {
      setSubject('Submission Shared: ' + formDetails.name);
    }
  }, [formDetails]);

  useEffect(() => {
    setExcludedSubmissionIds(allSubmissionIds.filter(id => !submissionIds.includes(id)));
  }, [submissionIds]);

  const flushState = () => {
    setEmails([]);
    setSubject('Submission Shared: ' + formDetails.name);
    setMessage(EditorState
      .createWithContent(ContentState.createFromText('I want to share this submission document with you.')));
  };

  const handleCancelClick = () => {
    setIsOpen(false);
  };

  const handleOnOkClick = () => {
    if (emails.length > 0 && !error) {
      shareSubmissions({
        message: editorStateToStructure(message),
        subject,
        formId,
        submissionIds,
        includeAllSubmissions: isAllSelected,
        excludedSubmissionIds,
        emails,
        onError: () => errorModalToggle(true),
        onSuccess: flushState,
      });
    } else {
      setError(true);
    }
  };

  const handleSelect = data => {
    setEmails(data);
    setError(false);
  };

  const modalActions = [
    <span key='note' className={styles.note}><strong>Note:&nbsp;</strong>Only completed submissions will be shared</span>,
    <Action key='cancel' label='Cancel' secondary onClick={handleCancelClick} />,
    <Action
      key='share'
      label='Share'
      primaryBlue
      disabled={false}
      onClick={handleOnOkClick}
    />,
  ];

  return (<LargeModal
    title='Share By Email'
    titleIcon='share'
    actions={modalActions}
    onClose={handleCancelClick}
    open={isOpen}
  >
    <p className={styles.Text}>
      You are sharing{' '}
      <Highlighted
        text={isAllSelected ? (totalSubmissions - excludedSubmissionIds.length) : selectedFilesCount}
        animating={isAllSelected}
      />
      {` out of ${totalSubmissions} `}
      {totalSubmissions !== 1 ? 'submissions. ' : 'submission. '}
    </p>
    <div className={styles.FieldWrapper}>
      <label htmlFor='emails' className={styles.Label}>Recipient(s):</label>
      <div className={styles.SelectWrapper}>
        <AssigneesSelect
          options={users}
          selected={emails}
          setSelected={handleSelect}
          customClassName={classNames(styles.CustomSelectStyle, {[styles.Error]: !!error})}
          // @ts-ignore
          customResultsClassName={styles.customResultsClassName}
          allowNonRegisteredUsers
          error={error}
          // @ts-ignore
          setError={setError}
          multiple
        />
      </div>
    </div>
    <div className={styles.FieldWrapper}>
      <label htmlFor='subject' className={styles.Label}>Subject:</label>
      <input name='subject' value={subject} onChange={e => setSubject(e.target.value)} className={styles.Input}/>
    </div>
    <div className={styles.FieldWrapper}>
      <label htmlFor='message' className={styles.Label}>Message:</label>
      <RichTextEditor editorState={message} onChange={data => setMessage(data)} />
    </div>
  </LargeModal>);
};

const mapStateToProps = state => ({
  isOpen: state.submissions.shareVisible,
  users: Object.keys(state.users).map(key => state.users[key]),
  formId: state.submissionTable.formId,
  formDetails: state.submissionTable.formDetails,
  allSubmissionIds: state.submissionTable.table.records.map(record => record.submissionId),
});

const mapDispatchToProps = dispatch => ({
  setIsOpen: payload => dispatch(shareVisibility(payload)),
  shareSubmissions: payload => dispatch(shareSubmissionsAction(payload)),
  errorModalToggle: payload => dispatch(bulkActionErrorModalToggle(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareSubmissionsModal);
