import { find } from 'lodash';
import { PaymentStatus } from 'constants/payments';
import { GovOSPaymentType, PaymentCellData, PaymentType } from 'types/submissionsManager/tableTypes';

// type Payment = {
//   amount: RecordDetailType,
//   attribute: RecordDetailType,
//   authCode: RecordDetailType,
//   paymentEffectiveDate: RecordDetailType,
//   paymentString: RecordDetailType,
//   status: RecordDetailType,
//   transactionDate: RecordDetailType,
//   transactionId: RecordDetailType,
//   transactionMode: RecordDetailType,
//   transactionType: RecordDetailType,
// };

const getPaymentNodeFromKey = (payment, key) => {
  const node = find(payment, input => input.input_name === key);
  return node ? node : { raw_value: '', label: '', input_name: '' };
};

export const mapPaymentNodeToObject = payment => {
  if (payment.length >= 4) {
    const amount = getPaymentNodeFromKey(payment, 'amount');
    const attribute = getPaymentNodeFromKey(payment, 'attribute');
    const status = getPaymentNodeFromKey(payment, 'status');
    const authCode = getPaymentNodeFromKey(payment, 'auth_code');
    const paymentEffectiveDate = getPaymentNodeFromKey(payment, 'paymentEffectiveDate');
    const paymentString = getPaymentNodeFromKey(payment, 'paymentString');
    const transactionDate = getPaymentNodeFromKey(payment, 'transaction_date');
    const transactionMode = getPaymentNodeFromKey(payment, 'transaction_mode');
    const transactionType = getPaymentNodeFromKey(payment, 'transaction_type');
    const transactionId = getPaymentNodeFromKey(payment, 'transaction_id');

    const amountRawValue = amount.raw_value.replace(/\$/, '');
    return {
      amount: { ...amount, raw_value: amountRawValue },
      attribute,
      status,
      authCode,
      paymentEffectiveDate,
      paymentString,
      transactionDate,
      transactionMode,
      transactionType,
      transactionId,
    };
  }
  return null;
};

const seamlessPayWebform = /Payment_PaymentSeamlessPay_\w+|plugin_SeamlessPay_\w+/;
export const isPaymentKey = (key: string) => {
  switch (key) {
    case 'payment_PaymentAuthorizeNet':
    case 'payment_PaymentElavon':
    case 'payment_PaymentForte':
    case 'payment_PaymentInvoiceCloud':
    case 'payment_PaymentSeamlessPay':
    case 'payment_PaymentStripe':
    case 'payment_PaymentUSBankIowa':
    case 'payment_PaymentNIC':
    case 'payment_PaymentMunicipay':
    case 'plugin_PaymentElavon':
    case 'plugin_PaymentInvoiceCloud':
    case 'payment_PaymentForteCheckout':
    case 'payment_PaymentXpressBillPay':
      return true;
    default:
      return seamlessPayWebform.test(key);
  }
};

export const findPaymentField = (record: { submissionData: any[]; }) =>
  record.submissionData.find(field => isPaymentKey(field.input_name));

export const calculateTotalCollectedValue = (payment: PaymentType[]): number => {
  const processedTransaction = payment.filter(item => PaymentStatus[item.transactionType] === 'processed').pop();
  const refundTransaction = payment.filter(item => PaymentStatus[item.transactionType] === 'refunded').pop();

  // If we don't have a processed transaction, we can't calculate the total collected value
  if (!processedTransaction) {
    return 0;
  }

  // If we have a processed transaction but no refund, we can just return the processed amount
  if (!refundTransaction) {
    return processedTransaction.amount;
  }

  // If we have a processed transaction and a refund, we need to subtract the refund amount from the processed amount
  return processedTransaction.amount - refundTransaction.amount;
};


function isPaymentActive(payment: PaymentType | GovOSPaymentType) {
  switch (payment.integrationType) {
    case 'govos':
      return payment.transactionStatus === 'SUCCESS';
    case 'spreedly':
    default:
      return payment.status === 'active';
  }
}

const getSpreedlyPaymentData = (payment: PaymentType[]): PaymentCellData => {
  const activePayment = payment.filter(isPaymentActive).pop();

  if (!activePayment) {
    return {
      isActive: false,
      amount: 0,
      status: '',
    };
  }

  return {
    isActive: true,
    amount: calculateTotalCollectedValue(payment),
    status: PaymentStatus[activePayment.transactionType],
  };
};

const getGovOSPaymentData = (payment: GovOSPaymentType[]): PaymentCellData => {
  const activePayment = payment.filter(isPaymentActive).pop();

  if (!activePayment) {
    return {
      isActive: false,
      amount: 0,
      status: '',
    };
  }

  return {
    isActive: true,
    amount: activePayment.amount,
    status: PaymentStatus.purchase, // We currently only have the purchase status for GovOS payments
  };
};

export const getPaymentData = (payment: PaymentType[] | GovOSPaymentType[]): PaymentCellData => {
  if (!payment || !payment.length) {
    return {
      isActive: false,
      amount: 0,
      status: '',
    };
  }

  if (payment[0]?.integrationType === 'govos') {
    return getGovOSPaymentData(payment as GovOSPaymentType[]);
  }

  return getSpreedlyPaymentData(payment as PaymentType[]);
};
