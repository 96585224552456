import React from 'react';
import _ from 'lodash';
import styles from './SubmissionDetails.css';
import features from '../../features';
import FieldItem from '../FieldItem';
import Icon from '@material-ui/core/Icon';
import RefundPaymentModal from '../Modals/RefundPaymentModal';
import { joinClassNames } from 'utils/strings';

export default class PaymentBoxContent extends React.Component<any, any> {
  content

  render() {
    const {
      amount,
      attribute,
      authCode,
      onRefundModalToggle,
      paymentEffectiveDate,
      paymentString,
      processor,
      refundVisible,
      status,
      transactionDate,
      transactionMode,
      transactionID,
      transactionType,
    } = this.props;

    const isStatusSentence = status.raw_value.split(' ').length > 1;

    return (
      <span>
        <ul ref={c => { this.content = c; }}>
          <li className={isStatusSentence ? styles.boxListItem : styles.boxListHalfLeft}>
            <Icon classes={{ root: styles.boxListIcon }}>attach_money</Icon>
            <div className={styles.boxListContent}>
              <FieldItem label={amount.label} fieldValue={`$${amount.raw_value}`} />
            </div>
          </li>
          <li className={isStatusSentence ? styles.boxListItem : styles.boxListHalf}>
            <Icon classes={{ root: styles.boxListIcon }}>flag</Icon>
            <div className={styles.boxListContent}>
              <FieldItem label={status.label} fieldValue={_.capitalize(status.raw_value)} />
            </div>
          </li>
          <li className={styles.boxListItem}>
            <Icon classes={{ root: styles.boxListIcon }}>business</Icon>
            <div className={styles.boxListContent}>
              <FieldItem label='Payment Processor' fieldValue={processor} />
            </div>
          </li>
          <li className={styles.boxListItem}>
            <Icon classes={{ root: styles.boxListIcon }}>today</Icon>
            <div className={styles.boxListContent}>
              <FieldItem
                label={transactionDate.label}
                fieldValue={!!transactionDate.raw_value ? transactionDate.raw_value + ' UTC' : 'N/A'}
              />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.withoutBottomBorder)}>
            <Icon classes={{ root: styles.boxListIcon }}>receipt</Icon>
            <div className={styles.boxListContent}>
              <FieldItem label={transactionType.label || 'Transaction Type'} fieldValue={transactionType.raw_value || 'Single Payment'} />
              <FieldItem label={transactionID.label} fieldValue={transactionID.raw_value} />
              {authCode.raw_value && <FieldItem label={authCode.label} fieldValue={authCode.raw_value} />}
              {attribute.raw_value && <FieldItem label={attribute.label} fieldValue={attribute.raw_value} />}
              {paymentEffectiveDate.raw_value &&
                <FieldItem label={paymentEffectiveDate.label} fieldValue={paymentEffectiveDate.raw_value + ' UTC'} />
              }
              {paymentString.raw_value
                && <FieldItem label={paymentString.label} fieldValue={paymentString.raw_value} />}
              {transactionMode.raw_value &&
                <FieldItem label={transactionMode.label} fieldValue={transactionMode.raw_value} />
              }
              {features.paymentDetailsRefund.enabled &&
                <div className={styles.refundPayment} onClick={onRefundModalToggle}>Refund Payment</div>
              }
            </div>
          </li>
        </ul>
        <RefundPaymentModal
          amount={amount.raw_value}
          status={status.raw_value}
          processor={processor}
          transactionDate={transactionDate.raw_value}
          transactionType={transactionType.raw_value || 'Single Payment'}
          transactionID={transactionID.raw_value}
          authCode={authCode.raw_value}
          open={refundVisible}
          onClose={onRefundModalToggle} />
      </span>
    );
  }
}
