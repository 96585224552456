import React, { memo, FC } from 'react';
import type { IdVerificationType } from 'types/submissionsManager/tableTypes';
import styles from './IdVerificationStyles.css';

type IdVerificationContentProps = {
  idVerificationData: IdVerificationType[];
};

const IdVerificationContent: FC<IdVerificationContentProps> = memo(({ idVerificationData }) => {
  if (!idVerificationData) return null;

  const getStatusText = (status: string) => {
    switch (status) {
      case 'COMPLETED':
        return 'Verified';
      case 'CREATED':
        return 'Not Verified (Created)';
      case 'FAILED':
        return 'Not Verified (Failed)';
      default:
        return 'Not Verified';
    }
  };


  return (
    <div className={styles.contentContainer} role='region' aria-live='polite'>
      <div className={styles.headerWrapper}>
        <h2 className={styles.headerLabel}>Verification ID Data</h2>
      </div>
      {idVerificationData.map(({ id, idVerificationType, status, formattedWebhookPayload }, i) => (
        <div key={`${id}-${i}`} className={styles.contentWrapper}>
          <div className={styles.detailsRow}>
            <div className={styles.nameText}>Verification Status:</div>
            <span
              className={status === 'COMPLETED' ? styles.verified : styles.notVerified}
              aria-live='assertive'
            >
              {getStatusText(status)}
            </span>
          </div>
          {formattedWebhookPayload.map(({ name, value }, index) => (
            <div key={`${idVerificationType}-${index}`} className={styles.detailsRow}>
              <span className={styles.nameText}>{name}:</span>
              <span>{value}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
});

export default IdVerificationContent;
