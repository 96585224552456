import { validateEmailAddress } from 'utils/email';

export const ensureAbsoluteURL = (url: string) => {
  // If the URL is absolute, protocol-relative, or an absolute path return it
  if (/^(https?:\/|\/|)\/[^/].*/.test(url)) {
    return url;
  }
  // If the URL is an email address or a tel link, return it
  if (/^(mailto|tel):.*/.test(url)) {
    return url;
  }
  // If the URL looks like an email address, return it with the mailto protocol
  if (validateEmailAddress(url)) {
    return `mailto:${url}`;
  }
  // Otherwise, return it as an absolute URL
  return `http://${url}`;
};
