import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './BulkActionWrapper.css';
import { bindActionCreators } from 'redux';
import { createTags } from 'actions/tags';
import AssigneesSelect from '../Components/AssigneesSelect';
import { User } from 'types/users';

const AddAssignee = props => {
  const { options, addAssignee } = props;
  const [value, setValue] = useState<User[]>([]);

  const handleAddAssignee = () => {
    const { dispatch, submissionIds, formId, errorModalToggle, includeAllSubmissions, excludedSubmissionIds } = props;
    const userIds = value.map(user => user.userId);
    addAssignee({
      submissionIds,
      userIds,
      formId,
      includeAllSubmissions,
      excludedSubmissionIds,
      dispatch,
      onError: () => errorModalToggle(true)});
  };

  const onAddTagButtonClick = () => {
    const { includeAllSubmissions, loaderModalToggle, submissionIds } = props;
    const submissionsNumber = includeAllSubmissions ? 'all the' : `the ${submissionIds.length} selected`;
    loaderModalToggle(
      true,
      'addAssignee',
      'Add Assignees',
      `Are you sure you want to add assignees for ${submissionsNumber} submission(s)?`,
      handleAddAssignee,
    );
  };

  return (
    <div className={styles.addToContainer}>
      <label className={styles.label} htmlFor='addAssignee'>Add assignees:</label>
      <div className={styles.controlsContainer}>
        <div className={styles.tagsInput}>
          <AssigneesSelect options={options} selected={value} setSelected={setValue} multiple/>
        </div>
        <button type='button' className={styles.button} onClick={onAddTagButtonClick} disabled={!value.length}>Add</button>
      </div>
    </div>);
};

export default connect(null, dispatch => ({
  createTag: bindActionCreators(createTags, dispatch),
  dispatch,
}))(AddAssignee);
