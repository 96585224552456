import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popover from 'components/Popover';
import StageEditor from './StageEditor';
import * as stagesActions from 'actions/stages';
import stageEditStyles from './StageEditor.css';

import styles from '../Stage.css';
import editIcon from 'icons/stages/edit.svg';


class StageEditorContainer extends PureComponent<any, any> {
  updateStage = newStage => {
    const updatedStage = { ...newStage, color: newStage.color };
    this.props.actions.updateStage(this.props.formId, this.props.stage.name, updatedStage);
    this.props.closeEditor();
  }

  render() {
    const { stage, isEditing, closeEditor, openEditor } = this.props;
    return (
      <Popover
        isOpen={isEditing}
        requestClose={closeEditor}
        requestOpen={openEditor}
        widthClassName={stageEditStyles.stageEditWidth}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        tabIndex='-1'
        renderTriggerButton={() => (
          <img className={styles.editIcon} src={editIcon} alt='Edit stage' />
        )}>
        <StageEditor stage={stage} updateStage={this.updateStage} closeEditor={closeEditor} />
      </Popover>
    );
  }
}

const mapStateToProps = ({ submissionTable: { formId } }) => ({ formId });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(stagesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StageEditorContainer);
