import React from 'react';
import {
  ASSIGNMENTS_COLUMN_LABEL,
  CHECKBOX_WIDTH,
  COMPLETION_COLUMN_LABEL,
  ID_VERIFICATION_STATUS_COLUMN_LABEL,
  PAYMENT_AMOUNT_COLUMN_LABEL,
  PAYMENT_STATUS_COLUMN_LABEL,
  ROW_NUMBER_CELL_WIDTH,
  STAGES_COLUMN_LABEL,
  SUBMISSIONS_ICON_WIDTH,
  TABLE_CELL_WIDTH,
  TAGS_COLUMN_LABEL,
} from 'constants/tableConsts';
import { textWithEllipsis } from 'utils/textHelper';
import Icon from '@material-ui/core/Icon';
import features from '../../features';
import { every, intersection, keyBy, some, times, union } from 'lodash';
import api from 'actions/api';
import { generateCapabilitiesGetters } from 'utils/auth';
import { makeAttachmentKey } from 'utils/submissionsManager/attachments';
import { immutableInsertElementInArray } from 'utils/reduxHelper';
// import type { UserCapabilities } from 'types/auth';
import {
  ActionPayload,
  ColumnComponentsType,
  DecryptionAction,
  SearchTableJSON,
  SelectedRowDataType,
  SubmissionTableType,
  TableFromRecordsType,
  TableJSON,
  TableType,
} from 'types/submissionsManager/tableTypes';
import { Props } from 'types/submissionsManager/submissionTable';
import { SortingForServer } from 'types/submissionsManager/submissionSorting';
import { Column as ColumnT, SubmissionFilterForServer } from 'types/submissionsManager/submissionFilters';
import { selectAllSupportedActions, SelectAllSupportedActionType } from 'constants/submissionManager';
// import type { FormDetails, FormMetadata } from 'types/forms';
// import type { Features } from 'types/features';

type ColumnStyles = { [key: string]: string };
type TableColumnStyles = { [key: string]: string };

export const ONLY_ACTIVE_SUBMISSIONS = 'OnlyActive';

export function formatRecord(record: SelectedRowDataType): SelectedRowDataType {
  const nodes = {};
  record.submissionData.forEach(node => {
    nodes[node.input_name] = node;
  });

  return {
    assignees: record.assignees,
    attachments: record.attachments,
    createdTs: record.createdTs,
    isActive: record.isActive,
    isArchived: record.isArchived,
    isIncomplete: record.isIncomplete,
    modifiedTs: record.modifiedTs,
    nodes,
    payment: record.payment,
    payments: record.payments,
    permissions: record.permissions,
    signatureHistory: record.signatureHistory,
    signatureStatus: record.signatureHistory.status,
    stageName: record.stageName,
    submissionData: record.submissionData,
    submissionId: record.submissionId,
    submissionPDFUrl: record.submissionPDFUrl,
    tags: record.tags,
    idVerificationData: [],
  };
}

export function createTableFromRecords(records: SelectedRowDataType[]): TableFromRecordsType {
  if (!records || records.length === 0) {
    return {
      records: [],
      labels: {},
    };
  }
  const recordList = records.map(formatRecord);
  const uniqueLabelSet = {};
  records
    .map(record => record.submissionData)
    .reduce((acc, curr) => acc.concat(curr))
    .forEach(({ input_name: inputName, label }) => {
      // typeof check is to have the table headers show the latest label of the input
      if (inputName != null && typeof uniqueLabelSet[inputName] !== 'string') {
        uniqueLabelSet[inputName] = label;
      }
    });
  const maximumAttachmentsCount = Math.max(...records.map(record => record.attachments.length));
  times(maximumAttachmentsCount, i => {
    uniqueLabelSet[makeAttachmentKey(i)] = `Attachment ${i + 1}`;
  });

  const activeRecordList = recordList.filter(submission => submission.isActive);
  return {
    records: activeRecordList,
    labels: uniqueLabelSet,
  };
}

export function filterLabelsByConditions(labels: { label: string, shouldDisplay: boolean }[]) {
  return labels.reduce((prev, next) => {
    if (!next.shouldDisplay) return prev;
    return [...prev, next.label];
  }, []);
}

// export const shouldDisplayCompletionColumn = (records: SelectedRowDataType[], { QUICK_FILTERS_SUPPORT }): Features =>
export const shouldDisplayCompletionColumn = (records: SelectedRowDataType[], { QUICK_FILTERS_SUPPORT }) =>
  !!QUICK_FILTERS_SUPPORT && some(records, record => record.isIncomplete);

export function createTableFromJSON({
  isSearching,
  offset,
  records,
  requestTime,
  totalVisibleSubmissionsForFilter,
  totalVisibleSubmissionsForForm,
  fieldOrdering,
}: TableJSON): TableType {
  const table = createTableFromRecords(records);

  return {
    labels: table.labels,
    isSearching,
    offset,
    records: table.records,
    requestTime,
    size: table.records.length,
    sortingFromServer: fieldOrdering || [],
    totalSubmissions: totalVisibleSubmissionsForForm,
    totalSubmissionsForFilter: totalVisibleSubmissionsForFilter,
  };
}

export function createTableFromSearchJSON({
  estimatedHits,
  isSearching,
  offset,
  records,
  requestTime,
  fieldOrdering,
}: SearchTableJSON): TableType {
  return createTableFromJSON({
    fieldOrdering,
    isSearching,
    offset,
    records,
    requestTime,
    totalVisibleSubmissionsForFilter: estimatedHits,
    totalVisibleSubmissionsForForm: estimatedHits,
  });
}

export function mergeTables(table1: TableType, table2: TableType): TableType {
  return {
    totalSubmissions: table2.totalSubmissions,
    totalSubmissionsForFilter: table2.totalSubmissionsForFilter,
    size: table1.size + table2.size,
    records: table1.records.concat(table2.records),
    sortingFromServer: table1.sortingFromServer || table2.sortingFromServer,
    labels: Object.assign({}, table1.labels, table2.labels),
    nextPageToken: table2.nextPageToken,
  };
}

function isStaticFieldColumn(id) {
  return /^(hr|h2|h3)_/.test(id);
}

export function getFilteredColumnsFromServerColumns(
  sortingFromServer: string[],
  table: TableType,
  // featureFlags: Features,
  featureFlags: any,
  // userCapabilities: UserCapabilities,
  userCapabilities: any,
): string[] {
  const {
    ASSIGNMENTS_IN_SUBMISSION_MANAGER_SUPPORT,
    STAGES_IN_SUBMISSION_MANAGER_SUPPORT,
    TAGS_IN_SUBMISSION_MANAGER_SUPPORT,
  } = featureFlags;

  const { records, labels: tableLabels } = table;
  const capabilityGetters = generateCapabilitiesGetters(userCapabilities);

  const hasPayments = some(records, record => record.payments || record.govOsPayments);
  const allIncomplete = every(records, record => record.isIncomplete);
  const includeAssignments =
    capabilityGetters.canUserReadAssignedSubmissions() && !!ASSIGNMENTS_IN_SUBMISSION_MANAGER_SUPPORT && !allIncomplete;
  const includePayments = !allIncomplete && hasPayments;
  const includeTags = capabilityGetters.canUserAccessTags() && !!TAGS_IN_SUBMISSION_MANAGER_SUPPORT && !allIncomplete;
  const includeStage = !!STAGES_IN_SUBMISSION_MANAGER_SUPPORT && !allIncomplete;
  const includeIncomplete = shouldDisplayCompletionColumn(records, featureFlags);

  const labels = [
    {
      label: 'createdTs',
      shouldDisplay: true,
    },
    {
      label: 'signatureStatus',
      // records will either have a signatureStatus that is a string, or not have a signatureStatus
      shouldDisplay: some(records, record => record.signatureHistory.status !== null),
    },
    {
      label: COMPLETION_COLUMN_LABEL,
      shouldDisplay: includeIncomplete,
    },
    {
      label: PAYMENT_AMOUNT_COLUMN_LABEL,
      shouldDisplay: includePayments,
    },
    {
      label: PAYMENT_STATUS_COLUMN_LABEL,
      shouldDisplay: includePayments,
    },
    {
      label: ASSIGNMENTS_COLUMN_LABEL,
      shouldDisplay: includeAssignments,
    },
    {
      label: TAGS_COLUMN_LABEL,
      shouldDisplay: includeTags,
    },
    {
      label: STAGES_COLUMN_LABEL,
      shouldDisplay: includeStage,
    },
  ];

  const commonLabels = filterLabelsByConditions(labels);
  const formLabels = Object.keys(tableLabels);
  const labelsArray = [...commonLabels, ...formLabels];

  // Use intersection to get labels PRESENT in BOTH arrays (the labels order that came from the server and the
  // list of labels we calculated we should display) in the order of the first (the server order).
  // That should sort the labels and filter out the ones they had stored in the DB but we shouldn't display.
  // Then use union to include in that result the ones they may have missing from our calculated list.
  return union(intersection(sortingFromServer || [], labelsArray), labelsArray)
    .filter(id => !isStaticFieldColumn(id));
}

export function getTableColumnsWithLabels(
  columns: string[],
  labels: { key: string }
): ColumnT[] {
  const result: ColumnT[] = columns.map(column => {
    if (labels[column]) {
      return {
        key: column,
        label: labels[column],
      };
    }

    switch (column) {
      case 'signatureStatus':
        return {
          key: column,
          label: 'Signature Status',
        };
      case 'createdTs':
        return {
          key: column,
          label: 'Submission Created',
        };
      case COMPLETION_COLUMN_LABEL:
        return {
          key: column,
          label: 'Status',
        };
      case ASSIGNMENTS_COLUMN_LABEL:
        return {
          key: column,
          label: 'Assigned to',
        };
      case TAGS_COLUMN_LABEL:
        return {
          key: column,
          label: 'Tags',
        };
      case STAGES_COLUMN_LABEL:
        return {
          key: column,
          label: 'Stage',
        };
      case PAYMENT_AMOUNT_COLUMN_LABEL:
        return {
          key: column,
          label: 'Total Collected',
        };
      case PAYMENT_STATUS_COLUMN_LABEL:
        return {
          key: column,
          label: 'Payment Status',
        };
      default:
        return {
          key: column,
          label: 'Empty',
        };
    }
  });
  return result;
}

// this gets array rearranging signal from TableSort and does the job
// and passes to reducer for column rearrangement
// this function is tested in /components/SubmissionTable/TableSort.spec.js
// anyone is welcomed to rewrite this in a simpler way that passes tests
export function rearrangeArray(targetArray: string[], prevIndex: number, nextIndex: number): string[] {
  if (targetArray.length === 0) {
    return [];
  }

  return targetArray.map((element, index, array) => {
    const target = array[prevIndex];
    if (prevIndex < nextIndex) {
      if (index === nextIndex) {
        return target;
      } else if (index < prevIndex) {
        return element;
      } else if (index > nextIndex) {
        return element;
      }
      return array[index + 1];
    }
    if (index === nextIndex) {
      return target;
    } else if (index < nextIndex) {
      return element;
    } else if (index > prevIndex) {
      return element;
    }
    return array[index - 1];
  });
}

const checkLabelAndVerificationMatch = (label: string, records: SelectedRowDataType[]): boolean => {
  if (!records || records.length === 0) {
    return false;
  }

  // Iterate through all records to find a match
  for (const record of records) {
    // Check if the current record has any idVerificationData
    const idVerificationData = record?.idVerificationData;

    if (idVerificationData && idVerificationData.length > 0) {
      // Find the verification item that matches the provided label (formElementId)
      const matchingVerification = idVerificationData.find(verification => verification.formElementId === label);

      if (matchingVerification) {
        return true;
      }
    }
  }
  // If no matches are found after checking all records, return false
  return false;
};


function createColumn(
  headerLabel: string,
  key: string,
  index: number,
  _this: { props: Props },
  styles: ColumnStyles,
  columnComponents: ColumnComponentsType,
  hoveredRowIndex: number | null | undefined,
  width: number = TABLE_CELL_WIDTH,
): JSX.Element {
  const {
    isTableSortable,
    makeSortable,
    showSortingArrowIndex,
    sortingTargetIndex,
    handleDecrypt,
    table,
  } = _this.props;

  const { records } = table;
  const { Cell, Column, RecordCell, SortFilterDropdown } = columnComponents;

  // Sort arrow is being toggled between off, down, up states, see line 285 for details
  const isShowingSortArrow =
    features.tableSort.enabled && (showSortingArrowIndex % 3 !== 0 && sortingTargetIndex === index);
  const isShowingDownArrow = showSortingArrowIndex % 3 === 1;
  const headerLableTruncateLength = 19;

  const colHeader = (
    <Cell>
      <div
        className={isShowingSortArrow ? styles.columnHeaderBold : styles.columnHeader}
        onMouseDown={() => makeSortable(index, textWithEllipsis(headerLabel, headerLableTruncateLength))}>
        {textWithEllipsis(headerLabel, headerLableTruncateLength)}
        {isShowingSortArrow && (
          <Icon classes={{ root: styles.sortArrow }}>{isShowingDownArrow ? 'arrow_downward' : 'arrow_upward'}</Icon>
        )}
        <SortFilterDropdown columnKey={key} />
      </div>
    </Cell>
  );

  return (
    <Column
      header={() => colHeader}
      key={key}
      columnKey={key}
      allowCellsRecycling
      cell={({ rowIndex }) => (
        <RecordCell
          rowIndex={rowIndex}
          columnKey={key}
          isHovered={rowIndex === hoveredRowIndex}
          isTableSortable={isTableSortable}
          records={records}
          handleDecrypt={handleDecrypt} />
      )}
      width={width} />
  );
}

type CreateColumnProps = {
  _this: { props: Props },
  columnComponents: ColumnComponentsType,
  hoveredRowIndex: number | null | undefined,
  includeCheckboxes: boolean,
  isIncompleteOrHasSubmissionPdfUrl: boolean,
  sortedFilteredColumns: string[],
  styles: TableColumnStyles,
};

export function createTableColumns({
  _this,
  styles,
  columnComponents,
  hoveredRowIndex,
  isIncompleteOrHasSubmissionPdfUrl,
  includeCheckboxes,
  sortedFilteredColumns,
}: CreateColumnProps): JSX.Element[] {
  const { table, selected, onCheck, isAllSelected, isAllVisible, selectAllOnCheck, trackDownloadFile } = _this.props;
  const { Column, CheckboxCell, SubmissionCellIcon } = columnComponents;
  const { records, labels } = table;

  /*
 * table column and cell width are controlled externally here
 * do not change lightly! this width is depended upon by
 * the sorting column component
 */
  const columnList: JSX.Element[] = [];

  // Utility function to create a generic column
  const createGenericColumn = (
    headerName: string,
    columnKey: string,
    index: number
  ) => createColumn(headerName, columnKey, index, _this, styles, columnComponents, hoveredRowIndex);

  // Row Number Column
  columnList.push(
    <Column
      header={() => <div className={styles.rowNumberHeader}>#</div>}
      key='rowNumber'
      columnKey='rowNumber'
      allowCellsRecycling
      cell={props =>
        records.length > 0 ? <span className={styles.rowNumberCell}>{props.rowIndex + 1}</span> : <span />
      }
      width={ROW_NUMBER_CELL_WIDTH}
    />
  );

  // Checkbox Column (if needed)
  if (includeCheckboxes) {
    columnList.push(
      <Column
        header={() =>
          isAllVisible ? (
            <CheckboxCell checked={isAllSelected} onCheck={() => selectAllOnCheck(!isAllSelected)} />
          ) : (
            <div className={styles.columnHeader} />
          )
        }
        key='selected'
        columnKey='selected'
        allowCellsRecycling
        cell={props =>
          records.length > 0 ? (
            <CheckboxCell {...props} selected={selected} records={records} onCheck={onCheck} />
          ) : (
            <div />
          )
        }
        width={CHECKBOX_WIDTH}
      />
    );
  }

  // Submission Icon Column (if applicable)
  if (isIncompleteOrHasSubmissionPdfUrl) {
    columnList.push(
      <Column
        header={() => <div className={styles.columnHeader} />}
        key='submissionIcon'
        columnKey='submissionIcon'
        allowCellsRecycling
        cell={props => (
          <SubmissionCellIcon
            rowIndex={props.rowIndex}
            submissionRecord={records[props.rowIndex]}
            trackDownloadFile={trackDownloadFile}
          />
        )}
        width={SUBMISSIONS_ICON_WIDTH}
      />
    );
  }

  // Dynamic Column Creation based on sortedFilteredColumns
  sortedFilteredColumns.forEach((label: string, index: number) => {
    const labelText = labels[label];

    if (labelText && checkLabelAndVerificationMatch(label, records)) {
      columnList.push(createGenericColumn(labelText, `${ID_VERIFICATION_STATUS_COLUMN_LABEL}_${label}`, index));
    } else if (labelText) {
      columnList.push(createGenericColumn(labelText, label, index));
    } else {
      switch (label) {
        case 'signatureStatus':
          columnList.push(createGenericColumn('Signature Status', 'signatureStatus', index));
          break;
        case 'createdTs':
          columnList.push(createGenericColumn('Submission Created', 'createdTs', index));
          break;
        case COMPLETION_COLUMN_LABEL:
          columnList.push(createGenericColumn('Status', COMPLETION_COLUMN_LABEL, index));
          break;
        case ASSIGNMENTS_COLUMN_LABEL:
          columnList.push(createGenericColumn('Assigned to', ASSIGNMENTS_COLUMN_LABEL, index));
          break;
        case TAGS_COLUMN_LABEL:
          columnList.push(createGenericColumn('Tags', TAGS_COLUMN_LABEL, index));
          break;
        case STAGES_COLUMN_LABEL:
          columnList.push(createGenericColumn('Stage', STAGES_COLUMN_LABEL, index));
          break;
        case PAYMENT_AMOUNT_COLUMN_LABEL:
          columnList.push(createGenericColumn('Total Collected', PAYMENT_AMOUNT_COLUMN_LABEL, index));
          break;
        case PAYMENT_STATUS_COLUMN_LABEL:
          columnList.push(createGenericColumn('Payment Status', PAYMENT_STATUS_COLUMN_LABEL, index));
          break;
        default:
          columnList.push(createGenericColumn('Empty', label, index));
          break;
      }
    }
  });

  return columnList;
}


export const mergeVisibleColumnsWithHidden = (columnsFromServer: string[], filteredColumns: string[]) => {
  // Any columns that were filtered out should be anchored in their positions
  const filteredColumnsExistenceLookup = keyBy(filteredColumns);
  const mergedVisibleAndInvisibleColumns = columnsFromServer.reduce((accumulator, currentValue, currentIndex) => {
    if (!filteredColumnsExistenceLookup[currentValue]) {
      return immutableInsertElementInArray(accumulator, currentIndex, currentValue);
    }
    return accumulator;
  }, filteredColumns);
  return mergedVisibleAndInvisibleColumns;
};

/*
 * This is the helper that toggles the sorting arrow on the table header text
 * It is complicated because on every header click, this function is fired 'twice'
 * Because a click is broken into a mouseDown and mouseUp, which shows/hides the
 * draggable box. Due to this, this function needs to track whether each firing is
 * up or down, whether different header is clicked & whether the arrow is currently shown
 * this helper is being used in submissionTable reducer
 */
export const toggleSortingArrow = (actionPayload: ActionPayload, state: SubmissionTableType): number => {
  const { showSortingArrow } = actionPayload;
  const { showSortingArrowIndex, sortingTargetIndex } = state;
  const isSelectingDifferentHeader = actionPayload.sortingTargetIndex !== sortingTargetIndex;
  const isMouseUp = showSortingArrow;
  const isArrowCurrentlyShowing = showSortingArrowIndex % 3 !== 0;

  // when showSortingArrowIndex is divisible by 3, the arrow is hidden; vice versa
  if (isMouseUp) {
    // mouse is up in here; want to toggle arrow visibility
    if (isSelectingDifferentHeader) {
      if (isArrowCurrentlyShowing) {
        // keep showing the arrow
        return showSortingArrowIndex;
      }
      // show the arrow
      return showSortingArrowIndex + 1;
    }
    // is selecting the same header; toggle the arrow visibility
    return showSortingArrowIndex + 1;
  }
  // mouse is down in the block; do not want to show arrow here;
  if (isSelectingDifferentHeader) {
    if (isArrowCurrentlyShowing) {
      // hide the arrow
      return showSortingArrowIndex + 2;
    }
    // keep hiding the arrow
    return showSortingArrowIndex;
  }
  // is selecting the same header; do not toggle;
  return showSortingArrowIndex;
};

export const decryptionTableHelper = (record: SelectedRowDataType, action: DecryptionAction): SelectedRowDataType => ({
  ...record,
  nodes: {
    ...record.nodes,
    [action.payload.inputName]: {
      ...record.nodes[action.payload.inputName],
      raw_value: action.payload.result,
      isEncrypted: false,
    },
  },
});

export const getSelectedRecords = (
  selectedRowData: SelectedRowDataType | null | undefined,
  selectedRecords?: SelectedRowDataType[],
): SelectedRowDataType[] => {
  if (selectedRecords && selectedRecords.length > 0) {
    return selectedRecords;
  }
  if (selectedRowData) {
    return [selectedRowData];
  }
  return [];
};

// const getFormDetails = (formMetadata: FormMetadata): FormDetails | null | undefined => {
const getFormDetails = formMetadata => {
  if (!formMetadata) return null;
  return {
    alias: formMetadata.formAlias,
    authorName: formMetadata.formAuthorName,
    createdTime: formMetadata.formCreated,
    id: formMetadata.formId,
    name: formMetadata.formName,
  };
};

export const loadSubmissionsAction = (
  baseType: string,
  endpoint: string,
  formId: string,
  paginationToken: string | null | undefined = null,
  offset: number = 0,
  archiveOptions: string | null | undefined = 'OnlyActive',
  filtering?: SubmissionFilterForServer[],
  ordering?: SortingForServer,
) => {
  const basePayload = { formId, includeFieldOrdering: true, archiveOptions };
  const payload = {
    ...basePayload,
    ...(paginationToken ? { paginationToken } : null),
    ...(filtering ? { filtering } : null),
    ...(ordering ? { ordering } : null),
  };
  return api.postWithAuth({
    endpoint,
    data: payload,
    baseType,
    success: (
      _state,
      {
        response: {
          records,
          nextPageToken,
          totalVisibleSubmissionsForFilter,
          totalVisibleSubmissionsForForm,
          fieldOrdering,
          formMetadata,
        },
      },
    ) => {
      const isSearching = false;
      const requestTime = new Date();
      const tableData = createTableFromJSON({
        isSearching,
        offset,
        records,
        requestTime,
        totalVisibleSubmissionsForFilter,
        totalVisibleSubmissionsForForm,
        fieldOrdering,
      });
      return {
        ...tableData,
        formDetails: getFormDetails(formMetadata),
        nextPageToken,
      };
    },
    failure: (_state, error) => ({ error }),
  });
};

export const isSupportedWithSelectAllAndFiltering = (
  input: SelectAllSupportedActionType,
  isAllSelected: boolean,
  isSubmissionFilterApplied: boolean,
): boolean => {
  if (isSubmissionFilterApplied) {
    return isAllSelected ? selectAllSupportedActions[input] : true;
  }
  return true;
};

export const extractVerificationDetails = (
  record: SelectedRowDataType,
  formElementId: string
) => {
  const { idVerificationData } = record;
  let firstName = '';
  let lastName = '';
  let verificationStatus = 'Not Verified';
  // Find the first verification that matches the formElementId
  const matchingVerification = idVerificationData.find(
    verification => verification.formElementId === formElementId
  );

  // If a match is found, extract the verification details
  if (matchingVerification) {
    verificationStatus = matchingVerification.status;

    if (verificationStatus === 'COMPLETED') {
      const firstNamePayload = matchingVerification.formattedWebhookPayload.find(
        item => item.name === "Applicant's First Name"
      )?.value;

      const lastNamePayload = matchingVerification.formattedWebhookPayload.find(
        item => item.name === "Applicant's Last Name"
      )?.value;

      firstName = firstNamePayload || '';
      lastName = lastNamePayload || '';
    }
  }

  return { firstName, lastName, verificationStatus };
};


