import {success} from '../utils/filters';
import {quickFiltersEnum} from 'constants/quickFilters';
import {ASSIGN_USERS, UNASSIGN_USERS} from 'constants/types/assignmentsActionTypes';
import * as apbTypes from 'constants/types/automatedProcessBuilderActionTypes';
import * as paymentGatewaySidebarTypes from 'constants/paymentGatewaySidebar';
import * as paymentConfigurationTypes from 'constants/paymentConfiguration';
import * as paymentSettingsTypes from 'constants/paymentSettings';
import * as paymentConfigurationCredentialsTypes from 'constants/paymentConfigurationCredentials';
import * as submissionsTableActionTypes from 'constants/types/submissionsTableActionTypes';
import {SELECT_QUICK_FILTER} from 'constants/types/quickFilterActionTypes';
import {ASSIGN_TAGS, UNASSIGN_TAGS} from 'constants/types/tagActionTypes';
import {LOG_SUBMISSION_NAVIGATION} from 'constants/types/routingActionTypes';
import {
  CREATE_STAGES,
  DELETE_STAGE,
  UPDATE_STAGE,
  ASSIGN_STAGE,
  UNASSIGN_STAGE,
  REORDER_STAGES,
} from 'constants/types/stageActionTypes';
import {
  getSelectedQuickFilter,
} from '../../reducers';

declare global {
  interface Window {analytics: any}
}

export const assignmentsAssignEventTracker = action$ =>
  action$
    .ofType(ASSIGN_USERS)
    .filter(success)
    .do(({payload: {source}}) => {
      if (window.analytics) {
        window.analytics.track('Submission assign', {
          category: 'Assignments',
          label: `Assign submission from ${source}`,
        });
      }
    })
    .ignoreElements();

export const assignmentsUnassignEventTracker = action$ =>
  action$
    .ofType(UNASSIGN_USERS)
    .filter(success)
    .do(({payload: {source}}) => {
      if (window.analytics) {
        window.analytics.track('Submission unassign', {
          category: 'Assignments',
          label: `Unassign submission from ${source}`,
        });
      }
    })
    .ignoreElements();

export const tagsAssociationEventTracker = action$ =>
  action$
    .ofType(ASSIGN_TAGS)
    .filter(success)
    .do(({payload: {source}}) => {
      if (window.analytics) {
        window.analytics.track('Tag assign', {
          category: 'Tags',
          label: `Assign tag from ${source}`,
        });
      }
    })
    .ignoreElements();

export const tagsDisassociationEventTracker = action$ =>
  action$
    .ofType(UNASSIGN_TAGS)
    .filter(success)
    .do(({payload: {source}}) => {
      if (window.analytics) {
        window.analytics.track('Tag unassign', {
          category: 'Tags',
          label: `Unassign tag from ${source}`,
        });
      }
    })
    .ignoreElements();

export const stagesCreateEventTracker = action$ =>
  action$
    .ofType(CREATE_STAGES)
    .filter(success)
    .do(({payload: { submissionsData }}) => {
      if (window.analytics) {
        window.analytics.track('Stage create', {
          category: 'Stages',
          label: 'Create new stage',
        });
        if (submissionsData.deletedCount > 0 && submissionsData.totalSubmissions === 0) {
          window.analytics.track(`Stage create whith 0 submissions present and ${submissionsData.deletedCount} submissions deleted`, {
            category: 'Stages',
            label: 'Create new stage',
          });
        }
        if (submissionsData.deletedCount === 0 && submissionsData.totalSubmissions === 0) {
          window.analytics.track('Stage create with no submissions received', {
            category: 'Stages',
            label: 'Create new stage',
          });
        }
      }
    })
    .ignoreElements();

export const stagesUpdateEventTracker = action$ =>
  action$
    .ofType(UPDATE_STAGE)
    .filter(success)
    .do(() => {
      if (window.analytics) {
        window.analytics.track('Stage update', {
          category: 'Stages',
          label: 'Change stage name or color',
        });
      }
    })
    .ignoreElements();

export const stagesDeleteEventTracker = action$ =>
  action$
    .ofType(DELETE_STAGE)
    .filter(success)
    .do(() => {
      if (window.analytics) {
        window.analytics.track('Stage delete', {
          category: 'Stages',
          label: 'Delete stage',
        });
      }
    })
    .ignoreElements();

export const stagesAssignEventTracker = action$ =>
  action$
    .ofType(ASSIGN_STAGE)
    .filter(success)
    .do(({payload: {source, isUpdate}}) => {
      if (window.analytics) {
        window.analytics.track(`Stage ${isUpdate ? 'change' : 'assign'}`, {
          category: 'Stages',
          label: `${isUpdate ? 'Change' : 'Assign'} stage from ${source}`,
        });
      }
    })
    .ignoreElements();

export const stagesUnassignEventTracker = action$ =>
  action$
    .ofType(UNASSIGN_STAGE)
    .filter(success)
    .do(({payload: {source, isUnassign}}) => {
      if (window.analytics && isUnassign) {
        window.analytics.track('Stage unassign', {
          category: 'Stages',
          label: `Unassign stage from ${source}`,
        });
      }
    })
    .ignoreElements();

export const stagesReorderEventTracker = action$ =>
  action$
    .ofType(REORDER_STAGES)
    .filter(success)
    .do(() => {
      if (window.analytics) {
        window.analytics.track('Stage reorder', {
          category: 'Stages',
          label: 'Reorder stages',
        });
      }
    })
    .ignoreElements();

export const quickFilterEventTracker = action$ =>
  action$.ofType(SELECT_QUICK_FILTER)
    .filter(action => action.shouldLogAnalytics)
    .do(({quickFilterName}) => {
      if (window.analytics) {
        window.analytics.track(`Filter select: ${quickFilterName}`, {
          category: 'Filters',
          label: `Select quick filter ${quickFilterName}`,
        });
      }
    })
    .ignoreElements();

export const getSubmissionNavigationMetadata = (selectedQuickFilter, source) => {
  const currentView = selectedQuickFilter || 'Folder Tree';
  const isSubmissionManagerFiltered = selectedQuickFilter && selectedQuickFilter !== quickFiltersEnum.ALL;
  const action = isSubmissionManagerFiltered ? 'View Filtered Submissions' : 'View Unfiltered Submissions';
  const label = `from ${currentView} via ${source}`;
  return {
    action,
    label,
  };
};

export const navigationToSubmissionManagerEventTracker = (
  action$,
  store,
) =>
  action$.ofType(LOG_SUBMISSION_NAVIGATION)
    .do(({payload}) => {
      const { source, submissionsCount } = payload;
      if (window.analytics) {
        const state = store.getState();
        const selectedQuickFilter = getSelectedQuickFilter(state);
        const {action, label} = getSubmissionNavigationMetadata(selectedQuickFilter, source);
        window.analytics.track(action, {
          category: 'View Submissions',
          label,
        });
        if (submissionsCount === 0) {
          window.analytics.track('View Sumbissions with 0 submissions present', {
            category: 'View Submissions',
            label,
          });
        }
      }
    })
    .ignoreElements();

export const submissionArchiveEventTracker = action$ =>
  action$
    .ofType(submissionsTableActionTypes.ARCHIVE_SUBMISSION_API)
    .filter(success)
    .do(({ payload }) => {
      switch (payload.snackbarMessage) {
        case 'Restored successfully':
          window.analytics.track('Submission restore', {
            category: 'Submissions',
            label: 'Restore submission',
          });
          break;
        case 'Deleted successfully':
          window.analytics.track('Submission delete', {
            category: 'Submissions',
            label: 'Delete submission',
          });
          break;
        case 'Archived successfully':
        default:
          window.analytics.track('Submission archive', {
            category: 'Submissions',
            label: 'Archive submission',
          });
          break;
      }
    })
    .ignoreElements();

const APB_CATEGORY = 'APB';

export const apbOpenEventTracker = action$ =>
  action$.ofType(apbTypes.SET_APB_VISIBILITY)
    .filter(action => action.isVisible)
    .do(() => {
      if (window.analytics) {
        window.analytics.track('Open APB', {
          category: APB_CATEGORY,
          label: 'APB button clicked from Submission Manager',
        });
      }
    })
    .ignoreElements();

export const apbAddRuleEventTracker = action$ =>
  action$.ofType(apbTypes.ADD_EMPTY_RULE)
    .do(() => {
      if (window.analytics) {
        window.analytics.track('Add new APB Rule', {
          category: APB_CATEGORY,
          label: 'Add rule button clicked in APB',
        });
      }
    })
    .ignoreElements();

export const apbDeleteRuleEventTracker = action$ =>
  action$.ofType(apbTypes.DELETE_RULE)
    .do(() => {
      if (window.analytics) {
        window.analytics.track('Delete APB Rule', {
          category: APB_CATEGORY,
          label: 'Delete rule button clicked in APB',
        });
      }
    })
    .ignoreElements();

export const apbSaveRuleEventTracker = action$ =>
  action$.ofType(apbTypes.SAVE_RULES)
    .filter(success)
    .do(() => {
      if (window.analytics) {
        window.analytics.track('Save APB Rules', {
          category: APB_CATEGORY,
          label: 'Save rules button clicked in APB',
        });
      }
    }).ignoreElements();

export const apbChangeTriggerEventTracker = action$ =>
  action$.ofType(apbTypes.CHANGE_RULE_TRIGGER)
    .filter(action => !!action.trigger)
    .do(action => {
      if (window.analytics) {
        const actionForAnalytics = `APB Trigger change: ${action.triggerType} to ${action.trigger}`;
        const label = `Changed APB ${action.triggerType} trigger to ${action.trigger}`;
        window.analytics.track(actionForAnalytics, {
          category: APB_CATEGORY,
          label,
        });
      }
    }).ignoreElements();

const PAYMENT_CONFIGURATION_CATEGORY = 'Payment Configuration';

export const openPaymentsModal = (
  action$,
  store,
) =>
  action$.ofType(paymentGatewaySidebarTypes.TOGGLE_MODAL)
    .filter(() => store.getState().ui.paymentGatewaySidebar.isModalOpen)
    .do(() => {
      if (window.analytics) {
        window.analytics.track('Opens Payments Modal', {
          category: PAYMENT_CONFIGURATION_CATEGORY,
        });
      }
    }).ignoreElements();

export const choosesPaymentGateway = action$ =>
  action$.ofType(paymentConfigurationTypes.SET_CHOSEN_GATEWAY)
    .do(action => {
      if (window.analytics) {
        window.analytics.track('Chooses Payment Gateway', {
          category: PAYMENT_CONFIGURATION_CATEGORY,
          label: action.gateway.name,
        });
      }
    }).ignoreElements();

export const paymentGatewayAuth = (
  action$,
  store,
) =>
  action$.ofType(paymentConfigurationCredentialsTypes.CONNECT_PAYMENT_ACCOUNT)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      if (window.analytics) {
        const gatewayName = store.getState().paymentConfiguration.chosenGateway.gatewayName;
        const status = action.status;
        const trackTitle = status === 'success' ? 'Successful' : 'Unsuccessful' + ' Gateway Auth';
        window.analytics.track(trackTitle, {
          category: PAYMENT_CONFIGURATION_CATEGORY,
          label: gatewayName,
        });
      }
    }).ignoreElements();

export const removedPaymentGateway = (
  action$,
  store,
) =>
  action$.ofType(paymentConfigurationTypes.DELETE_GATEWAY)
    .filter(action => action.status === 'success')
    .do(() => {
      if (window.analytics) {
        window.analytics.track('Removes Gateway', {
          category: PAYMENT_CONFIGURATION_CATEGORY,
          label: store.getState().paymentConfiguration.chosenGateway.gatewayName,
        });
      }
    }).ignoreElements();

const trackAmountSourceType = amountSourceTypeTitle => {
  window.analytics.track(amountSourceTypeTitle, {
    category: PAYMENT_CONFIGURATION_CATEGORY,
  });
};

const trackAmountSource = amountSourceTitle => {
  window.analytics.track('Selects Field for Amount Source', {
    category: PAYMENT_CONFIGURATION_CATEGORY,
    label: amountSourceTitle,
  });
};

const trackOptionalPayment = isPaymentOptional => {
  window.analytics.track(isPaymentOptional ? 'Enables' : 'Disables' + ' Optional Payment', {
    category: PAYMENT_CONFIGURATION_CATEGORY,
  });
};

const trackPreAuthorization = authorizeOnly => {
  window.analytics.track(authorizeOnly ? 'Enables' : 'Disables' + ' Pre-Authorization', {
    category: PAYMENT_CONFIGURATION_CATEGORY,
  });
};

const trackSavePaymentConfiguration = gatewayName => {
  window.analytics.track('Saves Payment Configurations', {
    category: PAYMENT_CONFIGURATION_CATEGORY,
    label: gatewayName,
  });
};

const trackPaymentDisables = () => {
  window.analytics.track('Disabled Payment', {
    category: PAYMENT_CONFIGURATION_CATEGORY,
  });
};

export const savePaymentsConfiguration = (
  action$,
  store,
) =>
  action$.ofType(paymentSettingsTypes.SAVE_PAYMENT_SETTINGS)
    .filter(action => action.status === 'success')
    .do(() => {
      if (window.analytics) {
        const paymentsConfig = store.getState().paymentConfiguration.paymentsConfig;
        const amountSource = paymentsConfig.amountSource;
        const fields = store.getState().paymentSettings.fields;
        const amountSourceTypeTitle = paymentsConfig.amountSourceType === 'amount' ?
          'Amount Source - Set Amount' :
          'Amount Source - Field Value';
        trackAmountSourceType(amountSourceTypeTitle);
        trackOptionalPayment(paymentsConfig.isPaymentOptional);
        trackPreAuthorization(paymentsConfig.authorizeOnly);
        trackSavePaymentConfiguration(paymentsConfig.gatewayName);
        if (paymentsConfig.amountSourceType === 'field') {
          trackAmountSource(fields.find(element => element.id === amountSource.label));
        }
        if (paymentsConfig.status === 'disabled') {
          trackPaymentDisables();
        }
      }
    }).ignoreElements();

const SUBMISSION_MANAGER_CATEGORY = 'Submission Manager';

const trackTransactionAction = (title, gatewayName, amount) => {
  window.analytics.track(title, {
    category: SUBMISSION_MANAGER_CATEGORY,
    label: gatewayName,
    value: amount,
  });
};

export const processPreAuthPayment = action$ =>
  action$.ofType(submissionsTableActionTypes.PROCESS_PAYMENT)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      if (window.analytics) {
        const preAuthTitle = action.status === 'success' ? 'Successful' : 'Failed' + ' Process Preauthed Payment';
        trackTransactionAction('Attempt Process Preauthed Payment', action.payload.gatewayName, action.payload.amount);
        trackTransactionAction(preAuthTitle, action.payload.gatewayName, action.payload.amount);
      }
    }).ignoreElements();

export const processRefundPayment = action$ =>
  action$.ofType(submissionsTableActionTypes.PROCESS_REFUND)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      if (window.analytics) {
        const refundTitle = action.status === 'success' ? 'Successful' : 'Failed' + ' Payment Refund';
        trackTransactionAction('Attempt Payment Refund', action.payload.gatewayName, action.payload.amount);
        trackTransactionAction(refundTitle, action.payload.gatewayName, action.payload.amount);
      }
    }).ignoreElements();

export default [
  apbAddRuleEventTracker,
  apbChangeTriggerEventTracker,
  apbDeleteRuleEventTracker,
  apbOpenEventTracker,
  apbSaveRuleEventTracker,
  assignmentsAssignEventTracker,
  assignmentsUnassignEventTracker,
  choosesPaymentGateway,
  navigationToSubmissionManagerEventTracker,
  openPaymentsModal,
  paymentGatewayAuth,
  processPreAuthPayment,
  processRefundPayment,
  tagsAssociationEventTracker,
  tagsDisassociationEventTracker,
  removedPaymentGateway,
  savePaymentsConfiguration,
  stagesCreateEventTracker,
  stagesUpdateEventTracker,
  stagesDeleteEventTracker,
  stagesAssignEventTracker,
  stagesUnassignEventTracker,
  stagesReorderEventTracker,
  quickFilterEventTracker,
  submissionArchiveEventTracker,
];
