import { find, findIndex } from 'lodash';
import api from 'reducers/api';
import { GET_USERS } from 'constants/types/userActionTypes';
import {
  ADD_ROLE,
  CHANGE_ALLOW_VISITOR_REGISTRATION,
  CHANGE_AUTHOR,
  CHANGE_PERMISSION_LEVEL,
  CHANGE_REQUIRE_PASSWORD,
  CHANGE_REQUIRE_VISITOR_REGISTRATION,
  CHANGE_ACCESS_CODE_ENABLED,
  CHANGE_ROLE_PERMISSION_LEVEL,
  CHANGE_USER,
  GET_FORM_PERMISSIONS,
  INITIALIZE_PERMISSIONS,
  permissionLevelsEnum,
  REMOVE_PERMISSION,
  REMOVE_ROLE,
  TOGGLE_MODAL,
  TOGGLE_SNACKBAR,
  SAVE_UPDATING_PERMISSIONS_FORM_ID,
  DELETE_UPDATING_PERMISSIONS_FORM_ID,
} from 'constants/permissions';
import { groupPermissionsByUserId, groupPermissionsByRole } from 'utils/permissions';
import { immutableModifyElementInArray, immutableRemoveElementInArray } from 'utils/reduxHelper';


export const initialState = {
  allowVisitorRegistration: false,
  accessCodeEnabled: false,
  authorId: '',
  fetchingPermissions: false,
  fetchingUsers: false,
  formId: '',
  isDirty: false,
  isPdf: false,
  isPublic: true,
  modalOpen: false,
  permissions: [],
  requireVisitorRegistration: false,
  rolePermissions: [],
  snackbarMessage: null,
  snackbarOpen: false,
  updatingFormIds: [],
};

const calculateNewPermissionLevels = (
  currentPermissions,
  isAdding,
  permissionBeingChanged,
) => {
  let newPermissions = currentPermissions;

  if (isAdding) {
    newPermissions = [...currentPermissions, permissionBeingChanged];

    if (permissionBeingChanged === permissionLevelsEnum.ProcessSubmissionsForm) {
      newPermissions.push(permissionLevelsEnum.ShareAllFormSubmissions);
    }

    newPermissions.push(permissionLevelsEnum.ShareForm);

    return Array.from(new Set(newPermissions));
  }

  if (permissionBeingChanged === permissionLevelsEnum.ShareForm) {
    return [];
  }

  return currentPermissions.filter(permissionName => permissionName !== permissionBeingChanged);
};

const getUpdatedPermissionsForUser = (state, action) => {
  // if we are adding, the new state is the permission, any old permissions, and ShareForm if not already included
  // if we are subtracting, remove the permission; remove all permissions if we're subtracting ShareForm
  const permissionList = state.permissions;
  const user = find(permissionList, permission => permission.userId === action.userId);
  if (!user) {
    return [];
  }
  const permissionToChange = user.permissions;
  return calculateNewPermissionLevels(permissionToChange, action.isAddingPermission, action.permissionName);
};

const getUpdatedFormSettings = (state, action) => {
  const { allowVisitorRegistration, requireVisitorRegistration, isPublic } = state;
  const isDirty = true;

  switch (action.type) {
    case CHANGE_REQUIRE_PASSWORD:
      const requireLogin = action.requirePassword;
      return {
        ...state,
        isDirty,
        isPublic: !requireLogin,
        allowVisitorRegistration: (requireLogin || requireVisitorRegistration) && allowVisitorRegistration,
      };
    case CHANGE_REQUIRE_VISITOR_REGISTRATION:
      return {
        ...state,
        isDirty,
        requireVisitorRegistration: action.requireVisitorRegistration,
        allowVisitorRegistration: action.requireVisitorRegistration || (!isPublic && allowVisitorRegistration),
      };
    case CHANGE_ALLOW_VISITOR_REGISTRATION:
      if (isPublic || requireVisitorRegistration) return state;
      return {
        ...state,
        isDirty,
        allowVisitorRegistration: action.allowVisitorRegistration,
      };
    case CHANGE_ACCESS_CODE_ENABLED:
      return {
        ...state,
        isDirty,
        accessCodeEnabled: action.accessCodeEnabled,
      };
    default:
      return state;
  }
};

export default function permissions(state = initialState, _action) {
  const action = _action;
  switch (action.type) {
    case CHANGE_USER:
      const userId = action.userId;
      const index = state.permissions.length;
      if (index === -1) {
        return state;
      }

      return {
        ...state,
        isDirty: true,
        permissions: immutableModifyElementInArray(state.permissions, index, {
          permissions: [],
          userId,
          hasBeenSaved: false,
        }),
      };
    case CHANGE_REQUIRE_PASSWORD:
    case CHANGE_REQUIRE_VISITOR_REGISTRATION:
    case CHANGE_ALLOW_VISITOR_REGISTRATION:
    case CHANGE_ACCESS_CODE_ENABLED:
      return getUpdatedFormSettings(state, action);
    case GET_FORM_PERMISSIONS:
      return api(action, state, {
        pending: () => ({ ...state, fetchingPermissions: true }),
        success: () => {
          const {
            allowVisitorRegistration,
            accessCodeEnabled,
            authorId,
            formId,
            isPublic,
            isSaveAndContinueEnabled: requireVisitorRegistration,
          } = action.payload;
          return {
            ...state,
            allowVisitorRegistration,
            authorId,
            formId,
            isPublic,
            permissions: groupPermissionsByUserId(
              action.payload.shareFormUsers,
              action.payload.shareSubmissionUsers,
              action.payload.usersWithFormEdit,
              action.payload.usersWithProcessSubmission,
            ),
            rolePermissions: groupPermissionsByRole(
              action.payload.rolesWithFormViewData,
              action.payload.rolesWithFormSubmissionsViewData,
              action.payload.rolesWithFormEdit,
              action.payload.rolesWithProcessSubmission,
            ),
            fetchingPermissions: false,
            requireVisitorRegistration,
            accessCodeEnabled,
          };
        },
        failure: () => ({
          ...state,
          fetchingPermissions: false,
        }),
      });
    case GET_USERS:
      return api(action, state, {
        pending: () => ({ ...state, fetchingUsers: true }),
        success: () => ({
          ...state,
          fetchingUsers: false,
        }),
        failure: () => ({
          ...state,
          fetchingUsers: false,
        }),
      });
    case CHANGE_AUTHOR:
      return {
        ...state,
        authorId: action.userId,
        isDirty: true,
      };
    case CHANGE_PERMISSION_LEVEL:
      const newPermissions = getUpdatedPermissionsForUser(state, action);
      // @ts-ignore
      const indexToUpdate = findIndex(state.permissions, permission => permission.userId === action.userId);
      if (indexToUpdate === -1) {
        return state;
      }
      return {
        ...state,
        isDirty: true,
        permissions: immutableModifyElementInArray(state.permissions, indexToUpdate, {
          // @ts-ignore
          ...state.permissions[indexToUpdate],
          permissions: newPermissions,
        }),
      };
    case INITIALIZE_PERMISSIONS:
      return {
        ...state,
        formId: action.payload.formId,
        isPdf: action.payload.isPdf,
      };
    case REMOVE_PERMISSION:
      // @ts-ignore
      const indexToRemove = findIndex(state.permissions, permission => permission.userId === action.userId);
      return {
        ...state,
        isDirty: true,
        permissions: immutableRemoveElementInArray(state.permissions, indexToRemove),
      };
    case ADD_ROLE:
      return {
        ...state,
        isDirty: true,
        rolePermissions: [
          ...state.rolePermissions,
          {
            permissions: [],
            role: action.roleName,
          },
        ],
      };
    case REMOVE_ROLE:
      const roleIndexToRemove = findIndex(
        state.rolePermissions,
        // @ts-ignore
        rolePermission => rolePermission.role === action.roleName,
      );
      return {
        ...state,
        isDirty: true,
        rolePermissions: immutableRemoveElementInArray(state.rolePermissions, roleIndexToRemove),
      };
    case CHANGE_ROLE_PERMISSION_LEVEL:
      const roleIndexToUpdate = findIndex(
        state.rolePermissions,
        // @ts-ignore
        rolePermission => rolePermission.role === action.roleName,
      );
      const currentPermissions = state.rolePermissions[roleIndexToUpdate];
      const newRolePermissions = calculateNewPermissionLevels(
        // @ts-ignore
        currentPermissions.permissions,
        action.isAddingPermission,
        action.permissionName,
      );
      return {
        ...state,
        isDirty: true,
        rolePermissions: immutableModifyElementInArray(state.rolePermissions, roleIndexToUpdate, {
          // @ts-ignore
          ...state.rolePermissions[roleIndexToUpdate],
          permissions: newRolePermissions,
        }),
      };
    case TOGGLE_MODAL:
      return { ...state, isDirty: false, modalOpen: action.modalOpen };
    case TOGGLE_SNACKBAR:
      return { ...state, snackbarOpen: action.snackbarOpen, snackbarMessage: action.snackbarMessage };
    case SAVE_UPDATING_PERMISSIONS_FORM_ID:
      return {
        ...state,
        updatingFormIds: [...state.updatingFormIds, action.formId],
      };
    case DELETE_UPDATING_PERMISSIONS_FORM_ID:
      return {
        ...state,
        updatingFormIds: state.updatingFormIds.filter(x => x !== action.formId),
      };
    default:
      return state;
  }
}
