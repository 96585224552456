import React, { useEffect, forwardRef } from 'react';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import { generateUniqueString } from 'utils/strings';
import FormInput from '../FormInput/FormInput';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';
import { LabelAlignment } from 'types/liveView';

export type Props = {
  id: string,
  label?: string,
  onChange?: Function,
  updateForm?: Function,
  extraData?: any,
  fieldState?: any,
  specialSettings?: {
    label: string,
    showLabel: boolean,
    labelAlign: LabelAlignment,
    length: number,
    prefix: string,
  },
}

const UniqueString = ({
  id,
  label = 'Unique String',
  fieldState,
  onChange = undefined,
  updateForm = undefined,
  specialSettings = {
    label: 'Unique String',
    showLabel: true,
    labelAlign: 'auto',
    length: 6,
    prefix: '',
  },
}: Props) => {
  const isLabelVisible: boolean = specialSettings ? specialSettings.showLabel : true;
  const labelAlignment: LabelAlignment = specialSettings ? specialSettings.labelAlign : 'auto';
  const length: number = specialSettings ? specialSettings.length : 6;
  const prefix: string = specialSettings ? specialSettings.prefix : '';
  const value: string = fieldState?.fields[label] || `${prefix}${generateUniqueString(length)}`;

  useEffect(() => {
    onChange && onChange(value);
    const s = {
      fields: {
        [label]: value,
      },
    };
    updateForm && updateForm(s);
  }, []);

  const alignment: string = getAlignmentClass(labelAlignment, sharedStyle);

  return (
    <div id={`container_${id}`} className={'form_control_group'}>
      <fieldset className={classNames(sharedStyle.Fieldset, 'choice_group')} id={`unique_string_${id}`}>
        {isLabelVisible && <legend className={classNames('choice_group_label', alignment)}>{label}</legend>}
        <FormInput
          value={value}
          label={label}
          id={`unique_string_input_${id}`}
          type='text'
          extraData={{ showLabel: 'f', readonly: 't' }}
        />
      </fieldset>
    </div>
  );
};

export default React.memo(forwardRef(UniqueString));
