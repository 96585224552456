import * as types from 'constants/types/authActionTypes';
import * as auth from 'utils/auth';
import api from './api';


const getErrorMsg = error => {
  try {
    let errorMsg = '';

    if (typeof error.xhr.response === 'string') {
      errorMsg = JSON.parse(error.xhr.response).message;
    } else {
      errorMsg = error.xhr.response;
    }

    return errorMsg;
  } catch (e) {
    return '';
  }
};

const initialState = {
  accountCapabilities: null,
  activeDirectoryConnections: [],
  companyName: null,
  address: null,
  address2: null,
  companyEmail: null,
  emailVerifyError: null,
  emailVerifySuccess: false,
  error: null,
  isAuthenticated: auth.isLoggedIn(),
  isFetching: false,
  isFetchingOrganizationInfo: false,
  isSnackbarOpen: false,
  maid: '',
  organizationFetchFailure: false,
  organizationLogo: null,
  profile: auth.getProfile(),
  registrationError: null,
  registrationSuccess: false,
  resetPasswordError: null,
  resetPasswordSuccess: false,
  role: null,
  sendResetPasswordError: null,
  sendResetPasswordSuccess: false,
  sendVerifyEmailError: null,
  sendVerifyEmailSuccess: false,
  snackbarMessage: '',
  token: auth.getIdToken(),
  isSignUpVisitorProcessing: false,
  signUpVisitorError: false,
  signUpVisitorSuccess: false,
  signUpVisitorErrorMsg: null,
  signUpEmailErrorMsg: null,
  accessCode: '',
  verifyAccessCodeError: null,
  submissionViewerRoute: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        error: null,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        error: null,
        idToken: action.idToken,
        profile: action.profile,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        token: null,
        profile: null,
        error: action.error,
      };
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        idToken: null,
        profile: null,
        error: null,
      };
    case types.ORGANIZATION_INFO_LOADING:
      return {
        ...state,
        isFetchingOrganizationInfo: action.isLoading,
      };
    case types.ORGANIZATION_INFO_API:
      return api(action, state, {
        success: () => ({
          ...state,
          organizationLogo: action.payload.logoUrl,
          companyName: action.payload.companyName,
          maid: action.payload.maid,
          address: action.payload.address,
          address2: action.payload.address2,
          companyEmail: action.payload.companyEmail,
        }),
        pending: () => ({
          ...state,
          isFetchingOrganizationInfo: true,
        }),
      });
    case types.USER_PROFILE_API:
      return api(action, state, {
        success: () => {
          const {
            payload: {
              accountCapabilities,
              role,
              useFederatedLogout,
              firstName,
              lastName,
              email,
              profileId,
            },
          } = action;
          return {
            ...state,
            accountCapabilities,
            role,
            useFederatedLogout,
            firstName,
            lastName,
            email,
            profileId,
          };
        },
        failure: () => ({
          ...state,
        }),
      });
    case types.GET_MASTER_ACCOUNTS_FROM_API:
      return api(action, state, {
        success: () => {
          const {accountDomains} = action.payload;
          return {
            ...state,
            accountDomains,
          };
        },
      });
    case types.GET_MASTER_ACCOUNTS:
      const {accountDomains} = action.payload;
      return {
        ...state,
        accountDomains,
      };
    case types.GET_ACCOUNT_KEY:
      return api(action, state, {
        success: () => ({
          ...state,
          accountKey: action.payload.accountKey,
        }),
        pending: () => ({
          ...state,
          isFetchingOrganizationInfo: true,
        }),
      });
    case types.GET_ACTIVE_DIRECTORY_CONNECTIONS:
      return api(action, state, {
        success: () => ({
          ...state,
          activeDirectoryConnections: action.payload.connections,
        }),
      });
    case types.UPLOAD_NEW_DOCUMENT_FAILURE:
      return {
        ...state,
        isSnackbarOpen: true,
        snackbarMessage: action.filepickerError,
      };
    case types.RESET_HEADER_SNACKBAR:
      return {
        ...state,
        isSnackbarOpen: false,
        snackbarMessage: '',
      };
    case types.SUBDOMAIN_EXISTS:
      return {
        ...state,
        isSubdomainExists: action.isExists,
      };
    case types.COREGISTRATION_INFO_RECEIVED:
      return {
        ...state,
        partialCoregistrationProfile: {
          auid: action.auid,
          maid: action.maid,
        },
      };
    case types.REGISTER_USER:
      return api(action, state, {
        success: () => ({
          ...state,
          registrationError: null,
          registrationSuccess: true,
        }),
        pending: () => ({
          ...state,
          registrationError: null,
        }),
        failure: () => ({
          ...state,
          registrationError: action.error.xhr.response.message,
          registrationSuccess: false,
        }),
      });
    case types.COMPLETE_REGISTRATION:
      return api(action, state, {
        success: () => ({
          ...state,
          isFetching: false,
          registrationSuccess: true,
        }),
        pending: () => ({
          ...state,
          isFetching: true,
        }),
        failure: () => ({
          ...state,
          isFetching: false,
          registrationError: action.error.xhr.response.message,
        }),
      });
    case types.SEND_RESET_PASSWORD:
      return api(action, state, {
        success: () => ({
          ...state,
          isFetching: false,
          sendResetPasswordError: null,
          sendResetPasswordSuccess: true,
        }),
        pending: () => ({
          ...state,
          isFetching: true,
          sendResetPasswordError: null,
        }),
        failure: () => {
          const message = action.error.xhr.responseType === 'json' ?
            action.error.xhr.response.message :
            JSON.parse(action.error.xhr.response).message;

          return {
            ...state,
            isFetching: false,
            sendResetPasswordError: message,
            sendResetPasswordSuccess: false,
          };
        },
      });
    case types.RESET_PASSWORD:
      return api(action, state, {
        success: () => ({
          ...state,
          isFetching: false,
          resetPasswordError: null,
          resetPasswordSuccess: true,
        }),
        pending: () => ({
          ...state,
          isFetching: true,
          resetPasswordError: null,
        }),
        failure: () => ({
          ...state,
          isFetching: false,
          resetPasswordError: action.error.xhr.response.message,
          resetPasswordSuccess: false,
        }),
      });
    case types.EMAIL_VERIFIED:
      return api(action, state, {
        success: () => ({
          ...state,
          isFetching: false,
          emailVerifyError: null,
          emailVerifySuccess: true,
        }),
        pending: () => ({
          ...state,
          isFetching: true,
          emailVerifyError: null,
        }),
        failure: () => ({
          ...state,
          isFetching: false,
          emailVerifyError:
            (action.error.xhr.response && action.error.xhr.response.message) ||
            'Email verification failed.',
          emailVerifySuccess: false,
        }),
      });
    case types.SEND_VERIFY_EMAIL:
      return api(action, state, {
        success: () => ({
          ...state,
          isFetching: false,
          sendVerifyEmailError: null,
          sendVerifyEmailSuccess: true,
        }),
        pending: () => ({
          ...state,
          isFetching: true,
          sendVerifyEmailError: null,
        }),
        failure: () => ({
          ...state,
          isFetching: false,
          sendVerifyEmailError:
            (action.error.xhr.response && action.error.xhr.response.message) ||
            'Failed to send email.',
          sendVerifyEmailSuccess: false,
        }),
      });
    case types.VERIFY_ACCESS_CODE:
      return api(action, state, {
        success: () => ({
          ...state,
          isFetching: false,
          verifyAccessCodeError: null,
          accessCode: action.payload?.result?.accessCode,
          submissionViewerRoute: `/ng/submissions/viewer/${action.payload?.result?.formId}/${action.payload?.result?.submissionId}`,
        }),
        pending: () => ({
          ...state,
          isFetching: true,
          verifyAccessCodeError: null,
        }),
        failure: () => ({
          ...state,
          isFetching: false,
          verifyAccessCodeError:
            (action.error.xhr.response && action.error.xhr.response.message) ||
            'Invalid access code.',
        }),
      });
    case types.SET_SUBMISSION_VIEWER_ROUTE:
      return {
        ...state,
        submissionViewerRoute: action.payload.route,
      };
    case types.SIGN_UP_VISITOR:
      return api(action, state, {
        success: () => ({
          ...state,
          isSignUpVisitorProcessing: false,
          signUpVisitorErrorMsg: null,
          signUpVisitorSuccess: true,
          signUpVisitorError: false,
        }),
        pending: () => ({
          ...state,
          isSignUpVisitorProcessing: true,
          signUpVisitorErrorMsg: null,
          signUpVisitorSuccess: false,
          signUpVisitorError: false,
        }),
        failure: () => ({
          ...state,
          isSignUpVisitorProcessing: false,
          signUpVisitorErrorMsg: getErrorMsg(action.error),
          signUpEmailErrorMsg: action.error.status === 409 ? 'Email address already exists, please login on previous page' : '',
          signUpVisitorSuccess: false,
          signUpVisitorError: true,
        }),
      });

    default:
      return state;
  }
}

export const getUserCapabilities = state =>
  state.accountCapabilities || [];

export const getUserCapabilitiesLoadingState = state =>
  !state.accountCapabilities;

export const getUserRole = state => state.role;

export const getAuthenticationState = state =>
  state.isAuthenticated;

export const getLoggedInUserId = ({ profile }) =>
  profile ? profile.auid : '';

export const getSubdomain = ({ profile }) =>
  profile ? profile.subd : '';

export const getOrganizationBrand = ({
  companyName,
  organizationLogo,
}) => ({ name: companyName, logo: organizationLogo });

export const getMaid = state =>
  state.maid;
