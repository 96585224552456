import React from 'react';
import styles from './SubmissionDetails.css';
import attachmentImg from 'images/v1-attachment.png';
import LinkToNewTab from 'components/LinkToNewTab';
import { ATTACHMENT } from 'constants/trackDownloadTypes';

export default class AttachmentBoxItem extends React.Component<any, any> {
  trackDownloadFile = event => {
    const { url, trackDownloadFile } = this.props;
    event.stopPropagation();
    trackDownloadFile(ATTACHMENT, url);
  }

  render() {
    const { fileType, fileName, url } = this.props;
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div className={styles.attachmentImg}>
          <img src={attachmentImg} alt='' />
          <span>{fileType}</span>
        </div>
        <div className={styles.boxListContent}>
          <p className={styles.attachmentName}>{fileName}</p>
          <p className={styles.attachmentLabel}>{fileType}</p>
        </div>
        <div className={styles.attachmentLink}>
          <LinkToNewTab
            href={url}
            className={styles.attachmentView}
            onClick={this.trackDownloadFile}>
            View
          </LinkToNewTab>
        </div>
      </div>
    );
  }
}
