import React from 'react';
import Popover from 'components/Popover';
import SelectionsWrapper from './SelectionsWrapper';
import { getAnchorPosition } from 'utils/popover';

class SelectionOverflow extends React.Component<any, any> {
  static defaultProps = {
    resultsListClassName: '',
  }
  state = { isPopoverOpen: false };
  triggerButtonRef = React.createRef();

  focusTriggerButton = () => {
    const triggerButtonRef = this.triggerButtonRef.current;
    if (triggerButtonRef) (triggerButtonRef as any).focus();
  }
  renderOverflowButton = () => {
    const { ShowOverflowButton, selections } = this.props;
    return <ShowOverflowButton overflowCount={selections.length} />;
  };

  handleClosePopover = () => this.setState({ isPopoverOpen: false }, this.focusTriggerButton);
  handleOpenPopover = () => this.setState(prevState => ({ isPopoverOpen: !prevState.isPopoverOpen }));

  render() {
    const { resultsListClassName, renderSelection, selections, onRemove, isReadOnly, popoverOrigin } = this.props;
    return (
      <Popover
        isOpen={this.state.isPopoverOpen}
        renderTriggerButton={this.renderOverflowButton}
        requestClose={this.handleClosePopover}
        requestOpen={this.handleOpenPopover}
        transformOrigin={popoverOrigin}
        anchorOrigin={getAnchorPosition(popoverOrigin) as any}
        tabIndex='0'
        triggerButtonRef={this.triggerButtonRef}
        shouldOpenOnHover>
        <SelectionsWrapper
          className={resultsListClassName}
          requestClose={this.handleClosePopover}
          renderSelection={renderSelection}
          selections={selections}
          onRemove={onRemove}
          isReadOnly={isReadOnly} />
      </Popover>
    );
  }
}

export default SelectionOverflow;
