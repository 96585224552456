import React from 'react';
import { connect } from 'react-redux';
import styles from './StagesSidebarDetails.css';
import StagesList from './StagesList';
import Header from './Header';
import ArchiveTitle from './Archive';

import CreateStage from 'components/Stages/CreateStage';
import Tooltip from 'components/Tooltip';
import {
  reorderStages,
  selectAllVirtualStage,
} from 'actions/stages';
import {
  requestInitialSubmissionLoad,
  expandStageSidebar,
  applyStageFilter,
  removeStageFilters,
} from 'actions/submissionsManager/tableApi';
import { formCapabilitiesEnum } from 'constants/tableConsts';
import {
  checkFormCapability,
  getSelectedStageName,
  getFeatureState,
} from '../../reducers';
import { isSubmissionManager } from '../OneNavBar/utils';


class StagesSidebarDetails extends React.PureComponent<any, any> {
  renderCreateStage() {
    const { isExpanded, stages } = this.props;
    const createStageButton = (
      <div>
        <CreateStage
          stages={stages}
          sidebarExpanded={isExpanded}
          onStartCreation={this.props.expandStageSidebar} />
      </div>
    );
    return isExpanded ?
      createStageButton :
      <Tooltip placement='right' className={styles.createStageTooltip} title='Create stage'>
        {createStageButton}
      </Tooltip>;
  }

  renderStages = () => {
    const {
      isExpanded,
      stages,
      formId,
      isReadOnly,
      totalSubmissions,
      cachedTotalSubmissions,
      isArchiveView,
    } = this.props;
    return (
      <>
        <StagesList
          stages={stages}
          sidebarExpanded={isExpanded}
          reorderStages={this.props.reorderStages}
          applyStageFilter={this.props.applyStageFilter}
          removeStageFilter={this.props.removeStageFilters}
          onSelectAllVirtualStage={this.handleSelectAllVirtualStage}
          formId={formId}
          isReadOnly={isReadOnly || isArchiveView}
          totalSubmissions={totalSubmissions}
          cachedTotalSubmissions={cachedTotalSubmissions}
          expandSidebar={this.props.expandStageSidebar} />
        {!isReadOnly && !isArchiveView && this.renderCreateStage()}
      </>
    );
  }

  renderSideBar = () => {
    const {
      isExpanded,
      areStagesEnabled,
      canUserArchiveSubmissions,
    } = this.props;
    const shouldDisplayArchiveIcon = !!(isSubmissionManager() && canUserArchiveSubmissions);
    return (<>
      { areStagesEnabled && this.renderStages() }
      { areStagesEnabled && shouldDisplayArchiveIcon && <div className={styles.divider} /> }
      { shouldDisplayArchiveIcon && <ArchiveTitle sidebarExpanded={isExpanded}/> }
    </>);
  }

  handleSelectAllVirtualStage = () => {
    this.props.selectAllVirtualStage();
    this.props.requestInitialSubmissionLoad(this.props.formId, this.props.isArchiveView);
  }

  render() {
    const { isExpanded, isHamburger, areStagesEnabled } = this.props;
    if (isHamburger) {
      return isExpanded ? this.renderSideBar() : null;
    }

    const sidebarHeader = isExpanded ? 'Stages' : <Header />;
    return (
      <React.Fragment>
        { areStagesEnabled && <div className={styles.sidebarHeader}>
          {sidebarHeader}
        </div> }
        <div className={styles.content}>
          { this.renderSideBar() }
        </div>
      </React.Fragment>);
  }
}

const mapStateToProps = state => {
  const {
    stages,
    submissionTable: { formId, totalSubmissions, cachedTotalSubmissions, isArchiveView },
  } = state;
  return {
    features: getFeatureState(state),
    stages: stages[formId] || [],
    formId,
    isReadOnly: !checkFormCapability(state, formId, formCapabilitiesEnum.OrganizeStages),
    selectedStageName: getSelectedStageName(state),
    totalSubmissions,
    cachedTotalSubmissions,
    canUserArchiveSubmissions: checkFormCapability(state, formId, formCapabilitiesEnum.ArchiveSubmissions),
    isArchiveView,
  };
};

export default connect(
  mapStateToProps,
  {
    reorderStages,
    requestInitialSubmissionLoad,
    expandStageSidebar,
    selectAllVirtualStage,
    applyStageFilter,
    removeStageFilters,
  },
)(StagesSidebarDetails);
