import React from 'react';
import Stage from 'components/Stages/Stage';
import { ALL_VIRTUAL_STAGE_NAME, ALL_VIRTUAL_STAGE_COLOR } from 'constants/stages';


const AllVirtualStage = ({ count, isExpanded, onClick, selectedStageName }) => (
  <Stage
    key={ALL_VIRTUAL_STAGE_NAME}
    data={{ name: ALL_VIRTUAL_STAGE_NAME, color: ALL_VIRTUAL_STAGE_COLOR, count }}
    onClick={onClick}
    inSidebar
    showDetails={isExpanded}
    selected={selectedStageName === ALL_VIRTUAL_STAGE_NAME}
    isReadOnly />
);

export default AllVirtualStage;
