import ReactDOM from 'react-dom';

export const findDOMNode = (element: React.ReactInstance) => {
  const node = ReactDOM.findDOMNode(element);
  return (node instanceof HTMLElement) ? node : null;
};

export const getBoundingClientRect = (element: React.ReactInstance) => {
  const node = findDOMNode(element);
  return node ? node.getBoundingClientRect() : null;
};

export const offsetHeight = (element: React.ReactInstance) =>
  (findDOMNode(element) || {}).offsetHeight;

export const offsetWidth = (element: React.ReactInstance) =>
  (findDOMNode(element) || {}).offsetWidth;
