import * as React from 'react';
import {connect} from 'react-redux';
import {getUserCapabilities} from '../reducers';
import {generateCapabilitiesGetters} from 'utils/auth';


const mapStateToProps = state => ({
  capabilities: getUserCapabilities(state),
});

export default function injectPermissions(Component) {
  const WithPermissions = ({capabilities, ...restProps}) => (
    <Component {...generateCapabilitiesGetters(capabilities)} {...restProps}/>
  );
  return connect(mapStateToProps)(WithPermissions);
}
