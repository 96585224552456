export const ARCHIVE_FORM_API = 'LB_ARCHIVE_FORM_API';
export const CREATE_FOLDER_MODAL_VISIBILITY = 'LB_CREATE_FOLDER_MODAL_VISIBILITY';
export const DELETE_FORM = 'LB_DELETE_FORM';
export const LOADER_VISIBILITY = 'LB_LOADER_VISIBILITY';
export const RESET_SCROLL_TO_ROW = 'LB_RESET_SCROLL_TO_ROW';
export const SCROLL_TO_ROW = 'LB_SCROLL_TO_ROW';
export const SEARCHBAR_INPUT_CHANGE = 'LB_SEARCHBAR_INPUT_CHANGE';
export const SELECT_ROW = 'LB_SELECT_ROW';
export const SET_FORM_DETAILS = 'LB_SET_FORM_DETAILS';
export const SHOW_LOBBY_WITH_FORM = 'LB_SHOW_LOBBY_WITH_FORM';
export const TOOLBAR_MOBILE_MENU_VISIBILITY = 'LB_TOOLBAR_MOBILE_MENU_VISIBILITY';
