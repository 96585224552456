export const SET_APB_VISIBILITY = 'SET_APB_VISIBILITY';
export const ADD_EMPTY_RULE = 'APB_ADD_RULE';
export const CHANGE_RULE_STATUS = 'APB_CHANGE_RULE_STATUS';
export const DELETE_RULE = 'APB_DELETE_RULE';
export const CHANGE_RULE_ASSIGNMENTS = 'APB_CHANGE_RULE_ASSIGNMENTS';
export const CHANGE_RULE_EMAIL_BODY = 'APB_CHANGE_RULE_EMAIL_BODY';
export const CHANGE_RULE_EMAIL_ATTACHED_PDF = 'APB_CHANGE_RULE_EMAIL_ATTACHED_PDF';
export const CHANGE_RULE_EMAIL_INCLUDE_SM_LINK = 'APB_CHANGE_RULE_EMAIL_INCLUDE_SM_LINK';
export const CHANGE_RULE_EMAIL_INCLUDE_FIELDS = 'APB_CHANGE_RULE_EMAIL_INCLUDE_FIELDS';
export const CHANGE_RULE_EMAIL_RECIPIENTS = 'APB_CHANGE_RULE_EMAIL_RECIPIENTS';
export const CHANGE_RULE_EMAIL_SUBJECT = 'APB_CHANGE_RULE_EMAIL_SUBJECT';
export const CHANGE_RULE_STAGE = 'APB_CHANGE_RULE_STAGE';
export const CHANGE_RULE_TAGS = 'APB_CHANGE_RULE_TAGS';
export const CHANGE_RULE_TRIGGER = 'APB_CHANGE_RULE_TRIGGER';
export const CHANGE_RULE_WEBHOOK = 'APB_CHANGE_RULE_WEBHOOK';
export const LOAD_RULES = 'APB_LOAD_RULES';
export const SAVE_RULES = 'APB_SAVE_RULES';
export const SET_EMAIL_VISIBILITY = 'APB_SET_EMAIL_VISIBLITY';
export const GET_EMAIL_FIELDS_OF_FORM = 'APB_GET_EMAIL_FIELDS_OF_FORM';
export const GET_FORM_FIELDS = 'APB_GET_FORM_FIELDS';
export const GET_SIGNERS_FIELDS_OF_FORM = 'APB_GET_SIGNERS_FIELDS_OF_FORM';
export const SET_LEGACY_FORM_ID = 'APB_SET_LEGACY_FORM_ID';
