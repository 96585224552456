import React from 'react';
import MaterialUIPopover from '@material-ui/core/Popover';
import Icon from '@material-ui/core/Icon';
import DetailRow from 'components/DetailRow';
import styles from './FormDetailsDropdown.css';
import * as Colors from 'utils/colors';
import headerIcon from 'icons/icon25.svg';
import * as dateHelper from 'utils/dateHelper';
import * as routing from 'utils/routing';
import { Form } from 'types/routing';

const inlineStyles = {
  arrowStyle: {
    fontSize: '18px',
    verticalAlign: 'middle',
    cursor: 'pointer',
    color: Colors.white,
  },
  closeIconStyle: {
    fontSize: '18px',
    cursor: 'pointer',
    float: 'right',
  },
  footerIcon: {
    minWidth: '64px',
    padding: '20px',
    cursor: 'pointer',
    color: Colors.textSecondary,
  },
};


class FormDetailsDropdown extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  formatLegacyLiveviewUrl = () => {
    if (this.props.formDetails) {
      const form: Form = {
        itemType: undefined as any,
        alias: this.props.formDetails.alias,
      };

      return routing.formatLegacyLiveviewUrl(form);
    }
    return null;
  };

  formatLegacyEditFormUrl = () => {
    if (this.props.formDetails) {
      const form: Form = {
        formId: this.props.formDetails.id,
        itemType: this.props.formDetails.pdfId ? 'Doc' : 'Webform',
        alias: this.props.formDetails.alias,
      };

      return routing.formatLegacyEditFormUrl(form);
    }

    return null;
  };

  handleOnClick = event => {
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    if (!this.props.formDetails) {
      return null;
    }

    const { name, authorName, createdTime, modifiedTime } = this.props.formDetails;

    const editFormUrl = this.formatLegacyEditFormUrl();
    const viewFormUrl = this.formatLegacyLiveviewUrl();

    return (
      <span>
        <Icon style={inlineStyles.arrowStyle} onClick={this.handleOnClick}>
          keyboard_arrow_down
        </Icon>
        <MaterialUIPopover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={this.handleRequestClose}
        >
          <div className={styles.container}>
            <div className={styles.header}>
              <span className={styles.headerIcon}>
                <img alt='' src={headerIcon} />
              </span>
              <span className={styles.headerTitle}>{name}</span>
              <Icon
                style={{ ...inlineStyles.closeIconStyle, color: Colors.textSecondary } as any}
                onClick={this.handleRequestClose}
              >
                clear
              </Icon>
            </div>
            <div className={styles.body}>
              {authorName && <DetailRow icon='account_circle' label='Owner' fieldValue={authorName} />}
              <DetailRow
                icon='event'
                label='Date Created'
                fieldValue={dateHelper.getFormattedTime(createdTime, dateHelper.TIME_FORMAT_LONG)}
              />
              {modifiedTime && (
                <DetailRow
                  icon='event'
                  label='Last Modified'
                  fieldValue={dateHelper.getFormattedTime(modifiedTime, dateHelper.TIME_FORMAT_LONG)}
                />
              )}
            </div>
            <div className={styles.footer}>
              <a href={viewFormUrl || undefined}>
                {/* @ts-ignore */}
                <Icon style={inlineStyles.footerIcon} hoverColor={Colors.textPrimary}>
                  visibility
                </Icon>
              </a>
              {this.props.canUserEditForm && (
                <a href={editFormUrl || undefined}>
                  {/* @ts-ignore */}
                  <Icon style={inlineStyles.footerIcon} hoverColor={Colors.textPrimary}>
                    create
                  </Icon>
                </a>
              )}
            </div>
          </div>
        </MaterialUIPopover>
      </span>
    );
  }
}

export default FormDetailsDropdown;
