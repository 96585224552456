import {unescape} from 'lodash';
import {selectFolder} from '../../actions/lobby/folderNavigationActions';
import {newTable} from 'actions/lobby/tableApiActions';
// import {Dir} from 'utils/vfs';


export const isLobby = () => /ng\/lobby/.test(location.href);
export const isSubmissionManager = () => /ng\/submissions/.test(location.href);
export const isSubmissionManagerArchive = () => /ng\/submissions\/archived/.test(location.href);

function changeFolder(path, node, dispatch) {
  dispatch(selectFolder(path, node));
  if (!node.meta.folderId) return;
  dispatch(newTable(node.meta.folderId));
}

function goToLobby(router) {
  router.push('/ng/lobby');
}

function nodeClick(router, dispatch, path, node) {
  if (isLobby()) {
    changeFolder(path, node, dispatch);
    return;
  }
  goToLobby(router);
  changeFolder(path, node, dispatch);
}


export function generateBreadcrumbs({
  currentNode,
  dispatch,
  currentForm,
  router,
}) {
  const breadcrumbs: any[] = [];
  const path: any[] = [];
  let node = currentNode;
  if (!node) return [];

  do {
    path.unshift(node.name);
    breadcrumbs.unshift({
      id: node.name,
      name: unescape(node.name).replace('&#039;', "'"),
      onClick: nodeClick.bind(this, router, dispatch, path, node),
    });
    node = node.parent;
  } while (node);

  if (isSubmissionManager() && currentForm && currentForm.name) {
    breadcrumbs.push({
      id: currentForm.id,
      name: `${unescape(currentForm.name).replace('&#039;', "'")} submissions`,
      onClick: isSubmissionManagerArchive() && !!router.params.formID ? () => {
        router.push(`/ng/submissions/${router.params.formID}`);
      } : () => {},
    });
  }

  if (isSubmissionManagerArchive()) {
    breadcrumbs.push({
      id: 'Archive',
      name: 'Archives',
    });
  }

  return breadcrumbs;
}
