import React from 'react';
import styles from './styles.css';
import Button from 'components/Button';
import FormResponseBox from 'components/FormResponseBox';


export default class InvitationExpired extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { resendLinkClicked: false };
  }

  resendLink = () => {
    const {
      email,
      maid,
    } = this.props;
    if (this.state.resendLinkClicked) return;
    this.props.resendInvite({
      email,
      maid,
    });
    this.setState({ resendLinkClicked: true });
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <h2>Your invitation link has expired</h2>
          <p className={styles.subtext}>
            Your invitation link is only valid for 2 weeks for your security. In order to complete your
            registration, click the button below to have a new link sent to your email.
          </p>
        </div>
        <div className={styles.resendInviteButtonWrapper}>
          <Button
            label='Resend Invite'
            primaryBlue
            primary={false}
            onClick={this.resendLink} />
        </div>
        <div className={styles.responseBoxWrapper}>
          <FormResponseBox
            show={this.state.resendLinkClicked}
            success={this.state.resendLinkClicked}
            message='Your invite has been resent. Please check your email.' />
        </div>
      </React.Fragment>
    );
  }
}
