import React from 'react';
import style from './FormLiveView.css';

function FormLoader() {
  const rowCount = Math.ceil(Math.random() * 10) + 3;

  const generateCells = () => {
    const cellCount = Math.floor((Math.random() * 100)) % 3 + 1;
    const cells: JSX.Element[] = [];
    for (let i = 0; i < cellCount; i++) {
      cells.push(<div className={style.LoaderCell} key={i} />);
    }
    return cells;
  };

  const generateRows = () => {
    const rows: JSX.Element[] = [];
    for (let i = 0; i < rowCount; i++) {
      rows.push(<div className={style.LoaderRow} key={i}>{generateCells()}</div>);
    }
    return rows;
  };

  return (<div>{generateRows()}</div>);
}

export default React.memo(FormLoader);
