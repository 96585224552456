import {ASSIGN_USERS_TO_MULTIPLE, UNASSIGN_USERS_TO_MULTIPLE} from 'constants/types/assignmentsActionTypes';
import * as submissionsTableActionTypes from 'constants/types/submissionsTableActionTypes';
import * as submissionsActionTypes from 'constants/types/submissionsActionTypes';
import {ASSIGN_TAGS_TO_MULTIPLE, UNASSIGN_TAGS_TO_MULTIPLE} from 'constants/types/tagActionTypes';
import {
  ASSIGN_STAGES,
  UNASSIGN_STAGES,
} from 'constants/types/stageActionTypes';
import { bulkActions, bulkActionTypes } from 'constants/submissionManager';
import { DOWNLOAD_VISIBILITY, LOADER_VISIBILITY } from 'constants/types/submissionsActionTypes';

const BULK_ACTION_CATEGORY = 'Bulk Action';
const STAGES_LABEL = 'Stages';
const ASSIGNEES_LABEL = 'Assignees';
const EXPORT_LABEL = 'Export';
const ARCHIVE_LABEL = 'Archive';
const DELETE_LABEL = 'Delete';
const TAGS_LABEL = 'Tags';
const SHARE_LABEL = 'Share';
const BULK_PREFIX = 'Bulk:';
const SELECTED = 'selected';
const CANCELLED = 'cancelled';
const EXECUTED = 'executed';
const FAILED = 'failed';

const MOVE_TO_STAGE_EVENT = 'Move to stage';
const REMOVE_STAGES_EVENT = 'Remove stages';
const FIND_AND_REPLACE_STAGES_EVENT = 'Find and replace stages';
const BULK_MOVE_TO_STAGE = `${BULK_PREFIX} ${MOVE_TO_STAGE_EVENT}`;
const BULK_REMOVE_STAGES = `${BULK_PREFIX} ${REMOVE_STAGES_EVENT}`;
const BULK_FIND_AND_REPLACE_STAGES = `${BULK_PREFIX} ${FIND_AND_REPLACE_STAGES_EVENT}`;

const ADD_ASSIGNEES_EVENT = 'Add assignees';
const REPLACE_ASSIGNEES_EVENT = 'Replace assignees';
const REMOVE_ASSIGNEES_EVENT = 'Remove assignees';
const FIND_AND_REPLACE_ASSIGNEES_EVENT = 'Find and replace assignees';
const BULK_ADD_ASSIGNEES = `${BULK_PREFIX} ${ADD_ASSIGNEES_EVENT}`;
const BULK_REPLACE_ASSIGNEES = `${BULK_PREFIX} ${REPLACE_ASSIGNEES_EVENT}`;
const BULK_REMOVE_ASSIGNEES = `${BULK_PREFIX} ${REMOVE_ASSIGNEES_EVENT}`;
const BULK_FIND_AND_REPLACE_ASSIGNEES = `${BULK_PREFIX} ${FIND_AND_REPLACE_ASSIGNEES_EVENT}`;

const ADD_TAGS_EVENT = 'Add tags';
const REPLACE_TAGS_EVENT = 'Replace tags';
const REMOVE_TAGS_EVENT = 'Remove tags';
const FIND_AND_REPLACE_TAGS_EVENT = 'Find and replace tags';
const BULK_ADD_TAGS = `${BULK_PREFIX} ${ADD_TAGS_EVENT}`;
const BULK_REPLACE_TAGS = `${BULK_PREFIX} ${REPLACE_TAGS_EVENT}`;
const BULK_REMOVE_TAGS = `${BULK_PREFIX} ${REMOVE_TAGS_EVENT}`;
const BULK_FIND_AND_REPLACE_TAGS = `${BULK_PREFIX} ${FIND_AND_REPLACE_TAGS_EVENT}`;

const SHARE_EVENT = 'Share submissions';
const WITH_USERS = 'with existing users';
const WITH_NONUSERS = 'with non users';
const SHARE_SUBMISSIONS = `${BULK_PREFIX} ${SHARE_EVENT}`;
const BULK_SHARE_WITH_USERS = `${SHARE_SUBMISSIONS} ${WITH_USERS}`;
const BULK_SHARE_WITH_NONUSERS = `${SHARE_SUBMISSIONS} ${WITH_NONUSERS}`;
const BULK_SHARE_WITH_USERS_AND_NONUSERS = `${SHARE_SUBMISSIONS} ${WITH_USERS} and ${WITH_NONUSERS}`;

export const trackMoveToStage = action$ =>
  action$.ofType(ASSIGN_STAGES)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      let event = '';
      if (action.status === 'success') {
        event = `${BULK_MOVE_TO_STAGE} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${BULK_MOVE_TO_STAGE} ${FAILED}`;
      }
      if (window.analytics && event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: STAGES_LABEL,
        });
      }
    }).ignoreElements();

export const trackRemoveStages = action$ =>
  action$.ofType(UNASSIGN_STAGES)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      const isPartOfMoveToStage = action.payload.snackbarMessage === 'Moved successfully';
      let event = '';
      if (action.status === 'success' && !isPartOfMoveToStage) {
        event = `${BULK_REMOVE_STAGES} ${EXECUTED}`;
      } else if (action.status === 'failure' && isPartOfMoveToStage) {
        event = `${BULK_MOVE_TO_STAGE} ${FAILED}`;
      } else if (action.status === 'failure' && !isPartOfMoveToStage) {
        event = `${BULK_REMOVE_STAGES} ${FAILED}`;
      }
      if (window.analytics && event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: STAGES_LABEL,
        });
      }
    }).ignoreElements();

export const trackFindAndReplaceStages = action$ =>
  action$.ofType(submissionsTableActionTypes.FIND_AND_REPLACE_STAGES)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      let event = '';
      if (action.status === 'success') {
        event = `${BULK_FIND_AND_REPLACE_STAGES} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${BULK_FIND_AND_REPLACE_STAGES} ${FAILED}`;
      }
      if (window.analytics && event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: STAGES_LABEL,
        });
      }
    }).ignoreElements();

export const trackAddAssignee = action$ =>
  action$.ofType(ASSIGN_USERS_TO_MULTIPLE)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      let event = '';
      if (action.status === 'success') {
        event = `${BULK_ADD_ASSIGNEES} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${BULK_ADD_ASSIGNEES} ${FAILED}`;
      }
      if (window.analytics && event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: ASSIGNEES_LABEL,
        });
      }
    }).ignoreElements();

export const trackReplaceAssignees = action$ =>
  action$.ofType(submissionsTableActionTypes.REPLACE_ASSIGNEES)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      let event = '';
      if (action.status === 'success') {
        event = `${BULK_REPLACE_ASSIGNEES} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${BULK_REPLACE_ASSIGNEES} ${FAILED}`;
      }
      if (window.analytics && event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: ASSIGNEES_LABEL,
        });
      }
    }).ignoreElements();

export const trackRemoveAssignees = action$ =>
  action$.ofType(UNASSIGN_USERS_TO_MULTIPLE)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      let event = '';
      if (action.status === 'success') {
        event = `${BULK_REMOVE_ASSIGNEES} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${BULK_REMOVE_ASSIGNEES} ${FAILED}`;
      }
      if (window.analytics && event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: ASSIGNEES_LABEL,
        });
      }
    }).ignoreElements();

export const trackFindAndReplaceAssignees = action$ =>
  action$.ofType(submissionsTableActionTypes.FIND_AND_REPLACE_ASSIGNEES)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      let event = '';
      if (action.status === 'success') {
        event = `${BULK_FIND_AND_REPLACE_ASSIGNEES} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${BULK_FIND_AND_REPLACE_ASSIGNEES} ${FAILED}`;
      }
      if (window.analytics && event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: ASSIGNEES_LABEL,
        });
      }
    }).ignoreElements();

export const trackAddTag = action$ =>
  action$.ofType(ASSIGN_TAGS_TO_MULTIPLE)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      let event = '';
      if (action.status === 'success') {
        event = `${BULK_ADD_TAGS} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${BULK_ADD_TAGS} ${FAILED}`;
      }
      if (window.analytics && event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: TAGS_LABEL,
        });
      }
    }).ignoreElements();

export const trackReplaceTags = action$ =>
  action$.ofType(submissionsTableActionTypes.REPLACE_TAGS)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      let event = '';
      if (action.status === 'success') {
        event = `${BULK_REPLACE_TAGS} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${BULK_REPLACE_TAGS} ${FAILED}`;
      }
      if (window.analytics && event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: TAGS_LABEL,
        });
      }
    }).ignoreElements();

export const trackRemoveTags = action$ =>
  action$.ofType(UNASSIGN_TAGS_TO_MULTIPLE)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      let event = '';
      if (action.status === 'success') {
        event = `${BULK_REMOVE_TAGS} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${BULK_REMOVE_TAGS} ${FAILED}`;
      }
      if (window.analytics && event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: TAGS_LABEL,
        });
      }
    }).ignoreElements();

export const trackFindAndReplaceTags = action$ =>
  action$.ofType(submissionsTableActionTypes.FIND_AND_REPLACE_TAGS)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      let event = '';
      if (action.status === 'success') {
        event = `${BULK_FIND_AND_REPLACE_TAGS} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${BULK_FIND_AND_REPLACE_TAGS} ${FAILED}`;
      }
      if (window.analytics && event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: TAGS_LABEL,
        });
      }
    }).ignoreElements();

export const trackShareSubmissionsSelection = action$ =>
  action$.ofType(submissionsActionTypes.SHARE_VISIBILITY)
    .do(({ shareVisible }) => {
      if (window.analytics) {
        let event = `${BULK_PREFIX} ${SHARE_LABEL}`;
        if (shareVisible) {
          event = `${event} ${SELECTED}`;
        } else {
          event = `${event} ${CANCELLED}`;
        }
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: SHARE_LABEL,
        });
      }
    }).ignoreElements();

export const trackShareSubmissions = action$ =>
  action$.ofType(submissionsActionTypes.SHARE_SUBMISSIONS)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      const emails = action.payload.emails;
      const allExistingUsers = emails.every(email => email.hasOwnProperty('role'));
      const allNonUsers = emails.every(email => !email.hasOwnProperty('role'));

      let event = '';

      if (allExistingUsers) {
        event = BULK_SHARE_WITH_USERS;
      } else if (allNonUsers) {
        event = BULK_SHARE_WITH_NONUSERS;
      } else {
        event = BULK_SHARE_WITH_USERS_AND_NONUSERS;
      }

      if (action.status === 'success') {
        event = `${event} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${event} ${FAILED}`;
      }

      if (window.analytics) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: SHARE_LABEL,
        });
      }
    }).ignoreElements();

export const trackBulkActionSelection = action$ =>
  action$.ofType(submissionsTableActionTypes.TOGGLE_DROPDOWN)
    .do(({ dropdownId, value }) => {
      if (window.analytics && value) {
        let event = '';
        let label = '';
        switch (dropdownId) {
          case bulkActionTypes.Stages:
            event = `${BULK_PREFIX} ${STAGES_LABEL} ${SELECTED}`;
            label = STAGES_LABEL;
            break;
          case bulkActionTypes.Assignees:
            event = `${BULK_PREFIX} ${ASSIGNEES_LABEL} ${SELECTED}`;
            label = ASSIGNEES_LABEL;
            break;
          case bulkActionTypes.Tags:
            event = `${BULK_PREFIX} ${TAGS_LABEL} ${SELECTED}`;
            label = TAGS_LABEL;
            break;
          default:
            break;
        }
        if (event) {
          window.analytics.track(event, {
            category: BULK_ACTION_CATEGORY,
            label,
          });
        }
      }
    }).ignoreElements();

export const trackBulkExportSelect = action$ =>
  action$.ofType(DOWNLOAD_VISIBILITY)
    .do(({ downloadVisible }) => {
      if (window.analytics) {
        let event = `${BULK_PREFIX} ${EXPORT_LABEL}`;
        if (downloadVisible) {
          event = `${event} ${SELECTED}`;
        } else {
          event = `${event} ${CANCELLED}`;
        }
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: EXPORT_LABEL,
        });
      }
    }).ignoreElements();

export const trackBulkExport = action$ =>
  action$.ofType(submissionsTableActionTypes.EXPORT_SUBMISSION_API)
    .filter(action => action.status === 'success' || action.status === 'failure')
    .do(action => {
      let event = `${BULK_PREFIX} ${EXPORT_LABEL}`;

      if (action.status === 'success') {
        event = `${event} ${EXECUTED}`;
      } else if (action.status === 'failure') {
        event = `${event} ${FAILED}`;
      }

      if (window.analytics) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label: EXPORT_LABEL,
        });
      }
    }).ignoreElements();

export const trackBulkArchiveAndDeleteSelect = action$ =>
  action$.ofType(LOADER_VISIBILITY)
    .do(({ visibility, status }) => {
      if (status) {
        let event = '';
        let label = '';
        switch (status) {
          case 'archiveSubmission':
            label = ARCHIVE_LABEL;
            event = `${BULK_PREFIX} ${ARCHIVE_LABEL}`;
            break;
          case 'deleteSubmission':
          case 'deleteSubmissions':
            label = DELETE_LABEL;
            event = `${BULK_PREFIX} ${DELETE_LABEL}`;
            break;
          case 'deleteStagesBulk':
            label = STAGES_LABEL;
            event = BULK_REMOVE_STAGES;
            break;
          case 'deleteAssigneesBulk':
            label = ASSIGNEES_LABEL;
            event = BULK_REMOVE_ASSIGNEES;
            break;
          case 'deleteTagsBulk':
            label = TAGS_LABEL;
            event = BULK_REMOVE_TAGS;
            break;
          default:
            break;
        }

        if (event) {
          event = visibility ? `${event} ${SELECTED}` : `${event} ${CANCELLED}`;
          window.analytics.track(event, {
            category: BULK_ACTION_CATEGORY,
            label,
          });
        }
      }
    }).ignoreElements();

export const trackBulkActionsWithDropdownCancel = action$ =>
  action$.ofType(LOADER_VISIBILITY)
    .do(({ visibility, status }) => {
      if (status && !visibility) {
        let event = '';
        let label = '';
        switch (status) {
          case 'moveToStage':
            label = STAGES_LABEL;
            event = `${BULK_MOVE_TO_STAGE} ${CANCELLED}`;
            break;
          case 'fnrStage':
            label = STAGES_LABEL;
            event = `${BULK_FIND_AND_REPLACE_STAGES} ${CANCELLED}`;
            break;
          case 'addAssignee':
            label = ASSIGNEES_LABEL;
            event = `${BULK_ADD_ASSIGNEES} ${CANCELLED}`;
            break;
          case 'replaceAssignees':
            label = ASSIGNEES_LABEL;
            event = `${BULK_REPLACE_ASSIGNEES} ${CANCELLED}`;
            break;
          case 'fnrAssignees':
            label = ASSIGNEES_LABEL;
            event = `${BULK_FIND_AND_REPLACE_ASSIGNEES} ${CANCELLED}`;
            break;
          case 'addTag':
            label = TAGS_LABEL;
            event = `${BULK_ADD_TAGS} ${CANCELLED}`;
            break;
          case 'replaceTags':
            label = TAGS_LABEL;
            event = `${BULK_REPLACE_TAGS} ${CANCELLED}`;
            break;
          case 'fnrTags':
            label = TAGS_LABEL;
            event = `${BULK_FIND_AND_REPLACE_TAGS} ${CANCELLED}`;
            break;
          default:
            break;
        }

        if (event) {
          window.analytics.track(event, {
            category: BULK_ACTION_CATEGORY,
            label,
          });
        }
      }
    }).ignoreElements();

export const trackBulkActionsWithDropdownSelect = action$ =>
  action$.ofType(submissionsTableActionTypes.SET_BULK_ACTION_STATE)
    .do(action => {
      let label;
      let event;
      switch (action.payload.entityType) {
        case bulkActionTypes.Stages:
          label = STAGES_LABEL;
          switch (action.payload.modalType) {
            case bulkActions.Add:
              event = `${BULK_MOVE_TO_STAGE} ${SELECTED}`;
              break;
            case bulkActions.FindAndReplace:
              event = `${BULK_FIND_AND_REPLACE_STAGES} ${SELECTED}`;
              break;
            default:
              break;
          }
          break;
        case bulkActionTypes.Assignees:
          label = ASSIGNEES_LABEL;
          switch (action.payload.modalType) {
            case bulkActions.Add:
              event = `${BULK_ADD_ASSIGNEES} ${SELECTED}`;
              break;
            case bulkActions.Replace:
              event = `${BULK_REPLACE_ASSIGNEES} ${SELECTED}`;
              break;
            case bulkActions.FindAndReplace:
              event = `${BULK_FIND_AND_REPLACE_ASSIGNEES} ${SELECTED}`;
              break;
            default:
              break;
          }
          break;
        case bulkActionTypes.Tags:
          label = TAGS_LABEL;
          switch (action.payload.modalType) {
            case bulkActions.Add:
              event = `${BULK_ADD_TAGS} ${SELECTED}`;
              break;
            case bulkActions.Replace:
              event = `${BULK_REPLACE_TAGS} ${SELECTED}`;
              break;
            case bulkActions.FindAndReplace:
              event = `${BULK_FIND_AND_REPLACE_TAGS} ${SELECTED}`;
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }

      if (event) {
        window.analytics.track(event, {
          category: BULK_ACTION_CATEGORY,
          label,
        });
      }
    }).ignoreElements();


export default [
  trackMoveToStage,
  trackRemoveStages,
  trackFindAndReplaceStages,
  trackAddAssignee,
  trackReplaceAssignees,
  trackRemoveAssignees,
  trackFindAndReplaceAssignees,
  trackAddTag,
  trackReplaceTags,
  trackRemoveTags,
  trackFindAndReplaceTags,
  trackShareSubmissionsSelection,
  trackShareSubmissions,
  trackBulkActionSelection,
  trackBulkExportSelect,
  trackBulkExport,
  trackBulkArchiveAndDeleteSelect,
  trackBulkActionsWithDropdownCancel,
  trackBulkActionsWithDropdownSelect,
];
