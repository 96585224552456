import {connect} from 'react-redux';

import {
  addEmptyRule,
  changeRuleEmailBody,
  changeRuleEmailRecipients,
  changeRuleEmailSubject,
  changeRuleEmailAttachedPdf,
  changeRuleEmailIncludeSMLink,
  changeRuleEmailIncludeFields,
  changeRuleStatus,
  deleteRule,
  loadRules,
  getFormFieldsForEmail,
  saveRulesToServer,
  setApbVisibility,
  setEmailVisibility,
} from 'actions/automatedProcessBuilder';
import AutomatedProcessBuilder from 'components/AutomatedProcessBuilder';

import injectPermissions from 'decorators/injectPermissions';
import {
  getRuleForEmailEditor,
  getRulesForCurrentForm,
  getSubmissionManagerFormId,
  isApbLoading,
  getCurrentFormEntities,
} from '../../reducers';
import {
  getRuleForEmailEditor as getRuleForEmailEditorEmbed,
  getRulesForCurrentForm as getRulesForCurrentFormEmbed,
  getApbFormId,
  isApbLoading as isApbLoadingEmbed,
  getFormEntities as getFormEntitiesEmbed,
} from 'embed/reducers';
import {areRulesValid, getIsThenValueValid} from 'utils/automatedProcessBuilder';


const mapStateToPropsForEmbed = (state, props) => {
  const rules = getRulesForCurrentFormEmbed(state);
  const entities = getFormEntitiesEmbed(state);
  const areAllRulesValid = areRulesValid(rules, entities);
  const ruleForEmailEditor = getRuleForEmailEditorEmbed(state);
  return {
    entities,
    formId: getApbFormId(state) || '',
    haveUnsavedRules: props.haveUnsavedRules,
    isAddEnabled: rules.length === 0 || areAllRulesValid,
    isApplyEnabled: ruleForEmailEditor && !!getIsThenValueValid(ruleForEmailEditor.thenValue, entities),
    isLoading: isApbLoadingEmbed(state),
    isSaveEnabled: areAllRulesValid,
    ruleForEmailEditor,
    rules,
    setHaveRulesChanged: props.setHaveRulesChanged,
    showConfirmation: props.showConfirmation,
  };
};

const mapStateToPropsNonEmbed = (state, props) => {
  const rules = getRulesForCurrentForm(state);
  const entities = getCurrentFormEntities(state);
  const areAllRulesValid = areRulesValid(rules, entities);
  const ruleForEmailEditor = getRuleForEmailEditor(state);
  return {
    entities,
    formId: getSubmissionManagerFormId(state),
    haveUnsavedRules: props.haveUnsavedRules,
    isAddEnabled: rules.length === 0 || areAllRulesValid,
    isApplyEnabled: ruleForEmailEditor && !!getIsThenValueValid(ruleForEmailEditor.thenValue, entities),
    isLoading: isApbLoading(state),
    isSaveEnabled: areAllRulesValid,
    ruleForEmailEditor,
    rules,
    setHaveRulesChanged: props.setHaveRulesChanged,
    showConfirmation: props.showConfirmation,
  };
};

const mapStateToProps = (state, props) =>
  mapStateToPropsNonEmbed(state, props);

const mapDispatchToProps = {
  addEmptyRule,
  changeRuleEmailBody,
  changeRuleEmailRecipients,
  changeRuleEmailSubject,
  changeRuleEmailAttachedPdf,
  changeRuleEmailIncludeSMLink,
  changeRuleEmailIncludeFields,
  changeRuleStatus,
  deleteRule,
  loadRules,
  getFormFieldsForEmail,
  saveRulesToServer,
  setApbVisibility,
  setEmailVisibility,
};

export const AutomatedProcessBuilderEmbed = injectPermissions(
  connect(
    mapStateToPropsForEmbed,
    mapDispatchToProps
  )(AutomatedProcessBuilder)
);

export default injectPermissions(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AutomatedProcessBuilder)
);
