import api from 'actions/api';
import { REQUEST_INSTRUCTION } from 'constants/types/liveViewGatingActionTypes';


export const requestInstruction = (token: string, commandCompleted = null) =>
  api.postWithAuthIfPresent({
    endpoint: 'forms/gate/liveViewGate.json',
    baseType: REQUEST_INSTRUCTION,
    data: {
      commandCompleted,
      token,
    },
    success: (_state, {response}) => response.result,
    failure: (_state, error) => ({error}),
  });
