import React from 'react';
import styles from './Text.css';
import * as Colors from 'utils/colors';


export default class Highlighted extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isAnimating: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { animating } = this.props;
    if (nextProps.animating && animating !== nextProps.animating) {
      this.setState({ isAnimating: true });
      setTimeout(() => {
        this.setState({ isAnimating: false });
      }, 500);
    }
  }

  render() {
    const { isAnimating } = this.state;
    return (<span className={styles.highlight} style={{ backgroundColor: isAnimating ? '#616161' : Colors.seamlessDocsBlue }}>{this.props.text}</span>);
  }
}

