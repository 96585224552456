import api from './api';
import { GET_FEATURE_FLAGS } from 'constants/types/features';
import { Action, State } from 'types/features';

export default function features(state: State = {}, action: Action): State {
  switch (action.type) {
    case GET_FEATURE_FLAGS:
      return api(action, state, {
        success: () => ({ ...action.payload.features }),
      });
    default:
      return state;
  }
}

export const getFeatureState = (state: State): State => state;
