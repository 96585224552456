import React, { useState } from 'react';
import classNames from 'classnames';

import { FormState, LiveViewElement, LiveViewPage } from 'types/liveView';
import formStyle from './FormLiveView.css';

import arrowDownSolid from 'icons/arrowDownSolid.svg';
import arrowUpSolid from 'icons/arrowUpSolid.svg';
import editButton from 'icons/editButton.svg';
import { elementsForPreview } from 'constants/liveViewPage';
import moment from 'moment';
import { getCheckboxValue, getElementValue } from 'utils/formLiveView/formLiveView';

type Props = {
  opened?: boolean,
  page: LiveViewPage,
  formState: FormState,
  pageIndex: number,
  setCurrentPage: (index: number) => void,
}
const ReviewSection = ({ opened = false, page, formState, pageIndex, setCurrentPage }: Props) => {
  const [isOpened, setIsOpened] = useState<boolean>(opened);

  const elements: LiveViewElement[] =
    page.rows.flatMap(row => row.elements).
      filter(element => element.excludeFromPreview !== true && elementsForPreview.includes(element.elementType));

  if (!elements.length) return null;

  const isHeadingDynamic = (): boolean => {
    const firstEl = elements[0];
    return !!((firstEl?.elementType === 'heading' || firstEl?.elementType === 'sub_heading')
      && firstEl?.label);
  };

  const getPageHeadingLabel = (): string => {
    const firstEl = elements[0];
    if (isHeadingDynamic() && firstEl.label) {
      return firstEl.label;
    }
    return `Section ${pageIndex + 1}`;
  };
  const pageHeadingLabel: string = getPageHeadingLabel();

  const renderField = (element: LiveViewElement) => {
    let value: string = '';
    switch (element.elementType) {
      case 'checkbox':
      case 'radio':
        value = getCheckboxValue(element, formState);
        break;
      case 'date_picker':
        const date: Date = new Date(getElementValue(element.id, formState));
        value = moment(date).format('MM/DD/YYYY');
        break;
      default:
        value = getElementValue(element.id, formState);
        break;
    }
    return (<div key={`review_${element.id}`} className={formStyle.reviewLine}>
      <span className={formStyle.reviewLineLabel}>{element.label}</span>
      <span className={formStyle.reviewLineValue}>{value}</span>
    </div>
    );
  };

  return (<div className={formStyle.reviewSection}>
    <div
      onClick={() => setIsOpened(!isOpened)}
      className={classNames(formStyle.reviewsectionHeading,
        {
          [formStyle.reviewsectionHeadingOpened]: isOpened,
        }
      )}
    >
      <div className={formStyle.reviewsectionHeadingLabelContainer}>
        <span className={formStyle.reviewsectionHeadingLabel}>
          {pageHeadingLabel}
        </span>
        <img src={editButton} alt='editButton' onClick={() => setCurrentPage(pageIndex)} />
      </div>
      {isOpened ? <img src={arrowUpSolid} alt='arrowUp' /> : <img src={arrowDownSolid} alt='arrowDown' />}
    </div>
    <div className={formStyle.reviewInfo}>
      {isOpened && elements.map((element: LiveViewElement, index: number) => {
        if (index === 0 && isHeadingDynamic()) {
          return null;
        }
        return renderField(element);
      })}
    </div>
  </div>);
};

export default ReviewSection;
