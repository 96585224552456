export const ALL_FILTER_INITIAL_LOAD = 'ALL_FILTER_INITIAL_LOAD';
export const ALL_FILTER_SUBSEQUENT_LOAD = 'ALL_FILTER_SUBSEQUENT_LOAD';
export const ARCHIVED_SUBMISSION_FILTER_INITIAL_LOAD = 'SM_ARCHIVED_SUBMISSION_FILTER_INITIAL_LOAD';
export const ASSIGNED_TO_ME_FILTER_INITIAL_LOAD = 'ASSIGNED_TO_ME_FILTER_INTIAL_LOAD';
export const ASSIGNED_TO_ME_FILTER_SUBSEQUENT_LOAD = 'ASSIGNED_TO_ME_FILTER_SUBSEQUENT_LOAD';
export const DRAFTS_FILTER_INITIAL_LOAD = 'DRAFTS_FILTER_INITIAL_LOAD';
export const DRAFTS_FILTER_SUBSEQUENT_LOAD = 'DRAFTS_FILTER_SUBSEQUENT_LOAD';
export const SHARED_FILTER_INITIAL_LOAD = 'SHARED_FILTER_INITIAL_LOAD';
export const SHARED_FILTER_SUBSEQUENT_LOAD = 'SHARED_FILTER_SUBSEQUENT_LOAD';
export const SUBMITTED_FILTER_INITIAL_LOAD = 'SUBMITTED_FILTER_INITIAL_LOAD';
export const SUBMITTED_FILTER_SUBSEQUENT_LOAD = 'SUBMITTED_FILTER_SUBSEQUENT_LOAD';
export const SIGNED_BY_ME_FILTER_INITIAL_LOAD = 'SIGNED_BY_ME_FILTER_INITIAL_LOAD';
export const SIGNED_BY_ME_FILTER_SUBSEQUENT_LOAD = 'SIGNED_BY_ME_FILTER_SUBSEQUENT_LOAD';
export const ASSIGNED_TO_ME_SUBMISSION_FILTER_INITIAL_LOAD = 'ASSIGNED_TO_ME_SUBMISSION_FILTER_INITIAL_LOAD';
export const ASSIGNED_TO_ME_SUBMISSION_FILTER_SUBSEQUENT_LOAD = 'ASSIGNED_TO_ME_SUBMISSION_FILTER_SUBSEQUENT_LOAD';
export const DRAFTS_SUBMISSION_FILTER_INITIAL_LOAD = 'DRAFT_SUBMISSION_FILTER_INITIAL_LOAD';
export const DRAFTS_SUBMISSION_FILTER_SUBSEQUENT_LOAD = 'DRAFT_SUBMISSION_FILTER_SUBSEQUENT_LOAD';
export const SHARED_SUBMISSION_FILTER_INITIAL_LOAD = 'SHARED_SUBMISSION_FILTER_INITIAL_LOAD';
export const SHARED_SUBMISSION_FILTER_SUBSEQUENT_LOAD = 'SHARED_SUBMISSION_FILTER_SUBSEQUENT_LOAD';
export const SUBMITTED_SUBMISSION_FILTER_INITIAL_LOAD = 'SUBMITTED_SUBMISSION_FILTER_INITIAL_LOAD';
export const SUBMITTED_SUBMISSION_FILTER_SUBSEQUENT_LOAD = 'SUBMITTED_SUBMISSION_FILTER_SUBSEQUENT_LOAD';
export const SIGNED_BY_ME_SUBMISSION_FILTER_INITIAL_LOAD = 'SIGNED_BY_ME_SUBMISSION_FILTER_INITIAL_LOAD';
export const SIGNED_BY_ME_SUBMISSION_FILTER_SUBSEQUENT_LOAD = 'SIGNED_BY_ME_SUBMISSION_FILTER_SUBSEQUENT_LOAD';
export const CLEAR_QUICK_FILTER = 'CLEAR_QUICK_FILTER';
export const SELECT_QUICK_FILTER = 'SELECT_QUICK_FILTER';
