import {createStore, applyMiddleware} from 'redux';
import rootReducer from '../reducers';
import epicMiddleware from '../middleware/epics';
import {routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';
import {browserHistory} from 'react-router';


export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      epicMiddleware(),
      routerMiddleware(browserHistory),
      thunk,
    )
  );
}
