import React, { useState, useEffect, forwardRef } from 'react';
import sharedStyle from '../shared.css';
import classNames from 'classnames';
import DynamicListElement from './DynamicListElement';
import { v4 as uuidv4 } from 'uuid';
import style from './DynamicList.css';
import { DYNAMIC_LIST_DEFAULT_VALUES } from 'constants/liveViewPage';
import { LabelAlignment } from 'types/liveView';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';

type ListElementValue = {[key: string]: string};
const convertFieldStateToValues = (fieldState: any, label: string): ListElementValue[] => {
  const values = fieldState?.fields[label] || [];
  if (!values.length) {
    return [{ val: '', key: uuidv4() }];
  }
  return values.map((val: string) => ({ val, key: uuidv4() }));
};

export type Props = {
  id: string,
  label?: string,
  onChange?: Function,
  updateForm?: Function,
  disabled?: boolean,
  required?: boolean,
  extraData?: any,
  fieldState?: any,
  specialSettings?: {
    buttonLabel: string,
    description: string,
    fieldLimit: string,
    inputLabel: string,
    inputPlaceholder: string,
    isLabelVisible: boolean,
    labelAlignment: LabelAlignment,
  }
}
function DynamicList({
  id,
  label = DYNAMIC_LIST_DEFAULT_VALUES.label,
  fieldState,
  onChange = undefined,
  updateForm = undefined,
  disabled = false,
  extraData,
  specialSettings,
  ...props
}: Props) {
  const [values, setValues] = useState<ListElementValue[]>(convertFieldStateToValues(fieldState, label));

  const description: string = specialSettings?.description || DYNAMIC_LIST_DEFAULT_VALUES.description;
  const fieldLimit: string = specialSettings?.fieldLimit || DYNAMIC_LIST_DEFAULT_VALUES.fieldLimit;
  const inputLabel: string = specialSettings?.inputLabel || DYNAMIC_LIST_DEFAULT_VALUES.inputLabel;
  const inputPlaceholder: string =
    specialSettings?.inputPlaceholder || DYNAMIC_LIST_DEFAULT_VALUES.inputPlaceholder;
  const buttonLabel: string = specialSettings?.buttonLabel || DYNAMIC_LIST_DEFAULT_VALUES.buttonLabel;
  const limit: number = Number.parseInt(fieldLimit, 10);
  const showLabel: boolean = extraData?.showLabel === 'f' ? false : true;

  const updateFormState = (): void => {
    const s = {
      fields: {
        [label]: values.map(v => v.val),
      },
    };
    updateForm && updateForm(s);
  };

  useEffect(() => {
    onChange && onChange(values.map(v => v.val));
    updateFormState();
  }, [values]);

  const addNewInput = (): void => {
    if (values.length < limit) {
      setValues([...values, { val: '', key: uuidv4() }]);
    }
  };

  const removeInput = (index: number): void => {
    const newValues: {[key: string]: string}[] = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  };

  const changeValue = (newValue: string, index: number): void => {
    const newValues: {[key: string]: string}[] = [...values];
    const oldValue: {[key: string]: string} = newValues.splice(index, 1)[0];
    newValues.splice(index, 0, {...oldValue, val: newValue});
    setValues(newValues);
  };

  return (
    <div id={`container_${id}`} className={classNames('form_control_group')} {...props}>
      <fieldset name={id} id={id} className={sharedStyle.Fieldset} disabled={disabled}>
        { showLabel && <legend className={getAlignmentClass(specialSettings?.labelAlignment || 'auto', sharedStyle)}>{label}</legend> }
        <p id={`description_${id}`}>{description}</p>
        <div>
          {values.map(({ val, key }: {[key: string]: string}, index: number) => <DynamicListElement
            id={id}
            index={index}
            label={inputLabel}
            placeholder={inputPlaceholder}
            value={val}
            key={key}
            onRemove={removeInput}
            onChangeValue={changeValue}
          />
          )}
        </div>
        <div className={style.addNewInputButtonContainer}>
          <button
            id={`addNewInputButton_${id}`}
            disabled={values.length >= limit}
            type='button'
            onClick={addNewInput}
            className={style.addNewInputButton}
          >{buttonLabel}</button>
          { values.length >= limit && <p>You have reached the max limit of fields.</p> }
        </div>
      </fieldset>
    </div>
  );
}

export default React.memo(forwardRef(DynamicList));
