import { Observable } from 'rxjs/Observable';

import { LOAD_PARTICIPANTS, SAVE_PARTICIPANTS } from 'constants/participantsSidebar';
import { success } from './utils/filters';

import type { ActionsObservable } from 'redux-observable';
import type { MiddlewareAPI } from 'redux';
import type { State } from 'types/shared';
import { getConditionalsForCurrentForm, getExistingParticipants, getParticipantsFormId, getSubmitter } from 'embed/reducers';
import { loadRules, saveRules } from 'actions/conditionals';

export default function conditionalsEpic(
  action$: ActionsObservable<any>,
  store: MiddlewareAPI<State>,
): Observable<any> {
  const loadParticipantsAction$ = action$.ofType(LOAD_PARTICIPANTS).filter(success);
  const saveParticipantsAction$ = action$.ofType(SAVE_PARTICIPANTS).filter(success);

  const loadRulesAction$ = loadParticipantsAction$
    .mergeMap(() => {
      const state = store.getState();
      const formId = getParticipantsFormId(state);
      const existingParticipants = getExistingParticipants(state);
      return Observable.of(loadRules(formId, existingParticipants));
    });

  const saveRulesAction$ = saveParticipantsAction$
    .mergeMap(() => {
      const state = store.getState();
      const formId = getParticipantsFormId(state);
      const rules = getConditionalsForCurrentForm(state);
      const existingParticipants = getExistingParticipants(state);
      const submitter = getSubmitter(state);
      return Observable.of(saveRules(formId, rules, existingParticipants, submitter));
    });

  return loadRulesAction$.merge(saveRulesAction$);
}
