import type { ParticipantTypeEnum, EmailTypeEnum, AddParticipantTabEnum } from 'types/participants';

export const KNOWN_PARTICIPANT = 'KNOWN';
export const UNKNOWN_PARTICIPANT = 'UNKNOWN';
export const KNOWN_LIST = 'KNOWN_LIST';
export const SUBMITTER = 'SUBMITTER';
export const PARALLEL_GROUP = 'PARALLEL_GROUP';
export const participantTypeEnum: ParticipantTypeEnum = {
  knownParticipant: KNOWN_PARTICIPANT,
  unknownParticipant: UNKNOWN_PARTICIPANT,
  knownList: KNOWN_LIST,
  submitter: SUBMITTER,
  parallelGroup: PARALLEL_GROUP,
};

export const REQUEST_EMAIL = 'REQUEST';
export const COMPLETION_EMAIL = 'COMPLETION';
export const SIGNATURE_CONFIRMATION_EMAIL = 'SIGNATURE_CONFIRMATION';
export const emailTypeEnum: EmailTypeEnum = {
  request: REQUEST_EMAIL,
  completion: COMPLETION_EMAIL,
  signatureConfirmation: SIGNATURE_CONFIRMATION_EMAIL,
};

export const SUBMITTER_INDEX = -1;
export const SUBMITTER_PARTICIPATION_ORDER = 0;

export const SIGNER_KEY_RESPONSE_SEPARATOR = '\n\t\t\t\t\t\t\t\t';

export const DETAILS = 'Details';
export const EMAILS = 'Emails';
export const RULES = 'Rules';
export const addParticipantTabEnum: AddParticipantTabEnum = {
  details: DETAILS,
  emails: EMAILS,
  rules: RULES,
};

export const KNOWN_LIST_USER_LIMIT = 150;

export const SET_IS_LABEL_INPUT_IN_FOCUS = 'EMBED_SET_IS_LABEL_INPUT_IN_FOCUS';
export const TOGGLE_MODAL = 'EMBED_PARTICIPANTS_SIDEBAR_TOGGLE_MODAL';
export const SET_FORM_ID = 'EMBED_PARTICIPANTS_SIDEBAR_SET_FORM_ID_MODAL';
export const LOAD_PARTICIPANTS = 'EMBED_LOAD_PARTICIPANTS';
export const LOAD_PARTICIPANTS_WITH_CONDITIONALS = 'EMBED_LOAD_PARTICIPANTS_WITH_CONDITIONALS';
export const SAVE_PARTICIPANTS = 'EMBED_SAVE_PARTICIPANTS';
export const SAVE_PARTICIPANTS_WITH_CONDITIONALS = 'EMBED_SAVE_PARTICIPANTS_WITH_CONDITIONALS';
export const OPEN_ADD_PARTICIPANT_TAB = 'EMBED_OPEN_ADD_PARTICIPANT_TAB';
export const OPEN_EDIT_EXISTING_PARTICIPANT = 'EMBED_OPEN_EDIT_EXISTING_PARTICIPANT';
export const CLOSE_ADD_PARTICIPANT_TAB = 'EMBED_CLOSE_ADD_PARTICIPANT_TAB';
export const UPDATE_CURRENT_PARTICIPANT = 'EMBED_UPDATE_CURRENT_PARTICIPANT';
export const ADD_TO_EXISTING_PARTICIPANTS = 'EMBED_ADD_TO_EXISTING_PARTICIPANTS';
export const ADD_SUBMITTER = 'EMBED_ADD_SUBMITTER';
export const UPDATE_EXISTING_PARTICIPANT = 'EMBED_UPDATE_EXISTING_PARTICIPANT';
export const TOGGLE_EMAIL_SETTING = 'EMBED_TOGGLE_EMAIL_SETTING';
export const OPEN_EDIT_EMAIL_UI = 'EMBED_OPEN_EDIT_EMAIL_UI';
export const CLOSE_EDIT_EMAIL_UI = 'EMBED_CLOSE_EDIT_EMAIL_UI';
export const DELETE_PARTICIPANT = 'EMBED_DELETE_PARTICIPANT';
export const REMOVE_PARTICIPANT_FROM_GROUP = 'EMBED_REMOVE_PARTICIPANT_FROM_GROUP';
export const DUPLICATE_PARTICIPANT = 'EMBED_DUPLICATE_PARTICIPANT';
export const TOGGLE_INCLUDE_LEGAL_DISCLAIMER = 'EMBED_TOGGLE_INCLUDE_LEGAL_DISCLAIMER';
export const TOGGLE_SEND_SIGNATURE_REMINDERS = 'EMBED_TOGGLE_SEND_SIGNATURE_REMINDERS';
export const UPDATE_CURRENT_EMAIL_SETTING = 'EMBED_UPDATE_CURRENT_EMAIL_SETTING';
export const SWITCH_PARTICIPANT_TYPE = 'EMBED_SWITCH_PARTICIPANT_TYPE';
export const TRACK_EMAIL_SETTINGS = 'EMBED_TRACK_EMAIL_SETTINGS';
export const RELOCATE_PARTICIPANT = 'EMBED_RELOCATE_PARTICIPANT';
export const MOVE_PARTICIPANT_TO_GROUP = 'EMBED_MOVE_PARTICIPANT_TO_GROUP';
export const MOVE_PARTICIPANT_FROM_GROUP = 'EMBED_MOVE_PARTICIPANT_FROM_GROUP';

export const OPEN_ADD_GROUP_DETAILS_TAB = 'EMBED_OPEN_ADD_GROUP_DETAILS_TAB';
export const CLOSE_ADD_GROUP_DETAILS_TAB = 'EMBED_CLOSE_ADD_GROUP_DETAILS_TAB';
export const ADD_GROUP = 'EMBED_ADD_GROUP';
export const DELETE_GROUP = 'EMBED_DELETE_GROUP';
export const OPEN_EDIT_EXISTING_GROUP = 'EMBED_OPEN_EDIT_EXISTING_GROUP';
export const UPDATE_CURRENT_GROUP = 'EMBED_UPDATE_CURRENT_GROUP';
export const UPDATE_GROUP = 'EMBED_UPDATE_GROUP';
export const RELOCATE_GROUP = 'EMBED_RELOCATE_GROUP';
