import React from 'react';

export default class TestErrorHandling extends React.Component {
  foo;

  render() {
    this.foo(); // Should throw something like [TypeError: this.foo is not a function]
    return (
      <div>This page exists to trigger our error handling in a natural way in production</div>
    );
  }
}
