import React from 'react';
import DropdownOption from 'components/Dropdown/DropdownOption';
import {
  getTriggerIcon,
  getTriggerText,
} from 'utils/triggers';

import styles from './TriggerItem.css';

// import type {
//   ApbRuleTrigger,
// } from 'types/automatedProcessBuilder';

export interface Props {
  data: any,
  isCurrentSelection: boolean,
  isFocused: boolean,
  onClick?: (trigger: any) => void,
}

export default function TriggerItem({
  data,
  isCurrentSelection,
  onClick,
  isFocused,
}: Props) {
  const {
    trigger,
    triggerType,
  } = data;
  const handleOnClick = () => {
    onClick && onClick(data);
  };
  if (!trigger) {
    return (
      <DropdownOption
        isFocused={isFocused}
        isSelected={isCurrentSelection}
        onClick={handleOnClick}>
        <div className={styles.triggerItemAppearance}>
          <span>Choose</span>
        </div>
      </DropdownOption>
    );
  }
  const Icon = getTriggerIcon(trigger);
  const text = getTriggerText(trigger, triggerType);
  return (
    <DropdownOption
      isFocused={isFocused}
      isSelected={isCurrentSelection}
      showCheckmark={false}
      onClick={handleOnClick}>
      <div className={styles.triggerItemAppearance}>
        <Icon className={styles.triggerItemIcon}/>
        <span className={styles.triggerItemTextLayout}>
          {text}
        </span>
      </div>
    </DropdownOption>
  );
}
