import {every, get, isArray} from 'lodash';
import { nanoid } from 'nanoid';
import {ruleStatusEnum} from 'constants/automatedProcessBuilder';
import {triggersEnum, triggerTypeEnum, recipientTypeEnum} from 'constants/triggers';
import {editorStateToStructure, structureToEditorState} from 'utils/richTextEditor/conversion';
import {containsInvalidEmail} from 'utils/email';

export function createEmptyRule() {
  return {
    errorMessages: [],
    isOnlyEmailModified: false,
    isModified: false,
    isNew: true,
    ifTrigger: null,
    ifValue: null,
    ruleId: nanoid(), // Will be server-generated
    status: ruleStatusEnum.ENABLED,
    thenTrigger: null,
    thenValue: null,
  };
}

export const getTriggerProperty = (triggerType: string) =>
  triggerType === triggerTypeEnum.IF ? 'ifTrigger' : 'thenTrigger';

export const getValueProperty = (triggerType: string) =>
  triggerType === triggerTypeEnum.IF ? 'ifValue' : 'thenValue';

export function getTagNamesFromRule(rule: any, triggerType: string) {
  const valueProperty = getValueProperty(triggerType);
  const tagNames = get(rule, [valueProperty, 'tag'], []);
  return tagNames;
}

export function getOtherTagNamesFromRule(rule: any, triggerType: string) {
  const IF = triggerTypeEnum.IF;
  const THEN = triggerTypeEnum.THEN;
  const valueProperty = getValueProperty(triggerType === IF ? THEN : IF);
  const tagNames = get(rule, [valueProperty, 'tag'], []);
  return tagNames;
}

export function getStageFromRule(rule: any, triggerType: string, stages: any[]) {
  const valueProperty = getValueProperty(triggerType);
  const stageName = get(rule, [valueProperty, 'stage'], null);
  return stageName && stages.find(stageFromList => stageName === stageFromList.name);
}

export function getOtherStageFromRule(rule: any, triggerType: string, stages: any[]) {
  const IF = triggerTypeEnum.IF;
  const THEN = triggerTypeEnum.THEN;
  const valueProperty = getValueProperty(triggerType === IF ? THEN : IF);
  const stageName = get(rule, [valueProperty, 'stage'], null);
  return stageName && stages.find(stageFromList => stageName === stageFromList.name);
}

export function getAssigneesFromRule(rule: any, triggerType: string, users: { [x: string]: any; }) {
  const valueProperty = getValueProperty(triggerType);
  const assigneeIds: string[] = get(rule, [valueProperty, 'assignment'], []);
  // Filter out nonexistent users silently rather than failing
  return assigneeIds.map(assigneeId => users[assigneeId]).filter(Boolean);
}

export function getOtherAssigneesFromRule(rule: any, triggerType: string, users: { [x: string]: any; }) {
  const IF = triggerTypeEnum.IF;
  const THEN = triggerTypeEnum.THEN;
  const valueProperty = getValueProperty(triggerType === IF ? THEN : IF);
  const assigneeIds = get(rule, [valueProperty, 'assignment'], []);
  // Filter out nonexistent users silently rather than failing
  return assigneeIds.map(assigneeId => users[assigneeId]).filter(Boolean);
}

export function getEmailFromRule(rule) {
  const email = get(rule, ['thenValue', 'email', 'email']);
  const emptyEmail = {
    body: null,
    subject: '',
    attachedPdf: false,
    hasLinkToSubmissionManager: false,
    recipientResolver: {
      recipientType: null,
      recipients: [],
    },
  };
  return email ? {...emptyEmail, ...email} : emptyEmail;
}

export const isRuleEnabled = rule => rule.status === ruleStatusEnum.ENABLED;

// We return an empty string if anything went wrong here, relying on client and server side validation to pick that up
export function convertIfValueForServer(trigger, value) {
  switch (trigger) {
    case triggersEnum.ASSIGNMENT:
      return {
        value: get(value, ['assignment', '0'], ''),
      };
    case triggersEnum.TAG:
      return {
        value: get(value, ['tag', '0'], ''),
      };
    case triggersEnum.STAGE:
      return {
        value: get(value, ['stage'], ''),
      };
    default:
      return {value: ''};
  }
}

export function convertThenValueForServer(trigger, value) {
  switch (trigger) {
    case triggersEnum.ASSIGNMENT:
      return {
        values: get(value, ['assignment'], []),
      };
    case triggersEnum.TAG:
      return {
        values: get(value, ['tag'], []),
      };
    case triggersEnum.STAGE:
      return {
        values: [get(value, ['stage'], [])],
      };
    case triggersEnum.NOTIFICATION:
      if (value?.email?.email) {
        const { email } = value.email;
        return {
          recipientResolver: email.recipientResolver,
          subject: email.subject,
          body: editorStateToStructure(email.body),
          attachedPdf: email.attachedPdf,
          hasLinkToSubmissionManager: email.hasLinkToSubmissionManager,
          fieldIds: email.fieldIds,
        };
      }
      return {values: []};
    case triggersEnum.WEBHOOK:
      return {
        values: [get(value, ['webhook'], [])]};
    default:
      return {values: []};
  }
}

export function convertRulesForServer(formId: string, rules: any[]) {
  return rules
    .map(rule => {
      if (rule.thenTrigger && rule.ifTrigger) {
        const ruleForServer = {
          formId,
          triggerType: rule.ifTrigger,
          actionType: rule.thenTrigger,
          actionValue: convertThenValueForServer(rule.thenTrigger, rule.thenValue),
          isModified: rule.isModified,
          status: rule.status,
          triggerValue: convertIfValueForServer(rule.ifTrigger, rule.ifValue),
        };
        if (!rule.isNew) {
          (ruleForServer as any).ruleId = rule.ruleId;
        }
        return ruleForServer;
      }
      return null;
    })
    .filter(Boolean);
}

export function convertIfValueForClient(trigger, {value}) {
  switch (trigger) {
    case triggersEnum.ASSIGNMENT:
      return {
        assignment: [value],
      };
    case triggersEnum.TAG:
      return {
        tag: [value],
      };
    case triggersEnum.STAGE:
      return {
        stage: value,
      };
    default:
      return null;
  }
}

export function convertThenValueForClient(trigger, value) {
  // short circuit the email to refine value
  if (!value.values && (value.body || value.recipientResolver || value.subject || value.attachedPdf)) {
    const {body, recipientResolver, subject, hasLinkToSubmissionManager, attachedPdf, fieldIds} = value;
    return {
      email: {
        email: {
          body: structureToEditorState(body),
          recipientResolver,
          subject,
          attachedPdf,
          hasLinkToSubmissionManager,
          fieldIds,
        },
      },
    };
  }
  // this basically opts us out of flow checking because I can't get flow to refine to not Email
  const values = get(value, ['values'], []);
  switch (trigger) {
    case triggersEnum.ASSIGNMENT:
      return {
        assignment: values,
      };
    case triggersEnum.TAG:
      return {
        tag: values,
      };
    case triggersEnum.STAGE:
      return {
        stage: values[0],
      };
    case triggersEnum.WEBHOOK:
      return {
        webhook: values[0],
      };
    default:
      return null;
  }
}

export function convertRulesForClient(rules) {
  return rules.map(rule => ({
    errorMessages: isArray(rule.errorMessages) ? rule.errorMessages : [],
    ifTrigger: rule.triggerType,
    ifValue: convertIfValueForClient(rule.triggerType, rule.triggerValue),
    isModified: rule.isModified,
    isOnlyEmailModified: false,
    isNew: !rule.ruleId,
    ruleId: rule.ruleId || nanoid(),
    status: rule.status,
    thenTrigger: rule.actionType,
    thenValue: convertThenValueForClient(rule.actionType, rule.actionValue),
  }));
}

const fieldsExist = (fieldsId: string[], knownFields: any[]) => (
  knownFields &&
  knownFields.length &&
  fieldsId.every(fieldId => !!knownFields.find(field => field.id === fieldId))
);

const usersAreActive = (usersId, userMap) => (
  userMap &&
  usersId.every(userId => !!userMap[userId] && userMap[userId].isActive)
);

const areRecipientsValid = ({recipientType, recipients}, entities) => {
  switch (recipientType) {
    case recipientTypeEnum.STATIC:
      return !!recipients.length && !containsInvalidEmail(recipients);
    case recipientTypeEnum.SIGNER:
      return !!recipients.length && entities && fieldsExist(recipients, entities.signerFields);
    case recipientTypeEnum.EMAIL_FIELD:
      return !!recipients.length && entities && fieldsExist(recipients, entities.emailFields);
    case recipientTypeEnum.SYSTEM_USER:
      return !!recipients.length && entities && usersAreActive(recipients, entities.users);
    case recipientTypeEnum.ASSIGNED_USER:
    case recipientTypeEnum.SUBMITTER:
      return true;
    default:
      return false;
  }
};

const isWbhookUrlValid = (url: string) => {
  const pattern = new RegExp('^(https:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(url);
};

export const getIsThenValueValid = (thenValue, entities) => {
  if (!thenValue) return false;
  if (thenValue.email) {
    const recipientResolver = get(thenValue, ['email', 'email', 'recipientResolver']);
    return (
      !!recipientResolver &&
      !!recipientResolver.recipientType &&
      areRecipientsValid(recipientResolver, entities)
    );
  } else if (thenValue.webhook) {
    return isWbhookUrlValid(thenValue.webhook);
  }
  return true;
};

export const areRulesValid = (rules, entities) =>
  every(rules, rule => {
    const isArchived = rule.status === ruleStatusEnum.ARCHIVED;
    const isThenValueValid = getIsThenValueValid(rule.thenValue, entities);
    return isArchived || (rule.ifTrigger && rule.thenTrigger && rule.ifValue && isThenValueValid);
  });
