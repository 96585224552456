import { combineReducers } from 'redux';
import core from './core';
import forLobby, * as forLobbySelectors from './forLobby';
import publicInfo, * as publicInfoSelectors from './publicInfo';
import liveView, * as liveViewSelectors from './liveview';
import formSubmit from './formSubmit';
import attachments from './attachments';
import coverLetter from './coverLetter';
import formPassword, * as formPasswordSelectors from './formPassword';
import thankYouPage, * as thankYouPageSelectors from './thankYouPage';


export default combineReducers({
  core,
  forLobby,
  publicInfo,
  liveView,
  formSubmit,
  attachments,
  coverLetter,
  formPassword,
  thankYouPage,
});

export const getFormLiveViewById = (state, formId) =>
  liveViewSelectors.getFormLiveViewById(state.liveView, formId);

export const getFormPublicInfoById = (state, formId) =>
  publicInfoSelectors.getFormPublicInfoById(state.publicInfo, formId);

export const getFormsForLobbyById = (state, formIds) =>
  forLobbySelectors.getFormsForLobbyById(state.forLobby, formIds);

export const checkFormCapability = (state, formId, capability) =>
  forLobbySelectors.checkFormCapability(state.forLobby, formId, capability);

export const getHasEnteredCorrectFormPassword = (state): boolean =>
  formPasswordSelectors.getHasEnteredCorrectPassword(state.formPassword);

export const getIsCheckingFormPassword = (state): boolean =>
  formPasswordSelectors.getIsCheckingPassword(state.formPassword);

export const getCheckFormPasswordError = (state): string | null =>
  formPasswordSelectors.getCheckPasswordError(state.formPassword);

export const getIsThankYouPageShareModalOpen = (state): boolean =>
  thankYouPageSelectors.getIsShareModalOpen(state.thankYouPage);

export const getIsShareSubmissionLoading = (state): boolean =>
  thankYouPageSelectors.getIsShareSubmissionLoading(state.thankYouPage);

export const getShareSubmissionError = (state): string | null =>
  thankYouPageSelectors.getShareSubmissionError(state.thankYouPage);

export const getThankYouPagePdfUrl = (state): string | null =>
  thankYouPageSelectors.getPdfUrl(state.thankYouPage);
