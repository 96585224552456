import React from 'react';
import raf from 'raf';
import Icon from '@material-ui/core/Icon';
import { findDOMNode } from 'utils/reactDOM';
import DarkIconButton from './buttons/DarkIconButton';
import * as search from 'utils/search';
import { withNavBarContext } from 'components/OneNavBar';
import styles from './Toolbar.css';


/* eslint-disable no-useless-computed-key */
const KEYS = {
  [9]: 'tab',
  [27]: 'escape',
  [13]: 'return',
  [37]: 'left',
  [38]: 'up',
  [39]: 'right',
  [40]: 'down',
};
/* eslint-enable */

class SearchToolbarGroup extends React.Component<any, any> { // eslint-disable-line no-use-before-define
  componentDidMount() {
    window.addEventListener('click', this._handleWindowClick);
    const { onChange, onSubmit, navBarContext } = this.props;
    navBarContext.setSearchCallbacks({ onChange, onSubmit });
  }

  componentDidUpdate() {
    raf(this._updateHighlightPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this._handleWindowClick);
  }

  _handleWindowClick = event => {
    const { onBlur, open } = this.props;
    if (open) {
      const toolbarGroupEl = findDOMNode(this.refs.toolbarGroup);
      const { target } = event;
      if (!toolbarGroupEl
        // Reconcile `EventTarget` with `HTMLElement.contains(node: ?Node)`
        || !(target instanceof Node)
        || (toolbarGroupEl !== target && !toolbarGroupEl.contains(target))) {
        onBlur(this);
      }
    }
  };

  _updateHighlightPosition = () => {
    const { input, highlights } = this.refs;
    // This function is called asynchronously, so we need to verify that it's still mounted
    if (highlights && input) {
      (highlights as HTMLElement).style.left = `-${(input as HTMLElement).scrollLeft}px`;
    }
  };

  _refocus = event => {
    event.stopPropagation();
    const { open, onRefocus } = this.props;
    if (open) {
      onRefocus(event);
    }
  };

  _onChange = () => {
    const input = this.refs.input as HTMLInputElement;
    const selectionIndex = (input.selectionStart === input.selectionEnd) ? input.selectionStart : null;
    this.props.onChange(input.value, selectionIndex);
  };

  handleInputOnWheel = () => {
    this._updateHighlightPosition();
  };

  handleInputOnFocus = event => {
    if (search.parsedToStringLength(this.props.value)) {
      this._onChange();
    }
    this._refocus(event);
  };

  handleInputOnBlur = () => {
    raf(this._updateHighlightPosition);
  };

  handleInputOnChange = () => {
    this._onChange();
  };

  handleInputOnKeyDown = event => {
    switch (KEYS[event.keyCode]) {
      case 'down':
      case 'tab':
        event.preventDefault();
        event.stopPropagation();
        this.props.onEscape();
        return;
      case 'up':
        event.preventDefault();
        event.stopPropagation();
        return;
      case 'return':
        event.preventDefault();
        event.stopPropagation();
        this.props.onSubmit();
        return;
      case 'left':
      case 'right':
        // This is here to block events from reaching the DatePicker
        event.stopPropagation();
        break;
      case 'escape':
        event.preventDefault();
        event.stopPropagation();
        this.props.onEscape();
        return;
      default:
        break;
    }
  };

  handleInputOnKeyUp = event => {
    switch (KEYS[event.keyCode]) {
      case 'return':
        // This is here to block events from reaching the DatePicker
        event.preventDefault();
        event.stopPropagation();
        return;
      default:
        return;
    }
  };

  handleGroupOnClick = event => {
    this._onChange();
    this._refocus(event);
    if (!this.props.open) {
      this.props.onOpen(this);
    }
  };

  handleClearButtonOnClick = event => {
    this._refocus(event);
    this.props.onChange('');
  };

  render() {
    const { placeholder, position, isMobile, leftOffset, open, width, value } = this.props;

    const shouldShowClearButton = open && !search.parsedIsEmpty(value);

    return (
      <div
        ref='toolbarGroup'
        className={open ? styles.searchGroupOpen : styles.searchGroup}
        onClick={this.handleGroupOnClick}
        style={{
          display: 'flex',
          boxSizing: 'border-box',
          left: `${leftOffset}px`,
          position,
          width: `${width}px`,
        }}>
        <div
          className={styles.searchIconContainer}
          style={{ cursor: open ? 'default' : 'pointer' }}>
          <Icon classes={{ root: styles.searchIcon }}>search</Icon>
        </div>
        <div
        /* @ts-ignore */
          autoComplete='off' // This is to prevent Firefox state caching
          className={styles.searchInputContainer}
          style={{ cursor: open ? 'auto' : 'pointer', width: (open || !isMobile) ? '100%' : 0 }}>
          <div ref='highlights' className={styles.searchInputHighlights}>
            {search.parsedToComponents(value)}
          </div>
          <input
            ref='input'
            className={styles.searchInput}
            onBlur={this.handleInputOnBlur}
            onChange={this.handleInputOnChange}
            onFocus={this.handleInputOnFocus}
            onKeyDown={this.handleInputOnKeyDown}
            onKeyUp={this.handleInputOnKeyUp}
            onWheel={this.handleInputOnWheel}
            placeholder={placeholder}
            autoComplete='off'
            style={{ cursor: open ? 'auto' : 'pointer' }}
            type='text'
            value={search.parsedToString(value)} />
        </div>
        <div
          className={styles.searchIconContainer}
          style={{ display: shouldShowClearButton ? 'block' : 'none' }}>
          <DarkIconButton icon='highlight_off' onClick={this.handleClearButtonOnClick} />
        </div>
      </div>
    );
  }
}

export default withNavBarContext(SearchToolbarGroup);


