import React, { memo } from 'react';
import styles from '../govOsPay.css';
import { GovOsPaymentObject, FormDataGovOSPaymentConfig } from 'types/govOsPay/govOsPaymentSettings';
import PaymentCard from '../PaymentCard';
import OverlayModalContainer from 'components/GovOsPay/OverlayModalContainer';

type PaymentsModalProps = {
  open: boolean;
  closeModal: () => void;
  govOSPaymentMethods: GovOsPaymentObject[];
  paymentConfig: FormDataGovOSPaymentConfig | null | undefined;
}


const MODAL_SUB_TITLE = 'Select form of payment';

const PaymentsModal = (props: PaymentsModalProps) => {
  const { open, closeModal, govOSPaymentMethods, paymentConfig } = props;

  if (!open) return null;

  return (
    <OverlayModalContainer open={open} closeModal={closeModal} isSelectPaymentTypeModal>
      <h1 className={styles.modalHeader}>
        {MODAL_SUB_TITLE}
      </h1>
      <div className={styles.modalBody}>
        {govOSPaymentMethods?.map(method =>
          (<PaymentCard key={method.paymentType} {...method} />))}
      </div>
      {paymentConfig?.paymentOptional && (
        <button onClick={() => null} className={styles.submitWithoutPayingButton}>Submit without paying</button>
      )}
    </OverlayModalContainer>
  );
};

export default memo(PaymentsModal);
