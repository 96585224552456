import React from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import styles from './FormLiveView.css';
import { DialogContent, DialogTitle } from '@material-ui/core';
import TriangleError from 'icons/icon52.svg';

interface Props {
  open: boolean,
  error: any,
  onClose: React.MouseEventHandler,
}

const defaultErrorTitle = (
  <span>Error submitting form<br/><br/></span>);

const paymentErrorTitle = (
  <span>Error processing payment<br/><br/></span>);

function SubmitErrorModal({
  onClose,
  error,
  open}: Props) {
  const title = error?.status === 400 ? paymentErrorTitle : defaultErrorTitle;
  return (
    <Dialog
      classes={{
        root: classNames(styles.PaymentModalRoot, 'form_pay_modal_root'),
        paper: classNames(styles.PaymentModalContainer, 'form_pay_modal_container'),
      }}
      open={open}
      disableBackdropClick
    >
      <DialogTitle disableTypography className={classNames(styles.PaymentModalTitle, 'form_pay_modal_title')}>
        <img src={TriangleError} alt='error icon' style={{width: '3rem', height: '3rem'}}/><br/><br/>
        {title}
      </DialogTitle>
      <DialogContent>
        <div style={{textAlign: 'center'}}>
          Please try again or contact support.
        </div>
      </DialogContent>
      <div style={{textAlign: 'center', padding: '1em 3em'}}>
        <button className={styles.PaymentModalGoToFormButton} onClick={onClose}>Go back to the form</button>
      </div>
    </Dialog>
  );
}

export default React.memo(SubmitErrorModal);
