import * as React from 'react';
import styles from './SubmissionDetails.css';
import SignatureBoxItem from './SignatureBoxItem';
import EditSignerModal from '../Modals/EditSignerModal';
import SignerDetailsModal from '../Modals/SignerDetailsModal';
import { getFullname, getEmail, getSignerKey, getDeclineReason } from 'utils/textHelper';
import moment from 'moment';
import { recipientsFrom } from 'utils/submissionsManager/signatures';

export default class SignatureStatusBox extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      selectedEmail: null,
      selectedSigner: null,
      selectedSignerKey: null,
    };
  }

  getTimeAgo = datesigned => (datesigned ? moment.unix(parseInt(datesigned, 10)).fromNow() : '');

  setEditSignerStateAndToggle = (signerKey, email) => {
    this.setState({
      selectedEmail: email,
      selectedSignerKey: signerKey,
    });

    if (signerKey) {
      this.props.onEditSignerModalToggle();
    }
  };

  makePreparerListItem = preparer => {
    const _preparer = preparer;
    if (_preparer) {
      return (
        <li className={styles.signatureCard} key='0'>
          <SignatureBoxItem
            signeeType='Preparer'
            fullname={getFullname(_preparer)}
            email={getEmail(_preparer)}
            signerKey={getSignerKey(_preparer)}
            status='prepared'
            signature={_preparer.signature}
            timeAgo={this.getTimeAgo(_preparer.datesigned)}
            onSignerDetailsToggle={() => this.handleSignatureBoxSelect(_preparer)}
            onEditSignerModalToggle={() =>
              this.setEditSignerStateAndToggle(getSignerKey(_preparer), getEmail(_preparer))
            }
          />
        </li>
      );
    }
    return null;
  };

  handleSignNow = signInfo => {
    const { onSignNow } = this.props;
    onSignNow && onSignNow(signInfo);
  };

  handleSignatureBoxSelect = selectedSigner => {
    this.setState({ selectedSigner });
    this.props.onSignerDetailsToggle();
  };

  render() {
    const {
      editSignerVisible,
      handleReNotify,
      onEditSignerModalToggle,
      onSignerDetailsToggle,
      signatureHistory,
      signatureStatus,
      signerDetailsVisible,
    } = this.props;

    const { selectedSigner, selectedSignerKey, selectedEmail } = this.state;
    const declined = signatureStatus === 'DECLINED';

    const preparerListItem = this.makePreparerListItem(signatureHistory.preparer);

    const recipientsList = recipientsFrom(signatureHistory).map((recipient, index) => (
      <li className={styles.signatureCard} key={index + 1}>
        <SignatureBoxItem
          signatureNumber={index + 1}
          signeeType='Signer'
          declined={declined}
          declinedReason={getDeclineReason(recipient)}
          fullname={getFullname(recipient)}
          email={getEmail(recipient)}
          signerKey={getSignerKey(recipient)}
          status={recipient.status}
          signature={recipient.signature}
          handleReNotify={handleReNotify}
          timeAgo={this.getTimeAgo(recipient.datesigned)}
          isRenotifyEnable={recipient.ready_to_sign || false}
          onSignerDetailsToggle={() => this.handleSignatureBoxSelect(recipient)}
          onEditSignerModalToggle={() =>
            this.setEditSignerStateAndToggle(getSignerKey(recipient), getEmail(recipient))
          }
          onSignNowClicked={this.handleSignNow}
        />
      </li>
    ));

    return (
      <span>
        {selectedSignerKey && (
          <EditSignerModal
            editSignerVisible={editSignerVisible}
            onEditSignerModalToggle={onEditSignerModalToggle}
            handleReNotify={handleReNotify}
            signerKey={selectedSignerKey}
            email={selectedEmail}
          />
        )}
        {selectedSigner && (
          <SignerDetailsModal
            open={signerDetailsVisible}
            signatureHistory={signatureHistory}
            selectedSigner={selectedSigner}
            onClose={onSignerDetailsToggle}
          />
        )}
        <ul ref={c => { (this as any).content = c; }}>
          {preparerListItem}
          {recipientsList}
        </ul>
      </span>
    );
  }
}
