import * as React from 'react';
import ShowPasswordField from 'components/Login/ShowPasswordField';
import SubmitButton from 'components/AuthLayout/SubmitButton';
import ErrorMessage from 'components/AuthLayout/ErrorMessage';
import ActiveDirectoryAccounts from 'components/Login/ActiveDirectoryAccounts';
import { grmSignup, deployment } from 'utils/routing';
import styles from 'styles/Login.css';
import authLayoutStyles from 'components/AuthLayout/styles.css';
import injectFeatures from 'decorators/injectFeatures';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { LOGIN } from 'constants/route';


class Login extends React.Component<any, any> {
  static defaultProps = {
    showVisitorLogin: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    this.emailInput && this.emailInput.focus();
  }

  cannotSubmit() {
    return this.props.processingSubmission || !(this.state.email && this.state.password);
  }

  updateInfo = e => {
    const element = e.target;
    this.setState({ [element.name]: element.value });
  };

  emailInput;

  handleSubmit = e => {
    e.preventDefault();
    if (this.cannotSubmit()) return;
    this.props.onSubmit(this.state);
  };

  render() {
    const {
      activeDirectoryConnections,
      error,
      handleActiveDirectoryLogin,
      showADButtons,
      showVisitorLogin,
      maid,
      features,
    } = this.props;

    return (
      <div>
        {features.SSO_LOGIN_ONLY && (
          <span>Your account admin kindly requests you use your organization&#39;s identity provider to log in.</span>
        )}

        {showADButtons && (
          <ActiveDirectoryAccounts
            connections={activeDirectoryConnections}
            onConnectionChoice={handleActiveDirectoryLogin}
          />
        )}
        {!features.SSO_LOGIN_ONLY && showADButtons && (
          <div className={styles.orDivider}>
            <span>or</span>
          </div>
        )}

        {!features.SSO_LOGIN_ONLY && (
          <form className={styles.loginForm} onSubmit={this.handleSubmit}>
            <label htmlFor='emailInput' className={authLayoutStyles.label}>
              Email address
              <input
                ref={input => {
                  this.emailInput = input;
                }}
                id='emailInput'
                className={authLayoutStyles.field}
                onChange={this.updateInfo}
                name='email'
                placeholder='Email address'
                type='email'
                value={this.state.email}
                autoComplete='username'
              />
            </label>
            <label htmlFor='passwordInput' className={authLayoutStyles.label}>
              Password
              <ShowPasswordField
                inputClassName={authLayoutStyles.field}
                name='password'
                onChange={this.updateInfo}
                placeholder='Password'
              />
            </label>
            {error && <ErrorMessage message={error} />}
            <div className={styles.submitRow}>
              <a href='/forgot' className={styles.forgotPassword}>
                Forgot password?
              </a>
              <SubmitButton
                style={{ width: '55%', height: '40px' }}
                disabled={this.cannotSubmit()}
                label={this.props.processingSubmission ? 'Signing in...' : 'Sign in'}
              />
            </div>
            {showVisitorLogin && (
              <div className={styles.visitorContainer}>
                <SplitTreatments names={['ONE_NAV']} attributes={{ maid, deployment }}>
                  {({ treatments: { ONE_NAV }, isReady }) => {
                    if (!isReady) {
                      return null;
                    }

                    if (ONE_NAV.treatment === 'on') {
                      return (
                        <div>
                          Don&apos;t have an account? <a href={grmSignup()}>Sign Up</a>
                        </div>
                      );
                    }

                    return (
                      <div>
                        Are you a visitor?{' '}
                        <a href={LOGIN}>Sign in here</a>
                      </div>
                    );
                  }}
                </SplitTreatments>
              </div>
            )}
          </form>
        )}
      </div>
    );
  }
}

export default injectFeatures(Login);
