import React from 'react';
import Tags from 'components/Tags';
import { SM_DETAILS_SOURCE } from 'constants/forms';
import { NOT_TAGGED_MESSAGE, NO_TAGS_IN_ACCOUNT_MESSAGE } from 'constants/tags';


export default props => (
  <Tags
    {...props}
    maxElements={0}
    source={SM_DETAILS_SOURCE}
    notTaggedMessage={NOT_TAGGED_MESSAGE}
    noTagsInAccountMessage={NO_TAGS_IN_ACCOUNT_MESSAGE} />
);
