import {EMAIL_REGEX} from 'constants/validations';
import {every} from 'lodash';

export const splitEmails = (emails: string) =>
  (emails || '')
    .split(',')
    .map(recipient => recipient.trim())
    .filter(Boolean);

export const validateEmailAddress = (email: string) => EMAIL_REGEX.test(email);

export const validateEmails = (emails: string) =>
  !!emails &&
  every(splitEmails(emails), validateEmailAddress) &&
  (!RegExp(' ').test(emails) || RegExp(', ').test(emails));

export const containsInvalidEmail = (emails: string[]) =>
  emails.some(email => !validateEmailAddress(email));
