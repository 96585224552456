import React from 'react';
import bowser from 'bowser';
import * as breakpoints from 'utils/breakpoints';

/*
 Use <WindowSize onChange={...}/> to get notified on window resize events.
 Use getWindowSizeData() to get window size data for your initial render.
 */

// export type WindowSizeData = {
//   height: number,
//   isMobile: boolean,
//   isPhone: boolean,
//   isTablet: boolean,
//   width: number,
// };

export function getWindowSizeData() {
  const {innerHeight: height, innerWidth: width} = window;
  const desktop = breakpoints.desktop();
  const phonePortrait = breakpoints.phonePortrait();
  const isMobile = width < desktop;
  return {
    isMobile,
    isPhone: isMobile && width <= phonePortrait,
    isTablet: isMobile && width > phonePortrait,
    height,
    width,
  };
}

export class WindowSize extends React.Component<any, any> {
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    /**
     * There is a bug where Firefox is setting the offsetHeight too soon.
     * We need to add a time delay of 1.5s in order for the table height
     * to be rendered properly.
     */
    const timeoutLength = bowser.firefox ? 1500 : 0;
    this.timeoutId = setTimeout(this.handleResize, timeoutLength);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
    window.removeEventListener('resize', this.handleResize);
  }

  timeoutId;

  handleResize = () => {
    this.props.onChange(getWindowSizeData());
  }

  render() {
    return null;
  }
}
