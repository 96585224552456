import React from 'react';
import CrossMarkIcon from './CrossMarkIcon';

import styles from './User.css';
import { blueGrey200 } from 'utils/colors';


export default ({ user: { fullName, email } }) => (
  <div className={styles.details}>
    {fullName && <p>{fullName}</p>}
    {email && <span>{email}</span>}
    <div className={styles.removeText}>
      <CrossMarkIcon className={styles.removeIcon} color={blueGrey200} size={18} />
      <span>Remove</span>
    </div>
  </div>
);
