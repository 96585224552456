import React, { useState, useCallback } from 'react';
import { CustomPicker } from 'react-color';
import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common';
import style from './CanvasSketch.css';
import useOutsideClick from 'hooks/useOutsideClick';

const ColorPicker = ({ hex, hsl, hsv, onChange }) => {
  const [isPickerVisible, setIsPickerVisible] = useState<boolean>(false);
  const handleOutsideClick = useCallback(() => setIsPickerVisible(false), []);
  const handleClick = useCallback(() => setIsPickerVisible(true), []);
  const ref = useOutsideClick(handleOutsideClick);
  return (
    <div ref={ref} onClick={handleClick}>
      <div className={style.ColorPickerInput}>
        <EditableInput
          className={style.EditableInput}
          value={hex}
          onChange={onChange}
        />
      </div>
      { isPickerVisible && <div className={style.ColorPickerModal}>
        <div className={style.Hue}>
          <Hue hsl={hsl} onChange={onChange} />
        </div>
        <div className={style.Saturation}>
          <Saturation hsl={hsl} hsv={hsv} onChange={onChange} />
        </div>
      </div> }
    </div>
  );
};

// Use React.memo to prevent unnecessary re-renders
export default React.memo(CustomPicker(ColorPicker), (prevProps, nextProps) =>
  // Only re-render if the color has changed
  prevProps.hex === nextProps.hex && prevProps.hsl === nextProps.hsl && prevProps.hsv === nextProps.hsv
);
