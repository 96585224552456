import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Dictionary, memoize } from 'lodash';
import Icon from '@material-ui/core/Icon';
import { completeRegistration, resendRegistrationInvite } from 'actions/auth';
import { getFormPublicInfo } from 'actions/forms';
import AuthLayout from 'components/AuthLayout';
import { RegistrationHeader } from 'components/AuthLayout/AuthHeader';
import InvitationExpired from 'components/CoregistrationInvitation/InvitationExpired';
import RedirectOnLogin from 'containers/RedirectOnLogin';
import { loginInterstitialPath } from 'utils/routing';
import { isTokenExpired, removeNamespacesFromProfile, setCoregistrationToken } from 'utils/auth';
import jwtDecode from 'jwt-decode';
import authLayoutStyles from 'components/AuthLayout/styles.css';
import styles from 'components/Registration/styles.css';
import * as Colors from 'utils/colors';
import { redirectToLogin } from 'utils/routing';
import { getFormPublicInfoById } from '../../reducers';


const errorMessages = {
  USER_ALREADY_COMPLETED: 'USER_ALREADY_COMPLETED',
  USER_DOES_NOT_EXIST: 'USER_DOES_NOT_EXIST',
  JWT_TOKEN_ERROR: 'JWT_TOKEN_ERROR',
};

export class RegistrationCompletion extends React.Component<any, any> {
  componentDidMount() {
    if (!this.props.decodedToken) {
      redirectToLogin(this.props.router);
      return;
    }
    const { formId, maid } = this.props.decodedToken;
    this.props.getFormPublicInfo(formId, maid);
  }

  componentDidUpdate() {
    const {
      featureFlagLoaded,
      visitorRegistrationEnabled,
      emailVerified,
      emailVerificationFailed,
      isFetching,
      location,
      router,
    } = this.props;
    const jwt = location.query.t || '';

    if (featureFlagLoaded && !visitorRegistrationEnabled) {
      redirectToLogin(router);
      return;
    }
    if (!isFetching) {
      if (emailVerified || emailVerificationFailed === errorMessages.USER_ALREADY_COMPLETED) {
        this.timeoutId = setTimeout(() => {
          router.replace(this.constructLoginPath());
        }, 5000);
      } else if (!!emailVerificationFailed) {
        // all other error cases
        this.timeoutId = setTimeout(() => {
          redirectToLogin(router);
        }, 5000);
      } else if (!isTokenExpired(jwt) && !emailVerificationFailed) {
        const token = this.props.decodedToken || {};
        const { auid, maid } = token;
        this.props.completeRegistration(jwt, auid, maid);
      }
    }
  }

  componentWillUnmount() {
    this.timeoutId && clearTimeout(this.timeoutId);
  }

  timeoutId;

  constructLoginPath() {
    if (!this.props.decodedToken) return '';
    const { formId, maid, liveViewUrl } = this.props.decodedToken;
    return loginInterstitialPath(`?form_id=${formId}&maid=${maid}&redirect_url=${liveViewUrl}`);
  }

  loginLink() {
    return <Link to={this.constructLoginPath()}>log in</Link>;
  }

  errorText() {
    const { USER_ALREADY_COMPLETED, USER_DOES_NOT_EXIST, JWT_TOKEN_ERROR } = errorMessages;
    switch (this.props.emailVerificationFailed) {
      case USER_ALREADY_COMPLETED:
        return 'You have already completed your registration.';
      case USER_DOES_NOT_EXIST:
      case JWT_TOKEN_ERROR:
        return 'We were unable to complete registration for this email address.';
      default:
        return '';
    }
  }

  _render() {
    const { emailVerified, isFetching, location } = this.props;
    const rawJwt = location.query.t || '';
    const token = this.props.decodedToken;
    if (!token) {
      return null;
    } else if (isTokenExpired(rawJwt) || isFetching) {
      const { emal: email, maid } = token;
      const header = <RegistrationHeader />;
      const mainComponent = (
        <InvitationExpired email={email} maid={maid} resendInvite={this.props.resendRegistrationInvite} />
      );
      return <AuthLayout fetching={isFetching} header={header} mainComponent={mainComponent} sidebar={null} />;
    }

    let completionText;
    let completionHeader;
    let completionIcon;
    if (emailVerified) {
      completionHeader = 'Thank you';
      completionText = (
        <span>Your email has been verified. You will now be asked to {this.loginLink()} to view your form.</span>
      );
      completionIcon = 'check_circle_outline';
    } else {
      completionHeader = 'Something went wrong';
      completionText = <span>{this.errorText()} Please try logging in. You will be redirected shortly.</span>;
      completionIcon = 'error_outline';
    }
    return (
      <div className={authLayoutStyles.pageWrapper}>
        <div className={authLayoutStyles.mainContent}>
          <Icon style={{ fontSize: '77px', color: Colors.buttonBlueBackground }}>{completionIcon}</Icon>
          <h1 className={styles.thankYouHeader}>{completionHeader}</h1>
          <p className={styles.thankYouText}>{completionText}</p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <RedirectOnLogin location={this.props.location} />
        {this._render()}
      </React.Fragment>
    );
  }
}

const decodeRegistrationToken = memoize(token => {
  setCoregistrationToken(token);
  return removeNamespacesFromProfile(jwtDecode(token));
});

const mapStateToProps = (state, { location }) => {
  const { auth } = state;
  let decodedToken: Dictionary<any> | null = null;
  let featureFlagLoaded = false;
  let visitorRegistrationEnabled = false;

  if (location.query.t) {
    decodedToken = decodeRegistrationToken(location.query.t);
    const form = getFormPublicInfoById(state, decodedToken.formId);
    if (form) {
      featureFlagLoaded = true;
      visitorRegistrationEnabled = form.publicFormSettings.allowVisitorRegistration;
    }
  }

  return {
    decodedToken,
    emailVerified: auth.registrationSuccess,
    emailVerificationFailed: auth.registrationError,
    isFetching: auth.isFetching || !featureFlagLoaded,
    featureFlagLoaded,
    visitorRegistrationEnabled,
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ completeRegistration, getFormPublicInfo, resendRegistrationInvite }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationCompletion);
