import * as types from 'constants/types/authActionTypes';
import {getOrganizationInfo} from 'actions/auth';
import {getSubdomain, getOrganizationBrand} from '../reducers';


export default function organizationInfoEpic(action$, store) {
  return action$
    .ofType(types.AUTHORIZATION_CHECK_SUCCESS)
    .filter(() => {
      const state = store.getState();
      return !getOrganizationBrand(state).name;
    })
    .map(() => {
      const state = store.getState();
      return getOrganizationInfo(
        getSubdomain(state),
        store.dispatch
      );
    });
}
