import React from 'react';
// import { generateRandomTooltipId } from 'utils/assignees';
import { joinClassNames } from 'utils/strings';
import styles from './tag.css';
import CrossIcon from './CrossIcon';
import Tooltip from 'components/Tooltip';


class Tag extends React.Component<any, any> {
  static defaultProps = {
    inPopover: false,
    isMultiplePicked: false,
    isReadOnly: false,
    onKeyDown: () => { },
    onRemove: () => { },
    onSelect: () => { },
    selected: false,
    isSelectedByKeyboard: false,
    tabIndex: '',
  };

  renderTagName() {
    const { inPopover, selectionData: tag } = this.props;
    const tagNameElement = (
      <p className={styles.tagName}>
        {tag.name}
      </p>
    );
    return inPopover ?
      tagNameElement :
      <Tooltip className={styles.tooltip} title={tag.name}>
        {tagNameElement}
      </Tooltip>;
  }

  handleOnSelect = e => {
    e.stopPropagation();
    const { selectionData, isReadOnly, onSelect } = this.props;
    if (isReadOnly) return;
    onSelect(selectionData);
  };

  handleOnRemove = () => {
    const { selectionData, isReadOnly, onRemove } = this.props;
    if (isReadOnly) return;
    onRemove(selectionData);
  };

  render() {
    const {
      selectionData: tag,
      isReadOnly,
      selected,
      isSelectedByKeyboard,
      inPopover,
      isMultiplePicked,
      onKeyDown,
      tabIndex,
    } = this.props;
    // let tooltipId;
    // if (!inPopover) tooltipId = generateRandomTooltipId(tag.name);
    const showCross = !isReadOnly && selected;
    return (
      <div className={styles.container}>
        <div
          onClick={this.handleOnSelect}
          className={
            joinClassNames(
              styles.body,
              inPopover ? styles.inPopover : '',
              isReadOnly ? styles.readonly : '',
              selected ? styles.selected : '',
              isMultiplePicked ? styles.isMultiplePicked : '',
              isSelectedByKeyboard ? styles.isHover : ''
            )
          }
          aria-label={tag.name}
          tabIndex={tabIndex}
          onKeyDown={onKeyDown}>
          {this.renderTagName()}
          {showCross &&
            <CrossIcon
              onClick={this.handleOnRemove}
              className={styles.crossmark} />
          }
        </div>
      </div>
    );
  }
}

export default Tag;
