import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { values } from 'lodash';
import styles from './Stage.css';
import StageInput from 'components/Stages/StageInput';
import { ensureValidColorNumber } from 'utils/stageColors';
import { ALL_VIRTUAL_STAGE_NAME } from 'constants/stages';
import { ENTER_KEY, SPACE_KEY, ESC_KEY, TAB_KEY, _getKeyboardCode } from 'utils/keyboard';
import { joinClassNames } from 'utils/strings';
import * as stagesActions from 'actions/stages';
import addIcon from 'icons/stages/addSidebar.svg';
import crossIcon from 'icons/stages/cross.svg';

class CreateStage extends React.PureComponent<any, any> {
  static defaultProps = {
    inDropdown: false,
    onCreate: () => { },
    onStartCreation: () => { },
    isFocused: false,
  }
  state = { isEditing: false, errorMessage: '', inputValue: '' }

  componentDidMount() {
    window.addEventListener('click', this.handleOnClickOut);
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.handleOnClickOut);
  }

  inputRef = React.createRef();

  createStage = () => {
    const { props: { stages, formId, actions, totalSubmissions, deletedCount }, state: { inputValue } } = this;
    const value = inputValue.trim();
    if (!value) {
      this.setState({ errorMessage: inputValue ? 'Invalid stage name.' : 'Give the stage a name.' });
      return;
    }
    const names = [ALL_VIRTUAL_STAGE_NAME, ...stages.map(({ name }) => name)];
    const alreadyExists = names.some(name => name.toLowerCase() === value.toLowerCase());
    if (alreadyExists) {
      this.setState({ errorMessage: 'Name already exists.' });
      return;
    }
    const newStage = {
      color: ensureValidColorNumber(Math.floor(Math.random() * 12) + 1),
      name: value,
    };
    const submissionsData = {
      totalSubmissions,
      deletedCount,
    };
    actions.createStage(formId, [newStage], () => {
      this.props.onCreate(newStage);
    }, submissionsData);
    this.setState({ isEditing: false, inputValue: '' });
  }

  onChange = ({ target: { value } }) => {
    this.setState({ inputValue: value, errorMessage: '' });
  }

  handleOnKeyUp = e => {
    const key = _getKeyboardCode(e);
    switch (key) {
      case ENTER_KEY:
      case SPACE_KEY:
        if (this.state.isEditing) break;
        this.handleToggleEdit();
        break;
      default:
        return;
    }
  }

  handleOnKeyDown = e => {
    const key = _getKeyboardCode(e);
    switch (key) {
      case ESC_KEY:
        if (!this.state.isEditing) break;
        this.handleToggleEdit();
        break;
      case TAB_KEY:
        if (!this.state.isEditing) break;
        this.createStage();
        break;
      default:
        return;
    }
  }

  handleToggleEdit = (e?: MouseEvent) => {
    if (e && e.target === this.inputRef.current) return;
    this.props.onStartCreation();
    if (e) e.stopPropagation();
    this.setState(({ isEditing }) => ({ isEditing: !isEditing, inputValue: '', errorMessage: '' }));
  }

  handleOnClickOut = ({ target }) => {
    if (!this.state.isEditing || target === this.inputRef.current) return;
    this.createStage();
    this.setState({ isEditing: false, inputValue: '', errorMessage: '' });
  }

  render() {
    const { props: { sidebarExpanded, inDropdown, isFocused }, state: { isEditing, inputValue, errorMessage } } = this;
    const createStagesContainerClass = joinClassNames(
      styles.createStageContainer,
      isEditing ? styles.isEditing : '',
      inDropdown ? styles.inDropdown : '',
      isFocused ? styles.focused : ''
    );
    return (
      <div
        className={createStagesContainerClass}
        onClick={this.handleToggleEdit as any}
        onKeyUp={this.handleOnKeyUp}
        onKeyDown={this.handleOnKeyDown}
        tabIndex={0}>
        <img
          className={styles.icon}
          onClick={this.handleToggleEdit as any}
          src={isEditing ? crossIcon : addIcon}
          alt={isEditing ? 'Cancel creating stage' : 'Create new stage'} />
        {((sidebarExpanded || inDropdown) && !isEditing) && <span>Create Stage</span>}
        {isEditing && <StageInput
          errorMessage={errorMessage}
          legend={'Press enter to add'}
          value={inputValue}
          inputRef={this.inputRef}
          onSubmit={this.createStage}
          onChange={this.onChange} />}
      </div>
    );
  }
}

const mapStateToProps = ({ stages, submissionTable: { formId, totalSubmissions, deletedCount } }) => {
  let arrayFromStages: any[] = [];
  if (formId) {
    arrayFromStages = values(stages[formId]);
  }
  return { formId, totalSubmissions, deletedCount, stages: arrayFromStages };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(stagesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateStage);
