import React, { memo, MouseEventHandler, lazy, Suspense, FC, useState } from 'react';
import WithFocusTrap from '../WithFocustrap';
import {VERIFICATION_ID_ENV} from '../../../env';

const PersonaReact = lazy(() => import('persona-react'));

type ModalContentProps = {
  onComplete: () => void;
  closeModalHandler: MouseEventHandler<HTMLButtonElement>;
  templateId: string;
  referenceId: string;
  open: boolean;
};


const ModalContent: FC<ModalContentProps> = memo(({
  closeModalHandler,
  templateId,
  referenceId,
  onComplete,
  open,
}) => {
  const [isPersonaComponentLoaded, setIsPersonaComponentLoaded] = useState<boolean>(false);
  return (
    <WithFocusTrap isModalContent isContentLoaded={isPersonaComponentLoaded} closeModal={closeModalHandler} open={open}>
      <Suspense fallback={<div>Loading...</div>}>
        <PersonaReact
          templateId={templateId}
          environmentId={VERIFICATION_ID_ENV}
          onReady={() => {
            setIsPersonaComponentLoaded(true);
          }}
          onComplete={onComplete}
          referenceId={referenceId}
        />
      </Suspense>
    </WithFocusTrap>);
});

export default ModalContent;
