import React from 'react';
import moment from 'moment-timezone';
import SubmitButton from 'components/AuthLayout/SubmitButton';
import ErrorMessage from 'components/AuthLayout/ErrorMessage';
import ShowPasswordField from 'components/Login/ShowPasswordField';
import TimezoneSelect from 'components/TimezoneSelect';
import styles from './styles.css';
import authLayoutStyles from 'components/AuthLayout/styles.css';


export default class Registration extends React.Component<any, any> {
  static defaultProps = {
    processingSubmission: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      email: props.email || '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirm: '',
      phoneNumber: '',
      timezone: moment.tz.guess(),
    };
  }

  componentDidMount() {
    this.nameInput && this.nameInput.focus();
  }

  cannotSubmit() {
    return this.props.processingSubmission || !(
      this.state.email &&
      this.state.firstName &&
      this.state.lastName &&
      this.state.password &&
      this.state.passwordConfirm
    );
  }

  emailField() {
    if (this.props.email) {
      return (
        <input
          id='emailInput'
          className={styles.disabledEmailField}
          disabled
          name='email'
          type='email'
          value={this.props.email} />
      );
    }
    return (
      <input
        id='emailInput'
        className={authLayoutStyles.field}
        onChange={this.updateInfo}
        name='email'
        placeholder='Email Address'
        type='email'
        value={this.state.email} />
    );
  }

  updateInfo = e => {
    const element = e.target;
    this.setState({ [element.name]: element.value });
  };

  nameInput

  handleSubmit = e => {
    e.preventDefault();
    if (this.cannotSubmit()) return;
    this.props.onSubmit(this.state);
  }

  render() {
    const bannerCopy = 'After clicking Register you will be prompted to sign in using your new password. This will enable you to access and collaborate on all of your forms and submissions.';
    return (
      <form className={styles.registrationForm} onSubmit={this.handleSubmit}>
        <div className={styles.nameFields}>
          <label htmlFor='firstNameInput' className={authLayoutStyles.label}>
            First Name*
            <input ref={input => { this.nameInput = input; }} id='firstNameInput' className={authLayoutStyles.field} name='firstName' onChange={this.updateInfo} placeholder='First Name' type='text' />
          </label>
          <label htmlFor='lastNameInput' className={authLayoutStyles.label}>
            Last Name*
            <input id='lastNameInput' className={authLayoutStyles.field} name='lastName' onChange={this.updateInfo} placeholder='Last Name' type='text' />
          </label>
        </div>
        <div className={styles.emailPasswordFields}>
          <label htmlFor='emailInput' className={authLayoutStyles.label}>
            Email Address*
            {this.emailField()}
          </label>
          <label htmlFor='passwordInput' className={authLayoutStyles.label}>
            Password*
            <ShowPasswordField inputClassName={authLayoutStyles.field} name='password' onChange={this.updateInfo} placeholder='Password' />
          </label>
          <label htmlFor='passwordConfirm' className={authLayoutStyles.label}>
            Confirm Password*
            <ShowPasswordField id='passwordConfirm' inputClassName={authLayoutStyles.field} name='passwordConfirm' onChange={this.updateInfo} placeholder='Confirm' />
          </label>
        </div>
        <div className={styles.nameFields}>
          <label htmlFor='phoneNumberInput' className={authLayoutStyles.label}>
            Phone Number
            <input
              id='phoneNumberInput'
              className={authLayoutStyles.field}
              name='phoneNumber'
              onChange={this.updateInfo}
              placeholder='Phone Number (optional)'
              type='text'
              value={this.state.phoneNumber} />
          </label>
          <label className={authLayoutStyles.label}>
            Time Zone
            <TimezoneSelect className={authLayoutStyles.field} name='timezone' onChange={this.updateInfo} value={this.state.timezone} />
          </label>
        </div>
        {this.props.errors && <ErrorMessage message={this.props.errors} />}
        <p className={styles.banner}>{bannerCopy}</p>
        <SubmitButton disabled={this.cannotSubmit()} label={this.props.processingSubmission ? 'Registering...' : 'Register now'} />
      </form>
    );
  }
}
