import {colorStyleMap} from 'utils/richTextEditor/colorsForPicker';
import bowser from 'bowser';

/*
 * IE has this concept of "layout" that it applies to elements with certain properties, such as
 * `display: inline-block;`.
 * This isn't normally an issue, except that IE spazzes out when an element with "layout" appears
 * inside a contenteditable element.
 * "layout" cannot be removed from an element with `display: inline-block;`
 * The normal alternative to `display: inline-block;`, `float: <left|right>;` isn't suitable here
 * because it won't play nice with our text alignment functionality.
 *
 * The best we can do here is abandon `display: inline-block;` and everything it enables (currently
 * limited to vertical margins around headers) in the rich text editor in IE.
 *
 * Example of spastic behavior:
 * - http://ckeditor.com/forums/CKEditor/contenteditable-and-Internet-Explorer-problem-with-hasLayout
 * More detail on "layout":
 * - http://www.satzansatz.de/cssd/onhavinglayout.html
 */
const display = bowser.msie ? 'inline' : 'inline-block';

export const headerStyleMap = {
  H1: {
    fontSize: '38px',
    lineHeight: '38px',
    display,
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },
  H2: {
    fontSize: '32px',
    lineHeight: '32px',
    display,
    marginTop: '0.4em',
    marginBottom: '0.4em',
  },
  H3: {
    fontSize: '24px',
    lineHeight: '24px',
    display,
    marginTop: '0.3em',
    marginBottom: '0.3em',
  },
  H4: {
    fontSize: '18px',
    lineHeight: '18px',
    display,
    marginTop: '0.2em',
    marginBottom: '0.2em',
  },
  H5: {
    fontSize: '14px',
    lineHeight: '14px',
    display,
    marginTop: '0.1em',
    marginBottom: '0.1em',
  },
  H6: {
    fontSize: '12px',
    lineHeight: '12px',
    display,
    marginTop: '0',
    marginBottom: '0',
  },
};

export default {
  headerStyleMap,
  colorStyleMap,
  combinedStyleMap: {
    ...headerStyleMap,
    ...colorStyleMap,
  },
};
