import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { deleteStage } from 'actions/stages';
import styles from './Stage.css';
import binIcon from 'icons/stages/binSidebar.svg';

class DeleteStage extends React.PureComponent<any, any> {
  handleOnDelete = e => {
    e.stopPropagation();
    this.props.deleteStage(this.props.formId, this.props.stageName);
  }

  render() {
    return (
      <Fragment>
        <img className={styles.binIcon} onClick={this.props.toggleModalState} src={binIcon} alt='Delete stage' />
        <ConfirmationModal
          onClose={this.props.toggleModalState}
          primaryButton={{
            onClick: this.handleOnDelete,
            text: 'Proceed',
          }}
          open={this.props.showConfirmationDeleteModal}>
          Deleting this stage will also remove any submissions from it. Are you sure you want to delete this stage?
        </ConfirmationModal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ submissionTable: { formId } }) => ({ formId });


export default connect(mapStateToProps, { deleteStage })(DeleteStage);
