import Stage from 'components/Stages/Stage';
import RemoveButton from 'components/Stages/StageSelector/RemoveButton';
import CreateStageButton from 'components/Stages/StageSelector/CreateStageButton';


export const getStageItem = (
  data: any,
  onClick: Function,
  props: Object
) => ({
  Component: Stage,
  props: {
    data,
    onClick,
    ...props,
  },
});


export const getRemoveButtonItem = (onClick: Function) => ({
  Component: RemoveButton,
  props: {
    data: undefined,
    onClick,
  },
});


export const getCreateStageButtonItem = (onSelectStage: Function) => ({
  Component: CreateStageButton,
  props: {
    data: undefined,
    onClick: () => { },
    onCreate: onSelectStage,
  },
});
