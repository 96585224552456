import React, { useState, useEffect, useMemo, forwardRef } from 'react';
import style from './Emoticon.css';
import { EMOTICON_DEFAULT_VALUES, EMOTICON_ITEMS } from 'constants/liveViewPage';
import classNames from 'classnames';
import sharedStyle from '../shared.css';

export type Props = {
  id: string,
  label?: string,
  onChange?: Function,
  updateForm?: Function,
  extraData?: any,
  fieldState?: any,
  specialSettings?: {
    label: string,
    isLabelVisible: boolean,
    labelAlignment: 'right' | 'auto' | 'top',
  }
}

const Emoticon = ({
  id,
  label = EMOTICON_DEFAULT_VALUES.label,
  fieldState,
  onChange = undefined,
  updateForm = undefined,
  specialSettings = {
    label: EMOTICON_DEFAULT_VALUES.label,
    isLabelVisible: true,
    labelAlignment: 'auto',
  },
}: Props) => {
  const [value, setValue] = useState<string | null>(fieldState?.fields[label] || null);
  const isLabelVisible: boolean = specialSettings ? specialSettings.isLabelVisible : true;
  const labelAlignment: string = specialSettings ? specialSettings.labelAlignment : 'auto';

  useEffect(() => {
    onChange && onChange(value);
    const s = {
      fields: {
        [label]: value,
      },
    };
    updateForm && updateForm(s);
  }, [value]);

  const alignment: string = useMemo(() => {
    switch (labelAlignment) {
      case 'right':
        return sharedStyle.labelAlignRight;
      case 'top':
      default:
        return sharedStyle.labelAlignLeft;
    }
  }, []);

  const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  return (
    <div id={`container_${id}`} className={'form_control_group'}>
      <fieldset className={classNames(sharedStyle.Fieldset, 'choice_group', style.EmoticonsWrapper)} id={`emoticons_${id}`}>
        {isLabelVisible && <legend className={classNames('choice_group_label', alignment)}>{label}</legend>}
        {EMOTICON_ITEMS.map((item, index) =>
          <div key={index} className={style.Emoticon}>
            <label htmlFor={`${id}_${index}`} title={item.title} className={style.Emoji}>
              {item.iconCode}
            </label>
            <input
              name={id}
              id={`${id}_${index}`}
              type='radio'
              value={item.value}
              onChange={onOptionChange}
              checked={value === item.value}
            />
          </div>
        )}
      </fieldset>
    </div>
  );
};

export default React.memo(forwardRef(Emoticon));
