// import typeof {LOG_SUBMISSION_NAVIGATION} from 'constants/types/routingActionTypes';
export const FROM_TOOLBAR = 'Toolbar';
export const FROM_CELL = 'Submission Number';
// export type SubmissionNavigationSource = typeof FROM_TOOLBAR | typeof FROM_CELL;

// export type LogSubmissionNavigationAction = {
//   source: SubmissionNavigationSource,
//   type: LOG_SUBMISSION_NAVIGATION,
// };

// export type LogSubmissionNavigationActionCreator =
//   (source: SubmissionNavigationSource) => LogSubmissionNavigationAction;

export type FormType = 'Webform' | 'Wizard' | 'Doc' | undefined;

export interface Form {
  itemType: FormType;
  formId?: string; // Only Webform will need a formId
  alias: string; // Wizard and Doc will use alias
}

export type getLiveViewUrlFormType = {
  isNgLiveViewExcluded?: boolean,
  isNgLiveViewIncompatible?: boolean,
  itemType: FormType,
  alias: string
}
