const inlineStyles = {
  centeredOverride: {
    margin: '0 auto',
    width: 'auto',
  },
  iconStyle: {
    marginRight: '0',
  },
  buttonStyle: {
    padding: '5px',
    height: 'auto',
    width: 'auto',
  },
  clearIconStyle: {
    height: '12px',
    width: '12px',
  },
};
export default inlineStyles;
