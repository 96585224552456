import React from 'react';
import injectPermissions from 'decorators/injectPermissions';
import styles from './SubmissionDetails.css';

export const SignatureBoxItem = ({
  canUserReadSubmissionDetails,
  declined,
  declinedReason,
  email,
  fullname,
  handleReNotify,
  noSignatureDivider,
  onEditSignerModalToggle,
  onSignNowClicked,
  onSignerDetailsToggle,
  signature,
  signatureNumber,
  signeeType,
  signerKey,
  status,
  timeAgo,
  isRenotifyEnable,
}) => {
  let signatureStatus;
  let isShowingSignature;

  const handleSignNow = event => {
    event.preventDefault();
    event.stopPropagation();
    onSignNowClicked && onSignNowClicked({ signerKey, signature, signeeType, signatureNumber, email, fullname });
  };

  switch (status && status.toLowerCase()) {
    case 'declined':
      signatureStatus = <p className={styles.signatureDeclined}>Declined to Sign</p>;
      isShowingSignature = false;
      break;
    case 'sent':
      signatureStatus = <p className={styles.signatureNotified}>Notified</p>;
      isShowingSignature = false;
      break;
    case 'opened':
      signatureStatus = <p className={styles.signatureOpened}>Opened</p>;
      isShowingSignature = false;
      break;
    case 'signed':
    case 'approved':
      signatureStatus = <p className={styles.signatureSigned}>Signed</p>;
      isShowingSignature = true;
      break;
    case 'prepared':
      signatureStatus = null;
      isShowingSignature = true;
      break;
    case 'skipped':
      signatureStatus = <p className={styles.signatureSkipped}>Conditionally Skipped</p>;
      isShowingSignature = false;
      break;
    default:
      signatureStatus = declined ? null : <p className={styles.signaturePending}>Pending</p>;
      isShowingSignature = false;
      break;
  }

  return (
    <div>
      <div className={styles.signatureNumberSize}>
        {signatureNumber && <p className={styles.signatureNumber}>{signatureNumber}</p>}
      </div>
      <div className={styles.boxListContent}>
        <div className={styles.signatureWrap}>
          <p className={styles.signatureType}>{signeeType}</p>
          <p className={styles.signatureName}>{decodeURIComponent(fullname)}</p>
          <p className={styles.signatureTimeAgo}>{timeAgo}</p>
        </div>
        <div>
          {email && <p className={styles.signatureEmail}>{decodeURIComponent(email)}</p>}
          {signatureStatus}
          {declinedReason && <p className={styles.declineReason}>{decodeURIComponent(declinedReason)}</p>}
        </div>
      </div>
      {canUserReadSubmissionDetails() &&
        isRenotifyEnable &&
        onEditSignerModalToggle &&
        signerKey &&
        handleReNotify &&
        (<>
          <div className={styles.signatureLinkBox}>
            <p className={styles.signatureLink} onClick={onEditSignerModalToggle}>Invite signer</p>
          </div>
          <div className={styles.signatureLinkBox}>
            <p className={styles.signatureLink} onClick={handleSignNow}>Sign Now</p>
          </div>
        </>)}

      {isShowingSignature && (
        <div className={styles.signatureLinkBox}>
          <img
            alt={`${fullname}'s signature`}
            className={`${styles.signatureImg} ${!noSignatureDivider ? styles.signatureImgWithDetails : ''}`}
            src={decodeURIComponent(signature)}
          />
          {onSignerDetailsToggle && (
            <p className={styles.signatureViewImg} onClick={onSignerDetailsToggle}>
              View Details
            </p>
          )}
        </div>
      )}
    </div>
  );
};

SignatureBoxItem.defaultProps = {
  isRenotifyEnable: false,
};

export default injectPermissions(SignatureBoxItem);
