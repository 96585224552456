import React from 'react';
import EmptyState from './EmptyState';
import Loader from 'components/Loader';


class ResultList extends React.Component<any, any> {
  render() {
    const { inQueue, isLoading, selectedItemIndex, onAddSelection, results, renderSelection } = this.props;
    if (isLoading) return <Loader />;
    if (!results.length) return <EmptyState />;
    return results.map((selectionData, index) =>
      renderSelection({
        selectionData,
        key: index,
        onSelect: onAddSelection,
        inPopover: true,
        isSelectedByKeyboard: selectedItemIndex === index,
        isMultiplePicked: inQueue(selectionData),
      })
    );
  }
}

export default ResultList;
