import React from 'react';
import Tooltip from 'components/Tooltip';
import ClearIcon from '@material-ui/icons/ClearRounded';
import styles from './Item.css';

interface Props {
  caption?: string,
  className: string,
  onClick?: React.MouseEventHandler
  tooltipId?: string,
}

const CrossIcon = ({className, onClick, caption = 'Click to remove'}: Props) => (
  <Tooltip title={caption} className={styles.tooltip}>
    <span className={className} onClick={onClick} aria-label={caption}>
      <ClearIcon style={{fontSize: '13px'}}/>
    </span>
  </Tooltip>
);

export default CrossIcon;
