// 1) The popover body starts from anchorOrigin, so if we set `popoverOrigin` to {vertical: 'top'}
// we want the anchorOrigin to be bottom (bottom of the trigger element) so the body starts from there
// 2) horizontalOffset should override the horizontal property on popoverOrigin, both cannot coexist
export const getAnchorPosition = (
  popoverOrigin: { horizontal: string; vertical: string; },
  horizontalOffset?: number | undefined
) => ({
  horizontal: horizontalOffset || popoverOrigin && popoverOrigin.horizontal || 'left',
  vertical: !popoverOrigin || popoverOrigin.vertical === 'top' ? 'bottom' : 'top',
});
