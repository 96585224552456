import {saveTableSortOrder} from 'actions/submissionsManager/tableApi';
import {SORT_COLUMNS} from 'constants/types/submissionsTableActionTypes';
import {
  getSortingFromServer,
  getSubmissionManagerFormId,
} from '../reducers';
import {mergeVisibleColumnsWithHidden} from 'utils/submissionsManager/tableHelper';


export default function columnSortingEpic(
  action$,
  store,
) {
  return action$
    .ofType(SORT_COLUMNS)
    .map(({columnOrder}) => {
      const state = store.getState();
      const formId = getSubmissionManagerFormId(state);
      const oldServerColumnOrder = getSortingFromServer(state);
      const newColumnsForServer = mergeVisibleColumnsWithHidden(oldServerColumnOrder, columnOrder);
      return saveTableSortOrder(
        formId,
        oldServerColumnOrder,
        newColumnsForServer,
      );
    });
}
