import React from 'react';
import SelectionOverflow from './SelectionOverflow';
import PopoverTypeahead from 'components/Typeaheads/PopoverTypeahead';
import { joinClassNames } from 'utils/strings';
import styles from './MultiSelector.css';


class MultiSelector extends React.Component<any, any> {
  static defaultProps = {
    avoidForcedPopoverReposition: false,
    hidePeak: false,
    isLoading: false,
    isReadOnly: false,
    maxElements: 3,
    overflowButtonCountsAsElement: true,
    selections: [],
  };

  onClick = event => event.stopPropagation();

  // stop wheel and touch propagation so fixed-data-table doesn't scroll
  // https://github.com/schrodinger/fixed-data-table-2/issues/242
  handleScroll = event => event.stopPropagation();

  render() {
    const {
      avoidForcedPopoverReposition,
      ShowTypeaheadButton,
      hidePeak,
      isLoading,
      isReadOnly,
      maxElements,
      onAddSelections,
      onInputTypeahead,
      onRemoveSelection,
      overflowButtonCountsAsElement,
      popoverOrigin,
      renderSelection,
      resultsListClassName,
      selections,
      ShowOverflowButton,
      TypeaheadInput,
      typeaheadOffset,
      typeaheadResults,
      renderFooter,
    } = this.props;

    let isThereOverflow;
    let limit;

    if (maxElements > 0) {
      isThereOverflow = selections.length > maxElements;
      limit = Math.max(0, isThereOverflow && overflowButtonCountsAsElement ? maxElements - 1 : maxElements);
    }
    const visibleSelections = selections.slice(0, limit);
    const hiddenSelections = selections.slice(limit);
    return (
      <div onClick={this.onClick} onTouchMove={this.handleScroll} onWheel={this.handleScroll} className={joinClassNames(styles.container, !maxElements ? styles.wrapItems : '')}>
        {
          visibleSelections.map((selectionData, index) =>
            renderSelection({
              selectionData,
              key: index,
              onRemove: onRemoveSelection,
              isReadOnly,
              selected: true,
            })
          )}
        {isThereOverflow && (
          <SelectionOverflow
            ShowOverflowButton={ShowOverflowButton}
            resultsListClassName={resultsListClassName}
            renderSelection={renderSelection}
            selections={hiddenSelections}
            onRemove={onRemoveSelection}
            popoverOrigin={popoverOrigin}
            isReadOnly={isReadOnly} />
        )}
        {!isReadOnly && (!!TypeaheadInput || !!typeaheadResults.length) && (
          <PopoverTypeahead
            avoidForcedReposition={avoidForcedPopoverReposition}
            horizontalOffset={typeaheadOffset}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
            onAddSelections={onAddSelections}
            onInputTypeahead={onInputTypeahead}
            peak={hidePeak ? null : <div className={styles.peak} />}
            popoverOrigin={popoverOrigin}
            renderSelection={renderSelection}
            ShowTypeaheadButton={ShowTypeaheadButton}
            TypeaheadInput={TypeaheadInput}
            renderFooter={renderFooter}
            resultsListClassName={resultsListClassName}
            typeaheadResults={typeaheadResults} />
        )}
      </div>
    );
  }
}

export default MultiSelector;
