import React from 'react';
import className from 'classnames';
import { formatPaymentAmount } from 'utils/strings';
import styles from './styles.css';
import { GovOSPaymentType, PaymentType } from 'types/submissionsManager/tableTypes';
import { getPaymentData } from 'utils/submissionsManager/payment';

type Props = {
  isStatus?: boolean,
  maxWidth?: string,
  payment: PaymentType[] | GovOSPaymentType[],
  source: string,
};

export default class PaymentsCell extends React.PureComponent<Props> {
  static defaultProps = {
    maxWidth: '160px',
    isStatus: false,
  };

  render() {
    const { payment, isStatus } = this.props;

    const { isActive, amount, status } = getPaymentData(payment);

    if (!isActive) {
      return null;
    }

    return (
      <div className={styles.wrapper}>
        {isStatus ? (
          <div className={className(styles.status, styles[status])}>{status}</div>
        ) :
          (<div className={styles.amount}>{formatPaymentAmount(amount)}</div>)
        }
      </div>
    );
  }
}
