import React from 'react';
import {partial} from 'lodash';
import AccordionItem from 'components/Accordion/Item';
import styles from './QuickFilters.css';
import {joinClassNames} from 'utils/strings';
import chevronIcon from 'icons/icon79.svg';
import filters from 'icons/lobby/filters.svg';

const renderHeader = (isOpen: boolean) => {
  const altText = isOpen ? 'open' : 'closed';
  const classNames = joinClassNames(styles.chevron, isOpen ? styles.isOpen : '');
  return (
    <div className={joinClassNames(styles.quickFilterAppearance, styles.quickFilterHeaderLayout)}>
      <img alt={altText} className={classNames} src={chevronIcon} />
      <img
        alt=''
        className={joinClassNames(styles.quickFilterIconLayout, styles.quickFilterIconAppearance)}
        src={filters}
      />
      <span>My Submissions</span>
    </div>
  );
};

interface QFProps {
  isSelected: boolean;
  onClick: any;
  src: any;
  text: any;
}
const QuickFilter = (props: QFProps) => {
  const {isSelected, onClick, src, text} = props;
  return (
    <button
      className={joinClassNames(
        styles.quickFilterAppearance,
        styles.quickFilterLayout,
        isSelected ? styles.isSelected : ''
      )}
      onClick={partial(onClick, text)}
    >
      <img
        alt=''
        className={joinClassNames(styles.quickFilterIconAppearance, styles.quickFilterIconLayout)}
        src={src}
      />
      {text}
    </button>
  );
};

interface QFSProps {
  availableQuickFilters: any[];
  selectedFilter: any;
  onSelect: () => void;
  selectQuickFilter: any;
  clearSearchBar: () => void;
}
interface QFSState {
  isOpen: boolean;
}
class QuickFilters extends React.PureComponent<QFSProps, QFSState> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  renderContent = () => (
    <div className={styles.quickFiltersContentLayout}>
      {this.props.availableQuickFilters.map(({src, text}) => (
        <QuickFilter
          key={text}
          isSelected={text === this.props.selectedFilter}
          onClick={this.handleQuickFilterSelect}
          src={src}
          text={text}
        />
      ))}
    </div>
  );

  handleQuickFilterSelect = (selectedFilterName: string) => {
    const {onSelect, selectQuickFilter, clearSearchBar} = this.props;
    const shouldLogAnalytics = true;
    clearSearchBar();
    selectQuickFilter(selectedFilterName, shouldLogAnalytics);
    onSelect();
  };

  handleToggleVisibility = () => {
    this.setState(state => ({isOpen: !state.isOpen}));
  };

  render() {
    return (
      <div className={styles.quickFiltersLayout}>
        <AccordionItem isOpen={this.state.isOpen} onToggle={this.handleToggleVisibility} renderHeader={renderHeader}>
          {this.renderContent()}
        </AccordionItem>
      </div>
    );
  }
}

export default QuickFilters;
