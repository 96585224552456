import React from 'react';
import Button from 'components/Button';

export default props => {
  const actionStyle = { marginRight: '8px', marginLeft: '8px', textTransform: 'capitalize' };
  const { style, ...otherProps } = props;
  return (
    <Button
      style={{ ...actionStyle, ...style }}
      {...otherProps} />
  );
};
