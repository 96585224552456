import React, {useContext, useMemo, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import NavBarBase from '@seamlessdocs/nav-bar';
import store from './store';
import {generateBreadcrumbs} from './utils';
import {FILEPICKER_KEY} from '../../env';
import {LOBBY_ROOT_FOLDER, LOBBY_ARCHIVED_FOLDER} from 'constants/tableConsts';
import {Dispatch} from 'redux';
import {getUserProfile} from 'actions/auth';
import {getOrganizationBrand, getUserCapabilitiesLoadingState} from 'reducers/index';
import {parsedToString} from 'utils/search';
import {OneNavBarSelectorProps, PropsFromMapDispatchToProps, PropsFromMapToState} from 'components/OneNavBar/types';

function NavBarContainer({
  currentNode,
  currentForm,
  accountCapabilities,
  dispatch,
  account,
  features,
  accountDomains,
  search: { value: searchValue },
  user,
  internalUser,
  isAuthenticated,
  userCapabilitiesLoading,
  fetchUserProfile,
  router,
}: OneNavBarSelectorProps & { router: any }) {
  const {values: context} = useContext(store);

  const userForOneNav = useMemo(() => {
    if (!user || userCapabilitiesLoading) return undefined;
    // copy to fullName for NavBar (user shapes are different between GRM and NG).
    const {firstName, lastName} = internalUser;
    const fullName = firstName || lastName ? `${firstName || ''} ${lastName || ''}`.trim() : user.name;
    // ENG-1749: make sure roles are in upper case for one nav.
    const role = internalUser.role ? internalUser.role : user.role.toUpperCase();

    return {...user, fullName, role};
  }, [user, internalUser, userCapabilitiesLoading]);

  // Needed for componentDidUpdate, see below
  const prevProps = useRef<{ isAuthenticated: boolean } | null>(null);

  // Emulating the componentDidMount of the old <Header/> component
  useEffect((): void => {
    if (isAuthenticated && userCapabilitiesLoading) {
      dispatch(fetchUserProfile(dispatch));
    }
  }, []);

  // Emulating the componentDidUpdate of the old <Header/> component
  useEffect((): void => {
    if (!prevProps || !prevProps.current) return;
    if (!prevProps.current.isAuthenticated && isAuthenticated && userCapabilitiesLoading) {
      dispatch(fetchUserProfile(dispatch));
    }
  }, [isAuthenticated, userCapabilitiesLoading, dispatch, fetchUserProfile]);

  useEffect((): void => {
    prevProps.current = {isAuthenticated};
  }, []);

  const breadcrumbs = useMemo(() => (
    generateBreadcrumbs({currentNode, currentForm, dispatch, router})
  ), [currentNode, currentForm, router.location]);

  const search = useMemo(() => {
    if (!context.search.onChange) return undefined;
    return {
      placeHolder: context.placeHolder,
      displayClearIcon: context.displayClearIcon,
      onChange: context.search.onChange,
      value: searchValue,
    };
  }, [context.search.onChange, context.placeHolder, searchValue]);

  const folderIdForCreation = useMemo<string>(() => {
    // Make sure user is in the lobby
    if (router.location.pathname !== '/ng/lobby') return undefined;
    const folderId = currentNode?.meta?.folderId;
    // Ignore if in root folder or archive folder
    if (folderId === LOBBY_ROOT_FOLDER || folderId === LOBBY_ARCHIVED_FOLDER) {
      return undefined;
    }
    return folderId ? folderId : undefined;
  }, [router.location, currentNode.meta.folderId]);

  return (
    <NavBarBase
      user={userForOneNav}
      buttons={context.buttons}
      account={account}
      search={search}
      breadcrumbs={breadcrumbs}
      accountCapabilities={accountCapabilities}
      ngFeatures={features}
      filePickerKey={FILEPICKER_KEY}
      accountDomains={accountDomains}
      folderIdForCreation={folderIdForCreation}
    />
  );
}

export function mapDispatchToProps(dispatch: Dispatch): PropsFromMapDispatchToProps {
  return {
    fetchUserProfile: getUserProfile,
    dispatch,
  };
}

export function mapStateToProps(state): PropsFromMapToState {
  const { name, logo } = getOrganizationBrand(state);
  const { firstName, lastName, email, role, accountDomains } = state.auth;

  const searchInput = state.navBarSearch.searchBarInput;

  return {
    account: {
      companyName: name,
      logoUrl: logo,
    },
    search: {
      value: searchInput.length ? parsedToString(searchInput) : '',
    },
    accountCapabilities: state.auth.accountCapabilities,
    user: state.auth.profile,
    internalUser: { firstName, lastName, email, role },
    currentNode: state.folderNavigation.currentNode,
    userCapabilitiesLoading: getUserCapabilitiesLoadingState(state),
    isAuthenticated: state.auth.isAuthenticated,
    currentForm: state.submissionTable.formDetails,
    features: state.features,
    accountDomains,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBarContainer));
export {default as withNavBarContext} from './withNavBarContext';
export {default as OneNavBarContextProvider} from './Provider';
