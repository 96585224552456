import React, { FC, memo, MouseEvent, useCallback } from 'react';
import { FormData } from 'types/liveView';
import styles from './LiveViewHeaderStyles.css';

type LiveViewHeaderProps = {
  canEditForm: boolean;
  formData: FormData;
  logoUrl: string;
};

const WEBFORM_TYPE = 'Webform';
const WIZARD_TYPE = 'Wizard';

const LiveViewHeader: FC<LiveViewHeaderProps> = memo(({ formData, logoUrl, canEditForm }) => {
  const HEADER = formData?.form?.formName || '';
  const FORM_ID = formData?.form?.formId || '';
  const WIZARD_FORM_ALIAS = formData?.form?.formAlias || '';
  const WEBFORM_BUILDER_URL = `/forms/builder/${FORM_ID}`;
  const WIZARD_BUILDER_URL = `/w/${WIZARD_FORM_ALIAS}/admin`;
  const FORM_TYPE = formData?.form?.itemType || '';

  const defaultBuilderUrlHandler = useCallback((): string => {
    if (FORM_TYPE === WEBFORM_TYPE) {
      return WEBFORM_BUILDER_URL;
    } else if (FORM_TYPE === WIZARD_TYPE) {
      return WIZARD_BUILDER_URL;
    }
    return '';
  }, [FORM_TYPE, WEBFORM_BUILDER_URL, WIZARD_BUILDER_URL]);

  const onEditClickHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.location.href = defaultBuilderUrlHandler();
  }, [defaultBuilderUrlHandler]);

  return (
    <header className={styles.headerContainer}>
      <div className={styles.headerWrapper}>
        <div className={styles.titleAndLogoWrapper}>
          <a href='/ng/lobby' className={styles.linkNoDecoration} aria-label='Go to lobby'>
            <img
              className={styles.headerLogo}
              src={logoUrl}
              alt='Company logo linking to the NG lobby'
            />
          </a>
          <h3 className={styles.headerTitle} aria-live='polite'>{HEADER}</h3>
        </div>
        {canEditForm && (
          <div>
            <button
              className={styles.editButton}
              onClick={onEditClickHandler}
              aria-label={`Edit ${HEADER} form`}
            >
              Edit
            </button>
          </div>
        )}
      </div>
    </header>
  );
});

export default LiveViewHeader;
