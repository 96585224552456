import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import styles from '../Toolbar.css';


const inlineStyles = {
  button: {
    minWidth: styles.toolbarHeight,
    height: styles.toolbarHeight,
    paddingLeft: 5,
    paddingRight: 5,
  },
};

export default class DarkIconButton extends React.Component<any, any> {
  render() {
    const { icon, iconOverride, onClick, style, tooltip } = this.props;
    const mergedStyle = { ...inlineStyles.button, ...style };
    const iconButton = (
      <IconButton
        classes={{ root: styles.toolbarButton }}
        onClick={onClick}
        style={mergedStyle}>
        {iconOverride ? iconOverride : <Icon classes={{ root: styles.toolbarButtonIcon }}>{icon}</Icon>}
      </IconButton>
    );

    if (!!tooltip) {
      return (
        <Tooltip
          classes={{ tooltip: styles.toolbarButtonTooltip, tooltipPlacementTop: styles.toolbarButtonTooltipPlacement }}
          placement='top'
          title={tooltip}>
          {iconButton}
        </Tooltip>
      );
    }
    return iconButton;
  }
}
