import React, { memo } from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Loader from 'components/Loader';
import styles from './FormLiveView.css';

interface Props {
  open: boolean;
  message?: string;
}

function ProcModal({ message = 'Processing...', open }: Props) {
  return (
    <Dialog
      aria-labelledby='processing-dialog-title'
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{
        root: classNames(styles.PaymentModalRoot, 'form_pay_modal_container'),
      }}
    >
      <DialogContent>
        <div role='status' aria-live='polite' className={styles.visuallyHidden}>
          <span sr-only>{open ? message : ''}</span>
        </div>
        <Loader aria-hidden='true' />
        <div style={{ textAlign: 'center', minWidth: 400, padding: '3rem' }}>
          <h2
            id='processing-dialog-title'
            style={{ marginTop: '1rem', fontSize: '2rem' }}
          >
            <strong>{message}</strong>
          </h2>
          <p id='processing-dialog-description'>
            IMPORTANT: Please do not close or refresh this window.
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default memo(ProcModal);
