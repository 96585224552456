import * as React from 'react';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import Error from 'components/AssetFetcher/Error';
import {
  getSignedUrlError,
  getFetchingAssetState,
  getSignedUrl,
} from '../../reducers';
// import type { State } from 'types/shared';
// import type { FetchSubmissionPdfActionCreator } from 'types/assets';

type PropsFromState = {
  error: boolean,
  fetchingAsset: boolean,
  signedUrl?: string,
};

type Props = {
  assetId: string, // supplied by wrapper components
  fetchAsset: any, // FetchSubmissionPdfActionCreator, // supplied by wrapper components
} & PropsFromState;

class AssetFetcher extends React.Component<Props, any> {
  componentDidMount() {
    this.props.fetchAsset(this.props.assetId);
  }

  componentDidUpdate() {
    if (this.props.signedUrl) {
      window.location.href = this.props.signedUrl;
    }
  }

  render() {
    if (this.props.fetchingAsset) {
      return <Loader />;
    } else if (this.props.error) {
      return <Error />;
    }
    return null;
  }
}

const mapStateToProps = state => ({
  error: getSignedUrlError(state),
  fetchingAsset: getFetchingAssetState(state),
  signedUrl: getSignedUrl(state),
});

export default connect(mapStateToProps, null)(AssetFetcher);
