import {
  triggerCopyLookup,
  triggersEnum,
  triggerIconLookup,
  triggerTypeEnum,
} from 'constants/triggers';

export const getTriggerText = (trigger: string, triggerType: string) =>
  triggerCopyLookup[trigger][triggerType];

export const getTriggerIcon = (trigger: string) => triggerIconLookup[trigger];

export const getPossibleTriggersForApb = (
  features:
  {
    ASSIGNMENTS_IN_SUBMISSION_MANAGER_SUPPORT: any;
    TAGS_IN_SUBMISSION_MANAGER_SUPPORT: any;
    STAGES_IN_SUBMISSION_MANAGER_SUPPORT: any;
  },
  triggerType: string,
  includeStages: boolean,
) => {
  const triggerLookup = {
    [triggersEnum.NOTIFICATION]: triggerType === triggerTypeEnum.THEN,
    [triggersEnum.ASSIGNMENT]: features.ASSIGNMENTS_IN_SUBMISSION_MANAGER_SUPPORT,
    [triggersEnum.TAG]: features.TAGS_IN_SUBMISSION_MANAGER_SUPPORT,
    [triggersEnum.STAGE]: features.STAGES_IN_SUBMISSION_MANAGER_SUPPORT && includeStages,
    [triggersEnum.WEBHOOK]: triggerType === triggerTypeEnum.THEN,
  };
  return Object.keys(triggerLookup).filter(trigger => triggerLookup[trigger]);
};

export const getPossibleTriggersForSrb = (
  features:{ SUBMISSION_RULE_BUILDER_TRIGGERS_SUPPORT: any; },
  fields: any[],
  rule: { field: { id: any; };
}) => {
  const triggersSupported = features.SUBMISSION_RULE_BUILDER_TRIGGERS_SUPPORT;

  let availableTriggers = [triggersEnum.NOTIFICATION, triggersEnum.WEBHOOK];

  if (triggersSupported) {
    availableTriggers = [...availableTriggers, triggersEnum.ASSIGNMENT, triggersEnum.STAGE, triggersEnum.TAG];
  }

  const fieldForRule = fields.find(field => field.id === rule.field.id);
  if (fieldForRule && fieldForRule.validation === 'email') {
    availableTriggers = [...availableTriggers, triggersEnum.COREGISTRATION];
  }

  return availableTriggers;
};
