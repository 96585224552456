import {keyBy} from 'lodash';
import api from './api';
import {
  GET_TAGS,
  CREATE_TAGS,
} from 'constants/types/tagActionTypes';


const initialState = {
  haveLoaded: false,
  tags: {},
};

export default function tags(state = initialState, _action) {
  const action = _action;
  switch (action.type) {
    case GET_TAGS:
      return api(action, state, {
        success: () => ({
          haveLoaded: true,
          tags: keyBy(action.payload.tags, 'name'),
        }),
      });
    case CREATE_TAGS:
      return api(action, state, {
        success: () => {
          const {tagNames} = action.payload;
          const tagsToAdd = tagNames.reduce((accum, name) => ({...accum, [name]: {name}}), {});
          return {
            ...state,
            tags: {
              ...state.tags,
              ...tagsToAdd,
            },
          };
        },
      });
    default:
      return state;
  }
}

export const getTags = state => state.tags;

export const getHaveTagsLoaded = state => state.haveLoaded;
