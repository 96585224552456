import {CLEAR_SELECTED_STAGE} from 'constants/types/stageActionTypes';
import {ALL_VIRTUAL_STAGE_NAME} from 'constants/stages';


const initialState = {
  selectedStageName: ALL_VIRTUAL_STAGE_NAME,
};

export default (state = initialState, _action) => {
  const action = _action;
  switch (action.type) {
    case CLEAR_SELECTED_STAGE:
      return initialState;
    default:
      return state;
  }
};

export const getSelectedStageName = state => state.selectedStageName;

