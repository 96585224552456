import api from 'actions/api';
import {
  convertClientStageToServer,
  convertServerStageToClient,
} from 'utils/stageColors';
import {
  GET_STAGES_OF_FORM,
  CREATE_STAGES,
  ASSIGN_STAGE,
  UNASSIGN_STAGE,
  REORDER_STAGES,
  DELETE_STAGE,
  UPDATE_STAGE,
  CLEAR_SELECTED_STAGE,
  SELECT_ALL_VIRTUAL_STAGE,
  ASSIGN_STAGES,
  UNASSIGN_STAGES,
} from 'constants/types/stageActionTypes';

interface StagesArgs {
  formId: string,
  source: string,
  stageName: string,
  submissionId: string,
}

type SelectStageArgs = StagesArgs & {currentStageName?: string};

type AssignStageArgs = StagesArgs & {
  isUpdate: boolean,
  selectedStageName: string,
};

interface UnassignStagesArgs {
  submissionIds: string[],
  includeAllSubmissions: boolean,
  excludedSubmissionIds: string[],
  formId: string,
  onSuccess?: Function,
  onError?: Function
  snackbarMessage?: string,
}

type AssignStagesArgs = UnassignStagesArgs & {
  selectedStageName: string,
}

export const getStagesOfForm = (formId: string, isArchivedPage: boolean = false) =>
  api.postWithAuth({
    baseType: GET_STAGES_OF_FORM,
    endpoint: 'grm/stages/getStagesWithCounts.json',
    data: {
      formId,
      isArchivedPage,
    },
    success: (_state, {response}) => {
      const serverStages = response.result[formId];
      const stages = serverStages ? serverStages.map(convertServerStageToClient) : [];
      return {
        formId,
        stages,
      };
    },
    failure: (_state, error) => ({error}),
  });

export const createStage = (formId: string, stages: any[], onSuccess: Function, submissionsData: any) =>
  api.postWithAuth({
    baseType: CREATE_STAGES,
    data: {
      formId,
      stages: stages.map(stage => convertClientStageToServer(stage)),
      submissionsData,
    },
    endpoint: 'grm/stages/create.json',
    success: () => {
      onSuccess && onSuccess();
      return {formId, stages, submissionsData};
    },
    failure: (_state, error) => ({error}),
  });

export const unassignStage = (
  {source, submissionId, stageName, formId}: StagesArgs,
  selectedStageName: string,
  onSuccess: Function) =>
  api.postWithAuth({
    baseType: UNASSIGN_STAGE,
    endpoint: 'grm/stages/disassociate.json',
    data: {
      submissionId,
      stageName,
    },
    success: () => {
      onSuccess && onSuccess();
      return {source, submissionId, isUnassign: !onSuccess, stageName, selectedStageName, formId};
    },
    failure: (_state, error) => ({error}),
  });

export const assignStage = ({
  source,
  submissionId,
  stageName,
  isUpdate,
  selectedStageName,
  formId,
}: AssignStageArgs) =>
  api.postWithAuth({
    baseType: ASSIGN_STAGE,
    endpoint: 'grm/stages/associate.json',
    data: {
      submissionId,
      stageName,
    },
    success: (_state, {response: {result: {triggeredRules}}}) => ({
      source,
      submissionId,
      stageName,
      isUpdate,
      selectedStageName,
      formId,
      triggeredRules,
    }),
    failure: (_state, error) => ({error}),
  });

export const assignStages = ({
  submissionIds,
  selectedStageName,
  includeAllSubmissions,
  excludedSubmissionIds,
  formId,
  onSuccess,
  onError,
}: AssignStagesArgs) =>
  api.postWithAuth({
    baseType: ASSIGN_STAGES,
    endpoint: 'stages/associate.json',
    data: {
      submissionIds,
      stageName: selectedStageName,
      includeAllSubmissions,
      excludedSubmissionIds,
      formId,
    },
    success: (_state, { response }) => {
      onSuccess && onSuccess();
      return {
        status: response.status,
        numOfSelected: submissionIds.length,
        triggeredRules: response.result?.triggeredRules,
        submissionIds,
      };
    },
    failure: (_state, error) => {
      onError && onError();
      return error;
    },
    pending: {
      numOfSelected: submissionIds.length,
    },
  });

export const unassignStages = ({
  submissionIds,
  includeAllSubmissions,
  excludedSubmissionIds,
  formId,
  onSuccess,
  onError,
  snackbarMessage = 'Stages removed successfully',
}: UnassignStagesArgs) =>
  api.postWithAuth({
    baseType: UNASSIGN_STAGES,
    endpoint: 'stages/disassociate.json',
    data: {
      submissionIds,
      includeAllSubmissions,
      excludedSubmissionIds,
      formId,
    },
    success: (_state, response) => {
      onSuccess && onSuccess();
      return {
        status: response.status,
        snackbarMessage,
        numOfSelected: submissionIds.length,
      };
    },
    failure: (_state, error) => {
      onError && onError();
      return {
        error,
        snackbarMessage,
      };
    },
    pending: {
      numOfSelected: submissionIds.length,
    },
  });

export const selectStage = (
  {source, submissionId, stageName, currentStageName, formId}: SelectStageArgs,
  selectedStageName,
  dispatch
) => {
  const assignStageAction = assignStage({
    source,
    submissionId,
    stageName,
    isUpdate: !!currentStageName,
    selectedStageName,
    formId,
  });
  if (!currentStageName) return assignStageAction;
  return unassignStage(
    {source, submissionId, stageName: currentStageName, formId},
    selectedStageName,
    () => dispatch(assignStageAction),
  );
};

export const reorderStages = (formId: string, stageNames: string[]) =>
  api.postWithAuth({
    baseType: REORDER_STAGES,
    endpoint: 'grm/stages/reorder.json',
    data: {
      formId,
      stageNames,
    },
    success: () => ({formId, stageNames}),
    failure: (_state, error) => ({error}),
  });

export const deleteStage = (formId: string, stageName: string) =>
  api.postWithAuth({
    baseType: DELETE_STAGE,
    data: {formId, stageName},
    endpoint: 'grm/stages/delete.json',
    success: () => ({formId, stageName}),
    failure: (_state, error) => ({error}),
  });

export const updateStage = (
  formId: string,
  oldStageName: string,
  newStage: any,
) =>
  api.postWithAuth({
    baseType: UPDATE_STAGE,
    data: {formId, oldStageName, newStage: convertClientStageToServer(newStage)},
    endpoint: 'grm/stages/update.json',
    success: () => ({formId, oldStageName, newStage}),
    failure: (state, error) => ({error}),
  });

export const clearSelectedStage = () => ({type: CLEAR_SELECTED_STAGE});

export const selectAllVirtualStage = () => ({
  type: SELECT_ALL_VIRTUAL_STAGE,
});
