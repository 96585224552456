import api from 'reducers/api';
import {SAVE_PAYMENT_SETTINGS, CLOSE_SNACKBAR, GET_FORM_FIELDS, GET_FORM_EMAIL_FIELDS, GET_FORM_FIELDS_ALL} from 'constants/paymentSettings';


export const initialState = {
  isSaveSnackbarOpen: false,
  fields: [],
  emailFields: [],
  formHasRecaptcha: false,
};

export default function paymentConfiguration(state = initialState, action) {
  switch (action.type) {
    case SAVE_PAYMENT_SETTINGS:
      return api(action, state, {
        success: () => ({...state, isSaveSnackbarOpen: true}),
        failure: () => ({...state}),
        pending: () => ({...state}),
      });
    case CLOSE_SNACKBAR:
      return {...state, isSaveSnackbarOpen: false};
    case GET_FORM_FIELDS:
      return {...state, fields: action.payload};
    case GET_FORM_EMAIL_FIELDS:
      return {...state, emailFields: action.payload};
    case GET_FORM_FIELDS_ALL:
      return api(action, state, {
        success: () => {
          const formFields = action.payload?.formFields || [];
          return {
            ...state,
            formHasRecaptcha: !!formFields.find(f => f.type === 'recaptcha'),
          };
        },
        failure: () => ({...state}),
        pending: () => ({...state}),
      });
    default:
      return state;
  }
}

export const isSaveSnackbarOpen = state => state.isSaveSnackbarOpen;
export const getFormFields = state => state.fields;
export const getFormEmailFields = state => state.emailFields;
export const getFormHasRecaptcha = state => state.formHasRecaptcha;
