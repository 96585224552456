import React from 'react';
import LargeModal from 'components/Modals/LargeModal';
import Action from 'components/Modals/Action';
import moment from 'moment-timezone';
import { userTimezone } from 'utils/dateHelper';
import { capitalize } from 'lodash';
import { getFullname, getEmail } from 'utils/textHelper';
import styles from './SignerDetailsModal.css';
import { TIME_FORMAT_LONG_WITH_DAY } from 'constants/dateTime';
import { recipientsFrom } from 'utils/submissionsManager/signatures';


const makeDeviceString = signerMeta => (
  signerMeta.mobile
    ? `${signerMeta.browser} on ${signerMeta.mobile}`
    : `${signerMeta.browser} on ${signerMeta.platform}`);

export default class SignerDetailsModal extends React.Component<any, any> {
  render() {
    const {
      open,
      onClose,
      signatureHistory,
      selectedSigner,
    } = this.props;

    const modalActions = [
      <Action
        key='close'
        label='Close'
        primary
        onClick={onClose} />,
    ];

    const recipientsNode = selectedSigner;
    const signatureUrl = decodeURIComponent(recipientsNode.signature);
    const signerFullname = getFullname(recipientsNode);
    const signerEmail = getEmail(recipientsNode);
    const dateSigned = moment.unix(parseInt(recipientsNode.datesigned, 10))
      .tz(userTimezone)
      .format(TIME_FORMAT_LONG_WITH_DAY);

    const signerMeta = recipientsNode.user_agent_xml;
    const signerDevice = signerMeta ? makeDeviceString(signerMeta) : 'Not detected';
    const altText = `${signerFullname}'s signature`;

    return (
      <LargeModal
        title='Document Submission Information'
        titleIcon='gesture'
        actions={modalActions}
        disableBackdropClick
        open={open}>
        <div className={styles.container}>
          <div className={styles.flexItem}>
            <span className={styles.label}>
              Signer Name
            </span>
            <span className={styles.information}>
              {signerFullname}
            </span>
            <span className={styles.label}>
              Signer Email
            </span>
            <span className={styles.information}>
              {signerEmail}
            </span>
            <span className={styles.label}>
              Signer Key
            </span>
            <span className={styles.information}>
              {recipientsNode.signer_key}
            </span>

            {recipientsNode.unique_key && (
              <React.Fragment>
                <span className={styles.label}>
                  Signature Unique Identifier
                </span>
                <span className={styles.information}>
                  {recipientsNode.unique_key}
                </span>
              </React.Fragment>)
            }

            <span className={styles.label}>
              Number of Signers
            </span>
            <span className={styles.information}>
              {recipientsFrom(signatureHistory).length}
            </span>
            <span className={styles.label}>
              Overall Status
            </span>
            <span className={styles.information}>
              {capitalize(signatureHistory.status)}
            </span>
          </div>
          <div className={styles.flexItem}>
            <span className={styles.label}>
              Signature
            </span>
            <span className={styles.information}>
              <img src={signatureUrl} alt={altText} />
            </span>
            <span className={styles.label}>
              Date Signed
            </span>
            <span className={styles.information}>
              {dateSigned}
            </span>
            <span className={styles.label}>
              IP Address
            </span>
            <span className={styles.information}>
              {recipientsNode.ip_address}
            </span>
            <span className={styles.label}>
              Device
            </span>
            <span className={styles.information}>
              {decodeURIComponent(signerDevice)}
            </span>
          </div>
        </div>
      </LargeModal>
    );
  }
}
