import { ifNotFeature } from './features';
import { USER_PROFILE_API } from 'constants/types/authActionTypes';
import { loginError } from 'actions/auth';
import { generateCapabilitiesGetters } from 'utils/auth';
import { success } from './utils/filters';


const BAD_ACCOUNT_TYPE_COPY = 'This account type doesn\'t have authorization to login. Please contact your administrator.';

export default function roleEpic(action$) {
  const $userProfileSuccess = action$
    .ofType(USER_PROFILE_API)
    .filter(success)
    .filter(({ payload: { accountCapabilities } }) => {
      const cannotAccessSD = !generateCapabilitiesGetters(accountCapabilities).canUserAccessSD();
      return cannotAccessSD;
    });
  return $userProfileSuccess
    // ENG-1633: removing the all the lines below will cause max stack call error. Removing only requestLogout works
    .letBind(ifNotFeature(action$, 'VISITOR_ACCESS_SUPPORT'))
    .mergeMap(() => [
      loginError(new Error(BAD_ACCOUNT_TYPE_COPY)),
    ]);
}
