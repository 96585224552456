import {ALWAYS} from 'embed/constants/submissionRuleBuilder';
import {
  editorStateToStructure,
  structureToEditorState,
} from 'utils/richTextEditor/conversion';
import {splitEmails} from 'utils/email';


export const updateCoverLetterSettings = (
  state,
  settings
) => ({
  ...state,
  coverLetter: {
    ...state.coverLetter,
    settings: {
      ...state.coverLetter.settings,
      ...settings,
    },
  },
});

const determineNewTriggers = (rule, field) => {
  if (!field) return rule.triggers;
  if (rule.triggers === 'coregistration' && field.validation !== 'email') {
    return 'notification';
  }
  return rule.triggers;
};

export const updateSubmissionRules = (rules, fields, action) => {
  const {fieldComparator, fieldId, fieldValue, ruleIndex} = action;
  const fullField = fields.find(({id}) => id === fieldId);
  return rules.map((rule, index) => {
    if (index === ruleIndex) {
      return {
        ...rule,
        field: {
          id: fieldId,
          value: fieldValue,
          comparator: fieldComparator,
        },
        triggers: determineNewTriggers(rule, fullField),
      };
    }
    return rule;
  });
};

export const updateSubmissionRuleEmailSettings = (rules, action) => {
  const {ruleIndex, settings} = action;

  return rules.map((rule, index) => {
    if (index === ruleIndex) {
      return {
        ...rule,
        email: {
          ...rule.email,
          ...settings,
        },
      };
    }
    return rule;
  });
};

export const updateSubmissionRuleAssignees = (rules, action) => {
  const {ruleIndex, assignees} = action;

  return rules.map((rule, index) => {
    if (index === ruleIndex) {
      return {
        ...rule,
        assignment: {
          ...rule.assignment,
          assignees,
        },
      };
    }
    return rule;
  });
};

export const updateSubmissionRuleStage = (rules, action) => {
  const {ruleIndex, stage} = action;

  return rules.map((rule, index) => {
    if (index === ruleIndex) {
      return {
        ...rule,
        stage: {
          stage: stage || null,
        },
      };
    }
    return rule;
  });
};

export const updateSubmissionRuleTags = (rules, action) => {
  const {ruleIndex, tags} = action;

  return rules.map((rule, index) => {
    if (index === ruleIndex) {
      return {
        ...rule,
        tag: {
          ...rule.tag,
          tags,
        },
      };
    }
    return rule;
  });
};

export const updateSubmissionRuleTriggers = (rules, action) => {
  const {ruleIndex, triggers} = action;

  return rules.map((rule, index) => (index === ruleIndex)
    ? {...rule, triggers}
    : rule);
};

export const updateSubmissionRuleCoregistrationSettings = (
  rules,
  action
) => {
  const {ruleIndex, settings} = action;

  return rules.map((rule, index) => (index === ruleIndex)
    ? {
      ...rule,
      coregistration: {
        ...rule.coregistration,
        ...settings,
      },
    }
    : rule);
};

export const removeRuleFromRules = (rules, action) =>
  rules.filter((_, index) => index !== action.index);

const baselineRule = {
  assignment: {assignees: []},
  coregistration: {body: null, subject: null},
  email: {},
  field: {comparator: null, id: null, value: null},
  stage: {stage: null},
  tag: {tags: []},
  triggers: null,
};

const getField = (ruleField = {comparator: null, id: null, value: null},
  shouldAlwaysTrigger = false
) => {
  if (shouldAlwaysTrigger) {
    // @ts-ignore
    ruleField.id = ALWAYS;
  }
  const {id, value, comparator} = ruleField;
  return {
    id,
    value,
    comparator,
  };
};

const formatNotificationRule = ({email, triggers, field, shouldAlwaysTrigger}) => {
  if (!email) throw new Error('Submission rule that triggers notification should have an email field.');

  const {
    body,
    includeGRMLink,
    includeSubmissionData,
    includeSubmissionPdf,
    recipients,
    subject} = email;
  return {
    ...baselineRule,
    email: {
      body: structureToEditorState(body),
      // ENG-3578: legacy includeGRMLink field is temporarily being repurposed to control includeSubmissionViewerLink
      includeSubmissionViewerLink: includeGRMLink,
      includeSubmissionData,
      includeSubmissionPdf,
      recipients: recipients.join(', '),
      subject,
    },
    field: getField(field, shouldAlwaysTrigger),
    triggers,
  };
};

const formatAssignmentRule = ({assignment, triggers, field, shouldAlwaysTrigger}) => {
  if (!assignment) throw new Error('Submission rule that triggers assignment should have an assignment field.');

  const {assignees} = assignment;
  return {
    ...baselineRule,
    assignment: {
      assignees,
    },
    field: getField(field, shouldAlwaysTrigger),
    triggers,
  };
};

const formatStageRule = ({triggers, stage, field, shouldAlwaysTrigger}) => {
  if (!stage) throw new Error('Submission rule that triggers tag should have a tag field.');

  return {
    ...baselineRule,
    field: getField(field, shouldAlwaysTrigger),
    stage,
    triggers,
  };
};

const formatTagRule = ({triggers, tag, field, shouldAlwaysTrigger}) => {
  if (!tag) throw new Error('Submission rule that triggers tag should have a tag field.');

  const {tags} = tag;
  return {
    ...baselineRule,
    field: getField(field, shouldAlwaysTrigger),
    tag: {tags},
    triggers,
  };
};

const formatWebhookRule = ({triggers, webhook, field, shouldAlwaysTrigger}) => {
  if (!webhook) throw new Error('Submission rule that triggers webhook should have a URL field.');

  return {
    ...baselineRule,
    field: getField(field, shouldAlwaysTrigger),
    webhook: {webhook},
    triggers,
  };
};

const formatCoregistrationRule = ({coregistration, triggers, field, shouldAlwaysTrigger}) => {
  if (!coregistration) throw new Error('Submission rule that triggers coregistration should have a coregistration field.');

  const {body, subject} = coregistration;
  return {
    ...baselineRule,
    coregistration: {
      body: structureToEditorState(body),
      subject,
    },
    field: getField(field, shouldAlwaysTrigger),
    triggers,
  };
};

export const rulesFromServerToRules = rules => rules.map(rule => {
  switch (rule.triggers) {
    case 'assignment':
      return formatAssignmentRule(rule);
    case 'coregistration':
      return formatCoregistrationRule(rule);
    case 'stage':
      return formatStageRule(rule);
    case 'tag':
      return formatTagRule(rule);
    case 'webhook':
      return formatWebhookRule(rule);
    case 'notification':
    default:
      return formatNotificationRule(rule);
  }
});

const formatNotificationRuleForServer = ({email}) => {
  if (!email) {
    throw new Error('Submission rule that triggers notification should have an email field.');
  }

  const {
    body,
    includeSubmissionViewerLink,
    includeSubmissionData,
    includeSubmissionPdf,
    recipients,
    subject} = email;

  return {
    body: editorStateToStructure(body),
    // ENG-3578: legacy includeGRMLink field is temporarily being repurposed to control includeSubmissionViewerLink
    includeGRMLink: includeSubmissionViewerLink || false,
    includeSubmissionData: includeSubmissionData || false,
    includeSubmissionPdf: includeSubmissionPdf || false,
    recipients: splitEmails(recipients),
    subject: subject || '',
  };
};

const formatAssignmentRuleForServer = ({assignment}) => {
  if (!assignment) throw new Error('Submission rule that triggers assignment should have an assignment field.');

  const {assignees} = assignment;
  return {
    assignees,
  };
};

const formatCoregistrationRuleForServer = ({coregistration}) => {
  if (!coregistration) throw new Error('Submission rule that triggers coregistration should have a coregistration field.');
  const {body, subject} = coregistration;
  return {
    body: editorStateToStructure(body),
    subject,
  };
};

const formatStageRuleForServer = rule => {
  if (!rule.stage) throw new Error('Submission rule that triggers stage should have a stage field.');

  const {stage} = rule.stage;
  return {stage};
};

const formatTagRuleForServer = ({tag}) => {
  if (!tag) throw new Error('Submission rule that triggers tag should have a tag field.');

  const {tags} = tag;
  return {tags};
};

export const rulesToRulesFromServer = rules => rules.map(rule => {
  const {field: {id, value, comparator}, triggers} = rule;

  if (id == null || (comparator !== 'any' && value == null && id !== ALWAYS)) {
    throw new Error('invalid rule input');
  }

  const ruleForServer: any = {triggers};

  if (id !== ALWAYS) {
    ruleForServer.field = {id, value, comparator};
  } else {
    ruleForServer.shouldAlwaysTrigger = true;
  }

  switch (triggers) {
    case 'assignment':
      return {...ruleForServer, assignment: formatAssignmentRuleForServer(rule)};
    case 'coregistration':
      return {...ruleForServer, coregistration: formatCoregistrationRuleForServer(rule)};
    case 'stage':
      return {...ruleForServer, stage: formatStageRuleForServer(rule)};
    case 'tag':
      return {...ruleForServer, tag: formatTagRuleForServer(rule)};
    case 'webhook':
      return {...ruleForServer, webhook: formatTagRuleForServer(rule)};
    case 'notification':
    default:
      return {...ruleForServer, email: formatNotificationRuleForServer(rule)};
  }
});

export const valueToLabel = (options, value) => options.find(o => o.value === value)?.label;
