import {map} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {
  NEW_TABLE_API,
  UPDATE_TABLE_API,
} from 'constants/types/lobbyTableActionTypes';
import {
  QUICK_FILTER_INITIAL_LOADS,
  QUICK_FILTER_SUBSEQUENT_LOADS,
} from 'constants/quickFilters';
import {
  getCanUpdateSettings,
  getPerFormCapabilities,
} from 'actions/lobby/tableApiActions';
import {success} from './utils/filters';


export default function lobbySideloadEpic(
  action$
) {
  const formLoadSuccesses = [
    NEW_TABLE_API,
    UPDATE_TABLE_API,
    ...QUICK_FILTER_INITIAL_LOADS,
    ...QUICK_FILTER_SUBSEQUENT_LOADS,
  ];
  const formLoadSuccess$ = action$.ofType(...formLoadSuccesses).filter(success);

  return formLoadSuccess$
    .mergeMap(({payload: {tableData}}) => {
      const formIds = map(tableData, 'formId');
      return Observable.of(getPerFormCapabilities(formIds))
        .merge(Observable.of(getCanUpdateSettings(formIds)));
    });
}
