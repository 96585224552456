import React from 'react';
import {map, memoize, omit, pickBy, size, values} from 'lodash';
import {colors, backgroundColors} from 'utils/assigneeColors';
import {userRolesEnum} from 'constants/user';
import UserComponent from 'components/Users/User';
import withKeyboardFunctionality from 'components/MultiSelector//withKeyboardFunctionality';

export const getInitials = (name: string) => name.split(' ').map(bit => bit.slice(0, 1)).join('').toUpperCase();

export const generateRandomTooltipId = (userId: string) =>
  `tooltipId-${userId}${Math.random()
    .toString(36)
    .slice(-3)}`;

// This purely generates an int for a string - think of it as an extremely
// poor person's hash function. We're not worried about collisions or about
// distributing perfectly evenly.
export const getColorsForUserId = (userId: string) => {
  const sum = userId
    .split('')
    .map(oneChar => oneChar.charCodeAt(0))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return {
    backgroundColor: backgroundColors[sum % backgroundColors.length],
    color: colors[sum % colors.length],
  };
};

export const filterToEligibleAssignees = memoize(users => (
  pickBy(users, ({role, isActive}) => role !== userRolesEnum.VISITOR && isActive)
));

export const filterToAssigneesBySearchString = (users: any[], searchString: string | RegExp) => {
  const searchRegExp = new RegExp(searchString, 'i');
  return users.filter(current =>
    searchRegExp.test(`${current.email || ''} ${current.fullName || ''}`)
  );
};

const hydrateAssigneesPrivate = (
  assigneeIds: string[],
  assignees: { [x: string]: any; },
  includeDisabled?: boolean
) => (
  assigneeIds
    .reduce((accum: any[], assigneeId: string) => {
      const hydrated = assignees[assigneeId];
      if (hydrated) {
        // @ts-ignore
        accum.push(hydrated);
        return accum;
      }
      if (!includeDisabled) return accum;
      // @ts-ignore
      accum.push({
        userId: assigneeId,
        fullName: 'Disabled user',
        isActive: false,
        email: '',
        role: userRolesEnum.OWNER,
      });
      return accum;
    }, [])
);

export const hydrateAssignees = memoize(hydrateAssigneesPrivate,
  (assigneeIds, assignees) => `${assigneeIds.join()}-${size(assignees)}`);

const filterOutSelectedAssigneesPrivate = (
  selectedAssigneeIds: string[],
  allAssignees: any[]
) => (
  values(omit(allAssignees, selectedAssigneeIds))
);

export const filterOutSelectedAssignees = memoize(filterOutSelectedAssigneesPrivate,
  (selectedAssigneeIds, allAssignees) => `${selectedAssigneeIds.join()}-${size(allAssignees)}`);

const filterToEligibleAuthorsPrivate = (currentAuthor: { userId: any; }, users: any[]) => (
  users.filter(user => (
    user.userId !== (currentAuthor ? currentAuthor.userId : '') &&
    user.isActive &&
    user.role !== userRolesEnum.VISITOR
  ))
);

export const filterToEligibleAuthors = memoize(filterToEligibleAuthorsPrivate,
  (currentAuthor, users) => `${currentAuthor ? currentAuthor.userId : ''}-${map(users, 'userId').join()}`);


export const filterToUsersEligibleToReceivePermissionsFactory = userIdsWithPermissions => (
  users => (
    users.filter(user => (
      !userIdsWithPermissions.includes(user.userId) &&
        user.isActive &&
        user.role !== userRolesEnum.OWNER &&
        user.role !== userRolesEnum.VISITOR
    ))
  )
);

export const renderUser = (wideBubble = false) => (
  props => {
    const UserWithKeyBoard = withKeyboardFunctionality(UserComponent);
    return (
      <UserWithKeyBoard
        inPopover={props.inPopover}
        isMultiplePicked={props.isMultiplePicked}
        isReadOnly={props.isReadOnly}
        isSelectedByKeyboard={props.isSelectedByKeyboard}
        key={props.key}
        onRemove={props.onRemove}
        onSelect={props.onSelect}
        selected={props.selected}
        selectionData={props.selectionData}
        wideBubble={wideBubble}/>
    );
  }
);
