import React, { useMemo, useState } from 'react';

import { joinClassNames } from 'utils/strings';
import Dropdown, { Item } from 'components/Dropdown';
import ClosedDropdown from 'components/Dropdown/ClosedDropdown';
import DropdownOption from 'components/Dropdown/DropdownOption';

import styles from './styles.css';

type DropdownItemType = {
  label: string;
  value: string;
}

type DropdownItemProps = {
  data: DropdownItemType,
  onClick: (dropdownItem: DropdownItemType) => void,
}

type Props = {
  items: DropdownItemType[];
  selected: DropdownItemType | undefined,
  onSelect: (dropdownItem: DropdownItemType) => void,
};


export const DropdownItem = ({
  data,
  onClick,
}: DropdownItemProps) => (
  <DropdownOption onClick={() => onClick(data)}>
    <div>
      {data ? data.label : 'Choose'}
    </div>
  </DropdownOption>
);

const FilterDropdown = ({
  items = [],
  selected = undefined,
  onSelect,
}: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleSelect = (item: DropdownItemType) => {
    setIsOpened(false);
    onSelect(item);
  };

  const dropdownItems: Item<DropdownItemType>[] = useMemo(() => items.map(item => ({
    Component: DropdownItem,
    props: {
      data: item,
      onClick: handleSelect,
    },
  })), [items, selected]);

  const renderTriggerButton = useMemo(() => () => (
    <ClosedDropdown className={joinClassNames(styles.leftColumn, styles.closedDropdown)}>
      <span className={styles.selectedLabel}>
        {selected ? selected.label : 'Choose'}
      </span>
    </ClosedDropdown>
  ), [selected]);

  return (
    <Dropdown
      isEnabled
      isOpen={isOpened}
      items={dropdownItems}
      requestClose={() => setIsOpened(false)}
      requestOpen={() => setIsOpened(true)}
      renderTriggerButton={renderTriggerButton}
      tabIndex='-1'
    />
  );
};

export default FilterDropdown;
