import api from 'actions/api';
import {ASSIGN_TAGS_TO_MULTIPLE, GET_TAGS, UNASSIGN_TAGS_TO_MULTIPLE} from 'constants/types/tagActionTypes';
import {union} from 'lodash';
import {ASSIGN_TAGS, UNASSIGN_TAGS, CREATE_TAGS} from 'constants/types/tagActionTypes';

interface TagsArgs {
  source: string,
  submissionId: string,
  tagNames: string[],
}

interface TagsForMultipleSubmissionsArgs {
  submissionIds: string[],
  excludedSubmissionIds: string[],
  formId: string,
  includeAllSubmissions?: boolean,
}

type UnassignTagsForMultipleSubmissionsArgs = TagsForMultipleSubmissionsArgs & {
  onSuccess?: Function,
  onError?: Function
}

type AssignTagsForMultipleSubmissionsArgs = UnassignTagsForMultipleSubmissionsArgs & {
  tagNames: string[],
}

export const getTags = () =>
  api.postWithAuth({
    baseType: GET_TAGS,
    endpoint: 'grm/tags/get.json',
    success: (_state, {response}) => ({tags: response}),
    failure: (_state, error) => ({error}),
  });


export const assignTags = ({source, submissionId, tagNames}: TagsArgs) =>
  api.postWithAuth({
    baseType: ASSIGN_TAGS,
    endpoint: 'grm/tags/associate.json',
    data: {
      submissionId,
      tagNames,
    },
    success: (_state, {response: {apbTriggerServiceResponse: {triggeredRules}}}) => ({
      source,
      submissionId,
      tagNames,
      triggeredRules,
    }),
    failure: (_, error) => ({error}),
  });

export const unassignTags = ({source, submissionId, tagNames}: TagsArgs) =>
  api.postWithAuth({
    baseType: UNASSIGN_TAGS,
    endpoint: 'grm/tags/disassociate.json',
    data: {
      submissionId,
      tagNames,
    },
    success: () => ({source, submissionId, tagNames}),
    failure: (_state, error) => ({error}),
  });

export const assignTagsToMultipleSubmissions = ({
  submissionIds,
  excludedSubmissionIds,
  tagNames,
  formId,
  includeAllSubmissions,
  onSuccess,
  onError,
}: AssignTagsForMultipleSubmissionsArgs) =>
  api.postWithAuth({
    baseType: ASSIGN_TAGS_TO_MULTIPLE,
    endpoint: 'tags/associate.json',
    data: {
      submissionIds,
      tags: tagNames,
      formId,
      includeAllSubmissions,
      excludedSubmissionIds,
    },
    success: (_state, { response }) => {
      onSuccess && onSuccess();
      return {
        status: response.status,
        snackbarMessage: 'Tags added successfully',
        numOfSelected: submissionIds.length,
        triggeredRules: response.result?.triggeredRules,
        submissionIds,
      };
    },
    failure: (_state, error) => {
      onError && onError();
      return error;
    },
    pending: {
      numOfSelected: submissionIds.length,
    },
  });

export const unassignTagsToMultipleSubmissions = ({
  submissionIds,
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  onSuccess,
  onError,
}: UnassignTagsForMultipleSubmissionsArgs) =>
  api.postWithAuth({
    baseType: UNASSIGN_TAGS_TO_MULTIPLE,
    endpoint: 'tags/disassociate.json',
    data: {
      submissionIds,
      formId,
      includeAllSubmissions,
      excludedSubmissionIds,
    },
    success: (_state, response) => {
      onSuccess && onSuccess();
      return {
        status: response.status,
        snackbarMessage: 'Tags removed successfully',
        numOfSelected: submissionIds.length,
      };
    },
    failure: (_state, error) => {
      onError && onError();
      return error;
    },
    pending: {
      numOfSelected: submissionIds.length,
    },
  });

export const createTags = (tagNames: string[], successCallback: Function) =>
  api.postWithAuth({
    baseType: CREATE_TAGS,
    data: {tags: tagNames.map(name => ({name}))},
    endpoint: 'grm/tags/create.json',
    success: (_state, response) => {
      const {succeeded, alreadyExists} = response.response;
      const newTags = union(succeeded, alreadyExists);
      successCallback(newTags);
      return {tagNames: newTags};
    },
    failure: (_state, error) => ({error}),
  });
