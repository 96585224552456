/*
 * Inline-style version of ./input.css for use in embedded environments (e.g. V1).
 * CSS version should be preferred in NG.
 * Properties that are not present in CSS version should be annotated with justification (e.g. overrides Bootstrap CSS)
 */

import * as Colors from 'utils/colors';

export const inputField = {
  backgroundColor: Colors.white,
  border: `1px solid ${Colors.grey300}`,
  borderRadius: 0,
  color: Colors.textPrimary,
  display: 'block',
  fontSize: '14px',
  height: '42px',
  marginBottom: '10px',
  outline: 0,
  padding: '0 12px',
  transitionProperty: 'none', // overrides Bootstrap CSS
  width: 'calc(100% - 26px)',
  boxSizing: 'border-box', // V1 WF override
};

export const inputFieldFocused = {
  ...inputField,
  border: `1px solid ${Colors.orangePrimary}`,
  boxShadow: 'none', // overrides Bootstrap CSS
};
