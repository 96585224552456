import React from 'react';
import style from '../modalShared.css';
import SmallModal from 'components/Modals/SmallModal';
import Action from 'components/Modals/Action';
import FormResponseBox from 'components/FormResponseBox';
import { validateEmailAddress } from 'utils/email';


export default class EditSignerModal extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      email: props.email,
      formResponse: false,
      formResponseMessage: '',
      formResponseSuccess: false,
      signerKey: props.signerKey,
    };
  }

  handleEmail(event) {
    this.setState({
      email: event.target.value,
    });
  }

  handleSubmit = event => {
    event.preventDefault();

    const { email, signerKey } = this.state;
    const { onEditSignerModalToggle, handleReNotify } = this.props;

    const validEmail = validateEmailAddress(email);

    if (validEmail) {
      onEditSignerModalToggle();
      handleReNotify(signerKey, email);
      this.setState({ email: this.props.email });
    } else {
      this.setState({
        formResponse: true,
        formResponseSuccess: false,
        formResponseMessage: 'Invalid email address',
      });
    }
  }

  render() {
    const { editSignerVisible, onEditSignerModalToggle } = this.props;

    let formInputClass = style.formInput;

    if (this.state.formResponse && !this.state.formResponseSuccess) {
      formInputClass = style.formInputError;
    }

    const modalActions = [
      <Action
        key='cancel'
        label='Cancel'
        secondary
        onClick={onEditSignerModalToggle} />,
      <Action
        key='send'
        label='Send'
        primary
        onClick={this.handleSubmit} />,
    ];

    return (
      <SmallModal
        title='Send Email Requesting Signature'
        titleIcon='gesture'
        actions={modalActions}
        disableBackdropClick
        onClose={onEditSignerModalToggle}
        open={editSignerVisible}>

        <form className={style.formContainer} onSubmit={this.handleSubmit}>
          <label htmlFor='emailAddress' className={style.formLabel}>Email Address</label>
          <input type='email' name='emailAddress' className={formInputClass} onChange={this.handleEmail.bind(this)} value={this.state.email} />

          <FormResponseBox
            show={this.state.formResponse}
            success={this.state.formResponseSuccess}
            message={this.state.formResponseMessage} />
        </form>

      </SmallModal>
    );
  }
}
