import { GET_FORM_COVER_LETTER } from 'constants/types/formActionTypes';
import api from '../api';

const initialState = Object.freeze({});

export default (state = initialState, action_) => {
  const action = action_;
  switch (action.type) {
    case GET_FORM_COVER_LETTER:
      return api(action, state, {
        success: () => {
          const { payload: { coverLetter } } = action;
          return {
            ...state,
            ...coverLetter,
          };
        },
        failure: () => {
          const { payload: { error } } = action;
          return {
            ...state,
            error,
          };
        },
      });
    default:
      return state;
  }
};
