import { invertBy, mapValues } from 'lodash';
import * as Colors from 'utils/colors';

export const colorStyleMap = {
  FONT_LIGHT_GREY: {
    color: Colors.grey650,
  },
  FONT_GREY: {
    color: Colors.grey850,
  },
  FONT_LIGHT_RED: {
    color: Colors.red500,
  },
  FONT_RED: {
    color: Colors.red800,
  },
  FONT_LIGHT_PURPLE: {
    color: Colors.purple500,
  },
  FONT_PURPLE: {
    color: Colors.purple800,
  },
  FONT_LIGHT_BLUE: {
    color: Colors.blue500,
  },
  FONT_BLUE: {
    color: Colors.blue800,
  },
  FONT_LIGHT_GREEN: {
    color: Colors.green500,
  },
  FONT_GREEN: {
    color: Colors.green800,
  },
  FONT_LIGHT_ORANGE: {
    color: Colors.orange500,
  },
  FONT_ORANGE: {
    color: Colors.orange800,
  },
  FONT_LIGHT_BROWN: {
    color: Colors.brown500,
  },
  FONT_BROWN: {
    color: Colors.brown800,
  },
};

const invertedColorMap = invertBy(colorStyleMap, style => style.color);
export const colorMapHex = mapValues(invertedColorMap, color => color[0]);
export const FONT_COLORS_FOR_PICKER = Object.keys(colorMapHex);
