import {get, isNumber} from 'lodash';

import api from 'reducers/api';
import {
  ADD_EMPTY_RULE,
  CHANGE_RULE_ASSIGNMENTS,
  CHANGE_RULE_EMAIL_BODY,
  CHANGE_RULE_EMAIL_ATTACHED_PDF,
  CHANGE_RULE_EMAIL_INCLUDE_SM_LINK,
  CHANGE_RULE_EMAIL_INCLUDE_FIELDS,
  CHANGE_RULE_EMAIL_RECIPIENTS,
  CHANGE_RULE_EMAIL_SUBJECT,
  CHANGE_RULE_STAGE,
  CHANGE_RULE_WEBHOOK,
  CHANGE_RULE_STATUS,
  CHANGE_RULE_TAGS,
  CHANGE_RULE_TRIGGER,
  DELETE_RULE,
  LOAD_RULES,
  SAVE_RULES,
} from 'constants/types/automatedProcessBuilderActionTypes';
import {ruleStatusEnum} from 'constants/automatedProcessBuilder';
import {createEmptyRule, getTriggerProperty, getValueProperty} from 'utils/automatedProcessBuilder';
import {immutableModifyElementInArray, immutableRemoveElementInArray} from 'utils/reduxHelper';


// Freeze to make it compatible with our exact shape
// https://github.com/facebook/flow/issues/2977
export const initialState = Object.freeze({});

export const findRuleIndex = (formId, ruleId, state) =>
  state[formId].findIndex(rule => rule.ruleId === ruleId);

export const getUpdatedValue = (action, currentValue) => {
  switch (action.type) {
    case CHANGE_RULE_ASSIGNMENTS:
      if (action.assigneeIds.length === 0) return null;
      return {
        assignment: action.assigneeIds,
      };
    case CHANGE_RULE_EMAIL_BODY:
    case CHANGE_RULE_EMAIL_ATTACHED_PDF:
    case CHANGE_RULE_EMAIL_INCLUDE_SM_LINK:
    case CHANGE_RULE_EMAIL_INCLUDE_FIELDS:
    case CHANGE_RULE_EMAIL_RECIPIENTS:
    case CHANGE_RULE_EMAIL_SUBJECT:
      const currentEmail = get(currentValue, ['email', 'email']);
      const updatedEmail = currentEmail ? {...currentEmail, ...action.emailChanges} : {...action.emailChanges};
      return {
        email: {
          email: updatedEmail,
        },
      };
    case CHANGE_RULE_TAGS:
      if (action.tagNames.length === 0) return null;
      return {
        tag: action.tagNames,
      };
    case CHANGE_RULE_STAGE:
      if (!action.stageName) return null;
      return {
        stage: action.stageName,
      };
    case CHANGE_RULE_WEBHOOK:
      if (!action.webhookUrl) return null;
      return {
        webhook: action.webhookUrl,
      };
    default:
      return null;
  }
};

export default function rules(state = initialState, action) {
  switch (action.type) {
    case LOAD_RULES:
      return api(action, state, {
        success: () => ({
          ...state,
          [action.payload.formId]: action.payload.clientRules,
        }),
        failure: () => ({
          ...state,
          [action.payload.formId]: action.payload.clientRules,
        }),
      });
    case SAVE_RULES:
      return api(action, state, {
        success: () => ({
          ...state,
          [action.payload.formId]: action.payload.clientRules,
        }),
        failure: () => ({
          ...state,
          [action.payload.formId]: action.payload.clientRules || [],
        }),
      });
    case ADD_EMPTY_RULE:
      const existingRules = state[action.formId] || [];
      return {
        ...state,
        [action.formId]: [...existingRules, createEmptyRule()],
      };
    case CHANGE_RULE_STATUS:
      const statusChangeIndex = findRuleIndex(action.formId, action.ruleId, state);
      if (isNumber(statusChangeIndex)) {
        return {
          ...state,
          [action.formId]: immutableModifyElementInArray(state[action.formId], statusChangeIndex, {
            ...state[action.formId][statusChangeIndex],
            isModified: true,
            status: action.isEnabled ? ruleStatusEnum.ENABLED : ruleStatusEnum.DISABLED,
          }),
        };
      }
      return state;
    case CHANGE_RULE_ASSIGNMENTS:
    case CHANGE_RULE_TAGS:
    case CHANGE_RULE_WEBHOOK:
    case CHANGE_RULE_STAGE:
      const ruleChangeIndex = findRuleIndex(action.formId, action.ruleId, state);
      const rule = get(state, [action.formId, ruleChangeIndex]);
      const valueToModify = getValueProperty(action.triggerType);
      const updatedValue = getUpdatedValue(action, rule[valueToModify]);
      if (isNumber(ruleChangeIndex)) {
        return {
          ...state,
          [action.formId]: immutableModifyElementInArray(state[action.formId], ruleChangeIndex, {
            ...state[action.formId][ruleChangeIndex],
            isOnlyEmailModified: false,
            isModified: true,
            [valueToModify]: updatedValue,
          }),
        };
      }
      return state;
    case CHANGE_RULE_EMAIL_BODY:
    case CHANGE_RULE_EMAIL_RECIPIENTS:
    case CHANGE_RULE_EMAIL_ATTACHED_PDF:
    case CHANGE_RULE_EMAIL_INCLUDE_SM_LINK:
    case CHANGE_RULE_EMAIL_INCLUDE_FIELDS:
    case CHANGE_RULE_EMAIL_SUBJECT:
      const ruleChangeIndexEmail = findRuleIndex(action.formId, action.ruleId, state);
      const ruleEmail = get(state, [action.formId, ruleChangeIndexEmail]);
      const valueToModifyEmail = getValueProperty(action.triggerType);
      const updatedValueEmail = getUpdatedValue(action, ruleEmail[valueToModifyEmail]);
      const isModified = state[action.formId][ruleChangeIndexEmail].isModified;
      const isOnlyEmailModified = state[action.formId][ruleChangeIndexEmail].isOnlyEmailModified;
      if (isNumber(ruleChangeIndexEmail)) {
        return {
          ...state,
          [action.formId]: immutableModifyElementInArray(state[action.formId], ruleChangeIndexEmail, {
            ...state[action.formId][ruleChangeIndexEmail],
            isOnlyEmailModified: (isModified && isOnlyEmailModified) || (!isModified && !isOnlyEmailModified),
            isModified: true,
            [valueToModifyEmail]: updatedValueEmail,
          }),
        };
      }
      return state;
    case CHANGE_RULE_TRIGGER:
      const triggerChangeIndex = findRuleIndex(action.formId, action.ruleId, state);
      if (isNumber(triggerChangeIndex)) {
        const propertyToModify = getTriggerProperty(action.triggerType);
        const valueToSetToNull = getValueProperty(action.triggerType);
        return {
          ...state,
          [action.formId]: immutableModifyElementInArray(state[action.formId], triggerChangeIndex, {
            ...state[action.formId][triggerChangeIndex],
            isModified: true,
            [propertyToModify]: action.trigger,
            [valueToSetToNull]: null,
          }),
        };
      }
      return state;
    case DELETE_RULE:
      const ruleToDeleteIndex = findRuleIndex(action.formId, action.ruleId, state);
      if (isNumber(ruleToDeleteIndex)) {
        const ruleToDelete = state[action.formId][ruleToDeleteIndex];
        if (ruleToDelete.isNew) {
          return {
            ...state,
            [action.formId]: immutableRemoveElementInArray(state[action.formId], ruleToDeleteIndex),
          };
        }
        return {
          ...state,
          [action.formId]: immutableModifyElementInArray(state[action.formId], ruleToDeleteIndex, {
            ...state[action.formId][ruleToDeleteIndex],
            status: ruleStatusEnum.ARCHIVED,
          }),
        };
      }
      return state;
    default:
      return state;
  }
}

export const getRuleState = state => state;

export const getRulesForForm = (state, formId) => state[formId] || [];
