import React from 'react';
import * as Colors from 'utils/colors';
import styles from './SubmissionTable.css';


const SignatureStatusCell = ({ signatureStatus, totalCompletedSigners, totalSigners }) => {
  let signatureStatusColor;
  let signatureStatusText;
  let signatureStatusProgress: JSX.Element | string = '';

  switch (signatureStatus) {
    case 'COMPLETE':
      signatureStatusText = 'Complete';
      signatureStatusColor = Colors.green500;
      break;
    case 'PENDING':
    case 'INCOMPLETE(PENDING)':
      signatureStatusText = 'Pending';
      signatureStatusColor = Colors.yellow700;
      if (totalCompletedSigners != null && totalSigners != null) {
        signatureStatusProgress = (
          <span className={styles.signaturePendingProgress}>
            {`${totalCompletedSigners}/${totalSigners}`}
          </span>
        );
      }
      break;
    case 'DECLINED':
      signatureStatusText = 'Declined';
      signatureStatusColor = Colors.red500;
      break;
    default:
      signatureStatusText = signatureStatus;
      signatureStatusColor = null;
  }

  return (
    <span>
      <span
        className={styles.signatureStatusLosenge}
        style={{ backgroundColor: signatureStatusColor }}>
        {signatureStatusText}
      </span>
      {signatureStatusProgress}
    </span>
  );
};

export default SignatureStatusCell;
