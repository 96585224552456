/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import classNames from 'classnames';
import style from './FormCoverLetter.css';

const tagTypeMap = {
  text: 'span',
  unorderedList: 'ul',
  orderedList: 'ol',
  image: 'img',
  link: 'a',
};

function renderCoverLetterFromStructure(htmlObj: any[] = [], parentTag?: string) {
  return htmlObj
    ?.map(
      ({ tagType, children = [], text, styles, ...rest }, index) => {
        try {
          let Tag = tagTypeMap[tagType] || tagType;
          // @ts-ignore
          if (!Tag && ['ol', 'ul'].includes(parentTag)) {
            Tag = 'li';
          }
          let arr;
          switch (Tag) {
            case 'br':
            case 'hr':
              return (<Tag key={index} style={styles} />);
            case 'a':
              arr = renderCoverLetterFromStructure(text, Tag);
              return (<Tag key={index} style={styles} href={rest.linkTo}>{arr}</Tag>);
            case 'img':
              return (<Tag key={index} style={styles} src={rest.url} width={rest.width} height={rest.height} />);
            default:
              arr = text || renderCoverLetterFromStructure(children, Tag);
              return (<Tag key={index} style={styles}>{arr}</Tag>);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        return null;
      }) || null;
}

interface CoverLetterProps {
  legacyHtml: string,
  ackText: string,
  title: string,
  requireAck: boolean,
  structure: any[],
  onContinue: React.MouseEventHandler
}

function FormCoverLetter(
  { legacyHtml, ackText, title, requireAck, structure, onContinue = () => { } }: CoverLetterProps) {
  const [ack, setAck] = useState(!requireAck);
  const onAckChange = () => {
    setAck(!ack);
  };

  const renderAckTextAndControl = () => {
    const label = ackText || 'By continuing I agree that I am willing to complete a digital version of the document(s) and that information about my user session will be stored.';
    return (
      <div className={style.ackControlGroup}>
        <label className={style.ackLabel}>
          {requireAck
            ? (<input type='checkbox' onChange={onAckChange} checked={ack} id='ack_cb' className={style.ackCheckbox} />)
            : null}
          <span className={classNames({ 'has-checkbox': requireAck })}>{label}</span>
        </label>
      </div>
    );
  };

  return (
    <div className={classNames('form_cover_letter')}>
      <div className={classNames(style.textContainer, 'form_cover_letter_html')}>
        {title ? (<h1>{title}</h1>) : null}
        {
          legacyHtml
            ? (<div dangerouslySetInnerHTML={{ __html: legacyHtml }} />)
            : renderCoverLetterFromStructure(structure)
        }
      </div>
      <div className={classNames(style.ackContainer, 'form_cover_letter_ack')}>
        {renderAckTextAndControl()}
        <button disabled={!ack} className={style.nextButton} onClick={onContinue}>Next</button>
      </div>
    </div>
  );
}

export default FormCoverLetter;
