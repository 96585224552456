import React from 'react';
import MultiSelector from 'components/MultiSelector';
import AddTagButton from './AddTagButton';
import TagComponent from '../Tag';
import withKeyboardFunctionality from 'components/MultiSelector//withKeyboardFunctionality';
import ShowOverflowButton from './ShowOverflowButton';
import TypeaheadInput from './TypeaheadInput';
import CreateTagFooter from 'components/MultiSelector/shared/CreateTagFooter';
import styles from './TagMultiSelector.css';


class TagMultiSelector extends React.Component<any, any> {
  static defaultProps = {
    hidePeak: false,
    isLoading: false,
    isReadOnly: false,
    maxElements: 1,
  };

  renderFooter = props => {
    const handleOnClick = tagName => {
      this.props.onCreateTag(tagName);
      props.onClick();
    };
    return (
      <CreateTagFooter
        isSelected={!!props.isSelected}
        onClick={handleOnClick}
        text={this.props.footerText}
        footeref={props.ref} />
    );
  };

  renderTag = props => {
    const TagWithKeyBoard = withKeyboardFunctionality(TagComponent);
    return (
      <TagWithKeyBoard
        inPopover={props.inPopover}
        isMultiplePicked={props.isMultiplePicked}
        isReadOnly={props.isReadOnly}
        isSelectedByKeyboard={props.isSelectedByKeyboard}
        key={props.key}
        onRemove={props.onRemove}
        onSelect={props.onSelect}
        selected={props.selected}
        selectionData={props.selectionData} />
    );
  }
  render() {
    const {
      hidePeak,
      isLoading,
      isReadOnly,
      maxElements,
      onAddTags,
      onInputTypeahead,
      onRemoveTag,
      tags,
      typeaheadResults,
    } = this.props;
    return (
      <MultiSelector
        hidePeak={hidePeak}
        isLoading={isLoading}
        isReadOnly={isReadOnly}
        maxElements={maxElements}
        onAddSelections={onAddTags}
        onInputTypeahead={onInputTypeahead}
        onRemoveSelection={onRemoveTag}
        overflowButtonCountsAsElement={false}
        renderFooter={this.renderFooter}
        renderSelection={this.renderTag}
        resultsListClassName={styles.resultsListContainer}
        selections={tags}
        ShowOverflowButton={ShowOverflowButton}
        ShowTypeaheadButton={AddTagButton}
        TypeaheadInput={TypeaheadInput}
        typeaheadOffset={-20}
        typeaheadResults={typeaheadResults} />
    );
  }
}

export default TagMultiSelector;
