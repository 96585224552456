import React from 'react';
import Tooltip from 'components/Tooltip';
import StageDot from 'components/Stages/StageDot';
import styles from './StageTooltip.css';


const StageTooltip = ({ children, stage }) => (
  <Tooltip
    placement='right'
    className={styles.tooltip}
    title={
      <React.Fragment>
        <StageDot radiusInPx={4} color={stage.color} />
        <span className={styles.stageName}>{stage.name}</span>
      </React.Fragment>
    }>
    {children}
  </Tooltip>
);

export default StageTooltip;
