import React from 'react';
import { Cell } from 'fixed-data-table-2';
import documentIcon from 'icons/icon25.svg';
import formIcon from 'icons/icon26.svg';
import wizardIcon from 'icons/icon-wizard.svg';
import styles from './FormsTable.css';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';


export const itemType = 'FORM_ITEM';


const source = {
  beginDrag(props) {
    const { selectRow, index, rowData } = props;
    // TODO: Improve this anti-pattern. https://seamlessdocs.atlassian.net/browse/NG-1836
    selectRow && selectRow({}, index);
    return {
      rowData,
      index,
      itemType,
    };
  },
  canDrag(props) {
    return props.isDraggable;
  },
};

const dragCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

class FormTypeIcon extends React.Component<any, any> {
  static defaultProps = {
    isDraggable: true,
  };

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  getIconUrl(_itemType) {
    switch (_itemType) {
      case 'Webform':
        return formIcon;
      case 'Wizard':
        return wizardIcon;
      default:
        return documentIcon;
    }
  }

  render() {
    const {
      cellClass,
      rowData,
      className,
      isDragging,
      connectDragSource,
    } = this.props;
    const style = isDragging ? { ...this.props.style, opacity: 0.5 } : this.props.style;
    const resultantClassName = className ? className : `${styles.iconSubmissions} icon-submission-holder`;
    const icon = this.getIconUrl(rowData.itemType);

    return (
      <Cell className={cellClass}>
        {
          /*
          * .icon-submission-holder styles can be found in /styles/fixed-data-table.css
          * it's for when the row is selected and can't be set in the specific css modules file
          */
        }
        {
          connectDragSource(
            <div className={resultantClassName} style={style}>
              <img src={icon} alt={rowData.itemType} />
            </div>
          )
        }
      </Cell>
    );
  }
}

export default DragSource(itemType, source, dragCollect)(FormTypeIcon);
