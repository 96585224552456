import React from 'react';
import { EditorState } from 'draft-js';


const alignmentToStyle = {
  default: {},
  center: { display: 'block', margin: '0 auto' },
  left: { float: 'left', margin: '0 24px 24px 0' },
  right: { float: 'right', margin: '0 0 24px 24px' },
};

const createDecorator = WrappedComponent =>
  class BlockAlignmentDecorator extends React.Component<any, any> {
    componentDidUpdate() {
      const {
        blockProps: {
          alignmentSetter,
          isCollapsedSelection,
          isFocused,
          onBlur,
          onFocus,
        },
      } = this.props;
      if (isFocused && isCollapsedSelection) {
        setTimeout(() => onFocus(alignmentSetter));
      } else {
        onBlur();
      }
    }

    render() {
      const { blockProps, style, ...elementProps } = this.props;

      const newStyle = { ...style, ...alignmentToStyle[blockProps.alignment] };

      return (
        <WrappedComponent
          {...elementProps}
          blockProps={blockProps}
          style={newStyle} />
      );
    }
  };

const createSetAlignment = (
  contentBlock,
  { getEditorState, setEditorState }
) =>
  alignment => {
    const entityKey = contentBlock.getEntityAt(0);
    if (entityKey) {
      const editorState = getEditorState();
      const contentState = editorState.getCurrentContent();
      const previousData = contentState.getEntity(entityKey).data || {};
      if (alignment !== previousData.alignment) {
        contentState.mergeEntityData(entityKey, { alignment });
        setEditorState(EditorState.forceSelection(editorState, editorState.getSelection()));
      }
    }
  };

export default ({ onBlur, onFocus }) => ({
  decorator: createDecorator,
  blockRendererFn: (contentBlock, { getEditorState, setEditorState }) => {
    const entityKey = contentBlock.getEntityAt(0);
    const contentState = getEditorState().getCurrentContent();
    const alignmentData = entityKey ? contentState.getEntity(entityKey).data : {};
    return {
      props: {
        alignment: alignmentData.alignment || 'default',
        alignmentSetter: createSetAlignment(contentBlock, { getEditorState, setEditorState }),
        onBlur,
        onFocus,
      },
    };
  },
});
