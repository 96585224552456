/**
 * Form Response Box Component
 *
 * This returns the message box at the end of the form
 * Usually used for validation
 *
 * Props:
 * show: Boolean [Required]
 * success: Boolean
 * message: String
 *
 * Example Use:
 *
 * <FormResponseBox show={true}
 *                  success={true}
 *                  message="Successful" />
 */

import React from 'react';
import style from './FormResponseBox.css';

const FormResponseBox = ({ message, show, success }


) => {
  let formResponseClass;

  if (show) {
    if (success) {
      formResponseClass = style.success;
    } else {
      formResponseClass = style.failure;
    }
  } else {
    formResponseClass = style.formResponse;
  }

  return (
    <div className={formResponseClass}>
      {message}
    </div>
  );
};

export default FormResponseBox;
