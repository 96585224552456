export const INFINITE_SCROLL_TRIGGER = 1000;
export const LOBBY_FORM_COUNT_REQUEST_SIZE = 50;
export const ItemTypes = {
  BOX: 'box',
};

export const LOBBY_ROOT_FOLDER = 'RootFolder';
export const LOBBY_ARCHIVED_FOLDER = 'ArchivedItems';

export const TABLE_CELL_WIDTH = 200;
export const ROW_NUMBER_CELL_WIDTH = 40;
export const CHECKBOX_WIDTH = 48;
export const SUBMISSIONS_ICON_WIDTH = 34;

// debounce rate in milliseconds
export const SEARCH_DEBOUNCE_RATE_MS = 1000;

export const ASSIGNMENTS_COLUMN_LABEL = 'assignments';
export const TAGS_COLUMN_LABEL = 'tags';
export const STAGES_COLUMN_LABEL = 'stages';
export const COMPLETION_COLUMN_LABEL = 'completionStatus';
export const PAYMENT_AMOUNT_COLUMN_LABEL = 'paymentAmount';
export const PAYMENT_STATUS_COLUMN_LABEL = 'paymentStatus';
export const GOVOS_PAYMENT_STATUS_COLUMN_LABEL = 'govOsPaymentStatus';
export const SIGNATURE_STATUS_COLUMN_LABEL = 'signatureStatus';
export const ID_VERIFICATION_STATUS_COLUMN_LABEL = 'idVerificationStatus';

export const UNEXPORTABLE_COLUMNS = [
  ASSIGNMENTS_COLUMN_LABEL,
  TAGS_COLUMN_LABEL,
  STAGES_COLUMN_LABEL,
  COMPLETION_COLUMN_LABEL,
  PAYMENT_AMOUNT_COLUMN_LABEL,
  PAYMENT_STATUS_COLUMN_LABEL,
];

export const formCapabilitiesEnum = {
  Archive: 'Archive',
  Delete: 'Delete',
  Edit: 'Edit',
  EditPermissions: 'EditPermissions',
  OrganizeStages: 'OrganizeStages',
  ArchiveSubmissions: 'ArchiveSubmissions',
};
