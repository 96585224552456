/*
 Utilities for rendering canvas smoothly on retina screens
 Adapted from https://github.com/jondavidjohn/hidpi-canvas-polyfill
 */

export const getPixelRatio = (context: CanvasRenderingContext2D | any) => {
  let backingStore = context.backingStorePixelRatio
    || context.webkitBackingStorePixelRatio
    || context.mozBackingStorePixelRatio
    || context.msBackingStorePixelRatio
    || context.oBackingStorePixelRatio
    || context.backingStorePixelRatio;
  if (typeof backingStore !== 'number') {
    backingStore = 1;
  }

  return (window.devicePixelRatio || 1) / backingStore;
};

export const setCanvasSize = (canvas: HTMLCanvasElement, pixelRatio: number) => {
  if (pixelRatio > 1) {
    canvas.style.height = `${canvas.height}px`;
    canvas.style.width = `${canvas.width}px`;
    canvas.width *= pixelRatio;
    canvas.height *= pixelRatio;
  }
};
