import React from 'react';
import { findDOMNode } from 'utils/reactDOM';
import CreateStage from '../CreateStage';


class CreateStageButton extends React.Component<any, any> {
  state = {
    shouldFocusWhenSelected: true,
  }

  componentDidMount() {
    this.stageCreatorRef = findDOMNode(this.refs.stageCreator);
  }

  // Take focus when 'isFocused' to capture the Enter key event to start creating
  componentDidUpdate(prevProps) {
    if (prevProps.isFocused === this.props.isFocused) return;
    if (!this.props.isFocused || !this.state.shouldFocusWhenSelected) return;
    this.stageCreatorRef && this.stageCreatorRef.focus();
  }

  stageCreatorRef;

  // Select itself when creation is started, otherwise if you didn't got to it by using the keyboard
  // pressing enter would select the current 'selectedStage'
  selectSelf = () => {
    // but when clicked, the input instanly appears, and we don't want to take the focus away from it
    this.setState({ shouldFocusWhenSelected: false });
    this.props.requestSelfSelection();
  }

  render() {
    return (
      <CreateStage
        inDropdown
        onCreate={this.props.onCreate}
        onStartCreation={this.selectSelf}
        isFocused={this.props.isFocused}
        ref='stageCreator' />
    );
  }
}

export default CreateStageButton;
