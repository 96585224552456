import React from 'react';
import styles from './FormInfo.css';
import moment from 'moment';


function FormInfo({form: {authorName, createdTime}}) {
  return (
    <div className={styles.container}>
      <span className={styles.label}>Form Created by</span>
      <span>{authorName}</span>
      <span className={styles.label}>Form Created on</span>
      <span>{moment(createdTime).format('MMM DD, YYYY H:mmA')}</span>
    </div>
  );
}

export default FormInfo;
