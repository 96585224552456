import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import jwtDecode from 'jwt-decode';
import classNames from 'classnames';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';

import injectRouter from 'decorators/injectRouter';
import NewAuthLayout from 'components/AuthLayout/NewAuthLayout';
import { getWindowSizeData } from 'components/WindowSize';
import Form from '../SignUp/Form/Form';
import FieldContainer from '../FieldContainer/FieldContainer';

import {
  coregistrationInfoReceived,
  getAccountKey,
  verifyCoregistrationJWT,
} from 'actions/auth';
import * as actions from 'actions/coregistration/coregistrationActions';

import {
  setCoregistrationToken,
  removeNamespacesFromProfile,
  getRegistrationForServer,
} from 'utils/auth';
import { LOGIN } from 'constants/route';

import style from './CoregSignUp.css';
import styles from '../SignUp/Form/Form.css';


export class CoregSignUp extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      coregistrationEmail: '',
      linkExpired: false,
      tokenInvalid: false,
      isResentInvitation: false,
    };
  }

  componentDidMount() {
    this.props.getAccountKey();
    const queryStringUrl = parse(window.location.search);
    const token = queryStringUrl.t && queryStringUrl.t;
    const email = queryStringUrl.email;

    if (!token) {
      if (email) {
        // eslint-disable-next-line react/no-did-mount-set-state
        return this.setState({ coregistrationEmail: email, linkExpired: true, tokenInvalid: false });
      }
      return this.redirectToLogin();
    }

    try {
      const decodedToken = removeNamespacesFromProfile(jwtDecode(token));
      if (typeof token === 'string') {
        setCoregistrationToken(token);
        this.props.coregistrationInfoReceived(
          decodedToken.maid,
          decodedToken.auid
        );
        this.props.verifyCoregistrationJWT(token);
      }
      // eslint-disable-next-line react/no-did-mount-set-state
      return this.setState({
        coregistrationEmail: decodedToken.emal,
        linkExpired: this.registrationLinkIsExpired(decodedToken.exp),
      });
    } catch (e) {
      // eslint-disable-next-line react/no-did-mount-set-state
      return this.setState({ coregistrationEmail: '', linkExpired: false, tokenInvalid: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.coregistration.didCoregistrationFail !== prevProps.coregistration?.didCoregistrationFail) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ coregistrationEmail: '' });
    }
  }

  registrationLinkIsExpired = expiresTimestamp =>
    Date.now() > expiresTimestamp * 1000;

  redirectToLogin() {
    this.props.router.replace(LOGIN);
  }

  getExpiredContent = (maid, subtitle = 'Your registration link expired.') => {
    const { account, coregistration } = this.props;
    const { isResentInvitation, coregistrationEmail, tokenInvalid } = this.state;
    const showEmailInput = tokenInvalid || coregistration.didCoregistrationFail;

    return (
      <NewAuthLayout
        title='Let’s set up your account.'
        subtitle={subtitle}
        fetching={
          account.isFetchingOrganizationInfo ||
          coregistration.isFetchingCoregistrationVerification
        }
        companyName={account.companyName}
        logo={account.organizationLogo}
      >
        {!isResentInvitation && (
          <Formik
            initialValues={{
              email: coregistrationEmail,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Please supply a valid email.')
                .required('This can’t be blank.'),
            })}
            onSubmit={values => {
              const { email } = values;

              this.setState({
                isResentInvitation: true,
              });
              this.props.actions.resendInvite({
                email,
                maid,
              });
            }}
          >
            {({ isValid }) => (
              <FormikForm>
                <div className={styles.emailContainer}>
                  <FieldContainer name='email' label={showEmailInput ? 'Email Address' : ''} isRequired={showEmailInput}>
                    {(field, meta) => (
                      <input
                        className={classNames(styles.input, {
                          [styles.invalidInput]: meta.touched && meta.error,
                        })}
                        type='text'
                        hidden={!showEmailInput}
                        {...field}
                      />
                    )}
                  </FieldContainer>
                </div>

                <div className={style.sendRegEmailBtnContainer}>
                  <button
                    className={classNames(style.submitBtn, {
                      [style.submitBtnInvalid]: !isValid,
                    })}
                    type='submit'
                    disabled={!isValid}
                  >
                    Send Registration Email
                  </button>
                </div>
              </FormikForm>
            )}
          </Formik>
        )}

        {isResentInvitation && (
          <div className={style.sendRegEmailTitle}>
            Your invite has been resent. Please check your email.
          </div>
        )}

        <div className={style.backToLogin}>
          <Link
            className={style.backToLogin}
            to={LOGIN}
          >
            Back to Login
          </Link>
        </div>
      </NewAuthLayout>
    );
  };

  onSubmit = state => {
    const subdomain = this.props.account.accountKey
      ? this.props.account.accountKey
      : '';

    this.props.actions.completeCoregistration(
      getRegistrationForServer(state),
      true,
      subdomain,
      this.props.dispatch
    );
  };

  render() {
    const { account, coregistration } = this.props;
    const { linkExpired, coregistrationEmail, tokenInvalid } = this.state;

    const maid = account.partialCoregistrationProfile?.maid || account.maid;

    if (maid && (tokenInvalid || coregistration.didCoregistrationFail)) {
      return this.getExpiredContent(maid, 'Your registration link is invalid.');
    }

    if (maid && linkExpired) {
      return this.getExpiredContent(maid);
    }

    const { isMobile } = getWindowSizeData();

    return (
      <NewAuthLayout
        title='Let’s set up your account.'
        subtitle={
          isMobile
            ? 'Create a login to access form submissions.'
            : 'You’ll be able to see the progress of your submissions, save your work to continue later, download your completed documents, and share your submissions with others.'
        }
        fetching={
          account.isFetchingOrganizationInfo ||
          coregistration.isFetchingCoregistrationVerification
        }
        companyName={account.companyName}
        logo={account.organizationLogo}
      >
        <div className={style.signUpForm}>
          <Form
            email={coregistrationEmail}
            isProcessing={coregistration.isCoregistrationProcessing}
            onCreate={data => {
              this.onSubmit({
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                password: data.password,
                passwordConfirm: data.password,
                phoneNumber: data.phoneNumber,
                timezone: data.timeZone,
              });
            }}
          />
        </div>
      </NewAuthLayout>
    );
  }
}

const mapStateToProps = ({
  auth,
  coregistration,
}


) => ({
  account: auth,
  coregistration,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  coregistrationInfoReceived: bindActionCreators(
    coregistrationInfoReceived,
    dispatch
  ),
  getAccountKey: () => dispatch(getAccountKey(dispatch)),
  verifyCoregistrationJWT: bindActionCreators(
    verifyCoregistrationJWT,
    dispatch
  ),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectRouter(CoregSignUp));
