/* Lobby Folder Text */
export const FOLDER_TREE_ROOT_NAME = 'Forms Manager';
export const FOLDER_TREE_ARCHIVE_NAME = 'Archive';

/* Lobby Folder Blank State */
export const FOLDER_BLANK_STATE = 'This folder is empty';

/* Submissions Manager Blank State */
export const SUBMISSIONS_BLANK_STATE = 'No submissions';
export const SUBMISSIONS_BLANK_STATE_FILTERED_BY_STAGE = 'No submissions for this stage';

/* Modal Messages Texts */
export const SEND_NOTIFICATION_LOADING_TITLE = 'Sending Notification';
export const SEND_NOTIFICATION_LOADING_BODY = email => `We are sending a notification to the email ${email}`;
export const SEND_NOTIFICATION_SUCCESS_TITLE = 'Congratulations';
export const SEND_NOTIFICATION_SUCCESS_BODY = 'The notification email has been successfully sent';
export const SEND_NOTIFICATION_FAILURE_TITLE = 'Notification Failed';
export const SEND_NOTIFICATION_FAILURE_BODY = 'Something went wrong, please try again later';

export const DOWNLOAD_REQUEST_LOADING_TITLE = 'Downloading';
export const DOWNLOAD_REQUEST_LOADING_BODY = 'We are preparing your request, please wait a moment';
export const DOWNLOAD_REQUEST_SUCCESS_TITLE = 'Congratulations';
export const DOWNLOAD_REQUEST_SUCCESS_BODY = 'Your request was successfully downloaded';

export const ARCHIVE_FORM_TITLE = 'Archive Form';
export const ARCHIVE_FORM_BODY = 'Are you sure you want to archive this form?';

export const DELETE_SM_RECORD_TITLE = 'Delete Submission';
export const DELETE_SM_RECORD_BODY = 'Are you sure you want to delete this submission?';

export const DELETE_SM_RECORD_TITLE_PLURAL = 'Delete Submissions';
export const DELETE_SM_RECORD_BODY_PLURAL = 'Are you sure you want to delete these submissions?';

export const EXPORT_SUBMISSION_SUCCESS_TITLE = 'Export Requested Successfully';
export const EXPORT_SUBMISSION_SUCCESS_BODY = email => `Your export file will be delivered to ${email} shortly`;

export const EXPORT_SUBMISSION_FAILURE_TITLE = 'Export Request Failed';
export const EXPORT_SUBMISSION_FAILURE_BODY = 'Oops, something went wrong. Please try again later.';

export const EXPORT_SUBMISSION_LOADING_TITLE = 'Requesting Submission Export';
export const EXPORT_SUBMISSION_LOADING_BODY = 'Please wait. Your request is being processed';

export const DELETE_FOLDER_TITLE = 'Delete Folder';
export const DELETE_FOLDER_BODY = 'All forms within this folder will be archived. Are you sure?';

export const COMPANY_NAME = 'GovOS';

export const QUICK_FILTERS_NAME = 'Filters';

export const EMAIL_ERROR_MESSAGE = 'Please input valid emails, separated by commas';

