import React from 'react';
import styles from './CreateTagFooter.css';
import { ENTER_KEY, _getKeyboardCode } from 'utils/keyboard';


export default class CreateTagFooter extends React.Component<any, any> {
  componentDidMount() {
    window.addEventListener('keydown', this.handleOnKeyDown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleOnKeyDown);
  }

  handleOnKeyDown = e => {
    if (_getKeyboardCode(e) !== ENTER_KEY || !this.props.isSelected) return;
    this.handleOnClick();
  }

  handleOnClick = () => this.props.onClick(this.props.text);

  render() {
    const { text = '', isSelected, footeref } = this.props;
    if (!text) return null;
    return (
      <div ref={footeref} onClick={this.handleOnClick} className={styles.container + `${isSelected ? ` ${styles.isSelected}` : ''}`}>
        <p className={styles.heading}>NEW TAG</p>
        <p className={styles.text}>{text}</p>
      </div>
    );
  }
}
