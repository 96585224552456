import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';
import { GoogleMap, InfoBox, Marker, useLoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '../../env';
import styles from './GoogleMaps.css';


// eslint-disable-next-line no-shadow
export enum MapTypeValue {
  ROADMAP = 'roadmap',
  SATELLITE = 'satellite',
  HYBRID = 'hybrid',
  TERRAIN = 'terrain'
}

export interface GoogleMapsProps {
  id: string;
  specialSettings: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
    mapWidth: number;
    mapHeight: number;
    zoom: number;
    mapType: MapTypeValue;
  };
}

const GoogleMaps = forwardRef<HTMLDivElement, GoogleMapsProps>((props, ref) => {
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadError, setLoadError] = useState<Error | null>(null);
  const [showInfoBox, setShowInfoBox] = useState(false); // New state
  const { id, specialSettings } = props;
  const { address, city, state, zipCode, mapWidth, mapHeight, zoom, mapType } = specialSettings;

  const { isLoaded: isMapsLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    region: 'us',
    authReferrerPolicy: 'origin',
  });

  const MEMOIZED_ADDRESS = useMemo(() => {
    let location: string = address;
    if (city && state && zipCode) {
      location = `${address}, ${city}, ${state}, ${zipCode}`;
    }
    return encodeURIComponent(location);
  }, [address, city, state, zipCode]);

  const fetchCoordinates = async() => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${MEMOIZED_ADDRESS}&key=${GOOGLE_MAPS_API_KEY}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch coordinates from address');
      }
      const data = await response.json();
      const { lat, lng } = data?.results[0]?.geometry?.location;
      setCenter({ lat, lng });
      setIsLoaded(true);
    } catch (error) {
      setLoadError(error);
    }
  };

  useEffect(() => {
    if (isMapsLoaded && !isLoaded) {
      fetchCoordinates();
    }
  }, [isMapsLoaded, isLoaded]);

  return (
    <div id={`Google_Maps_${id}`} ref={ref} >
      {!isLoaded && !loadError && <div className={styles.loading}>Loading...</div>}
      {isLoaded && !loadError && (
        <GoogleMap
          mapContainerStyle={{width: `${mapWidth}px`, height: `${mapHeight}px`}}
          center={center}
          zoom={zoom}
          mapTypeId={MapTypeValue[mapType] || MapTypeValue.ROADMAP}
        >
          <Marker
            aria-label='marker'
            position={center}
            onClick={() => setShowInfoBox(!showInfoBox)}
          />
          {showInfoBox && (
            <InfoBox
              options={{ closeBoxURL: '', enableEventPropagation: true,
                pixelOffset: new window.google.maps.Size(-85, -135),
              }}
              position={new window.google.maps.LatLng(center.lat, center.lng)}>
              <div
                className={styles.info_box_container} onClick={() => setShowInfoBox(false)}
                role='dialog'
                aria-modal='true'
                aria-labelledby='info-box-title'
                aria-describedby='info-box-description'>
                <div className={styles.info_box_content}>
                  <span>
                    <strong>Address:</strong>
                  </span>
                  <span>{address}</span>
                  <span>{city ? city + ',' : ''} {state || ''} {zipCode || ''}</span>
                </div>
              </div>
            </InfoBox>
          )}
        </GoogleMap>
      )}
    </div>
  );
});

export default memo(GoogleMaps);
