import React from 'react';
import Modal from './Modal';


const desktopWidths = {
  small: '500px',
};

const SmallModal = props => <Modal {...props} desktopWidth={desktopWidths.small} />;

SmallModal.defaultProps = {
  disableBackdropClick: false,
};

export default SmallModal;
