// export type KeywordFormatter = (keyword: Keyword) => React$Element<*> | string;

const splitWithIndex = (string = '', delimiter = ' ') => {
  const result: any[] = [];
  const splits = string.split(delimiter);
  const delimiterLength = delimiter.length;
  let index = 0;
  for (const split of splits) {
    result.push([index, split]);
    index += split.length + delimiterLength;
  }
  return result;
};

const makeSpaces = (count: number, collapsible = true) => {
  let result = '';
  const space = collapsible ? ' ' : '\u00a0';
  for (let i = 0; i < count; i++) {
    result += space;
  }
  return result;
};

const makeText = (index: number, word = '') => ({
  end: index + word.length,
  start: index,
  type: 'TEXT',
  value: word,
});

export function parsedIsEmpty(parsedInput: any[] = []) {
  return !parsedInput.some(data => {
    if (data.type === 'TEXT') {
      return !!data.value;
    }
    return true;
  });
}

export function parsedToStringLength(parsedInput: any[] = []) {
  return parsedInput.length ? parsedInput[parsedInput.length - 1].end : 0;
}

export function parsedToComponents(
  parsedInput: any[] = [],
  collapsible = false
) {
  const result = [''];
  let previousEndIndex = 0;
  for (const data of parsedInput) {
    result.push(makeSpaces(data.start - previousEndIndex, collapsible));
    switch (data.type) {
      case 'TEXT':
        result.push(data.value);
        break;
      default:
        break;
    }
    previousEndIndex = data.end;
  }
  return result;
}

export function parsedToString(parsedInput: any[] = []) {
  return parsedToComponents(parsedInput, true).join('');
}

const condense = (input: any[]) => parsedToString(input).split(' ').filter(Boolean).join('');

export function parsedIsEquivalent(inputA: any[], inputB: any[]) {
  // Tells us whether the only changes to search input have been whitespace
  return condense(inputA) === condense(inputB);
}

export function parse(input: string) {
  return splitWithIndex(input).map(([index, word]) =>
    makeText(index, word));
}
