import React from 'react';
import styles from './styles.css';
import fileIcon from 'icons/webform-icon-blue.svg';
import lockIcon from 'icons/lock-icon-green.svg';
import Loader from 'components/Loader';
import { TERMS_OF_USE_URL } from 'constants/links';
// import type {Node} from 'react';

// type Props = {|
//   fetching: boolean,
//   formName: string,
//   header: Node,
//   mainComponent: Node,
//   sidebar: ?Node,
// |};

export default class AuthLayout extends React.Component<any, any> {
  static defaultProps = { formName: '' };
  render() {
    const {
      fetching,
      formName,
      header,
      mainComponent,
      sidebar,
    } = this.props;

    if (fetching) return <Loader />;
    return (
      <div className={styles.pageWrapper}>
        <div className={styles.innerPageWrapper}>
          {
            !!formName &&
            <header className={styles.formNameHeader}>
              <div className={styles.formInfoLeft}>
                <img alt='' src={fileIcon} />
                <h2 className={styles.formName}>{formName}</h2>
              </div>
              <div className={styles.formInfoRight}>
                <img alt='' src={lockIcon} />
                <p className={styles.secure}>Secure</p>
              </div>
            </header>
          }
          <div className={styles.mainContent}>
            <div className={styles.blueCircle} />
            <div className={styles.orangeCircle} />
            {header}
            <div className={styles.container}>
              {mainComponent}
            </div>
            <a className={styles.termsOfService} href={TERMS_OF_USE_URL} target='_blank'>
              <span>Terms of Service</span>
            </a>
          </div>
        </div>
        {sidebar}
      </div>
    );
  }
}
