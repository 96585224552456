import React from 'react';
import MultiSelector from 'components/MultiSelector';
import AddAssigneeButton from './AddAssigneeButton';
import ShowOverflowButton from './ShowOverflowButton';
import TypeaheadInput from 'components/Users/UserTypeaheadInput';
import {renderUser} from 'utils/assignees';
// import type {User} from 'types/users';
// import type {Position} from 'components/Popover';

// type Props = {
//   assignees: User[],
//   CustomAddAssigneeButton?: React$ComponentType<*>,
//   isLoading: boolean,
//   isReadOnly: boolean,
//   maxElements: number,
//   onAddAssignees: (addedAssignees: User[]) => void,
//   onInputTypeahead: (searchValue: string) => void,
//   onRemoveAssignee: (removedAssignee: User) => void,
//   overflowButtonCountsAsElement?: boolean,
//   popoverOrigin?: Position,
//   typeaheadOffset?: number,
//   typeaheadResults: User[],
//   wideBubbles: boolean,
// };
class AssigneeMultiSelector extends React.Component<any, any> {
  static defaultProps = {
    isLoading: false,
    isReadOnly: false,
    maxElements: 3,
    typeaheadOffset: -20,
    wideBubbles: false,
  };

  render() {
    const {
      assignees,
      CustomAddAssigneeButton,
      isLoading,
      isReadOnly,
      wideBubbles,
      maxElements,
      overflowButtonCountsAsElement,
      onAddAssignees,
      onInputTypeahead,
      onRemoveAssignee,
      popoverOrigin,
      typeaheadOffset,
      typeaheadResults,
    } = this.props;
    return (
      <MultiSelector
        hidePeak={wideBubbles}
        isLoading={isLoading}
        isReadOnly={isReadOnly}
        maxElements={maxElements}
        overflowButtonCountsAsElement={overflowButtonCountsAsElement}
        onAddSelections={onAddAssignees}
        onInputTypeahead={onInputTypeahead}
        onRemoveSelection={onRemoveAssignee}
        popoverOrigin={popoverOrigin}
        renderSelection={renderUser(wideBubbles)}
        selections={assignees}
        ShowOverflowButton={ShowOverflowButton}
        ShowTypeaheadButton={CustomAddAssigneeButton || AddAssigneeButton}
        TypeaheadInput={TypeaheadInput}
        typeaheadOffset={typeaheadOffset}
        typeaheadResults={typeaheadResults}/>
    );
  }
}

export default AssigneeMultiSelector;
