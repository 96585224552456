import React, { useState, useEffect, useRef, forwardRef, Ref, useImperativeHandle, useCallback } from 'react';
import style from './CanvasSketch.css';
import { CANVAS_SKETCH_DEFAULT_VALUES, REQUIRED_MSG } from 'constants/liveViewPage';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import ColorPicker from './ColorPicker';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import { debounce } from 'lodash';
import { ReactSketchCanvas, ReactSketchCanvasRef, CanvasPath } from 'react-sketch-canvas';

export type Props = {
  id: string,
  label?: string,
  onChange?: Function,
  updateForm?: Function,
  extraData?: any,
  fieldState?: any,
  required?: boolean,
  specialSettings?: {
    backgroundImage?: string,
    isLabelVisible?: boolean,
  }
}

const CanvasSketch = ({
  id,
  label = CANVAS_SKETCH_DEFAULT_VALUES.label,
  fieldState,
  onChange = undefined,
  updateForm = undefined,
  required = false,
  specialSettings = {
    backgroundImage: '',
    isLabelVisible: true,
  },
}: Props, elementRef) => {
  const loadedPaths: CanvasPath[] | undefined = fieldState?.fields?.extraData?.paths;
  const ref: Ref<ReactSketchCanvasRef> | undefined = useRef(null);
  const [strokeWidth, setStrokeWidth] = useState<number>(10);
  const [brushColor, setBrushColor] = useState<string>('#333333');
  const [sketch, setSketch] = useState<string | null>(null);
  const [paths, setPaths] = useState<CanvasPath[] | []>([]);
  const [error, setError] = useState<string>('');
  const [imageDimensions, setImageDimensions] = useState({ width: -1, height: -1 });
  const { backgroundImage, isLabelVisible } = specialSettings;

  const loadImage = useCallback((imageUrl: string): void => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      let height: number = img.height;
      let width: number = img.width;
      if (width > 660) {
        const descale: number = 660 / width;
        width *= descale;
        height *= descale;
      }
      setImageDimensions({ height, width });
      if (loadedPaths) {
        ref.current?.loadPaths(loadedPaths);
      }
    };
    img.onerror = err => {
      window.console.error(err);
    };
  }, [loadedPaths]);

  useEffect(() => {
    if (backgroundImage) {
      loadImage(backgroundImage);
    }
    if (loadedPaths) {
      ref.current?.loadPaths(loadedPaths);
    }
  }, [backgroundImage, loadedPaths, loadImage]);

  useEffect(() => {
    onChange && onChange(sketch);
    const s = {
      fields: {
        [label]: sketch,
        extraData: {
          paths,
        },
      },
    };
    updateForm && updateForm(s);
  }, [sketch]);

  const selfValidate = useCallback((): boolean => {
    if (required && !paths.length) {
      setError(REQUIRED_MSG);
      return false;
    }

    setError('');
    return true;
  }, [required, paths]);

  useImperativeHandle(
    elementRef,
    () => ({
      validate: selfValidate,
    }));

  const getBackgroundSize = useCallback((): { backgroundSize: string } =>
    ({ backgroundSize: `${(strokeWidth * 100) / 20}% 100%` }), [strokeWidth]);

  const onCanvasChange = useCallback(debounce(() => {
    ref.current?.exportImage('png')
      .then((result: string) => setSketch(result));
    ref.current?.exportPaths()
      .then((result: CanvasPath[]) => setPaths(result));
  }, 1000), []);

  const renderCanvas = useCallback((): JSX.Element =>
    <ReactSketchCanvas
      ref={ref}
      width={backgroundImage ? `${imageDimensions.width}px` : '400px'}
      height={backgroundImage ? `${imageDimensions.height}px` : '400px'}
      strokeWidth={strokeWidth}
      strokeColor={brushColor}
      backgroundImage={backgroundImage}
      onChange={onCanvasChange}
    />, [ref, backgroundImage, imageDimensions.width, imageDimensions.height, strokeWidth, brushColor, onCanvasChange]);

  return (
    <div id={`container_${id}`} className={'form_control_group'}>
      <fieldset className={classNames(sharedStyle.Fieldset, 'choice_group', style.CanvasWrapper)} id={`canvas_sketch_${id}`}>
        {isLabelVisible &&
          <legend className={classNames({ [sharedStyle.Required]: required }, 'choice_group_label')}>
            {label}
          </legend>}
        <ErrorContainer error={error}>
          {backgroundImage ? imageDimensions.width > -1 && renderCanvas() : renderCanvas()}
        </ErrorContainer>
        <div className={style.Controls}>
          <div className={style.BrushColorContainer}>
            <span>Select a brush color:</span>
            <div>
              <div className={style.Swatch} style={{ backgroundColor: brushColor }} />
              <ColorPicker
                color={brushColor}
                onChangeComplete={color => setBrushColor(color?.hex)}
              />
            </div>
          </div>
          <div className={style.BrushWidthContainer}>
            <label htmlFor={`${id}_stroke_width`}>Select a stroke width:</label>
            <div>
              <input
                id={`${id}_stroke_width`}
                type='range'
                min={1}
                max={20}
                onChange={e => setStrokeWidth(Number.parseInt(e.target.value, 10))}
                value={strokeWidth}
                style={getBackgroundSize()}
              />
              <span
                style={{
                  height: strokeWidth,
                  width: strokeWidth,
                  borderRadius: strokeWidth,
                  backgroundColor: brushColor,
                }}
                className={style.Brush}
              />
            </div>
          </div>
        </div>
        <input
          type='button'
          onClick={() => ref.current?.clearCanvas()}
          value='Clear Canvas'
          className={style.ClearCanvasBtn}
          id='clear_canvas_btn'
        />
      </fieldset>
    </div>
  );
};

export default React.memo(forwardRef(CanvasSketch));
