import React from 'react';
import { memoize } from 'lodash';
import Typeahead from 'components/Typeaheads/Typeahead';
import UserTypeahead from 'components/Users/UserTypeahead';
import { RoleTypeaheadInput } from 'components/Users/UserTypeaheadInput';
import Role from 'components/Users/Role';
import { PERMISSION_USER_ROLES } from 'constants/user';
import styles from './styles.css';


const initialState = {
  inputValue: '',
};


class AddPermission extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  getRoleResults = memoize(alreadySelectedRoles => (
    PERMISSION_USER_ROLES.filter(role => !alreadySelectedRoles.includes(role))
  ))

  renderRole = props => (
    <Role
      isSelectedByKeyboard={props.isSelectedByKeyboard}
      onSelect={props.onSelect}
      selectionData={props.selectionData} />
  )

  handleTypeaheadInput = inputValue => {
    this.setState({ inputValue });
  }

  handleSelectRole = roleNames => {
    // TODO: Temporarily picking the first (and only) role in the selected roles list. See SA-795
    const roleName = roleNames[0];
    if (!roleName) return;
    this.props.addRolePermission(roleName);
    this.setState(initialState);
  }

  handleSelectUser = user => {
    this.props.handleSelectUser(user);
    this.setState(initialState);
  }

  render() {
    const {
      alreadySelectedRoles,
      filter,
      users,
    } = this.props;

    let typeahead;
    const roleResults = this.getRoleResults(alreadySelectedRoles);
    const showUsersTypeahead = roleResults.length === 0 || this.state.inputValue.length > 0;
    if (showUsersTypeahead) {
      const shouldAutoFocusInput = this.state.inputValue !== '';
      typeahead = (
        <UserTypeahead
          filter={filter}
          handleSelectUser={this.handleSelectUser}
          initialValue={this.state.inputValue}
          onTypeaheadInput={this.handleTypeaheadInput}
          shouldAutoFocusInput={shouldAutoFocusInput}
          users={users} />
      );
    } else {
      typeahead = (
        <Typeahead
          allowPickingMultipleItems={false}
          Input={RoleTypeaheadInput}
          onAddSelections={this.handleSelectRole}
          onInput={this.handleTypeaheadInput}
          renderSelection={this.renderRole}
          results={roleResults}
          shouldAutoFocusInput={false}
          showResultsInPopover />
      );
    }

    return (
      <div className={styles.addPermissionInput}>
        {typeahead}
      </div>
    );
  }
}

export default AddPermission;
