import React from 'react';
import Dropdown from 'components/Dropdown';
import AddSelectionButton from 'components/MultiSelector/shared/AddSelectionButton';
import {getStageItem, getRemoveButtonItem, getCreateStageButtonItem} from 'utils/stages';
import StageIcon from 'components/Icons/StageIcon';
import Tooltip from 'components/Tooltip';
import styles from './StageSelector.css';
import downArrowIcon from 'icons/stages/down-arrow.svg';

const renderAddStage = () => <AddSelectionButton Icon={StageIcon} caption='Click to add stage'/>;
const renderChangeStage = ({onMouseEnter, onMouseLeave}) => () => (
  <Tooltip title='Click to change stage'>
    <span className={styles.anchor} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <img src={downArrowIcon} alt='Change stage'/>
    </span>
  </Tooltip>
);

const StagesDropdown = props => {
  const {
    canCreate,
    dropdownOpen,
    onClearStage,
    onSelectStage,
    selectedStage,
    stages = [],
    requestClose,
    requestOpen,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const items = stages.map(stage => getStageItem(stage, onSelectStage, {isReadOnly: true}));
  if (selectedStage) items.unshift(getRemoveButtonItem(onClearStage));
  if (canCreate) items.push(getCreateStageButtonItem(onSelectStage));

  return (
    <Dropdown
      isOpen={dropdownOpen}
      items={items}
      requestClose={requestClose}
      requestOpen={requestOpen}
      renderTriggerButton={selectedStage ? renderChangeStage({onMouseEnter, onMouseLeave}) : renderAddStage}
      tabIndex='-1'/>
  );
};

export default StagesDropdown;
