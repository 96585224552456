import React, { FC, memo } from 'react';
import { SelectedRowDataType } from 'types/submissionsManager/tableTypes';
import styles from './SubmissionTable.css';
import { extractVerificationDetails } from 'utils/submissionsManager/tableHelper';

type Props = {
  record: SelectedRowDataType;
  columnKey: string;
};

// Utility function to remove the idVerificationStatus_ prefix using regex
const removeIdVerificationStatusPrefix = (columnKeyStr: string) => columnKeyStr?.replace(/^idVerificationStatus_/, '');

const IdVerificationCell: FC<Props> = memo(({ record, columnKey }) => {
  const formElementId = removeIdVerificationStatusPrefix(columnKey);
  const { firstName, lastName, verificationStatus } = extractVerificationDetails(record, formElementId);

  return (
    <div className={styles.cellContainer} role='status' aria-live='polite'>
      <span className={verificationStatus === 'COMPLETED' ? styles.verified : styles.notVerified}>
        {verificationStatus}
      </span>
      {verificationStatus === 'COMPLETED' && (
        <>
          <span>{firstName}</span>
          <span>{lastName}</span>
        </>
      )}
    </div>
  );
});

export default IdVerificationCell;
