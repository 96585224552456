import api from 'actions/api';
import { userRolesEnum } from 'constants/user';
import { GET_USERS } from 'constants/types/userActionTypes';

export const getAllUsers = (success: Function) =>
  api.postWithAuth({
    baseType: GET_USERS,
    data: {
      filter: {
        roles: [
          userRolesEnum.DIRECTOR,
          userRolesEnum.OWNER,
          userRolesEnum.PROCESSOR,
          userRolesEnum.VISITOR,
          userRolesEnum.ANALYST,
        ],
        isActive: [true, false],
      },
    },
    endpoint: 'users/hydrate.json',
    success,
    failure: (_, error) => ({ error }),
  });
