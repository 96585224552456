export {RESEND_INVITE} from 'constants/types/coregistration';
// There are three possible states for our login
// process and we need actions for each of them
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_USERNAME_PASSWORD = 'LOGIN_USERNAME_PASSWORD';

export const AUTH0_LOGIN_SUCCESS = 'AUTH0_LOGIN_SUCCESS';

// Three possible states for our logout process as well.
// Since we are using JWTs, we just need to remove the token
// from localStorage. These actions are more useful if we
// were calling the API to log the user out
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const UPLOAD_NEW_DOCUMENT_SUCCESS = 'UPLOAD_NEW_DOCUMENT_SUCCESS';
export const UPLOAD_NEW_DOCUMENT_FAILURE = 'UPLOAD_NEW_DOCUMENT_FAILURE';

export const ORGANIZATION_INFO_API = 'ORGANIZATION_INFO_API';
export const GET_INTERNAL_ID_TOKEN = 'GET_INTERNAL_ID_TOKEN';
export const USER_PROFILE_API = 'USER_PROFILE_API';
export const GET_MASTER_ACCOUNTS = 'GET_MASTER_ACCOUNTS';
export const GET_MASTER_ACCOUNTS_FROM_API = 'GET_MASTER_ACCOUNTS_FROM_API';
export const GET_ACCOUNT_KEY = 'GET_ACCOUNT_KEY';
export const GET_ACTIVE_DIRECTORY_CONNECTIONS = 'GET_ACTIVE_DIRECTORY_CONNECTIONS';

export const AUTHORIZATION_CHECK = 'AUTHORIZATION_CHECK';
export const AUTHORIZATION_CHECK_FAILURE = 'AUTHORIZATION_CHECK_FAILURE';
export const AUTHORIZATION_CHECK_SUCCESS = 'AUTHORIZATION_CHECK_SUCCESS';

export const RESET_HEADER_SNACKBAR = 'RESET_HEADER_SNACKBAR';

export const ORGANIZATION_INFO_LOADING = 'ORGANIZATION_INFO_LOADING';
export const SUBDOMAIN_EXISTS = 'SUBDOMAIN_EXISTS';

export const COREGISTRATION_INFO_RECEIVED = 'COREGISTRATION_INFO_RECEIVED';
export const VERIFY_COREGISTRATION = 'VERIFY_COREGISTRATION';

export const REGISTER_USER = 'REGISTER_USER';
export const COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION';

export const SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const SEND_VERIFY_EMAIL = 'SEND_VERIFY_EMAIL';
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';
export const VERIFY_ACCESS_CODE = 'VERIFY_ACCESS_CODE';
export const SET_SUBMISSION_VIEWER_ROUTE = 'SET_SUBMISSION_VIEWER_ROUTE';

export const SIGN_UP_VISITOR = 'SIGN_UP_VISITOR';
