import { convertToRaw } from 'draft-js';
import { isEqual } from 'lodash';
import { ensureAbsoluteURL } from './shared';
import { colorStyleMap } from './colorsForPicker';

const isIndexInRange = (index, offset, length) =>
  offset <= index && (offset + length) > index;

const lengthToNextEntity = (index, entityRanges) => {
  /* This method assumes we only want an actual number when we're not currently "in" an entity range */
  for (const {offset, length} of entityRanges) {
    if (offset > index) {
      return offset - index;
    }
    if (isIndexInRange(index, offset, length)) {
      return null;
    }
  }
  return null;
};

const entityDataAtIndex = (index, entityRanges, entityMap) => {
  for (const {key, length, offset} of entityRanges) {
    if (isIndexInRange(index, offset, length)) {
      return {
        entity: entityMap[key.toString()],
        length,
      };
    }
  }
  return null;
};

const styleMap = {
  BOLD: {fontWeight: 'bold'},
  ITALIC: {fontStyle: 'italic'},
  UNDERLINE: {textDecoration: 'underline'},
  ...colorStyleMap,
};

const inlineTagMap = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
};

const stylesAtIndex = (index, inlineStyleRanges) => {
  const styles = {};
  for (const {length, offset, style} of inlineStyleRanges) {
    if (isIndexInRange(index, offset, length)) {
      Object.assign(styles, styleMap[style]);
    }
  }
  return styles;
};

const tagTypeAtIndex = (index, inlineStyleRanges) => {
  for (const {length, offset, style} of inlineStyleRanges) {
    if (isIndexInRange(index, offset, length)) {
      if (inlineTagMap.hasOwnProperty(style)) {
        return inlineTagMap[style];
      }
    }
  }
  return 'text';
};

const textTags = (
  text,
  start,
  length,
  inlineStyleRanges,
) => {
  const tags: any[] = [];
  const textLength = start + length;
  for (let i = start; i < textLength; i++) {
    const char = text[i];
    const styles = stylesAtIndex(i, inlineStyleRanges);
    const tagType = tagTypeAtIndex(i, inlineStyleRanges);
    if (i > start) {
      const last = tags[tags.length - 1];
      if (last.tagType === tagType && last.styles && isEqual(styles, last.styles)) {
        last.text += char;
        continue;
      }
    }
    tags.push({tagType, styles, text: char});
  }
  return tags;
};

const sortByOffset = ranges => {
  ranges.sort(({offset: a}, {offset: b}) => {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    return 0;
  });
};

const inlineFromBlock = (block, entityMap) => {
  const {text} = block;
  const entityRanges = block.entityRanges || [];
  const inlineStyleRanges = block.inlineStyleRanges || [];
  sortByOffset(entityRanges);

  let index = 0;
  const tags: any[] = [];
  while (index < text.length) {
    const entityData = entityDataAtIndex(index, entityRanges, entityMap);
    if (entityData) {
      const {entity: {data, type}, length} = entityData;
      const linkTo = (data || {}).URL || text || '';

      if (type === 'LINK') {
        tags.push({
          tagType: 'link',
          linkTo: ensureAbsoluteURL(linkTo),
          text: textTags(text, index, length, inlineStyleRanges),
        });
      } else {
        tags.push(...textTags(text, index, length, inlineStyleRanges));
      }
      index += length;
    } else {
      const length = lengthToNextEntity(index, entityRanges) || (text.length - index);
      tags.push(...textTags(text, index, length, inlineStyleRanges));
      index += length;
    }
  }
  return tags;
};

const alignments = {
  'align-left': 'left',
  'align-center': 'center',
  'align-right': 'right',
};

const tagTypes = {
  'align-left': 'div',
  'align-center': 'div',
  'align-right': 'div',
  'code-block': 'pre',
  'header-five': 'h5',
  'header-four': 'h4',
  'header-one': 'h1',
  'header-six': 'h6',
  'header-three': 'h3',
  'header-two': 'h2',
  'ordered-list-item': 'orderedList',
  'unordered-list-item': 'unorderedList',
  atomic: 'div',
  blockquote: 'blockquote',
  paragraph: 'div',
  unstyled: 'div',
};

const imageFromEntityBlock = ({entityRanges}, entityMap) => {
  const image: any = {
    height: null,
    tagType: 'image',
    url: '',
    width: null,
  };

  if (entityRanges) {
    const {key} = entityRanges[0];
    const {data} = entityMap[key.toString()];

    if (data && data.src) {
      const {alignment, height, src, width} = data;
      image.url = src;
      image.height = height || null;
      image.width = width || null;
      if (alignment !== 'default' && alignment != null) {
        image.alignment = alignment;
      }
    }
  }

  return image;
};

const rawToAnnotateds = ({blocks, entityMap}) =>
  /*
   * This method produces an intermediate format in which list items are still separate blocks
   * (stored in `AnnotatedListItem` objects so the next phase knows how to roll them up).
   */
  blocks.map(block => {
    const {type} = block;

    switch (type) {
      case 'ordered-list-item':
      case 'unordered-list-item':
        return {
          type,
          styles: {},
          depth: block.depth,
          children: inlineFromBlock(block, entityMap),
        };
      case 'atomic':
        const imageProperties = imageFromEntityBlock(block, entityMap);
        return {
          type: 'block',
          ...imageProperties,
        };
      default:
        const styles = ~['align-left', 'align-center', 'align-right'].indexOf(type)
          ? {textAlign: alignments[type]}
          : {};
        return {
          children: inlineFromBlock(block, entityMap),
          styles,
          tagType: tagTypes[type],
          type: 'block',
        };
    }
  });

const listTypes = {
  'ordered-list-item': 'orderedList',
  'unordered-list-item': 'unorderedList',
};

const addItemToLowerDepth = (stack, item, tagType) => {
  const newList = {tagType, children: [item]};
  stack[0].children.push(newList);
  stack.unshift(newList);
};

const addItemToCurrentDepth = (stack, item, tagType) => {
  if (tagType === stack[0].tagType) {
    stack[0].children.push(item);
  } else {
    stack.shift();
    addItemToLowerDepth(stack, item, tagType);
  }
};

const addItemToDepth = (
  stack,
  listItem,
  tagType,
  depth,
  currentDepth
) => {
  const delta = depth - currentDepth;
  if (delta === 0) {
    addItemToCurrentDepth(stack, listItem, tagType);
  } else if (delta > 0) {
    for (let d = 1; d < delta; d++) {
      addItemToLowerDepth(stack, {tagType, children: []}, tagType);
    }
    addItemToLowerDepth(stack, listItem, tagType);
  } else {
    for (let d = 0; d > delta; d--) {
      stack.shift();
    }
    addItemToCurrentDepth(stack, listItem, tagType);
  }
};

const extractAnnotation = item => {
  const {type, depth, ...listItem} = item;
  return {
    tagType: listTypes[type],
    depth,
    listItem,
  };
};

const rollUpList = (annotated, startingOffset) => {
  /*
   * This method takes the annotated list of blocks that we've processed from "raw" format.
   * It produces a single List tree, as well as the offset that the caller should jump to in
   * the annotated list.
   *
   * We traverse the contiguous range of AnnotatedListItems starting at startingOffset.
   * We maintain a stack of lists to keep track of our position in the recursive structure
   * that we create for the api List format.
   *  - We assume that the annotated item at startingOffset is an AnnotatedListItem.
   *  - If we come accross an AnnotatedListItem in our contiguous range with a depth of 0
   *    and a different tagType, we need to end the rollup, since it necessitates a new tree.
   *  - It is possible to "jump" multiple depth levels at a time, i.e. a list entry at depth 1
   *    can have a list entry at depth 4 immediately beneath (or depth 4 with depth 1 beneath).
   */
  let {tagType, depth, listItem} = extractAnnotation(annotated[startingOffset]);
  let currentOffset = startingOffset;
  const rootTagType = tagType;
  let stack;
  if (tagType === 'orderedList' || tagType === 'unorderedList') {
    stack = [{tagType, children: []}];
  } else {
    // annotated item has unrecognized type; skip over it.
    return {offset: startingOffset + 1};
  }
  let currentDepth = depth;
  while (
    (tagType === 'orderedList' || tagType === 'unorderedList')
    && (typeof depth === 'number')
    && (typeof currentDepth === 'number')
    && (depth > 0 || tagType === rootTagType)
  ) {
    addItemToDepth(stack, listItem, tagType, depth, currentDepth);

    if (++currentOffset === annotated.length) {
      break;
    }
    currentDepth = depth;
    ({tagType, depth, listItem} = extractAnnotation(annotated[currentOffset]));
  }
  return {
    list: stack[stack.length - 1],
    offset: currentOffset,
  };
};

const annotatedHasContent = annotated => {
  // Images and Lists are always considered content.
  // Blocks must have children to be considered content.
  if (annotated.type === 'block') {
    return (annotated.tagType === 'image') || !!annotated.children.length;
  }
  return true;
};

const annotatedsHasContent = annotateds => {
  if (annotateds.length) {
    if (annotateds.length > 1 || annotatedHasContent(annotateds[0])) {
      return true;
    }
  }
  return false;
};

const annotatedsToStructure = annotateds => {
  if (!annotatedsHasContent(annotateds)) {
    return null;
  }
  const structure: any[] = [];
  let i = 0;
  while (i < annotateds.length) {
    const annotated = annotateds[i];
    const {type, ...rest} = annotated;
    if (type === 'block') {
      if (annotatedHasContent(annotated)) {
        structure.push(rest);
      } else {
        structure.push({tagType: 'br'});
      }
      i++;
    } else {
      const {offset, list} = rollUpList(annotateds, i);
      i = offset;
      if (list) {
        structure.push(list);
      }
    }
  }
  return structure;
};


export const rawToStructure = ({blocks, entityMap}) => {
  const annotated = rawToAnnotateds({blocks, entityMap});
  return annotatedsToStructure(annotated);
};

export const convertToStructure = editorState => {
  const raw = convertToRaw(editorState.getCurrentContent());
  return rawToStructure(raw);
};
