import classNames from 'classnames';
import * as React from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import sharedStyles from '../shared.css';
import { LabelAlignment } from 'types/liveView';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';

interface Props {
  id: string,
  label: string,
  cssClass?: string,
  tag: keyof JSX.IntrinsicElements
  extraData?: {
    alignment: LabelAlignment,
  }
}

function FormHeading({
  id,
  label,
  cssClass = '',
  tag: Tag = 'h1',
  extraData = {
    alignment: 'left',
  },
} : Props, ref: React.Ref<unknown>) : JSX.Element | null {
  useImperativeHandle(
    ref,
    () => ({
      validate: () => true,
    }));

  return label ?
    (<Tag
      id={id}
      className={classNames(cssClass, 'form_heading', sharedStyles[Tag] ?? '', getAlignmentClass(extraData?.alignment, sharedStyles))}>
      {label}
    </Tag>) : null;
}

const exported = React.memo(forwardRef(FormHeading)) as unknown as
  (typeof FormHeading & {willBeNull: Function});

exported.willBeNull = ({ label }) => !label;

export default exported;
