import React from 'react';
import Icon from '@material-ui/core/Icon';
import {grey700} from 'utils/colors';
import styles from './Sidebar.css';

const headerIcon = ({icon, color = grey700}) => {
  if (!icon) return null;

  const content = typeof icon === 'string'
    ? <Icon style={{color}}>{icon}</Icon>
    : icon;

  return <span className={styles.headerIconWrapper}>{content}</span>;
};

export default headerIcon;
