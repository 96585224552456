import React from 'react';
import { folderItemType, formItemType } from 'components/FolderTree/Node';
import NodeDragPreview from 'components/FolderTree/NodeDragPreview';
import FormDragPreview from 'components/FormsTable/FormDragPreview';
import { DragLayer } from 'react-dnd';
// import * as VFS from 'utils/vfs';


const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
};

class LobbyDragLayer extends React.Component<any, any> {
  renderItem(type, item) {
    switch (type) {
      case folderItemType:
        return <NodeDragPreview tree={((item)).tree} />;
      case formItemType:
        const {
          rowData,
        } = ((item));
        return <FormDragPreview rowData={rowData} />;
      default:
        return null;
    }
  }

  render() {
    const {
      item,
      itemType,
      isDragging,
      currentOffset,
    } = this.props;

    if (!isDragging) {
      return null;
    }

    let itemStyle;
    if (!currentOffset) {
      itemStyle = {
        display: 'none',
      };
    } else {
      const { x, y } = currentOffset;
      const transform = `translate(${x}px, ${y}px)`;
      itemStyle = {
        transform,
        WebkitTransform: transform,
      };
    }

    return (
      <div style={layerStyles as any}>
        <div style={itemStyle}>{this.renderItem(itemType, item)}</div>
      </div>
    );
  }
}

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
}

export default DragLayer(collect)(LobbyDragLayer);
