import React from 'react';
import { some } from 'lodash';


const FOCUS_TRAP_EXEMPTION_SELECTORS = [
  '.mui-fixed', '.sd-typeahead', '#typeaheadInput', '#stageInput', // Used by MUI popover for typeheads, etc.
];

type Props = {
  root: HTMLElement | null,
};

export default class FocusTrap extends React.Component<Props, any> {
  componentDidMount() {
    this.trapFocus();
    document.addEventListener('focus', this.onBlur, true);
  }

  componentWillUnmount() {
    document.removeEventListener('focus', this.onBlur, true);
    this.timer && clearTimeout(this.timer);
  }

  timer: NodeJS.Timeout | null = null

  contains(parent, element) {
    return !!(parent && parent.contains(element));
  }

  focus = () => {
    const validFocusLocations = this.getValidFocusLocations();
    const isFocusValid = some(validFocusLocations, parent => this.contains(parent, document.activeElement));
    if (this.props.root && !isFocusValid) {
      this.props.root.focus();
    }
  }

  getValidFocusLocations() {
    // these are slow dom operations but these exemptions are added and removed from the DOM sometimes so we can't cache
    const focusTrapExemptions = FOCUS_TRAP_EXEMPTION_SELECTORS.map(selector => document.querySelector(selector));
    return focusTrapExemptions.concat(this.props.root);
  }

  trapFocus() {
    this.timer && clearTimeout(this.timer);
    setTimeout(this.focus, 0);
  }

  onBlur = event => {
    const validFocusLocations = this.getValidFocusLocations();
    if (!some(validFocusLocations, parent => this.contains(parent, event.target))) {
      event.preventDefault();
      this.trapFocus();
    }
  }

  render() {
    return null;
  }
}
