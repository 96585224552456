import React, { PureComponent } from 'react';
import { colors, ensureValidColorNumber, isColorSelectable } from 'utils/stageColors';
import styles from './ColorPicker.css';
import ColorItem from './ColorItem';


export default class ColorPicker extends PureComponent<any, any> {
  renderColors = () => colors.map((hex, index) => {
    const colorNumber = ensureValidColorNumber(index);
    if (!isColorSelectable(colorNumber)) return null;
    return (
      <ColorItem
        color={colorNumber}
        isSelected={this.props.value === index}
        key={index}
        onSelect={this.handleOnSelect} />
    );
  })

  handleOnSelect = color => this.props.onChange(color)

  render() {
    return (
      <div className={styles.colorPickerContainer}>
        <p>Change Color</p>
        <ul className={styles.colorList}>
          {this.renderColors()}
        </ul>
      </div>
    );
  }
}
