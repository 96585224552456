import React from 'react';
import { connect } from 'react-redux';
import { changeRuleAssignments } from 'actions/automatedProcessBuilder';
import AssigneeMultiSelector from 'components/Assignees/AssigneeMultiSelector';
import {
  filterToEligibleAssignees,
  filterOutSelectedAssignees,
  filterToAssigneesBySearchString,
} from 'utils/assignees';
import { getUserMap } from '../../../reducers';
import {
  triggerTypeEnum,
} from 'constants/triggers';
import { getAssigneesFromRule, getOtherAssigneesFromRule } from 'utils/automatedProcessBuilder';


class AssignmentTrigger extends React.Component<any, any> {
  static defaultProps = {
    isLoading: false,
  }

  state = {
    typeaheadResults: [],
  }

  handleAddAssignees = addedAssignees => {
    const {
      formId,
      rule,
      selectedAssignees,
      triggerType,
    } = this.props;
    if (triggerType === triggerTypeEnum.IF) {
      const newAssigneeId = addedAssignees[0].userId;
      this.props.changeRuleAssignments(formId, rule.ruleId, triggerType, [newAssigneeId]);
    } else {
      const updatedAssignees = addedAssignees.concat(selectedAssignees);
      const updatedAssigneeIds = updatedAssignees.map(assignee => assignee.userId);
      this.props.changeRuleAssignments(formId, rule.ruleId, triggerType, updatedAssigneeIds);
    }
  }

  handleInputTypeahead = searchValue => {
    const {
      eligibleAssignees,
      selectedAssignees,
      otherSelectedAssignees,
    } = this.props;
    const allSelectedAssignees = [...selectedAssignees, ...otherSelectedAssignees];
    const allSelectedAssigneeIds = allSelectedAssignees.map(selectedAssignee => selectedAssignee.userId);
    const assigneesAvailableToSelect = filterOutSelectedAssignees(allSelectedAssigneeIds, eligibleAssignees);
    const searchResults = filterToAssigneesBySearchString(assigneesAvailableToSelect, searchValue);
    this.setState({
      typeaheadResults: searchResults,
    });
  }

  handleRemoveAssignee = removedAssignee => {
    const {
      formId,
      rule,
      selectedAssignees,
      triggerType,
    } = this.props;
    const updatedAssignees = selectedAssignees.filter(
      selectedAssignee => selectedAssignee.userId !== removedAssignee.userId,
    );
    const updatedAssigneeIds = updatedAssignees.map(assignee => assignee.userId);
    this.props.changeRuleAssignments(formId, rule.ruleId, triggerType, updatedAssigneeIds);
  }

  render() {
    const {
      isReadOnly,
      selectedAssignees,
    } = this.props;
    const useWideBubbles = selectedAssignees.length < 2;
    return (
      <AssigneeMultiSelector
        assignees={selectedAssignees}
        isLoading={false}
        isReadOnly={isReadOnly}
        maxElements={2}
        onAddAssignees={this.handleAddAssignees}
        onInputTypeahead={this.handleInputTypeahead}
        onRemoveAssignee={this.handleRemoveAssignee}
        typeaheadResults={this.state.typeaheadResults}
        wideBubbles={useWideBubbles} />
    );
  }
}

const mapStateToProps = (state, { rule, triggerType }) => {
  const users = getUserMap(state);
  return {
    eligibleAssignees: filterToEligibleAssignees(getUserMap(state)),
    selectedAssignees: getAssigneesFromRule(rule, triggerType, users),
    otherSelectedAssignees: getOtherAssigneesFromRule(rule, triggerType, users),
  };
};

const mapDispatchToProps = {
  changeRuleAssignments,
};


export default connect(mapStateToProps, mapDispatchToProps)(AssignmentTrigger);
