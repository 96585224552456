import React from 'react';
import {recipientTypeEnum} from 'constants/triggers';
import EmailFieldsPicker from './EmailFields';
import CustomEmailPicker from './CustomEmail';
import SignersPicker from './Signers';
import UserPicker from './SystemUser';


const Picker = ({type, ...props}) => {
  switch (type) {
    case recipientTypeEnum.EMAIL_FIELD:
      return <EmailFieldsPicker {...props} />;
    case recipientTypeEnum.SIGNER:
      return <SignersPicker {...props}/>;
    case recipientTypeEnum.STATIC:
      return <CustomEmailPicker {...props as any} />;
    case recipientTypeEnum.SYSTEM_USER:
      return <UserPicker {...props} />;
    case recipientTypeEnum.SUBMITTER:
    case recipientTypeEnum.ASSIGNED_USER:
    default:
      return null;
  }
};

export default Picker;
