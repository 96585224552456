import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import style from './CheckAndText.css';
import sharedStyle from '../shared.css';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { ControlState, LabelAlignment } from 'types/liveView';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';
import FormInput from '../FormInput/FormInput';
import ErrorContainer from '../ErrorContainer/ErrorContainer';

export type Props = {
  elementType?: string,
  id: string,
  label?: string,
  fieldState?: ControlState | null,
  updateForm?: Function | null,
  cssClass?: string,
  specialSettings?: {
    showLabel: boolean,
    option: string,
  },
  extraData?: {
    readonly: 't' | 'f',
    labelAlign: LabelAlignment,
  }
  required?: boolean,
  hoverText?: string,
  isDisabled?: boolean,
};

const CheckAndText = ({
  id,
  elementType,
  label = 'Check And Text',
  fieldState,
  cssClass = sharedStyle.FormControlGroup,
  specialSettings = {
    showLabel: true,
    option: 'Yes',
  },
  extraData = {
    readonly: 'f',
    labelAlign: 'auto',
  },
  required = false,
  isDisabled = false,
  updateForm,
  hoverText = ''}: Props, ref) => {
  const [checked, setChecked] = useState<boolean>(fieldState?.fields[label].checked || false);
  const [textVal, setTextVal] = useState<string>(fieldState?.fields[label].text || '');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const s = {
      fields: {
        [label]: {
          checked,
          text: textVal,
          option: specialSettings?.option,
        },
      },
      extraData: {
        elementType,
      },
    };
    updateForm && updateForm(s);
  }, [checked, textVal]);

  const selfValidate = () => {
    if (required && !checked) {
      setError('This field is required.');
      return false;
    }

    setError('');
    return true;
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  const onCheckboxChange = e => {
    setChecked(e.target.checked);
    if (required && e.target.checked) {
      setError('');
    }
  };

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <div className={classNames(cssClass, 'form_control_group')} id={`container_${id}`}>
        <fieldset id={id} className={sharedStyle.Fieldset}>
          { label && specialSettings?.showLabel &&
            <legend
              className={classNames(
                getAlignmentClass(extraData?.labelAlign, sharedStyle),
                {[sharedStyle.Required]: required })}
            >{label}</legend> }
          <div className={style.CheckAndTextContainer}>
            <ErrorContainer error={error}>
              <div>
                <input
                  id={`checkbox_${id}`}
                  type='checkbox'
                  checked={checked}
                  onChange={onCheckboxChange}
                  className={style.Checkbox}
                  required={required}
                  disabled={isDisabled}
                  onBlur={selfValidate}
                />
                <label
                  htmlFor={`checkbox_${id}`}
                  className={style.Text}>{specialSettings?.option}</label>
              </div>
            </ErrorContainer>
            <FormInput
              id={`text_${id}`}
              type='text'
              value={textVal}
              onChange={e => setTextVal(e.target.value)}
              showLabel={false}
              extraData={{ showLabel: 'f', readonly: extraData?.readonly }}
              isDisabled={isDisabled}
            />
          </div>
        </fieldset>
      </div>
    </Tooltip>
  );
};

export default React.memo(forwardRef(CheckAndText));
