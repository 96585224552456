import React from 'react';

import styles from './ClosedDropdown.css';
import { joinClassNames } from 'utils/strings';

import chevronIcon from 'icons/icon79.svg';

export type Props = {
  children: React.ReactNode,
  className?: string,
  closedDropdownRef?: React.MutableRefObject<any>,
  isEnabled: boolean,
  onBlur?: () => void,
};

const ClosedDropdown = ({
  children,
  className,
  closedDropdownRef,
  isEnabled,
  onBlur,
}: Props) => (
  <span
    className={joinClassNames(
      styles.closedButtonAppearance,
      !isEnabled ? styles.isDisabled : '',
      className
    )}
    tabIndex={isEnabled ? 0 : undefined}
    role='button'
    ref={closedDropdownRef}
    onBlur={onBlur}
  >
    {children}
    <img
      alt=''
      className={joinClassNames(
        styles.closedButtonChevronAppearance,
        styles.closedButtonChevronLayout,
      )}
      src={chevronIcon} />
  </span>
);

ClosedDropdown.defaultProps = {
  isEnabled: true,
};

export default ClosedDropdown;
