import { CompositeDecorator } from 'draft-js';
import Link from 'components/RichTextEditor/Link';

export const isLink = (contentState, entityKey) =>
  !!entityKey && contentState.getEntity(entityKey).getType() === 'LINK';

export const linkFilter = (contentState, char) => {
  const key = char.getEntity();
  return key !== null && isLink(contentState, key);
};

const findLinkEntities = (
  contentBlock,
  callback,
  contentState
) => contentBlock.findEntityRanges(char => linkFilter(contentState, char), callback);

export const decorators = [{
  strategy: findLinkEntities,
  component: Link,
}];

export const createDecorator = () => new CompositeDecorator(decorators);
