import {LOBBY_ROOT_FOLDER, LOBBY_ARCHIVED_FOLDER} from 'constants/tableConsts';
import api from '../api';
import {root, archive} from 'utils/lobby/folders';

import {
  GET_DIR_JSON_API,
  SELECT_FOLDER,
  EXPAND_FOLDER,
  COLLAPSE_FOLDER,
  ARCHIVE_FOLDER,
  RESTORE_FOLDER,
  UPSERT_FOLDER,
  FORM_INTO_FOLDER,
  GET_FOLDER_BREADCRUMBS,
} from 'constants/types/lobbyFolderNavigationActionTypes';

import {SELECT_QUICK_FILTER} from 'constants/types/quickFilterActionTypes';
import { Dir, Path } from 'utils/vfs';
import { FolderNavigationState, Action } from 'types/lobby/folderNavigation';

const initialState: FolderNavigationState = {
  archive,
  currentNode: root,
  currentPath: [''],
  error: {},
  failure: false,
  requestFormIntoFolder: false,
  root,
};

function refreshFolderTree(
  state: FolderNavigationState,
  currentNode?: Dir | null,
  rootTree?: Dir,
): FolderNavigationState {
  const newRoot = rootTree || state.root.copy();
  const newArchive = state.archive.copy();
  let newCurrentNode;

  if (currentNode) {
    newCurrentNode = currentNode;
  } else if (state.currentNode.meta.folderId === LOBBY_ROOT_FOLDER) {
    newCurrentNode = newRoot;
  } else if (state.currentNode.meta.folderId === LOBBY_ARCHIVED_FOLDER) {
    newCurrentNode = newArchive;
  } else {
    newCurrentNode = newRoot.getChildAtPath(state.currentPath);
  }

  return {
    ...state,
    currentPath: newCurrentNode ? newCurrentNode.path() : state.currentPath,
    currentNode: newCurrentNode || state.currentNode,
    root: newRoot,
  };
}

export default function folderNavigation(state: FolderNavigationState = initialState, action: Action) {
  switch (action.type) {
    case GET_DIR_JSON_API:
      const {error} = action;
      return api(action, state, {
        success: () => {
          if (error) {
            return {...initialState, error};
          }
          return refreshFolderTree(state, null, action.payload);
        },
        failure: () => ({...state, error}),
      });
    case SELECT_FOLDER:
      return {...state, currentPath: action.path, currentNode: action.node};
    case EXPAND_FOLDER:
      return refreshFolderTree(state);
    case COLLAPSE_FOLDER:
      return refreshFolderTree(state);
    case ARCHIVE_FOLDER:
      return {...refreshFolderTree(state), archive: state.archive.copy()};
    case RESTORE_FOLDER:
      return {...refreshFolderTree(state), archive: state.archive.copy()};
    case UPSERT_FOLDER:
      return api(action, state, {
        pending: () => refreshFolderTree(state, action.payload.newDir),
        success: () => refreshFolderTree(state),
        failure: () => refreshFolderTree(state),
      });
    case FORM_INTO_FOLDER:
      return api(action, state, {
        pending: () => ({
          ...state,
          requestFormIntoFolder: true,
          requestedFormIndex: action.payload.requestedFormIndex,
        }),
        success: () => ({
          ...state,
          requestFormIntoFolder: false,
          requestedFormIndex: action.payload.requestedFormIndex,
        }),
        failure: () => ({
          ...state,
          error: action.payload.error,
          failure: true,
          requestFormIntoFolder: false,
        }),
      });
    case SELECT_QUICK_FILTER:
      return {
        ...state,
        currentNode: root,
        currentPath: [''],
      };
    case GET_FOLDER_BREADCRUMBS:
      return api(action, state, {
        success: () => {
          const {rootFromBreadcrumbs, newCurrentNode, path} = action.payload;
          const newRoot: Dir = state.root;
          let prevNewRootNode: Dir = newRoot;
          let prevRootNode: Dir = rootFromBreadcrumbs;
          for (let i = 1; i < path.length; i++) {
            const node: Dir = prevNewRootNode.getChild(path[i]);
            const nodeToInsert: Dir = prevRootNode.getChild(path[i]);
            if (node) {
              prevNewRootNode = node;
              prevRootNode = nodeToInsert;
            } else {
              prevNewRootNode = prevNewRootNode.add(nodeToInsert);
              prevRootNode = nodeToInsert;
            }
          }
          return {
            ...state,
            currentPath: path,
            currentNode: newCurrentNode,
            root: newRoot,
          };
        },
        failure: () => ({...state, error}),
      });
    default:
      return state;
  }
}

export const getCurrentFolderId = (state: FolderNavigationState): string => state.currentNode.meta.folderId;

export const getCurrentFolderPath = (state: FolderNavigationState): Path => state.currentPath;
