import React from 'react';
import {connect} from 'react-redux';

import styles from './AutomatedProcessBuilder.css';

import {checkFormCapability, getFeatureState, getStagesList} from '../../reducers';
import {changeRuleTrigger} from 'actions/automatedProcessBuilder';
import Dropdown from 'components/Dropdown';
import ClosedDropdown from 'components/Dropdown/ClosedDropdown';
import TriggerItem from './TriggerItem';
import {formCapabilitiesEnum} from 'constants/tableConsts';
import {getPossibleTriggersForApb} from 'utils/triggers';

import {triggerTypeEnum} from 'constants/triggers';
// import type {Item} from 'components/Dropdown';
// import type {Features} from 'types/features';
// import type {State as ReduxState} from 'types/shared';
// import type {
//   ApbTrigger,
//   ApbRuleTrigger,
//   ChangeRuleTriggerActionCreator,
//   TriggerType,
// } from 'types/automatedProcessBuilder';


// type PropsFromDispatch = {
//   changeRuleTrigger: ChangeRuleTriggerActionCreator,
// };

// type PropsFromState = {
//   features: Features,
//   isStageSelectorUseful: boolean,
// };

// export type Props = {
//   formId: string,
//   isEnabled?: boolean,
//   isNew: boolean,
//   ruleId: string,
//   trigger: ApbTrigger,
//   triggerType: TriggerType,
// } & PropsFromDispatch &
//   PropsFromState;

// type State = {|
//   isOpen: boolean,
// |};

export class TriggerDropdown extends React.PureComponent<any, any> {
  static defaultProps = {
    isEnabled: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.availableTriggerFeatures = getPossibleTriggersForApb(
      props.features,
      props.triggerType,
      props.isStageSelectorUseful,
    );
  }

  componentDidMount() {
    if (this.props.isNew && this.props.triggerType === triggerTypeEnum.IF) {
      const closedDropdownRef = this.closedDropdownRef.current;
      closedDropdownRef && closedDropdownRef.focus();
    }
  }

  availableTriggerFeatures;

  closedDropdownRef = React.createRef<HTMLElement>();

  createChangeRuleTriggerAction = () => {
    const {formId, ruleId, triggerType} = this.props;
    return ruleTrigger => {
      const trigger = ruleTrigger ? ruleTrigger.trigger : null;
      this.props.changeRuleTrigger(formId, ruleId, trigger, triggerType);
      this.closeDropdown();
    };
  };

  getItems = () => {
    const items = this.availableTriggerFeatures.map(trigger => ({
      Component: TriggerItem,
      props: {
        data: {
          trigger,
          triggerType: this.props.triggerType,
        },
        onClick: this.createChangeRuleTriggerAction(),
        isCurrentSelection: trigger === this.props.trigger,
      },
    }));
    return items;
  };

  renderTriggerButton = () => {
    const {isEnabled, trigger, triggerType} = this.props;
    const data = {
      trigger,
      triggerType,
    };
    return (
      <ClosedDropdown
        closedDropdownRef={this.closedDropdownRef}
        isEnabled={isEnabled}
        className={styles.dropdownTrigger}>
        <TriggerItem data={data} isCurrentSelection={false} isFocused={false}/>
      </ClosedDropdown>
    );
  };

  closeDropdown = () => {
    this.setState({isOpen: false}, () => {
      const closedDropdownRef = this.closedDropdownRef.current;
      if (closedDropdownRef) closedDropdownRef.focus();
    });
  };

  handleRequestClose = () => this.closeDropdown();
  handleRequestOpen = () => this.setState({isOpen: true});

  render() {
    return (
      <Dropdown
        isEnabled={this.props.isEnabled}
        isOpen={this.state.isOpen}
        items={this.getItems()}
        requestClose={this.handleRequestClose}
        requestOpen={this.handleRequestOpen}
        renderTriggerButton={this.renderTriggerButton}
        tabIndex='-1'/>);
  }
}

const mapStateToProps = (state, {formId}) => {
  const canCreateStage = state.forms ? checkFormCapability(state, formId, formCapabilitiesEnum.OrganizeStages) : true;
  const formHasStages = getStagesList(state, formId) && getStagesList(state, formId).length > 0;
  return {
    isStageSelectorUseful: canCreateStage || formHasStages,
    features: getFeatureState(state),
  };
};

const mapDispatchToProps = {
  changeRuleTrigger,
};

export default connect(mapStateToProps, mapDispatchToProps)(TriggerDropdown);
