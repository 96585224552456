import {Observable} from 'rxjs/Observable';
import {AUTHORIZATION_CHECK_SUCCESS, GET_ACCOUNT_KEY} from 'constants/types/authActionTypes';
import {GET_FEATURE_FLAGS} from 'constants/types/features';
import {getFeatureFlags} from 'actions/features';
import {success} from './utils/filters';
import {MiddlewareAPI} from 'redux';
import {ActionsObservable} from 'redux-observable';
// import type {State} from 'types/auth';
import {DatabaseBackedFeature, Features, GetFeatureFlagsSuccess} from 'types/features';


export default function featureFlagsEpic(
  action$: ActionsObservable<any>,
  // store: MiddlewareAPI<{ auth: State }, *>
  store: MiddlewareAPI<any, any>
): Observable<any> {
  const $getAccountKeySuccess = action$
    .ofType(GET_ACCOUNT_KEY)
    .filter(success);
  const $authorizationCheckSuccess = action$
    .ofType(AUTHORIZATION_CHECK_SUCCESS);
  return $getAccountKeySuccess
    .merge($authorizationCheckSuccess)
    .take(1)
    .map(() => {
      // const state: State = store.getState().auth;
      const state: any = store.getState().auth;
      const accountKey = state.profile ? state.profile.subd : state.accountKey;
      if (accountKey) {
        return getFeatureFlags(accountKey);
      }
      throw new Error('Couldn\'t find account key');
    });
}

type Predicate = (
  features: Features,
  combineWithAction: Observable<any>
) => Observable<any> | null | undefined;

const checkFeature = (action$: ActionsObservable<any>, feature: DatabaseBackedFeature, predicate: Predicate) =>
  (combineWithAction$: Observable<any>): Observable<any> =>
    action$
      .ofType(GET_FEATURE_FLAGS)
      .filter(success)
      .combineLatest(combineWithAction$,
        ({payload: {features}}: GetFeatureFlagsSuccess, combineWithAction) =>
          predicate(features, combineWithAction)
      )
      .filter(Boolean);

export const ifFeature = (action$: ActionsObservable<any>, feature: DatabaseBackedFeature) =>
  checkFeature(
    action$,
    feature,
    (features, combineWithAction) => features[feature] ? combineWithAction : null
  );

export const ifNotFeature = (action$: ActionsObservable<any>, feature: DatabaseBackedFeature) =>
  checkFeature(
    action$,
    feature,
    (features, combineWithAction) => features[feature] ? null : combineWithAction
  );
