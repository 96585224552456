import { ActionsObservable } from 'redux-observable';
import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { replace } from 'react-router-redux';

import {
  allSubmissionsInitialLoad,
  allSubmissionsSubsequentLoad,
  archivedSubmissionsInitialLoad,
  archivedSubmissionsSubsequentLoad,
  searchSubmissionsInitial,
  searchSubmissionsSubsequent,
  removeSubmission,
  resetFilters,
  resetSorting,
} from 'actions/submissionsManager/tableApi';
import {
  assignedToMeSubmissionInitialLoad,
  assignedToMeSubmissionSubsequentLoad,
  draftsSubmissionInitialLoad,
  draftsSubmissionSubsequentLoad,
  sharedSubmissionInitialLoad,
  sharedSubmissionSubsequentLoad,
  signedByMeSubmissionInitialLoad,
  signedByMeSubmissionSubsequentLoad,
  submittedSubmissionInitialLoad,
  submittedSubmissionSubsequentLoad,
} from 'actions/quickFilters';

import {
  getAppliedSubmissionFilters,
  getIsArchivedView,
  getLoadedSubmissionCount,
  getLoggedInUserId,
  getSelectedStageName,
  getSelectedSubmissionQuickFilter,
  getSortingState,
  getSubmissionManagerFormId,
  getSubmissionPaginationToken,
  getSubmissionSearchBarInput,
} from '../reducers';

import { success } from './utils/filters';
import { parsedToString } from 'utils/search';
import { getSortingForServer } from 'utils/submissionsManager/submissionSorting';
import { getSubmissionFiltersForServer } from 'utils/submissionsManager/submissionFilters';

import { SUBMISSIONS_MANAGER, LOBBY, SUBMISSIONS_MANAGER_ARCHIVED } from 'constants/route';
import { UNASSIGN_USERS } from 'constants/types/assignmentsActionTypes';
import {
  DELETE_STAGE,
  SELECT_ALL_VIRTUAL_STAGE,
} from 'constants/types/stageActionTypes';
import {
  APPLY_FILTERS,
  APPLY_STAGE_FILTER,
  REMOVE_STAGE_FILTERS,
  NEW_TABLE_SEARCH_API,
  REQUEST_INITIAL_SUBMISSION_LOAD,
  REQUEST_SUBSEQUENT_SUBMISSION_LOAD,
  SORT_SUBMISSIONS,
  UNSORT_SUBMISSIONS,
} from 'constants/types/submissionsTableActionTypes';
import {
  CLEAR_QUICK_FILTER,
  SUBMITTED_SUBMISSION_FILTER_INITIAL_LOAD,
  SHARED_SUBMISSION_FILTER_INITIAL_LOAD,
  DRAFTS_SUBMISSION_FILTER_INITIAL_LOAD,
  ASSIGNED_TO_ME_SUBMISSION_FILTER_INITIAL_LOAD,
  SIGNED_BY_ME_SUBMISSION_FILTER_INITIAL_LOAD,
} from 'constants/types/quickFilterActionTypes';
import { quickFiltersEnum, ARCHIVED } from 'constants/quickFilters';

import { Action as ApiAction } from 'types/api';
import { SortingForServer } from 'types/submissionsManager/submissionSorting';
import { SubmissionFilter, SubmissionFilterForServer } from 'types/submissionsManager/submissionFilters';
// import { FormId } from 'types/forms';
// import { State } from 'types/shared';
// import { Stage } from 'types/stages';


// This is mostly for testability without mocking out the redux store
// The typing is subpar, but reflective of the fact that our epics are
// not really getting much type coverage at all at the moment
export function getInitialSubmissionAction(
  // formId: FormId,
  formId: string,
  searchQuery: string,
  selectedFilter: string,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
): ApiAction {
  const isSearching = !!searchQuery;
  if (isSearching) {
    return searchSubmissionsInitial(formId, searchQuery);
  }
  switch (selectedFilter) {
    case quickFiltersEnum.ASSIGNED_TO_ME:
      return assignedToMeSubmissionInitialLoad(formId, submissionFilters, sorting);
    case quickFiltersEnum.DRAFTS:
      return draftsSubmissionInitialLoad(formId, submissionFilters, sorting);
    case quickFiltersEnum.SHARED:
      return sharedSubmissionInitialLoad(formId, submissionFilters, sorting);
    case quickFiltersEnum.SUBMITTED:
      return submittedSubmissionInitialLoad(formId, submissionFilters, sorting);
    case ARCHIVED:
      return archivedSubmissionsInitialLoad(formId, submissionFilters, sorting);
    case quickFiltersEnum.SIGNED_BY_ME:
      return signedByMeSubmissionInitialLoad(formId, submissionFilters, sorting);
    case quickFiltersEnum.ALL:
    default:
      return allSubmissionsInitialLoad(formId, submissionFilters, sorting);
  }
}

function initialSubmissionLoad(
  action$: ActionsObservable<any>,
  store: MiddlewareAPI,
): Observable<any> {
  const initialSubmissionLoad$ = action$.ofType(REQUEST_INITIAL_SUBMISSION_LOAD);
  const justDeletedSelectedStage$ = action$
    .ofType(DELETE_STAGE)
    .filter(action => {
      // const state: State = store.getState();
      const state = store.getState();
      return success(action) && getSelectedStageName(state) === action.payload.stageName;
    });
  const clearQuickFilter$ = action$.ofType(CLEAR_QUICK_FILTER);
  const updateAppliedFilters$ = action$.ofType(APPLY_FILTERS, APPLY_STAGE_FILTER, REMOVE_STAGE_FILTERS);
  const sortOrUnsortSubmissions$ = action$.ofType(SORT_SUBMISSIONS, UNSORT_SUBMISSIONS);
  return initialSubmissionLoad$
    .merge(justDeletedSelectedStage$)
    .merge(clearQuickFilter$)
    .merge(updateAppliedFilters$)
    .merge(sortOrUnsortSubmissions$)
    .map(({ formId, isArchiveView, filters, newSortingState }) => {
      // const state: State = store.getState();
      const state = store.getState();
      const formIdForRequest = formId ? formId : getSubmissionManagerFormId(state);
      const searchQuery = parsedToString(getSubmissionSearchBarInput(state));
      const isArchivedView = isArchiveView || getIsArchivedView(state);
      const selectedFilter = isArchivedView ? ARCHIVED : getSelectedSubmissionQuickFilter(state);
      const sorting = newSortingState ? newSortingState : getSortingState(state);
      const sortingForServer = getSortingForServer(sorting);
      const submissionFilters: SubmissionFilter[] = filters ? filters : getAppliedSubmissionFilters(state);
      const submissionFiltersForServer = getSubmissionFiltersForServer(submissionFilters);
      return getInitialSubmissionAction(
        formIdForRequest,
        searchQuery,
        selectedFilter,
        submissionFiltersForServer,
        sortingForServer,
      );
    });
}

export function getSubsequentSubmissionAction(
  // formId: FormId,
  formId: string,
  searchQuery: string,
  paginationToken: string | null,
  loadedSubmissionCount: number,
  selectedFilter: string,
  isArchivedPage?: boolean,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
): ApiAction {
  const isSearching = !!searchQuery;
  if (isSearching) {
    return searchSubmissionsSubsequent(
      loadedSubmissionCount,
      formId,
      searchQuery,
    );
  }

  switch (selectedFilter) {
    case quickFiltersEnum.ASSIGNED_TO_ME:
      return assignedToMeSubmissionSubsequentLoad(
        formId,
        paginationToken,
        loadedSubmissionCount,
        submissionFilters,
        sorting,
      );
    case quickFiltersEnum.DRAFTS:
      return draftsSubmissionSubsequentLoad(
        formId,
        paginationToken,
        loadedSubmissionCount,
        submissionFilters,
        sorting,
      );
    case quickFiltersEnum.SHARED:
      return sharedSubmissionSubsequentLoad(
        formId,
        paginationToken,
        loadedSubmissionCount,
        submissionFilters,
        sorting,
      );
    case quickFiltersEnum.SUBMITTED:
      return submittedSubmissionSubsequentLoad(
        formId,
        paginationToken,
        loadedSubmissionCount,
        submissionFilters,
        sorting,
      );
    case quickFiltersEnum.SIGNED_BY_ME:
      return signedByMeSubmissionSubsequentLoad(
        formId,
        paginationToken,
        loadedSubmissionCount,
        submissionFilters,
        sorting,
      );
    case ARCHIVED:
      return archivedSubmissionsSubsequentLoad(
        formId,
        paginationToken,
        loadedSubmissionCount,
        submissionFilters,
        sorting,
      );
    case quickFiltersEnum.ALL:
    default:
      return allSubmissionsSubsequentLoad(formId, paginationToken, loadedSubmissionCount, submissionFilters, sorting);
  }
}

function subsequentSubmissionLoad(
  action$: ActionsObservable<any>,
  store: MiddlewareAPI,
): Observable<any> {
  const subsequentSubmissionLoad$ = action$.ofType(REQUEST_SUBSEQUENT_SUBMISSION_LOAD);

  return subsequentSubmissionLoad$
    .map(({formId, isArchiveView}) => {
      // const state: State = store.getState();
      const state = store.getState();
      const submissionPaginationToken = getSubmissionPaginationToken(state);
      const searchQuery = parsedToString(getSubmissionSearchBarInput(state));
      const selectedFilter = isArchiveView ? ARCHIVED : getSelectedSubmissionQuickFilter(state);
      const loadedSubmissionCount = getLoadedSubmissionCount(state);
      const isArchivedPage = getIsArchivedView(state);
      const sorting = getSortingState(state);
      const sortingForServer = getSortingForServer(sorting);
      const submissionFilters: SubmissionFilter[] = getAppliedSubmissionFilters(state);
      const submissionFiltersForServer = getSubmissionFiltersForServer(submissionFilters);
      return getSubsequentSubmissionAction(
        formId,
        searchQuery,
        submissionPaginationToken,
        loadedSubmissionCount,
        selectedFilter,
        isArchivedPage,
        submissionFiltersForServer,
        sortingForServer,
      );
    });
}

function submissionManagerRouteEpic(
  action$: ActionsObservable<any>,
  store: MiddlewareAPI,
): Observable<any> {
  const clearQuickFilter$ = action$.ofType(CLEAR_QUICK_FILTER);
  const initialSearchSuccess$ = action$
    .ofType(NEW_TABLE_SEARCH_API)
    .filter(success);
  const allVirtualStageSelect$ = action$
    .ofType(SELECT_ALL_VIRTUAL_STAGE);
  return clearQuickFilter$
    .merge(initialSearchSuccess$)
    .merge(allVirtualStageSelect$)
    .map(() => {
      const state = store.getState();
      const isArchiveView = getIsArchivedView(state);
      const formId = getSubmissionManagerFormId(state);
      if (isArchiveView) {
        return replace(`${SUBMISSIONS_MANAGER_ARCHIVED}/${formId}`);
      }
      return replace(`${SUBMISSIONS_MANAGER}/${formId}`);
    });
}

function clearSubmissionDetailsHashEpic(
  action$: ActionsObservable<any>,
  store: MiddlewareAPI,
): Observable<any> {
  return action$
    .ofType(
      APPLY_FILTERS,
      APPLY_STAGE_FILTER,
      REMOVE_STAGE_FILTERS,
      SORT_SUBMISSIONS,
      UNSORT_SUBMISSIONS,
    )
    .map(() => replace(store.getState().routing.locationBeforeTransitions.pathname));
}

function submissionManagerRedirectAwayEpic(action$: ActionsObservable<any>, store: MiddlewareAPI): Observable<any> {
  const quickFilter$ = action$.ofType(
    SUBMITTED_SUBMISSION_FILTER_INITIAL_LOAD,
    SHARED_SUBMISSION_FILTER_INITIAL_LOAD,
    DRAFTS_SUBMISSION_FILTER_INITIAL_LOAD,
    ASSIGNED_TO_ME_SUBMISSION_FILTER_INITIAL_LOAD,
    SIGNED_BY_ME_SUBMISSION_FILTER_INITIAL_LOAD,
  )
    .filter(success)
    .filter(action => !action.payload.records.length)
    .filter(() => {
      const state = store.getState();
      const submissionFilters = getAppliedSubmissionFilters(state);
      return submissionFilters.length === 0;
    });
  return quickFilter$.map(() => replace(LOBBY));
}


function assignedToMeQuickFilterLocalUpdate(
  action$: ActionsObservable<any>,
  store: MiddlewareAPI,
): Observable<any> {
  const unasssignedAction$ = action$.ofType(UNASSIGN_USERS);

  return unasssignedAction$
    .filter(action => {
      // const state: State = store.getState();
      const state = store.getState();
      const selectedFilter = getSelectedSubmissionQuickFilter(state);
      const currentUserId = getLoggedInUserId(state);
      return success(action) &&
        selectedFilter === quickFiltersEnum.ASSIGNED_TO_ME &&
        action.payload.userIds.includes(currentUserId);
    })
    .map(({payload: {submissionId}}) => removeSubmission(submissionId));
}

function resetSubmissionFilteringAndSortingEpic(action$: ActionsObservable<any>): Observable<any> {
  return action$
    .ofType(NEW_TABLE_SEARCH_API)
    .filter(success)
    .mergeMap(() => Observable.of(resetFilters()).merge(Observable.of(resetSorting()))
    );
}

export default [
  assignedToMeQuickFilterLocalUpdate,
  initialSubmissionLoad,
  subsequentSubmissionLoad,
  submissionManagerRouteEpic,
  clearSubmissionDetailsHashEpic,
  submissionManagerRedirectAwayEpic,
  resetSubmissionFilteringAndSortingEpic,
];
