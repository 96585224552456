import React from 'react';
import AccessibleSVG from 'components/AccessibleSVG';


const CrossMarkIcon = ({ className, color, onClick = null, size }) => (
  <AccessibleSVG width={size} height={size} onClick={onClick} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <rect
      x='11.9453'
      y='30.8768'
      width='3.92885'
      height='26.8354'
      transform='rotate(-135 11.9453 30.8768)'
      fill={color} />
    <rect
      x='9.16699'
      y='11.9012'
      width='3.92885'
      height='26.8354'
      transform='rotate(-45 9.16699 11.9012)'
      fill={color} />
  </AccessibleSVG>
);

export default CrossMarkIcon;
