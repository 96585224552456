import React, { useEffect, useRef } from 'react';

import FormPasswordUI from '../../../form-comps/FormPasswordUI/FormPasswordUI';
import styles from './Overlay.css';

type Props = {
  checkPassword: (password: string) => void;
  checkPasswordError: string | null;
  isCheckingPassword: boolean;
  isFormExpired: boolean;
  isFormSubmissionLimitReached: boolean;
  requiresPassword: boolean;
}

const OverlayComponent = ({
  checkPassword,
  checkPasswordError,
  isCheckingPassword,
  isFormSubmissionLimitReached,
  isFormExpired,
  requiresPassword,
}: Props): null | JSX.Element => {
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown: EventListener = (event: Event) => {
      const keyboardEvent = event as KeyboardEvent; // Explicitly cast event to KeyboardEvent
      if (keyboardEvent.key === 'Tab') {
        // Prevent default tab behavior
        keyboardEvent.preventDefault();
        // Find the first focusable element within the overlay
        const focusableElements = overlayRef?.current?.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );

        // Focus the first focusable element if any
        if (focusableElements && focusableElements.length > 0) {
          (focusableElements[0] as HTMLElement).focus();
        }
      }
    };
    // Add event listener for keydown
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const getOverlayMessage = () => {
    if (isFormSubmissionLimitReached) {
      return 'This form has reached the maximum limit for submissions';
    }
    if (isFormExpired) {
      return 'This form has expired';
    }
    return 'This form is password protected';
  };

  if (!isFormSubmissionLimitReached && !isFormExpired && !requiresPassword) {
    return null; // No need to render overlay if neither condition is met
  }

  const showPasswordInput = requiresPassword && !isFormExpired && !isFormSubmissionLimitReached;

  return (
    <div className={styles.disabledOverlay} ref={overlayRef}>
      <div className={styles.overlayModalContainer}>
        <div className={styles.disabledMessageReason}>
          {getOverlayMessage()}
        </div>
        {showPasswordInput && (<FormPasswordUI
          checkPassword={checkPassword}
          error={checkPasswordError}
          isLoading={isCheckingPassword}
        />)}
      </div>
      {/* Empty div to capture focus */}
      {/* <div tabIndex={-1} /> */}
    </div>
  );
};

export default OverlayComponent;
