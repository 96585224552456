import { calculatePayment, getFeeName, getFieldAmount } from 'utils/formLiveView/formLiveView';
import { FormData, FormState, LiveViewPaymentConfig, PaymentMethod, PaymentMethodsIcons, PaymentMethodsOpenModalHandlers } from 'types/liveView';
import { PaymentType } from 'types/paymentSettings';
import { getSubmitCardLabel } from './formLiveView';

/** Transforms payment amount data to human-readable string. */
const getAmountToDisplay = (
  totalAmount: number,
  roundedBaseAmount: number,
  feeAmount: number,
): string =>
  feeAmount ? `$${roundedBaseAmount.toFixed(2)} + $${feeAmount.toFixed(2)} = $${totalAmount.toFixed(2)}` :
    `$${totalAmount.toFixed(2)}`;

/** Returns an array of available payment methods for a form. */
export const getPaymentMethods = (
  formData: FormData,
  formState: FormState,
  icons: PaymentMethodsIcons,
  openPaymentModalHandlers: PaymentMethodsOpenModalHandlers,
): PaymentMethod[] => {
  const paymentConfig = formData?.form?.paymentConfig;

  if (!paymentConfig) {
    return [];
  }

  const { achEnabled, ccEnabled, debitEnabled } = paymentConfig;
  const [totalAmount, roundedBaseAmount, feeAmount] = calculatePayment(paymentConfig, formState, 'credit_card');
  const [totalAmountDc, roundedBaseAmountDc, feeAmountDc] = calculatePayment(paymentConfig, formState, 'debit_card');
  const [totalAmountAch, roundedBaseAmountAch, feeAmountAch] = calculatePayment(paymentConfig, formState, 'ach');

  const { bankAccount, creditCard, debitCard } = icons;
  const {
    openAchPaymentModal,
    openCreditCardPaymentModal,
    openDebitCardPaymentModal,
  } = openPaymentModalHandlers;

  const ach: PaymentMethod = {
    paymentType: 'ach',
    name: 'Bank Account',
    icon: bankAccount,
    amount: getAmountToDisplay(totalAmountAch, roundedBaseAmountAch, feeAmountAch),
    openPaymentModal: openAchPaymentModal,
  };

  const cc: PaymentMethod = {
    paymentType: 'credit_card',
    name: 'Credit Card',
    icon: creditCard,
    amount: getAmountToDisplay(totalAmount, roundedBaseAmount, feeAmount),
    openPaymentModal: openCreditCardPaymentModal,
  };

  const dc: PaymentMethod = {
    paymentType: 'debit_card',
    name: 'Debit Card',
    icon: debitCard,
    amount: getAmountToDisplay(totalAmountDc, roundedBaseAmountDc, feeAmountDc),
    openPaymentModal: openDebitCardPaymentModal,
  };


  return [
    ...(achEnabled ? [ach] : []),
    ...(ccEnabled ? [cc] : []),
    ...(debitEnabled ? [dc] : []),
  ];
};

export const showPaymentModal = (
  paymentType: PaymentType,
  formData: FormData,
  formState: FormState,
  companyInfo: { companyName: string, accountKey: string },
  submitForm: (paymentData?: any) => void,
  setIsModalOpen: (isOpen: boolean) => void,
): void => {
  const paymentConfig: LiveViewPaymentConfig | null | undefined = formData.form?.paymentConfig;
  if (!paymentConfig) {
    return;
  }
  const [totalAmount, baseAmount, feeTotal]: number[] = calculatePayment(paymentConfig, formState, paymentType);
  const modal = (window as any).SpreedlyExpress;
  try {
    // make sure to unload before showing. unloading on viewClosed instead, will cause the form to not submit.
    // ENG-2432: unloading avoids multiples of inserted content to modal.
    modal.unload();
  } catch {
    // do nothing
  }
  const feeText: string = feeTotal ? `<br/>${getFeeName(paymentConfig)}: $${feeTotal.toFixed(2)}` : '';
  // eslint-disable-next-line camelcase
  const sidebar_bottom_description: string = `${formData?.form?.formName || 'Form'}
  <br/>Amount: $${baseAmount.toFixed(2)}${feeText}`;
  // wait until init is done before showing, avoids some flickers
  modal.onInit(() => {
    modal.openView();
    setIsModalOpen(true);
  });
  modal.onPaymentMethod((paymentToken: string) => {
    submitForm({
      paymentToken,
      amount: totalAmount,
      baseAmount,
      feeTotal,
      feeAmount: paymentConfig?.feeStructure?.fees?.[0]?.amount || 0,
      feePercentage: paymentConfig?.feeStructure?.fees?.[0]?.percentage || 0,
      paymentType,
    });
    modal.unload();
  });
  modal.onViewClose(() => {
    setIsModalOpen(false);
  });
  modal.init(
    paymentConfig.apiKey,
    {
      amount: `$${totalAmount.toFixed(2)}`,
      company_name: `<p class="form_payment_company_name">${companyInfo.companyName || companyInfo.accountKey}</p>`,
      sidebar_top_description: paymentConfig.description || '',
      sidebar_bottom_description,
      close_label: 'Back to Form',
      submit_label: getSubmitCardLabel(paymentType, paymentConfig),
      number_label: paymentType === 'credit_card' ? 'Credit Card Number' : 'Debit Card Number',
    }
  );
};

export const doPaymentFlow = (
  paymentMethods: PaymentMethod[],
  formData: FormData,
  formState: FormState,
  submitForm: (paymentData?: any) => void,
  companyInfo: { companyName: string, accountKey: string },
  setters: {
    setModalOptions: ({ open, amount }: { open: boolean, amount: number }) => void,
    setIsPaymentMethodsModalOpen: (isOpen: boolean) => void,
    setIsACHModalOpen: (isOpen: boolean) => void,
    setIsModalOpen: (isOpen: boolean) => void,
  }
) => {
  const hasPayment: boolean = !!formData?.form?.paymentConfig;
  // if NO payment is set
  if (!hasPayment) {
    return submitForm();
  }

  const paymentConfig: LiveViewPaymentConfig | null |undefined = formData?.form?.paymentConfig;

  if (!paymentConfig) {
    return null;
  }

  const { paymentOptional,
    achEnabled,
    ccEnabled,
    debitEnabled,
    amountSource,
    amountSourceType,
  } = paymentConfig;

  if (amountSourceType === 'field') {
    const amount = getFieldAmount(formState, amountSource);

    if (amount <= 0) {
      return submitForm();
    }
  }

  if (paymentMethods.length === 1) {
    const [amount] = calculatePayment(paymentConfig, formState, paymentMethods[0].paymentType);

    // or amount is 0, just submit
    if (amount <= 0) {
      return submitForm();
    }

    // payment is optional and only one payment method is enabled, show pay now or submit choice modal
    if (paymentOptional) {
      return setters.setModalOptions({ open: true, amount });
    }
  }

  // if payment is not optional, show paymentModal, pay (auth or capture) and submit form
  if (!paymentOptional) {
    if (paymentMethods.length > 1) {
      return setters.setIsPaymentMethodsModalOpen(true);
    } else if (achEnabled && !ccEnabled && !debitEnabled) {
      return setters.setIsACHModalOpen(true);
    } else if (!achEnabled && ccEnabled && !debitEnabled) {
      return showPaymentModal('credit_card', formData, formState, companyInfo, submitForm, setters.setIsModalOpen);
    } else if (!achEnabled && !ccEnabled && debitEnabled) {
      return showPaymentModal('debit_card', formData, formState, companyInfo, submitForm, setters.setIsModalOpen);
    }
  }

  // payment is optional and all payment methods are enabled, show payment method choice modal
  return setters.setIsPaymentMethodsModalOpen(true);
};
