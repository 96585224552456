import React from 'react';
import styles from './SubmissionDetails.css';
import AttachmentBoxItem from './AttachmentBoxItem';
import { hackilyRewriteAttachmentUrl } from 'utils/submissionsManager/attachments';


export default class AttachmentBoxContent extends React.Component<any, any> {
  render() {
    const { attachments, trackDownloadFile } = this.props;

    const attachmentItems = attachments ? attachments.map((attachment, i) => {
      const fileSplitString = attachment.url.split('.');
      let fileType = fileSplitString[fileSplitString.length - 1];

      /**
       * Some AWS URLs do not have the file extension at the end of it
       * If the file type is not available, the fileType shouldn't be
       * rendered
       */
      if (fileType.length !== 3 && fileType.length !== 4) {
        fileType = '';
      }

      return (
        <li className={styles.boxCard} key={i}>
          <AttachmentBoxItem
            fileType={fileType}
            fileName={attachment.label}
            url={hackilyRewriteAttachmentUrl(attachment.url)}
            trackDownloadFile={trackDownloadFile} />
        </li>
      );
    }) : null;
    return (
      <span>
        <ul className={styles.attachmentBox} ref={c => { (this as any).content = c; }}>
          {attachmentItems}
        </ul>
      </span>
    );
  }
}

