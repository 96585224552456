import React from 'react';
import withLogoFallback from './WithLogoFallback';
import styles from './styles.css';
// import type {Thunk} from 'types/shared';

// type Props = {|
//   headerText: string,
//   subtext: string,
//   width: string,
// |};

const AuthHeader = ({ headerText, subtext, width }) => (
  <div className={styles.headline} style={{ width }}>
    <h1>{headerText}</h1>
    <p className={styles.subtext}>
      {subtext}
    </p>
  </div>
);

AuthHeader.defaultProps = { width: '510px' };

const subtextCopy = 'Please fill in the quick information below so you can save your work as you go and keep track of all your forms and submissions.';

export const RegistrationHeader = () => (
  <AuthHeader
    headerText='Register to view this form'
    subtext={subtextCopy} />
);

export const CoregistrationHeader = () => (
  <AuthHeader
    headerText='Complete your Account Registration'
    subtext={subtextCopy} />
);

export const LoginInterstitialHeader = () => (
  <AuthHeader
    headerText='Log in to view this form.'
    subtext={subtextCopy}
    width='425px' />
);

// type LoginHeaderProps = {
//   companyName: ?string,
//   ensureLogoFallback: Thunk,
//   handleLogoOnLoad: Thunk,
//   logo: string,
// };

export const LoginHeader = withLogoFallback(props => {
  const { companyName, logo, ensureLogoFallback, handleLogoOnLoad } = props;
  return (
    <div className={styles.header}>
      <div className={styles.companyLogoWrapper}>
        <img
          onError={ensureLogoFallback}
          onLoad={handleLogoOnLoad}
          className={styles.companyLogo}
          alt='organization logo'
          src={logo} />
      </div>
      <div className={styles.organizationName}>
        <h6>Log in to</h6>
        <h3>{companyName}</h3>
      </div>
    </div>
  );
});

export const ForgotPasswordHeader = () => (
  <AuthHeader
    headerText='Forgot Password?'
    subtext={'Need help finding your organization or resetting your password? \n Enter your email address and we\'ll get you logged in.'} />
);
