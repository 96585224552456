import React, { useEffect, useRef } from 'react';
import styles from '../IdVerification.css';
import idVerificationStyles from '../IdVerification.css';
import arrowBack from 'icons/arrowBack.svg';

type WithFocusTrapProps = {
  isModalContent?: boolean;
  isContentLoaded?: boolean;
  closeModal?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
  open: boolean;
};

const WithFocusTrap = ({ isModalContent, closeModal, open, isContentLoaded, children }: WithFocusTrapProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isContentLoaded && modalRef.current) {
      const focusableElements = modalRef.current.querySelectorAll(
        'button, [href], select, textarea, [tabindex]:not([tabindex="-1"])',
      ) as NodeListOf<HTMLElement>;

      if (focusableElements.length > 0) {
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'Tab') {
            // Handle the case where only one focusable element exists
            if (focusableElements.length === 1) {
              event.preventDefault();
              firstElement.focus();
              return;
            }

            // Shift+Tab: Move focus backward
            if (event.shiftKey) {
              if (document.activeElement === firstElement) {
                event.preventDefault();
                lastElement.focus();
              }
            } else {
              // Tab: Move focus forward
              if (document.activeElement === lastElement) {
                event.preventDefault();
                firstElement.focus();
              }
            }
          }
        };

        const handleFocus = (event: FocusEvent) => {
          if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            firstElement.focus();
          }
        };

        firstElement.focus();
        modalRef.current.addEventListener('keydown', handleKeyDown);
        document.addEventListener('focus', handleFocus, true);

        return () => {
          modalRef.current?.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('focus', handleFocus, true);
        };
      }
    }
    return () => {
    };
  }, [open, isContentLoaded]);

  return (
    <div className={styles.overlay} aria-live='polite' aria-busy={isContentLoaded ? 'false' : 'true'}>
      <div
        className={styles.personaContainer}
        ref={modalRef}
        role='dialog'
        aria-modal='true'
        aria-labelledby='modal-title'
        tabIndex={-1}
      >
        {isModalContent && (
          <button
            aria-label='Close modal and return to the form'
            onClick={closeModal}
            className={idVerificationStyles.backToFormButton}
          >
            <img src={arrowBack} alt='Go back to the form' />
            Back to form
          </button>
        )}
        <div className={idVerificationStyles.personaWrapper}>
          {children}
        </div>
        <button aria-label='last element' style={{ display: 'none' }} />
      </div>
    </div>
  );
};

export default WithFocusTrap;
