import {SET_FORM_ID, TOGGLE_MODAL, SET_SHOULD_RENDER_CONFIRMATION, SET_CONFIRMATION_CONFIG} from 'constants/paymentGatewaySidebar';


export const initialState = {
  formId: '',
  isModalOpen: false,
  confirmationConfig: {
    agreeText: '',
    description: '',
    disagreeText: '',
    title: '',
  },
  shouldRenderConfirmation: false,
};

export default function paymentGatewaySidebar(state = initialState, _action) {
  const action = _action;
  switch (action.type) {
    case SET_FORM_ID:
      return {...state, formId: action.formId};
    case TOGGLE_MODAL:
      return {...state, isModalOpen: !state.isModalOpen};
    case SET_SHOULD_RENDER_CONFIRMATION:
      return {...state, shouldRenderConfirmation: action.value};
    case SET_CONFIRMATION_CONFIG:
      return {...state, confirmationConfig: action.config};
    default:
      return state;
  }
}

export const getFormId = state => state.formId;

export const getIsModalOpen = state => state.isModalOpen;

export const getConfirmationConfig = state => state.confirmationConfig;

export const getShouldRenderConfirmation = state => state.shouldRenderConfirmation;
