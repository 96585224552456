import React from 'react';
import { connect } from 'react-redux';
import NotFoundPage from '../404/404';
import errorIcon from 'icons/errorIcon.svg';
import styles from './SSOFailure.css';

interface Props {
  error: string,
}

const SSOFailure = ({ error }: Props) =>
  <NotFoundPage
    errorDescription={error || 'Login failed, please try again'}
    errorTitle='Error occured'
    verticalErrorImage={{ src: errorIcon, alt: 'error', className: styles.verticalErrorImage }}
    horizontalErrorImage={{ src: errorIcon, alt: 'error', className: styles.horizontalErrorImage }}
  />;

const mapStateToProps = ({ auth: { error } }) => ({ error });

export default connect(mapStateToProps)(SSOFailure);
