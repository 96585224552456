import React, {useState, useMemo} from 'react';
import store, {initialValues } from './store';
import {NavBarContextProviderProps} from './types';

const {Provider} = store;


const NavBarContextProvider = ({children}: NavBarContextProviderProps) => {
  const [state, setState] = useState(initialValues);
  const contextValue = useMemo(() => ({
    values: state,
    resetToolbar: () => setState(initialValues),
    setSearchCallbacks: ({onChange, onSubmit}) => setState({
      ...state,
      search: {...state.search, onChange, onSubmit},
    }),
    setSearchValue: (value: string) => setState({
      ...state,
      search: {...state.search, value},
    }),
    setSearchPlaceHolder: (searchPlaceholder: string) => setState({
      ...state,
      placeHolder: searchPlaceholder,
    }),
    setButtons: (buttons:[]) => setState({
      ...state,
      buttons,
    }),
  }), [state]);

  return <Provider value={contextValue as any}>{children}</Provider>;
};

export default NavBarContextProvider;
