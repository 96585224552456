import * as React from 'react';
import _ from 'lodash';
import { Motion, spring } from 'react-motion';
import MaterialUIToolbar from '@material-ui/core/Toolbar';
import * as search from 'utils/search';
import { offsetWidth } from 'utils/reactDOM';
import SearchToolbarGroup from './SearchToolbarGroup';
import styles from './Toolbar.css';
import { Link } from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import { Menu } from './RightNav';
import * as css from 'utils/css';
import * as inlineStyles from './inlineStyles';


const searchbarIconWidth = _.sumBy([
  styles.searchIconPaddingLeft,
  styles.searchIconPaddingRight,
  styles.searchIconWidth,
], css.pxToNumber);
const searchbarOpenDesktopWidthFraction = 0.2;
const searchbarClosedDesktopWidth = searchbarIconWidth + 150;
const searchbarClosedMobileWidth = searchbarIconWidth;

const widthFromComponent = component => {
  const width = component ? offsetWidth(component) : 0;
  return width || 0;
};


export default class Toolbar extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      searchbarFullyClosed: true,
      searchbarOpen: false,
    };
  }

  UNSAFE_componentWillMount() {
    this._setRestingState();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.showDeleteButton !== nextProps.showDeleteButton) {
      setTimeout(() => {
        this._setToolbarMeasurements();
        this.forceUpdate();
      }, 0);
    }
  }

  backIcon;
  backIconWidth;
  rightToolbarGroupWidth;
  rightToolbarGroup;
  leftToolbarGroupWidth;
  leftToolbarGroup;
  searchToolbarInputEl;

  _setToolbarMeasurements = () => {
    if (!this.backIcon) return;
    this.backIconWidth = widthFromComponent(this.backIcon);
    this.rightToolbarGroupWidth = widthFromComponent(this.rightToolbarGroup);
    this.leftToolbarGroupWidth = widthFromComponent(this.leftToolbarGroup);
  };

  focusSearchToolbar = () => {
    if (this.searchToolbarInputEl) {
      this.searchToolbarInputEl.focus();
    }
  };

  _setRestingState = () => {
    const { searchbarOpen } = this.state;
    this.setState({ searchbarFullyClosed: !searchbarOpen });
    if (searchbarOpen) {
      this.focusSearchToolbar();
    }
  };

  _motionStyle = (searchbarFullyClosed, searchbarWidth) => ({
    searchbarWidth: searchbarFullyClosed ? searchbarWidth : spring(searchbarWidth, { stiffness: 150, damping: 19 }),
  })

  _handleSearchbarExit = () => {
    this.setState({ searchbarOpen: false });
    this._setToolbarMeasurements();
  };

  handleMotionOnRest = () => {
    this._setRestingState();
  };

  handleSearchbarOnOpen = searchToolbarGroup => {
    this._setToolbarMeasurements();
    this.setState({ searchbarOpen: true, searchbarFullyClosed: false });
    this.searchToolbarInputEl = searchToolbarGroup.refs.input;
  };

  handleSearchbarOnBlur = searchToolbarGroup => {
    this._handleSearchbarExit();
    this.searchToolbarInputEl = searchToolbarGroup.refs.input;
  };

  handleSearchbarInputOnChange = input => {
    const parsed = search.parse(input);
    if (!parsed) return;
    this.props.searchbarInputChange && this.props.searchbarInputChange(parsed);
  };

  handleEscapePress = () => {
    this._handleSearchbarExit();
  };

  handleSearchbarOnSubmit = () => {
    this.props.searchbarInputChange && this.props.searchbarInputChange(this.props.searchbarInput, true);
  };

  render() {
    const {
      backIcon,
      backIconBackgroundColor,
      backIconLink,
      backIconOnClick,
      children,
      isMobile,
      onToolbarMobileMenuToggle,
      searchbarInput,
      shouldShowSearchbar,
      titleContent,
      toolbarMobileMenuVisible,
      windowWidth,
    } = this.props;

    const {
      searchbarFullyClosed,
      searchbarOpen,
    } = this.state;

    const searchbarOpenWidth = isMobile
      ? windowWidth - this.rightToolbarGroupWidth
      : windowWidth * searchbarOpenDesktopWidthFraction;

    const searchbarClosedWidth = isMobile ? searchbarClosedMobileWidth : searchbarClosedDesktopWidth;
    const searchbarRightOffset = windowWidth - (this.rightToolbarGroupWidth || 0);
    const motionStyle = this._motionStyle(
      searchbarFullyClosed,
      searchbarOpen ? searchbarOpenWidth : searchbarClosedWidth);

    return (
      <div>
        <Motion
          onRest={this.handleMotionOnRest}
          defaultStyle={{ searchbarWidth: searchbarClosedWidth }}
          style={motionStyle}>
          {({ searchbarWidth }) => {
            const remainingSpace = searchbarRightOffset - searchbarWidth;
            let backIconLeftOffset = 0;
            if (searchbarOpen && (this.backIconWidth >= remainingSpace)) {
              backIconLeftOffset = remainingSpace - this.backIconWidth;
            }
            return (
              <MaterialUIToolbar style={inlineStyles.toolBar as any}>
                <div style={{ display: 'flex' }} ref={group => { this.leftToolbarGroup = group; }}>
                  <Link
                    to={backIconLink}
                    className={(backIconLink || backIconOnClick) ? null : styles.inactiveLink}
                    onClick={backIconOnClick}>
                    <div
                      className={styles.titleIconWrap}
                      style={{ left: `${backIconLeftOffset}px` }}
                      ref={icon => { this.backIcon = icon; }}>
                      <IconButton
                        classes={{ root: styles.titleIcon, label: styles.titleIconLabel }}
                        style={inlineStyles.titleIcon(backIconBackgroundColor)}>
                        {backIcon}
                      </IconButton>
                    </div>
                  </Link>
                  <span style={isMobile ? { left: `${searchbarClosedWidth - searchbarWidth}px`, ...inlineStyles.titleText() } as any : inlineStyles.titleText()}>
                    {titleContent}
                  </span>
                </div>

                {shouldShowSearchbar && <SearchToolbarGroup
                  isMobile={isMobile}
                  leftOffset={searchbarFullyClosed ? 0 : remainingSpace}
                  onBlur={this.handleSearchbarOnBlur}
                  onChange={this.handleSearchbarInputOnChange}
                  onEscape={this.handleEscapePress}
                  onOpen={this.handleSearchbarOnOpen}
                  onRefocus={this.focusSearchToolbar}
                  onSubmit={this.handleSearchbarOnSubmit}
                  open={searchbarOpen}
                  placeholder={(isMobile && !searchbarOpen) ? '' : 'Search...'}
                  position={searchbarFullyClosed ? 'relative' : 'absolute'}
                  value={searchbarInput}
                  width={searchbarWidth} />}
                <div
                  ref={group => { this.rightToolbarGroup = group; }}
                  style={inlineStyles.rightToolbarGroup as any}>
                  <Menu
                    windowWidth={windowWidth}
                    toolbarMobileMenuVisible={toolbarMobileMenuVisible}
                    onToolbarMobileMenuToggle={onToolbarMobileMenuToggle}>
                    {children}
                  </Menu>
                </div>
              </MaterialUIToolbar>
            );
          }}
        </Motion>
      </div>
    );
  }
}
