import * as React from 'react';
import { Link } from 'react-router';
import Toolbar from 'components/Toolbar';
import injectPermissions from 'decorators/injectPermissions';
import Icon from '@material-ui/core/Icon';
import * as Colors from 'utils/colors';
import styles from 'styles/shared/links.css';
import { MenuItem, PermissionsMenuItem } from 'components/Toolbar/RightNav';
import clientSideFeatures from '../../features';
import * as routing from 'utils/routing';
import { formCapabilitiesEnum } from 'constants/tableConsts';


import { FROM_TOOLBAR } from 'types/routing';


export class LobbyToolbar extends React.Component<any, any> {
  buildToolbarButtonGroup = () => {
    const {
      isInArchive,
      onRestoreArchived,
      selectedRowData,
      submissionManagerRoute,
      windowWidth,
    } = this.props;

    if (!selectedRowData) {
      return null;
    }

    const buttonGroup: any[] = [];

    if (isInArchive) {
      buttonGroup.push(
        <MenuItem windowWidth={windowWidth} text='Restore' key='restore' icon='restore' onClick={onRestoreArchived} />,
        <MenuItem windowWidth={windowWidth} text='Delete' key='delete' icon='delete' onClick={this.onDeleteFormIconClick} />
      );
    } else {
      const viewFormUrl = selectedRowData && routing.formatLegacyLiveviewUrl(selectedRowData);
      const editFormUrl = selectedRowData && routing.formatLegacyEditFormUrl(selectedRowData);
      const capabilities = selectedRowData.capabilities || [];

      buttonGroup.push(
        <a key='visibility' href={viewFormUrl} className={styles.unstyled} onClick={this.onToolbarMobileMenuToggle}>
          <MenuItem windowWidth={windowWidth} text='View' icon='visibility' />
        </a>
      );

      if (capabilities.includes(formCapabilitiesEnum.Edit)) {
        buttonGroup.push(
          <a key='create' href={editFormUrl} className={styles.unstyled} onClick={this.onToolbarMobileMenuToggle}>
            <MenuItem windowWidth={windowWidth} text='Edit' icon='create' />
          </a>
        );
      }

      if (capabilities.includes(formCapabilitiesEnum.Archive)) {
        buttonGroup.push(
          <MenuItem windowWidth={windowWidth} text='Archive' key='archive' icon='archive' onClick={this.onArchiveIconClick} />
        );
      }

      if (capabilities.includes(formCapabilitiesEnum.EditPermissions)) {
        buttonGroup.push(
          <PermissionsMenuItem windowWidth={windowWidth} text='Permissions' key='permissions' onClick={this.onPermissionsIconClick} />
        );
      }

      if (this.props.canUserAccessGRM() && selectedRowData.numSubmissions /* ENG-1486 */) {
        buttonGroup.push(
          <a
            href={routing.grmPipeline(selectedRowData.formId)}
            className={styles.unstyled}
            key='grm'
            onClick={this.onToolbarMobileMenuToggle}
            rel='noopener noreferrer'
            target='_blank'>
            <MenuItem windowWidth={windowWidth} text='GRM' icon='import_contacts' />
          </a>
        );
      }

      if (selectedRowData.numSubmissions) {
        buttonGroup.unshift(
          <Link
            className={styles.unstyled}
            key='inbox'
            to={`${submissionManagerRoute}/${selectedRowData.formId}`}>
            <MenuItem windowWidth={windowWidth as number} text='Submissions' icon='inbox' onClick={this.onInboxIconClick} />
          </Link>
        );
      }
    }

    return buttonGroup;
  }

  onToolbarMobileMenuToggle = () => {
    this.props.toolbarMobileMenuVisibility(!this.props.toolbarMobileMenuVisible);
  }

  onInboxIconClick = () => {
    this.props.toolbarMobileMenuVisibility(false);
    this.props.logSubmissionNavigation(FROM_TOOLBAR);
  };

  onArchiveIconClick = event => {
    event.preventDefault();
    this.props.handleOpenArchiveConfirmationModal();
    this.props.toolbarMobileMenuVisibility(false);
  };

  onDeleteFormIconClick = event => {
    event.preventDefault();
    this.props.handleOpenDeleteConfirmationModal();
    this.props.toolbarMobileMenuVisibility(false);
  };

  onPermissionsIconClick = event => {
    event.preventDefault();
    this.props.handleOpenPermissionsModal();
    this.props.toolbarMobileMenuVisibility(false);
  }

  render() {
    const {
      backIconOnClick,
      isMobile,
      searchbarInput,
      searchbarInputChange,
      searchbarOpen,
      titleContent,
      toolbarMobileMenuVisible,
      windowWidth,
    } = this.props;

    const buttonGroup = this.buildToolbarButtonGroup();

    return (
      <div>
        <Toolbar
          isMobile={isMobile}
          searchbarOpen={searchbarOpen}
          searchbarInput={searchbarInput}
          searchbarInputChange={searchbarInputChange}
          shouldShowSearchbar={clientSideFeatures.searchbarInLobby.enabled}
          backIcon={<Icon style={{ fontSize: '32px', paddingTop: '12%' }}>assignment</Icon>}
          backIconBackgroundColor={Colors.blue500}
          backIconLink=''
          backIconOnClick={backIconOnClick}
          titleContent={titleContent}
          windowWidth={windowWidth}
          toolbarMobileMenuVisible={toolbarMobileMenuVisible}
          onToolbarMobileMenuToggle={this.onToolbarMobileMenuToggle}>
          {buttonGroup}
        </Toolbar>
      </div>
    );
  }
}

export default injectPermissions(LobbyToolbar);
