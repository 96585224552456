import { authTypes } from 'constants/api';
import {getIdToken, getCoregistrationToken, getUID, getMAID, isLoggedIn, hasValidCoregistrationToken} from 'utils/auth';
import {SERVER_URL} from '../env';

interface FailureObject {
  error?: Error,
  request?: RequestInit,
  response?: Response
}

interface ApiCallback {
  success?: (res?: Response) => void,
  failure?: (obj?: FailureObject) => void,
  pending?: (req?: RequestInit) => void,
}

interface CoregCreds {
  maid: string,
  auid: string,
}
interface PostFetchInit {
  endpoint: string,
  data?: any,
  callbacks?: ApiCallback,
  headers?: HeadersInit,
  auth: string,
  coreg?: CoregCreds
}

function getCredentials(auth: string, coreg?: CoregCreds) {
  if (auth === authTypes.WithAuth) {
    return {
      maid: getMAID(),
      uid: getUID(),
    };
  }
  if (auth === authTypes.CoregAuth) {
    if (coreg) {
      return {
        maid: coreg.maid || '',
        uid: coreg.auid || '',
      };
    }
    throw new Error('No Co-registration credentials provided for CoregAuth request.');
  }
  return {};
}

function getValidRequestToken(auth) {
  if (auth === authTypes.WithAuth && isLoggedIn()) {
    return getIdToken();
  } else if (auth === authTypes.CoregAuth && hasValidCoregistrationToken()) {
    return getCoregistrationToken();
  }
  return '';
}

function authHeaders(headers: HeadersInit, auth?: string): HeadersInit {
  const idToken = getValidRequestToken(auth);
  switch (auth) {
    case authTypes.NoAuth:
      return headers;
    case authTypes.CoregAuth:
    case authTypes.WithAuth:
      return {
        ...headers,
        Authorization: `Bearer ${idToken}`,
      };
    default: // if present
      return idToken ? {
        ...headers,
        Authorization: `Bearer ${idToken}`,
      } : headers;
  }
}

export async function postFetch({
  endpoint,
  data = {},
  callbacks,
  auth,
  headers,
  coreg,
}: PostFetchInit) {
  let response: Response;
  let request: RequestInit;
  const nHeaders = headers || {
    'Content-Type': 'application/json',
  };
  if (auth !== authTypes.NoAuth) {
    data.credentials = getCredentials(auth, coreg);
  }
  try {
    request = {
      method: 'POST',
      credentials: 'include',
      headers: authHeaders(nHeaders, auth),
      body: JSON.stringify(data),
    };
    // @ts-ignore
    callbacks?.pending(request);
    response = await fetch(`${SERVER_URL}${endpoint}`, request);
    if (!response.ok) {
      throw new Error('Fetch Error at ' + endpoint);
    }
  } catch (error) {
    // @ts-ignore
    callbacks?.failure({error, request, response});
    return error;
  }
  // @ts-ignore
  callbacks?.success(response);
  return response;
}
