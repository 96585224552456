import React from 'react';
import MaterialUIPopover from '@material-ui/core/Popover';
import { findDOMNode } from 'utils/reactDOM';
import ResultList from './ResultList';
import styles from './Typeahead.css';


import { ENTER_KEY, DOWN_KEY, UP_KEY, TAB_KEY, _getKeyboardCode, _isCmdCtrlKey } from 'utils/keyboard';


class Typeahead extends React.Component<any, any> {
  static defaultProps = {
    allowPickingMultipleItems: true,
    initialValue: '',
    isLoading: false,
    shouldAutoFocusInput: true,
    showResultsInPopover: false,
    requestPopoverClose: () => { },
    resultsListClassName: '',
  }

  constructor(props) {
    super(props);
    this.footerRef = React.createRef();
    this.state = {
      holdingCmdCtrl: false,
      inputValue: props.initialValue,
      isFooterSelected: false,
      queuedSelections: [],
      resultsOpen: !props.showResultsInPopover,
      selectedItemIndex: null,
    };
  }

  componentDidMount() {
    if (!this.props.initialValue && this.props.onInput) {
      this.props.onInput('');
    }
    this.inputDiv = findDOMNode(this.refs.inputDiv);
    if (!this.props.Input && this.inputDiv && !!this.props.shouldAutoFocusInput) {
      this.inputDiv.focus();
    }
    window.addEventListener('keydown', this.handleBodyOnKeyDown);
    window.addEventListener('keyup', this.handleBodyOnKeyUp);
  }

  componentDidUpdate(prevProps) {
    const { results } = this.props;
    // We are only executing the setState when result prop changes
    // This makes it safe avoiding the possible infinite loops
    if (prevProps.results !== results) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isFooterSelected: this.hasFooter() && !results.length,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleBodyOnKeyDown);
    window.removeEventListener('keyup', this.handleBodyOnKeyUp);
  }

  isSelectionInQueue = selectionData =>
    !!this.state.queuedSelections.find(selection => selection === selectionData);

  setElement = elem => {
    this.element = elem;
  }

  element
  inputDiv
  footerRef

  scrollResults = () => {
    const layout = this.element;
    if (!layout || this.state.selectedItemIndex == null) return;
    const item = layout.children[this.state.selectedItemIndex];
    const dropdownWindow = { begin: layout.scrollTop, end: layout.scrollTop + layout.offsetHeight };
    const itemWindow = { begin: item.offsetTop, end: item.offsetTop + item.offsetHeight };
    if (itemWindow.end > dropdownWindow.end) {
      layout.scrollTop = itemWindow.end - layout.offsetHeight;
    }
    if (itemWindow.begin < dropdownWindow.begin) {
      layout.scrollTop = itemWindow.begin;
    }
  }

  hasFooter = () => !!this.footerRef.current;

  selectedPreviousItem = () => {
    const { props: { results }, state: { selectedItemIndex, isFooterSelected }, setItem, hasFooter } = this;
    if (!results.length && !hasFooter()) return;
    const lastItem = hasFooter() && !isFooterSelected || !results.length ? null : results.length - 1;
    const newSelectedItem = selectedItemIndex ? selectedItemIndex - 1 : lastItem;
    setItem(newSelectedItem);
  }

  selectNextItem = () => {
    const { props: { results }, state: { selectedItemIndex, isFooterSelected }, setItem, hasFooter } = this;
    if (!results.length && !hasFooter()) return;
    const firstItem = hasFooter() && (!isFooterSelected && selectedItemIndex != null) || !results.length ? null : 0;
    const shouldGoToFirst = selectedItemIndex == null || selectedItemIndex === results.length - 1;
    const newSelectedItem = shouldGoToFirst ? firstItem : selectedItemIndex + 1;
    setItem(newSelectedItem);
  }

  setItem = selectedItemIndex => {
    this.setState({ isFooterSelected: selectedItemIndex == null, selectedItemIndex }, this.scrollResults);
  }

  renderResults() {
    const {
      isLoading,
      onAddSelections,
      results,
      resultsListClassName,
      renderSelection,
      showResultsInPopover,
    } = this.props;
    // handleAddSelection is insanely dumb code to make flow pass https://github.com/facebook/flow/issues/6070
    const resultContent = results.length || this.state.inputValue ? (
      <React.Fragment>
        <div className={`${styles.results} ${resultsListClassName}`} ref={this.setElement}>
          <ResultList
            selectedItemIndex={this.state.isFooterSelected ? null : this.state.selectedItemIndex}
            inQueue={this.isSelectionInQueue}
            isLoading={isLoading}
            onAddSelection={selectionData => (
              this.handleAddSelection(selectionData, onAddSelections, showResultsInPopover, false)
            )}
            results={results}
            renderSelection={renderSelection} />
        </div>
      </React.Fragment>
    ) : null;
    if (showResultsInPopover) {
      return (
        <MaterialUIPopover
          anchorEl={this.inputDiv}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          disableAutoFocus // TODO: this is wrong, a11y wise, but this component has to be rethought a11y wise anyway.
          disableRestoreFocus // (and this one)
          onClose={this.handleRequestClose}
          open={this.state.resultsOpen}
          classes={{
            paper: styles.popoverBody,
          }}
          ModalClasses={{
            root: styles.placePopoverAboveMuiModal,
          }}>
          {resultContent}
        </MaterialUIPopover>
      );
    }
    return resultContent;
  }

  toggleSelectionInQueue = selectionData => {
    const { queuedSelections } = this.state;
    const alreadyInQueue = queuedSelections.includes(selectionData);
    this.setState({
      queuedSelections: alreadyInQueue
        ? queuedSelections.filter(selection => selection !== selectionData)
        : [...queuedSelections, selectionData],
    });
  }

  onChange = event => {
    const { value } = event.target;
    this.setState({
      inputValue: value,
      selectedItemIndex: null,
      isFooterSelected: false,
    });
    if (!this.props.onInput) return;
    this.props.onInput(value);
  };

  onFocus = () => {
    if (this.props.showResultsInPopover) {
      this.setState({
        resultsOpen: true,
      });
    }
  }

  handleAddSelection(
    selectionData,
    onAddSelections,
    showResultsInPopover,
    shouldKeepResultsOpen
  ) {
    if (this.state.holdingCmdCtrl) {
      this.toggleSelectionInQueue(selectionData);
      return;
    }
    if (showResultsInPopover) {
      this.setState({
        inputValue: '',
        resultsOpen: shouldKeepResultsOpen,
      });
    }
    onAddSelections([selectionData]);
  }

  handleOnKeyDown = e => {
    const key = _getKeyboardCode(e);
    switch (key) {
      case UP_KEY:
        e.preventDefault();
        this.selectedPreviousItem();
        break;
      case DOWN_KEY:
        e.preventDefault();
        this.selectNextItem();
        break;
      case ENTER_KEY:
        const {
          props: { results, onAddSelections, showResultsInPopover },
          state: { selectedItemIndex, isFooterSelected },
        } = this;
        if (selectedItemIndex == null || isFooterSelected) return;
        e.stopPropagation();
        this.handleAddSelection(results[selectedItemIndex], onAddSelections, showResultsInPopover, true);
        break;
      case TAB_KEY:
        e.preventDefault();
        this.props.requestPopoverClose();
        break;
      default:
        return;
    }
  }

  handleBodyOnKeyDown = e => {
    const key = _getKeyboardCode(e);
    if (!this.props.allowPickingMultipleItems || !_isCmdCtrlKey(key)) return;
    this.setState({ holdingCmdCtrl: true });
  }

  handleBodyOnKeyUp = e => {
    const key = _getKeyboardCode(e);
    if (!this.props.allowPickingMultipleItems || !_isCmdCtrlKey(key)) return;
    this.setState({ holdingCmdCtrl: false });
    const { queuedSelections } = this.state;
    if (!queuedSelections.length) return;
    this.setState({
      inputValue: '',
      resultsOpen: false,
    });
    this.props.onAddSelections(queuedSelections);
  }

  handleRequestClose = () => {
    if (this.props.showResultsInPopover) {
      this.setState({
        resultsOpen: false,
      });
    }
  }

  render() {
    const {
      props: {
        Input,
        renderFooter,
        requestPopoverClose,
        shouldAutoFocusInput,
      },
      state: { isFooterSelected },
      footerRef,
    } = this;
    const footerProps = {
      isSelected: isFooterSelected,
      onClick: requestPopoverClose,
      ref: footerRef,
    };
    return (
      <div
        className={styles.body}
        onKeyDown={this.handleOnKeyDown}
        tabIndex={!this.props.Input ? 0 : undefined}
        ref='inputDiv'>
        {Input && <Input
          onChange={this.onChange}
          onFocus={this.onFocus}
          shouldAutoFocus={shouldAutoFocusInput}
          requestPopoverClose={requestPopoverClose}
          value={this.state.inputValue} />}
        {this.renderResults()}
        {renderFooter && renderFooter(footerProps)}
      </div>
    );
  }
}

export default Typeahead;
