import { fieldTypes, operatorsEnum } from 'constants/conditionals';
import { participantTypeEnum, SUBMITTER_PARTICIPATION_ORDER } from 'constants/participantsSidebar';
import {
  filterSubmitterFromParticipants,
  getFieldsAssignedToEarlierParticipants,
  getFrontendIdByParticipantId,
} from 'utils/participants';

import {
  ConditionalRule,
  ConditionalRuleField,
  ConditionalRuleFieldType,
  ConditionalRuleForServer,
  ConditionalRuleForServerV2,
  Expectation,
  Operator,
  ValidatedConditionalRule,
  ValidationErrors,
} from 'types/conditionals';
import { FormField, FormFields } from 'types/formFields';
import { ParallelGroup, Participant, ParticipantFromServer, Submitter } from 'types/participants';


export function createEmptyRule(frontendId: string, orderId: number): ConditionalRule {
  return {
    frontendId,
    orderId,
    ruleInputName: undefined,
    ruleOperator: undefined,
    ruleExpectation: {
      value: undefined,
    },
    // @ts-ignore
    actionValue: undefined,
    serverErrors: [],
  };
}

// Only certain field types can be used in a rule
function getConditionalFieldsFromFields(fields: FormFields): ConditionalRuleField[] {
  const validFieldTypes: ConditionalRuleFieldType[] = Object.values(fieldTypes);
  const validFields: ConditionalRuleField[] =
    fields.filter(field => validFieldTypes.includes(field.type as ConditionalRuleFieldType)) as ConditionalRuleField[];
  return validFields;
}

export const getConditionalFieldsForParticipant = (
  formFields: FormFields,
  participationOrder: number,
  submitter: Submitter | Participant,
  existingParticipants: Participant[],
): ConditionalRuleField[] => {
  // Conditionals can only be triggered by fields filled out by earlier participants
  const fieldsAssignedToEarlierParticipants = getFieldsAssignedToEarlierParticipants(
    formFields,
    participationOrder,
    submitter,
    existingParticipants,
  );
  return getConditionalFieldsFromFields(fieldsAssignedToEarlierParticipants);
};

function getInputNameError(
  ruleInputName: string | undefined,
  validFields: ConditionalRuleField[]
): string | null {
  if (!ruleInputName) return 'Field is required';
  const validField = validFields.find(field => field.id === ruleInputName);
  if (!validField) return 'Invalid form field selected';
  return null;
}

function getOperatorError(operator: Operator | undefined): string | null {
  if (!operator) return 'Operator is required';
  return null;
}

function getExpectationError(
  operator: Operator | undefined,
  expectation: Expectation,
  ruleInputName: string | undefined,
  formFields: FormFields,
): string | null {
  if (operator === operatorsEnum.hasAValue) return null;
  if (expectation.value === undefined) return 'Expectation is required';
  const inputField: FormField | undefined = formFields.find(field => field.id === ruleInputName);
  const fieldType = inputField?.type;
  switch (fieldType) {
    case fieldTypes.dropdown:
    case fieldTypes.radioButton:
      // @ts-ignore
      if (!inputField.options.find(option => option.id === expectation.value)) return 'Expected field value is invalid';
      return null;
    case fieldTypes.checkbox:
      // @ts-ignore
      if (!(expectation.value as string[]).every(val => !!inputField.options.find(option => option.id === val))) {
        return 'Expected field value is invalid';
      }
      return null;
    default:
      return null;
  }
}

function getActionValueError(actionValue: string | undefined): string | null {
  if (!actionValue) return 'Then value is required';
  return null;
}

export function getValidatedConditionalRule(
  rule: ConditionalRule,
  validFields: ConditionalRuleField[],
  participationOrder: number,
  groups: ParallelGroup[],
): ValidatedConditionalRule {
  // If the participant is the submitter, the rule is always invalid.
  if (participationOrder === SUBMITTER_PARTICIPATION_ORDER) {
    return { ...rule, validationErrors: { otherError: 'Submitter can\'t have conditionals rules'} };
  }

  // If the participant is in a group, the rule is always invalid.
  if (groups.some(group => group.participationOrder === participationOrder)) {
    return { ...rule, validationErrors: { otherError: 'Participants in a group can\'t have conditionals rules'} };
  }

  const validationErrors: ValidationErrors = {};

  const { ruleInputName, ruleOperator, ruleExpectation, actionValue } = rule;

  const inputNameError = getInputNameError(ruleInputName, validFields);
  inputNameError && (validationErrors.ruleInputName = inputNameError);

  const operatorError = getOperatorError(ruleOperator);
  operatorError && (validationErrors.ruleOperator = operatorError);

  const expectationError = getExpectationError(ruleOperator, ruleExpectation, ruleInputName, validFields);
  expectationError && (validationErrors.ruleExpectation = expectationError);

  const actionValueError = getActionValueError(actionValue);
  actionValueError && (validationErrors.actionValue = actionValueError);

  return {
    ...rule,
    validationErrors,
  };
}

export const getValidatedConditionalsForParticipant = (
  conditionalsForParticipant: ConditionalRule[],
  conditionalFieldsForParticipant: ConditionalRuleField[],
  participationOrder: number,
  groups: ParallelGroup[],
): ValidatedConditionalRule[] =>
  conditionalsForParticipant
    .map(rule => getValidatedConditionalRule(rule, conditionalFieldsForParticipant, participationOrder, groups));

export const getValidatedConditionalsForAllParticipants = (
  allConditionals: ConditionalRule[],
  formFields: FormFields,
  submitter: Submitter | Participant,
  existingParticipants: Participant[],
  groups: ParallelGroup[],
): ValidatedConditionalRule[] => {
  const validatedConditionals: ValidatedConditionalRule[] = [];
  const allParticipants = [submitter, ...existingParticipants];
  allParticipants.forEach(participant => {
    const validFieldsForParticipant = getConditionalFieldsForParticipant(
      formFields,
      participant.participationOrder,
      submitter,
      existingParticipants,
    );
    const conditionalsForParticipant = allConditionals.filter(rule => rule.frontendId === participant.frontendId);
    const validatedConditionalsForParticipant: ValidatedConditionalRule[] = getValidatedConditionalsForParticipant(
      conditionalsForParticipant,
      validFieldsForParticipant,
      participant.participationOrder,
      groups,
    );

    validatedConditionals.push(...validatedConditionalsForParticipant);
  });
  return validatedConditionals;
};

export const isValidConditional = (rule: ValidatedConditionalRule): boolean =>
  Object.keys(rule.validationErrors).length === 0;

export const areConditionalsValid = (rules: ValidatedConditionalRule[]): boolean =>
  rules.every(isValidConditional);

export const convertRulesForClient = (
  rules: ConditionalRuleForServer[],
  participants: Participant[],
): ConditionalRule[] =>
  rules?.map((rule: ConditionalRuleForServer) => {
    const frontendId = getFrontendIdByParticipantId(rule.participantId, participants);
    const ruleForClient: ConditionalRule = { ...rule, serverErrors: rule.errors || [], frontendId };
    return ruleForClient;
  });

export const extractRulesFromServerParticipants = (
  serverParticipants: ParticipantFromServer[],
  clientParticipants: (Submitter | Participant)[],
): ConditionalRule[] => {
  const rules: ConditionalRule[] = [];
  const nonSubmitterParticipants = filterSubmitterFromParticipants(clientParticipants);
  serverParticipants.forEach(serverParticipant => {
    const { rule } = serverParticipant;
    if (rule) {
      const frontendId = getFrontendIdByParticipantId(serverParticipant.participantId, nonSubmitterParticipants);
      const ruleForClient: ConditionalRule = {
        ...rule,
        serverErrors: rule.errors || [],
        frontendId,
        orderId: rules.length,
      };
      rules.push(ruleForClient);
    }
  });
  return rules;
};

export function convertRulesForServer(
  rules: ConditionalRule[],
  existingParticipants: Participant[],
  submitter: Submitter | Participant,
): ConditionalRuleForServer[] {
  const participants: Participant[] = submitter.participantType === participantTypeEnum.submitter
    ? existingParticipants
    : [submitter, ...existingParticipants];
  // @ts-ignore
  return rules
    .map(rule => ({
      ...rule,
      participantId: participants.find(participant =>
        participant.frontendId === rule.frontendId)?.id,
    }))
    .filter(rule => rule.participantId);
}

export const convertRuleForServerV2 = ({
  ruleInputName,
  ruleOperator,
  ruleExpectation,
  actionValue,
}: ConditionalRule): ConditionalRuleForServerV2 => ({
  ruleInputName,
  ruleOperator,
  ruleExpectation,
  actionValue,
});
