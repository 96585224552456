import React from 'react';
import Icon from '@material-ui/core/Icon';
import styles from './SubmissionDetails.css';
import { Motion, spring } from 'react-motion';


export default class FieldBox extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.destinationHeight = 161;
  }

  componentDidMount() {
    if (this.box && this.box.content) {
      this.destinationHeight = this.box.content.clientHeight;
    }
    this.forceUpdate();
  }

  box;
  destinationHeight;

  onOpenBox = () => {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { label, children } = this.props;
    const { open } = this.state;
    const initialHeight = 161;

    return (
      <div>
        <p className={styles.fieldsLabel}>{label}</p>
        <div className={styles.fieldsContentBox}>
          <Motion
            style={{
              height: spring(open ? this.destinationHeight : initialHeight, { stiffness: 140, damping: 19 }),
              rotate: spring(open ? 0 : 180, { stiffness: 210, damping: 25 }),
            }}>
            {({ height, rotate }) =>
              <div>
                <div className={styles.fieldsContentBoxBody} style={{ height }}>
                  {children && React.cloneElement(children as any, { ref: c => { this.box = c; } })}
                </div>
                {this.destinationHeight > initialHeight && <div className={styles.expandBox} onClick={this.onOpenBox}>
                  <Icon style={{ transform: `rotate(${rotate}deg)` }} classes={{ root: styles.expandBoxIcon }}>
                    keyboard_arrow_up
                  </Icon>
                </div>}
              </div>
            }
          </Motion>
        </div>
      </div>
    );
  }
}
