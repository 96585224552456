import {Observable} from 'rxjs/Observable';
import {LOAD_RULES} from 'constants/types/automatedProcessBuilderActionTypes';
import {pending} from './utils/filters';
import {getAllUsers} from 'actions/users';
import {getTags} from 'actions/tags';
import {getStagesOfForm} from 'actions/stages';
import {getUserMap, getHaveTagsLoaded, getStageState, getEmailFieldMap, getSignerFieldMap} from '../reducers';
import {getEmailFieldsOfForm, getSignersFieldsOfForm} from 'actions/automatedProcessBuilder';


export default function apbSideloadEpic(action$, store) {
  const loadRuleAction$ = action$.ofType(LOAD_RULES).filter(pending);

  const getUsersOnSuccess = (_, {response: {users}}) => ({users});
  const assignmentsAction$ = loadRuleAction$
    .filter(() => {
      const state = store.getState();
      const userMap = getUserMap(state);
      return Object.keys(userMap).length === 0;
    })
    .mergeMap(() => Observable.of(getAllUsers(getUsersOnSuccess)));

  const tagAction$ = loadRuleAction$
    .filter(() => {
      const state = store.getState();
      const haveTagsLoaded = getHaveTagsLoaded(state);
      return !haveTagsLoaded;
    })
    .mergeMap(() => Observable.of(getTags()));

  const stageAction$ = loadRuleAction$
    .filter(action => {
      const state = store.getState();
      const stageMap = getStageState(state);
      return !stageMap[action.payload.formId];
    })
    .mergeMap(action => Observable.of(getStagesOfForm(action.payload.formId)));

  const emailFieldsAction$ = loadRuleAction$
    .filter(action => {
      const state = store.getState();
      const emailFieldMap = getEmailFieldMap(state);
      return !emailFieldMap[action.payload.formId];
    })
    .mergeMap(action => Observable.of(getEmailFieldsOfForm(action.payload.formId)));

  const signersFieldsAction$ = loadRuleAction$
    .filter(action => {
      const state = store.getState();
      const signerFieldMap = getSignerFieldMap(state);
      return !signerFieldMap[action.payload.formId];
    })
    .mergeMap(action => Observable.of(getSignersFieldsOfForm(action.payload.formId)));

  return assignmentsAction$.merge(tagAction$).merge(stageAction$).merge(emailFieldsAction$).merge(signersFieldsAction$);
}
