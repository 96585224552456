import React from 'react';
import { Cell, Column } from 'fixed-data-table-2';

import { Parsed } from 'types/search';
import { Action as ApiAction } from 'types/api';
import { FieldMap, FormData, Thunk } from 'types/shared';
// import type {MakeSortable} from './shared';
import { State as FiltersState, SubmissionFilter } from './submissionFilters';
import { State as SortingState } from './submissionSorting';
import { ConnectDropTarget } from 'types/react-dnd';
import { submissionManagerPermissions } from 'constants/submissionManager';
import {
  ALL_SUBMISSION_FILTER_INITIAL_LOAD,
  ALL_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
} from 'constants/types/submissionsTableActionTypes';
import { ChangeStage } from 'types/stages';
import RecordCell from 'components/SubmissionTable/RecordCell';
import SortFilterDropdown from 'components/SubmissionTable/SortFilterDropdown';

export type RecordDetailType = {
  input_name: string,
  label: string,
  raw_value: string,
};

export type RecordNodesType = {
  [key: string]: {
    field_map: FieldMap,
    input_name: string,
    isEncrypted?: boolean,
    label: string,
    position?: { height: number, left: number, top: number, width: number } | null | undefined,
    raw_value: string,
  },
};

export type Attachment = {
  label: string,
  url: string,
};
export type Attachments = Attachment[];

export type SignatureStatus = 'COMPLETE' | 'PENDING' | 'INCOMPLETE(PENDING)' | 'DECLINED' | '';

export const signatureStatusValuesEnum = {
  complete: 'Complete',
  pending: 'Pending',
  declined: 'Declined',
}

export const paymentStatusValuesEnum = {
  void: 'cancelled',
  processed: 'processed',
  preAuthorized: 'pre-authorized',
  refunded: 'refunded',
}

type RecipientInfoType = {
  email: string,
  fullname: string,
};

export type UserAgentXML = {
  browser: string,
  is_mobile: string | boolean,
  mobile: string,
  platform: string,
  robot: string,
  version: string,
};

export type SignatureRecipientType = {
  color: string,
  datesigned: string,
  decline_reason?: string,
  email?: string,
  form_id: string,
  fullname?: string,
  ip_address: string,
  key: string,
  original_recipient_info: RecipientInfoType,
  pdf_id: string,
  readable_time: string,
  ready_to_sign?: boolean,
  recipient_info?: RecipientInfoType,
  recipient_type: string,
  reference: string,
  referrer_url: string,
  signature: string,
  signer_key?: string,
  unique_key?: string,
  signer_type: string,
  status?: string,
  user_agent_xml: UserAgentXML,
  user_id: string,
};

export type SignatureHistory = {
  preparer: SignatureRecipientType | null | undefined,
  recipients: SignatureRecipientType[],
  signer_key?: string,
  status: SignatureStatus | null | undefined,
  totalCompletedSigners: number,
  totalSigners: number,
};

export type PaymentType = {
  integrationType?: 'spreedly',
  actionCreatedTs: string,
  actionResponse: string | null | undefined,
  amount: number,
  cardholderName: string,
  currency: string,
  gatewayFields: string | null | undefined,
  gatewayName: string,
  paymentsConfigId: string,
  paymentToken: string,
  status: string,
  submissionId: string,
  transactionId: string,
  transactionToken: string,
  transactionType: string,
  baseAmount: number,
  feeAmount: number | null | undefined,
  feePercentage: number | null | undefined,
  feeTotal: number | null | undefined,
  customFeeName: string | null | undefined,
  paymentType: 'credit_card' | 'ach',
  description?: string | null | undefined,
};

export type GovOSPaymentType = {
  integrationType: 'govos',
  amount: number,
  baseAmount: number,
  correlationId: string,
  createdTs: string,
  customFeeName: string | null | undefined,
  feeAmount: number | null | undefined,
  feePercentage: number | null | undefined,
  feeTotal: number | null | undefined,
  govOsPayConfigId: string,
  httpResponse: any,
  paymentType: 'credit_card' | 'ach',
  platformTransactionId: string | null,
  response: any,
  submissionId: string,
  transactionStatus: string,
};

export type PaymentCellData = {
  isActive: boolean,
  amount: number,
  status: string,
};

export type SelectedRowDataType = {
  assignees: string[],
  attachments: Attachments,
  createdTs: string,
  govOsPayments?: GovOSPaymentType[],
  idVerificationData: IdVerificationType[],
  isActive?: boolean,
  isArchived?: boolean,
  isIncomplete: boolean | null | undefined,
  modifiedTs: string,
  nodes: RecordNodesType,
  payment: RecordDetailType[],
  payments: PaymentType[],
  permissions?: typeof submissionManagerPermissions[keyof typeof submissionManagerPermissions][],
  signatureHistory: SignatureHistory,
  signatureStatus?: SignatureStatus | null | undefined,
  stageName: string | null | undefined,
  submissionData: RecordDetailType[],
  submissionId: string,
  submissionPDFUrl: string | null | undefined,
  tag?: string | null | undefined,
  tags: string[],
};

export type TableType = {
  labels: { [key: string]: string },
  records: SelectedRowDataType[],
  size: number,
  isSearching?: boolean,
  offset?: number,
  nextPageToken?: string,
  requestTime?: Date,
  sortingFromServer: string[],
  totalSubmissions: number,
  totalSubmissionsForFilter: number,
};

export type SnackbarState = {
  changeStage: ChangeStage,
  isChangeStageNotification: boolean,
  isSnackbarOpen?: boolean,
  snackbarMessage?: string,
};

export type SubmissionTableType = {
  archivingCheckedIndex?: number[] | null | undefined,
  archivingSelectedIndex?: number | null | undefined,
  archivingType?: 'checkbox' | 'select' | '',
  cachedLoadedSubmissions?: number | null | undefined,
  cachedTable?: TableType | null | undefined,
  cachedTotalSubmissions?: number | null | undefined,
  cachedTableSubmissionFilters?: SubmissionFilter[] | null | undefined,
  cachedTableSortingState?: SortingState | null | undefined,
  error: {},
  failure: boolean,
  fetching: boolean,
  fetchingMoreResults: boolean,
  formDetails: FormData | null | undefined,
  formId: string,
  headerLabel: string,
  isAllSelected: boolean,
  isDisplayingSearchResults: boolean,
  isSearching: boolean,
  isStagesSidebarExpanded: boolean,
  isTableSortable: boolean,
  loadedSubmissions: number,
  requestTime?: Date,
  searchbarInput?: Parsed,
  selectedRecordIndices: number[],
  selectedRecords: SelectedRowDataType[],
  showSortingArrowIndex: number,
  snackbarState: SnackbarState,
  sortingTargetIndex: number,
  submissionStatusError: boolean,
  table: TableType,
  totalSubmissions: number,
  totalSubmissionsForFilter: number,
  archivedCount: number,
  deletedCount: number,
  isSubmissionsLoading: boolean,
  isArchiveView: boolean,
  isStagesBulkActionsDropdownVisible: boolean,
  isAssigneesBulkActionsDropdownVisible: boolean,
  isTagsBulkActionsDropdownVisible: boolean,
  bulkActionState: {
    isOpened: boolean,
    modalType: string,
    entityType: string,
  },
  filtersState: FiltersState,
  sortingState: SortingState,
};

export type HandleSetHoverIndex = (index: number) => void;
export type HandleScrollOnDrag = (index: number, isScrollingRight?: boolean) => void;

export type TableSortProps = {
  connectDropTarget: ConnectDropTarget,
  formId: string,
  headerLabel: string,
  isCurrentFilterBarPresent: boolean,
  isIncompleteOrHasSubmissionPdfUrl: boolean,
  isTableSortable: boolean,
  // makeSortable: MakeSortable,
  makeSortable: any,
  scrollLeft: number,
  scrollOnDrag: HandleScrollOnDrag,
  scrollOnDragStop: Thunk,
  sortingTargetIndex: number,
  sortTableColumns: (prevIndex: number, nextIndex: number, sorting: string[], formId: string) => ApiAction,
  source: string[],
  tableSortTop: number,
  width: number,
};

export type TableSortState = {
  boxLeft: number,
  dragScrollingLeft: boolean,
  dragScrollingRight: boolean,
  hoverOverIndex: number | null | undefined,
};

export type RecordDetailsType = {
  createdTs?: string,
  modifiedTs: string,
  payment?: {
    paymentDetail: RecordDetailType[],
  },
  signatureHistory: SignatureHistory,
  signatureStatus: string,
  submissionData: RecordDetailType[],
  submissionId: string,
  formId: string,
  submissionPDFUrl: string | null | undefined,
};

export type SubmissionsType = {
  decryptionData?: {
    inputName: string,
    rawValue: string,
    rowIndex: number,
  },
  decryptModalVisible: boolean,
  downloadOptions: {},
  downloadVisible: boolean,
  editSignerVisible: boolean,
  error?: {},
  isPaymentProcessPending: boolean,
  loaderBody?: string,
  loaderStatus?: string,
  loaderTitle?: string,
  loaderVisibile: boolean,
  recordDetails?: RecordDetailsType,
  refundVisible: boolean,
  scrollToRow: number | null | undefined,
  searchbarInput: Parsed,
  searchbarOpen: boolean,
  selectedRowIndex: number | null | undefined,
  sidebarOpen: boolean,
  signerDetailsVisible: boolean,
  toolbarMobileMenuVisible: boolean,
};

export type TableFromRecordsType = {
  labels: { [key: string]: string },
  records: SelectedRowDataType[],
};

type AnyTableJSON = {
  fieldOrdering?: string[],
  isSearching: boolean,
  offset: number,
  records: SelectedRowDataType[],
  requestTime: Date,
};

export type TableJSON = AnyTableJSON & {
  formMetadata?: {
    formAlias: string,
    formCreated: string,
    formId: string,
    formName: string,
  },
  totalVisibleSubmissionsForFilter: number,
  totalVisibleSubmissionsForForm: number,
};

export type SearchTableJSON = AnyTableJSON & {
  estimatedHits: number,
};

export type ColumnComponentsType = {
  Cell: Cell,
  CheckboxCell: React.ComponentClass<any, any>,
  Column: Column,
  RecordCell: typeof RecordCell,
  SubmissionCellIcon: React.ComponentClass<any>,
  SortFilterDropdown: typeof SortFilterDropdown,
};

export type ActionPayload = {
  showSortingArrow: boolean,
  sortingTargetIndex: number,
};

export type DecryptionAction = {
  payload: {
    inputName: string,
    result: string,
  },
};

// This is unused for now, because making the reducer use a well-typed
// Action is a small project. But well-typing new actions seems like
// a good way to make this easier down the road.
export type QuickFilterPayload = TableType & { nextPageToken: string };

export type QuickFilterSuccess = {
  payload: QuickFilterPayload,
  status: 'success',
};

export type AllQuickFilterInitialSuccessAction = QuickFilterSuccess & { type: typeof ALL_SUBMISSION_FILTER_INITIAL_LOAD };

export type AllQuickFilterSubsequentSuccessAction = QuickFilterSuccess & {
  type: typeof ALL_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
};

export type Action = AllQuickFilterInitialSuccessAction | AllQuickFilterSubsequentSuccessAction;

export type IdVerificationType = {
  id: string
  participantId: string
  submissionId: string
  inquiryId: string
  maid: string
  formId: string
  idVerificationType: string
  status: string
  userRequestTimeout: boolean
  formattedWebhookPayload: FormattedWebhookPayload[]
  createdTs: string
  modifiedTs: string,
  formElementId: string | null
}

export type FormattedWebhookPayload = {
  name: string
  value: string
}
