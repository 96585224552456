import React from 'react';
import CrossMarkIcon from './CrossMarkIcon';
import { getColorsForUserId, getInitials } from 'utils/assignees';

import { blueGrey500, red50, red500, black } from 'utils/colors';
import styles from './User.css';


const getInlineStyles = (userId, wide, isActive) => {
  if (!isActive) {
    return {
      backgroundColor: red50,
      borderColor: red500,
      color: black,
    };
  }
  const { backgroundColor, color } = getColorsForUserId(userId);
  if (wide) {
    return {
      borderColor: color,
      color: blueGrey500,
    };
  }
  return {
    borderColor: color,
    backgroundColor,
    color,
  };
};

export default ({
  user: { avatar, email, fullName, userId, isActive },
  hasHoverInteraction,
  onCrossmarkClick,
  wide = false,
}) => {
  const name = fullName && fullName.trim() || email;
  const inlineStyles = getInlineStyles(userId, wide, isActive);
  return (
    <div className={`${styles.bubble} ${wide ? styles.wide : ''}`} style={inlineStyles}>
      {avatar
        ? <img alt={name} className={styles.avatar} src={avatar} />
        : <span className={styles.initials}>{wide ? name : getInitials(name || '')}</span>}
      {hasHoverInteraction && (
        <CrossMarkIcon
          onClick={onCrossmarkClick}
          className={styles.crossmark}
          color={inlineStyles.color}
          size={wide ? 12 : 18} />
      )}
    </div>
  );
};
