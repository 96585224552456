export const CHANGE_AUTHOR = 'EMBED_PERMISSIONS_CHANGE_AUTHOR';
export const CHANGE_PERMISSION_LEVEL = 'EMBED_PERMISSIONS_CHANGE_PERMISSION_LEVEL';
export const CHANGE_REQUIRE_PASSWORD = 'EMBED_PERMISSIONS_CHANGE_REQUIRE_PASSWORD';
export const CHANGE_REQUIRE_VISITOR_REGISTRATION = 'EMBED_PERMISSIONS_CHANGE_REQUIRE_VISITOR_REGISTRATION';
export const CHANGE_USER = 'EMBED_PERMISSIONS_CHANGE_USER';
export const GET_FORM_PERMISSIONS = 'EMBED_PERMISSIONS_GET_FORM_PERMISSIONS';
export const INITIALIZE_PERMISSIONS = 'EMBED_PERMISSIONS_INITIALIZE_PERMISSIONS';
export const SAVE_PERMISSIONS = 'EMBED_PERMISSIONS_SAVE_PERMISSIONS';
export const TOGGLE_MODAL = 'EMBED_PERMISSIONS_TOGGLE_MODAL';
export const TOGGLE_SNACKBAR = 'EMBED_PERMISSIONS_TOGGLE_SNACKBAR';

export const SAVE_UPDATING_PERMISSIONS_FORM_ID = 'SAVE_UPDATING_PERMISSIONS_FORM_ID';
export const DELETE_UPDATING_PERMISSIONS_FORM_ID = 'DELETE_UPDATING_PERMISSIONS_FORM_ID';

export const permissionLevelsEnum = {
  EditForm: 'EditForm',
  ShareForm: 'ShareForm',
  ProcessSubmissionsForm: 'ProcessSubmissionsForm',
  ShareAllFormSubmissions: 'ShareAllFormSubmissions',
};
export const REMOVE_PERMISSION = 'EMBED_PERMISSIONS_REMOVE_PERMISSION';
export const ADD_ROLE = 'PERMISSIONS_ADD_ROLE';
export const REMOVE_ROLE = 'PERMISSIONS_REMOVE_ROLE';
export const CHANGE_ROLE_PERMISSION_LEVEL = 'PERMISSIONS_CHANGE_ROLE_LEVEL';
export const CHANGE_ALLOW_VISITOR_REGISTRATION = 'CHANGE_ALLOW_VISITOR_REGISTRATION';
export const CHANGE_ACCESS_CODE_ENABLED = 'CHANGE_ACCESS_CODE_ENABLED';
