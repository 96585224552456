/*
 * Note: this is primarily intended for IE 9 & 10. It does *not* work on IE8 (none of NG does).
 */
import React from 'react';
import styles from './styles.css';
import { imageDir } from 'constants/assets';
import { COMPANY_NAME } from 'constants/applicationCopy';
import BrowserCard from './BrowserCard';
import brandLogoFull from 'images/brand-logo-full.svg';


export default class UpgradeBrowser extends React.Component {
  render() {
    return (
      <div className={styles.pageContainer}>
        <header className={styles.headerContainer}>
          <img alt={COMPANY_NAME} src={brandLogoFull} />
        </header>
        <section className={styles.contentContainer}>
          <img alt='' className={styles.oldBrowserDoc} src={`${imageDir}/ng-outdated-doc.png`} />
          <span className={styles.plea}>
            Your browser is out-of-date. Please download one of these up-to-date, free, and excellent browsers:
          </span>
          <BrowserCard
            company='Mozilla Foundation'
            imageURL={`${imageDir}/ng-logo-firefox.jpg`}
            linkURL='https://www.mozilla.org/en-US/firefox/new/'
            name='Firefox' />
          <BrowserCard
            company='Google'
            imageURL={`${imageDir}/ng-logo-chrome.jpg`}
            linkURL='https://www.google.com/chrome/browser/desktop/'
            name='Chrome'
            recommended />
          <BrowserCard
            company='Microsoft'
            imageURL={`${imageDir}/ng-logo-ie.jpg`}
            linkURL='https://windows.microsoft.com/en-us/internet-explorer/download-ie'
            name='IE' />
          <BrowserCard
            company='Apple'
            imageURL={`${imageDir}/ng-logo-safari.jpg`}
            linkURL='https://itunes.apple.com//app/os-x-yosemite/id915041082?mt=12'
            name='Safari' />
        </section>
      </div>
    );
  }
}
