import React from 'react';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { sendVerifyEmail, verifyEmail } from 'utils/auth';
import NewAuthLayout from '../../components/AuthLayout/NewAuthLayout';


import RedirectOnLogin from 'containers/RedirectOnLogin';


const LAST_VERIFY_KEY = 'lastVerify';
const CLICK_TIMEOUT = 10000; // 10 seconds

class VerifyEmail extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      tokenError: '',
      email: this.props.location.query.email || '',
    };
  }
  componentDidMount() {
    const {
      dispatch,
      // maid,
      params: { token },
      /* router, location*/
    } = this.props;
    if (token) {
      try {
        const jwt = jwtDecode(token);
        const expired = (jwt.exp * 1000) < Date.now();
        const tokenError = expired ? 'Your verification link has expired.' : '';

        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ email: jwt.email });

        if (tokenError) {
          this.setTokenError(tokenError);
        } else {
          verifyEmail(token, dispatch);
          // TODO: login && redirect to lobby/deep link on success
        }
      } catch (e) {
        this.setTokenError('This verification link is invalid.');
      }
    }
  }

  setTokenError = tokenError => {
    this.setState({ tokenError });
  }

  sendVerifyEmail = event => {
    event.preventDefault();
    const { dispatch, maid } = this.props;
    const { email } = this.state;
    sendVerifyEmail(email, maid, dispatch);
    this.setLastVerifyEmail(Date.now());
    this.setState({});
    // throttle clicks
    setTimeout(() => {
      this.setLastVerifyEmail();
      this.setState({});
    }, CLICK_TIMEOUT);
  };

  setLastVerifyEmail = (value?: string | number) => {
    value ?
      sessionStorage.setItem(LAST_VERIFY_KEY, '' + value) :
      sessionStorage.removeItem(LAST_VERIFY_KEY);
  }

  getLastVerifyEmail = () => sessionStorage.getItem(LAST_VERIFY_KEY);

  getSubtitle = () => {
    const { email, tokenError } = this.state;
    const { emailVerifyError } = this.props;
    if (!(tokenError || emailVerifyError)) {
      return (
        <>
          Your account needs to be verified. We sent a verification email to <strong>{email}</strong>.
          <br />
          <br />
          If you can&apos;t find the email, please check your spam folder or click the button below.
        </>
      );
    }
    return (<>{tokenError || emailVerifyError}</>);
  }

  redirect() {
    const { location, maid, emailVerifySuccess } = this.props;
    return !maid || emailVerifySuccess ? (<RedirectOnLogin location={location} />) : null;
  }
  render() {
    const {
      isFetchingOrganizationInfo,
      isSubdomainExists,
      companyName,
      organizationLogo,
    } = this.props;
    const lastClicked = this.getLastVerifyEmail();
    const disabled = !!(lastClicked && (Date.now() - parseInt(lastClicked, 10) <= CLICK_TIMEOUT));
    return (
      <>
        {this.redirect()}
        <NewAuthLayout
          title='Verify Your Email'
          subtitle={this.getSubtitle()}
          fetching={isFetchingOrganizationInfo || !isSubdomainExists}
          companyName={companyName}
          logo={organizationLogo}
        >
          <button className='authButton' disabled={disabled} onClick={this.sendVerifyEmail}>Resend Verification Email</button>
        </NewAuthLayout>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      accountKey,
      companyName,
      emailVerifyError,
      emailVerifySuccess,
      error,
      isFetching,
      isFetchingOrganizationInfo,
      isSubdomainExists,
      maid,
      organizationLogo,
      sendVerifyEmailError,
      sendVerifyEmailSuccess,
    },
  } = state;
  return {
    accountKey,
    companyName,
    emailVerifyError,
    emailVerifySuccess,
    error,
    isFetching,
    isFetchingOrganizationInfo,
    isSubdomainExists,
    maid,
    organizationLogo,
    sendVerifyEmailError,
    sendVerifyEmailSuccess,
  };
};

export default connect(mapStateToProps)(VerifyEmail);
