import React from 'react';
import Popover from 'components/Popover';
import KeyboardNavigationWrapper from './KeyboardNavigationWrapper';
import styles from './Dropdown.css';

export type Item<T> = {
  Component: React.ComponentType<any>,
  props: {
    data?: T,
    onClick: (data?: T) => void,
  },
};

type Props<T> = {
  isEnabled: boolean,
  isOpen: boolean,
  items: Item<T>[],
  renderTriggerButton: () => JSX.Element,
  requestClose: () => void,
  requestOpen: () => void,
  tabIndex: string,
  widthClassName?: string,
};


const Dropdown = <T, >(props: Props<T>) => {
  const {
    items,
    isEnabled,
    isOpen,
    renderTriggerButton,
    requestClose,
    requestOpen,
    tabIndex,
    widthClassName,
  } = props;
  return (
    <Popover
      isEnabled={isEnabled}
      isOpen={isOpen}
      requestClose={requestClose}
      requestOpen={requestOpen}
      widthClassName={widthClassName ? widthClassName : styles.dropdownWidth}
      renderTriggerButton={renderTriggerButton}
      tabIndex={isEnabled ? tabIndex : undefined}>
      <KeyboardNavigationWrapper
        items={items}
        onEscOrTabKey={requestClose}/>
    </Popover>
  );
};

Dropdown.defaultProps = {
  isEnabled: true,
  tabIndex: '0',
};
export default Dropdown;
