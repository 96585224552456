import {
  // ContentBlock,
  // ContentState,
  DefaultDraftBlockRenderMap,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js';
import {isLink, linkFilter} from 'utils/richTextEditor/draft';
import styleMaps from './styleMaps';
import 'draft-js/dist/Draft.css';
import styles from './styles.css'; // This comes after Draft.css so it can override styles


export const maxTabDepth = 4;

export const getLinkEntityData = (contentState, linkEntityKey) =>
  contentState.getEntity(linkEntityKey).getData();

export const getLinkEntityKey = editorState => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const block = contentState.getBlockForKey(selection.getStartKey());
  const offset = selection.getEndOffset();
  if (offset > 0) {
    const key = block.getEntityAt(offset - 1);
    if (isLink(contentState, key)) {
      return key;
    }
  }
  return null;
};

export const createLinkEntity = (contentState, data) =>
  contentState.createEntity('LINK', 'MUTABLE', data).getLastCreatedEntityKey();

export const updateLinkEntity = (contentState, key, data) =>
  /*
   * This method is an exception to the "immutable" paradigm. Blame Draft.
   */
  contentState.mergeEntityData(key, data);

export const blockRenderMap = DefaultDraftBlockRenderMap.merge({
  'align-left': {
    element: 'div',
  }, 'align-center': {
    element: 'div',
  }, 'align-right': {
    element: 'div',
  },
});

export const blockStyleFn = block => {
  switch (block.getType()) {
    case 'align-left':
      return styles.alignLeft;
    case 'align-center':
      return styles.alignCenter;
    case 'align-right':
      return styles.alignRight;
    case 'blockquote':
      return styles.blockquote;
    case 'code-block':
      return styles.codeBlock;
    default:
      return null;
  }
};

export const forEachCurrentLinkEntity = (
  editorState,
  callback
) => {
  /*
   * Run callback over each link offset range within a content block.
   * Bails if selection covers multiple content blocks.
   */
  const selection = editorState.getSelection();
  const blockKey = selection.getStartKey();
  if (blockKey === selection.getEndKey()) {
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(blockKey);
    const selectionStart = selection.getStartOffset();
    const selectionEnd = selection.getEndOffset();
    block.findEntityRanges(char => linkFilter(contentState, char), (start, end) => {
      if (!(selectionEnd < start || selectionStart > end)) {
        callback(start, end, block);
      }
    });
  }
};

export const currentLastLinkOffsets = editorState => {
  /*
   * Returns the offsets of the last link the the selected range.
   */
  let lastStart = null;
  let lastEnd = null;
  forEachCurrentLinkEntity(editorState, (start, end) => {
    lastStart = start;
    lastEnd = end;
  });
  return {start: lastStart, end: lastEnd};
};

export const isSpecificLinkSelected = editorState => {
  /*
   * "Did user unambiguously select a *specific* link?"
   * True if
   *  - cursor is placed on a link
   *  - user is selecting a range of text that is a subset of a single link.
   * False if:
   *  - a range of text is selected that includes a link, but also non-link text
   *  - a range of text is selected that includes multiple links
   */
  const selection = editorState.getSelection();
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();
  if (selection.isCollapsed()) {
    const blockKey = selection.getStartKey();
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(blockKey);
    if (startOffset > 0) {
      return isLink(contentState, block.getEntityAt(startOffset - 1));
    }
  } else {
    let linksCount = 0;
    let exceedsLinkBoundaries = false;
    forEachCurrentLinkEntity(editorState, (start, end) => {
      if (startOffset < start || endOffset > end) {
        exceedsLinkBoundaries = true;
      }
      linksCount++;
    });
    if (linksCount === 1 && !exceedsLinkBoundaries) {
      return true;
    }
  }
  return false;
};

export const correctSelection = editorState => {
  /*
   *  - When double clicking on a line, draft will sometimes move the end of the selection
   *    to the beginning of the next block. The `nextKey` check is to account for this.
   *  - If we are in fact overlapping onto the following block due to a double click,
   *    for some purposes we need to "correct" the selection to end on the main block.
   */
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const nextKey = editorState.getCurrentContent().getKeyAfter(startKey);
  if (startKey !== endKey) {
    if (selection.getEndOffset() === 0 && endKey === nextKey) {
      const endOffset = editorState.getCurrentContent().getBlockForKey(startKey).getLength();
      if (selection.getIsBackward()) {
        return EditorState.acceptSelection(editorState, selection.merge({
          anchorKey: startKey,
          anchorOffset: endOffset,
        }));
      }
      return EditorState.acceptSelection(editorState, selection.merge({
        focusKey: startKey,
        focusOffset: endOffset,
      }));
    }
  }
  return editorState;
};

export const isUseableNonLinkTextSelected = editorState => {
  /*
   * "Did user select a range of text that can reasonably be turned into a link?"
   * Criteria:
   *  - text isn't empty
   *  - text does not currently include any links
   *  - text does not span multiple blocks
   */
  if (!editorState.getSelection().isCollapsed()) {
    const correctedEditorState = correctSelection(editorState);
    const selection = correctedEditorState.getSelection();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    if (startKey === endKey) {
      const selectionStart = selection.getStartOffset();
      const selectionEnd = selection.getEndOffset();
      let linksCount = 0;
      forEachCurrentLinkEntity(correctedEditorState, (start, end) => {
        if (end === selectionStart || start === selectionEnd) {
          return;
        }
        linksCount++;
      });
      return !linksCount;
    }
  }
  return false;
};

const currentLastLinkData = editorState => {
  let data = null;
  if (isSpecificLinkSelected(editorState)) {
    forEachCurrentLinkEntity(editorState, (start, end, block) => {
      const entityKey = block.getEntityAt(start);
      if (entityKey) {
        data = getLinkEntityData(editorState.getCurrentContent(), entityKey);
      }
    });
  }
  return data || Object.freeze({});
};

export const currentLinkText = editorState => {
  const data = currentLastLinkData(editorState);
  // @ts-ignore
  return data.display || null;
};

export const currentLinkURL = editorState => {
  const data = currentLastLinkData(editorState);
  // @ts-ignore
  return data.URL || null;
};

export const moveSelection = (editorState, selection, offset) =>
  EditorState.acceptSelection(editorState, selection.merge({
    anchorOffset: offset,
    focusOffset: offset,
  }));

export const updateLinkText = (
  editorState,
  key,
  text,
  data
) => {
  /*
   * Given the key of an existing link entity and some new text:
   *  - replace the current visible text of the link with the new text
   *  - move the cursor to the end of the new text
   * Deletes the link entity if the new text is empty.
   *
   * NB: Assumes that the conditions of `isSpecificLinkSelected` are met;
   *     in particular that only one block is selected.
   */
  const contentState = editorState.getCurrentContent();
  updateLinkEntity(contentState, key, data);
  const blockKey = editorState.getSelection().getStartKey();
  const {start, end} = currentLastLinkOffsets(editorState);
  const stringText = text || '';
  const selection = SelectionState.createEmpty(blockKey).merge({
    // @ts-ignore
    anchorOffset: start,
    focusOffset: end,
  });
  const newEditorState = EditorState.set(editorState, {
    currentContent: Modifier.replaceText(contentState, selection, stringText, undefined, key),
  });
  return moveSelection(newEditorState, selection, start + stringText.length);
};

export const createLinkText = (editorState, text, URL) => {
  /*
   * Creates a new link entity to go with new text. Inserts either the link name or url as text.
   * Moves the cursor to the end of the new text.
   */
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const offset = selection.getEndOffset();
  const key = createLinkEntity(contentState, {URL, display: text});
  const inserted = text || URL || '';
  const newEditorState = EditorState.set(editorState, {
    currentContent: Modifier.insertText(contentState, selection, inserted, undefined, key),
  });
  return moveSelection(newEditorState, selection, offset + inserted.length);
};

export const removeLinkFromText = (editorState, start, end) => {
  /*
   * Given a range (assumed to be of text within a single block)
   * remove the attached entity (assumed to be a link entity)
   */
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection().merge({
    anchorOffset: start,
    focusOffset: end,
  });
  return EditorState.set(editorState, {
    currentContent: Modifier.applyEntity(contentState, selection, null),
  });
};


const toggleMutexStyle = (editorState, type, mutexWith) => {
  const selection = editorState.getSelection();

  const currentContent = mutexWith.reduce((contentState, style) =>
    Modifier.removeInlineStyle(contentState, selection, style)
  , editorState.getCurrentContent());

  let nextEditorState = EditorState.set(editorState, {currentContent});

  const currentStyle = editorState.getCurrentInlineStyle();

  if (selection.isCollapsed()) {
    nextEditorState = currentStyle
      .filter(style => ~mutexWith.indexOf(style))
      .reduce(RichUtils.toggleInlineStyle, nextEditorState);
  }

  if (!currentStyle.has(type)) {
    nextEditorState = RichUtils.toggleInlineStyle(
      nextEditorState,
      type
    );
  }

  return nextEditorState;
};

export const toggleColorStyle = (editorState, type) => {
  const FONT_COLOR_KEYS = Object.keys(styleMaps.colorStyleMap);
  return toggleMutexStyle(editorState, type, FONT_COLOR_KEYS);
};

export const toggleHeaderStyle = (editorState, type) => {
  const HEADER_KEYS = Object.keys(styleMaps.headerStyleMap);
  return toggleMutexStyle(editorState, type, HEADER_KEYS);
};
