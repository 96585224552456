import React from 'react';
import Popover from 'components/Popover';
import Typeahead from './Typeahead';
import { getAnchorPosition } from 'utils/popover';


class PopoverTypeahead extends React.Component<any, any> {
  static defaultProps = {
    allowPickingMultipleItems: true,
    peak: null,
    shouldRepositionForMobile: true,
  };
  state = { isPopoverOpen: false }

  componentDidUpdate(prevProps) {
    if (!this.props.avoidForcedReposition && prevProps.typeaheadResults !== this.props.typeaheadResults) {
      const {current}: {current: any} = this.childPopover;
      current?.actions?.updatePosition();
    }
  }

  childPopover = React.createRef();
  triggerButtonRef = React.createRef();

  renderShowTypeaheadButton = () => {
    const { ShowTypeaheadButton } = this.props;
    return <ShowTypeaheadButton />;
  };

  focusTriggerButton = () => {
    const triggerButtonRef = this.triggerButtonRef.current;
    if (triggerButtonRef) (triggerButtonRef as any).focus();
  }

  onAddSelections(
    selectionsData,
    handlePopoverClose,
    onAddSelectionsParent
  ) {
    handlePopoverClose();
    onAddSelectionsParent(selectionsData);
  }

  handleClosePopover = () => this.setState({ isPopoverOpen: false }, this.focusTriggerButton);
  handleOpenPopover = () => this.setState(prevState => ({ isPopoverOpen: !prevState.isPopoverOpen }));

  render() {
    const {
      allowPickingMultipleItems,
      horizontalOffset,
      isLoading,
      onInputTypeahead,
      popoverOrigin,
      renderFooter,
      resultsListClassName,
      renderSelection,
      peak,
      shouldRepositionForMobile,
      TypeaheadInput,
      typeaheadResults,
    } = this.props;
    return (
      <Popover
        transformOrigin={popoverOrigin}
        anchorOrigin={getAnchorPosition(popoverOrigin, horizontalOffset) as any}
        hasPeak={!!peak}
        isOpen={this.state.isPopoverOpen}
        triggerButtonRef={this.triggerButtonRef}
        ref={this.childPopover as any}
        renderTriggerButton={this.renderShowTypeaheadButton}
        requestClose={this.handleClosePopover}
        requestOpen={this.handleOpenPopover}
        shouldRepositionForMobile={shouldRepositionForMobile}
        tabIndex='0'>
        <React.Fragment>
          {peak}
          <Typeahead
            allowPickingMultipleItems={allowPickingMultipleItems}
            Input={TypeaheadInput}
            isLoading={isLoading}
            onAddSelections={selectionData =>
              this.onAddSelections(selectionData, this.handleClosePopover, this.props.onAddSelections)
            }
            onInput={onInputTypeahead}
            results={typeaheadResults}
            renderSelection={renderSelection}
            requestPopoverClose={this.handleClosePopover}
            renderFooter={renderFooter}
            resultsListClassName={resultsListClassName} />
        </React.Fragment>
      </Popover>
    );
  }
}

export default PopoverTypeahead;
