// Use DefinePlugin (Webpack) or loose-envify (Browserify)
// together with Uglify to strip the dev branch in prod build.
let configStore = null;

if (process.env.NODE_ENV === 'production') {
  configStore = require('./configureStore.prod');
} else {
  configStore = require('./configureStore.dev');
}

// @ts-ignore
export default configStore.default();
