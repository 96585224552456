import React from 'react';
import { Cell } from 'fixed-data-table-2';
import styles from './FormsTable.css';

export default ({ label }) =>
  <Cell>
    <div className={styles.headerCell}>
      {label}
    </div>
  </Cell>;
