import api from 'reducers/api';
import {updateCoverLetterSettings} from 'embed/utils/data';
import {structureToEditorState} from 'utils/richTextEditor/conversion';
import {
  EDIT_ACKNOWLEDGEMENT,
  EDIT_COVER_LETTER,
  GET_FORM_SETTINGS,
  SET_FORM_ID,
  TOGGLE_MODAL,
  TOGGLE_REQUIRE_ACKNOWLEDGEMENT,
  TOGGLE_SNACKBAR,
  TOGGLE_USE_COVERLETTER,
} from 'embed/constants/coverLetterSettings';


const initialState = {
  modalOpen: false,
  snackbarOpen: false,
  snackbarMessage: null,
  fetchingCoverLetterSettings: false,
  coverLetter: {
    settings: {
      useCoverLetter: false,
    },
    hasLegacyVersion: false,
  },
};

export default function coverLetterSettings(
  state = initialState,
  action
) {
  switch (action.type) {
    case EDIT_ACKNOWLEDGEMENT:
      return updateCoverLetterSettings(state, {acknowledgementText: action.text});
    case EDIT_COVER_LETTER:
      return {...state, editorState: action.editorState};
    case TOGGLE_MODAL:
      return {...state, modalOpen: action.modalOpen};
    case TOGGLE_SNACKBAR:
      return {...state, snackbarOpen: action.snackbarOpen, snackbarMessage: action.snackbarMessage};
    case SET_FORM_ID:
      return {...state, formId: action.formId};
    case GET_FORM_SETTINGS:
      const action_ = action;
      return api(action, state, {
        pending: () => ({
          ...state,
          fetchingCoverLetterSettings: true,
        }),
        success: () => {
          if (action_.status === 'success') {
            const {structure, settings, hasLegacyVersion} = action_.payload;
            const {coverLetter, ...rest} = state;
            return {
              ...rest,
              coverLetter: {...coverLetter, settings, hasLegacyVersion},
              editorState: structureToEditorState(structure),
              fetchingCoverLetterSettings: false,
            };
          }
          return state;
        },
        failure: () => ({...state, fetchingCoverLetterSettings: false}),
      });
    case TOGGLE_USE_COVERLETTER:
      return updateCoverLetterSettings(state, {useCoverLetter: action.useCoverLetter});
    case TOGGLE_REQUIRE_ACKNOWLEDGEMENT:
      return updateCoverLetterSettings(state, {requireAcknowledgement: action.requireAcknowledgement});
    default:
      return state;
  }
}
