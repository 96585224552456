import React from 'react';
import styles from './FieldItem.css';
import {handlePNGOrSVGContent, hasSignatureData, normalizeSignatureData, stripBrTags, extractSVGUrl, isSVG, isPNG } from 'utils/textHelper';
import Linkify from 'components/SubmissionDetails/Linkify';
import LinkToNewTab from 'components/LinkToNewTab';
import SignatureBoxItem from 'components/SubmissionDetails/SignatureBoxItem';
import moment from 'moment';
import { joinClassNames } from 'utils/strings';

const getSVGData = (fieldValue:string, fieldMap: any[]) => {
  if (extractSVGUrl(fieldMap)) {
    return <img src={extractSVGUrl(fieldMap)} width='100%' alt='' />;
  }
  const isTableCell = false;
  return handlePNGOrSVGContent(fieldValue, isTableCell);
};

const getTimeAgo = (day: string | number, time: string | number) => moment.utc(`${day} ${time}`, 'MM/DD/YYYY hh:mmA').fromNow();

const makeBox = (fieldValue: string, isRowView: boolean) => {
  let normalizedSigData: (string | number)[] = [];

  const showSignatureBox = !!fieldValue && hasSignatureData(fieldValue);
  if (fieldValue && showSignatureBox) {
    normalizedSigData = normalizeSignatureData(fieldValue);
  }
  return showSignatureBox && normalizedSigData ? (
    <div className={styles.signatureCard}>
      <SignatureBoxItem
        signatureNumber={1}
        signeeType='Citizen'
        fullname={`${normalizedSigData[0]} ${normalizedSigData[1]}` || 'Unknown signer'}
        email={normalizedSigData[2] || 'No email provided'}
        status={'signed'}
        signature={normalizedSigData[3]}
        timeAgo={getTimeAgo(normalizedSigData[4], normalizedSigData[5])}
        noSignatureDivider />
    </div>
  ) :
    (<Linkify component={LinkToNewTab}>
      <p className={joinClassNames(styles.fieldsContent, isRowView ? styles.rowFieldContent : '')}>
        {stripBrTags(fieldValue)}
      </p>
    </Linkify>
    );
};

interface FieldItemProps {
  label: string,
  fieldValue: string,
  fieldMap?: any,
  isRowView?: boolean
}

const FieldItem = ({ label, fieldValue, fieldMap, isRowView = false }: FieldItemProps): JSX.Element => {
  const isPNGCanvas = fieldValue && isPNG(fieldValue);
  const isSVGCanvas = fieldValue && isSVG(fieldValue);

  return (
    <div className={joinClassNames(styles.fieldWrapper, isRowView ? styles.rowFieldWrapper : '')}>
      <p className={styles.fieldsLabel}>{label}</p>
      {
        fieldValue && (isSVGCanvas || isPNGCanvas) ?
          getSVGData(fieldValue, fieldMap) :
          makeBox(fieldValue, isRowView)
      }
    </div>
  );
};

interface RichFieldProps {
  label: string,
  fieldContent: string | JSX.Element
}

export const RichField = ({ label, fieldContent }: RichFieldProps): JSX.Element => (
  <div className={styles.richFieldWrapper}>
    <p className={styles.richFieldsLabel}>{label}</p>
    {fieldContent}
  </div>
);

export default FieldItem;
