import React from 'react';
import { map, memoize } from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Clear from '@material-ui/icons/Clear';

import styles from './styles.css';

import PermissionsTable from './PermissionsTable';
import AddPermission from './AddPermission';
import { safeObjectValues } from 'utils/flow';
import { filterToUsersEligibleToReceivePermissionsFactory } from 'utils/assignees';


export default class Body extends React.Component<any, any> {
  createFilterFunction = memoize(
    (authorId, permissions) => {
      const userIdsToFilterOut = [authorId || '', ...map(permissions, 'userId')];
      const filter = filterToUsersEligibleToReceivePermissionsFactory(userIdsToFilterOut);
      return filter;
    },
    (authorId, permissions) => `${authorId}-${map(permissions, 'userId').join()}`,
  );

  renderTopRow() {
    const {
      addRolePermission,
      allowVisitorRegistration,
      accessCodeEnabled,
      authorId,
      permissions,
      requirePassword,
      requireVisitorRegistration,
      rolePermissions,
      showAllowRegistration,
      showRequireRegistration,
      users,
    } = this.props;
    const requireLoginToggle = (
      <div>
        <FormControlLabel
          classes={{
            label: styles.labelStyle,
            labelPlacementStart: styles.labelPlacementStart,
          } as any}
          control={
            <Switch
              classes={{
                checked: styles.isChecked,
                icon: styles.thumbStyle,
                iconChecked: styles.thumbSwitchedStyle,
                bar: styles.barStyle,
              }}
              color={'default'}
              checked={requirePassword}
              onChange={this.handleChangeRequirePassword}
              value='requireLoginChecked'
            />
          }
          label='Require User Login?'
          labelPlacement='start'
        />
      </div>
    );
    const saveAndContinueToggle = (
      <div>
        <FormControlLabel
          classes={{
            label: styles.labelStyle,
            labelPlacementStart: styles.labelPlacementStart,
          } as any}
          control={
            <Switch
              classes={{
                checked: styles.isChecked,
                icon: styles.thumbStyle,
                iconChecked: styles.thumbSwitchedStyle,
                bar: styles.barStyle,
              }}
              color={'default'}
              checked={requireVisitorRegistration}
              onChange={this.handleChangeRequireVisitorRegistration}
              value='enableSaveAndContinue'
            />
          }
          label='Save & Continue?'
          labelPlacement='start'
        />
      </div>
    );

    const visitorRegistrationToggle = (
      <div>
        <FormControlLabel
          classes={{
            label: styles.labelStyle,
            labelPlacementStart: styles.labelPlacementStart,
          } as any}
          control={
            <Switch
              classes={{
                checked: styles.isChecked,
                icon: styles.thumbStyle,
                iconChecked: styles.thumbSwitchedStyle,
                bar: styles.barStyle,
              }}
              color={'default'}
              checked={allowVisitorRegistration}
              onChange={this.handleChangeAllowVisitorRegistration}
              value='allowVisitorRegistration'
            />
          }
          label='Visitor Registration'
          labelPlacement='start'
        />
      </div>
    );

    const accessCodeToggle = (
      <div>
        <FormControlLabel
          classes={{
            label: styles.labelStyle,
            labelPlacementStart: styles.labelPlacementStart,
          } as any}
          control={
            <Switch
              classes={{
                checked: styles.isChecked,
                icon: styles.thumbStyle,
                iconChecked: styles.thumbSwitchedStyle,
                bar: styles.barStyle,
              }}
              color={'default'}
              checked={accessCodeEnabled}
              onChange={this.handleChangeAccessToggleEnabled}
              value='accessCodeEnabled'
            />
          }
          label='Enable Access Code to View Submission?'
          labelPlacement='start'
        />
      </div>
    );

    return (
      <React.Fragment>
        <AddPermission
          addRolePermission={addRolePermission}
          alreadySelectedRoles={map(rolePermissions, 'role')}
          filter={this.createFilterFunction(authorId || '', permissions)}
          handleSelectUser={this.handleAddUser}
          users={safeObjectValues(users)}
        />
        <div className={styles.topRow}>
          {requireLoginToggle}
          {showRequireRegistration && saveAndContinueToggle}
          {showAllowRegistration && visitorRegistrationToggle}
        </div>
        <div className={styles.topRow}>
          {accessCodeToggle}
        </div>
      </React.Fragment>
    );
  }

  handleAddUser = user => {
    this.props.changeUser(user.userId);
  };

  handleChangeRequirePassword = () => {
    const { requirePassword, changeRequirePassword } = this.props;
    changeRequirePassword(!requirePassword);
  };

  handleChangeRequireVisitorRegistration = () => {
    const { requireVisitorRegistration, changeRequireVisitorRegistration } = this.props;
    changeRequireVisitorRegistration(!requireVisitorRegistration);
  };

  handleChangeAllowVisitorRegistration = () => {
    const { allowVisitorRegistration, changeAllowVisitorRegistration } = this.props;
    changeAllowVisitorRegistration(!allowVisitorRegistration);
  };

  handleChangeAccessToggleEnabled = () => {
    const { accessCodeEnabled, changeAccessCodeEnabled } = this.props;
    changeAccessCodeEnabled(!accessCodeEnabled);
  };

  render() {
    const {
      authorId,
      changeAuthor,
      changePermissionLevel,
      changeRolePermission,
      changeUser,
      closeModal,
      permissions,
      removePermission,
      removeRole,
      rolePermissions,
      users,
    } = this.props;
    return (
      <div className={styles.body}>
        <IconButton classes={{ root: styles.closeButton }} onClick={closeModal}>
          <Clear classes={{ root: styles.iconStyle }} />
        </IconButton>
        <div>
          <div className={styles.toggles}>{this.renderTopRow()}</div>
          <PermissionsTable
            authorId={authorId}
            changeAuthor={changeAuthor}
            changePermissionLevel={changePermissionLevel}
            changeRolePermission={changeRolePermission}
            changeUser={changeUser}
            permissions={permissions}
            removePermission={removePermission}
            removeRole={removeRole}
            rolePermissions={rolePermissions}
            users={users}
          />
        </div>
      </div>
    );
  }
}
