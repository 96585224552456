export const immutableModifyElementInArray = <T>(input: T[], index: number, newItem: T): T[] => {
  const isFirstElement = index === 0;
  const isLastElement = index === input.length - 1;
  if (isFirstElement) {
    return [
      newItem,
      ...input.slice(1),
    ];
  } else if (isLastElement) {
    return [
      ...input.slice(0, index),
      newItem,
    ];
  }
  return [
    ...input.slice(0, index),
    newItem,
    ...input.slice(index + 1),
  ];
};

export const immutableRemoveElementInArray = <T>(input: T[], index: number): T[] => {
  const isFirstElement = index === 0;
  const isLastElement = index === input.length - 1;
  if (isFirstElement) {
    return input.slice(1);
  } else if (isLastElement) {
    return input.slice(0, index);
  }
  return [
    ...input.slice(0, index),
    ...input.slice(index + 1),
  ];
};

export const immutableInsertElementInArray = <T>(input: T[], index: number, newItem: T): T[] => {
  const isFirstElement = index === 0;
  const willBeLastElement = index === input.length;
  if (isFirstElement) {
    return [
      newItem,
      ...input,
    ];
  } else if (willBeLastElement) {
    return [
      ...input,
      newItem,
    ];
  }
  return [
    ...input.slice(0, index),
    newItem,
    ...input.slice(index),
  ];
};
