import React, {PureComponent} from 'react';
import { nanoid } from 'nanoid';

interface Props {
  children: React.ReactNode,
  desc?: string,
  title?: string,
}

export default class AccessibleSVG extends PureComponent< Props & Partial<any>> {
  titleId = nanoid();
  descId = this.props.desc && nanoid();

  render() {
    const {children, title: svgTitle, desc: svgDesc, ...rest} = this.props;
    const labelledby = this.descId ? `${this.titleId} ${this.descId}` : this.titleId;
    return (
      <svg {...rest} aria-labelledby={labelledby} role='img'>
        {svgTitle && <title id={this.titleId}>{svgTitle}</title>}
        {svgDesc && <desc id={this.descId}>{svgDesc}</desc>}
        {children}
      </svg>
    );
  }
}
