import React, {memo, forwardRef, useState, useEffect, ChangeEvent, useMemo, useRef, useImperativeHandle} from 'react';
import {LabelAlignment, ShowLabelValue} from 'types/liveView';
import {getAlignmentClass, showLabelToBoolean} from 'utils/formLiveView/formLiveView';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import Tooltip from 'components/Tooltip';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import styles from './QueryGoogleSheets.css';
import {GOOGLE_SHEETS_API_KEY} from '../../env';

export interface QueryGoogleSheetsProps {
  id: string;
  label: string;
  required: boolean;
  excludedFromPreview: boolean;
  elementType: string;
  isDisabled: boolean;
  properties?: any;
  formula?: any;
  fieldState?: any;
  hoverText?: string;
  showOtherOption: boolean;
  extraData: {
    label: string;
    labelAlign: LabelAlignment;
    showLabel: ShowLabelValue;
  };
  specialSettings: {
    disabled: boolean;
    googleSheetUrl: string;
    required: boolean;
    width: number;
  };
  updateForm?: (s: { fields: { [p: string]: string } }) => void;
}


const QueryGoogleSheets = forwardRef((props: QueryGoogleSheetsProps, ref) => {
  const {
    id,
    required,
    hoverText,
    extraData: {showLabel, label, labelAlign},
    specialSettings: {googleSheetUrl},
    updateForm,
  } = props;

  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const [filteredValues, setFilteredValues] = useState<{ [key: string]: string[] }>({});
  const [focusedInput, setFocusedInput] = useState<string | null>(null);
  const [googleSheetsValues, setGoogleSheetsValues] = useState<string[][]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const columnHeaders = useMemo(() => googleSheetsValues[0], [googleSheetsValues]);

  const selfValidate = () => {
    const newErrors: { [key: string]: string } = {};
    let isValid = true;
    const inputValuesObj = {};
    columnHeaders?.forEach((header, index) => {
      const inputValue = inputValues[`${id}_${header}_${index}`];
      inputValuesObj[header] = inputValue ? inputValue : '';
      newErrors[`${id}_${header}_${index}`] = !inputValue ? 'This Field is required' : '';
    });
    if (required && Object.values(inputValuesObj).some((val: string) => !val.trim())) {
      setErrors(newErrors);
      isValid = false;
    }
    return isValid;
  };

  useEffect(() => {
    if (updateForm) {
      // Split inputValues and pass them to updateForm
      const splitValues = {};
      columnHeaders?.forEach((header, index) => {
        const inputValue = inputValues[`${id}_${header}_${index}`];
        splitValues[header] = inputValue ? `${header}: ${inputValue}` : '';
      });

      const s = {
        fields: splitValues,
      };
      updateForm(s);
    }
    // Remove error messages for keys where inputValues have changed
    const updatedErrors = {...errors};
    Object.keys(inputValues).forEach(key => {
      if (errors[key]) {
        updatedErrors[key] = '';
      }
    });
    setErrors(updatedErrors);
  }, [inputValues]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async(): Promise<void> => {
      try {
        const response: Response = await fetch(
          `https://sheets.googleapis.com/v4/spreadsheets/${googleSheetUrl}/values:batchGet?ranges=Sheet1&key=${GOOGLE_SHEETS_API_KEY}`,
          {
            method: 'GET',
          }
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch data from Google Sheets API. Status: ${response.status}`);
        }
        const apiRes = await response.json();
        const apiValues = await apiRes?.valueRanges[0]?.values || [];
        setGoogleSheetsValues(apiValues);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data from Google Sheets API:', error);
        setApiError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [googleSheetUrl]);


  const handleInputChange = (header: string, index: number, value: string): void => {
    setInputValues(prevInputValues => ({
      ...prevInputValues,
      [`${id}_${header}_${index}`]: value,
    }));

    setFocusedInput(`${id}_${header}_${index}`);

    if (!value.trim()) {
      setFocusedInput(null);
      setFilteredValues(prevFilteredValues => ({
        ...prevFilteredValues,
        [`${id}_${header}_${index}`]: [],
      }));
      return;
    }

    const filteredColumnValues = googleSheetsValues.slice(1)
      .map(row => row[index])
      .filter(columnValue => columnValue.toLowerCase().includes(value.toLowerCase()));

    setFilteredValues(prevFilteredValues => ({
      ...prevFilteredValues,
      [`${id}_${header}_${index}`]: filteredColumnValues,
    }));
  };


  const handleLiClick = (header: string, index: number, selectedValue: string) => {
    setInputValues(prevInputValues => {
      const updatedInputValues = {...prevInputValues, [`${id}_${header}_${index}`]: selectedValue};

      // Find the corresponding indexes in the other columns and set their values
      const otherIndexes = columnHeaders?.map((col, colIndex) => colIndex !== index ? colIndex : -1);
      otherIndexes?.forEach(otherIndex => {
        if (columnHeaders !== undefined && otherIndex !== undefined && otherIndex !== -1) {
          const matchingRowIndex = googleSheetsValues.findIndex(row =>
            row[index].toLowerCase() === selectedValue.toLowerCase()
          );

          if (matchingRowIndex !== -1) {
            updatedInputValues[`${id}_${columnHeaders[otherIndex]}_${otherIndex}`] =
              googleSheetsValues[matchingRowIndex][otherIndex];
          }
        }
      });

      return updatedInputValues;
    });

    setFocusedInput(null);
  };


  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }),
  );
  return (
    <Tooltip title={hoverText || ''} disabled={!hoverText} placement='top'>
      <section id={id} ref={ref as React.RefObject<HTMLDivElement>}>
        {showLabelToBoolean(showLabel) && (
          <label
            aria-label={label}
            htmlFor={id}
            className={classNames(
              sharedStyle.FormLabel,
              {[sharedStyle.Required]: required},
              'field_label',
              getAlignmentClass(labelAlign, sharedStyle),
            )}
          >
            {label}
          </label>
        )}
        <div>
          {isLoading && (
            <div className={styles.qgs_loading}>
              The fields are currently being loaded. Please wait a few more
              seconds....
            </div>
          )}
          {apiError && !isLoading && (
            <div className={styles.qgs_loading}>
              There is an error fetching your data from Google Sheets....
            </div>
          )}
          {!apiError && googleSheetsValues?.length > 0 &&
            columnHeaders?.map((header, index) => (
              <div key={`${id}_${header}_${index}`} className={styles.qgs_input_container}>
                <label aria-label={header} htmlFor={`${id}_${header}_${index}`}>
                  {header}
                </label>
                <ErrorContainer error={errors[`${id}_${header}_${index}`]}>
                  <input
                    ref={inputRef}
                    id={`${id}_${header}_${index}`}
                    name={`${id}[${header}]`}
                    type='text'
                    aria-label={`${label} ${header} Input`}
                    value={inputValues[`${id}_${header}_${index}`] || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(header, index, e.target.value)
                    }
                  />
                </ErrorContainer>
                {focusedInput === `${id}_${header}_${index}` && (
                  <ul
                    className={styles.qgs_auto_complete_container}
                    role='listbox'
                    aria-expanded={
                      !!filteredValues[`${id}_${header}_${index}`]?.length
                    }
                  >
                    {filteredValues[`${id}_${header}_${index}`]?.map(
                      (filteredValue, idx) => (
                        <li key={idx} role='option'>
                          <button
                            onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                              e.preventDefault();
                              handleLiClick(header, index, filteredValue);
                            }}
                          >
                            {filteredValue}
                          </button>
                        </li>
                      ),
                    )}
                  </ul>
                )}
              </div>
            ))}
        </div>
      </section>
    </Tooltip>
  );
});

export default memo(QueryGoogleSheets);
