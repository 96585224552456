import * as React from 'react';
import brandLogo from 'images/brand-logo.svg';

// type Props = {
//   logo: ?string,
// };

// type State = {
//   logoLoaded: boolean,
//   useDefaultLogo: boolean,
// };

export default function withLogoFallback(Component) {
  return class LogoFallback extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = {
        logoLoaded: false,
        useDefaultLogo: false,
      };
    }

    componentDidMount() {
      this.ensureLogoFallbackTimeout = setTimeout(this.ensureLogoFallback, 5000);
    }

    componentWillUnmount() {
      const timeout = this.ensureLogoFallbackTimeout;
      if (timeout) clearTimeout(timeout);
    }

    ensureLogoFallbackTimeout

    ensureLogoFallback = () => {
      const {logoLoaded} = this.state;
      if (!logoLoaded) this.setState({logoLoaded: true, useDefaultLogo: true});
    };

    handleLogoOnLoad = () => {
      this.setState({logoLoaded: true});
    }

    render() {
      const {logo, ...props} = this.props;
      const assignedLogo = (!this.state.useDefaultLogo && logo) ? logo : brandLogo;
      return (
        <Component
          {...props}
          ensureLogoFallback={this.ensureLogoFallback}
          handleLogoOnLoad={this.handleLogoOnLoad}
          logo={assignedLogo}/>
      );
    }
  };
}
