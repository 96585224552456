import api from 'reducers/api';
import {
  quickFiltersEnum,
  URL_TO_FILTER,
} from 'constants/quickFilters';
import {NEW_TABLE_API} from 'constants/types/lobbyTableActionTypes';
import {
  CLEAR_QUICK_FILTER,
  SELECT_QUICK_FILTER,
} from 'constants/types/quickFilterActionTypes';
import {
  SELECT_ALL_VIRTUAL_STAGE,
} from 'constants/types/stageActionTypes';
import {
  NEW_TABLE_SEARCH_API,
  INITIALIZE,
} from 'constants/types/submissionsTableActionTypes';


export const initialState = {
  selectedFilter: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE:
      // we only use selectedFilter if it is truthy
      // selectedFilter reflects the currently selected form quick filter,
      // and this comes from the submission manager route. we don't want to
      // clear out the all form filter which would have a null filter in the route
      if (action.selectedFilter && URL_TO_FILTER[action.selectedFilter]) {
        const selectedFilter = URL_TO_FILTER[action.selectedFilter];
        return {
          ...state,
          selectedFilter,
        };
      }
      return state;
    case SELECT_QUICK_FILTER:
      return {
        ...state,
        selectedFilter: action.quickFilterName,
      };
    case SELECT_ALL_VIRTUAL_STAGE:
    case CLEAR_QUICK_FILTER:
      return initialState;
    case NEW_TABLE_API:
      return api(action, state, {
        pending: () => ({
          ...state,
          selectedFilter: null,
        }),
      });
    case NEW_TABLE_SEARCH_API:
      return api(action, state, {
        success: () => {
          if (action.payload.isSearching) {
            return initialState;
          }
          return state;
        },
      });
    default:
      (action);
      return state;
  }
};

/* SELECTORS */
export const getSelectedQuickFilter = state => state.selectedFilter;

// The stored state indicates the selected quick filter in the lobby, but if no quick
// filter is selected we also want to use the 'all submissions' submissions endpoint
export const getSelectedSubmissionQuickFilter = state => {
  if (state.selectedFilter) {
    return state.selectedFilter;
  }
  return quickFiltersEnum.ALL;
};
