import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Star } from '@material-ui/icons';
import classNames from 'classnames';
import style from './StarRating.css';
import sharedStyle from '../shared.css';
import { STAR_RATING_DEFAULT_VALUES } from 'constants/liveViewPage';
import Tooltip from 'components/Tooltip';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';
import { LabelAlignment } from 'types/liveView';
import ErrorContainer from '../ErrorContainer/ErrorContainer';

export type Props = {
  id: string,
  label?: string,
  onChange?: Function,
  updateForm?: Function,
  extraData?: any,
  fieldState?: any,
  hoverText?: string,
  required?: boolean,
  specialSettings: {
    label: string,
    isLabelVisible: boolean,
    labelAlign: 'right' | 'auto' | 'top',
    numberOfStars: string,
    areHalvesUsed: boolean,
  }
}

const StarRating = ({
  id,
  label = STAR_RATING_DEFAULT_VALUES.label,
  fieldState,
  onChange = undefined,
  updateForm = undefined,
  required = false,
  hoverText = '',
  specialSettings = {
    label: STAR_RATING_DEFAULT_VALUES.label,
    isLabelVisible: true,
    labelAlign: 'auto',
    numberOfStars: STAR_RATING_DEFAULT_VALUES.numberOfStars,
    areHalvesUsed: true,
  },
}: Props, ref) => {
  const [activeStar, setActiveStar] = useState<number | null>(fieldState?.fields[label] || null);
  const [tempActiveStar, setTempActiveStar] = useState<number | null>(null);
  const [error, setError] = useState<string>('');
  const [touched, setTouched] = useState<boolean>(false);

  const isLabelVisible: boolean = specialSettings ? specialSettings.isLabelVisible : true;
  const labelAlignment: LabelAlignment = specialSettings ? specialSettings.labelAlign : 'auto';
  const numberOfStars: number =
    specialSettings ? Number.parseInt(specialSettings.numberOfStars, 10) :
      Number.parseInt(STAR_RATING_DEFAULT_VALUES.numberOfStars, 10);
  const areHalvesUsed: boolean = specialSettings ? specialSettings.areHalvesUsed : true;

  useEffect(() => {
    onChange && onChange(activeStar);
    const s = {
      fields: {
        [label]: activeStar,
      },
    };
    updateForm && updateForm(s);
  }, [activeStar]);

  const selfValidate = () => {
    if (required && !activeStar) {
      setError('This field is required.');
      return false;
    }

    setError('');
    return true;
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  useEffect(() => {
    touched && selfValidate();
  }, [activeStar]);

  const handleMouseover = (rating: number | null): void => {
    setTempActiveStar(activeStar);
    setActiveStar(rating);
  };

  const handleMouseout = (): void => {
    setActiveStar(tempActiveStar);
  };

  const rate = (rating: number | null): void => {
    setTempActiveStar(rating);
    setActiveStar(rating);
    setTouched(true);
  };

  const renderStars = (): JSX.Element[] => {
    const totalStars: number = areHalvesUsed ? numberOfStars * 2 : numberOfStars;
    return [...new Array(totalStars)].map((arr: any, index: number) => {
      const starClass: string = activeStar !== null && activeStar >= index ? style.YellowStar : style.GreyStar;
      return (<i
        className={areHalvesUsed ? style.HalfStar : style.FullStar}
        style={{
          direction: index % 2 === 0 ? 'ltr' : 'rtl',
        }}
        onMouseOver={() => handleMouseover(index)}
        onClick={() => rate(index)}
        onMouseOut={handleMouseout}
        key={index}
      >
        <Star fontSize='large' className={starClass} />
      </i>);
    });
  };

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <fieldset className={classNames(sharedStyle.Fieldset, 'choice_group', style.EmoticonsWrapper)} id={`star_rating_${id}`}>
        {isLabelVisible &&
          <legend
            className={classNames('choice_group_label', { [sharedStyle.Required]: required }, getAlignmentClass(labelAlignment, sharedStyle))}>{label}</legend>}
        <ErrorContainer error={error}>
          <div>{renderStars()}</div>
        </ErrorContainer>
      </fieldset>
    </Tooltip>
  );
};

export default React.memo(forwardRef(StarRating));
