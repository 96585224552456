import {combineEpics} from 'redux-observable';
import {ANALYTICS_DATA} from 'constants/analytics';
import {success} from './utils/filters';
import * as _ from 'lodash';

export function userReportingEpic(action$) {
  return action$
    .ofType(ANALYTICS_DATA)
    .filter(success)
    .take(1)
    .do(({payload: {
      result: {
        userData,
        companyData,
        featureFlags,
      },
    }}) => {
      if (window.analytics) {
        const company = _.merge(featureFlags,
          {
            company_id: companyData.companyId,
            createdAt: companyData.createdAt,
            docsLimit: companyData.docsLimit,
            formsLimit: companyData.formsLimit,
            usersLimit: companyData.usersLimit,
            name: companyData.companyName,
            plan: companyData.packagePlan,
            subdomain: companyData.subdomain,
            numberOfVisitors: companyData.numberOfVisitors,
          });
        window.analytics.identify(userData.userId, {
          createdAt: userData.createdAt,
          defaultTimezone: userData.defaultTimezone,
          department: userData.department,
          email: userData.accountEmail,
          name: userData.accountFullName,
          phone: userData.phone,
          role: userData.role,
          title: userData.title,
          company,
        }, {
          integrations: {
            Intercom: {
              user_hash: userData.intercomHash,
            },
          },
        });
      }
    });
}

export default combineEpics(userReportingEpic);
