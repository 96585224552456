import React from 'react';
import SmallModal from '../SmallModal';
import styles from './NotificationLoaderModal.css';
import Loader from '../../Loader';
import Action from '../Action';
import { imageDir } from 'constants/assets';
import archiveImage from 'icons/TrashIcon.svg';
import archiveSubmissionImage from 'icons/graphic_archive.svg';
import unarchiveSubmissionImage from 'icons/graphic_restore.svg';
import deleteFolderImage from 'icons/deleteFolder.svg';
import deleteFormImage from 'icons/deleteForm.svg';
import deleteSubmissionImage from 'icons/deleteSubmission.svg';
import formBuilderLoaderImg from 'images/v1-loader-form_builder.gif';
import stageImage from 'icons/submissions/stage.svg';
import errorImage from 'icons/submissions/error.svg';
import tagImage from 'icons/submissions/tag.svg';
import assigneeImage from 'icons/submissions/tag.svg';
import ProgressBar from 'containers/SubmissionsManager/Components/ProgressBar';

/*
* This modal is a combo of Notification Modal & Loading Modal
* when you pass in status='loading' props it renders a loader
* if status='successful' or status='failed' is passed, it defaults to a notification modal
*/


const NotificationLoaderModal = props => {
  let modalImg;
  let modalActions;
  const handleArchiveConfirm = () => {
    props.handleNotificationModalClose();
    if (props.handleArchiveForm) {
      props.handleArchiveForm();
    }
  };

  const archiveSubmission = () => {
    props.handleNotificationModalClose();
    props.archiveSubmission();
  };

  const restoreSubmission = () => {
    props.handleNotificationModalClose();
    props.restoreSubmission();
  };

  const handleDeleteFolder = () => {
    props.handleNotificationModalClose();
    if (props.handleDeleteFolder) {
      props.handleDeleteFolder();
    }
  };

  const handleDeleteForm = () => {
    props.handleNotificationModalClose();
    if (props.handleDeleteForm) {
      props.handleDeleteForm();
    }
  };

  const handleAndTrackNotificationModalClose = () => props.handleNotificationModalClose(props.status);

  switch (props.status) {
    case 'successful':
      modalImg = <img className={styles.statusImage} src={`${imageDir}/success-doc-animation.gif`} alt='' />;
      modalActions = [
        <Action
          key='close'
          label='Close'
          primary
          onClick={props.handleNotificationModalClose} />,
      ];
      break;
    case 'failed':
      modalImg = <img className={styles.statusImage} src={`${imageDir}/error-doc-animation.gif`} alt='' />;
      modalActions = [
        <Action
          key='close'
          label='Close'
          secondary
          onClick={props.handleNotificationModalClose} />,
      ];
      break;
    case 'loading':
      modalImg = <Loader style={{ height: 120 }} />;
      modalActions = null;
      break;
    case 'deleteForm':
      modalImg = <img className={styles.statusImage} src={deleteFormImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={props.handleNotificationModalClose} />,
        <Action
          key='deleteForm'
          label='Delete'
          primary
          onClick={handleDeleteForm} />,
      ];
      break;
    case 'deleteSubmission':
      modalImg = <img className={styles.statusImage} src={deleteSubmissionImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={handleAndTrackNotificationModalClose} />,
        <Action
          key='archiveForm'
          label='Delete'
          primary
          onClick={handleArchiveConfirm} />,
      ];
      break;
    case 'archive':
      modalImg = <img className={styles.statusImage} src={archiveImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={props.handleNotificationModalClose} />,
        <Action
          key='confirmArchiveForm'
          label={props.status}
          primary
          onClick={handleArchiveConfirm} />,
      ];
      break;
    case 'archiveSubmission':
      modalImg = <img className={styles.statusImage} src={archiveSubmissionImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={handleAndTrackNotificationModalClose} />,
        <Action
          key='confirmArchiveSubmission'
          label='Archive'
          primary
          onClick={archiveSubmission} />,
      ];
      break;
    case 'restoreSubmission':
      modalImg = <img className={styles.statusImage} src={unarchiveSubmissionImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={props.handleNotificationModalClose} />,
        <Action
          key='confirmRestoreSubmission'
          label='Restore'
          primary
          onClick={restoreSubmission} />,
      ];
      break;
    case 'deleteFolder':
      modalImg = <img className={styles.statusImage} src={deleteFolderImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={props.handleNotificationModalClose} />,
        <Action
          key='deleteFolder'
          label='Delete'
          primary
          onClick={handleDeleteFolder} />,
      ];
      break;
    case 'loadingNewDoc':
      modalImg = <img style={{ maxWidth: 90 }} className={styles.statusImage} src={formBuilderLoaderImg} alt='' />;
      modalActions = null;
      break;
    case 'moveToStage':
      modalImg = <img className={styles.statusImage} src={stageImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={handleAndTrackNotificationModalClose} />,
        <Action
          key='confirmMoveToStage'
          label='Move'
          primary
          onClick={props.actionHandler} />,
      ];
      break;
    case 'addTag':
      modalImg = <img className={styles.statusImage} src={tagImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={handleAndTrackNotificationModalClose} />,
        <Action
          key='confirmAddTag'
          label='Add'
          primary
          onClick={props.actionHandler} />,
      ];
      break;
    case 'addAssignee':
      modalImg = <img className={styles.statusImage} src={assigneeImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={handleAndTrackNotificationModalClose} />,
        <Action
          key='confirmAddAssignee'
          label='Add'
          primary
          onClick={props.actionHandler} />,
      ];
      break;
    case 'deleteStagesBulk':
      modalImg = <img className={styles.statusImage} src={stageImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={handleAndTrackNotificationModalClose} />,
        <Action
          key='confirmRemoveStages'
          label='Remove'
          primary
          onClick={props.actionHandler} />,
      ];
      break;
    case 'deleteAssigneesBulk':
      modalImg = <img className={styles.statusImage} src={assigneeImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={handleAndTrackNotificationModalClose} />,
        <Action
          key='confirmRemoveAssignees'
          label='Remove'
          primary
          onClick={props.actionHandler} />,
      ];
      break;
    case 'deleteTagsBulk':
      modalImg = <img className={styles.statusImage} src={tagImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={handleAndTrackNotificationModalClose} />,
        <Action
          key='confirmRemoveTags'
          label='Remove'
          primary
          onClick={props.actionHandler} />,
      ];
      break;
    case 'fnrStage':
      modalImg = <img className={styles.statusImage} src={stageImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={handleAndTrackNotificationModalClose} />,
        <Action
          key='confirmReplaceStage'
          label='Replace'
          primary
          onClick={props.actionHandler} />,
      ];
      break;
    case 'fnrAssignees':
    case 'replaceAssignees':
      modalImg = <img className={styles.statusImage} src={assigneeImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={handleAndTrackNotificationModalClose} />,
        <Action
          key='confirmReplaceAssignee'
          label='Replace'
          primary
          onClick={props.actionHandler} />,
      ];
      break;
    case 'fnrTags':
    case 'replaceTags':
      modalImg = <img className={styles.statusImage} src={tagImage} alt='' />;
      modalActions = [
        <Action
          key='cancel'
          label='Cancel'
          secondary
          onClick={handleAndTrackNotificationModalClose} />,
        <Action
          key='confirmReplaceTag'
          label='Replace'
          primary
          onClick={props.actionHandler} />,
      ];
      break;

    case 'error':
      modalImg = <img className={styles.statusImage} src={errorImage} alt='' />;
      modalActions = [
        <Action
          key='close'
          label='Close'
          primary
          onClick={props.handleNotificationModalClose} />,
      ];
      break;
    case 'bulkProcessing':
      modalImg = <ProgressBar className={styles.progressBar} />;
      modalActions = null;
      break;
    default:
      modalActions = null;
      modalImg = <Loader />;
      break;
  }

  return (
    <SmallModal
      hideTitle
      actions={modalActions}
      disableBackdropClick
      onClose={props.onClose}
      open={props.open || false}>
      <div className={styles.notificationContainer}>
        <div className={styles.statusImageWrapper}>
          {modalImg}
        </div>
        <div className={styles.title}>
          {props.title}
        </div>
        <div className={styles.bodyText}>
          {props.bodyText}
        </div>
      </div>
    </SmallModal>
  );
};

export default NotificationLoaderModal;
