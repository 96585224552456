import {EditorState} from 'draft-js';
import {OrderedSet} from 'immutable';

const createUnstyledNewLinePlugin = ({affectedTypes}) => ({
  onChange: editorState => {
    if (editorState.getLastChangeType() !== 'split-block') return editorState;

    const styles = editorState.getCurrentInlineStyle();
    if (!affectedTypes.some(type => styles.has(type))) return editorState;

    const newLineLength = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getEndKey())
      .getLength();
    if (newLineLength !== 0) return editorState;

    return EditorState.setInlineStyleOverride(editorState, OrderedSet());
  },
});

export default createUnstyledNewLinePlugin;
