import { FeeTypes } from 'constants/payments';
import { PaymentProvider, ProviderDropdownOption } from 'types/govOsPay/govOsPaymentConfiguration';
import {
  FeeForServer,
  FeeStructure,
  GovOSPaymentConfig,
  GovOSPaymentConfigForServer,
  PaymentType,
} from 'types/govOsPay/govOsPaymentSettings';

export const providerToDropdownOption = (provider: PaymentProvider): ProviderDropdownOption => ({
  label: provider.mapping.providerName,
  value: provider.providerAccountCode,
});

export const providersToDropdownOptions = (providers: PaymentProvider[]): ProviderDropdownOption[] =>
  providers.map(providerToDropdownOption);


export const getFeeByType = (fees: FeeForServer[], paymentMethodType: PaymentType): FeeForServer | undefined => {
  if (paymentMethodType === 'ach') {
    return fees[0];
  }
  if (paymentMethodType === 'credit_card') {
    return fees.find(f => f.paymentMethodType === 'credit_card');
  }
  return fees.find(f => f.paymentMethodType === paymentMethodType);
};

const parseFee = (fees: FeeForServer[], paymentMethodType: PaymentType): FeeStructure => {
  let feeStructure: FeeStructure = {
    state: 'disabled',
    feeType: '',
    customFeeName: '',
    percentage: 0,
    amount: 0,
    isOffsetEnabled: false,
  };

  if (fees && fees.length) {
    const fee = getFeeByType(fees, paymentMethodType);

    if (!fee) {
      return feeStructure;
    }

    feeStructure = {
      state: fee.state,
      feeType: fee.feeType,
      customFeeName: fee.customFeeName,
      percentage: fee.percentage,
      amount: fee.amount,
      isOffsetEnabled: fee.isOffsetEnabled || false,
    };
  }

  return feeStructure;
};

export function convertPaymentConfigResponse(model: GovOSPaymentConfigForServer): GovOSPaymentConfig {
  const PaymentConfigStatusGateway: GovOSPaymentConfig = {
    providerId: model.providerId,
    formId: model.formId,
    paymentsConfigId: model.paymentsConfigId,

    status: model.status,
    paymentOptional: model.paymentOptional,

    description: model.description,
    amountSource: model.amountSource,
    amountSourceType: model.amountSourceType,

    ccEnabled: model.ccEnabled,
    achEnabled: model.achEnabled,
    // recipientSource: model.recipientSource,

    ccFeeStructure: parseFee(model.ccFeeStructure.fees, 'credit_card'),
    achFeeStructure: parseFee(model.achFeeStructure.fees, 'ach'),
  };

  return PaymentConfigStatusGateway;
}

// This was copied and pasted from the Spreedly implementation
// Rather than use the same helper, I copied it with the intention of refactoring it
// to improve type coverage without breaking the existing implementation
export const parseFeeValues = (
  feeType: string,
  feeAmount: number,
  feePercentage: number,
  feeIsOffsetEnabled: boolean,
  combinationFeeAmount: number,
  combinationFeePercentage: number,
  combinationIsOffsetEnabled: boolean,
  greaterFeeAmount: number,
  greaterFeePercentage: number,
  greaterIsOffsetEnabled: boolean,
) => {
  let amount: number | null = null;
  let percentage: number | null = null;
  let isOffsetEnabled: boolean = false;

  switch (feeType) {
    case FeeTypes.AMOUNT: {
      amount = feeAmount;
      percentage = null;
      break;
    }
    case FeeTypes.PERCENTAGE: {
      amount = null;
      percentage = feePercentage;
      isOffsetEnabled = feeIsOffsetEnabled;
      break;
    }
    case FeeTypes.COMBINATION: {
      amount = combinationFeeAmount;
      percentage = combinationFeePercentage;
      isOffsetEnabled = combinationIsOffsetEnabled;
      break;
    }
    case FeeTypes.GREATER: {
      amount = greaterFeeAmount;
      percentage = greaterFeePercentage;
      isOffsetEnabled = greaterIsOffsetEnabled;
      break;
    }

    default:
      break;
  }

  return {
    amount: amount ? +amount : null,
    percentage: percentage ? +percentage : null,
    isOffsetEnabled,
  };
};
