const getRegion = (container: string) => {
  if (['seamlessdocsdev', 'seamlessdocs-attachments'].includes(container)) {
    return 's3-us-west-2';
  }
  return 's3';
};

export function convertFilepickerUrlToS3(filepickerMeta: {container: string, key: string, url: string}) {
  const {container, key, url} = filepickerMeta;
  if (container && key) {
    const region = getRegion(container);
    return `https://${region}.amazonaws.com/${container}/${encodeURIComponent(key)}`;
  }
  return url;
}
