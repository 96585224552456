import React, { memo, forwardRef, useCallback, useMemo } from 'react';
import moment from 'moment';
import { getAlignmentClass, showLabelToBoolean } from 'utils/formLiveView/formLiveView';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import { LabelAlignment, ShowLabelValue } from 'types/liveView';
import styles from './AddToCalendar.css';
import {
  iCalFileUrl,
  generateGoogleEventURL,
  generateOutlookEventURL,
  generateYahooEventURL,
  timeFormatToAMOrPM,
  concatDateAndTimePropsToISO, arePropsValid,
} from 'utils/formLiveView/calendarEvents/calendarEventHelpers';

export interface AddToCalendarInterface {
  id: string;
  label: string;
  required: boolean;
  validationType?: string | null;
  elementType: string;
  isDisabled: boolean;
  properties?: any;
  formula?: any;
  fieldState?: any;
  showOtherOption: boolean;
  excludeFromPreview: boolean;
  hoverText: string | null;
  extraData: {
    label: string;
    labelAlign: LabelAlignment;
    showLabel: ShowLabelValue;
  };
  specialSettings: {
    summary: string;
    endDate: string;
    description: string;
    startTime: string;
    location: string;
    endTime: string;
    startDate: string;
    url: string;
  };
  updateForm?: (s: { fields: { [p: string]: string | null } }) => void;
}

const AddToCalendar = forwardRef((props: AddToCalendarInterface, ref: React.ForwardedRef<any>) => {
  const {
    id,
    label,
    required,
    extraData: {
      showLabel,
      labelAlign,
    },
    specialSettings: {
      description,
      endTime,
      endDate,
      location,
      startTime,
      startDate,
      summary,
      url,
    },
  } = props;
  // eslint-disable-next-line no-console
  console.log('AT: props', props);
  const areDatePropsValid = arePropsValid(startDate, endDate, startTime, endTime, 'MM-DD-YYYY', 'HH:mm:ss');

  const startDateMemoized = useMemo(() => moment(startDate, 'MM.DD.YYYY').format('ll'), [startDate]);
  const endDateMemoized = useMemo(() => moment(endDate, 'MM.DD.YYYY').format('ll'), [endDate]);

  const eventObj = useMemo(() => ({
    title: summary,
    description,
    location,
    url,
    start: concatDateAndTimePropsToISO(startDate, startTime),
    end: concatDateAndTimePropsToISO(endDate, endTime),
  }), [props.specialSettings]);

  const handleEventRedirect = useCallback((e, linkType: string): void => {
    e.preventDefault();
    let eventLink: string | undefined;
    switch (linkType) {
      case 'google':
        eventLink = generateGoogleEventURL(eventObj);
        break;
      case 'outlook':
        eventLink = generateOutlookEventURL(eventObj);
        break;
      case 'yahoo':
        eventLink = generateYahooEventURL(eventObj);
        break;
      default:
        return;
    }
    window.open(eventLink);
  }, [eventObj]);

  const handleICalFileDownload = useCallback(e => {
    e.preventDefault();
    const iCalFileLink = iCalFileUrl(eventObj);
    const blob = new Blob([iCalFileLink], { type: 'text/calendar;charset=utf-8' });
    const fileName = 'my-event.ics';
    if (navigator?.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [eventObj]);


  return (
    <div id={id} ref={ref as React.RefObject<HTMLDivElement>}>
      {showLabelToBoolean(showLabel) ? (
        <label
          aria-label={label}
          htmlFor={id}
          className={classNames(
            sharedStyle.FormLabel,
            { [sharedStyle.Required]: required },
            'field_label',
            getAlignmentClass(labelAlign, sharedStyle),
          )}
        >
          {label}
        </label>
      ) : null}
      <div className={styles.scheduler_wrapper}>
        <div id='inner_wrapper'>
          <p id='summary' className={styles.summary}>
            {summary}
          </p>
        </div>

        <div className={styles.scheduler_links}>
          {areDatePropsValid && (
            <>
              <button
                id={`${id}_google_btn`}
                aria-label='Add to Google Calendar'
                onClick={(e: React.MouseEvent<HTMLButtonElement>): void => handleEventRedirect(e, 'google')}
              >
                <p>Google</p>
                <img
                  className={styles.calendar_image}
                  width='64'
                  height='64'
                  alt='google_cal_img'
                  src='https://seamlessdocs.com/components/odoForms/plugins/addtocalendar/images/3.png'
                />
              </button>

              <button
                id={`${id}_iCal_btn`}
                aria-label='Download iCal event'
                onClick={handleICalFileDownload}
              >
                <p>iCal</p>
                <img
                  className={styles.calendar_image}
                  width='64'
                  height='64'
                  alt='ical_img'
                  src='https://seamlessdocs.com/components/odoForms/plugins/addtocalendar/images/1.png'
                />
              </button>

              <button
                id={`${id}_outlook_btn`}
                aria-label='Add To Outlook Calendar'
                onClick={(e: React.MouseEvent<HTMLButtonElement>): void => handleEventRedirect(e, 'outlook')}
              >
                <p>Outlook</p>
                <img
                  className={styles.calendar_image}
                  height='64'
                  width='64'
                  alt='outlook_cal_img'
                  src='https://seamlessdocs.com/components/odoForms/plugins/addtocalendar/images/2.png'
                />
              </button>

              <button
                id={`${id}_yahoo_btn`}
                aria-label='Add To Yahoo Calendar'
                onClick={(e: React.MouseEvent<HTMLButtonElement>): void => handleEventRedirect(e, 'yahoo')}
              >
                <p>Yahoo</p>
                <img
                  className={styles.calendar_image}
                  height='64'
                  width='64'
                  alt='yahoo_cal_img'
                  src='https://seamlessdocs.com/components/odoForms/plugins/addtocalendar/images/yahoo.png'
                />
              </button>
            </>
          )}
          {!areDatePropsValid && (
            <>
              <div>
                <p>Google</p>
                <img
                  className={styles.calendar_image}
                  width='64'
                  height='64'
                  alt='google_cal_img'
                  src='https://seamlessdocs.com/components/odoForms/plugins/addtocalendar/images/3.png'
                />
              </div>
              <div>
                <p>iCal</p>
                <img
                  className={styles.calendar_image}
                  width='64'
                  height='64'
                  alt='ical_img'
                  src='https://seamlessdocs.com/components/odoForms/plugins/addtocalendar/images/1.png'
                />
              </div>
              <div>
                <p>Outlook</p>
                <img
                  className={styles.calendar_image}
                  height='64'
                  width='64'
                  alt='outlook_cal_img'
                  src='https://seamlessdocs.com/components/odoForms/plugins/addtocalendar/images/2.png'
                />
              </div>
              <div>
                <p>Yahoo</p>
                <img
                  className={styles.calendar_image}
                  height='64'
                  width='64'
                  alt='yahoo_cal_img'
                  src='https://seamlessdocs.com/components/odoForms/plugins/addtocalendar/images/yahoo.png'
                />
              </div>
            </>
          )}
        </div>

        <div
          className={classNames(
            styles.scheduler_wrapper,
            styles.schedule_time_holder,
          )}
        >
          {areDatePropsValid ? (
            <>
              <p>{`Start Time: ${startDateMemoized} @ ${timeFormatToAMOrPM(startTime)}`}</p>
              <p>{`End Time: ${endDateMemoized} @ ${timeFormatToAMOrPM(endTime)}`}</p>
            </>
          ) : (
            <p>Invalid Date/Time</p>
          )
          }
        </div>
      </div>
    </div>
  );
});

export default memo(AddToCalendar);
