import React, { forwardRef } from 'react';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';
import { LabelAlignment } from 'types/liveView';

type Player = 'youtube' | 'vimeo';

export type Props = {
  id: string,
  extraData?: {
    alignment: LabelAlignment;
    height: string,
    videoId: string,
    width: string,
  },
  player?: Player,
}

const EmbedVideo = ({
  id,
  extraData = {
    alignment: 'auto',
    height: '360',
    width: '640',
    videoId: 'BvA0J_2ZpIQ',
  },
  player = 'youtube',
}: Props) => {
  const height: string = extraData ? extraData.height : '360';
  const width: string = extraData ? extraData.width : '640';
  const videoAlignment: LabelAlignment = extraData ? extraData.alignment : 'auto';

  const generateVideoSrc = (): string => {
    switch (player) {
      case 'vimeo':
        return `https://player.vimeo.com/video/${extraData ? extraData.videoId : ''}`;
      case 'youtube':
      default:
        return `https://www.youtube.com/embed/${extraData ? extraData.videoId : ''}`;
    }
  };

  const alignment: string = getAlignmentClass(videoAlignment, sharedStyle);

  return (
    <div id={`container_${id}`} className={'form_control_group'}>
      <fieldset className={classNames(sharedStyle.Fieldset, alignment)} id={`embed_youtube_${id}`}>
        <iframe
          width={width} height={height}
          src={generateVideoSrc()} />
      </fieldset>
    </div>
  );
};

export default React.memo(forwardRef(EmbedVideo));
