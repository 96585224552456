import api from 'actions/api';
import * as types from 'constants/types/quickFilterActionTypes';
import { SubmissionFilterForServer } from 'types/submissionsManager/submissionFilters';
import { SortingForServer } from 'types/submissionsManager/submissionSorting';
import { loadSubmissionsAction } from 'utils/submissionsManager/tableHelper';
// import type {Action as ApiAction} from 'types/api';
// import type {
//   ClearQuickFilterAction,
//   SelectQuickFilterActionCreator,
// } from 'types/quickFilters';

export const selectQuickFilter = (quickFilterName: string, shouldLogAnalytics: boolean = true) => ({
  type: types.SELECT_QUICK_FILTER,
  quickFilterName,
  shouldLogAnalytics,
});

export const clearQuickFilter = () => ({
  type: types.CLEAR_QUICK_FILTER,
});

const loadFormsAction = (baseType: string, endpoint: string, paginationToken: string | null = null) => {
  const requestPayload = paginationToken ? { paginationToken } : {};
  return api.postWithAuth({
    endpoint,
    data: requestPayload,
    baseType,
    success: (state, { response: { result: { forms, nextPageToken } } }) => ({ tableData: forms, nextPageToken }),
    failure: (state, error) => ({ error }),
  });
};

export const allFilter = (baseType: string, paginationToken: string | null = null) =>
  loadFormsAction(baseType, 'formsBySubmission/all.json', paginationToken);

export const allFilterInitialLoad = () =>
  allFilter(types.ALL_FILTER_INITIAL_LOAD);

export const allFilterSubsequentLoad = (paginationToken: string) =>
  allFilter(types.ALL_FILTER_SUBSEQUENT_LOAD, paginationToken);

export const assignedToMeFilter = (baseType: string, paginationToken: string | null = null) =>
  loadFormsAction(baseType, 'formsBySubmission/assigned.json', paginationToken);

export const assignedToMeFilterInitialLoad = () =>
  assignedToMeFilter(types.ASSIGNED_TO_ME_FILTER_INITIAL_LOAD);

export const assignedToMeFilterSubsequentLoad = (paginationToken: string) =>
  assignedToMeFilter(types.ASSIGNED_TO_ME_FILTER_SUBSEQUENT_LOAD, paginationToken);

export const draftsFilter = (baseType: string, paginationToken: string | null = null) =>
  loadFormsAction(baseType, 'formsBySubmission/drafted.json', paginationToken);

export const draftsFilterInitialLoad = () =>
  draftsFilter(types.DRAFTS_FILTER_INITIAL_LOAD);

export const draftsFilterSubsequentLoad = paginationToken =>
  draftsFilter(types.DRAFTS_FILTER_SUBSEQUENT_LOAD, paginationToken);

export const sharedFilter = (baseType, paginationToken = null) =>
  loadFormsAction(baseType, 'formsBySubmission/shared.json', paginationToken);

export const sharedFilterInitialLoad = () =>
  sharedFilter(types.SHARED_FILTER_INITIAL_LOAD);

export const sharedFilterSubsequentLoad = paginationToken =>
  sharedFilter(types.SHARED_FILTER_SUBSEQUENT_LOAD, paginationToken);

export const submittedFilter = (baseType: string, paginationToken: string | null = null) =>
  loadFormsAction(baseType, 'formsBySubmission/submitted.json', paginationToken);

export const submittedFilterInitialLoad = () =>
  submittedFilter(types.SUBMITTED_FILTER_INITIAL_LOAD);

export const submittedFilterSubsequentLoad = (paginationToken: string) =>
  submittedFilter(types.SUBMITTED_FILTER_SUBSEQUENT_LOAD, paginationToken);

export const signedByMeFilter = (baseType: string, paginationToken: string | null = null) =>
  loadFormsAction(baseType, 'formsBySubmission/signed.json', paginationToken);

export const signedByMeFilterInitialLoad = () =>
  signedByMeFilter(types.SIGNED_BY_ME_FILTER_INITIAL_LOAD);

export const signedByMeFilterSubsequentLoad = (paginationToken: string) =>
  signedByMeFilter(types.SIGNED_BY_ME_FILTER_SUBSEQUENT_LOAD, paginationToken);

export const assignedToMeSubmissionFilter = (
  baseType: string,
  formId: string,
  paginationToken: string | null,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  loadSubmissionsAction(
    baseType,
    'filteredSubmissions/assigned.json',
    formId,
    paginationToken,
    offset,
    'OnlyActive',
    submissionFilters,
    sorting,
  );

export const assignedToMeSubmissionInitialLoad = (
  formId: string,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  assignedToMeSubmissionFilter(
    types.ASSIGNED_TO_ME_SUBMISSION_FILTER_INITIAL_LOAD,
    formId,
    null,
    0,
    submissionFilters,
    sorting,
  );

export const assignedToMeSubmissionSubsequentLoad = (
  formId: string,
  paginationToken: string | null,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  assignedToMeSubmissionFilter(
    types.ASSIGNED_TO_ME_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
    formId,
    paginationToken,
    offset,
    submissionFilters,
    sorting,
  );

export const draftsSubmissionFilter = (
  baseType: string,
  formId: string,
  paginationToken: string | null,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  loadSubmissionsAction(
    baseType,
    'filteredSubmissions/drafted.json',
    formId,
    paginationToken,
    offset,
    'IncludeAll',
    submissionFilters,
    sorting,
  );

export const draftsSubmissionInitialLoad = (
  formId: string,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  draftsSubmissionFilter(
    types.DRAFTS_SUBMISSION_FILTER_INITIAL_LOAD,
    formId,
    null,
    0,
    submissionFilters,
    sorting,
  );

export const draftsSubmissionSubsequentLoad = (
  formId: string,
  paginationToken: string | null,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  draftsSubmissionFilter(
    types.DRAFTS_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
    formId,
    paginationToken,
    offset,
    submissionFilters,
    sorting,
  );

export const sharedSubmissionFilter = (
  baseType: string,
  formId: string,
  paginationToken: string | null,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  loadSubmissionsAction(
    baseType,
    'filteredSubmissions/shared.json',
    formId,
    paginationToken,
    offset,
    'IncludeAll',
    submissionFilters,
    sorting,
  );

export const sharedSubmissionInitialLoad = (
  formId: string,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  sharedSubmissionFilter(
    types.SHARED_SUBMISSION_FILTER_INITIAL_LOAD,
    formId,
    null,
    0,
    submissionFilters,
    sorting,
  );

export const sharedSubmissionSubsequentLoad = (
  formId: string,
  paginationToken: string | null,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  sharedSubmissionFilter(
    types.SHARED_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
    formId,
    paginationToken,
    offset,
    submissionFilters,
    sorting,
  );

export const submittedSubmissionFilter = (
  baseType: string,
  formId: string,
  paginationToken: string | null,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  loadSubmissionsAction(
    baseType,
    'filteredSubmissions/submitted.json',
    formId,
    paginationToken,
    offset,
    'IncludeAll',
    submissionFilters,
    sorting,
  );

export const submittedSubmissionInitialLoad = (
  formId: string,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  submittedSubmissionFilter(
    types.SUBMITTED_SUBMISSION_FILTER_INITIAL_LOAD,
    formId,
    null,
    0,
    submissionFilters,
    sorting,
  );

export const submittedSubmissionSubsequentLoad = (
  formId: string,
  paginationToken: string | null,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  submittedSubmissionFilter(
    types.SUBMITTED_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
    formId,
    paginationToken,
    offset,
    submissionFilters,
    sorting,
  );

export const signedByMeSubmissionFilter = (
  baseType: string,
  formId: string,
  paginationToken: string | null,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  loadSubmissionsAction(
    baseType,
    'filteredSubmissions/signed.json',
    formId,
    paginationToken,
    offset,
    'IncludeAll',
    submissionFilters,
    sorting,
  );

export const signedByMeSubmissionInitialLoad = (
  formId: string,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  signedByMeSubmissionFilter(
    types.SIGNED_BY_ME_SUBMISSION_FILTER_INITIAL_LOAD,
    formId,
    null,
    0,
    submissionFilters,
    sorting,
  );

export const signedByMeSubmissionSubsequentLoad = (
  formId: string,
  paginationToken: string | null,
  offset: number,
  submissionFilters?: SubmissionFilterForServer[],
  sorting?: SortingForServer,
) =>
  signedByMeSubmissionFilter(
    types.SIGNED_BY_ME_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
    formId,
    paginationToken,
    offset,
    submissionFilters,
    sorting,
  );
