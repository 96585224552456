import {connect} from 'react-redux';
import {logSubmissionNavigation} from 'actions/routing';
import { getFolderBreadcrumbs } from 'actions/lobby/folderNavigationActions';
import SubmissionCell from 'components/FormsTable/SubmissionCell';
import {getSubmissionManagerRoute} from '../../reducers';


const mapDispatchToProps = {
  logSubmissionNavigation,
  getFolderBreadcrumbs,
};

const mapStateToProps = state => ({
  submissionManagerRoute: getSubmissionManagerRoute(state),
  isSearchResult: state?.lobbyTable?.isSearchPage === true,
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionCell);
