import api from 'actions/api';
import {getAllUsers} from 'actions/users';
import {ASSIGN_USERS, ASSIGN_USERS_TO_MULTIPLE, UNASSIGN_USERS, UNASSIGN_USERS_TO_MULTIPLE} from 'constants/types/assignmentsActionTypes';

// type AssignmentsArgs = {
//   shouldSendEmailNotification?: boolean,
//   source: string,
//   submissionId: string,
//   userIds: string[],
// };

interface AssignmentsToMultipleArgs {
  submissionIds: string[],
  userIds: string[],
  excludedSubmissionIds: string[],
  formId: string,
  includeAllSubmissions?: boolean,
  onSuccess?: Function,
  onError?: Function,
  unassignAllUsers?: boolean,
}

export const getUsersFromAssignments = () => {
  const onSuccess = (_, {response: {users}}) => ({users});
  return getAllUsers(onSuccess);
};

export const assignUsers = ({
  source,
  submissionId,
  userIds,
  shouldSendEmailNotification = false,
}) =>
  api.postWithAuth({
    baseType: ASSIGN_USERS,
    endpoint: 'grm/assignSubmission.json',
    data: {
      submissionId,
      userIds,
      shouldSendEmailNotification,
    },
    success: (_state, {response: {triggeredRules}}) => ({
      source,
      submissionId,
      userIds,
      triggeredRules: triggeredRules.triggeredRules,
    }),
    failure: (_state, error) => ({error}),
  });

export const unassignUsers = ({source, submissionId, userIds}) =>
  api.postWithAuth({
    baseType: UNASSIGN_USERS,
    endpoint: 'grm/unassignSubmission.json',
    data: {
      submissionId,
      userIds,
    },
    success: () => ({source, submissionId, userIds}),
    failure: (_state, error) => ({error}),
  });

export const assignUsersToMultipleSubmissions = ({
  submissionIds,
  userIds,
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  onSuccess,
  onError,
}: AssignmentsToMultipleArgs) =>
  api.postWithAuth({
    baseType: ASSIGN_USERS_TO_MULTIPLE,
    endpoint: 'users/associate.json',
    data: {
      submissionIds,
      userIds,
      formId,
      includeAllSubmissions,
      excludedSubmissionIds,
    },
    success: (_state, { response }) => {
      onSuccess && onSuccess();
      return {
        status: response.status,
        snackbarMessage: 'Assigned successfully',
        numOfSelected: submissionIds.length,
        triggeredRules: response.result?.triggeredRules,
        submissionIds,
      };
    },
    failure: (_state, error) => {
      onError && onError();
      return error;
    },
    pending: {
      numOfSelected: submissionIds.length,
    },
  });

export const unassignUsersToMultipleSubmissions = ({
  submissionIds,
  userIds = [],
  formId,
  includeAllSubmissions,
  excludedSubmissionIds,
  unassignAllUsers = false,
  onSuccess,
  onError,
}: AssignmentsToMultipleArgs) =>
  api.postWithAuth({
    baseType: UNASSIGN_USERS_TO_MULTIPLE,
    endpoint: 'users/disassociate.json',
    data: {
      submissionIds,
      userIds,
      formId,
      unassignAllUsers,
      includeAllSubmissions,
      excludedSubmissionIds,
    },
    success: (_state, response) => {
      onSuccess && onSuccess();
      return {
        status: response.status,
        snackbarMessage: 'Assignees removed successfully',
        numOfSelected: submissionIds.length,
      };
    },
    failure: (_state, error) => {
      onError && onError();
      return error;
    },
    pending: {
      numOfSelected: submissionIds.length,
    },
  });
