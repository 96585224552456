import React from 'react';
import { map, memoize, sortBy } from 'lodash';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { pluralize } from 'humanize-plus';
import styles from './styles.css';
import permissionRowStyles from './PermissionRow/styles.css';
import * as Colors from 'utils/colors';

import PermissionRow from './PermissionRow';
import RolePermissionRow from './PermissionRow/RolePermissionRow';
import Tooltip from 'components/Tooltip';


const iconStyles = {
  cursor: 'pointer',
  color: Colors.grey400,
  height: '12px',
  marginLeft: '5px',
  position: 'relative',
  top: '2px',
  width: '12px',
};

const tooltipCopy = {
  viewForm: "Select this option to display the form in the users' lobby.",
  viewSubmissions: "Select this option to give access to all of the form's submissions.",
  editForm: 'Select this option to allow access to edit the form.',
  processSubmissions: "Select this option to all processing of all the form's submissions",
};

const sortRolePermissions = memoize(rolePermissions => sortBy(rolePermissions, 'role'));

export default class PermissionsTable extends React.Component<any, any> {
  tooltip(tooltipId) {
    return (
      <div>
        <Tooltip className={styles.tooltip} title={tooltipCopy[tooltipId]}>
          <HelpOutline style={iconStyles as any} />
        </Tooltip>
      </div>
    );
  }

  getTableHeaderText(permissions, rolePermissions) {
    const roleText = rolePermissions.length
      ? `${rolePermissions.length} ${pluralize(rolePermissions.length, 'Role')}`
      : '';
    const userText = permissions.length ? `${permissions.length} ${pluralize(permissions.length, 'User')}` : '';
    if (rolePermissions.length && permissions.length) {
      return `${roleText}, ${userText}`;
    } else if (rolePermissions.length) {
      return roleText;
    } else if (permissions.length) {
      return userText;
    }
    return '';
  }

  render() {
    const {
      authorId,
      changeAuthor,
      changePermissionLevel,
      changeRolePermission,
      changeUser,
      permissions,
      removePermission,
      removeRole,
      rolePermissions,
      users,
    } = this.props;
    const permissionsForActiveUsers = permissions.filter(permission => permission.isActive);
    const userIdsToFilter = map(permissionsForActiveUsers, 'userId');
    const hasData = permissionsForActiveUsers.length || rolePermissions.length;
    let body;
    if (hasData) {
      body = (
        <React.Fragment>
          {sortRolePermissions(rolePermissions).map(rolePermission => (
            <RolePermissionRow
              changeRolePermission={changeRolePermission}
              key={rolePermission.role}
              permissionLevels={rolePermission.permissions}
              removeRole={removeRole}
              roleName={rolePermission.role}
            />
          ))}
          {permissionsForActiveUsers.map(permission => (
            <PermissionRow
              authorId={authorId}
              changeAuthor={changeAuthor}
              changePermissionLevel={changePermissionLevel}
              changeUser={changeUser}
              isUnsaved={!permission.hasBeenSaved}
              permission={permission}
              removePermission={removePermission}
              userIdsToFilter={userIdsToFilter}
              users={users}
              key={permission.userId}
            />
          ))}
        </React.Fragment>
      );
    } else {
      body = (
        <React.Fragment>
          <tr className={`${permissionRowStyles.permissionsRow} ${styles.noUsersMessage}`}>
            <td style={{ padding: '4px 16px' }}>No users or roles</td>
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr className={`${permissionRowStyles.permissionsRow} ${styles.noUsersTip}`}>
            <td style={{ padding: '4px 16px' }}>To get started, please enter users or roles in the input above.</td>
            <td />
            <td />
            <td />
            <td />
          </tr>
        </React.Fragment>
      );
    }
    return (
      <table className={styles.permissionsTable}>
        <thead>
          <tr className={styles.permissionsTableLockedHeader}>
            <th className={styles.permissionsTableHeader}>
              {this.getTableHeaderText(permissionsForActiveUsers, rolePermissions)}
            </th>
            <th className={styles.tableHeader}>
              <div className={styles.columnHeaderContainer}>
                <span>View Form</span>
                {this.tooltip('viewForm')}
              </div>
            </th>
            <th className={styles.tableHeader}>
              <div className={styles.columnHeaderContainer}>
                <span>View Submissions</span>
                {this.tooltip('viewSubmissions')}
              </div>
            </th>
            <th className={styles.tableHeader}>
              <div className={styles.columnHeaderContainer}>
                <span>Edit Form</span>
                {this.tooltip('editForm')}
              </div>
            </th>
            <th className={styles.tableHeader}>
              <div className={styles.columnHeaderContainer}>
                <span>Process Submissions</span>
                {this.tooltip('processSubmissions')}
              </div>
            </th>
          </tr>
        </thead>
        <tbody className={styles.scrollableTable}>{body}</tbody>
      </table>
    );
  }
}
