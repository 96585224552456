import React from 'react';


export const createTreeKey = path => path.join('/');

export default class Tree extends React.Component<any, any> {
  static defaultProps = {
    hasSubTree: () => false,
    path: [''],
    shouldSort: true,
  };

  showSelf() {
    return this.props.createNode(this.props.tree, this.props.path);
  }

  showChildren() {
    const {
      tree,
      path,
      shouldSort,
      ...others
    } = this.props;

    if (tree && this.props.hasSubTree(tree)) {
      const subTreeKeys = Object.keys(tree.data);
      return (shouldSort ? subTreeKeys.sort() : subTreeKeys).map(name => {
        const subTree = tree.data[name];
        if (!(subTree && subTree.name)) return null;
        const subPath = path.concat([subTree.name]);
        return (
          <Tree {...others} key={createTreeKey(subPath)} path={subPath} tree={subTree} />
        );
      });
    }

    return null;
  }

  render() {
    const {
      style,
      className,
    } = this.props;
    return (
      <div style={style} className={className}>
        {this.showSelf()}
        {this.showChildren()}
      </div>
    );
  }
}
