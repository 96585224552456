/*
 * Dump shims here for compatibility with V1 environment
 */
import _ from 'lodash';
import { onLoad } from 'embed/utils/window';

const setFunctionBind = bind => {
  Function.prototype.bind = bind; // eslint-disable-line no-extend-native
};
let _bind;
const MooTools = (window as any).MooTools;
if (MooTools) {
  // @ts-ignore
  import(/* webpackMode: "eager" */'core-js/stable/function/virtual/bind').then(b => {
    // We delete .bind so our polyfills don't get tricked
    // @ts-ignore
    delete Function.prototype.bind;
    // Setting .bind, this may be overridden by MooTools depending on load order
    setFunctionBind(b?.default);
  });
}
const isImmutableList = item => !!(item && item.toArray && item.slice);

// Shim for V1 MooTools Array.from which our es6 polyfills override.
const _from = Array.from;
export const arrayFrom = (iter, mapFn, thisArg) => {
  if (iter == null) {
    return [];
  }

  const _iter = isImmutableList(iter) || _.isArrayLikeObject(iter) || iter instanceof Set ? iter : [iter];
  return _from(_iter, mapFn, thisArg);
};


if (MooTools) {
  // @ts-ignore
  Array.from = arrayFrom;
}

onLoad(() => {
  // Making sure we get the last word in on what .bind should be
  // @ts-ignore
  _bind && _setFunctionBind(_bind);
});

export const shimTypeIsEnumerable = () => {
  // Make MooTools Type.isEnumerable recognize ImmutableJS List.
  // This is on-demand because we load MooTools after the embed script on
  // personal stage instances.
  const Type = (window as any).Type;
  if (Type) {
    const isEnumerable = Type.isEnumerable;
    Type.isEnumerable = item => isImmutableList(item) || isEnumerable.call(Type, item);
  }
};
