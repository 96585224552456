import {
  SET_FORM_ID,
  TOGGLE_MODAL,
  SAVE_GOVOS_PAYMENT_SETTINGS,
} from 'constants/types/govOsPayTypes';
import api from 'reducers/api';

export type State = {
  isModalVisible: boolean,
  formId: string,
};

const initialState:State = {
  isModalVisible: false,
  formId: '',
};

export default (state:State = initialState, action):State => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        isModalVisible: !state.isModalVisible,
      };
    case SET_FORM_ID:
      return {...state, formId: action.formId};
    case SAVE_GOVOS_PAYMENT_SETTINGS:
      return api(action, state, {
        success: () => ({...state, isModalVisible: false}),
      });
    default:
      return state;
  }
};

export const isGovOsPayModalVisible = (state:State) => state.isModalVisible;

export const getFormId = state => state.formId;
