import React from 'react';
import * as Colors from 'utils/colors';
// import type {Style} from 'types/shared';

// type Props = {|
//   disabled: boolean,
//   label: string,
//   style?: Style,
// |};
const SubmitButton = ({ disabled, label, style = {} }) => {
  let inlineStyles = {
    color: Colors.white,
    borderRadius: '5px',
    backgroundColor: Colors.buttonBlueBackground,
    width: '100%',
    fontSize: '14px',
    fontWeight: '600',
    height: '40px',
  };
  if (disabled) {
    inlineStyles = { ...inlineStyles, opacity: '0.3' } as any;
  }

  return (
    <button
      style={{ ...inlineStyles, ...style } as any}
      type='submit'
      aria-disabled={disabled}>
      {label}
    </button>
  );
};

export default SubmitButton;
