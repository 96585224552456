import storage from 'utils/storage';
import jwtDecode from 'jwt-decode';
import {INSTRUCTIONS_ENUM} from 'constants/liveViewGating';
import {loginInterstitialPath} from 'utils/routing';

const PREDICATE_TOKEN_KEY = 'PREDICATE_TOKEN';
interface LeanFormToken {
  formId: string,
  maid: string
}

export const getPredicateTokenFromLocalStorage = (): string => {
  const token = storage().getItem(PREDICATE_TOKEN_KEY);
  return token ? token : '';
};

export const getDecodedPredicateTokenFromLocalStorage = (_token?: string): LeanFormToken => {
  const token = _token || getPredicateTokenFromLocalStorage();
  // @ts-ignore
  return !!token ? jwtDecode(token) as LeanFormToken : null;
};

export const setPredicateTokenInLocalStorage = (token: any) => {
  storage().setItem(PREDICATE_TOKEN_KEY, token);
};

export const getRouteForInstruction = (instruction: string) => {
  let route = '';
  const rawToken = getPredicateTokenFromLocalStorage();
  const token = getDecodedPredicateTokenFromLocalStorage(rawToken) || ({} as LeanFormToken);
  switch (instruction) {
    case INSTRUCTIONS_ENUM.REQUIRES_LOGIN:
      route = loginInterstitialPath(
        `?form_id=${token.formId}&maid=${token.maid}&state=/ng/liveViewGate?predicateToken=${rawToken}`
      ).replace('/ng', ''); // loginInterstitialPath helper returns a full path, but we're going to append it below
      break;
    case INSTRUCTIONS_ENUM.REDIRECT_TO_LIVE_VIEW:
      route = `?predicateToken=${rawToken}`;
      break;
    default:
      break;
  }
  return `/ng/liveViewGate${route}`;
};
