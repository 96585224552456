import React from 'react';
import {connect} from 'react-redux';
import MultiSelector from './shared/MultiSelector';


const SignersPicker = ({values, onChange, signerFields}) => (
  <MultiSelector
    values={values}
    items={signerFields}
    onChange={onChange}
    addButtonCaption='Add form signer'
    removeCaption='Remove form signer'/>
);

const mapStateToProps = ({signerFields}, {formId}) => ({
  signerFields: signerFields[formId] || [],
});

export default connect(mapStateToProps)(SignersPicker);
