import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import classNames from 'classnames';
import style from '../FormChoiceGroup/FormChoiceGroup.css';
import ErrorContainer from '../ErrorContainer/ErrorContainer';

interface Props {
  id: string,
  label: string,
  required: boolean,
  cssClass?: string,
  inputCssClass?: string,
  labelCssClass?: string,
  fieldState: any,
  onChange?: Function | null,
  updateForm?: Function | null,
}

function FormCheckbox({
  id,
  label,
  required,
  cssClass = 'choice_container',
  inputCssClass = style.ChoiceInput,
  labelCssClass = style.ChoiceLabel,
  // extraData = {},
  fieldState = null,
  onChange = null,
  updateForm = null,
} : Props, ref) {
  const checked = fieldState?.fields?.checked || false;
  const calculations = 0; // get from actual data eventually
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState('');

  const selfValidate = () => {
    if (required && !checked) {
      setError('This field is required.');
      return false;
    }

    setError('');
    return true;
  };

  useEffect(() => {
    touched && selfValidate();
  }, [checked]);

  const handleToggle = event => {
    event.preventDefault();
    setTouched(true);
    const nVal = !checked;
    onChange && onChange(event);
    updateForm && updateForm({
      fields: { checked: nVal },
      calculations: nVal ? calculations : 0,
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  return (
    <div className={classNames(cssClass, 'single_checkbox_container')} title={label}>
      <ErrorContainer error={error}>
        <input
          className={classNames(inputCssClass, 'choice_input')}
          onChange={handleToggle}
          checked={checked}
          id={id}
          name={id}
          type='checkbox'
          aria-label={label}
          data-calc-value={calculations} />
        <label className={classNames(labelCssClass, 'choice_label')} htmlFor={id}>{label}</label>
      </ErrorContainer>
    </div>
  );
}

export default React.memo(forwardRef(FormCheckbox));
