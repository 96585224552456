import React from 'react';
import Icon from '@material-ui/core/Icon';
import styles from './styles.css';
import * as Colors from 'utils/colors';

const ErrorMessage = ({ message }) => (
  <div className={styles.errorMessageWrapper}>
    <Icon style={{ margin: '0 15px', color: Colors.white, fontSize: '20px' }}>error_outline</Icon>
    {message}
  </div>
);

export default ErrorMessage;
