import {connect} from 'react-redux';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import {clearQuickFilter} from 'actions/quickFilters';
import { openFiltersModal, unsortSubmissions } from 'actions/submissionsManager/tableApi';
import {quickFiltersEnum} from 'constants/quickFilters';
import injectFeatures from 'decorators/injectFeatures';
import CurrentFilterBar from 'components/SubmissionTable/Filters/CurrentFilterBar';
import {
  getAppliedSubmissionFilters,
  getSelectedStage,
  getSelectedSubmissionQuickFilter,
  getSortingState,
  getTotalSubmissionsForFilterCount,
  isCurrentFilterBarRendered,
} from '../../reducers';
import { PropsFromState, PropsFromDispatch } from 'components/SubmissionTable/Filters/CurrentFilterBar';


export const FILTER_BAR_HEIGHT = 54;

const mapStateToProps = (state): PropsFromState => {
  const selectedQuickFilter = getSelectedSubmissionQuickFilter(state);
  return {
    isFiltered: getAppliedSubmissionFilters(state).length > 0,
    isSorted: getSortingState(state).columnKeyBeingSorted !== null,
    shouldRender: isCurrentFilterBarRendered(state),
    stage: getSelectedStage(state),
    selectedQuickFilter: selectedQuickFilter !== quickFiltersEnum.ALL ? selectedQuickFilter : null,
    submissionCount: getTotalSubmissionsForFilterCount(state),
    isSortingAndFilteringEnabled: selectTreatmentValue(state.splitio, 'SORT_AND_FILTER_SUBMISSION_MANAGER') === 'on',
  };
};

const mapDispatchToProps: PropsFromDispatch = {
  clearQuickFilter,
  openFiltersModal,
  unsortSubmissions,
};

export default injectFeatures(connect(mapStateToProps, mapDispatchToProps)(CurrentFilterBar));
