export function pendingActionWith(type, payload, error) {
  const status = 'pending';
  return {error, payload: payload || {}, status, type};
}

export function successActionWith(
  type,
  store,
  payload,
  response
) {
  return {
    payload: payload(store.getState(), response),
    status: 'success',
    type,
  };
}

export function failureActionWith(
  type,
  store,
  payload,
  error
) {
  return {
    error,
    payload: payload(store.getState(), error),
    status: 'failure',
    type,
  };
}
