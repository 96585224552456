import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import PermissionLevels from './PermissionLevels';
import User from 'components/Users/User';
import { userRolesEnum } from 'constants/user';

import styles from './styles.css';


export default class PermissionRow extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { showDelete: false };
  }

  handlePermissionChange = (isChecked, permissionLevel) => {
    const { changePermissionLevel, permission } = this.props;
    changePermissionLevel(permission.userId, isChecked, permissionLevel);
  };

  handleHover(hoverState) {
    return () => this.setState({ showDelete: hoverState });
  }

  handlePermissionRemoval = () => {
    const {
      removePermission,
      permission: { userId },
    } = this.props;
    removePermission(userId);
  };

  render() {
    const { authorId, changePermissionLevel, permission } = this.props;
    const isAuthor = authorId === permission.userId;

    const { hasBeenSaved, permissions, ...user } = permission;
    const rowStyles = styles.tableRow;
    return (
      <tr
        className={`${styles.permissionsRow} ${rowStyles}`}
        onMouseOver={this.handleHover(true)}
        onMouseLeave={this.handleHover(false)}
      >
        <td>
          <div className={styles.permissionUserGroup}>
            <div className={styles.permissionUserName}>
              <User selectionData={user} inPopover isReadOnly />
            </div>
            {this.state.showDelete && (
              <IconButton classes={{ root: styles.permissionRowDelete }} onClick={this.handlePermissionRemoval}>
                <Clear style={{ width: '10px', marginRight: 0 }} />
              </IconButton>
            )}
          </div>
        </td>
        <PermissionLevels
          changePermissionLevel={changePermissionLevel}
          isAuthor={isAuthor}
          onCheck={this.handlePermissionChange}
          permissionLevels={permission.permissions}
          showCanEditCheckbox={![userRolesEnum.VISITOR, userRolesEnum.ANALYST].includes(user.role)}
          showСanProcessSubmissionsCheckbox={![userRolesEnum.VISITOR].includes(user.role)}
        />
      </tr>
    );
  }
}
