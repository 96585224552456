import React, {useState} from 'react';
import { connect } from 'react-redux';
import styles from 'containers/SubmissionsManager/Modals/BulkActionWrapper.css';
import TagsSelect from 'containers/SubmissionsManager/Components/TagsSelect';
import {bindActionCreators} from 'redux';
import {createTags} from 'actions/tags';

const ReplaceTags = ({
  submissionIds,
  formId,
  replaceTags,
  createTag,
  dispatch,
  includeAllSubmissions,
  excludedSubmissionIds,
  options = [],
  loaderModalToggle,
  errorModalToggle,
}) => {
  const [replaceValue, setReplaceValue] = useState<any[]>([]);

  const handleReplaceTags = () => {
    replaceTags({
      submissionIds,
      formId,
      includeAllSubmissions,
      excludedSubmissionIds,
      tagsToReplace: replaceValue.map(tag => tag.name),
      onError: () => errorModalToggle(true),
    }, dispatch);
  };

  const onReplaceClick = () => {
    const submissionsNumber = includeAllSubmissions ? 'all the' : `the ${submissionIds.length} selected`;
    loaderModalToggle(
      true,
      'replaceTags',
      'Replace Existing Tags',
      `Are you sure you want to replace the existing tags from ${submissionsNumber} submission(s)?`,
      handleReplaceTags,
    );
  };

  return (<div className={styles.replaceContainer}>
    <label htmlFor='find'>Replace existing tags with:</label>
    <div className={styles.controlsContainer}>
      <div className={styles.tagsInput}>
        <TagsSelect
          options={options}
          selected={replaceValue}
          setSelected={setReplaceValue}
          createTag={createTag}
          shouldCreateTag={false}
          multiple/>
      </div>
      <button type='button' className={styles.replaceButton} onClick={onReplaceClick} disabled={!replaceValue.length}>Replace</button>
    </div>
  </div>);
};

export default connect(null, dispatch => ({
  createTag: bindActionCreators(createTags, dispatch),
  dispatch,
}))(ReplaceTags);
