/*
* Basic feature-tagging for the client.
* Warning: Do not try to use this to enforce any kind of security!
*
* TODO: Replace these with database-backed features
*
* */

const searchbarInLobby = {enabled: false};
const tableSort = {enabled: false};
const paymentDetailsRefund = {enabled: false};
const includeSubmissionDataInConditionalNotification = {enabled: false};
const signedS3Urls = {enabled: true}; // NEXT-338 - Remove this feature flag

export default {
  includeSubmissionDataInConditionalNotification,
  paymentDetailsRefund,
  searchbarInLobby,
  signedS3Urls,
  tableSort,
};
