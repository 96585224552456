import React from 'react';
import ReactDOM from 'react-dom';

import closeIcon from 'icons/closeIcon.svg';

import styles from './ConfirmationModal.css';


const ModalPortal = ({ children }) => {
  const modalNode = document.querySelector('.modal-window');
  return modalNode ? ReactDOM.createPortal(children, modalNode) : null;
};


const ConfirmationModal = ({ onApprove, onCancel, title, description, disagreeText, agreeText }) => (
  <ModalPortal>
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        <img className={styles.close} onClick={onCancel} alt='closeIcon' src={closeIcon} />
        <div className={styles.header}>{title}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.buttons}>
          <div className={styles.cancelButton} onClick={onCancel}>
            {disagreeText}
          </div>
          <div className={styles.approveButton} onClick={onApprove}>
            {agreeText}
          </div>
        </div>
      </div>
    </div>
  </ModalPortal>
);

export default ConfirmationModal;
