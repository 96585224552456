import React from 'react';
import ReactDOM from 'react-dom';
import {syncHistoryWithStore} from 'react-router-redux';
import {browserHistory} from 'react-router';
import { initSplitSdk } from '@splitsoftware/splitio-redux';
import Root from 'containers/Root';
import './styles/main.css';
import './styles/fixed-data-table.css';
import './styles/filestack.css';
import store from './store';
import {isSupportedBrowser} from 'utils/browsers';
import UpgradeBrowser from 'components/UpgradeBrowser';

const appContainerElement = document.getElementById('app');

const CONFIG = {
  core: {
    authorizationKey: 'nf6la0prrjrks14opg5fuqno3s2nlje1njt8',
    key: '55epev8a305806cuqsclv2cs9ormosrk0o9d',
  },
};

store.dispatch(initSplitSdk({
  config: CONFIG,
}) as any);

if (isSupportedBrowser()) {
  const history = syncHistoryWithStore(browserHistory, store);

  const mountDevRoot = () => {
    const Redbox = require('redbox-react').default;
    const AppContainer = require('react-hot-loader').AppContainer;
    const HotRoot = require('./containers/Root').default;
    if (appContainerElement != null) {
      ReactDOM.render(
        <AppContainer errorReporter={Redbox}>
          <HotRoot store={store} history={history}/>
        </AppContainer>,
        appContainerElement
      );
    }
  };


  if (process.env.NODE_ENV === 'production') {
    if (appContainerElement != null) {
      ReactDOM.render(
        <Root store={store} history={history}/>,
        appContainerElement
      );
    }
  } else {
    mountDevRoot();
  }

  if ((module as any).hot) {
    (module as any).hot.accept('./containers/Root', mountDevRoot);
  }
} else {
  if (appContainerElement != null) {
    ReactDOM.render(
      <UpgradeBrowser/>,
      appContainerElement
    );
  }
}
