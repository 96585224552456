import { UPLOAD_ATTACHMENT, REMOVE_ATTACHMENTS } from 'constants/types/formActionTypes';
import api from '../api';

const initialState = Object.freeze({});
// TODO: change according to actual req/res format
export default (state: any = initialState, action_) => {
  const action = action_;
  switch (action.type) {
    case UPLOAD_ATTACHMENT:
      return api(action, state, {
        success: () => {
          const {pendingUploads = {}} = state;
          const { payload: { attachment, id } } = action;
          const pendingForId = pendingUploads[id];
          const isPending = pendingForId.has(attachment.fileName);
          if (isPending) {
            pendingUploads[id].delete(attachment.fileName); // remove from pending when complete
            return {
              ...state,
              pendingUploads,
              [id]: state[id] ? [...state[id], attachment] : [attachment],
            };
          }
          return state;
        },
        pending: () => {
          const { payload: { pendingFile, id } } = action;
          const {pendingUploads = {}} = state;
          pendingUploads[id] = pendingUploads[id] || new Set();
          pendingUploads[id].add(pendingFile.name);
          return {
            ...state,
            pendingUploads,
          };
        },
      });
    case REMOVE_ATTACHMENTS:
      const { payload: { id, attachments } } = action;
      const prev = state[id] || [];
      const {pendingUploads = {}} = state;
      const pendingForId = pendingUploads[id];
      if (pendingForId) {
        attachments.forEach(a => pendingForId.delete(a?.fileName));
      }
      const removeUrls = attachments.map(a => a?.url);
      const nAtt = prev.filter(att => !removeUrls.includes(att.url));
      return {
        ...state,
        [id]: nAtt,
        pendingUploads,
      };
    default:
      return state;
  }
};
