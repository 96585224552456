import React from 'react';
import { DragSource } from 'react-dnd';
import bowser from 'bowser';
import { ItemTypes } from 'constants/tableConsts';
import styles from './Box.css';
import * as Colors from 'utils/colors';


const boxSource = {
  beginDrag(props) {
    const { left } = props;
    return { left };
  },
};

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

class Box extends React.Component<any, any> {
  handleMouseUp = () => {
    const { makeSortable, sortingTargetIndex } = this.props;
    makeSortable(sortingTargetIndex, '', true);
  }

  render() {
    const {
      hideSourceOnDrag,
      left,
      connectDragSource,
      headerLabel,
      isDragging,
    } = this.props;
    if (isDragging && hideSourceOnDrag) {
      return null;
    }

    const boxStyle = {
      backgroundColor: bowser.msie ? Colors.lightBlack : undefined,
      left,
    };

    return connectDragSource ? connectDragSource(
      <div
        className={styles.box}
        style={boxStyle}
        onMouseUp={bowser.msie ? undefined : this.handleMouseUp}>
        <div className={styles.columnHeader}>
          {headerLabel}
        </div>
      </div>
    ) : null;
  }
}

export default DragSource(ItemTypes.BOX, boxSource, collect)(Box);
