import { isNumber, cloneDeep } from 'lodash';

import api from 'reducers/api';
import {
  ADD_EMPTY_RULE,
  DELETE_RULE,
  MODIFY_RULE,
  LOAD_RULES,
} from 'constants/types/conditionalsActionTypes';
import {
  OPEN_ADD_PARTICIPANT_TAB,
  OPEN_EDIT_EXISTING_PARTICIPANT,
  DUPLICATE_PARTICIPANT,
  CLOSE_ADD_PARTICIPANT_TAB,
  OPEN_ADD_GROUP_DETAILS_TAB,
  OPEN_EDIT_EXISTING_GROUP,
  CLOSE_ADD_GROUP_DETAILS_TAB,
  LOAD_PARTICIPANTS_WITH_CONDITIONALS,
} from 'constants/participantsSidebar';
import { immutableModifyElementInArray, immutableRemoveElementInArray } from 'utils/reduxHelper';
import { convertRulesForClient, createEmptyRule } from 'utils/conditionals';

import { Action, State } from 'types/conditionals';
import { CurrentParticipant, Participant } from 'types/participants';

export const initialState: State = {
  rules: {},
  stateBeforeEditingParticipant: [],
  stateBeforeEditingGroup: [],
  haveRulesChangedSincePreviousState: false,
};

export const findRuleIndex = (formId: string, frontendId: string, state: State) =>
  state.rules[formId].findIndex(rule => rule.frontendId === frontendId);

export default function conditionals(state: State = initialState, action: Action | any): State {
  switch (action.type) {
    case LOAD_RULES:
      return api(action, state, {
        success: () => ({
          ...state,
          rules: {
            [action.payload.formId]: convertRulesForClient(action.payload.rules, action.payload.existingParticipants),
          },
        }),
        failure: () => ({
          ...state,
          rules: {
            [action.payload.formId]: convertRulesForClient(action.payload.rules, action.payload.existingParticipants),
          },
        }),
      });

    case LOAD_PARTICIPANTS_WITH_CONDITIONALS:
      return api(action, state, {
        success: () => ({
          ...state,
          rules: {
            [action.payload.formId]: action?.payload?.clientRules || [],
          },
        }),
      });

    case ADD_EMPTY_RULE:
      const existingRules = state.rules[action.formId] || [];
      return {
        ...state,
        haveRulesChangedSincePreviousState: true,
        rules: {
          ...state.rules,
          [action.formId]: [...existingRules, createEmptyRule(action.frontendId, existingRules.length)],
        },
      };

    case DELETE_RULE:
      const ruleToDeleteIndex = findRuleIndex(action.formId, action.frontendId, state);
      if (isNumber(ruleToDeleteIndex) && ruleToDeleteIndex >= 0) {
        return {
          ...state,
          haveRulesChangedSincePreviousState: true,
          rules: {
            ...state.rules,
            [action.formId]: immutableRemoveElementInArray(state.rules[action.formId], ruleToDeleteIndex),
          },
        };
      }
      return state;

    case MODIFY_RULE:
      const ruleToModifyIndex = findRuleIndex(action.formId, action.rule.frontendId, state);
      if (isNumber(ruleToModifyIndex) && ruleToModifyIndex >= 0) {
        return {
          ...state,
          haveRulesChangedSincePreviousState: true,
          rules: {
            ...state.rules,
            [action.formId]: immutableModifyElementInArray(state.rules[action.formId], ruleToModifyIndex, action.rule),
          },
        };
      }
      return state;

    case OPEN_ADD_PARTICIPANT_TAB:
    case OPEN_EDIT_EXISTING_PARTICIPANT:
    case DUPLICATE_PARTICIPANT:
      const rulesBeforeEditingParticipant = cloneDeep(state.rules[action.formId]);
      return {
        ...state,
        stateBeforeEditingParticipant: rulesBeforeEditingParticipant,
        haveRulesChangedSincePreviousState: false,
      };

    case CLOSE_ADD_PARTICIPANT_TAB:
      return {
        ...state,
        haveRulesChangedSincePreviousState: false,
        rules: {
          ...state.rules,
          [action.formId]: state.stateBeforeEditingParticipant,
        },
      };

    case OPEN_ADD_GROUP_DETAILS_TAB:
    case OPEN_EDIT_EXISTING_GROUP:
      const rulesBeforeEditingGroup = cloneDeep(state.rules[action.formId]);
      return {
        ...state,
        stateBeforeEditingGroup: rulesBeforeEditingGroup,
        haveRulesChangedSincePreviousState: false,
      };

    case CLOSE_ADD_GROUP_DETAILS_TAB:
      return {
        ...state,
        haveRulesChangedSincePreviousState: false,
        rules: {
          ...state.rules,
          [action.formId]: state.stateBeforeEditingGroup,
        },
      };

    default:
      return state;
  }
}

export const getConditionalsState = (state: State) => state;

export const getConditionalsForForm = (state: State, formId: string) => state.rules[formId] || [];

export const getConditionalsStateBeforeEditingParticipant = (state: State) => state.stateBeforeEditingParticipant;

export const getHaveConditionalsChangedSincePreviousState = (state: State) => state.haveRulesChangedSincePreviousState;

export const getConditionalsForParticipant = (
  state: State,
  formId: string,
  participant: Participant | CurrentParticipant,
) => {
  const conditionalsForForm = getConditionalsForForm(state, formId);
  return conditionalsForForm.filter(conditional => (conditional.frontendId === participant.frontendId));
};
