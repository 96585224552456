import React from 'react';
import {last} from 'lodash';
import Icon from '@material-ui/core/Icon';
import DarkIconButton from 'components/Toolbar/buttons/DarkIconButton';
import * as Colors from 'utils/colors';
import {textWithEllipsis} from 'utils/textHelper';


const breadcrumbsIconStyle = {
  color: Colors.white,
  opacity: 0.5,
  padding: '0 1px',
  verticalAlign: 'middle',
};

const PathSeparator = () => (
  <Icon style={breadcrumbsIconStyle}>
    keyboard_arrow_right
  </Icon>
);

export const Name = ({name, maxLength}) =>
  <span>{textWithEllipsis(name, maxLength)}</span>;

const joinPathWithSeparators = (path, nameSpan) =>
  path.reduce((accumulator, component, index) => {
    const key = `${component}_${index}`;
    return accumulator.concat(
      <span key={key}>{component}</span>,
      [<PathSeparator key={`${key}_icon`}/>]
    );
  }, []).concat(nameSpan);

export default function breadcrumbs(
  path,
  isPhone,
  isTablet,
  onClickBackArrow
) {
  const ancestors = path.slice(0, -1);
  const name = last(path);
  const key = `${name}_path_component`;

  if (isPhone || isTablet) {
    const nameSpan = <Name key={key} name={name} maxLength={50}/>;
    if (ancestors.length) {
      const backButton = [
        <DarkIconButton
          icon='arrow_back'
          key={`${name}_icon`}
          onClick={onClickBackArrow}
          style={{marginLeft: '-16px'}}/>,
      ];
      return isPhone ? backButton : backButton.concat(nameSpan);
    }
    return isPhone ? [] : [nameSpan];
  }
  return joinPathWithSeparators(
    ((ancestors.join('') + name).length > 80) ? ['...'] : ancestors,
    <Name key={key} name={name} maxLength={80}/>
  );
}
