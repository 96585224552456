import {
  SET_FORM_ID,
  TOGGLE_MODAL,
  SET_IS_PAYMENT_ACTIVE,
  GET_PROVIDER_OPTIONS,
  SAVE_GOVOS_PAYMENT_SETTINGS,
  GET_GOVOS_PAYMENT_SETTINGS,
} from 'constants/types/govOsPayTypes';
import api from 'actions/api';
import { SourceAmountTypes } from 'constants/payments';
import {
  convertPaymentConfigResponse,
  parseFeeValues,
} from 'utils/govOsPaymentSettings';
import { GovOSPaymentConfigForServer, PaymentSettingsFormValues } from 'types/govOsPay/govOsPaymentSettings';
import {defaultPaymentConfig} from 'embed/reducers/govOsPaymentConfiguration';

export const toggleModal = () => ({
  type: TOGGLE_MODAL,
});

export const setFormId = (formId: string) => ({
  type: SET_FORM_ID,
  formId,
});

export const setPaymentActivityStatus = (isActive: boolean) => ({
  status: isActive ? 'active' : 'disabled',
  type: SET_IS_PAYMENT_ACTIVE,
});

export const getProviderOptions = () => api.postWithAuth({
  endpoint: 'payments/govosPay/providers.json',
  baseType: GET_PROVIDER_OPTIONS,
  success: (state, { response }) => ({ providers: response?.result?.payload || [] }),
  failure: (state, error) => ({ error }),
  pending: state => state,
});

export const savePaymentSettings = (
  formId: string,
  paymentsConfigId: string | null,
  status: string,
  fields: PaymentSettingsFormValues,
) => {
  const ccFees = fields.ccFeeType ? [{
    feeType: fields.ccFeeType,
    customFeeName: fields.ccFeeName,
    paymentMethodType: 'credit_card',
    state: fields.isCCFeeActive ? 'enabled' : 'disabled',
    ...parseFeeValues(
      fields.ccFeeType,
      fields.ccFeeAmount,
      fields.ccFeePercentage,
      fields.ccFeeIsOffsetEnabled,
      fields.ccCombinationFeeAmount,
      fields.ccCombinationFeePercentage,
      fields.ccCombinationIsOffsetEnabled,
      fields.ccGreaterFeeAmount,
      fields.ccGreaterFeePercentage,
      fields.ccGreaterIsOffsetEnabled,
    ),
  }] : [];

  const paymentsConfig: GovOSPaymentConfigForServer = {
    paymentsConfigId: status === 'active' ? paymentsConfigId : null,
    formId,
    status,
    description: fields.description,
    providerId: fields.providerId,
    paymentOptional: fields.paymentOptional || false,
    amountSourceType: fields.amountSource,
    amountSource: fields.amountSource === SourceAmountTypes.SET_AMOUNT ? fields.inputAmount : fields.fieldValue,

    ccEnabled: fields.ccEnabled,
    achEnabled: fields.achEnabled,

    ccFeeStructure: {
      fees: [...ccFees],
    },
    achFeeStructure: {
      fees: fields.achFeeType
        ? [
          {
            feeType: fields.achFeeType,
            customFeeName: fields.achFeeName,
            paymentMethodType: 'ach',
            state: fields.isACHFeeActive ? 'enabled' : 'disabled',
            ...parseFeeValues(
              fields.achFeeType,
              fields.achFeeAmount,
              fields.achFeePercentage,
              fields.achFeeIsOffsetEnabled,
              fields.achCombinationFeeAmount,
              fields.achCombinationFeePercentage,
              fields.achCombinationIsOffsetEnabled,
              fields.achGreaterFeeAmount,
              fields.achGreaterFeePercentage,
              fields.achGreaterIsOffsetEnabled,
            ),
          },
        ]
        : [],
    },
    // TODO: Remove type casting when possible
  } as GovOSPaymentConfigForServer;

  return api.postWithAuth({
    endpoint: 'payments/configuration/saveGovOsPayConfig.json',
    baseType: SAVE_GOVOS_PAYMENT_SETTINGS,
    data: { formId, paymentsConfig },
    pending: () => {},
    success: (state, { response }) => response,
    failure: (state, error) => ({ error }),
  });
};

export const getPaymentSettings = (formId: string) => api.postWithAuth({
  endpoint: 'payments/configuration/getGovOsPayConfig.json',
  baseType: GET_GOVOS_PAYMENT_SETTINGS,
  data: { formId },
  success: (state, { response }) => ({
    config: response?.result ? convertPaymentConfigResponse(response?.result) : defaultPaymentConfig,
  }),
  failure: (state, error) => ({ error }),
});
