import React from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import styles from './FormLiveView.css';
import modalLogo from 'images/pay_modal_choice_logo.svg';

interface Props {
  onClose?: React.EventHandler<React.SyntheticEvent>
  title?: string,
  onJustSubmit: React.MouseEventHandler,
  onPayAndSubmit: React.MouseEventHandler,
  open: boolean,
  amount: number,
}

function PaymentChoiceModal({
  onClose,
  title = 'Pay now or later',
  onJustSubmit,
  onPayAndSubmit,
  open,
  amount,
  ...props
}: Props): JSX.Element {
  return (
    <Dialog
      classes={{
        root: classNames(styles.PaymentModalRoot, 'form_pay_modal_root'),
        paper: classNames(styles.PaymentModalContainer, 'form_pay_modal_container'),
      }}
      open={open}
      disableBackdropClick
      onClose={onClose}
      {...props}
    >
      <DialogTitle disableTypography className={classNames(styles.PaymentModalTitle, 'form_pay_modal_title')}>
        <img src={modalLogo} alt='logo'/><br /><br /><br />
        {title}
      </DialogTitle>
      <DialogContent>
        <div style={{textAlign: 'center', marginTop: 0}}>The cost of this submission is <strong>${amount.toFixed(2)}</strong></div>
      </DialogContent>
      <DialogActions>
        <Button
          disableRipple
          className={classNames(styles.PaymentModalActionButton, 'form_pay_modal_button')}
          onClick={onJustSubmit}>Submit Without Paying</Button>
        <Button
          disableRipple
          className={classNames(styles.PaymentModalActionButton, styles.PaymentModalActionButtonAttention, 'form_pay_modal_button')}
          onClick={onPayAndSubmit}>Pay Now</Button>
      </DialogActions>
      <div style={{textAlign: 'center', fontSize: '0.8rem', color: '#000'}}>
        Go  <button className={styles.PaymentModalGoToFormButton} onClick={onClose}>back to the form</button>
      </div>
    </Dialog>
  );
}

export default React.memo(PaymentChoiceModal);
