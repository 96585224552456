// import type {FormPublicInfo, FormLetterhead} from 'types/forms';

export const BLANK_FORM_LETTERHEAD = {
  address: '',
  companyName: '',
  email: '',
  logo: '',
  phoneNumber: '',
};

export const BLANK_FORM = {
  formId: '',
  formName: '',
  letterheadInfo: BLANK_FORM_LETTERHEAD,
  publicFormSettings: {
    allowVisitorRegistration: false,
  },
};

export const SM_TABLE_SOURCE = 'Submission Manager';
export const SM_DETAILS_SOURCE = 'Submission Details';
