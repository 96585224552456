export const BACK_SPACE_KEY = 8;
export const ENTER_KEY = 13;
export const ESC_KEY = 27;
export const SPACE_KEY = 32;
export const LEFT_KEY = 37;
export const UP_KEY = 38;
export const DOWN_KEY = 40;
export const DEL_KEY = 46;
export const HOME_KEY = 36;
export const END_KEY = 35;
export const TAB_KEY = 9;
export const LEFT_CMD_WIN_KEY = 91;
export const RIGHT_CMD_CONTEXT_KEY = 93;
export const CTRL_KEY = 17;
export const _isAnArrowKey = (key: number) => (key >= LEFT_KEY && key <= DOWN_KEY);
export const _getKeyboardCode = (e: KeyboardEvent) => e.keyCode || e.which;
export const _isCmdCtrlKey = (key: number) =>
  (key === LEFT_CMD_WIN_KEY || key === RIGHT_CMD_CONTEXT_KEY || key === CTRL_KEY);
