import React, {useEffect, useRef} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import { Formik, Form } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';

import NewAuthLayout from 'components/AuthLayout/NewAuthLayout';
import FieldContainer from 'containers/Login/FieldContainer/FieldContainer';
import TermsOfUse from 'containers/Login/TermsOfUse';
import * as authActions from 'actions/auth';
import styles from 'containers/Login/Login/LoginForm/LoginForm.css';

const FormSchema = Yup.object().shape({
  accessCode: Yup.string().required('This can’t be blank.'),
});

const AccessCode = ({
  setSubmissionViewerRoute,
  isFetchingOrganizationInfo,
  isSubdomainExists,
  submissionViewerRoute,
  companyName,
  organizationLogo,
  verifyAccessCode,
  accessCode,
  router,
  error,
  maid,
  dispatch,
}) => {
  const form = useRef(null);
  useEffect(() => {
    dispatch(authActions.getAccountKey(dispatch));
  }, []);
  const subtitle = 'You can view this submission by entering the unique access code that was included in the email notification you received.';

  useEffect(() => {
    if (error && form.current) {
      // @ts-ignore
      form.current.setErrors({
        accessCode: error,
      });
    }
  });

  useEffect(() => {
    if (!!accessCode) {
      if (submissionViewerRoute) {
        router.push(submissionViewerRoute);
        setSubmissionViewerRoute(null);
      }
    }
  }, [accessCode]);

  const onSubmit = code => {
    verifyAccessCode(code, maid);
  };

  return (
    <>
      <NewAuthLayout
        title='View Submission'
        subtitle={subtitle}
        fetching={isFetchingOrganizationInfo || !isSubdomainExists}
        companyName={companyName}
        logo={organizationLogo}
      >
        <Formik
          innerRef={form}
          initialValues={{
            accessCode: '',
          }}
          validationSchema={FormSchema}
          onSubmit={values => {
            onSubmit(values.accessCode);
          }}
        >
          {({ isValid }) => (
            <Form>
              <div className={styles.form}>
                <div className={styles.emailContainer}>
                  <FieldContainer name='accessCode' label='Access Code'>
                    {(field, meta) => (
                      <input
                        className={classNames(styles.input, {
                          [styles.invalidInput]: meta.touched && meta.error,
                        })}
                        type='text'
                        {...field}
                      />
                    )}
                  </FieldContainer>
                </div>

                <div className={styles.submitBtnContainer}>
                  <button
                    className={classNames(styles.submitBtn)}
                    type='submit'
                    disabled={!isValid}
                  >
                    View Submission
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div className={styles.backBtnContainer}>
          <Link to='/ng/login'>Back to Login</Link>
        </div>

        <TermsOfUse />
      </NewAuthLayout>
    </>
  );
};

const mapStateToProps = state => {
  const {
    auth: {
      isFetchingOrganizationInfo,
      isSubdomainExists,
      organizationLogo,
      companyName,
      accessCode,
      verifyAccessCodeError,
      submissionViewerRoute,
      maid,
    },
  } = state;
  return {
    error: verifyAccessCodeError,
    accessCode,
    isFetchingOrganizationInfo,
    submissionViewerRoute,
    isSubdomainExists,
    organizationLogo,
    companyName,
    maid,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  verifyAccessCode: authActions.verifyAccessCode,
  setSubmissionViewerRoute: authActions.setSubmissionViewerRoute,
  dispatch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccessCode);
