import React, { useCallback } from 'react';
import TypeDropdown from './TypeDropdown';
import Picker from './Picker';

import styles from './RecipientsSelector.css';


const RecipientsSelector = ({
  formId,
  value,
  onChange,
}) => {
  const handleOnTypeChange = useCallback(newType => {
    onChange({
      recipientType: newType,
      recipients: [],
    });
  }, [onChange]);

  const handleOnListChange = useCallback(newList => {
    if (!value || !newList) return;
    onChange({
      recipientType: value.recipientType,
      recipients: newList,
    });
  }, [onChange, value]);

  return (
    <div className={styles.recipientsSelectorRow}>
      <TypeDropdown
        formId={formId}
        type={value && value.recipientType}
        onChange={handleOnTypeChange} />
      {value && (
        <Picker
          formId={formId}
          type={value.recipientType}
          values={value.recipients}
          onChange={handleOnListChange} />
      )}
    </div>
  );
};

export default RecipientsSelector;
