import React from 'react';
import TypeaheadInput from 'components/Typeaheads/TypeaheadInput';


export default props => (
  <TypeaheadInput
    onChange={props.onChange}
    placeholder='Tag name...'
    value={props.value} />
);
