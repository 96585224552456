import React from 'react';
import Assignments from 'components/Assignees/Assignments';
import { SM_DETAILS_SOURCE } from 'constants/forms';
import { NO_ASSIGNEES_MESSAGE } from 'constants/assignees';


export default props => (
  <Assignments
    {...props}
    maxElements={0}
    source={SM_DETAILS_SOURCE}
    noAssigneesMessage={NO_ASSIGNEES_MESSAGE} />
);
