import React, { MouseEventHandler } from 'react';
import Icon from '@material-ui/core/Icon';
import MaterialUIMenuItem from '@material-ui/core/MenuItem';
import DarkIconButton from '../buttons/DarkIconButton';
import { shouldContract } from './shared';
import styles from 'components/Toolbar/Toolbar.css';

interface Props {
  icon?: string | JSX.Element,
  text?: string,
  windowWidth?: number | string,
  onClick?: Function,
}
export default class MenuItem extends React.Component<Props & Partial<any>, any> {
  render() {
    const { icon, text, onClick, windowWidth } = this.props;

    if (shouldContract(windowWidth)) {
      return (
        <MaterialUIMenuItem onClick={onClick as MouseEventHandler}>
          <Icon classes={{ root: styles.menuItemIcon }}>{icon}</Icon>
          <div>{text}</div>
        </MaterialUIMenuItem>
      );
    }

    return (
      <span>
        <DarkIconButton
          icon={icon}
          onClick={onClick}
          tooltip={text} />
      </span>
    );
  }
}
