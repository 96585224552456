import React from 'react';
import RecordCell from './RecordCell';
import CheckboxCell from './CheckboxCell';
import SubmissionCellIcon from './SubmissionCellIcon';
import SortFilterDropdown from './SortFilterDropdown';
import TableSort from './TableSort';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { debounce, some } from 'lodash';
import emptySubmissionsImg from 'images/v2-emptysubmissions.svg';
import styles from './SubmissionTable.css';
import {
  createTableColumns,
} from 'utils/submissionsManager/tableHelper';


import injectPermissions from 'decorators/injectPermissions';


class SubmissionTable extends React.Component<any, any> {
  setScrollLeftDebounced;

  static defaultProps = {
    width: 960,
    height: 200,
  };

  constructor(props) {
    super(props);
    this.state = {
      hoveredRowIndex: null,
      scrollLeft: 0,
      scrollToColumn: null,
      isDragScrolling: false,
    };

    this.setScrollLeftDebounced = debounce(this.setScrollLeft, 500, { leading: true });
  }

  _onRowClick = (event, index) => {
    this.props.onRowClick(event, index, this.props.table.records[index]);
  };

  setScrollLeft = scrollX => {
    this.setState({
      scrollLeft: scrollX,
    });
  };

  scrollOnDrag = (index, isScrollingRight) => {
    const { scrollToColumn } = this.state;
    if (isScrollingRight) {
      this.setState({
        scrollToColumn: scrollToColumn ? scrollToColumn + 1 : index + 1,
        isDragScrolling: true,
      });
    } else {
      this.setState({
        scrollToColumn: scrollToColumn ? scrollToColumn - 1 : index - 1,
        isDragScrolling: true,
      });
    }
  };

  scrollOnDragStop = () => {
    const { isDragScrolling } = this.state;
    if (isDragScrolling) {
      this.setState({
        scrollToColumn: null,
        isDragScrolling: false,
      });
    }
  };

  onScrollX = position => {
    if (this.state.isDragScrolling) {
      this.setScrollLeftDebounced(position);
    } else {
      this.setScrollLeft(position);
    }
    // `fixed-data-table-2` won't update the table's scroll position without this
    return true;
  };

  onScrollY = position => {
    this.props.onScrollY(position);
    // `fixed-data-table-2` won't update the table's scroll position without this
    return true;
  }

  handleMouseEnter = (event, index) => {
    this.setState({
      hoveredRowIndex: index,
    });
  }

  handleMouseLeave = () => {
    this.setState({
      hoveredRowIndex: null,
    });
  }

  render() {
    const {
      emptyStateMessage,
      width,
      height,
      table,
      isTableSortable,
      makeSortable,
      onContentHeightChange,
      sortTableColumns,
      sortingTargetIndex,
      rowClassNameGetter,
      headerLabel,
      scrollToRow,
      formId,
      sortedFilteredColumns,
      tableSortTop,
      onRowClick, // eslint-disable-line no-unused-vars
      ...other
    } = this.props;

    const {
      hoveredRowIndex,
      scrollToColumn,
      scrollLeft,
    } = this.state;

    const columnComponents = {
      Column,
      CheckboxCell,
      Cell,
      RecordCell,
      SortFilterDropdown,
      SubmissionCellIcon,
    };

    /**
     * Blank State
     */
    if (table.records.length === 0) {
      return (
        <div className={styles.blankStateContainer}>
          <img alt='' className={styles.blankImage} src={emptySubmissionsImg} />
          <div className={styles.blankCopy}>{emptyStateMessage}</div>
        </div>
      );
    }

    const { records } = table;
    const includeCheckboxes = this.props.canUserExportSubmissions();
    const isIncompleteOrHasSubmissionPdfUrl =
      some(records, record => (record.submissionPDFUrl !== null) || record.isIncomplete);

    const tableColumns = createTableColumns({
      _this: this,
      styles,
      columnComponents,
      isIncompleteOrHasSubmissionPdfUrl,
      includeCheckboxes,
      hoveredRowIndex,
      sortedFilteredColumns,
    });

    return (
      <div className='fixedDataTable submissionTable'>
        <Table
          {...other}
          headerHeight={54}
          height={height}
          onContentHeightChange={onContentHeightChange}
          onRowClick={this._onRowClick}
          onRowMouseEnter={this.handleMouseEnter}
          onRowMouseLeave={this.handleMouseLeave}
          onHorizontalScroll={this.onScrollX}
          onVerticalScroll={this.onScrollY}
          ownerHeight={height}
          ref='table'
          rowClassNameGetter={rowClassNameGetter}
          rowHeight={54}
          rowsCount={table && table.records.length}
          scrollToColumn={scrollToColumn}
          scrollToRow={scrollToRow}
          touchScrollEnabled
          width={width}>
          {tableColumns}
        </Table>
        <TableSort
          formId={formId}
          headerLabel={headerLabel}
          isIncompleteOrHasSubmissionPdfUrl={isIncompleteOrHasSubmissionPdfUrl}
          isTableSortable={isTableSortable}
          makeSortable={makeSortable}
          scrollLeft={scrollLeft}
          scrollOnDrag={this.scrollOnDrag}
          scrollOnDragStop={this.scrollOnDragStop}
          sortingTargetIndex={sortingTargetIndex}
          sortTableColumns={sortTableColumns}
          source={sortedFilteredColumns}
          tableSortTop={tableSortTop}
          width={width} />
      </div>
    );
  }
}

export default injectPermissions(SubmissionTable);
