

const onlyNumbersStrHandler = (strValue: string) : string => strValue.replace(/[^0-9.]/g, '');

export const formatCurrencyHandler = (inputValue: string): string => {
  if (!inputValue.trim()) {
    return '';
  }

  const onlyNumbers = onlyNumbersStrHandler(inputValue);
  if (!onlyNumbers) {
    return '$0.00';
  }

  const formattedValue = parseFloat(onlyNumbers).toFixed(2);
  const withCommas = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `$${withCommas}`;
};

export const formatPercentageHandler = (inputValue: string): string => {
  if (!inputValue.trim()) {
    return '';
  }

  const numericValue = onlyNumbersStrHandler(inputValue);
  if (!numericValue) {
    return '0%';
  }

  const formattedValue = parseFloat(numericValue).toFixed(2);
  return `${formattedValue}%`;
};


export const formatNumberHandler = (inputValue: string): string => {
  const trimmedValue = inputValue.trim();

  if (!trimmedValue) {
    return '';
  }

  const onlyNumbers = trimmedValue.replace(/[^0-9.]/g, '');

  const parsedValue = parseFloat(onlyNumbers);

  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue.toString();
};

export const formatLowerCaseHandler = (inputValue: string): string => {
  const trimmedValue = inputValue.trim();
  if (!trimmedValue) {
    return '';
  }
  return trimmedValue.replace(/[A-Z]/g, char => char.toLowerCase());
};

export const formatUpperCaseHandler = (inputValue: string): string => {
  const trimmedValue = inputValue.trim();
  if (!trimmedValue) {
    return '';
  }
  return trimmedValue.replace(/[a-z]/g, char => char.toUpperCase());
};

// Unified formatting handlers
export const formattingHandlers = {
  currency: formatCurrencyHandler,
  percent: formatPercentageHandler,
  number: formatNumberHandler,
  lowercase: formatLowerCaseHandler,
  uppercase: formatUpperCaseHandler,
};
