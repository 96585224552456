import {
  MODIFY_MAPPING,
  SAVE_MAPPINGS,
  LOAD_MAPPINGS,
} from 'constants/types/dataDictionaryActionTypes';
import { immutableModifyElementInArray, immutableInsertElementInArray } from 'utils/reduxHelper';
import { DataDictionaryMapping } from 'types/dataDictionary';
import api from 'reducers/api';

type State = {
  mappings: { [key: string]: DataDictionaryMapping[] },
};


const initialState: State = {
  mappings: {},
};

export default function dataDictionaries(state = initialState, action) {
  switch (action.type) {
    case MODIFY_MAPPING: {
      const { formId, mapping } = action.payload;
      const formMappings = state.mappings[formId] || [];
      console.log('formMappings', formMappings); // eslint-disable-line
      const index = formMappings.findIndex(map => map.formFieldId === mapping.formFieldId);
      const newMappings = index === -1
        ? immutableInsertElementInArray(formMappings, formMappings.length, mapping)
        : immutableModifyElementInArray(formMappings, index, mapping);
      console.log('newMappings', newMappings); // eslint-disable-line
      return {
        ...state,
        mappings: {
          ...state.mappings,
          [formId]: newMappings,
        },
      };
    }
    case SAVE_MAPPINGS:
      return api(action, state, {
        success: () => ({
          ...state,
          [action.payload.formId]: action.payload.fieldMappings,
        }),
        failure: () => ({
          ...state,
          [action.payload.formId]: action.payload.fieldMappings || [],
        }),
      });
    case LOAD_MAPPINGS:
      return api(action, state, {
        success: () => ({
          ...state,
          mappings: {
            ...state.mappings,
            [action.payload.formId]: action.payload.fieldMappings,
          },
        }),
        failure: () => ({
          ...state,
          mappings: {
            ...state.mappings,
            [action.payload.formId]: action.payload.fieldMappings || [],
          },
        }),
      });
    default:
      return state;
  }
}

export const getDDMappings = (state: State) => state.mappings;

export const getDDMappingsForForm = (state: State, formId: string): DataDictionaryMapping[] =>
  getDDMappings(state)[formId] || [];
