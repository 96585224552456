import { cloneDeep, isEqual, isNumber } from 'lodash';

import api from 'reducers/api';

import {
  TOGGLE_MODAL,
  SET_FORM_ID,
  LOAD_PARTICIPANTS,
  SAVE_PARTICIPANTS,
  OPEN_ADD_PARTICIPANT_TAB,
  OPEN_EDIT_EXISTING_PARTICIPANT,
  CLOSE_ADD_PARTICIPANT_TAB,
  UPDATE_CURRENT_PARTICIPANT,
  ADD_TO_EXISTING_PARTICIPANTS,
  UPDATE_EXISTING_PARTICIPANT,
  OPEN_EDIT_EMAIL_UI,
  TOGGLE_EMAIL_SETTING,
  CLOSE_EDIT_EMAIL_UI,
  DELETE_PARTICIPANT,
  TOGGLE_INCLUDE_LEGAL_DISCLAIMER,
  TOGGLE_SEND_SIGNATURE_REMINDERS,
  UPDATE_CURRENT_EMAIL_SETTING,
  SWITCH_PARTICIPANT_TYPE,
  DUPLICATE_PARTICIPANT,
  participantTypeEnum,
  OPEN_ADD_GROUP_DETAILS_TAB,
  CLOSE_ADD_GROUP_DETAILS_TAB,
  RELOCATE_PARTICIPANT,
  UNKNOWN_PARTICIPANT,
  KNOWN_LIST,
  SUBMITTER_INDEX,
  ADD_GROUP,
  OPEN_EDIT_EXISTING_GROUP,
  DELETE_GROUP,
  UPDATE_GROUP,
  UPDATE_CURRENT_GROUP,
  MOVE_PARTICIPANT_TO_GROUP,
  MOVE_PARTICIPANT_FROM_GROUP,
  RELOCATE_GROUP,
  SUBMITTER_PARTICIPATION_ORDER,
  ADD_SUBMITTER,
  LOAD_PARTICIPANTS_WITH_CONDITIONALS,
  SAVE_PARTICIPANTS_WITH_CONDITIONALS,
  SET_IS_LABEL_INPUT_IN_FOCUS,
  REMOVE_PARTICIPANT_FROM_GROUP,
} from 'constants/participantsSidebar';
import {
  CurrentParticipant,
  EmailSetting,
  EmailType,
  ParallelGroup,
  Participant,
  ParticipantType,
  ServerParticipantErrors,
  ServerValidationError,
  State,
  Submitter,
} from 'types/participants';
import {
  getEmailTypePropName,
  convertParticipantsForClient,
  getGenericSubmitter,
  getGenericCurrentParticipant,
  getParticipantsWithServerErrors,
  assignSignerKeysByFrontendIds,
  maxParticipationOrder,
  removeOrderGaps,
  getPossibleIdentifiers,
  getGenericGroup,
  duplicateParticipant,
  updateParticipationOrder,
  handleMoveParticipantFromGroup,
  handleMoveParticipant,
  handleMoveGroup,
  convertGroupsForClient,
  handleGroupDelete,
} from 'utils/participants';
import { immutableInsertElementInArray, immutableModifyElementInArray, immutableRemoveElementInArray } from 'utils/reduxHelper';

export const initialState: State = {
  isModalOpen: false,
  formId: '',
  isLoading: false,
  errors: [],
  isAddParticipantTabVisible: false,
  isAddGroupTabVisible: false,
  existingParticipants: [],
  submitter: getGenericSubmitter(),
  includeLegalDisclaimer: true,
  sendSignatureReminders: true,
  hasParticipantsDataChanged: false,
  isEditEmailUiVisible: false,
  emailTypeBeingEdited: null,
  currentParticipant: getGenericCurrentParticipant(),
  currentParticipantInitialState: null,
  hasCurrentParticipantChanged: false,
  indexOfParticipantBeingEdited: null,
  indexOfGroupBeingEdited: null,
  currentEmailSetting: null,
  currentEmailSettingInitialState: null,
  hasCurrentEmailSettingChanged: false,
  groups: [],
  currentGroup: getGenericGroup(),
  currentGroupInitialState: null,
  hasCurrentGroupChanged: false,
  participantsBeforeGroupEdits: null,
  isLabelInputInFocus: false,
};

export const findParticipantIndex = (frontendId: string, state: State) =>
  state.existingParticipants.findIndex(p => p.frontendId === frontendId);

export const findGroupIndex = (frontendId: string, state: State) =>
  state.groups.findIndex(g => g.frontendId === frontendId);

export const findGroupParticipationOrder = (frontendId: string, state: State) =>
  state.groups.find(g => g.frontendId === frontendId)?.participationOrder || -1;

export default function participantsSidebar(state: State = initialState, action: any): State {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
        isAddParticipantTabVisible: false,
        currentParticipant: initialState.currentParticipant,
        isEditEmailUiVisible: false,
        emailTypeBeingEdited: null,
        isAddGroupTabVisible: false,
      };

    case LOAD_PARTICIPANTS:
      return api(action, state, {
        success: (): State => {
          const {
            participants: participantsFromServer,
            groups: groupsFromServer,
            includeLegalDisclaimer,
            sendSignatureReminders,
          } = action.payload;
          let participants: (Participant | Submitter)[];
          let groups: ParallelGroup[];
          try {
            participants = convertParticipantsForClient(participantsFromServer);
            groups = convertGroupsForClient(groupsFromServer);
          } catch (error) {
            console.error(error.message); // eslint-disable-line no-console
            return {
              ...state,
              errors: [error.message],
              isLoading: false,
            };
          }
          if (participants.length === 0) {
            console.log('No participants found. Creating generic submitter.'); // eslint-disable-line no-console
            return {
              ...state,
              isLoading: false,
              errors: [],
              submitter: getGenericSubmitter(),
              existingParticipants: [],
              groups,
              includeLegalDisclaimer,
              sendSignatureReminders,
              hasParticipantsDataChanged: false,
            };
          }
          const submitter: (Submitter | Participant) = participants[0];
          const existingParticipants = participants.slice(1) as Participant[];
          return {
            ...state,
            submitter,
            existingParticipants,
            groups,
            includeLegalDisclaimer,
            sendSignatureReminders,
            isLoading: false,
            errors: [],
            hasParticipantsDataChanged: false,
          };
        },
        pending: (): State => ({...state, isLoading: true}),
        failure: (): State => ({...state, isLoading: false, errors: ['Error fetching participants.']}),
      });

    case LOAD_PARTICIPANTS_WITH_CONDITIONALS:
      return api(action, state, {
        success: (): State => {
          if (action.payload.error) {
            return {
              ...state,
              errors: [action.payload.error.message],
              isLoading: false,
            };
          }
          const {
            clientParticipants,
            clientGroups,
            includeLegalDisclaimer,
            sendSignatureReminders,
          } = action.payload;
          if (clientParticipants.length === 0) {
            console.log('No participants found. Creating generic submitter.'); // eslint-disable-line no-console
            return {
              ...state,
              isLoading: false,
              errors: [],
              submitter: getGenericSubmitter(),
              existingParticipants: [],
              groups: clientGroups,
              includeLegalDisclaimer,
              sendSignatureReminders,
              hasParticipantsDataChanged: false,
            };
          }
          const submitter: (Submitter | Participant) = clientParticipants[0];
          const existingParticipants = clientParticipants.slice(1) as Participant[];
          return {
            ...state,
            submitter,
            existingParticipants,
            groups: clientGroups,
            includeLegalDisclaimer,
            sendSignatureReminders,
            isLoading: false,
            errors: [],
            hasParticipantsDataChanged: false,
          };
        },
        pending: (): State => ({...state, isLoading: true}),
        failure: (): State => ({...state, isLoading: false, errors: ['Error fetching participants.']}),
      });

    case SAVE_PARTICIPANTS:
      return api(action, state, {
        success: () => {
          const [newSubmitter, newParticipants] =
            assignSignerKeysByFrontendIds(state.submitter, state.existingParticipants, action.payload.participants);
          return {
            ...state,
            existingParticipants: newParticipants,
            submitter: newSubmitter,
            isModalOpen: false,
            isLoading: false,
          };
        },
        pending: (): State => ({...state, isLoading: true}),
        failure: (): State => {
          const { generalErrors, participantErrors } = action.payload as {
            generalErrors: ServerValidationError[],
            participantErrors: ServerParticipantErrors
          };

          // If there are no errors to display, show a generic error.
          if (generalErrors.length < 1 && Object.keys(participantErrors).length < 1) {
            return { ...state, isLoading: false, errors: ['Unknown error saving participants.'] };
          }

          const errors = generalErrors.map(err => err.error);

          // If there no participantErrors, we're done.
          if (Object.keys(participantErrors).length < 1) {
            return { ...state, isLoading: false, errors };
          }
          // Otherwise, map them to the participants.
          const { submitterWithServerErrors, existingParticipantsWithServerErrors } = getParticipantsWithServerErrors(
            state.submitter,
            state.existingParticipants,
            participantErrors,
          );
          return {
            ...state,
            isLoading: false,
            errors,
            submitter: submitterWithServerErrors,
            existingParticipants: existingParticipantsWithServerErrors,
          };
        },
      });

    case SAVE_PARTICIPANTS_WITH_CONDITIONALS:
      return api(action, state, {
        success: () => ({
          ...state,
          isModalOpen: false,
          isLoading: false,
        }),
        pending: (): State => ({...state, isLoading: true}),
        failure: (): State => {
          const { generalErrors, participantErrors } = action.payload as {
            generalErrors: ServerValidationError[],
            participantErrors: ServerParticipantErrors
          };

          // If there are no errors to display, show a generic error.
          if (generalErrors.length < 1 && Object.keys(participantErrors).length < 1) {
            return { ...state, isLoading: false, errors: ['Unknown error saving participants.'] };
          }

          const errors = generalErrors.map(err => err.error);

          // If there no participantErrors, we're done.
          if (Object.keys(participantErrors).length < 1) {
            return { ...state, isLoading: false, errors };
          }
          // Otherwise, map them to the participants.
          const { submitterWithServerErrors, existingParticipantsWithServerErrors } = getParticipantsWithServerErrors(
            state.submitter,
            state.existingParticipants,
            participantErrors,
          );
          return {
            ...state,
            isLoading: false,
            errors,
            submitter: submitterWithServerErrors,
            existingParticipants: existingParticipantsWithServerErrors,
          };
        },
      });

    case SET_FORM_ID:
      return {...state, formId: action.formId};

    case OPEN_ADD_PARTICIPANT_TAB:
      const isFirstSigner: boolean =
        !state.existingParticipants.length && state.submitter.participantType === participantTypeEnum.submitter ?
          true : false;
      const participationOrder: number = isFirstSigner ? SUBMITTER_PARTICIPATION_ORDER : Math.max(
        maxParticipationOrder(state.existingParticipants),
        maxParticipationOrder(state.groups)
      ) + 1;
      return {
        ...state,
        isAddParticipantTabVisible: true,
        currentParticipant: {
          ...getGenericCurrentParticipant(),
          participantType: action.participantType,
          participationOrder: action.isMemberOfParallelGroup ? action.groupParticipationOrder : participationOrder,
        },
        currentParticipantInitialState: null,
        hasCurrentParticipantChanged: false,
        indexOfParticipantBeingEdited: null,
      };

    case OPEN_EDIT_EXISTING_PARTICIPANT:
      const participantEditIndex = findParticipantIndex(action.frontendId, state);
      if (isNumber(participantEditIndex) && participantEditIndex >= 0) {
        const participantToEdit = state.existingParticipants[participantEditIndex];
        const participantAsCurrentParticipant = {
          ...getGenericCurrentParticipant(),
          ...participantToEdit,
        };
        return {
          ...state,
          currentParticipant: participantAsCurrentParticipant,
          indexOfParticipantBeingEdited: participantEditIndex,
          isAddParticipantTabVisible: true,
          currentParticipantInitialState: cloneDeep(participantAsCurrentParticipant),
          hasCurrentParticipantChanged: false,
        };
      }
      if (state.submitter.frontendId === action.frontendId) {
        const submitterAsCurrentParticipant = {
          ...getGenericCurrentParticipant(),
          ...state.submitter,
        };
        return {
          ...state,
          currentParticipant: submitterAsCurrentParticipant,
          indexOfParticipantBeingEdited: SUBMITTER_INDEX,
          isAddParticipantTabVisible: true,
          currentParticipantInitialState: cloneDeep(submitterAsCurrentParticipant),
          hasCurrentParticipantChanged: false,
        };
      }
      return state;

    case CLOSE_ADD_PARTICIPANT_TAB:
      return {
        ...state,
        isAddParticipantTabVisible: false,
        currentParticipant: getGenericCurrentParticipant(),
        hasCurrentParticipantChanged: false,
        hasCurrentEmailSettingChanged: false,
      };

    case UPDATE_CURRENT_PARTICIPANT:
      return {
        ...state,
        currentParticipant: action.currentParticipant,
        hasCurrentParticipantChanged: !isEqual(action.currentParticipant, state.currentParticipantInitialState),
      };

    case ADD_TO_EXISTING_PARTICIPANTS:
      return {
        ...state,
        existingParticipants: immutableInsertElementInArray(
          state.existingParticipants,
          state.existingParticipants.length,
          action.newParticipant,
        ),
        isAddParticipantTabVisible: false,
        hasParticipantsDataChanged: true,
        hasCurrentParticipantChanged: false,
        hasCurrentEmailSettingChanged: false,
        hasCurrentGroupChanged: true,
      };

    case ADD_SUBMITTER:
      const newSubmitter: Participant = {
        ...action.newParticipant,
        participationOrder: SUBMITTER_PARTICIPATION_ORDER,
      };
      if (newSubmitter.participantType !== participantTypeEnum.knownParticipant) {
        newSubmitter.identifier = null;
      }
      return {
        ...state,
        submitter: newSubmitter,
        isAddParticipantTabVisible: false,
        hasParticipantsDataChanged: true,
        hasCurrentParticipantChanged: false,
        hasCurrentEmailSettingChanged: false,
      };

    case UPDATE_EXISTING_PARTICIPANT:
      const participantChangeIndex = findParticipantIndex(action.frontendId, state);
      if (isNumber(participantChangeIndex) && participantChangeIndex >= 0) {
        return {
          ...state,
          existingParticipants: immutableModifyElementInArray(
            state.existingParticipants,
            participantChangeIndex,
            action.updatedParticipant
          ),
          isAddParticipantTabVisible: false,
          hasParticipantsDataChanged: true,
          hasCurrentParticipantChanged: false,
          hasCurrentEmailSettingChanged: false,
          hasCurrentGroupChanged: true,
        };
      }
      if (state.submitter.frontendId === action.frontendId) {
        return {
          ...state,
          submitter: action.updatedParticipant,
          isAddParticipantTabVisible: false,
          hasParticipantsDataChanged: true,
          hasCurrentParticipantChanged: false,
          hasCurrentEmailSettingChanged: false,
        };
      }
      return state;

    case REMOVE_PARTICIPANT_FROM_GROUP:
      const participantRemoveIndex = findParticipantIndex(action.frontendId, state);
      if (isNumber(participantRemoveIndex) && participantRemoveIndex >= 0) {
        // The removed participant's new participation order will be different depending on
        // whether the user is creating a new group or editing an existing one.
        // This is because the new group is not included with the rest yet.
        const isEditingNewGroup: boolean = state.isAddGroupTabVisible &&
          !state.groups.some(g => g.frontendId === state.currentGroup.frontendId);

        const newOrder: number = Math.max(
          maxParticipationOrder(state.existingParticipants),
          maxParticipationOrder(state.groups),
          isEditingNewGroup ? state.currentGroup.participationOrder : 0,
        ) + 1;

        const updatedParticipants = immutableModifyElementInArray(
          state.existingParticipants,
          participantRemoveIndex,
          updateParticipationOrder(state.existingParticipants[participantRemoveIndex], newOrder),
        );
        return {
          ...state,
          existingParticipants: updatedParticipants,
          hasParticipantsDataChanged: true,
          hasCurrentGroupChanged: true,
        };
      }
      return state;

    case DELETE_PARTICIPANT:
      const participantDeleteIndex = findParticipantIndex(action.frontendId, state);
      if (isNumber(participantDeleteIndex) && participantDeleteIndex >= 0) {
        const participantsAfterDelete = immutableRemoveElementInArray(
          state.existingParticipants,
          participantDeleteIndex
        );
        if (state.isAddGroupTabVisible) {
          // If the user is editing a group, we can't remove the participation order gaps yet
          // because it may move participants out of the group
          return {
            ...state,
            existingParticipants: participantsAfterDelete,
            hasParticipantsDataChanged: true,
            hasCurrentGroupChanged: true,
          };
        }
        const [updatedParticipants, updatedGroups] = removeOrderGaps(participantsAfterDelete, state.groups);
        return {
          ...state,
          existingParticipants: updatedParticipants,
          groups: updatedGroups,
          hasParticipantsDataChanged: true,
          hasCurrentGroupChanged: true,
        };
      }
      if (state.submitter.frontendId === action.frontendId) {
        return {
          ...state,
          submitter: getGenericSubmitter(),
          hasParticipantsDataChanged: true,
        };
      }
      return state;

    case DUPLICATE_PARTICIPANT:
      const participantDuplicateIndex = findParticipantIndex(action.frontendId, state);
      if (isNumber(participantDuplicateIndex) && participantDuplicateIndex >= 0) {
        const duplicatedCurrentParticipant: CurrentParticipant = {
          ...getGenericCurrentParticipant(),
          ...duplicateParticipant(state.existingParticipants[participantDuplicateIndex]),
          participationOrder: maxParticipationOrder(state.existingParticipants) + 1,
        };
        return {
          ...state,
          currentParticipant: duplicatedCurrentParticipant,
          isAddParticipantTabVisible: true,
          currentParticipantInitialState: cloneDeep(duplicatedCurrentParticipant),
          hasCurrentParticipantChanged: true,
          indexOfParticipantBeingEdited: null,
        };
      }
      if (state.submitter.frontendId === action.frontendId) {
        const duplicatedCurrentParticipant: CurrentParticipant = {
          ...getGenericCurrentParticipant(),
          ...duplicateParticipant(state.submitter as Participant),
          participationOrder: maxParticipationOrder(state.existingParticipants) + 1,
        };
        return {
          ...state,
          currentParticipant: duplicatedCurrentParticipant,
          isAddParticipantTabVisible: true,
          currentParticipantInitialState: cloneDeep(duplicatedCurrentParticipant),
          hasCurrentParticipantChanged: true,
          indexOfParticipantBeingEdited: null,
        };
      }
      return state;

    case TOGGLE_EMAIL_SETTING:
      const emailTypePropName = getEmailTypePropName(action.emailType);
      return {
        ...state,
        currentParticipant: {
          ...state.currentParticipant,
          emailSettings: {
            ...state.currentParticipant.emailSettings,
            [emailTypePropName]: {
              ...state.currentParticipant.emailSettings[emailTypePropName],
              isEnabled: !state.currentParticipant.emailSettings[emailTypePropName].isEnabled,
            },
          },
        },
        hasCurrentParticipantChanged: true,
      };

    case OPEN_EDIT_EMAIL_UI:
      const newCurrentEmailSetting = {
        ...state.currentParticipant.emailSettings[getEmailTypePropName(action.emailType)],
      };
      return {
        ...state,
        emailTypeBeingEdited: action.emailType,
        isEditEmailUiVisible: true,
        currentEmailSetting: newCurrentEmailSetting,
        currentEmailSettingInitialState: cloneDeep(newCurrentEmailSetting),
        hasCurrentEmailSettingChanged: false,
      };

    case UPDATE_CURRENT_EMAIL_SETTING:
      return {
        ...state,
        currentEmailSetting: action.emailSetting,
        hasCurrentEmailSettingChanged: true,
      };

    case CLOSE_EDIT_EMAIL_UI:
      return {
        ...state,
        isAddParticipantTabVisible: true,
        isEditEmailUiVisible: false,
        emailTypeBeingEdited: null,
        hasCurrentEmailSettingChanged: false,
      };

    case TOGGLE_INCLUDE_LEGAL_DISCLAIMER:
      return {
        ...state,
        includeLegalDisclaimer: !state.includeLegalDisclaimer,
        hasParticipantsDataChanged: true,
      };

    case TOGGLE_SEND_SIGNATURE_REMINDERS:
      return {
        ...state,
        sendSignatureReminders: !state.sendSignatureReminders,
        hasParticipantsDataChanged: true,
      };

    case SWITCH_PARTICIPANT_TYPE:
      const switchedParticipant: CurrentParticipant = {
        ...state.currentParticipant,
        participantType: action.participantType,
      };
      return {
        ...state,
        currentParticipant: switchedParticipant,
        hasCurrentParticipantChanged: true,
      };

    case OPEN_ADD_GROUP_DETAILS_TAB:
      return {
        ...state,
        currentGroupInitialState: null,
        hasCurrentGroupChanged: false,
        indexOfGroupBeingEdited: null,
        isAddGroupTabVisible: true,
        currentGroup: {
          ...getGenericGroup(),
          participationOrder: Math.max(
            maxParticipationOrder(state.existingParticipants),
            maxParticipationOrder(state.groups)
          ) + 1,
        },
        participantsBeforeGroupEdits: cloneDeep(state.existingParticipants),
      };

    case CLOSE_ADD_GROUP_DETAILS_TAB:
      if (!state.participantsBeforeGroupEdits) {
        return {
          ...state,
          isAddGroupTabVisible: false,
          participantsBeforeGroupEdits: null,
        };
      }
      return {
        ...state,
        isAddGroupTabVisible: false,
        existingParticipants: state.participantsBeforeGroupEdits,
        participantsBeforeGroupEdits: null,
      };

    case OPEN_EDIT_EXISTING_GROUP:
      const groupEditIndex = findGroupIndex(action.frontendId, state);
      if (isNumber(groupEditIndex) && groupEditIndex >= 0) {
        return {
          ...state,
          currentGroup: { ...state.groups[groupEditIndex] },
          indexOfGroupBeingEdited: groupEditIndex,
          isAddGroupTabVisible: true,
          currentGroupInitialState: cloneDeep(state.groups[groupEditIndex]),
          hasCurrentGroupChanged: false,
          participantsBeforeGroupEdits: cloneDeep(state.existingParticipants),
        };
      }
      return state;

    case UPDATE_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: action.currentGroup,
        hasCurrentGroupChanged: true,
      };

    case ADD_GROUP:
      const [participantsAfterAdd, groupsAfterAdd] = removeOrderGaps(
        state.existingParticipants,
        immutableInsertElementInArray(state.groups, state.groups.length, { ...state.currentGroup }),
      );
      return {
        ...state,
        existingParticipants: participantsAfterAdd,
        groups: groupsAfterAdd,
        hasCurrentGroupChanged: false,
        hasParticipantsDataChanged: true,
        isAddGroupTabVisible: false,
      };

    case UPDATE_GROUP:
      const groupChangeIndex = findGroupIndex(action.frontendId, state);
      if (isNumber(groupChangeIndex) && groupChangeIndex >= 0) {
        const [participantsAfterUpdate, groupsAfterUpdate] = removeOrderGaps(
          state.existingParticipants,
          immutableModifyElementInArray(state.groups, groupChangeIndex, state.currentGroup),
        );
        return {
          ...state,
          existingParticipants: participantsAfterUpdate,
          groups: groupsAfterUpdate,
          hasCurrentEmailSettingChanged: false,
          hasCurrentGroupChanged: false,
          hasParticipantsDataChanged: true,
          isAddGroupTabVisible: false,
        };
      }
      return state;

    case DELETE_GROUP:
      const groupDeleteIndex = findGroupIndex(action.frontendId, state);
      const groupParticipationOrder = findGroupParticipationOrder(action.frontendId, state);
      if (isNumber(groupDeleteIndex) && groupDeleteIndex >= 0) {
        const groupsAfterDelete = immutableRemoveElementInArray(
          state.groups,
          groupDeleteIndex
        );
        const [updatedParticipants, updatedGroups] =
          handleGroupDelete(state.existingParticipants, groupsAfterDelete, groupParticipationOrder);
        return {
          ...state,
          existingParticipants: updatedParticipants,
          groups: updatedGroups,
          hasParticipantsDataChanged: true,
        };
      }
      return state;

    case RELOCATE_PARTICIPANT:
      const participantRelocateIndex = findParticipantIndex(action.frontendId, state);
      if (isNumber(participantRelocateIndex) && participantRelocateIndex >= 0) {
        if (action.newParticipationOrder === SUBMITTER_PARTICIPATION_ORDER) {
          // Moved participant will become the submitter
          const participantAfterMove = updateParticipationOrder(
            state.existingParticipants[participantRelocateIndex],
            action.newParticipationOrder,
          );
          if (participantAfterMove.participantType !== participantTypeEnum.knownParticipant) {
            participantAfterMove.identifier = null;
          }
          const [participantsAfterMove, groupsAfterMove] = removeOrderGaps(
            immutableRemoveElementInArray(state.existingParticipants, participantRelocateIndex),
            state.groups,
          );
          return {
            ...state,
            submitter: participantAfterMove,
            existingParticipants: participantsAfterMove,
            groups: groupsAfterMove,
            hasParticipantsDataChanged: true,
          };
        }
        const [participantsAfterMove, groupsAfterMove] = handleMoveParticipant(
          state.existingParticipants,
          state.groups,
          action.frontendId,
          action.newParticipationOrder,
        );
        return {
          ...state,
          existingParticipants: participantsAfterMove,
          groups: groupsAfterMove,
          hasParticipantsDataChanged: true,
        };
      }
      if (
        action.frontendId === state.submitter.frontendId
        && state.submitter.participantType !== participantTypeEnum.submitter
      ) {
        const movedSubmitter = {
          ...state.submitter,
          participationOrder: action.newParticipationOrder,
        };
        const replacement = getGenericSubmitter();
        if (movedSubmitter.participantType === UNKNOWN_PARTICIPANT || movedSubmitter.participantType === KNOWN_LIST) {
          const possibleIdentifiers = getPossibleIdentifiers(
            replacement,
            state.existingParticipants,
            action.newParticipationOrder,
          );
          const identifier = possibleIdentifiers.find(
            possibleIdentifier => possibleIdentifier.frontendId === movedSubmitter.identifier?.frontendId
          );
          if (!identifier) {
            movedSubmitter.identifier = possibleIdentifiers[possibleIdentifiers.length - 1];
          }
        }
        const otherParticipantsAfterMove = state.existingParticipants.map(p =>
          p.participationOrder >= action.newParticipationOrder
            ? { ...p, participationOrder: p.participationOrder + 1 }
            : p
        );
        const groupsAfterMove = state.groups.map(g =>
          g.participationOrder >= action.newParticipationOrder
            ? { ...g, participationOrder: g.participationOrder + 1 }
            : g
        );
        return {
          ...state,
          submitter: replacement,
          existingParticipants: [...otherParticipantsAfterMove, movedSubmitter],
          groups: groupsAfterMove,
          hasParticipantsDataChanged: true,
        };
      }
      return state;

    case RELOCATE_GROUP:
      const groupRelocateIndex = findGroupIndex(action.frontendId, state);
      if (isNumber(groupRelocateIndex) && groupRelocateIndex >= 0) {
        const [participantsAfterMove, groupsAfterMove] = handleMoveGroup(
          state.existingParticipants,
          state.groups,
          action.frontendId,
          action.newParticipationOrder,
        );
        return {
          ...state,
          existingParticipants: participantsAfterMove,
          groups: groupsAfterMove,
          hasParticipantsDataChanged: true,
        };
      }
      return state;

    case MOVE_PARTICIPANT_TO_GROUP:
      const participantGroupMoveIndex = findParticipantIndex(action.participantFrontendId, state);
      if (isNumber(participantGroupMoveIndex) && participantGroupMoveIndex >= 0) {
        const participantAfterMove: Participant = {
          ...state.existingParticipants[participantGroupMoveIndex],
          participationOrder: action.groupParticipationOrder,
        };
        const participantsAfterMove = immutableModifyElementInArray(
          state.existingParticipants,
          participantGroupMoveIndex,
          participantAfterMove,
        );
        if (state.isAddGroupTabVisible) {
          // We can't remove participation order gaps if the group hasn't been added yet
          // but we will need to remove the gap when the group is actually added
          return {
            ...state,
            existingParticipants: participantsAfterMove,
            hasCurrentGroupChanged: true,
          };
        }
        const [updatedParticipants, updatedGroups] = removeOrderGaps(participantsAfterMove, state.groups);
        return {
          ...state,
          existingParticipants: updatedParticipants,
          groups: updatedGroups,
          hasParticipantsDataChanged: true,
        };
      }
      if (action.participantFrontendId === state.submitter.frontendId) {
        const movedSubmitter: Participant = {
          ...state.submitter as Participant,
          participationOrder: action.groupParticipationOrder,
        };
        return {
          ...state,
          submitter: getGenericSubmitter(),
          existingParticipants: immutableInsertElementInArray(
            state.existingParticipants,
            state.existingParticipants.length,
            movedSubmitter
          ),
          hasParticipantsDataChanged: true,
          hasCurrentGroupChanged: true,
        };
      }
      return state;

    case MOVE_PARTICIPANT_FROM_GROUP:
      const participantMoveIndex = findParticipantIndex(action.frontendId, state);
      if (isNumber(participantMoveIndex) && participantMoveIndex >= 0) {
        if (action.newParticipationOrder === SUBMITTER_PARTICIPATION_ORDER) {
          // Moved participant will become the submitter
          const participantAfterMove = updateParticipationOrder(
            state.existingParticipants[participantMoveIndex],
            action.newParticipationOrder,
          );
          if (participantAfterMove.participantType !== participantTypeEnum.knownParticipant) {
            participantAfterMove.identifier = null;
          }
          const [participantsAfterMove, groupsAfterMove] = removeOrderGaps(
            immutableRemoveElementInArray(state.existingParticipants, participantMoveIndex),
            state.groups,
          );
          return {
            ...state,
            submitter: participantAfterMove,
            existingParticipants: participantsAfterMove,
            groups: groupsAfterMove,
            hasParticipantsDataChanged: true,
          };
        }
        const [participantsAfterMove, groupsAfterMove] = handleMoveParticipantFromGroup(
          state.existingParticipants,
          state.groups,
          action.frontendId,
          action.newParticipationOrder,
        );
        return {
          ...state,
          existingParticipants: participantsAfterMove,
          groups: groupsAfterMove,
          hasParticipantsDataChanged: true,
        };
      }
      return state;
    case SET_IS_LABEL_INPUT_IN_FOCUS:
      return {
        ...state,
        isLabelInputInFocus: action.isLabelInputInFocus,
      };
    default:
      return state;
  }
}

export const getIsModalOpen = (state: State): boolean => state.isModalOpen;
export const getFormId = (state: State): string => state.formId;
export const getErrors = (state: State): string[] => state.errors;
export const getIsAddParticipantTabVisible = (state: State): boolean => state.isAddParticipantTabVisible;
export const getParticipantType = (state: State): ParticipantType | '' => state.currentParticipant.participantType;
export const getCurrentParticipant = (state: State): CurrentParticipant => state.currentParticipant;
export const getExistingParticipants = (state: State): Participant[] =>
  state.existingParticipants;
export const getIsEditEmailUiVisible = (state: State): boolean => state.isEditEmailUiVisible;
export const getEmailTypeBeingEdited = (state: State): EmailType | null => state.emailTypeBeingEdited;
export const getSubmitter = (state: State): Participant | Submitter => state.submitter;
export const getIncludeLegalDisclaimer = (state: State): boolean => state.includeLegalDisclaimer;
export const getSendSignatureReminders = (state: State): boolean => state.sendSignatureReminders;
export const getHasCurrentParticipantChanged = (state: State): boolean => state.hasCurrentParticipantChanged;
export const getIndexOfParticipantBeingEdited = (state: State): number | null => state.indexOfParticipantBeingEdited;
export const getCurrentEmailSetting = (state: State): EmailSetting | null => state.currentEmailSetting;
export const getHasCurrentEmailSettingChanged = (state: State): boolean => state.hasCurrentEmailSettingChanged;
export const getHasParticipantsDataChanged = (state: State): boolean => state.hasParticipantsDataChanged;
export const getAreParticipantsLoading = (state: State): boolean => state.isLoading;
export const getIsAddGroupTabVisible = (state: State): boolean => state.isAddGroupTabVisible;
export const getCurrentGroup = (state: State): ParallelGroup => state.currentGroup;
export const getParallelGroups = (state: State): ParallelGroup[] => state.groups;
export const getHasCurrentGroupChanged = (state: State): boolean => state.hasCurrentGroupChanged;
export const getIndexOfGroupBeingEdited = (state: State): number | null => state.indexOfGroupBeingEdited;
export const getIsLabelInputInFocus = (state: State): boolean => state.isLabelInputInFocus;
