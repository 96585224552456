import api from 'actions/api';
import * as types from '../constants/auth';
import * as authUtils from 'utils/auth';

export const loginInternal = (email: string, password: string, dispatch: Function) =>
  api.postWithoutAuth({
    endpoint: 'login/loginInternal.json.internal',
    baseType: types.LOG_IN,
    data: { email, password },
    success: (state, {
      response: {
        result: { jwt },
      },
    }) => {
      authUtils.handleLogin(dispatch)(null, { idToken: jwt });
    },
    failure: (state, error) => {
      authUtils.handleLogin(dispatch)(error);
    },
  });
