export const safeApplyMethod = (object: Object, methodName: string, ...args: any[]) => {
  const method = object[methodName];
  if (typeof method === 'function') {
    method.apply(object, args);
  }
};

export function safeObjectValues(map: Object) {
  return Object.keys(map).map(key => map[key]);
}

export default {
  safeApplyMethod,
};
