import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import DeviceDetector, { DeviceDetectorResult } from 'device-detector-js';

import { ControlState, UserDataT } from 'types/liveView';

type Props = {
  updateForm: (controlState: ControlState) => void,
  fieldState?: ControlState | null,
  elementType: string,
  specialSettings?: {
    captureIP: boolean,
    captureReferrerURL: boolean,
    captureBrowserType: boolean,
    captureBrowserName: boolean,
    captureBrowserVersion: boolean,
    capturePlatformType: boolean,
    captureOSName: boolean,
    captureOSVersion: boolean,
  }
}

const UserData = ({
  updateForm,
  fieldState,
  elementType,
  specialSettings = {
    captureIP: true,
    captureReferrerURL: true,
    captureBrowserType: true,
    captureBrowserName: true,
    captureBrowserVersion: true,
    capturePlatformType: true,
    captureOSName: true,
    captureOSVersion: true,
  },
}: Props) => {
  const [userAgentData, setUserAgentData] = useState<UserDataT>(fieldState?.fields['User Data'] || {});
  const captureIP: boolean = specialSettings ? specialSettings.captureIP : true;
  const captureReferrerURL: boolean = specialSettings ? specialSettings.captureReferrerURL : true;
  const captureBrowserType: boolean = specialSettings ? specialSettings.captureBrowserType : true;
  const captureBrowserName: boolean = specialSettings ? specialSettings.captureBrowserName : true;
  const captureBrowserVersion: boolean = specialSettings ? specialSettings.captureBrowserVersion : true;
  const capturePlatformType: boolean = specialSettings ? specialSettings.capturePlatformType : true;
  const captureOSName: boolean = specialSettings ? specialSettings.captureOSName : true;
  const captureOSVersion: boolean = specialSettings ? specialSettings.captureOSVersion : true;

  const getUserAgentData = useCallback(() => {
    const deviceDetector: DeviceDetector = new DeviceDetector();
    const userAgent: string = window.navigator.userAgent;
    const device: DeviceDetectorResult = deviceDetector.parse(userAgent);
    const result = {
      referrer: window.document.referrer || 'Unknown Referrer',
      browserType: device.device?.type || 'Unknown Browser Type',
      browserName: device.client?.name || 'Unknown Browser',
      browserVersion: device.client?.version || 'Unknown Browser Version',
      platformType: device.os?.platform || 'Unknown Platform',
      nameOs: device.os?.name || 'Unknown OS',
      versionOs: device.os?.version || 'Unknown OS Version',
    };
    return result;
  }, []);

  const getProperUserData = (): UserDataT => ({
    ...(captureIP && {ip: userAgentData.ip}),
    ...(captureReferrerURL && {referrer: userAgentData.referrer}),
    ...(captureBrowserType && {browserType: userAgentData.browserType}),
    ...(captureBrowserName && {browserName: userAgentData.browserName}),
    ...(captureBrowserVersion && {browserVersion: userAgentData.browserVersion}),
    ...(capturePlatformType && {platformType: userAgentData.platformType}),
    ...(captureOSName && {nameOs: userAgentData.nameOs}),
    ...(captureOSVersion && {versionOs: userAgentData.versionOs}),
  });

  useEffect(() => {
    if (captureIP && !userAgentData.ip) {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => setUserAgentData({...userAgentData, ip: data.ip, ...getUserAgentData()}))
        .catch(() => setUserAgentData({...getUserAgentData()}));
    } else {
      setUserAgentData({...getUserAgentData()});
    }
  }, []);

  useEffect(() => {
    const s = {
      fields: {
        'User Data': getProperUserData(),
      },
      extraData: {
        elementType,
      },
    };
    updateForm && updateForm(s);
  }, [userAgentData]);

  return <div style={{ display: 'none' }}/>;
};

export default React.memo(forwardRef(UserData));
