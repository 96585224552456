import React from 'react';
import classNames from 'classnames';
import formStyle from '../../containers/FormLiveView/FormLiveView.css';
import { LiveViewRow } from 'types/liveView';

interface FormCellProps {
  formElem: any,
  id?: string,
}

export const FormCell = React.memo<FormCellProps>(({ formElem }) => {
  if (!formElem) return null;
  return (<div className={classNames(formStyle.FormCell, 'form_cell')}>{formElem}</div>);
});

interface FormRowProps {
  row: LiveViewRow,
  formElemRenderer: Function,
}

export const FormRow = React.memo<FormRowProps>(({ row, formElemRenderer }) => {
  const cells = row.elements.map(el => (<FormCell key={`cell_${el.id}`} formElem={formElemRenderer(el)} id={el.id} />));
  if (cells.every(c => c.props?.formElem === null)) {
    return null;
  }
  return (<div data-row-id={row.rowId} className={classNames(formStyle.FormRow, 'form_row')}>{cells}</div>);
});

interface FormTitleProps {
  logoUrl: string,
  formName: string
}

export const FormTitle = React.memo<FormTitleProps>(({ logoUrl, formName }) => (
  <header aria-label={formName} className={classNames(formStyle.FormTitle, 'form_title')}>
    <img className={classNames(formStyle.FormTitleLogo, 'form_title_logo')} src={logoUrl} alt='logo' />
    <h1 className={classNames(formStyle.FormTitleText, 'form_title_text')}>{formName}</h1>
  </header>
));

type FormButtonsProps = {
  hideAll: boolean,
  showPrev: boolean,
  showNext: boolean,
  showSubmit: boolean,
  submitText: string,
  disabledText: string,
  disablePrev: boolean,
  disableNext: boolean,
  disableSubmit: boolean,
  nextText: string,
  prevText: string,
  onPrev: () => void,
  onNext: () => void,
  onSubmit: () => void,
}

export const FormButtons = React.memo<FormButtonsProps>(({
  hideAll,
  showPrev,
  showNext,
  showSubmit,
  submitText,
  disabledText,
  disablePrev,
  disableNext,
  disableSubmit,
  nextText,
  prevText,
  onPrev,
  onNext,
  onSubmit,
}) => {
  if (hideAll) return null;

  const onClickPrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onPrev();
  };

  const onClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onNext();
  };

  const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <div className={classNames(formStyle.FormButtonContainer, 'form_button_container')}>
      {showPrev
        ? (
          <button
            key='prev_button'
            title='Previous Page'
            className={classNames(formStyle.FormSubmitButton, 'form_prev_button')}
            type='button'
            aria-label='Previous Page'
            disabled={disablePrev}
            onClick={onClickPrev}>
            {prevText}
          </button>
        )
        : null
      }
      {
        showNext
          ? (
            <button
              key='next_button'
              title='Next Page'
              className={classNames(formStyle.FormSubmitButton, formStyle.FormNextButton, 'form_next_button')}
              type='button'
              aria-label='Next Page'
              disabled={disableNext}
              onClick={onClickNext}>
              {nextText}
            </button>
          )
          : null
      }
      {
        showSubmit
          ? (
            <>
              {disabledText ? <span className={formStyle.FormSubmitLabel}>{disabledText}</span> : null}
              <button
                key='submit_button'
                title='Submit Form'
                className={classNames(formStyle.FormSubmitButton, 'form_submit_button')}
                type='button'
                aria-label='Submit Form'
                disabled={disableSubmit}
                onClick={onClickSubmit}>
                {submitText}
              </button>
            </>
          )
          : null
      }
    </div>
  );
});
