import * as css from 'utils/css';
import breakpoints from 'styles/shared/breakpoints.css';

export function desktop() {
  return css.pxToNumber(breakpoints.desktop);
}

export function tabletPortrait() {
  return css.pxToNumber(breakpoints.tabletPortrait);
}

export function phonePortrait() {
  return css.pxToNumber(breakpoints.phonePortrait);
}
