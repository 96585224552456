export const submissionManagerPermissions = {
  canArchive: 'Archive',
  canEditAssignees: 'ModAssignedUid',
  canEditStages: 'ModStages',
  canEditTags: 'ModTags',
  canExecuteTransactions: 'ExecuteTxns',
  canShareSubmissions: 'ShareSubs',
};

export const bulkActionTypes = {
  Stages: 'StagesBulkActions',
  Assignees: 'AssigneesBulkAction',
  Tags: 'TagsBulkActions',
};

export const bulkActions = {
  Add: 'AddBulkAction',
  Replace: 'ReplaceBulkAction',
  Remove: 'RemoveBulkAction',
  FindAndReplace: 'FindAndReplaceBulkAction',
};

export const selectAllSupportedActions = {
  export: true,
  stages: false,
  tags: false,
  assignees: false,
  share: false,
  archive: false,
  restore: false,
  delete: false,
};

export type SelectAllSupportedActionType = keyof typeof selectAllSupportedActions;
