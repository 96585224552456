import React from 'react';
import TypeaheadInput from 'components/Typeaheads/TypeaheadInput';


const UserTypeaheadInput = props => (
  <TypeaheadInput
    onBlur={props.onBlur}
    onChange={props.onChange}
    onFocus={props.onFocus}
    placeholder={props.placeholder || 'Name or email...'}
    shouldAutoFocus={props.shouldAutoFocus}
    value={props.value} />
);

export const RoleTypeaheadInput = props => {
  const userTypeaheadProps = {
    ...props,
    placeholder: 'Add users or roles...',
  };
  return (
    <UserTypeaheadInput
      {...userTypeaheadProps} />
  );
};

export default UserTypeaheadInput;
