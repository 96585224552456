export const SourceAmountTypes = {
  SET_AMOUNT: 'amount',
  FIELD_VALUE: 'field',
};

export const SourceAmountOptions = [
  { label: 'Set Amount', value: SourceAmountTypes.SET_AMOUNT },
  { label: 'Field Value', value: SourceAmountTypes.FIELD_VALUE },
];

export const PaymentStatus = {
  authorize: 'pre-authorized',
  capture: 'processed',
  purchase: 'processed',
  refund: 'refunded',
  void: 'cancelled',
};

export const DEFAULT_CONVENIENCE_FEE_LABEL = 'Additional Fee';
export const DEFAULT_GOVOS_PAY_CONVENIENCE_FEE_LABEL = 'Pay Service Fee';

export const FeeTypes = {
  AMOUNT: 'amount',
  PERCENTAGE: 'percentage',
  COMBINATION: 'combination',
  GREATER: 'greater',
};

export const FeeOptions = [
  { value: FeeTypes.PERCENTAGE, label: 'Percentage' },
  { value: FeeTypes.AMOUNT, label: 'Set Fee' },
  { value: FeeTypes.COMBINATION, label: 'Percentage + Set Fee' },
  { value: FeeTypes.GREATER, label: 'Percentage or Minimum Fee' },
];

export const PaymentType = {
  CREDIT_CARD: 'credit_card',
  DEBIT_CARD: 'debit_card',
  ACH: 'ach',
};

export const PaymentTypeLabel = {
  [PaymentType.CREDIT_CARD]: 'Credit Card',
  [PaymentType.DEBIT_CARD]: 'Debit Card',
  [PaymentType.ACH]: 'Bank Account (ACH)',
};
