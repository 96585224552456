import React, { useMemo, useEffect, forwardRef, memo } from 'react';
import style from './Receipt.css';
import sharedStyle from '../shared.css';
import Tooltip from 'components/Tooltip';
import { ShowLabelValue } from 'types/liveView';
import { showLabelToBoolean } from 'utils/formLiveView/formLiveView';
import classNames from 'classnames';

export interface ReceiptProps {
  id: string,
  label: string,
  hoverText?: string,
  elementType: string,
  extraData: {
  showLabel: ShowLabelValue,
  }
  specialSettings: {
    prefix: string,
    suffix: string,
    startNumber: string,
    dateFormat: string,
    prefixSeparator: string,
    suffixSeparator: string,
    useDateAsSuffix: boolean,
    thankYouPageMessage: string,
  },
  updateForm?:(s: { fields: { [p: string]: string } }) => void;
}


const Receipt = forwardRef<HTMLDivElement, ReceiptProps>((props, ref) => {
  const {
    id,
    label = 'Receipt',
    elementType,
    extraData: {
      showLabel,
    },
    specialSettings = {
      prefix: '',
      suffix: '',
      dateFormat: '',
      startNumber: '',
      prefixSeparator: '',
      suffixSeparator: '',
      useDateAsSuffix: false,
      thankYouPageMessage: '',
    },
    updateForm,
    hoverText = '',
  } = props;

  const prefix: string = specialSettings?.prefix || 'prefix';
  const suffix: string = specialSettings.suffix || (specialSettings.useDateAsSuffix ? specialSettings.dateFormat : 'suffix');
  const prefixSeparator: string = specialSettings?.prefixSeparator || '|';
  const suffixSeparator: string = specialSettings?.suffixSeparator || '>';

  useEffect(() => {
    if (updateForm) {
      const s = {
        fields: {
          [elementType]: `${prefix}${prefixSeparator}${specialSettings.startNumber}${suffixSeparator}${suffix}`,
        },
      };
      updateForm(s);
    }
  }, []);

  const receiptValue: string = useMemo(() => `${prefix}${prefixSeparator}###########${suffixSeparator}${suffix}`, [prefix, prefixSeparator, suffixSeparator, suffix]);

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <div className={'form_control_group'} id={`${elementType}_${id}`} ref={ref}>
        <fieldset id={id} className={sharedStyle.Fieldset}>
          {showLabelToBoolean(showLabel) && (
            <label
              aria-label={label}
              htmlFor={`${elementType}_${id}`}
              className={classNames(
                sharedStyle.FormLabel,
                'field_label',
              )}
            >
              {label}
            </label>
          )}
          <div className={style.Receipt}>
            <input
              type='text'
              value={receiptValue}
              disabled
              id={id}
              aria-label={`${elementType}`}
              className={style.ReceiptInput}
            />
            <em>You will be provided with a {label} upon submission.</em>
          </div>
        </fieldset>
      </div>
    </Tooltip>
  );
});

export default memo(Receipt);
