import React from 'react';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router';
import { COMPANY_NAME } from 'constants/applicationCopy';
import { LOBBY } from 'constants/route';
import styles from './styles.css';
import brandLogoFull from 'images/brand-logo-full.svg';

interface Props {
  copy: string,
}

const AssetFetchingError = ({ copy }: Props) => (
  <div className={styles.errorLayout}>
    <div className={styles.errorMessageGroup}>
      <Icon className={styles.block}>block</Icon>
      <h1 className={styles.errorCopy}>{copy}</h1>
      <p>For any questions, please contact your admin.</p>
    </div>

    <Link className={styles.lobbyLink} to={LOBBY}>
      <img alt={COMPANY_NAME} src={brandLogoFull} />
    </Link>
  </div>
);

AssetFetchingError.defaultProps = {
  copy: 'You don\'t have access to this file',
};

export default AssetFetchingError;
