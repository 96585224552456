/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
export const VFS_DIR = 'VFS_DIR';
export const VFS_UNKNOWN_NODE = 'VFS_UNKNOWN_NODE';

export type Path = string[];

interface Meta {
  collapsed?: boolean,
  description?: string,
  folderId: string,
  isArchive?: boolean,
  itemCount?: number,
  loading?: boolean,
  subfolderCount?: number,
}

// type FolderData = {
//   [key: string]: Dir,
// };

// type Tree = {
//   data: FolderData,
//   meta: Meta,
//   name: string,
//   type: string,
// };

const metaInitialState = {
  collapsed: false,
  description: null,
  folderId: null,
  itemCount: null,
  loading: false,
  name: '',
  subfolderCount: null,
};

export class Dir {
  name;
  type;
  data;
  meta;
  parent;

  // @ts-ignore
  constructor(name: string, meta: Meta = metaInitialState, type = VFS_DIR, data = {}) {
    this.name = name;
    this.type = type;
    this.data = data;
    this.meta = meta;
    this.parent = null;
  }

  cd(path) {
    return cd(this, path);
  }

  _copy(parent = null) {
    const newNode = new Dir(this.name, this.meta, this.type, this.data);
    for (const attr in this) {
      if (this.hasOwnProperty(attr)) newNode[attr as string] = this[attr];
    }
    if (parent) newNode.parent = parent;
    return newNode;
  }

  copy(parent = null) {
    const newDir = this._copy(parent);
    const sub = this.data;
    const newContent = {};

    for (const key in sub) {
      if (sub.hasOwnProperty(key)) {
        newContent[key] = sub[key].copy(newDir);
      }
    }

    newDir.data = newContent;

    return newDir;
  }

  destroy() {
    if (this.parent && this.parent.data && (this.name in this.parent.data)) {
      delete this.parent.data[this.name];
    }
    delete this.data;
    delete this.meta;
    delete this.parent;
  }

  find(comp) {
    if (comp(this)) {
      return this;
    }
    if (this.type === VFS_DIR) {
      for (const key in this.data) {
        if (this.data.hasOwnProperty(key) && this.data[key] instanceof Dir) {
          const res = this.data[key].find(comp);
          if (res) {
            return res;
          }
        }
      }
    }
    return null;
  }

  getChild(name) {
    return this.data && this.data[name];
  }

  getChildAtPath(path) {
    return cd(this, path);
  }

  isAncestorOf(node, predicate) {
    let parent = node.parent;
    while (parent) {
      if (predicate(this, parent)) {
        return true;
      }
      parent = parent.parent;
    }
    return false;
  }

  mkdir(name) {
    const dir = new Dir(name);
    dir.parent = this;
    return this;
  }

  add(dir, name = null) {
    const newDir = dir.copy(this);
    if (name) {
      newDir.name = name;
    }
    if (this.data[newDir.name]) this.delete(newDir.name);
    this.data[newDir.name] = newDir;

    return this;
  }

  mount(parent, name = null) {
    if (name) {
      this.name = name;
    }
    parent.data[this.name] = this;
    this.parent = parent;
    return this;
  }

  path() {
    return findCurrentPath(this);
  }

  unmount() {
    if (this.parent && this.parent.data) delete this.parent.data[this.name];
    this.parent = null;
    return this;
  }

  delete(node) {
    switch (typeof node) {
      case 'string':
        if (node in this.data) {
          this.data[node].parent = null;
          delete this.data[node];
        }
        break;
      case 'object':
        if (node && node.name && node.name in this.data) {
          node.parent = null;
          delete this.data[node.name];
        }
        break;
      // this cause side effects on its parent, may need to change
      case 'undefined': // delete self
        this.destroy();
        break;
      default:
        return;
    }
  }

  toTree() {
    const tree = {
      name: this.name,
      data: this.data,
      meta: this.meta,
      type: this.type,
    };
    const subTree = {};

    for (const key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        if (this.data[key] instanceof Dir) {
          subTree[key] = this.data[key].toTree();
        } else {
          subTree[key] = this.data[key];
        }
      }
    }

    tree.data = subTree;
    return tree;
  }
}

class UnknownNode extends Dir {
  constructor() {
    // @ts-ignore
    super('', metaInitialState, VFS_UNKNOWN_NODE);
  }
}

function findCurrentPath(node) {
  if (node.parent) {
    return findCurrentPath(node.parent).concat([node.name]);
  }
  return [''];
}

export const findRoot = node => node.parent ? findRoot(node.parent) : node;

function shortCD(node, path) {
  if (node) {
    switch (path) {
      case '..':
        return node.parent || new UnknownNode;
      case '':
      case '.':
        return node;
      case '/':
        return findRoot(node);
      default:
        if (node.type === VFS_DIR) {
          return node.getChild(path);
        }
    }
  }
  return new UnknownNode;
}

const cd = (dir, path) =>
  path.reduce((acc, nextJump) => shortCD(acc, nextJump), dir);

