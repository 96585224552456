import React from 'react';
import styles from './ShowOverflowButton.css';
import { joinClassNames } from 'utils/strings';


export default ({ caption, overflowCount, className }: any) => (
  <React.Fragment>
    <div className={joinClassNames(styles.bubble, className)} aria-label={caption}>
      <span>+{overflowCount}</span>
    </div>
  </React.Fragment>
);
