import React from 'react';
// import {EditorState} from 'draft-js';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InsertLink from '@material-ui/icons/Link';
import ColorPicker from './ColorPicker';

import LinkToNewTab from 'components/LinkToNewTab';
import { ensureAbsoluteURL } from 'utils/richTextEditor/shared';
import { colorMapHex } from 'utils/richTextEditor/colorsForPicker';
import { Inline, Header } from './Inline';
import ImageAlignment from './ImageAlignment';
import { Alignment, Format, Image } from './Block';
import * as inlineStyles from './styles';
import styles from './styles.css';


const Break = () => <span style={{ marginRight: '20px' }} />;


const inputStyle = focused =>
  focused ? inlineStyles.inputFieldFocused : inlineStyles.inputField;

export const linkUrlIconButton = url => (
  url ?
    <LinkToNewTab href={ensureAbsoluteURL(url)}>
      <Tooltip placement='top-start' classes={{ tooltip: styles.linkURLTooltip }} title='Test this link'>
        <IconButton disableRipple style={inlineStyles.linkURLLinkButton}>
          <InsertLink style={inlineStyles.linkURLLinkIconWithURL} />
        </IconButton>
      </Tooltip>
    </LinkToNewTab>
    :
    <InsertLink style={{ ...inlineStyles.linkURLLinkButton, ...inlineStyles.linkURLLinkIconWithoutURL }} />
);

export default class Controls extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      linkInputTextFocused: false,
      linkInputURLFocused: false,
    };
  }

  doFocusOnLinkText = () => (this.refs.linkText as any).focus();
  doFocusOnLinkURL = () => (this.refs.linkURL as any).focus();

  handleLinkTextOnBlur = () => this.setState({ linkInputTextFocused: false });
  handleLinkTextOnFocus = () => this.setState({ linkInputTextFocused: true });
  handleLinkURLOnBlur = () => this.setState({ linkInputURLFocused: false });
  handleLinkURLOnFocus = () => this.setState({ linkInputURLFocused: true });

  handleLinkTextOnChange = event => {
    this.props.onLinkTextChange(event.target.value || null);
  };

  handleLinkURLOnChange = event => {
    this.props.onLinkURLChange(event.target.value || null);
  };

  handleLinkURLOnConfirm = event => {
    event.stopPropagation();
    this.props.onLinkURLConfirm();
  }

  handleFontColorChange = (event, toggledColor) => {
    const colorFromHex = colorMapHex[toggledColor.hex];
    this.props.onFontColorChange(colorFromHex);
  };

  render() {
    const {
      editorState,
      imageFocused,
      isFontColorPickerToggled,
      isLinkToggled,
      linkInputText,
      linkInputURL,
      linkInputVisible,
      onToggleBlockType,
      onToggleImageAlignment,
      onToggleInlineType,
    } = this.props;

    const {
      linkInputTextFocused,
      linkInputURLFocused,
    } = this.state;

    return (
      <div className={styles.controlsWrapper}>
        <div style={inlineStyles.controlsContainer}>
          <Header editorState={editorState} onToggle={onToggleInlineType} />
          <Break />
          <Format editorState={editorState} onToggle={onToggleBlockType} />
          <Break />
          {imageFocused
            ? <ImageAlignment editorState={editorState} onToggle={onToggleImageAlignment} />
            : <Alignment editorState={editorState} onToggle={onToggleBlockType} />}
          <Break />
          <Inline
            editorState={editorState}
            onToggle={onToggleInlineType}
            isLinkToggled={isLinkToggled}
            isFontColorPickerToggled={isFontColorPickerToggled} />
          <Image editorState={editorState} onToggle={onToggleBlockType} />
        </div>
        {linkInputVisible && <div className={styles.linkInputContainer}>
          <input
            ref='linkText'
            onBlur={this.handleLinkTextOnBlur}
            onChange={this.handleLinkTextOnChange}
            onFocus={this.handleLinkTextOnFocus}
            placeholder='Text'
            style={inputStyle(linkInputTextFocused) as any}
            value={linkInputText || ''} />
          <div
            onClick={this.doFocusOnLinkURL}
            style={{ ...inputStyle(linkInputURLFocused), paddingLeft: '36px', paddingRight: '0' } as any}>
            {linkUrlIconButton(linkInputURL)}
            <input
              ref='linkURL'
              onBlur={this.handleLinkURLOnBlur}
              onChange={this.handleLinkURLOnChange}
              onFocus={this.handleLinkURLOnFocus}
              placeholder='URL'
              style={inlineStyles.linkInputField}
              value={linkInputURL || ''} />
            <span className={styles.linkURLDoneTextContainer}>
              <span onClick={this.handleLinkURLOnConfirm} style={inlineStyles.linkURLDoneText}>
                Add
              </span>
            </span>
          </div>
        </div>}
        {isFontColorPickerToggled &&
          <ColorPicker onChangeComplete={this.handleFontColorChange} />
        }
      </div>
    );
  }
}
