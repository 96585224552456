import React, { memo, ReactElement } from 'react';
import classNames from 'classnames';
import styles from './TickerCard.css';

interface TickerCardProps {
  remainingAmount: number;
  tickerType: string;
  fontColor: string;
  tickerColor: string;
  tickerSize: string;
}

export const TickerCard = memo((props: TickerCardProps): ReactElement => {
  const { remainingAmount, tickerType, tickerColor, tickerSize, fontColor } =
    props;
  const getTickerBackGround = (color: string, size: string): string =>
    `https://seamlessdocs.com/components/odoForms/plugins/countdown/flip/${color}/flipbg_${size}.png`;
  const getTickerOverlayImg = (color: string, size: string) =>
    `https://seamlessdocs.com/components/odoForms/plugins/countdown/flip/${color}/flipoverlay_${size}.png`;

  return (
    <div
      className={classNames(
        styles.tickerCard,
        styles[`tickerCard--${tickerSize}`],
      )}
      style={{
        color: `${fontColor}`,
        backgroundImage: `url(${getTickerBackGround(tickerColor, tickerSize)})`,
      }}
    >
      <div className={styles.countdown}>{remainingAmount}</div>
      <span>{tickerType}</span>
      <div
        className={styles.overlay}
        style={{
          backgroundImage: `url(${getTickerOverlayImg(
            tickerColor,
            tickerSize,
          )})`,
        }}
      />
    </div>
  );
});

TickerCard.displayName = 'TickerCard';
