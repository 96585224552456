import { push } from 'react-router-redux';
import * as types from 'constants/types/authActionTypes';
import jwtDecode from 'jwt-decode';
import * as authUtils from 'utils/auth';
import storage from 'utils/storage';
import api from 'actions/api';
import { getAnalyticsData } from 'actions/analytics';
import { setUserTimezone } from '../utils/dateHelper';
import { getProfile, isLoggedIn, setProfile } from 'utils/auth';
import { capitalizeFirstLetter } from 'utils/strings';

// import type { Dispatch } from 'redux';
// import type { Action } from 'types/api';
// import type {
//   Auth0LoginSuccess,
//   AuthorizationCheck,
//   CompleteRegistrationActionCreator,
//   CoregistrationInfoReceived,
//   LoginError,
//   LoginSuccess,
//   LoginUser,
//   OrganizationInfoLoading,
//   Profile,
//   SubdomainExists,
//   ReceiveLogout,
//   RegisterUserActionCreator,
//   RequestLogin,
//   RequestLogout,
//   ResetHeaderSnackbar,
//   UserProfileSuccess,
//   VerifyCoregistrationActionCreator,
// } from 'types/auth';
// import type { ResendInviteActionCreator } from 'types/coregistration';
// import type {
//   UploadNewDocumentFailure,
//   UploadNewDocumentSuccess,
// } from 'types/header';

export function requestLogin() {
  return {
    type: types.LOGIN_REQUEST,
  };
}

export function receiveLogin(idToken: string, profile: any) {
  return {
    type: types.LOGIN_SUCCESS,
    decodedToken: jwtDecode(idToken),
    idToken,
    profile,
  };
}

export function loginError(error) {
  return {
    type: types.LOGIN_FAILURE,
    error,
  };
}

export function auth0LoginSuccess(
  idToken: string,
  profile: any,
) {
  return {
    type: types.AUTH0_LOGIN_SUCCESS,
    idToken,
    profile,
  };
}

export function requestLogout() {
  return {
    type: types.LOGOUT_REQUEST,
  };
}

export function receiveLogout() {
  return {
    type: types.LOGOUT_SUCCESS,
  };
}

export const sendVerifyEmail = (email, maid) =>
  api.postWithoutAuth({
    baseType: types.SEND_VERIFY_EMAIL,
    endpoint: 'users/sendVerifyEmail.json',
    data: {
      email,
      maid,
    },
    success: (_, { response }) => response,
    failure: (s, error) => ({ error }),
  });

export function requestUsernamePasswordLogin(
  email: string,
  password: string,
  subdomain: string,
  redirectUrl: string,
  dispatch: Function,
) {
  return api.postWithoutAuth({
    endpoint: 'login/login.json',
    data: {
      email,
      password,
      subdomain,
    },
    baseType: types.LOGIN_USERNAME_PASSWORD,
    success: (
      state,
      {
        response: {
          result: { jwt, uid, maid },
        },
      }
    ) => {
      authUtils.handleLogin(dispatch)(null, { idToken: jwt, uid, maid, subdomain });

      if (redirectUrl) {
        window.location.href = redirectUrl;
        return {};
      }
      if (!state.routing?.locationBeforeTransitions?.state?.nextPathname?.includes('submissions/viewer')) {
        authUtils.redirectToLandingForVisitors(state.features);
      }

      return {};
    },
    failure: (_, error) => {
      // It's for IE
      const message = error.xhr.responseType === 'json' ?
        error.xhr.response?.message :
        JSON.parse(error.xhr.response)?.message;

      if (message === "The user's email was not verified") {
        dispatch(sendVerifyEmail(email, storage().getItem('maid')));
        dispatch(push('/ng/verify?email=' + encodeURIComponent(email)));
        return {};
      } else if (error.status === 423) {
        dispatch(push('/ng/invitation?email=' + encodeURIComponent(email)));
      }
      authUtils.handleLogin(dispatch)(error);
      return { error };
    },
  });
}

export function subdomainExists(isExists: boolean) {
  return {
    type: types.SUBDOMAIN_EXISTS,
    isExists,
  };
}

export function organizationInfoLoading(isLoading: boolean) {
  return {
    type: types.ORGANIZATION_INFO_LOADING,
    isLoading,
  };
}

export function coregistrationInfoReceived(maid: string, auid: string) {
  return {
    type: types.COREGISTRATION_INFO_RECEIVED,
    maid,
    auid,
  };
}

function setTokensAndReceiveLogin(idToken: string, profile: any) {
  authUtils.setTokenAndProfile(idToken, profile);
  authUtils.setLegacyToken(idToken);
  authUtils.setLegacyAUID(profile.auid);
  return receiveLogin(idToken, profile);
}

export function loginUser(idToken: string, profile: any, accountKey: string) {
  if (
    (accountKey && accountKey.toLowerCase() === profile.subd.toLowerCase()) ||
    process.env.NODE_ENV !== 'production'
  ) {
    return setTokensAndReceiveLogin(idToken, profile);
  }
  return loginError(
    new Error(
      'This account does not exist for this organization. Would you like to lookup your organization?'
    )
  );
}

export function loginInternalUser(idToken: string, profile: any) {
  return setTokensAndReceiveLogin(idToken, profile);
}

export const uploadNewDocumentSuccess = (filepickerResponse: any) => ({
  type: types.UPLOAD_NEW_DOCUMENT_SUCCESS,
  filepickerResponse,
});

export const uploadNewDocumentFailure = (filepickerError: any) => ({
  type: types.UPLOAD_NEW_DOCUMENT_FAILURE,
  filepickerError,
});

export function getOrganizationInfo(subdomain: string, dispatch: Function) {
  const organizationData = window.sessionStorage.getItem('organization');

  if (!!organizationData) {
    dispatch(subdomainExists(true));
    dispatch(organizationInfoLoading(false));

    return {
      type: types.ORGANIZATION_INFO_API,
      status: 'success',
      payload: JSON.parse(organizationData),
    };
  }

  return api.postWithoutAuth({
    endpoint: 'organization.json',
    data: { subdomain },
    baseType: types.ORGANIZATION_INFO_API,
    success: (state, { response }) => {
      dispatch(subdomainExists(true));
      dispatch(organizationInfoLoading(false));
      storage().setItem('maid', response.maid);
      sessionStorage.setItem('organization', JSON.stringify(response));
      return response;
    },
    failure: (state, error) => {
      dispatch(subdomainExists(false));
      dispatch(organizationInfoLoading(false));
      return { error };
    },
  });
}

export const getInternalIdToken = (auth0IdToken: string, subdomain: string, callback: Function) =>
  api.postWithoutAuth({
    endpoint: 'activedirectory/getInternalIdToken.json',
    data: { idToken: auth0IdToken, subdomain },
    baseType: types.GET_INTERNAL_ID_TOKEN,
    success: (state, { response: { idToken } }) => callback(null, idToken),
    failure: (state, error) => {
      callback(error);
      return { error };
    },
  });

export type AccountDomain = {
  maid: string,
  companyName: string,
  subdomain: string,
  userId: string,
}

export const getMasterAccounts = (accountDomains: AccountDomain[]) => ({
  type: types.GET_MASTER_ACCOUNTS,
  payload: { accountDomains },
});

export const getMasterAccountsCached = (profileId: number) => {
  const accountDomainsFromStorage: string | null = sessionStorage.getItem('masteraccount');

  if (accountDomainsFromStorage) {
    return getMasterAccounts(JSON.parse(accountDomainsFromStorage).result);
  }

  return api.postWithAuth({
    endpoint: 'masteraccount/byProfileId.json',
    baseType: types.GET_MASTER_ACCOUNTS_FROM_API,
    data: {profileId},
    success: (state, { response }) => {
      sessionStorage.setItem('masteraccount', JSON.stringify(response));
      return ({accountDomains: response.result as AccountDomain[]});
    },
    failure: (state, error) => ({ error }),
  });
};

export const getUserProfile = (dispatch: Function) => {
  dispatch(getAnalyticsData());
  return api.postWithAuth({
    endpoint: 'userProfile.json',
    baseType: types.USER_PROFILE_API,
    success: (state, { response }) => {
      const role = capitalizeFirstLetter(response.role);
      setProfile({...getProfile(), role });
      if (
        role !== capitalizeFirstLetter(state.auth.profile.role) && // Check if role has changed
        !state.routing?.locationBeforeTransitions?.state?.nextPathname?.includes('submissions/viewer') &&
        !state.routing?.locationBeforeTransitions?.pathname?.includes('submissions/viewer/')
      ) {
        authUtils.redirectToLandingForVisitors(state.features);
      }
      setUserTimezone(response.timezone);
      console.log('ENG-3100 request - ' + response.profileId + ' isLoggedIn ' + isLoggedIn()); //eslint-disable-line
      dispatch(getMasterAccountsCached(response.profileId));
      return response;
    },
    failure: (state, error) => ({ error }),
  });
};


// About Dispatch annotation: getOrganizationInfo returns an API action but loading action creators
// will return normal actions (type: string).
export const getAccountKey = (dispatch: Function) =>
  api.postWithoutAuth({
    endpoint: 'subdomain.json',
    baseType: types.GET_ACCOUNT_KEY,
    data: { whitelabelDomain: window.location.hostname },
    success: (state, { response: { subdomain } }) => {
      const accountKey =
        subdomain == null ? authUtils.getSubdomain() : subdomain;
      if (accountKey) {
        if (window && window.localStorage) {
          window.localStorage.setItem('subdomain', JSON.stringify(accountKey));
        }
        dispatch(getOrganizationInfo(accountKey, dispatch));
      } else {
        dispatch(organizationInfoLoading(false));
      }
      return { accountKey };
    },
    failure: (state, error) => {
      const subdomain = authUtils.getSubdomain();
      if (subdomain) {
        dispatch(getOrganizationInfo(subdomain, dispatch));
      }
      return { error };
    },
  });

export const getActiveDirectoryConnections = (accountKey: string) =>
  api.postWithoutAuth({
    endpoint: 'activedirectory/getConnectionInfo.json',
    baseType: types.GET_ACTIVE_DIRECTORY_CONNECTIONS,
    data: { subdomain: accountKey },
    success: (state, { response: { connections } }) => ({ connections }),
    failure: (state, error) => ({ error }),
  });

export const verifyCoregistrationJWT = token =>
  api.postWithCoregAuth({
    baseType: types.VERIFY_COREGISTRATION,
    endpoint: 'coregistration/completeVerification.json.coreg',
    data: {
      payload: token,
    },
    success: (_, { response }) => response,
    failure: (state, error) => ({ error }),
  });

export const registerUser = (liveViewUrl: string, maid: string, formId: string, user: any, submissionId: string) =>
  api.postWithoutAuth({
    baseType: types.REGISTER_USER,
    endpoint: 'registration/registerVisitor.json',
    data: {
      liveViewUrl,
      maid,
      formId,
      submissionId,
      ...user,
    },
    success: (_, { response }) => response,
    failure: (state, error) => ({ error }),
  });

export const completeRegistration = (jwt: string, uid: string, maid: string) =>
  api.postWithCoregAuth({
    baseType: types.COMPLETE_REGISTRATION,
    endpoint: 'registration/completeRegistration.json.coreg',
    data: {
      credentials: {
        uid,
        maid,
      },
      payload: jwt,
    },
    success: (_, { response }) => response,
    failure: (state, error) => ({ error }),
  });

export const resendRegistrationInvite = data =>
  api.postWithoutAuth({
    baseType: types.RESEND_INVITE,
    endpoint: 'registration/resendInvite.json',
    data,
    success: (_, { response }) => response,
    failure: (state, error) => ({ error }),
  });

export function authorizationCheckSuccess() {
  return {
    type: types.AUTHORIZATION_CHECK_SUCCESS,
  };
}

export function authorizationCheckFailure() {
  return {
    type: types.AUTHORIZATION_CHECK_FAILURE,
  };
}

export function resetHeaderSnackbar() {
  return {
    type: types.RESET_HEADER_SNACKBAR,
  };
}

export const sendResetPasswordEmail = (email: string, maid: string) =>
  api.postWithoutAuth({
    baseType: types.SEND_RESET_PASSWORD,
    endpoint: 'users/resetPassword.json',
    data: {
      email,
      maid,
    },
    success: (_, { response }) => response,
    failure: (s, error) => ({ error }),
  });

export const resetPassword = (password: string, jwtToken: string, dispatch: Function) =>
  api.postWithoutAuth({
    baseType: types.RESET_PASSWORD,
    endpoint: 'users/updatePassword.json',
    data: {
      password,
      jwtToken,
    },
    success: (state, { response }) => {
      const { jwt, uid, maid, subdomain } = response;
      authUtils.handleLogin(dispatch)(null, { idToken: jwt, uid, maid, subdomain });
      authUtils.redirectToLandingForVisitors(state.features);
      return response;
    },
    failure: (_, error) => ({ error }),
  });

export const verifyEmail = (jwtToken: string, dispatch: Function) =>
  api.postWithoutAuth({
    baseType: types.EMAIL_VERIFIED,
    endpoint: 'users/verifyUser.json',
    data: {
      jwtToken,
    },
    success: (state, { response }) => {
      const { jwt, uid, maid, subdomain } = response.result;
      authUtils.handleLogin(dispatch)(null, { idToken: jwt, uid, maid, subdomain });
      authUtils.redirectToLandingForVisitors(state.features);
      return response;
    },
    failure: (_, error) => {
      authUtils.handleLogin(dispatch)(error);
      return { error };
    },
  });

export const verifyAccessCode = (accessCode, maid) =>
  api.postWithoutAuth({
    baseType: types.VERIFY_ACCESS_CODE,
    endpoint: 'submissions/byAccessCode.json',
    data: {
      accessCode,
      maid,
    },
    success: (_, { response }) => (response),
    failure: (_, error) => error,
  });

export const setSubmissionViewerRoute = route => ({
  type: types.SET_SUBMISSION_VIEWER_ROUTE,
  payload: {route},
});

export const visitorSignUp = (maid: string, userDate: any, dispatch: Function) =>
  api.postWithoutAuth({
    baseType: types.SIGN_UP_VISITOR,
    endpoint: 'visitorSignup.json',
    data: {
      maid,
      profile: {
        userData: {
          email: userDate.email,
          timezone: userDate.timeZone,
          firstName: userDate.firstName,
          lastName: userDate.lastName,
          phone: userDate.phoneNumber,
        },
        password: userDate.password,
      },
    },
    success: (_, { response }) => {
      dispatch(push('/ng/verify?email=' + encodeURIComponent(userDate.email)));

      return response;
    },
    failure: (state, error) => ({ error }),
  });
