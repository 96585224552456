import * as types from 'constants/types/lobbyTableActionTypes';
import {ARCHIVE_FORM_API, DELETE_FORM} from 'constants/types/lobby';
import {createTableFromSearchJSON, formatTableDataFromJSON} from 'utils/lobby/tableHelper';
import {LOBBY_FORM_COUNT_REQUEST_SIZE, LOBBY_ARCHIVED_FOLDER, SEARCH_DEBOUNCE_RATE_MS} from 'constants/tableConsts';
import api from 'actions/api';
// import type {Action} from 'types/api';
// import type {
//   LobbyTableDataType,
//   RequestInitialFormLoadAction,
//   RequestSubsequentFormLoadAction,
// } from 'types/lobby/tableTypes';
/**
 * Pagination
 * Take corresponds with the amount of forms that should be returned
 * Drop corresponds with the number of forms to skip before the current page of results
 */

export function newTable(folderId: string) {
  return api.postWithAuth({
    endpoint: 'folderContents.json',
    data: {
      folder: folderId,
      pagination: {
        take: LOBBY_FORM_COUNT_REQUEST_SIZE,
        drop: 0,
      },
    },
    baseType: types.NEW_TABLE_API,
    success: (state: any, {response}: any) => formatTableDataFromJSON(response),
    failure: (state: any, error: any) => ({error}),
  });
}

/**
 * Pagination
 * Take corresponds with the amount of forms that should be returned
 * Drop corresponds with the number of forms to skip before the current page of results
 */
export function updateTable(folderId: string, offset: number) {
  return api.postWithAuth({
    endpoint: 'folderContents.json',
    data: {
      folder: folderId,
      pagination: {
        take: LOBBY_FORM_COUNT_REQUEST_SIZE,
        drop: offset,
      },
    },
    baseType: types.UPDATE_TABLE_API,
    success: (state: any, {response}: any) => formatTableDataFromJSON(response),
    failure: (state: any, error: any) => ({error}),
  });
}

export const archiveFormRequest = (
  selectedRowData: { formId: any; },
  selectedRowIndex: number,
  isUnarchiving: boolean,
) =>
  api.postWithAuth({
    endpoint: 'archiveForm.json',
    baseType: ARCHIVE_FORM_API,
    data: {
      formIds: [selectedRowData.formId],
      isActive: !!isUnarchiving,
    },
    pending: {selectedRowIndex},
    success: () => ({
      isUnarchiving,
      selectedRowData,
    }),
    failure: (state: any, error: any) => ({error}),
  });

export const deleteForm = (formId: string) =>
  api.postWithAuth({
    endpoint: 'forms/delete.json',
    baseType: DELETE_FORM,
    data: {forms: [formId]},
    success: () => ({formId}),
    failure: (_: any, error: any) => ({error}),
  });

export const loadArchivedTable = () =>
  newTable(LOBBY_ARCHIVED_FOLDER);

export const resetTableSnackbar = () => ({
  type: types.RESET_TABLE_SNACKBAR,
});

export const getCanUpdateSettings = (formIds: string[]) =>
  api.postWithAuth({
    endpoint: 'forms/settings/canUpdate.json',
    baseType: types.GET_CAN_UPDATE_SETTINGS,
    data: {formIds},
    success: (state: any, {response: {result: {forms}}}: any) => forms,
    failure: (_: any, error: any) => ({error}),
  });

export const getPerFormCapabilities = (forms: any[]) =>
  api.postWithAuth({
    endpoint: 'forms/mutatingCapabilities.json',
    baseType: types.GET_PER_FORM_CAPABILITIES,
    data: {forms},
    success: (state: any, {response: {result: {capabilitiesByFormId}}}: any) => capabilitiesByFormId,
    failure: (_: any, error: any) => ({error}),
  });

export function requestInitialFormLoad() {
  return {
    type: types.REQUEST_INITIAL_FORM_LOAD,
  };
}

export function requestSubsequentFormLoad() {
  return {
    type: types.REQUEST_SUBSEQUENT_FORM_LOAD,
  };
}

export const searchFormsInitial = (query: string) =>
  api.postWithAuth({
    baseType: types.NEW_TABLE_SEARCH_API,
    endpoint: 'forms/search.json',
    data: {
      searchParameter: query,
      limit: LOBBY_FORM_COUNT_REQUEST_SIZE,
      offset: 0,
    },
    success: (state: any, {response: {result}}: any) => createTableFromSearchJSON(result),
    pending: { isSearching: true },
    failure: (state: any, error: any) => ({error}),
    sample: SEARCH_DEBOUNCE_RATE_MS,
  });

export const searchFormsSubsequent = (drop: number, query: string) =>
  api.postWithAuth({
    baseType: types.UPDATE_TABLE_SEARCH_API,
    endpoint: 'forms/search.json',
    data: {
      searchParameter: query,
      limit: LOBBY_FORM_COUNT_REQUEST_SIZE,
      offset: drop,
    },
    success: (state: any, {response: {result}}: any) => createTableFromSearchJSON(result),
    pending: { isSearching: true },
    failure: (state: any, error: any) => ({error}),
    sample: SEARCH_DEBOUNCE_RATE_MS,
  });
