import React from 'react';
import Stage from 'components/Stages/Stage';
import { connect } from 'react-redux';
import { memoize } from 'lodash';

import Snackbar from '@material-ui/core/Snackbar';
import styles from './SubmissionManagerSnackbar.css';
import arrowIcon from 'icons/stages/snackbarArrow.svg';


export class SubmissionManagerSnackbar extends React.PureComponent<any, any> {
  static defaultProps = { allStages: [] };

  getStageFromName = memoize(
    stageName => this.props.allStages.find(stage => stage.name === stageName),
    stageName => `${stageName || 'none'}-${JSON.stringify(this.props.allStages.find(({ name }) => name === stageName))}`
  );

  render() {
    const { isChangeStageNotification, open, onClose, autoHideDuration, message } = this.props;
    const contentProps = { ContentProps: { classes: { root: styles.rootSnackbarContent } } };
    const snackbarProps = { open, onClose, autoHideDuration, message, ...contentProps };
    if (!isChangeStageNotification) return <Snackbar {...snackbarProps} />;
    const { oldStageName, selectedStageName } = this.props.stages;
    const oldStage = this.getStageFromName(oldStageName);
    const selectedStage = this.getStageFromName(selectedStageName);
    return (
      <Snackbar {...snackbarProps}>
        <div className={styles.container} onClick={e => e.stopPropagation()}>
          <div className={styles.stageWrapper}>
            {oldStage ? <Stage data={oldStage} inSnackbar /> : <p className={styles.noStage}>No Stage</p>}
          </div>
          <img alt='Moved to' src={arrowIcon} className={styles.arrowIcon} />
          <div className={styles.stageWrapper}>
            {selectedStage ? <Stage data={selectedStage} inSnackbar /> : <p className={styles.noStage}>No Stage</p>}
          </div>
        </div>
      </Snackbar>
    );
  }
}

const mapStateToProps = ({ stages, submissionTable: { formId } }) => ({ allStages: stages[formId] });

// we are chicken-egged here, as far as I can tell our react-redux typing for
// connected components with defaultProps is incorrect.  As  of flow 0.85 this produces an error.
// Upgrading to 0.89 and using connect<*, *, *, *, *, *> resolves it but 0.89 has a ton
// of other errors that I would like to defer solving for now.
export default connect(
  mapStateToProps,
  {}
)(SubmissionManagerSnackbar);
