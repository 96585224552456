import React from 'react';
import ShowOverflowButtonBase from 'components/MultiSelector/shared/ShowOverflowButton';
import {joinClassNames} from 'utils/strings';
import styles from '../../RecipientsSelector.css';


const ShowOverflowButton = ({invalid, ...restProps}) => (
  <ShowOverflowButtonBase
    {...restProps}
    className={joinClassNames(
      styles.showOverflowBubble,
      invalid ? styles.invalid : ''
    )}/>
);

export default ShowOverflowButton;
