import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import FormInput from '../FormInput/FormInput';
import FormDropDown from '../FormDropDown/FormDropDown';
import style from './InternationalAddress.css';
import sharedStyle from '../shared.css';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { ControlState, LabelAlignment } from 'types/liveView';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';
import { COUNTRIES } from 'constants/countries';

const countryOptions: { label: string, value: string }[] = Object
  .entries(COUNTRIES)
  .map(([abbrev, state]) => ({ label: state, value: abbrev }));

type RefType = {
  validate: () => boolean,
};

type Props = {
  id: string,
  label?: string,
  fieldState?: ControlState | null,
  updateForm: Function | null,
  cssClass?: string,
  specialSettings?: {
    label?: string,
    labelAlignment: LabelAlignment,
    isAddress1Required: boolean,
    isAddress2Required: boolean,
    isCityRequired: boolean,
    isCountryRequired: boolean,
    isLabelVisible: boolean,
    isPostalRequired: boolean,
    isSPRRequired: boolean,
  },
  required?: boolean,
  hoverText?: string,
};

const InternationalAddress = ({
  id,
  label = 'International Address',
  fieldState,
  updateForm = null,
  cssClass = sharedStyle.FormControlGroup,
  specialSettings = {
    labelAlignment: 'auto',
    isAddress1Required: false,
    isAddress2Required: false,
    isCityRequired: false,
    isCountryRequired: false,
    isLabelVisible: true,
    isPostalRequired: false,
    isSPRRequired: false,
  },
  hoverText = ''}: Props, ref) => {
  const address0Ref: React.MutableRefObject<RefType> = useRef({ validate: () => true });
  const address1Ref: React.MutableRefObject<RefType> = useRef({ validate: () => true });
  const cityRef: React.MutableRefObject<RefType> = useRef({ validate: () => true });
  const stateRef: React.MutableRefObject<RefType> = useRef({ validate: () => true });
  const zipRef: React.MutableRefObject<RefType> = useRef({ validate: () => true });
  const countryRef: React.MutableRefObject<RefType> = useRef({ validate: () => true });
  const selfValidate = (): boolean => {
    const isValid: boolean = address0Ref?.current?.validate() && address1Ref?.current?.validate() &&
      cityRef?.current?.validate() && stateRef?.current?.validate() && zipRef?.current?.validate() &&
      countryRef?.current?.validate();
    return isValid;
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  const onFieldChanged = field => event => {
    const fs = {
      ...fieldState?.fields,
      [field]: event.target.value,
    };
    updateForm && updateForm({ fields: fs });
  };

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <div className={classNames(cssClass, 'form_control_group')} id={`container_${id}`}>
        <fieldset id={id} className={sharedStyle.Fieldset}>
          { label && specialSettings.isLabelVisible &&
            <legend className={getAlignmentClass(specialSettings.labelAlignment, sharedStyle)}>{label}</legend> }
          <FormInput
            ref={address0Ref}
            id={`address0_${id}`}
            label='Address Line 1'
            autoComplete='address-line4'
            required={specialSettings?.isAddress1Required}
            onChange={onFieldChanged('address0')}
            value={fieldState?.fields?.address0 || ''}
          />
          <FormInput
            ref={address1Ref}
            label={'Address Line 2'}
            id={`address1_${id}`}
            autoComplete='address-line3'
            required={specialSettings?.isAddress2Required}
            onChange={onFieldChanged('address1')}
            value={fieldState?.fields?.address1 || ''}
          />
          <div className={style.SubContainer}>
            <div>
              <FormInput
                ref={cityRef}
                id={`city_${id}`}
                label='City'
                autoComplete='address-level2'
                required={specialSettings?.isCityRequired}
                onChange={onFieldChanged('city')}
                value={fieldState?.fields?.city || ''}
              />
            </div>
            <div>
              <FormInput
                ref={stateRef}
                id={`state_${id}`}
                label='State / Province / Region'
                autoComplete='state'
                required={specialSettings?.isSPRRequired}
                onChange={onFieldChanged('state')}
                value={fieldState?.fields?.state || ''}
              />
            </div>
            <div>
              <FormInput
                ref={zipRef}
                id={`zip_${id}`}
                label='Postal Code / Zip'
                autoComplete='postal-code'
                required={specialSettings?.isPostalRequired}
                onChange={onFieldChanged('zip')}
                value={fieldState?.fields?.zip || ''}
              />
            </div>
            <div>
              <FormDropDown
                ref={countryRef}
                id={`country_${id}`}
                options={countryOptions}
                label='Country'
                autoComplete='address-level1'
                required={specialSettings?.isCountryRequired}
                onChange={onFieldChanged('country')}
                value={fieldState?.fields?.country || ''}
              />
            </div>
          </div>
        </fieldset>
      </div>
    </Tooltip>
  );
};

export default React.memo(forwardRef(InternationalAddress));
