// We have to use explicit annotations to force Flow into typing these
// as string literals rather than strings.

export const SELECTOR_TYPE
                                  =
  'I/SD/FORMS/CONDITIONAL_NOTIFY';

export const ADD_RULE
                                    =
  'EMBED_SUBMISSION_RULES_ADD_RULE';
export const ASSIGNEES_UPDATE
                                            =
  'EMBED_SUBMISSION_RULES_ASSIGNEES_UPDATE';
export const ASSOCIATE_FORM_RULES_SELECTOR
                                                         =
  'EMBED_SUBMISSION_RULES_ASSOCIATE_FORM_RULES_SELECTOR';
export const CREATE_FORM_RULES_SELECTOR
                                                      =
  'EMBED_SUBMISSION_RULES_CREATE_FORM_RULES_SELECTOR';
export const UPDATE_EMAIL_BODY
                                             =
  'EMBED_SUBMISSION_RULES_UPDATE_EMAIL_BODY';
export const UPDATE_EMAIL_RECIPIENTS
                                                   =
  'EMBED_SUBMISSION_RULES_UPDATE_EMAIL_RECIPIENTS';
export const UPDATE_EMAIL_SUBJECT
                                                =
  'EMBED_SUBMISSION_RULES_UPDATE_EMAIL_SUBJECT';
export const TOGGLE_EMAIL_SUBMISSION_SETTINGS
                                                            =
  'EMBED_SUBMISSION_RULES_TOGGLE_EMAIL_SUBMISSION_SETTINGS';
export const UPDATE_COREGISTRATION_SETTINGS
                                                    =
  'EMBED_SUBMISSION_UPDATE_COREGISTRATION_SETTINGS';
export const UPDATE_FIELD
                                        =
  'EMBED_SUBMISSION_RULES_UPDATE_FIELD';
export const FORM_RULES_SAVEABLE
                                               =
  'EMBED_SUBMISSION_RULES_FORM_RULES_SAVEABLE';
export const GET_FIELD_DATA
                                          =
  'EMBED_SUBMISSION_RULES_GET_FIELD_DATA';
export const GET_FORM_RULES = 'EMBED_SUBMISSION_RULES_GET_FORM_RULES';
export const GET_STAGES
                                      =
  'EMBED_SUBMISSION_RULES_GET_STAGES';
export const MARK_ATTEMPTED_SAVE
                                               =
  'EMBED_SUBMISSION_RULES_MARK_ATTEMPTED_SAVE';
export const REMOVE_RULE
                                       =
  'EMBED_SUBMISSION_RULES_REMOVE_RULE';
export const UPDATE_RULE_TRIGGERS
                                                =
  'EMBED_SUBMISSION_RULES_UPDATE_RULE_TRIGGERS';
export const SET_CHANGED
                                       =
  'EMBED_SUBMISSION_RULES_SET_CHANGED';
export const SAVE_FORM_RULES
                                           =
  'EMBED_SUBMISSION_RULES_SAVE_FORM_RULES';
export const SET_FORM_ID
                                       =
  'EMBED_SUBMISSION_RULES_SET_FORM_ID';
export const UPDATE_STAGE
                                        =
  'EMBED_SUBMISSION_RULES_UPDATE_STAGE';
export const UPDATE_TAGS
                                       =
  'EMBED_SUBMISSION_RULES_UPDATE_TAGS';
export const TOGGLE_MODAL
                                        =
  'EMBED_SUBMISSION_RULES_TOGGLE_MODAL';
export const TOGGLE_SNACKBAR
                                           =
  'EMBED_SUBMISSION_RULES_TOGGLE_SNACKBAR';
export const TRIGGER_SAVE_FORM_RULES
                                                   =
  'EMBED_SUBMISSION_RULES_TRIGGER_SAVE_FORM_RULES';

export const ALWAYS
 = 'ALWAYS';
