import React, { memo, forwardRef, useState, useCallback, ForwardedRef } from 'react';
import { ControlState, LabelAlignment, ShowLabelValue } from 'types/liveView';
import classNames from 'classnames';
import styles from './CountdownDateTime.css';
import useCountdown from './useCountDown';
import sharedStyle from '../shared.css';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';
import { TickerCard } from './TickerCard/TickerCard';

export interface CountdownDateTimeProps {
  elementType: string;
  excludedFromPreview: boolean;
  extraData: {
    alignment: LabelAlignment;
    label: string;
    showLabel: ShowLabelValue;
    size: string;
  };
  fieldState?: ControlState;
  formula?: any;
  hoverText?: string;
  id: string;
  isDisabled: boolean;
  label: string;
  properties?: any[];
  required: boolean;
  showOtherOption: boolean;
  specialSettings: {
    endDate: string;
    fontColor: string;
    label: string;
    labelAlign: LabelAlignment;
    showYear: boolean;
    tickerColor: string;
    tickerSize: string;
  };
  updateForm?: (s: { fields: { [p: string]: string | null } }) => void;
  validationType?: any;
}

const CountdownDateTime = forwardRef((props: CountdownDateTimeProps, ref: ForwardedRef<HTMLDivElement>) => {
  const [displayDate, setDisplayDate] = useState<boolean>(true);
  const {
    id,
    specialSettings: {
      tickerSize = 'md',
      tickerColor,
      endDate,
      fontColor,
      showYear = true,
      labelAlign = 'auto',
    },
  } = props;

  const countDownToggleHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      event?.preventDefault();
      setDisplayDate(prevState => !prevState);
    },
    [],
  );

  const { years, months, days, hours, minutes, seconds } =
    useCountdown(endDate);

  return (
    <div id={`${id}_countdown`} ref={ref}>
      {displayDate && (
        <div
          className={classNames(
            styles.countdown_container,
            getAlignmentClass(labelAlign, styles),
          )}
          style={{ gap: `${tickerSize === 'sm' ? '.075rem' : '0.25rem'}` }}
        >
          {showYear && (
            <TickerCard
              remainingAmount={years}
              tickerType={'Years'}
              fontColor={fontColor}
              tickerColor={tickerColor}
              tickerSize={tickerSize}
            />
          )}

          <TickerCard
            remainingAmount={months}
            tickerType={'Month'}
            fontColor={fontColor}
            tickerColor={tickerColor}
            tickerSize={tickerSize}
          />
          <TickerCard
            remainingAmount={days}
            tickerType={'Days'}
            fontColor={fontColor}
            tickerColor={tickerColor}
            tickerSize={tickerSize}
          />
          <TickerCard
            remainingAmount={hours}
            tickerType={'Hours'}
            fontColor={fontColor}
            tickerColor={tickerColor}
            tickerSize={tickerSize}
          />
          <TickerCard
            remainingAmount={minutes}
            tickerType={'Minutes'}
            fontColor={fontColor}
            tickerColor={tickerColor}
            tickerSize={tickerSize}
          />
          <TickerCard
            remainingAmount={seconds}
            tickerType={'Seconds'}
            fontColor={fontColor}
            tickerColor={tickerColor}
            tickerSize={tickerSize}
          />
        </div>
      )}
      <div
        style={{ marginTop: '2rem', width: '100%' }}
        className={classNames(getAlignmentClass(labelAlign, sharedStyle))}
      >
        <button
          className={styles.countdown_toggle_button}
          onClick={countDownToggleHandler}
        >
          {displayDate ? 'Hide' : 'Show'}
        </button>
      </div>
    </div>
  );
});

export default memo(CountdownDateTime);
