import React from 'react';
import className from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { ModalData, DropDownValue } from './ProcessPaymentModalConstants';

import { formatPaymentAmount } from 'utils/strings';
import { formatDecimalInput } from 'utils/validation';

import styles from './ProcessPaymentModal.css';


const formatDecimalValue = value => {
  if (value === '') {
    return null;
  }

  return formatDecimalInput(value);
};

class ProcessPaymentModal extends React.PureComponent<any, any> {
  state = {
    dropDownValue: DropDownValue.AMOUNT,
    inputMoneyValue: this.props.totalValue.toFixed(2),
    inputPercentValue: '100',
  };

  getTotalValue = () => {
    if (this.state.dropDownValue === DropDownValue.AMOUNT) {
      return +Number(this.state.inputMoneyValue).toFixed(2);
    }

    if (!this.state.inputPercentValue) {
      return 0;
    }

    return +Number((this.props.totalValue * Number(this.state.inputPercentValue)) / 100).toFixed(2);
  };

  validate = () => {
    const totalValue = this.getTotalValue();

    if (
      this.state.dropDownValue === DropDownValue.PERCENTAGE &&
      this.state.inputPercentValue &&
      Number(this.state.inputPercentValue) > 100
    ) {
      return {
        isValid: false,
        msg: 'Maximum 100%',
      };
    }

    if (totalValue < 1) {
      return {
        isValid: false,
        msg: 'Minimum $1.00',
      };
    }

    if (totalValue > this.props.totalValue) {
      return {
        isValid: false,
        msg: `Maximum ${formatPaymentAmount(this.props.totalValue)}`,
      };
    }

    return {
      isValid: true,
      msg: '',
    };
  };

  getInputField = () => {
    const { isValid, msg } = this.validate();

    if (this.state.dropDownValue === DropDownValue.AMOUNT) {
      return (
        <TextField
          variant='outlined'
          value={this.state.inputMoneyValue === null ? '' : this.state.inputMoneyValue}
          onChange={event => {
            this.setState({
              inputMoneyValue: formatDecimalValue(event.target.value),
            });
          }}
          onBlur={event => {
            this.setState({ inputMoneyValue: formatDecimalValue(Number(event.target.value).toFixed(2)) });
          }}
          error={!isValid}
          helperText={msg}
          fullWidth
          type='number'
          FormHelperTextProps={{
            classes: { error: styles.inputError },
          }}
          InputProps={{
            classes: {
              input: `${styles.valueInput} ${styles.valueInput_money}`,
              root: styles.textField,
            },
            startAdornment: (
              <InputAdornment position='start' classes={{ root: styles.inputAdornment }}>
                $
              </InputAdornment>
            ),
            inputProps: { min: 1, max: this.props.totalValue, step: 0.01 },
          }}
        />
      );
    }

    return (
      <TextField
        variant='outlined'
        value={this.state.inputPercentValue === null ? '' : this.state.inputPercentValue}
        onChange={event => {
          this.setState({
            inputPercentValue: formatDecimalValue(event.target.value),
          });
        }}
        onBlur={event => {
          this.setState({ inputPercentValue: formatDecimalValue(Number(event.target.value).toFixed(2)) });
        }}
        type='number'
        error={!isValid}
        helperText={msg}
        fullWidth
        FormHelperTextProps={{
          classes: { error: styles.inputError },
        }}
        InputProps={{
          classes: {
            input: `${styles.valueInput} ${styles.valueInput_percent}`,
            root: styles.textField,
          },
          endAdornment: (
            <InputAdornment position='end' classes={{ root: styles.inputAdornment }}>
              %
            </InputAdornment>
          ),
          inputProps: { min: 0, max: 100, step: 0.01 },
        }}
      />
    );
  };

  onDropDownChange = event => {
    this.setState({ dropDownValue: event.target.value });
  };

  onSubmit = () => {
    this.props.onSubmit(this.getTotalValue());
  };

  render() {
    const { title, icon, inputLabel, totalLabel, btnText } = ModalData[this.props.type];
    const { isValid } = this.validate();

    const totalValueLabel = formatPaymentAmount(this.props.totalValue);

    return (
      <Dialog
        classes={{ paper: styles.modal }}
        fullScreen={false}
        open
        onClose={this.props.onClose}
        aria-labelledby='process payment dialog'
      >
        <IconButton className={styles.close} aria-label='Close' onClick={this.props.onClose}>
          <CloseIcon fontSize='small' />
        </IconButton>

        <DialogContent>
          <div className={styles.paymentIconContainer}>
            <img alt='payment icon' src={icon} />
          </div>
          <p className={styles.paymentLabel}>{title}</p>
          <div className={styles.formContainer}>
            <div className={styles.formInputs}>
              <div className={styles.labelContainer}>
                <p className={className(styles.formOptionLabel, styles.formOptionLabel_dropdown)}>{inputLabel}</p>
                <p className={className(styles.formOptionLabel, styles.formOptionLabel_totalValue)}>
                  {totalValueLabel}
                </p>
              </div>

              <div className={styles.inputsContainer}>
                <div className={styles.rowOptionContainer}>
                  <Select
                    value={this.state.dropDownValue}
                    onChange={this.onDropDownChange}
                    classes={{ icon: styles.dropDownIcon }}
                    input={<OutlinedInput fullWidth labelWidth={0} classes={{ input: styles.dropDownInput }} />}
                  >
                    <MenuItem value={DropDownValue.AMOUNT}>Amount</MenuItem>
                    <MenuItem value={DropDownValue.PERCENTAGE}>Percentage</MenuItem>
                  </Select>
                </div>
                <div className={styles.inputValueContainer}>{this.getInputField()}</div>
              </div>
            </div>

            <div className={styles.summaryInfoContainer}>
              <span>{totalLabel}</span>
              <span>{formatPaymentAmount(this.getTotalValue())}</span>
            </div>

            <div className={styles.btnContainer}>
              <Button
                variant='contained'
                fullWidth
                classes={{ root: styles.btn }}
                disabled={!isValid}
                onClick={this.onSubmit}
              >
                {btnText}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withMobileDialog()(ProcessPaymentModal);
