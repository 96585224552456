export const SELECT_FOLDER = 'LB_SELECT_FOLDER';
export const EXPAND_FOLDER = 'LB_EXPAND_FOLDER';
export const COLLAPSE_FOLDER = 'LB_COLLAPSE_FOLDER';
export const ARCHIVE_FOLDER = 'LB_ARCHIVE_FOLDER';
export const RESTORE_FOLDER = 'LB_RESTORE_FOLDER';

export const FORM_INTO_FOLDER = 'LB_FORM_INTO_FOLDER';
export const GET_DIR_JSON_API = 'LB_GET_DIR_JSON_API';
export const UPSERT_FOLDER = 'LB_UPSERT_FOLDER';

export const DELETE_FOLDER = 'LB_DELETE_FOLDER';

export const GET_FOLDER_BREADCRUMBS = 'LB_GET_FOLDER_BREADCRUMBS';
