import React from 'react';

import BaseLoader from 'components/Loader';

import styles from './Loader.css';

const Loader = () => (
  <div className={styles.loaderBackground}>
    <div className={styles.loaderBody}>
      <BaseLoader />
    </div>
  </div>
);

export default Loader;
