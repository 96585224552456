import React from 'react';
import * as Colors from 'utils/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialUICheckBox from '@material-ui/core/Checkbox';
import ToggleCheckboxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import ToggleCheckbox from '@material-ui/icons/CheckBox';

const defaultLabelStyle = { height: '32px', marginLeft: '-10px' };
interface Props {
  [x: string]: any;
   disabled?: boolean;
   label?: string;
   labelStyle?: any;
   onCheck?: React.ChangeEventHandler;
   style?: any;
   fill?: string,
 }
const CheckBox = (props: Props) => {
  const {
    disabled,
    label,
    labelStyle,
    onCheck,
    style,
    fill = Colors.orange500,
    ...otherProps
  } = props;
  const checkedIconStyle = disabled ? { fill: Colors.grey600 } : { fill };
  const uncheckedIconStyle = disabled ? { fill: Colors.grey200 } : { fill: Colors.grey600 };
  return (
    <FormControlLabel
      label={label}
      style={{ ...defaultLabelStyle, ...labelStyle }}
      control={
        <MaterialUICheckBox
          disabled={disabled}
          checkedIcon={<ToggleCheckbox style={checkedIconStyle} />}
          icon={<ToggleCheckboxOutlineBlank style={uncheckedIconStyle} />}
          style={style}
          onChange={onCheck}
          {...otherProps} />
      } />
  );
};

CheckBox.defaultProps = {
  disabled: false,
  label: '',
  labelStyle: defaultLabelStyle,
  style: {},
};

export default CheckBox;
