export const onLoad = callback => {
  // @ts-ignore
  const _onload: Function = window.onload;

  window.onload = (...args) => {
    callback();
    if (typeof _onload === 'function') {
      return _onload(...args);
    }
    return null;
  };
};
