import React from 'react';
import styles from './StageSelector.css';
import binIcon from 'icons/stages/bin.svg';

const RemoveButton = ({ onClick, isFocused }) => (
  <div
    className={`${styles.remove} ${isFocused ? styles.focused : ''}`}
    onClick={onClick}
    role='option'
    aria-selected={isFocused}>
    <span className={styles.icon}>
      <img src={binIcon} alt='' />
    </span>
    <span>Remove Stage</span>
  </div>
);

export default RemoveButton;
