import {connect} from 'react-redux';
import {
  decryptModalVisibility,
  editSignerVisibility,
  hideSidebar,
  loaderModalToggle,
  refundVisibility,
  signerDetailsVisibility,
  trackDownloadFile,
  requestSignerUrl,
} from 'actions/submissionsManager/submissionsActions';
import {reNotifyRequest} from 'actions/submissionsManager/tableApi';
import SubmissionManagerSidebar from 'components/SubmissionManagerSidebar';
import {
  getSelectedSubmissionRow,
  getSortedFilteredColumns,
  getIsProcessPaymentPending,
} from '../../reducers';


const mapStateToProps = state => {
  const {
    submissions: {
      editSignerVisible,
      recordDetails,
      refundVisible,
      selectedRowIndex,
      sidebarOpen,
      signerDetailsVisible,
    },
    submissionTable: {
      formDetails,
    },
  } = state;
  return {
    editSignerVisible,
    formDetails,
    isOpen: sidebarOpen,
    recordDetails,
    refundVisible,
    selectedRowIndex,
    signerDetailsVisible,
    sortedFilteredColumns: getSortedFilteredColumns(state),
    submission: getSelectedSubmissionRow(state),
    shouldRenderLoader: getIsProcessPaymentPending(state),
    maid: state.auth.profile && state.auth.profile.maid,
  };
};

const mapDispatchToProps = {
  decryptModalVisibility,
  editSignerVisibility,
  hideSidebar,
  loaderModalToggle,
  refundVisibility,
  renotify: reNotifyRequest,
  signerDetailsVisibility,
  trackDownloadFile,
  requestSignerUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionManagerSidebar);
