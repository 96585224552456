import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as tableApi from 'actions/lobby/tableApiActions';
import * as folderNavigationActions from 'actions/lobby/folderNavigationActions';
import { Cell, Column, Table } from 'fixed-data-table-2';
import { LOBBY_ROOT_FOLDER, TABLE_CELL_WIDTH } from 'constants/tableConsts';
import { columnNamesEnum } from 'constants/formColumns';
import HeaderCell from './HeaderCell';
import Tooltip from 'components/Tooltip';
import FormTypeIcon from './FormTypeIcon';
import SubmissionCell from 'containers/Lobby/SubmissionCell';
import { FOLDER_BLANK_STATE } from 'constants/applicationCopy';
import styles from './FormsTable.css';
import * as dateHelper from 'utils/dateHelper';
import * as breakpoints from 'utils/breakpoints';
import { formCapabilitiesEnum } from 'constants/tableConsts';
import { getFeatureState } from 'reducers/index';
import { getIsNgOrLegacy, getNgOrLegacyHoverText } from 'utils/lobby/tableHelper';
import { getLiveViewUrl } from 'utils/routing';

import emptyFolderImg from 'images/v1-emptyfolder.png';
import paymentIcon from 'icons/paymentIcon.svg';


class FormsTable extends React.Component<any, any> {
  state = { hoverRowIdx: null }

  _onRowClick = (event, index) => {
    this.props.onRowClick(event, index, this.props.tableData[index]);
  };

  _onRowDoubleClick = (event, index) => {
    const { tableData } = this.props;
    const selectedForm = tableData[index];
    const capabilities = selectedForm.capabilities || [];
    const canEditSelectedRow = capabilities.includes(formCapabilitiesEnum.Edit);
    const redirectUrl = getLiveViewUrl(selectedForm, canEditSelectedRow);

    if (redirectUrl) {
      window.open(redirectUrl);
    }
  };

  _onScrollY = position => {
    this.props.onScrollY(position);
    // `fixed-data-table-2` won't update the table's scroll position without this
    return true;
  };

  shouldRenderColumn = columnName => this.props.columns.includes(columnName);

  handleSetFormDetails = index => {
    const { tableData } = this.props;
    this.props.setFormDetails(tableData[index]);
  };

  handleMouseEnter = (event, index) => {
    this.setState({ hoverRowIdx: index });
  }

  handleMouseLeave = () => {
    this.setState({ hoverRowIdx: null });
  }

  handleOnFolderClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, folderId: string) => {
    // If there is no folderId, then we assume it is in the root folder
    this.props.tableApi.newTable(folderId || LOBBY_ROOT_FOLDER);
    // We don't need to fetch the breadcrumbs if it's in the root folder
    folderId && this.props.folderNavigationActions.getFolderBreadcrumbs(folderId);
  }

  render() {
    const {
      width,
      height,
      tableData,
      scrollToRow,
      rowClassNameGetter,
      onContentHeightChange,
      canDragRows,
      canDoubleClickRows,
      showSubmissionCount,
      features,
    } = this.props;

    /**
     * Blank State
     */
    if (tableData.length === 0) {
      return (
        <div className={styles.loaderContainer} style={{ height }}>
          <img className={styles.loaderImage} src={emptyFolderImg} alt='' />
          <div className={styles.loaderCopy}>{FOLDER_BLANK_STATE}</div>
        </div>
      );
    }

    const shouldRenderSubmissionColumn = showSubmissionCount && this.shouldRenderColumn(columnNamesEnum.numSubmissions);
    const shouldRenderLegacyOrNgColumn = features.WEBFORM_NG_LIVE_VIEW
      && this.shouldRenderColumn(columnNamesEnum.legacyOrNg);

    const iconColumnWidth = 54;
    const ngLegacyColumnWidth = shouldRenderLegacyOrNgColumn ? width / 12 : 0;
    const formTitleWidth = (width / 2) - ngLegacyColumnWidth;
    const submissionCountWidth = width / 10;
    const columnWidth = (width < breakpoints.tabletPortrait()) ?
      TABLE_CELL_WIDTH : (width - iconColumnWidth - formTitleWidth - submissionCountWidth - ngLegacyColumnWidth) / 2;

    return (
      <div className='fixedDataTable lobbyTable'>
        <Table
          headerHeight={54}
          height={height}
          onContentHeightChange={onContentHeightChange}
          onRowClick={this._onRowClick}
          onRowDoubleClick={canDoubleClickRows ? this._onRowDoubleClick : null}
          onRowMouseEnter={this.handleMouseEnter}
          onRowMouseLeave={this.handleMouseLeave}
          onVerticalScroll={this._onScrollY}
          ownerHeight={height}
          ref='formsTable'
          rowClassNameGetter={rowClassNameGetter}
          rowHeight={54}
          rowsCount={tableData.length}
          scrollToRow={scrollToRow}
          touchScrollEnabled
          width={width}>
          {this.shouldRenderColumn(columnNamesEnum.itemType) && <Column
            allowCellsRecycling
            cell={props => (
              <FormTypeIcon
                cellClass={styles.tableRow}
                index={props.rowIndex}
                isDraggable={canDragRows}
                selectRow={this._onRowClick}
                rowData={tableData[props.rowIndex]} />
            )}
            width={iconColumnWidth} />}
          {this.shouldRenderColumn(columnNamesEnum.formName) && <Column
            header={<HeaderCell label='Name' />}
            allowCellsRecycling
            cell={props => (
              <Cell className={styles.tableRow}>
                {ReactHtmlParser(tableData[props.rowIndex].formName)}
                {tableData[props.rowIndex].paymentsConfigurationStatus && <img src={paymentIcon} alt='paymentIcon' className={styles.tablePaymentIcon} />}
              </Cell>
            )}
            width={formTitleWidth} />}
          {shouldRenderSubmissionColumn && <Column
            header={<HeaderCell label='Submissions' />}
            allowCellsRecycling
            cell={props => (
              <SubmissionCell
                submissionsCount={tableData[props.rowIndex].numSubmissions === null ?
                  0 : tableData[props.rowIndex].numSubmissions
                }
                isHovered={this.state.hoverRowIdx === props.rowIndex}
                formId={tableData[props.rowIndex].formId}
                setFormDetails={() => this.handleSetFormDetails(props.rowIndex)}
                folderId={tableData[props.rowIndex].folderId} />
            )}
            width={submissionCountWidth} />}
          {this.shouldRenderColumn(columnNamesEnum.folder) && <Column
            header={<HeaderCell label='Folder' />}
            allowCellsRecycling
            cell={props => (
              <Cell
                onClick={e => this.handleOnFolderClick(e, tableData[props.rowIndex].folderId)}
                className={styles.tableRow}>
                {tableData[props.rowIndex].folderName || 'Root'}
              </Cell>
            )}
            width={columnWidth} />}
          {this.shouldRenderColumn(columnNamesEnum.formAuthorName) && <Column
            header={<HeaderCell label='Creator' />}
            allowCellsRecycling
            cell={props => (
              <Cell className={styles.tableRow}>
                {tableData[props.rowIndex].formAuthorName || ''}
              </Cell>
            )}
            width={columnWidth} />}
          {this.shouldRenderColumn(columnNamesEnum.createdTs) && <Column
            header={<HeaderCell label='Date Created' />}
            allowCellsRecycling
            cell={props => (
              <Cell className={styles.tableRow}>
                {tableData[props.rowIndex].createdTs ?
                  dateHelper.getFormattedTime(
                    tableData[props.rowIndex].createdTs, dateHelper.DATE_FORMAT_LONG
                  ) : ''
                }
              </Cell>
            )}
            width={columnWidth} />}
          {shouldRenderLegacyOrNgColumn && <Column
            header={<HeaderCell label='NG/Legacy' />}
            allowCellsRecycling
            cell={props => (
              <Cell className={styles.tableRow}>
                <Tooltip disabled={!getNgOrLegacyHoverText(tableData[props.rowIndex])} title={getNgOrLegacyHoverText(tableData[props.rowIndex]) || ''}>
                  <p>
                    {getIsNgOrLegacy(tableData[props.rowIndex])}
                  </p>
                </Tooltip>
              </Cell>
            )}
            width={ngLegacyColumnWidth} />}
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  features: getFeatureState(state),
});

const mapDispatchToProps = dispatch => ({
  tableApi: bindActionCreators(tableApi, dispatch),
  folderNavigationActions: bindActionCreators(folderNavigationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormsTable);
