import { Operator, ConditionalRuleFieldType, OperatorItem } from 'types/conditionals';

export const fieldTypes = {
  singleLineInput: 'line',
  multiLineInput: 'multiline',
  dropdown: 'dropdown',
  checkbox: 'checkbox',
  radioButton: 'radio',
} as const;

const EQUALS = 'equals';
const CONTAINS = 'contains';
const HAS_A_VALUE = 'has_value';

export const operatorsEnum = {
  equals: EQUALS,
  contains: CONTAINS,
  hasAValue: HAS_A_VALUE,
} as const;

const equalsDropdownItem: OperatorItem = {
  label: 'equals',
  value: operatorsEnum.equals,
};

const containsDropdownItem: OperatorItem = {
  label: 'contains',
  value: operatorsEnum.contains,
};

const hasAValueDropdownItem: OperatorItem = {
  label: 'has a value',
  value: operatorsEnum.hasAValue,
};

export const operatorsDropdownConfig = [
  equalsDropdownItem,
  containsDropdownItem,
  hasAValueDropdownItem,
] as const;

export const whenDropdownConfig: {
  [key in ConditionalRuleFieldType]: {
    operators: OperatorItem[],
    secondaryField: {
      type: 'input' | 'dropdown' | 'multiselect',
      displayWhen: Operator[],
    }
  }
} = {
  [fieldTypes.singleLineInput]: {
    operators: [equalsDropdownItem, containsDropdownItem, hasAValueDropdownItem],
    secondaryField: {
      type: 'input',
      displayWhen: [EQUALS, CONTAINS],
    },
  },
  [fieldTypes.multiLineInput]: {
    operators: [equalsDropdownItem, containsDropdownItem, hasAValueDropdownItem],
    secondaryField: {
      type: 'input',
      displayWhen: [EQUALS, CONTAINS],
    },
  },
  [fieldTypes.dropdown]: {
    operators: [equalsDropdownItem, hasAValueDropdownItem],
    secondaryField: {
      type: 'dropdown',
      displayWhen: [EQUALS],
    },
  },
  [fieldTypes.checkbox]: {
    operators: [equalsDropdownItem, containsDropdownItem, hasAValueDropdownItem],
    secondaryField: {
      type: 'multiselect',
      displayWhen: [EQUALS, CONTAINS],
    },
  },
  [fieldTypes.radioButton]: {
    operators: [equalsDropdownItem, hasAValueDropdownItem],
    secondaryField: {
      type: 'dropdown',
      displayWhen: [EQUALS],
    },
  },
};

export const actionValues = {
  requireSignature: 'require_signature',
  skipSignature: 'skip_signature',
  requireParticipant: 'require_participant',
  skipParticipant: 'skip_participant',
} as const;

export const thenDropdownConfig = [
  // {
  //   label: 'Require signature',
  //   value: actionValues.requireSignature,
  // },
  // {
  //   label: 'Don’t require signature',
  //   value: actionValues.skipSignature,
  // },
  {
    label: 'Require participant',
    value: actionValues.requireParticipant,
  },
  {
    label: 'Skip participant',
    value: actionValues.skipParticipant,
  },
] as const;
