import React from 'react';
import { connect } from 'react-redux';
import StageDot from 'components/Stages/StageDot';
import styles from './Stage.css';


const SelectedStage = ({ stage, isArchiveView }) => (
  <React.Fragment>
    <StageDot
      radiusInPx={7}
      color={isArchiveView ? 0 : stage.color}
      className={styles.dot} />
    <h2 className={styles.selectedName}>{isArchiveView ? 'Archives' : stage.name}</h2>
  </React.Fragment>
);

const mapStateToProps = state => {
  const { submissionTable: { isArchiveView } } = state;
  return {
    isArchiveView,
  };
};

export default connect(
  mapStateToProps,
)(SelectedStage);
