import React, { ChangeEventHandler } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Action from 'components/Modals/Action';
import SmallModal from 'components/Modals/SmallModal';
import Checkbox from 'components/Checkbox';
import Highlighted from 'components/Text/Highlighted';
import * as Colors from 'utils/colors';
import style from '../modalShared.css';
import { Motion, spring } from 'react-motion';
import FormResponseBox from 'components/FormResponseBox';
import { validateEmailAddress } from 'utils/email';


const modalTextStyle = {
  color: Colors.textPrimary,
  fontSize: '14px',
};
const descriptionTextStyle = {
  padding: '2px 0px 14px',
};
const checkboxStyle = {
  paddingBottom: '16px',
};
const exportOptions = [
  { value: 'all', label: 'All Data' },
  { value: 'submissionDetails', label: 'Submission Fields' },
  { value: 'submitter', label: 'Submitter Details' },
  { value: 'preparer', label: 'Preparer Details' },
  { value: 'signatures', label: 'Signature Details' },
  { value: 'stage', label: 'Stage Details' },
  { value: 'tags', label: 'Tags' },
  { value: 'assignees', label: 'Assignees' },
  { value: 'payment', label: 'Payment Details' },
];
const animatedComponents = makeAnimated();

const initialState = {
  withDifferentEmail: false,
  exportToEmail: '',
  isEmailValid: true,
  isDisablingSubmit: false,
  isExportingAllSelectedSubmissions: false,
  selectedExportOptions: [{ value: 'submissionDetails', label: 'Submission Fields' }],
};


export default class extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.downloadOptions = {
      withAttachments: false,
      withFullyRenderedPdfs: false,
      withAccessCode: false,
      withCsvData: true,
      withDifferentEmail: false,
    };
    this.state = initialState;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedFilesCount, totalSubmissions, cachedTotalSubmissions, isAllSelected } = nextProps;

    if (selectedFilesCount === 0 ||
      (isAllSelected && totalSubmissions >= cachedTotalSubmissions) ||
      (cachedTotalSubmissions !== 0 && selectedFilesCount === cachedTotalSubmissions) ||
      (cachedTotalSubmissions === 0 && selectedFilesCount === totalSubmissions)
    ) {
      this.setState({ isExportingAllSelectedSubmissions: true });
    } else {
      this.setState({ isExportingAllSelectedSubmissions: false });
    }
  }

  downloadOptions;

  makeCheckbox = (label, downloadOption) => (
    <Checkbox
      defaultChecked={!!this.downloadOptions[downloadOption]}
      style={{ ...modalTextStyle, ...checkboxStyle }}
      onCheck={this.onCheck(downloadOption) as ChangeEventHandler<Element>}
      fill={Colors.seamlessDocsBlue}
      label={label}
    />
  );

  getExportPayload = () => {
    const { exportToEmail, isExportingAllSelectedSubmissions, selectedExportOptions } = this.state;
    const exportPayload = {
      ...this.downloadOptions,
      // isExportingAll means is exporting all submissions of a form, different from exporting all
      // selected submissions (which could be in search results)
      isExportingAll: isExportingAllSelectedSubmissions,
      recipientEmail: this.downloadOptions.withDifferentEmail ? exportToEmail : '',
    };

    const csvOpts = {
      submissionDetails: false,
      payment: false,
      submitter: false,
      preparer: false,
      signatures: false,
      assignees: false,
      tags: false,
      stage: false,
    };

    if (selectedExportOptions.some(el => el.value === 'all')) {
      exportPayload.exportCsvOptions = {
        submissionDetails: true,
        payment: true,
        submitter: true,
        preparer: true,
        signatures: true,
        assignees: true,
        tags: true,
        stage: true,
      };
    } else {
      selectedExportOptions?.forEach(op => {
        csvOpts[op.value] = true;
      });

      exportPayload.exportCsvOptions = csvOpts;
    }

    return exportPayload;
  };

  onCheck = option => (event, value) => {
    this.downloadOptions[option] = value;
    if (option === 'withDifferentEmail') {
      this.setState(state => ({ withDifferentEmail: !state.withDifferentEmail }));
    } else {
      this.forceUpdate();
    }
  };

  handleEmailChange = event => {
    this.setState({
      exportToEmail: event.target.value,
      isEmailValid: validateEmailAddress(event.target.value),
      isDisablingSubmit: false,
    });
  };

  handleOnOkClick = exportPayload => {
    if (this.state.isEmailValid) {
      this.setState({ ...initialState });
      this.downloadOptions.withDifferentEmail = false;
      this.props.onOkClick(exportPayload);
    } else {
      this.setState({ isDisablingSubmit: true });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
  };

  handleExportAllClick = () => {
    this.setState({ isExportingAllSelectedSubmissions: true });
  };

  handleCancelClick = () => {
    this.setState({
      isExportingAllSelectedSubmissions: false,
      selectedExportOptions: [{ value: 'submissionDetails', label: 'Submission Fields' }],
    });
    this.props.onCancelClick();
  };

  handleKeyPress = event => {
    if (event.charCode === 13) {
      const exportPayload = this.getExportPayload();
      this.handleOnOkClick(exportPayload);
    }
  };

  handleOptionsChange = selectedOptions => {
    if (selectedOptions.some(el => el.value === 'all')) {
      this.setState({ selectedExportOptions: [{ value: 'all', label: 'All Data' }] });
    } else {
      this.setState({selectedExportOptions: selectedOptions});
    }
  };

  render() {
    const {
      open,
      selectedFilesCount,
      totalSubmissions,
      cachedTotalSubmissions,
      submissionFilters,
      totalSubmissionsForFilter,
    } = this.props;
    const { withDifferentEmail, exportToEmail, isExportingAllSelectedSubmissions, selectedExportOptions } = this.state;
    const exportPayload = this.getExportPayload();

    const getTotal = (): number => {
      if (cachedTotalSubmissions === 0) {
        if (submissionFilters.length) {
          return totalSubmissionsForFilter;
        }
        return totalSubmissions;
      }
      return cachedTotalSubmissions;
    };

    const total = getTotal();

    const modalActions = [
      <Action key='cancel' label='Cancel' secondary onClick={this.handleCancelClick} />,
      <Action
        key='export'
        label='Export'
        primaryBlue
        disabled={
          !this.downloadOptions.withAttachments &&
          !this.downloadOptions.withFullyRenderedPdfs &&
          !this.downloadOptions.withCsvData ||
          !this.state.selectedExportOptions?.length
        }
        onClick={() => this.handleOnOkClick(exportPayload)}
      />,
    ];

    return (
      <SmallModal
        title='Export Submissions'
        titleIcon='file_download'
        actions={modalActions}
        onClose={this.handleCancelClick}
        open={open}
      >
        <p style={{ ...modalTextStyle, ...descriptionTextStyle }}>
          You are exporting{' '}
          <Highlighted
            text={isExportingAllSelectedSubmissions ? total : selectedFilesCount}
            animating={isExportingAllSelectedSubmissions}
          />
          {` out of ${total} `}
          {totalSubmissions !== 1 ? 'submissions. ' : 'submission. '}
          {isExportingAllSelectedSubmissions || (
            <span onClick={this.handleExportAllClick} className={style.exportAll}>
              Export All
            </span>
          )}
        </p>
        <div className={style.checkboxColumn}>
          {this.makeCheckbox('CSV Data', 'withCsvData')}
          {this.downloadOptions?.withCsvData &&
            <div className={style.selectWrapper}>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={[exportOptions[0]]}
                options={selectedExportOptions.some(op => op.value === 'all') ? selectedExportOptions : exportOptions}
                value={selectedExportOptions}
                onChange={this.handleOptionsChange}
                maxMenuHeight={150}
                isMulti
              />
            </div>
          }
          {this.makeCheckbox('Include Attachments', 'withAttachments')}
          {this.makeCheckbox('Include PDFs', 'withFullyRenderedPdfs')}
          {this.makeCheckbox('Export to a different email address', 'withDifferentEmail')}
          {this.makeCheckbox('Include submission viewer access code', 'withAccessCode')}
        </div>
        <Motion
          style={{
            height: spring(withDifferentEmail ? 70 : 0, { stiffness: 140, damping: 19 }),
            opacity: spring(withDifferentEmail ? 1 : 0, { stiffness: 100, damping: 19 }),
          }}
        >
          {({ height, opacity }) => (
            <form style={{ height, opacity }} onSubmit={this.handleSubmit}>
              <input
                type='email'
                name='email'
                className={style.formInput}
                onChange={this.handleEmailChange}
                value={exportToEmail}
                placeholder='Email Address'
                onKeyPress={this.handleKeyPress}
              />
              <FormResponseBox
                show={this.state.isDisablingSubmit}
                success={false}
                message='Invalid Email. Please try again.'
              />
            </form>
          )}
        </Motion>
      </SmallModal>
    );
  }
}
