// TODO(jcsnv): Add process refund functionality when the backend endpoint is available:
// https://seamlessdocs.atlassian.net/browse/NG-570
import React from 'react';
import styles from './RefundPaymentModal.css';
import * as Colors from 'utils/colors';
import LargeModal from 'components/Modals/LargeModal';
import Action from 'components/Modals/Action';
import Checkbox from 'components/Checkbox';


export default class RefundPaymentModal extends React.Component<any, any> {
  render() {
    const {
      amount,
      status,
      processor,
      transactionDate,
      transactionType,
      transactionID,
      authCode,
      open,
      onClose,
    } = this.props;

    const modalActions = [
      <Action
        key='cancel'
        label='Cancel'
        secondary
        onClick={onClose} />,
      <Action
        key='refund'
        label='Refund Payment'
        primary
        onClick={onClose} />,
    ];

    const labelStyle = {
      color: Colors.textPrimary,
      fontSize: '14px',
    };

    return (
      <LargeModal
        title='Refund Payment'
        titleIcon='money_off'
        actions={modalActions}
        disableBackdropClick
        onClose={onClose}
        open={open}>

        <div className={styles.modalContainer}>

          <span className={styles.title}>Payment Details</span>

          <div className={styles.detailsContainer}>

            <div className={`${styles.detailWrapperHalf} ${styles.left}`}>
              <div className={`${styles.icon} material-icons`}>attach_money</div>
              <div className={styles.detail}>
                <span className={styles.label}>
                  Amount
                </span>
                <span className={styles.content}>
                  {`$${amount}`}
                </span>
              </div>
            </div>

            <div className={styles.detailWrapperHalf}>
              <div className={`${styles.icon} material-icons`}>flag</div>
              <div className={styles.detail}>
                <span className={styles.label}>
                  Status
                </span>
                <span className={styles.content}>
                  {status}
                </span>
              </div>
            </div>

            <div className={styles.detailWrapper}>
              <div className={`${styles.icon} material-icons`}>payment</div>
              <div className={styles.detail}>
                <span className={styles.label}>
                  Payment Processor
                </span>
                <span className={styles.content}>
                  {processor}
                </span>
              </div>
            </div>

            <div className={styles.detailWrapper}>
              <div className={`${styles.icon} material-icons`}>date_range</div>
              <div className={styles.detail}>
                <span className={styles.label}>
                  Transaction Date
                </span>
                <span className={styles.content}>
                  {transactionDate}
                </span>
              </div>
            </div>

            <div className={styles.detailWrapper}>
              <div className={`${styles.icon} material-icons`}>receipt</div>
              <div className={styles.detail}>
                <span className={styles.label}>
                  Transaction Type
                </span>
                <span className={`${styles.content} ${styles.paddingBottom}`}>
                  {transactionType}
                </span>
                <span className={styles.label}>
                  Transaction ID
                </span>
                <span className={`${styles.content} ${styles.paddingBottom}`}>
                  {transactionID}
                </span>
                <span className={styles.label}>
                  Authorization Code
                </span>
                <span className={styles.content}>
                  {authCode}
                </span>
              </div>
            </div>
          </div>
          <Checkbox label='Send a Notification Email to customer' labelStyle={labelStyle} />
        </div>
      </LargeModal>
    );
  }
}
