import { map, union } from 'lodash';
import { permissionLevelsEnum } from 'constants/permissions';

const convertArrayToLookup = (array: any[]) =>
  array.reduce((accumulator, currentValue) => {
    accumulator[currentValue] = true;
    return accumulator;
  }, {});

export function groupPermissionsByUserId(
  shareFormUsers = [],
  shareSubmissionUsers = [],
  usersWithFormEdit = [],
  usersWithProcessSubmission = [],
) {
  const shareFormUsersLookup = convertArrayToLookup(shareFormUsers);
  const shareSubmissionUsersLookup = convertArrayToLookup(shareSubmissionUsers);
  const editUsersLookup = convertArrayToLookup(usersWithFormEdit);
  const processSubmissionUsersLookup = convertArrayToLookup(usersWithProcessSubmission);

  const uniqueUsers = union(shareSubmissionUsers, shareFormUsers, usersWithFormEdit, usersWithProcessSubmission);

  const permissionsByUserId = uniqueUsers.map(userId => {
    const permissions: string[] = [];

    if (shareFormUsersLookup[userId]) {
      permissions.push(permissionLevelsEnum.ShareForm);
    }
    if (shareSubmissionUsersLookup[userId]) {
      permissions.push(permissionLevelsEnum.ShareAllFormSubmissions);
    }
    if (editUsersLookup[userId]) {
      permissions.push(permissionLevelsEnum.EditForm);
    }
    if (processSubmissionUsersLookup[userId]) {
      permissions.push(permissionLevelsEnum.ProcessSubmissionsForm);
    }

    return {
      userId,
      permissions,
      hasBeenSaved: true,
    };
  });

  return permissionsByUserId;
}

export function groupPermissionsByRole(
  rolesWithFormViewData = [],
  rolesWithFormSubmissionsViewData = [],
  rolesWithFormEdit = [],
  rolesWithProcessSubmission = [],
) {
  const uniqueRoles = union(
    rolesWithFormViewData,
    rolesWithFormSubmissionsViewData,
    rolesWithFormEdit,
    rolesWithProcessSubmission,
  );

  const result = uniqueRoles.map(role => {
    const permissions: string[] = [];

    if (rolesWithFormViewData.includes(role)) {
      permissions.push(permissionLevelsEnum.ShareForm);
    }

    if (rolesWithFormSubmissionsViewData.includes(role)) {
      permissions.push(permissionLevelsEnum.ShareAllFormSubmissions);
    }

    if (rolesWithFormEdit.includes(role)) {
      permissions.push(permissionLevelsEnum.EditForm);
    }

    if (rolesWithProcessSubmission.includes(role)) {
      permissions.push(permissionLevelsEnum.ProcessSubmissionsForm);
    }

    return {
      role,
      permissions,
    };
  });

  return result;
}

export function getUserIdsWithPermissionType(
  permissions: any[],
  permissionType: string,
  authorId: string,
) {
  const filteredPermissions = permissions.filter(
    permission => permission.permissions.includes(permissionType) && permission.userId !== authorId,
  );
  return map(filteredPermissions, 'userId');
}

export function getRolesWithPermissionType(rolePermissions: any[], permissionType: any) {
  const filteredPermissions = rolePermissions.filter(rolePermission =>
    rolePermission.permissions.includes(permissionType),
  );
  return map(filteredPermissions, 'role');
}

function hydratePermissionsWithUsers(permissions: any[], users: any[]) {
  return permissions.map(permission => ({ ...permission, ...users[permission.userId] }));
}

function sortPermissions(hydratedPermissions: any[], authorId: string) {
  const SORT_A_FIRST = -1;
  const SORT_B_FIRST = 1;
  return hydratedPermissions.sort((a, b) => {
    // author always first, then alphabetical
    if (a.userId === authorId) {
      return SORT_A_FIRST;
    }
    if (b.userId === authorId) {
      return SORT_B_FIRST;
    }
    // if fullName is missing, sort by emails
    if (!a.fullName && !b.fullName) {
      return a.email.toLowerCase() < b.email.toLowerCase() ? SORT_A_FIRST : SORT_B_FIRST;
    }
    if (!a.fullName) {
      return SORT_B_FIRST;
    }
    if (!b.fullName) {
      return SORT_A_FIRST;
    }
    return a.fullName?.toLowerCase() < b.fullName?.toLowerCase() ? SORT_A_FIRST : SORT_B_FIRST;
  });
}

export const hydratePermissionsWithUsersAndSort = (
  permissions: any[],
  users: any[],
  authorId: string,
) => {
  const hydratedPermissions = hydratePermissionsWithUsers(permissions, users);
  return sortPermissions(hydratedPermissions, authorId);
};
