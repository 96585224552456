import api from 'actions/api';
import * as types from 'constants/types/submissionsActionTypes';
// import type {Parsed} from 'types/search';
// import type {
//   DecryptModalVisibility,
//   DownloadVisibilityAction,
//   EditSignerVisibilityAction,
//   HideSidebar,
//   LoaderModalToggleAction,
//   RefundVisibilityAction,
//   SearchbarInputAction,
//   SelectRow,
//   SignerDetailsVisibilityAction,
//   TrackDownloadFile,
// } from 'types/submissionsManager/actionTypes';
// import type {
//   FetchSubmissionPdfActionCreator,
// } from 'types/assets';
export const hideSidebar = () => ({
  type: types.HIDE_SIDEBAR,
});

export function scrollToRow(rowIndex: number) {
  return {
    type: types.SCROLL_TO_ROW,
    rowIndex,
  };
}

export function resetScrollToRow() {
  return {
    type: types.RESET_SCROLL_TO_ROW,
  };
}

export function downloadVisibility(downloadVisible: boolean) {
  return {
    type: types.DOWNLOAD_VISIBILITY,
    downloadVisible,
  };
}

export function shareVisibility(shareVisible: boolean) {
  return {
    type: types.SHARE_VISIBILITY,
    shareVisible,
  };
}

export const decryptModalVisibility = (rawValue: string, rowIndex: number, inputName: string) => ({
  type: types.DECRYPT_MODAL_VISIBILITY,
  rawValue,
  rowIndex,
  inputName,
});

export const selectRow = (selectedRowIndex: number) => ({
  type: types.SELECT_ROW,
  selectedRowIndex,
});

export function refundVisibility(refundVisible: boolean) {
  return {
    type: types.REFUND_VISIBILITY,
    refundVisible,
  };
}

export function loaderModalToggle(
  visibility: any,
  status = '',
  title = '',
  body = '',
  actionHandler?: Function) {
  return {
    type: types.LOADER_VISIBILITY,
    visibility,
    status,
    title,
    body,
    actionHandler,
  };
}

export function bulkActionErrorModalToggle(visibility: any) {
  return loaderModalToggle(
    visibility,
    'error',
    'Error Occured',
    'Something occurred while processing your bulk action request. Please try again later.',
  );
}

export function bulkActionProcessingModalToggle(visibility: any) {
  return loaderModalToggle(
    visibility,
    'bulkProcessing',
    'Bulk Processing...',
    'Please wait as this may take a few minutes. Do not refresh the browser.',
  );
}

export function editSignerVisibility(editSignerVisible: boolean) {
  return {
    type: types.EDIT_SIGNER_VISIBILITY,
    editSignerVisible,
  };
}

export function signerDetailsVisibility(signerDetailsVisible: boolean) {
  return {
    type: types.SIGNER_DETAILS_VISIBILITY,
    signerDetailsVisible,
  };
}

/**
 * This action is used to track when a file is downloaded (Instrumentation/Analytics)
 */
export function trackDownloadFile(fileType: string, fileUrl: string) {
  return {
    type: types.TRACK_DOWNLOAD_FILE,
    fileType,
    fileUrl,
  };
}

export function toolbarMobileMenuVisibility(toolbarMobileMenuVisible: boolean) {
  return {
    type: types.TOOLBAR_MOBILE_MENU_VISIBILITY,
    toolbarMobileMenuVisible,
  };
}

export const fetchSubmissionPdfUrl = (submissionId: string) =>
  api.postWithAuth({
    baseType: types.FETCH_ASSET,
    endpoint: 'signedS3url/submissionPDF.json',
    data: {
      submissionId,
    },
    success: (state, {response}) => ({signedUrl: response.result.signedUrl}),
    failure: (state, error) => ({error}),
  });

export function requestSignerUrl(submissionId: string, formId: string, signerKey: string) {
  return api.postWithAuth({
    baseType: types.REQUEST_SIGN_URL,
    endpoint: 'submission/getSignUrl.json',
    data: {
      submissionId,
      signerKey,
      formId,
    },
    success: (_, {response}) => {
      const url = new URL(response.result.signUrl);
      url.protocol = location.protocol;
      url.host = location.host;
      window.open(url.toString(), '_blank');
      return {}; // we don't actually need a reducer for this.
    },
    failure: (_, error) => ({error}),
  });
}

export const shareSubmissionsAction = ({
  subject,
  message,
  formId,
  submissionIds,
  includeAllSubmissions,
  onError,
  onSuccess,
  excludedSubmissionIds = null,
  emails = [] as any[],
}) => api.postWithAuth({
  baseType: types.SHARE_SUBMISSIONS,
  endpoint: 'shareAssignments.json',
  data: {
    recipients: emails.map(e => e.email),
    subject,
    message,
    formId,
    submissionIds,
    includeAllSubmissions,
    excludedSubmissionIds,
  },
  success: () => {
    onSuccess && onSuccess();

    return {
      numOfSelected: submissionIds.length,
      snackbarMessage: 'Shared successfully',
      emails,
    };
  },
  pending: {
    numOfSelected: submissionIds.length,
  },
  failure: () => {
    onError && onError();

    return {
      emails,
    };
  },
});
