import {
  keyBy,
  mapValues,
} from 'lodash';
import { GET_FORM_PUBLIC_INFO } from 'constants/types/formActionTypes';
import {
  NEW_TABLE_API,
  UPDATE_TABLE_API,
} from 'constants/types/lobbyTableActionTypes';

import api from '../api';

const initialState = Object.freeze({});

export default (state: any = initialState, action_) => {
  const action = action_;
  switch (action.type) {
    case GET_FORM_PUBLIC_INFO:
      return api(action, state, {
        success: () => {
          const { payload: { formId, form } } = action;
          return {
            ...state,
            [formId]: {
              formName: form.formName,
            },
          };
        },
      });
    case NEW_TABLE_API:
    case UPDATE_TABLE_API:
      return api(action, state, {
        success: () => {
          const payload = action.payload;
          const formsFromPayload = payload.tableData;
          const keyedById = keyBy(formsFromPayload, 'formId');
          const mergedWithState = mapValues(keyedById, ({ formName }, formId) => {
            if (state[formId]) {
              return {
                ...state[formId],
                formName,
              };
            }
            return { formName };
          });
          return {
            ...state,
            ...mergedWithState,
          };
        },
        pending: () => state,
        failure: () => state,
      });
    default:
      (action);
      return state;
  }
};
