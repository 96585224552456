import React from 'react';
import EmailEditor from './EmailEditor';
import Rule from './Rule';
import styles from './AutomatedProcessBuilder.css';
// import {
//   changeRuleEmailBody,
//   changeRuleEmailRecipients,
//   changeRuleEmailSubject,
//   loadRules,
//   saveRulesToServer,
//   setApbVisibility,
//   setEmailVisibility,
// } from 'actions/automatedProcessBuilder';
import Tooltip from 'components/Tooltip';
import Loader from 'components/Loader';
import ConfirmationModal from 'components/ConfirmationModal';
import { ruleStatusEnum } from 'constants/automatedProcessBuilder';
import { triggerTypeEnum } from 'constants/triggers';
import { getEmailFromRule } from 'utils/automatedProcessBuilder';
import { joinClassNames } from 'utils/strings';
import apbIcon from 'icons/submissions/apbNoRules.svg';
import add from 'icons/add.svg';
// import type {EditorState} from 'draft-js';
// import type {UserCapabilityChecks} from 'types/auth';
// import type {
//   AddEmptyRuleActionCreator,
//   ApbRule,
//   ChangeRuleStatusActionCreator,
//   DeleteRuleActionCreator,
//   Recipients,
// } from 'types/automatedProcessBuilder';
// import type {SyntheticInputEventHandler, FormEntities} from 'types/shared';

// export type PropsFromDispatch = {
//   addEmptyRule: AddEmptyRuleActionCreator,
//   changeRuleEmailBody: typeof changeRuleEmailBody,
//   changeRuleEmailRecipients: typeof changeRuleEmailRecipients,
//   changeRuleEmailSubject: typeof changeRuleEmailSubject,
//   changeRuleStatus: ChangeRuleStatusActionCreator,
//   deleteRule: DeleteRuleActionCreator,
//   loadRules: typeof loadRules,
//   saveRulesToServer: typeof saveRulesToServer,
//   setApbVisibility: typeof setApbVisibility,
//   setEmailVisibility: typeof setEmailVisibility,
// };

// export type PropsFromState = {
//   entities: FormEntities,
//   formId: string,
//   haveUnsavedRules: boolean,
//   isAddEnabled: boolean,
//   isApplyEnabled: boolean,
//   isLoading: boolean,
//   isSaveEnabled: boolean,
//   ruleForEmailEditor: ?ApbRule,
//   rules: ApbRule[],
//   setHaveRulesChanged: (haveChanged: boolean) => void,
//   showConfirmation: (state: boolean) => void,
// };
// type Props = PropsFromState & PropsFromDispatch & UserCapabilityChecks;
// type State = {
//   confirmOpen: boolean,
//   gotBack: boolean,
//   initialEmail: {
//     initialBody: EditorState,
//     initialRecipientResolver: Recipients,
//     initialSubject: string,
//   },
// };

type InitialEmail = {
  initialBody: any,
  initialRecipientResolver: any[],
  initialSubject: string,
  initialAttachedPdf: boolean,
  initialHasLinkToSubmissionManager: boolean
  initialFieldIds: string[],
};

type State = {
  initialEmail: InitialEmail,
  gotBack: boolean,
  confirmOpen: boolean,
};

export default class AutomatedProcessBuilder extends React.PureComponent<any, any> {
  // eslint-disable-next-line react/sort-comp
  __initialEmail = getEmailFromRule(this.props.ruleForEmailEditor);
  state: State = {
    initialEmail: {
      initialBody: this.__initialEmail.body,
      initialRecipientResolver: this.__initialEmail.recipientResolver,
      initialSubject: this.__initialEmail.subject,
      initialAttachedPdf: this.__initialEmail.attachedPdf,
      initialHasLinkToSubmissionManager: this.__initialEmail.hasLinkToSubmissionManager,
      initialFieldIds: this.__initialEmail.fieldIds,
    },
    gotBack: false,
    confirmOpen: false,
  };
  renderEmpty = true;
  componentDidMount() {
    const {formId} = this.props;
    if (formId) {
      this.props.loadRules(formId);
      this.props.getFormFieldsForEmail(formId);
    }
    this.setRenderedRules();
  }

  setRenderedRules() {
    const { setHaveRulesChanged, ruleForEmailEditor } = this.props;
    const isEmailUiVisible = !!ruleForEmailEditor;
    const rulesToRender = this.getRulesToRender();
    if (rulesToRender.length) {
      if (
        rulesToRender.some(rule => !rule.isOnlyEmailModified && (rule.isNew || rule.isModified)) ||
        this.getHasEmailChanged()
      ) {
        setHaveRulesChanged(true);
      } else {
        setHaveRulesChanged(false);
      }
      this.renderEmpty = false;
    } else {
      setHaveRulesChanged(false);
      this.renderEmpty = true;
    }
    if (isEmailUiVisible) {
      this.setState({ gotBack: false });
    }
  }

  componentDidUpdate() {
    this.setRenderedRules();
  }

  getRulesToRender = () => this.props.rules.filter(rule => rule.status !== ruleStatusEnum.ARCHIVED);

  renderEmailEditor = () => {
    const { ruleForEmailEditor, formId, entities: {formFields} } = this.props;
    if (!ruleForEmailEditor) return null;
    const email = getEmailFromRule(ruleForEmailEditor);
    return (
      <div className={styles.apbRulesLayout}>
        <EmailEditor
          email={email}
          formId={formId}
          onBodyChange={this.handleEmailBodyChange}
          onRecipientsChange={this.handleEmailRecipientsChange}
          onSubjectChange={this.handleEmailSubjectChange}
          onAttachedPdfChange={this.handleEmailAttachedPdfChange}
          onIncludeSMLinkChange={this.handleIncludeSMLinkChange}
          onIncludeFieldsChange={this.handleIncludeFieldsChange}
          formFields={formFields}
          setInitialEmail={initialEmailFromEditor => {
            this.setState({ initialEmail: initialEmailFromEditor });
          }} />
      </div>
    );
  };

  renderRules = () => {
    const { changeRuleStatus, deleteRule, formId, entities } = this.props;
    const rulesToRender = this.getRulesToRender();
    return (
      <div className={styles.apbRulesLayout}>
        {rulesToRender.map((rule, index) => (
          <Rule
            entities={entities}
            changeRuleStatus={changeRuleStatus}
            deleteRule={deleteRule}
            formId={formId}
            index={index + 1}
            key={rule.ruleId}
            rule={rule} />
        ))}
      </div>
    );
  };

  renderNoRules = () => (
    <div className={joinClassNames(styles.apbZeroStateAppearance, styles.apbZeroStateLayout)}>
      <div>
        <img src={apbIcon} alt='' />
        <p className={joinClassNames(styles.apbHeadlineAppearance, styles.apbHeadlineLayout)}>No rules exist yet</p>
        <p className={styles.apbSubheadlineAppearance}>Start by creating one below</p>
      </div>
    </div>
  );

  renderBody = () => {
    const { ruleForEmailEditor } = this.props;
    const isEmailUiVisible = !!ruleForEmailEditor;
    const renderRulesFunc = this.renderEmpty ? this.renderNoRules : this.renderRules;
    return (
      <div className={joinClassNames(styles.apbBodyLayout, styles.apbBodyAppearance)}>
        {isEmailUiVisible ? this.renderEmailEditor() : renderRulesFunc()}
      </div>
    );
  };

  renderFooter = () => {
    const isEmailUiVisible = !!this.props.ruleForEmailEditor;

    return (
      <div className={joinClassNames(styles.apbFooterAppearance, styles.apbFooterLayout)}>
        <div>
          {!isEmailUiVisible && (
            <Tooltip
              title='Must finish editing current rule'
              placement='top'
              disabled={this.props.isAddEnabled}>
              <div>
                <button
                  className={styles.apbFooterButtonAppearance}
                  disabled={!this.props.isAddEnabled}
                  onClick={this.handleAddRule}>
                  <img src={add} alt='' />
                  <div
                    className={joinClassNames(styles.apbFooterButtonTextAppearance, styles.apbFooterButtonTextLayout)}>
                    Add Rule
                  </div>
                </button>
              </div>
            </Tooltip>
          )}
        </div>
        <div>
          {isEmailUiVisible ? (
            <button
              className={joinClassNames(styles.cancelButtonAppearance, styles.cancelButtonLayout)}
              onClick={this.handleBack}>
              Back
            </button>
          ) : (<button
            className={joinClassNames(styles.cancelButtonAppearance, styles.cancelButtonLayout)}
            onClick={this.handleCancel}>
            Cancel
          </button>)
          }
          <button
            className={styles.saveButtonAppearance}
            disabled={isEmailUiVisible ? !this.props.isApplyEnabled : !this.props.isSaveEnabled}
            onClick={this.handleSave}>
            {isEmailUiVisible ? 'Apply' : 'Save'}
          </button>
        </div>
      </div>
    );
  };

  getHasEmailChanged = () => {
    const { ruleForEmailEditor } = this.props;
    const { recipientResolver,
      subject,
      attachedPdf,
      hasLinkToSubmissionManager,
    } = getEmailFromRule(ruleForEmailEditor);
    const { initialRecipientResolver,
      initialSubject,
      initialAttachedPdf,
      initialHasLinkToSubmissionManager,
    } = this.state.initialEmail;

    return !this.state.gotBack && (initialRecipientResolver !== recipientResolver ||
        initialSubject !== subject ||
        initialAttachedPdf !== attachedPdf || initialHasLinkToSubmissionManager !== hasLinkToSubmissionManager);
  };

  handleEmailBodyChange = editorState => {
    const { formId, ruleForEmailEditor } = this.props;
    if (ruleForEmailEditor) {
      this.props.changeRuleEmailBody(formId, ruleForEmailEditor.ruleId, triggerTypeEnum.THEN, { body: editorState });
    }
  };

  handleEmailRecipientsChange = recipientResolver => {
    const { formId, ruleForEmailEditor } = this.props;
    if (ruleForEmailEditor) {
      this.props.changeRuleEmailRecipients(formId, ruleForEmailEditor.ruleId, triggerTypeEnum.THEN, {
        recipientResolver,
      });
    }
  };

  handleEmailSubjectChange = event => {
    const { formId, ruleForEmailEditor } = this.props;
    if (ruleForEmailEditor) {
      this.props.changeRuleEmailSubject(formId, ruleForEmailEditor.ruleId, triggerTypeEnum.THEN, {
        subject: event.target.value,
      });
    }
  };

  handleEmailAttachedPdfChange = (event, isChecked) => {
    const { formId, ruleForEmailEditor } = this.props;
    if (ruleForEmailEditor) {
      this.props.changeRuleEmailAttachedPdf(formId, ruleForEmailEditor.ruleId, triggerTypeEnum.THEN, {
        attachedPdf: isChecked,
      });
    }
  };

  handleIncludeSMLinkChange = (event, isChecked) => {
    const { formId, ruleForEmailEditor } = this.props;
    if (ruleForEmailEditor) {
      this.props.changeRuleEmailIncludeSMLink(formId, ruleForEmailEditor.ruleId, triggerTypeEnum.THEN, {
        hasLinkToSubmissionManager: isChecked,
      });
    }
  }

  handleIncludeFieldsChange = fields => {
    const { formId, ruleForEmailEditor } = this.props;
    if (ruleForEmailEditor) {
      this.props.changeRuleEmailIncludeFields(formId, ruleForEmailEditor.ruleId, triggerTypeEnum.THEN, {
        fieldIds: fields.map(f => f.id),
      });
    }
  }

  handleCancel = () => {
    const isEmailUiVisible = !!this.props.ruleForEmailEditor;
    if (this.props.haveUnsavedRules) {
      this.props.showConfirmation(true);
      return;
    }

    isEmailUiVisible ? this.props.setEmailVisibility(null) : this.props.setApbVisibility(false);
  };

  handleBack = () => {
    const {
      initialBody,
      initialRecipientResolver,
      initialSubject,
      initialAttachedPdf,
      initialHasLinkToSubmissionManager,
      initialFieldIds,
    } = this.state.initialEmail;
    const { formId, ruleForEmailEditor } = this.props;

    if (!this.state.confirmOpen && ruleForEmailEditor && ruleForEmailEditor.isOnlyEmailModified) {
      this.setState({ confirmOpen: true });
      return;
    }

    this.setState({ gotBack: true });

    this.handleEmailBodyChange(initialBody);
    if (ruleForEmailEditor) {
      this.props.changeRuleEmailRecipients(formId, ruleForEmailEditor.ruleId, triggerTypeEnum.THEN, {
        recipientResolver: initialRecipientResolver,
      });
      this.props.changeRuleEmailSubject(formId, ruleForEmailEditor.ruleId, triggerTypeEnum.THEN, {
        subject: initialSubject,
      });
      this.props.changeRuleEmailAttachedPdf(formId, ruleForEmailEditor.ruleId, triggerTypeEnum.THEN, {
        attachedPdf: initialAttachedPdf,
      });
      this.props.changeRuleEmailIncludeSMLink(formId, ruleForEmailEditor.ruleId, triggerTypeEnum.THEN, {
        hasLinkToSubmissionManager: initialHasLinkToSubmissionManager,
      });
      this.props.changeRuleEmailIncludeFields(formId, ruleForEmailEditor.ruleId, triggerTypeEnum.THEN, {
        fieldIds: initialFieldIds,
      });
    }
    this.props.setEmailVisibility(null);
  };

  handleAddRule = () => {
    const { addEmptyRule, formId } = this.props;
    addEmptyRule(formId);
  };

  handleSave = () => {
    const { formId, rules } = this.props;
    const isEmailUiVisible = !!this.props.ruleForEmailEditor;

    isEmailUiVisible ? this.props.setEmailVisibility(null) : this.props.saveRulesToServer(formId, rules);
  };

  render() {
    if (this.props.isLoading) return <Loader />;
    return (
      <div className={styles.apbContainerLayout}>
        {this.renderBody()}
        {this.renderFooter()}
        {this.state.confirmOpen && <ConfirmationModal
          title='Cancel Changes'
          description='You have unsaved changes. Do you want to proceed?'
          disagreeText='No'
          agreeText='Yes'
          onCancel={() => this.setState({ confirmOpen: false })}
          onApprove={() => {
            this.handleBack();
            this.setState({ confirmOpen: false });
          }} />}
      </div>
    );
  }
}
