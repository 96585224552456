import { participantTypeEnum, emailTypeEnum, OPEN_ADD_PARTICIPANT_TAB, OPEN_EDIT_EMAIL_UI, TRACK_EMAIL_SETTINGS, SWITCH_PARTICIPANT_TYPE } from 'constants/participantsSidebar';
import { EmailSetting, EmailType } from 'types/participants';
import { xor } from 'lodash';

const PARTICIPANTS_CATEGORY = 'Participants';
const COMPLETION_EMAIL_EVENT_NAME = 'Document Completion Notification';
const REQUEST_EMAIL_EVENT_NAME = 'Signature Requested Notification';

export const trackSignerCreation = action$ =>
  action$.ofType(OPEN_ADD_PARTICIPANT_TAB)
    .do(action => {
      let event: string = '';
      switch (action.participantType) {
        case participantTypeEnum.knownParticipant:
          event = 'Add Known Signer';
          break;
        case participantTypeEnum.unknownParticipant:
          event = 'Add Unknown Signer';
          break;
        case participantTypeEnum.knownList:
          event = 'Add Known List';
          break;
        default:
          break;
      }

      if (window.analytics) {
        window.analytics.track(event, {
          category: PARTICIPANTS_CATEGORY,
        });
      }
    }).ignoreElements();

export const trackSignerTypeSwitching = action$ =>
  action$.ofType(SWITCH_PARTICIPANT_TYPE)
    .do(action => {
      let event: string = '';
      switch (action.participantType) {
        case participantTypeEnum.knownParticipant:
          event = 'Switched to Known Signer';
          break;
        case participantTypeEnum.unknownParticipant:
          event = 'Switched to Unknown Signer';
          break;
        case participantTypeEnum.knownList:
          event = 'Switched to Known List';
          break;
        default:
          break;
      }

      if (window.analytics) {
        window.analytics.track(event, {
          category: PARTICIPANTS_CATEGORY,
        });
      }
    }).ignoreElements();

export const trackEmailTypeEditing = action$ =>
  action$.ofType(OPEN_EDIT_EMAIL_UI)
    .do(action => {
      let event: string = '';
      switch (action.emailType) {
        case emailTypeEnum.request:
          event = 'Edit Signature Requested Notification';
          break;
        case emailTypeEnum.completion:
          event = 'Edit Document Completion Notification';
          break;
        case emailTypeEnum.signatureConfirmation:
          event = 'Edit Signature Confirmation Notification';
          break;
        default:
          break;
      }

      if (window.analytics) {
        window.analytics.track(event, {
          category: PARTICIPANTS_CATEGORY,
        });
      }
    }).ignoreElements();

const findChangedFields = (newEmailSettings: EmailSetting, previousEmailSettings: EmailSetting): string[] => {
  const changedFields: string[] = [];
  if (newEmailSettings.subject !== previousEmailSettings.subject) {
    changedFields.push('Subject');
  }
  if (newEmailSettings.body.getCurrentContent().getPlainText('\u0001')
    !==
    previousEmailSettings.body.getCurrentContent().getPlainText('\u0001')) {
    changedFields.push('Body');
  }
  if (xor(newEmailSettings.fieldIds, previousEmailSettings.fieldIds).length !== 0) {
    changedFields.push('Field data');
  }
  return changedFields;
};

const trackEmailChange = (emailType: EmailType, action): void => {
  let newEmailSettings: EmailSetting | null = null;
  let prevEmailSettings: EmailSetting | null = null;

  switch (emailType) {
    case emailTypeEnum.completion:
      newEmailSettings = action.currentParticipant.emailSettings.completionEmail;
      prevEmailSettings = action.previousCurrentParticipantState.emailSettings.completionEmail;
      break;
    case emailTypeEnum.request:
      newEmailSettings = action.currentParticipant.emailSettings.requestEmail;
      prevEmailSettings = action.previousCurrentParticipantState.emailSettings.requestEmail;
      break;
    case emailTypeEnum.signatureConfirmation:
      newEmailSettings = action.currentParticipant.emailSettings.signatureConfirmationEmail;
      prevEmailSettings = action.previousCurrentParticipantState.emailSettings.signatureConfirmation;
      break;
    default:
      break;
  }
  // @ts-ignore
  const emailChanges: string[] = findChangedFields(newEmailSettings, prevEmailSettings);
  if (emailChanges.length) {
    const emailTypeName: string = emailType === emailTypeEnum.completion ?
      COMPLETION_EMAIL_EVENT_NAME :
      REQUEST_EMAIL_EVENT_NAME;
    const event: string = `Changed ${emailChanges.join(', ')} for ${emailTypeName}`;

    window.analytics && window.analytics.track(event, {
      category: PARTICIPANTS_CATEGORY,
    });
  }
};

export const trackEmailFieldsEditing = action$ =>
  action$.ofType(TRACK_EMAIL_SETTINGS)
    .do(action => {
      trackEmailChange(emailTypeEnum.completion, action);
      trackEmailChange(emailTypeEnum.request, action);
    }).ignoreElements();

export default [
  trackSignerCreation,
  trackSignerTypeSwitching,
  trackEmailTypeEditing,
  trackEmailFieldsEditing,
];
