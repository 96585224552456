import * as React from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import style from './FormSpacer.css';

type Props = {
  cssClass: string,
  specialSettings?: {
    color?: string,
    height?: string,
  }
}
function FormSpacer({
  cssClass = style.SpacerDefault,
  specialSettings = {
    height: '5',
  },
}: Props, ref) {
  useImperativeHandle(ref, () => ({ validate: () => true }));
  const styles = {
    height: specialSettings.height ? `${specialSettings.height}px` : '5px',
    backgroundColor: specialSettings.color ? specialSettings.color : undefined,
  };
  return (<hr
    className={classNames(cssClass, 'form_spacer')}
    style={styles}
  />);
}

export default React.memo(forwardRef(FormSpacer));
