import { Observable } from 'rxjs/Observable';

import { LOAD_PARTICIPANTS, LOAD_PARTICIPANTS_WITH_CONDITIONALS } from 'constants/participantsSidebar';
import { pending } from './utils/filters';
import { getAllUsers } from 'actions/users';
import { getFormFieldsForEmail } from 'actions/automatedProcessBuilder';
import { getUserMap } from '../reducers';

import type { ActionsObservable } from 'redux-observable';
import type { MiddlewareAPI } from 'redux';
import type { State } from 'types/shared';

export default function participantsSideloadEpic(
  action$: ActionsObservable<any>,
  store: MiddlewareAPI<State>,
): Observable<any> {
  const loadParticipantsActions$ = action$
    .ofType(LOAD_PARTICIPANTS, LOAD_PARTICIPANTS_WITH_CONDITIONALS)
    .filter(pending);

  const getUsersOnSuccess = (_, {response: {users}}) => ({users});
  const usersAction$ = loadParticipantsActions$
    .filter(() => {
      const state = store.getState();
      const userMap = getUserMap(state);
      return Object.keys(userMap).length === 0;
    })
    // This is a hack to prevent the epic from firing multiple times
    // until we can update rxjs to support throttle with an action type
    .throttleTime(5000)
    .mergeMap(() => Observable.of(getAllUsers(getUsersOnSuccess)));

  const formFieldsAction$ = loadParticipantsActions$
    .throttleTime(4000)
    .mergeMap(action => Observable.of(getFormFieldsForEmail(action.payload.formId)));

  return usersAction$.merge(formFieldsAction$);
}
