import React from 'react';
import styles from './Stage.css';
import StageDot from 'components/Stages/StageDot';
import StageTooltip from 'components/Stages/StageTooltip';
import StageEditor from './StageEditor';
import DeleteStage from './DeleteStage';
import {joinClassNames} from 'utils/strings';


import {ENTER_KEY, SPACE_KEY, BACK_SPACE_KEY, DEL_KEY, _getKeyboardCode} from 'utils/keyboard';


export default class Stage extends React.PureComponent<any, any> {
  static defaultProps = {
    inSidebar: false,
    inSnackbar: false,
    isReadOnly: false,
    isFocused: false,
    showDetails: false,
    onClick: () => {},
    expandSidebar: () => {},
  }
  state = {isEditing: false, showConfirmationDeleteModal: false};

  stageRef = React.createRef();
  closeEditor = () => {
    this.setState(
      {isEditing: false},
      () => this.stageRef.current && (this.stageRef.current as any).focus(),
    );
  }
  openEditor = () => this.setState({isEditing: true})

  toggleModalState = (e?: React.MouseEvent) => {
    if (e) e.stopPropagation();
    this.setState(({showConfirmationDeleteModal}) => ({showConfirmationDeleteModal: !showConfirmationDeleteModal}));
  }

  handleOnSelect = () => this.props.onClick(this.props.data);

  handleOnKeyUp = e => {
    if (this.state.isEditing || this.state.showConfirmationDeleteModal || this.props.isReadOnly) return;
    const key = _getKeyboardCode(e);
    if (!e.shiftKey || key !== ENTER_KEY) return;
    e.stopPropagation();
    this.props.expandSidebar();
    this.openEditor();
  }

  handleOnKeyDown = e => {
    if (this.state.isEditing || this.state.showConfirmationDeleteModal) return;
    const key = _getKeyboardCode(e);
    switch (key) {
      case SPACE_KEY:
        e.stopPropagation();
        this.handleOnSelect();
        break;
      case ENTER_KEY:
        if (e.shiftKey) break;
        e.stopPropagation();
        this.handleOnSelect();
        break;
      case BACK_SPACE_KEY:
      case DEL_KEY:
        if (this.props.isReadOnly) break;
        e.stopPropagation();
        this.props.expandSidebar();
        this.toggleModalState();
        break;
      default:
        break;
    }
  }

  render() {
    const {isFocused, data: stage, inSidebar, inSnackbar, isReadOnly, showDetails} = this.props;
    const {isEditing, showConfirmationDeleteModal} = this.state;
    const {handleOnSelect, closeEditor, openEditor, handleOnKeyUp, handleOnKeyDown, toggleModalState, stageRef} = this;
    const stagesClassName = joinClassNames(
      styles.body,
      isFocused ? styles.focused : '',
      inSidebar ? styles.inSidebar : '',
      isEditing ? styles.isEditing : '',
      inSnackbar ? styles.inSnackbar : '',
      showDetails ? styles.showDetails : ''
    );

    const content = (
      <div
        className={stagesClassName}
        onClick={handleOnSelect}
        aria-label={stage.name}
        onKeyUp={handleOnKeyUp}
        onKeyDown={handleOnKeyDown}
        tabIndex={0}
        ref={stageRef as any}>
        <StageDot
          radiusInPx={6}
          color={stage.color}
          cursor={!isReadOnly && showDetails ? 'move' : undefined}
          className={styles.dot}/>
        {(!inSidebar || showDetails) && <span className={styles.name}>{stage.name}</span>}
        {inSidebar && <div className={styles.count}>{stage.count}</div>}
        {!isReadOnly && (
          <StageEditor
            stage={stage}
            isEditing={isEditing}
            closeEditor={closeEditor}
            openEditor={openEditor}/>
        )}
        {(!isReadOnly && showDetails) &&
          <DeleteStage
            stageName={stage.name}
            showConfirmationDeleteModal={showConfirmationDeleteModal}
            toggleModalState={toggleModalState}/>
        }
      </div>
    );

    if (inSidebar && !showDetails) {
      return (
        <StageTooltip stage={stage}>
          {content}
        </StageTooltip>
      );
    }
    return content;
  }
}
