import React from 'react';
import Quote from '@material-ui/icons/FormatQuote';
import Code from '@material-ui/icons/Code';
import ListBulleted from '@material-ui/icons/FormatListBulleted';
import ListNumbered from '@material-ui/icons/FormatListNumbered';
import AlignLeft from '@material-ui/icons/FormatAlignLeft';
import AlignCenter from '@material-ui/icons/FormatAlignCenter';
import AlignRight from '@material-ui/icons/FormatAlignRight';
import InsertImage from '@material-ui/icons/Photo';
import { IconButton, getCurrentBlockType } from './shared';


const formatBlockTypes = [
  { Icon: ListBulleted, type: 'unordered-list-item', tooltip: 'Bulleted List' },
  { Icon: ListNumbered, type: 'ordered-list-item', tooltip: 'Numbered List' },
  { Icon: Quote, type: 'blockquote', tooltip: 'Block Quote' },
  { Icon: Code, type: 'code-block', tooltip: 'Code Block' },
];

const alignmentBlockTypes = [
  { Icon: AlignLeft, type: 'align-left', tooltip: 'Align Left' },
  { Icon: AlignCenter, type: 'align-center', tooltip: 'Align Center' },
  { Icon: AlignRight, type: 'align-right', tooltip: 'Align Right' },
];

class WithIcon extends React.Component<any, any> {
  render() {
    const { blockTypes, editorState, onToggle } = this.props;
    const currentBlockType = getCurrentBlockType(editorState);
    return (
      <span>
        {
          blockTypes.map(({ Icon, tooltip, type }) =>
            <IconButton
              key={type}
              active={type === currentBlockType}
              Icon={Icon}
              onToggle={onToggle}
              tooltip={tooltip}
              type={type} />
          )
        }
      </span>
    );
  }
}

export class Alignment extends React.Component<any, any> {
  render() {
    const props = { ...this.props, blockTypes: alignmentBlockTypes };
    return <WithIcon {...props} />;
  }
}

export class Format extends React.Component<any, any> {
  render() {
    const props = { ...this.props, blockTypes: formatBlockTypes };
    return <WithIcon {...props} />;
  }
}

export class Image extends React.Component<any, any> {
  render() {
    const { editorState, onToggle } = this.props;
    const currentBlockType = getCurrentBlockType(editorState);
    const type = 'IMAGE';

    return (
      <span>
        <IconButton
          key={type}
          active={currentBlockType === type}
          Icon={InsertImage}
          onToggle={onToggle}
          tooltip='Add an image'
          type={type} />
      </span>
    );
  }
}
