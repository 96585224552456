import React, {
  memo,
  forwardRef,
  useState,
  useEffect,
  ChangeEvent,
  FocusEvent,
  ForwardedRef, useImperativeHandle, useRef,
} from 'react';
import { ControlState, LabelAlignment, ShowLabelValue } from 'types/liveView';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';
import styles from './Autocomplete.css';
import ErrorContainer from '../ErrorContainer/ErrorContainer';

export interface AutoCompleteProps {
  elementType: string;
  excludedFromPreview: boolean;

  extraData: {
    label: string;
    labelAlign: LabelAlignment;
    showLabel: ShowLabelValue;
    value: string;
    width: string;
  };

  fieldState?: ControlState;
  formula?: any;
  hoverText?: string;
  id: string;
  isDisabled: boolean;
  label: string;
  properties?: any;
  required: boolean;
  showOtherOption: boolean;

  specialSettings: {
    align: string;
    disabled: boolean;
    entries: string[];
    label: string;
    labelAlignment: LabelAlignment;
    showLabel: boolean;
    width: string;
  };
  updateForm?: (s: { fields: { [p: string]: string | null } }) => void;
}

const AutoComplete = forwardRef(({
  id = '',
  required = false,
  isDisabled = false,
  extraData: { value = '', label = '' },
  specialSettings: { showLabel = true, labelAlignment = 'auto', width = '', entries = [] },
  updateForm = () => {},
}: AutoCompleteProps, ref: ForwardedRef<any>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>(value);
  const [filteredEntries, setFilteredEntries] = useState<string[]>([]);
  const [shouldDisplayEntries, setShouldDisplayEntries] =
    useState<boolean>(false);

  useEffect((): void => {
    setFilteredEntries(entries);
  }, [entries]);

  useEffect((): void => {
    const s = {
      fields: { [label]: inputValue },
    };
    if (updateForm) {
      updateForm(s);
    }
  }, [inputValue]);


  const selfValidate = (): boolean => {
    const isValid: boolean = required ? !!inputValue.trim() : true;
    setError(isValid ? '' : 'This field is required');
    return isValid;
  };

  useImperativeHandle(
    ref,
    () => ({
      focus: () => inputRef.current?.focus(),
      validate: selfValidate,
    }));

  const filterEntries = (typedValue: string): string[] => {
    const filtered = entries.filter(entry =>
      entry.toLowerCase().includes(typedValue.toLowerCase()),
    );
    return filtered.length > 0 ? filtered : entries;
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const typedValue = e.target.value;
    setInputValue(typedValue);
    setShouldDisplayEntries(!!typedValue);
    setFilteredEntries(filterEntries(typedValue));
  };
  const onFocusHandler = (e: FocusEvent<HTMLInputElement>): void => {
    e?.preventDefault();
    setFilteredEntries(entries);
  };

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>): void => {
    e?.preventDefault();
    setShouldDisplayEntries(false);
    selfValidate();
  };

  const boldingMatchedParts = (item: string): JSX.Element => {
    if (!inputValue) return <>{item}</>;

    const regex = new RegExp(`(${inputValue})`, 'gi');
    return (
      <>
        {item.split(regex).map((part, index) => (
          <span
            key={index}
            style={
              part.toLowerCase() === inputValue.toLowerCase()
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </>
    );
  };

  return (
    <div
      ref={ref as React.RefObject<HTMLDivElement>}
      className={classNames(sharedStyle.FormControlGroup, 'form_control_group')}
    >
      {showLabel && (
        <label
          aria-label={label}
          htmlFor={id}
          className={classNames(
            sharedStyle.FormLabel,
            { [sharedStyle.Required]: required },
            'field_label',
            getAlignmentClass(labelAlignment, sharedStyle),
          )}
        >
          {label}
        </label>
      )}
      <div>
        <ErrorContainer error={error}>
          <input
            ref={inputRef}
            id={id}
            width={width}
            value={inputValue}
            aria-autocomplete='list'
            role='combobox'
            className={classNames(styles.autocomplete_input, 'field_input')}
            onChange={handleOnChange}
            required={required}
            disabled={isDisabled}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
          />
        </ErrorContainer>
        <div
          role='listbox'
          style={{ width: '100%', position: 'relative' }}
          className={classNames(
            styles.autocomplete_entries,
            shouldDisplayEntries ? styles.showEntries : styles.hideEntries,
          )}
        >
          <ul>
            {shouldDisplayEntries &&
              filteredEntries.map((item, index) => (
                <li
                  role='option'
                  className={styles.autocomplete_item}
                  onMouseEnter={() => setInputValue(item)}
                  onClick={() => setInputValue(item)}
                  key={item + index}
                >
                  {boldingMatchedParts(item)}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
});

export default memo(AutoComplete);
