import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import useOutsideClick from 'hooks/useOutsideClick';
import { filterDisabledColumns, getFilterColumnItems } from 'utils/submissionsManager/submissionFilters';
import { joinClassNames } from 'utils/strings';
import {
  closeFiltersModal as closeFiltersModalAction,
  addFilter as addFilterAction,
  removeFilter as removeFilterAction,
  selectFilterColumn as selectFilterColumnAction,
  selectFilterOperator as selectFilterOperatorAction,
  changeFilterExpectation as changeFilterExpectationAction,
  applyFilters as applyFiltersAction,
} from 'actions/submissionsManager/tableApi';
import {
  getFiltersState,
  getFormFieldsForForm,
  getIsFiltersStateValid,
  getSortedFilteredColumnsWithLabels,
  getSubmissionFilters,
  getSubmissionManagerFormId,
} from 'reducers/index';
import {
  SubmissionFilter,
  Column,
  Operator,
  Expectation,
} from 'types/submissionsManager/submissionFilters';
import { FormField } from 'types/formFields';

import Filter from './Filter';

import styles from './styles.css';

type PropsFromState = {
  isOpened: boolean;
  submissionFilters: SubmissionFilter[];
  columns: Column[];
  isFiltersStateValid: boolean,
  formFields: FormField[],
}
type PropsFromDispatch = {
  closeFiltersModal: typeof closeFiltersModalAction;
  addFilter: typeof addFilterAction;
  removeFilter: typeof removeFilterAction;
  selectFilterColumn: typeof selectFilterColumnAction;
  selectFilterOperator: typeof selectFilterOperatorAction;
  changeFilterExpectation: typeof changeFilterExpectationAction;
  applyFilters: typeof applyFiltersAction;
}
export type Props =
  & PropsFromState
  & PropsFromDispatch;

export const FiltersModal = ({
  isOpened,
  submissionFilters = [],
  columns = [],
  formFields,
  isFiltersStateValid,
  closeFiltersModal,
  addFilter,
  removeFilter,
  selectFilterColumn,
  selectFilterOperator,
  changeFilterExpectation,
  applyFilters,
}: Props) => {
  const ref = useOutsideClick(closeFiltersModal);

  const handleRemoveFilter = (filterId: string) => removeFilter(filterId);
  const handleSelectColumn = (filterId: string, columnKey: string) => selectFilterColumn(filterId, columnKey);
  const handleSelectOperator = (filterId: string, operator: Operator) => selectFilterOperator(filterId, operator);
  const handleChangeExpectation = (filterId: string, expectation: Expectation) =>
    changeFilterExpectation(filterId, expectation);

  const columnItems = useMemo(
    () => getFilterColumnItems(columns, formFields),
    [columns, formFields]
  );

  const renderEmptyState = () =>
    (<div className={styles.emptyState} data-testid='emptyState'>
      <h6 className={styles.emptyStateText}>No filters applied</h6>
    </div>);

  const renderFilter = (filter: SubmissionFilter) =>
    (<Filter
      key={filter.id}
      columnItems={columnItems}
      filter={filter}
      formFields={formFields}
      onRemoveFilter={handleRemoveFilter}
      onSelectColumn={handleSelectColumn}
      onSelectOperator={handleSelectOperator}
      onChangeExpectation={handleChangeExpectation}
    />);

  const renderFilters = () => (<>
    <h6 className={joinClassNames(styles.leftColumn, styles.header)}>Columns</h6>
    <h6 className={joinClassNames(styles.middleColumn, styles.header)}>Operator</h6>
    <h6 className={joinClassNames(styles.rightColumn, styles.header)}>Value</h6>
    {submissionFilters.map(renderFilter)}
  </>);

  return isOpened ? (
    <div ref={ref} className={styles.Container}>
      {submissionFilters.length === 0 ? renderEmptyState() : renderFilters()}
      <button onClick={() => addFilter()} className={joinClassNames(styles.leftColumn, styles.addFilterButton)} data-testid='addFilter'>
        + Add Filter
      </button>
      <button
        onClick={() => applyFilters(submissionFilters)}
        className={joinClassNames(styles.rightColumn, styles.applyButton)}
        data-testid='applyFilters'
        disabled={!isFiltersStateValid}
      >
        Apply
      </button>
    </div>
  ) : null;
};

const mapStateToProps = (state): PropsFromState => {
  const columns = getSortedFilteredColumnsWithLabels(state);
  return {
    isOpened: getFiltersState(state).isOpened,
    submissionFilters: getSubmissionFilters(state),
    columns: filterDisabledColumns(columns),
    isFiltersStateValid: getIsFiltersStateValid(state),
    formFields: getFormFieldsForForm(state, getSubmissionManagerFormId(state)),
  };
};

const mapDispatchToProps = (dispatch): PropsFromDispatch => bindActionCreators({
  closeFiltersModal: closeFiltersModalAction,
  addFilter: addFilterAction,
  removeFilter: removeFilterAction,
  selectFilterColumn: selectFilterColumnAction,
  selectFilterOperator: selectFilterOperatorAction,
  changeFilterExpectation: changeFilterExpectationAction,
  applyFilters: applyFiltersAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FiltersModal);
