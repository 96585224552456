import { connect } from 'react-redux';
import React from 'react';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { memoize } from 'lodash';
import LargeModal from 'components/Modals/LargeModal';
import Loader from 'components/Loader';
import Body from 'components/permissions/Body';
import { WindowSize, getWindowSizeData } from 'components/WindowSize';
import { permissionLevelsEnum } from 'constants/permissions';
import * as actions from 'actions/permissions';
import * as Colors from 'utils/colors';
import styles from './Permissions.css';
import {
  getUserIdsWithPermissionType,
  getRolesWithPermissionType,
  hydratePermissionsWithUsersAndSort,
} from 'utils/permissions';


import AuthorSelector from 'components/permissions/AuthorSelector';
import { safeObjectValues } from 'utils/flow';
import { userRolesEnum } from 'constants/user';


const inlineStyles = {
  displayFlex: {
    display: 'flex',
  },
  column: {
    flexDirection: 'column',
  },
  titleIcon: {
    color: Colors.grey600,
    height: '24px',
    width: '24px',
    margin: '0 16px',
  },
  headerStyle: {
    fontWeight: '500',
    fontSize: '20px',
    margin: '0 24px',
  },
  toolbarStyle: {
    backgroundColor: Colors.white,
  },
  modalActionStyles: {
    padding: '0 8px 8px',
    margin: '0 10px',
  },
};

export class Permissions extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { isPhone: getWindowSizeData().isPhone };
  }

  UNSAFE_componentWillUpdate({ formId, modalOpen }) {
    if (modalOpen !== this.props.modalOpen && modalOpen) {
      if (formId) {
        this.props.actions.getUsers(this.props.dispatch, formId);
      }
    }
  }

  mapPermissionsStateToServer(props) {
    const {
      allowVisitorRegistration,
      accessCodeEnabled,
      authorId,
      formId,
      isPublic,
      requireVisitorRegistration,
      permissions,
      rolePermissions,
    } = props;
    const shareFormUsers = getUserIdsWithPermissionType(permissions, permissionLevelsEnum.ShareForm, authorId);
    const shareSubmissionUsers = getUserIdsWithPermissionType(
      permissions,
      permissionLevelsEnum.ShareAllFormSubmissions,
      authorId
    );
    const usersWithFormEdit = getUserIdsWithPermissionType(permissions, permissionLevelsEnum.EditForm, authorId);

    return {
      allowVisitorRegistration,
      accessCodeEnabled,
      authorId,
      formId,
      isPublic,
      isSaveAndContinueEnabled: requireVisitorRegistration,
      shareFormUsers,
      shareSubmissionUsers,
      usersWithFormEdit,
      rolesWithFormEdit: getRolesWithPermissionType(rolePermissions, permissionLevelsEnum.EditForm),
      rolesWithFormSubmissionsViewData: getRolesWithPermissionType(
        rolePermissions,
        permissionLevelsEnum.ShareAllFormSubmissions
      ),
      rolesWithFormViewData: getRolesWithPermissionType(rolePermissions, permissionLevelsEnum.ShareForm),
      rolesWithProcessSubmission: getRolesWithPermissionType(
        rolePermissions,
        permissionLevelsEnum.ProcessSubmissionsForm
      ),
      usersWithProcessSubmission: getUserIdsWithPermissionType(
        permissions,
        permissionLevelsEnum.ProcessSubmissionsForm,
        authorId
      ),
    };
  }

  hydratePermissionsWithUsersAndSort = memoize(hydratePermissionsWithUsersAndSort);

  handleClose = () => this.props.actions.toggleModal(false);

  handleRequestClose = () => {
    this.props.actions.toggleSnackbar(false, null);
  };

  handleSave = () => {
    const authorName = this.props.users[this.props.authorId].fullName;
    const permissionsArguments = this.mapPermissionsStateToServer(this.props);
    this.props.actions.savePermissions(this.props.dispatch, permissionsArguments, authorName);
  };

  handleWindowSizeOnChange = ({ isPhone }) => {
    this.setState({ isPhone });
  };

  render() {
    const {
      allowVisitorRegistration,
      accessCodeEnabled,
      authorId,
      features,
      fetchingPermissions,
      fetchingUsers,
      isDirty,
      isPdf,
      isPublic,
      modalOpen,
      permissions,
      requireVisitorRegistration,
      rolePermissions,
      snackbarOpen,
      snackbarMessage,
      users,
      updatingFormIds,
    } = this.props;

    const isUpdating = updatingFormIds.includes(this.props.formId);

    const fetchingData = fetchingUsers || fetchingPermissions;
    const modalActions = [
      <Button
        key='cancel'
        classes={{ root: styles.cancelButton, label: styles.modalButtonLabel }}
        onClick={this.handleClose}
      >
        Cancel
      </Button>,

      <Button
        disabled={!isDirty || isUpdating}
        key='save'
        style={{ backgroundColor: !isDirty || isUpdating ? Colors.grey700 : Colors.grey850 }}
        classes={{ root: styles.updateButton, label: styles.updateButtonLabel }}
        onClick={this.handleSave}
      >
        {isUpdating ? (
          <>
            <span>Updating</span>
            <CircularProgress className={styles.updateButtonCircular} size={20} />
          </>
        ) : (
          'Update'
        )}
      </Button>,
    ];
    if (!fetchingData) {
      const authorSelector = (
        <div key='author'>
          <AuthorSelector
            author={users[authorId]}
            changeAuthor={this.props.actions.changeAuthor}
            isPhone={this.state.isPhone}
            users={safeObjectValues(users).filter(user => user.role !== userRolesEnum.ANALYST)}
          />
          <p className={styles.info}>Account Owners and the form Author always have full permissions for this form.</p>
        </div>
      );
      modalActions.unshift(authorSelector);
    }

    const showAllowRegistration = features.VISITOR_USER_SELF_REGISTRATION_SUPPORT;
    const showRequireRegistration = isPdf && features.SAVE_AND_CONTINUE;
    const hydratedPermissions = this.hydratePermissionsWithUsersAndSort(permissions, users, authorId);
    const footerStyles = {
      ...inlineStyles.displayFlex,
      justifyContent: 'space-around',
      ...(this.state.isPhone ? inlineStyles.column : {}),
    };
    return (
      <div>
        <WindowSize onChange={this.handleWindowSizeOnChange} />
        {modalOpen && (
          <LargeModal
            actions={modalActions}
            footerInlineStyle={footerStyles}
            disableBackdropClick
            open
            title={'Permissions'}
            headerInlineStyle={inlineStyles.headerStyle}
            toolbarInlineStyle={inlineStyles.toolbarStyle}
          >
            {fetchingData ? (
              <Loader />
            ) : (
              <Body
                allowVisitorRegistration={allowVisitorRegistration}
                authorId={authorId}
                addRolePermission={this.props.actions.addRolePermission}
                changeAllowVisitorRegistration={this.props.actions.changeAllowVisitorRegistration}
                accessCodeEnabled={accessCodeEnabled}
                changeAccessCodeEnabled={this.props.actions.changeAccessCodeEnabled}
                changeAuthor={this.props.actions.changeAuthor}
                changeRolePermission={this.props.actions.changeRolePermission}
                changeUser={this.props.actions.changeUser}
                changePermissionLevel={this.props.actions.changePermissionLevel}
                changeRequirePassword={this.props.actions.changeRequirePassword}
                changeRequireVisitorRegistration={this.props.actions.changeRequireVisitorRegistration}
                closeModal={this.handleClose}
                permissions={hydratedPermissions}
                removePermission={this.props.actions.removePermission}
                removeRole={this.props.actions.removeRole}
                requirePassword={!isPublic}
                requireVisitorRegistration={requireVisitorRegistration}
                rolePermissions={rolePermissions}
                showAllowRegistration={!!showAllowRegistration}
                showRequireRegistration={!!showRequireRegistration}
                users={users}
              />
            )}
          </LargeModal>
        )}

        <Snackbar
          open={snackbarOpen}
          message={snackbarMessage || ''}
          autoHideDuration={3200}
          onClose={this.handleRequestClose}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  dispatch,
});

const mapStateToProps = state => ({
  ...state.permissions,
  features: state.features,
  users: state.users,
});

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);
