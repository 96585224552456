import React from 'react';
import { findDOMNode } from 'utils/reactDOM';
import {
  DOWN_KEY,
  ESC_KEY,
  ENTER_KEY,
  UP_KEY,
  HOME_KEY,
  END_KEY,
  TAB_KEY,
  SPACE_KEY,
  _getKeyboardCode,
} from 'utils/keyboard';
import styles from './Dropdown.css';

interface Props {
   items: any[],
   onEscOrTabKey: Function
 }

class KeyboardNavigationWrapper extends React.Component<Props, any> {
  state = {
    selectedIndex: null,
  }

  componentDidMount() {
    this.itemList = findDOMNode(this.refs.itemList);
    this.itemList && this.itemList.focus();
  }

  selectItem = itemIndex => {
    this.setState({
      selectedIndex: itemIndex,
    }, this.scrollIntoView);
  }

  itemList

  noItemSelected = () => !Number.isInteger(this.state.selectedIndex)

  scrollIntoView = () => {
    const listEl = this.itemList;
    if (!listEl || this.noItemSelected()) return;
    const itemEl = listEl.children[this.state.selectedIndex || 0];
    if (!itemEl) return;
    const list = {
      begin: listEl.scrollTop,
      end: listEl.scrollTop + listEl.offsetHeight,
      height: listEl.offsetHeight,
    };
    const item = {
      begin: itemEl.offsetTop,
      end: itemEl.offsetTop + itemEl.offsetHeight,
    };
    if (item.end > list.end) listEl.scrollTop = item.end - list.height;
    if (item.begin < list.begin) listEl.scrollTop = item.begin;
  }

  handleOnKeyDown = event => {
    const { selectedIndex } = this.state;
    const { items } = this.props;
    const key = _getKeyboardCode(event);
    switch (key) {
      case UP_KEY:
        event.preventDefault();
        event.persist();
        if (event.metaKey) {
          this.selectItem(0);
          break;
        }
        this.selectItem(!selectedIndex
          ? items.length - 1
          // @ts-ignore
          : selectedIndex - 1);
        break;

      case DOWN_KEY:
        event.preventDefault();
        event.persist();
        if (event.metaKey) {
          this.selectItem(items.length - 1);
          break;
        }
        this.selectItem(selectedIndex === items.length - 1 || this.noItemSelected()
          ? 0
          // @ts-ignore
          : selectedIndex + 1);
        break;

      case HOME_KEY:
        this.selectItem(0);
        break;

      case END_KEY:
        this.selectItem(items.length - 1);
        break;

      case ESC_KEY:
      case TAB_KEY:
        this.props.onEscOrTabKey();
        break;

      case ENTER_KEY:
      case SPACE_KEY:
        event.stopPropagation();
        if (this.noItemSelected()) return;
        const { data, onClick } = items[selectedIndex || 0].props;
        onClick(data);
        break;

      default:
        return;
    }
  }

  // stop wheel and touch propagation so fixed-data-table doesn't scroll
  // https://github.com/schrodinger/fixed-data-table-2/issues/242
  handleScroll = event => event.stopPropagation();

  render() {
    return (
      <div
        ref='itemList'
        tabIndex={-1}
        onKeyDown={this.handleOnKeyDown}
        onTouchMove={this.handleScroll}
        onWheel={this.handleScroll}
        className={styles.body}
        role='listbox'>
        {this.props.items.map((item, index) => {
          const selected = this.state.selectedIndex === index;
          const { Component, props } = item;
          return (
            <Component
              {...props}
              requestSelfSelection={() => this.selectItem(index)}
              key={index}
              isFocused={selected} />
          );
        })}
      </div>
    );
  }
}

export default KeyboardNavigationWrapper;
