import api from './api';
import {defaultMemoize} from 'reselect';
import {
  GET_STAGES_OF_FORM,
  CREATE_STAGES,
  REORDER_STAGES,
  DELETE_STAGE,
  UPDATE_STAGE,
  UNASSIGN_STAGE,
  ASSIGN_STAGE,
} from 'constants/types/stageActionTypes';

import {immutableRemoveElementInArray, immutableModifyElementInArray} from 'utils/reduxHelper';

const updateStageCount = (
  state,
  payload,
  change
) => {
  const {stageName, formId} = payload;
  const stages = state[formId];
  const stageIndex = stages.findIndex(({name}) => name === stageName);
  const newStage = {...stages[stageIndex], count: stages[stageIndex].count + change};
  const updatedStages = immutableModifyElementInArray(stages, stageIndex, newStage);
  return {
    ...state,
    [formId]: updatedStages,
  };
};

const initialState = {};

export default function formStages(state = initialState, _action) {
  const action = _action;
  switch (action.type) {
    case GET_STAGES_OF_FORM:
      return api(action, state, {
        success: () => {
          const {formId, stages} = action.payload;
          return {
            ...state,
            [formId]: stages,
          };
        },
      });
    case CREATE_STAGES:
      return api(action, state, {
        success: () => {
          const newStages = [
            ...state[action.payload.formId],
            ...action.payload.stages.map(stage => ({...stage, count: 0})),
          ];
          return {
            ...state,
            [action.payload.formId]: newStages,
          };
        },
      });
    case REORDER_STAGES:
      return api(action, state, {
        success: () => {
          const {formId, stageNames} = action.payload;
          const stages = state[formId];
          const reorderedStages = stageNames.reduce((accumulator, stageName) => {
            const stage = stages.find(({name}) => name === stageName);
            if (stage) accumulator.push(stage);
            return accumulator;
          }, []);
          return {
            ...state,
            [formId]: reorderedStages,
          };
        },
      });
    case DELETE_STAGE:
      return api(action, state, {
        success: () => {
          const {formId, stageName} = action.payload;
          const stages = state[formId];
          const stageIndex = stages.findIndex(({name}) => name === stageName);
          const newStages = immutableRemoveElementInArray(stages, stageIndex);
          return {
            ...state,
            [action.payload.formId]: newStages,
          };
        },
      });
    case UPDATE_STAGE:
      return api(action, state, {
        success: () => {
          const {
            formId,
            oldStageName,
            newStage: {name, color},
          } = action.payload;
          const stages = state[formId];
          const stageIndex = stages.findIndex(stage => stage.name === oldStageName);
          const updatedStage = {...stages[stageIndex], name, color};
          const updatedStages = immutableModifyElementInArray(stages, stageIndex, updatedStage);
          return {
            ...state,
            [formId]: updatedStages,
          };
        },
      });
    case UNASSIGN_STAGE:
      return api(action, state, {
        success: () => updateStageCount(state, action.payload, -1),
      });
    case ASSIGN_STAGE:
      return api(action, state, {
        success: () => updateStageCount(state, action.payload, 1),
      });
    default:
      return state;
  }
}

export const getStageState = state => state;

export const getStagesList = defaultMemoize((state, formId) => state[formId]);

export const getStage = (state, formId, stageName) => {
  if (state[formId]) return state[formId].find(stage => stage.name === stageName) || null;
  return null;
};
