import React, {useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import validate from 'internal-tools/utils/validator';
import withKeyboardFunctionality from 'components/MultiSelector/withKeyboardFunctionality';
import UserComponent from 'components/Users/User';
import Typeahead from 'components/Typeaheads/Typeahead';
import { ENTER_KEY, _getKeyboardCode } from 'utils/keyboard';
import crossIcon from '../../../../assets/icons/submissions/cross.svg';
import styles from './TagsSelect.css';
import { filterToAssigneesBySearchString } from 'utils/assignees';
import { User } from 'types/users';

type EmailResults = { valid: { email: string }[], invalid: string[] };

type Props = {
  allowNonRegisteredUsers?: boolean,
  customClassName?: string | null,
  customResultsClassName?: string | null,
  error?: boolean,
  multiple?: boolean,
  options: User[],
  selected: User[],
  setError?: Function,
  setSelected: Function,
}

const AssigneesSelect = ({
  selected,
  setSelected,
  error = false,
  setError = val => (val),
  customClassName = null,
  customResultsClassName = null,
  options = [],
  multiple = false,
  allowNonRegisteredUsers = false,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const typeheadRef = useRef<HTMLDivElement>(null);
  const searchValueRef = useRef<string | null>(null);
  const [searchValue, setSearch] = useState('');

  const setSearchValue = val => {
    setSearch(val);
    searchValueRef.current = val;
  };

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.width = searchValue.length === 0 ? '2ch' : searchValue.length + 'ch';
  }, [searchValue]);

  const filteredOptions = useMemo(() => {
    if (!selected.length) return options;
    const selectedUserIds = new Set<string>(selected.map(user => user.userId));
    return options.filter(user => !selectedUserIds.has(user.userId));
  }, [selected.length, options.length]);

  const renderAssignee = props => {
    const UserWithKeyBoard = withKeyboardFunctionality(UserComponent);
    return (
      <UserWithKeyBoard
        inPopover={props.inPopover}
        isMultiplePicked={props.isMultiplePicked}
        isReadOnly={props.isReadOnly}
        isSelectedByKeyboard={props.isSelectedByKeyboard}
        key={props.key}
        onRemove={props.onRemove}
        onSelect={props.onSelect}
        selected={props.selected}
        selectionData={props.selectionData}
        customClassName={customResultsClassName}
      />
    );
  };

  const onAddSelections = selectionData => {
    setSearchValue('');
    setSelected(multiple ? [...selected, ...selectionData] : selectionData);
  };

  const removeSelection = key => {
    setSelected([
      ...selected.slice(0, key),
      ...selected.slice(key + 1),
    ]);
  };

  const handleChange = ({target: {value}}) => {
    setSearchValue(value);
    setError(false);
  };

  const cropEmail = email => {
    const at = email.indexOf('@');
    const plus = email.indexOf('+');

    return email.substring(0, plus !== -1 ? plus : at);
  };

  const handleKeyDown = e => {
    if (_getKeyboardCode(e) === ENTER_KEY && allowNonRegisteredUsers) {
      const emails: EmailResults = {valid: [], invalid: []};
      searchValue.split(',').forEach(email => {
        if (validate('email', email)) {
          emails.valid.push({email});
        } else {
          emails.invalid.push(email);
        }
      });

      setSearchValue(emails.invalid.join(','));
      setSelected([...selected, ...emails.valid]);

      emails.invalid.length > 0 && setError(true);
    }
  };

  const handleBlur = () => {
    if (allowNonRegisteredUsers && !!searchValueRef.current) {
      const emails: EmailResults = {valid: [], invalid: []};
      searchValueRef.current.split(',').forEach(email => {
        if (validate('email', email)) {
          emails.valid.push({email});
        } else {
          emails.invalid.push(email);
        }
      });

      setSearchValue(emails.invalid.join(','));
      setSelected([...selected, ...emails.valid]);

      emails.invalid.length > 0 && setError(true);
    }
  };

  const handleRemove = (key, e) => {
    e.stopPropagation();
    removeSelection(key);
  };

  const renderSelections = () =>
    <div className={classNames(styles.Selections, {[styles.MultipleSelections]: multiple && selected.length > 0})}>
      <span
        className={classNames(
          {[styles.hidden]: selected.length > 0 || searchValue.length}
        )}>Name or email...</span>
      {selected.map((selection, key) => <div key={key} className={multiple ? styles.SelectedOption : styles.Selection}>
        <span>{selection.fullName && selection.fullName.trim() !== '' ? selection.fullName : cropEmail(selection.email)}</span>
        <span className={styles.SelectionRemove} onClick={e => handleRemove(key, e)}><img src={crossIcon} alt='x'/></span>
      </div>)}
      <input
        ref={ref}
        value={searchValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={() => setTimeout(handleBlur, 100)}
        className={classNames(
          styles.Input,
          {[styles.hidden]: !multiple && selected.length > 0},
          {[styles.placeholder]: !selected.length && !searchValue.length},
          {[styles.Error]: error}
        )}
      />
    </div>;

  return (<>
    <div className={classNames(styles.Select, customClassName)} onClick={() => ref.current?.focus()}>
      {renderSelections()}
    </div>
    {!!searchValue && <div ref={typeheadRef} className={classNames(styles.Results, customResultsClassName)}>
      <Typeahead
        allowPickingMultipleItems={false}
        isLoading={false}
        onAddSelections={onAddSelections}
        results={filterToAssigneesBySearchString(filteredOptions, searchValue)}
        renderSelection={renderAssignee}
        requestPopoverClose={() => {}}
        resultsListClassName={classNames(styles.resultsListClassName, customResultsClassName)}
        shouldAutoFocusInput={false}
      />
    </div>}
  </>);
};

export default AssigneesSelect;
