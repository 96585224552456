import { Observable } from 'rxjs/Observable';
import { LOBBY_ROOT_FOLDER } from 'constants/tableConsts';
import { REQUEST_INITIAL_FORM_LOAD, REQUEST_SUBSEQUENT_FORM_LOAD } from 'constants/types/lobbyTableActionTypes';
import { SELECT_QUICK_FILTER } from 'constants/types/quickFilterActionTypes';
import { quickFiltersEnum } from 'constants/quickFilters';
import { newTable, updateTable, getCanUpdateSettings, getPerFormCapabilities, searchFormsSubsequent, searchFormsInitial } from 'actions/lobby/tableApiActions';
import {
  allFilterInitialLoad,
  allFilterSubsequentLoad,
  assignedToMeFilterInitialLoad,
  assignedToMeFilterSubsequentLoad,
  draftsFilterInitialLoad,
  draftsFilterSubsequentLoad,
  sharedFilterInitialLoad,
  sharedFilterSubsequentLoad,
  submittedFilterInitialLoad,
  submittedFilterSubsequentLoad,
  signedByMeFilterInitialLoad,
  signedByMeFilterSubsequentLoad,
} from 'actions/quickFilters';
import { noopAction } from 'actions/shared';
import { getFormSearchBarInput, getLoggedInUserId, getSelectedQuickFilter } from '../reducers';

import { FORM_DETAILS } from 'constants/types/submissionsTableActionTypes';
import { success } from './utils/filters';
import { parsedToString } from 'utils/search';


// This is mostly for testability without mocking out the redux store
// The typing is subpar, but reflective of the fact that our epics are
// not really getting much type coverage at all at the moment
export function getInitialFormAction(
  fetching: boolean,
  tableDataLength: number,
  selectedFilter?: string | null,
  searchQuery?: string,
  isSearchPage?: boolean,
) {
  if (!!searchQuery) {
    return searchFormsInitial(searchQuery);
  }

  if (selectedFilter) {
    switch (selectedFilter) {
      case quickFiltersEnum.ASSIGNED_TO_ME:
        return assignedToMeFilterInitialLoad();
      case quickFiltersEnum.DRAFTS:
        return draftsFilterInitialLoad();
      case quickFiltersEnum.SHARED:
        return sharedFilterInitialLoad();
      case quickFiltersEnum.SUBMITTED:
        return submittedFilterInitialLoad();
      case quickFiltersEnum.SIGNED_BY_ME:
        return signedByMeFilterInitialLoad();
      case quickFiltersEnum.ALL:
      default:
        return allFilterInitialLoad();
    }
  } else if (isSearchPage) {
    // The lobby table contains (now stale) search results, so we should load the default table
    return newTable(LOBBY_ROOT_FOLDER);
  } else if (!fetching && tableDataLength === 0) {
    return newTable(LOBBY_ROOT_FOLDER);
  }
  return noopAction();
}

function initialFormLoad(action$, store) {
  const initialFormLoad$ = action$.ofType(REQUEST_INITIAL_FORM_LOAD);
  const quickFilterSelect$ = action$.ofType(SELECT_QUICK_FILTER);

  return initialFormLoad$.merge(quickFilterSelect$).map(() => {
    const state = store.getState();
    const { lobbyTable } = state;
    const { fetching, tableData, isSearchPage } = lobbyTable;
    const selectedFilter = getSelectedQuickFilter(state);
    const searchQuery = parsedToString(getFormSearchBarInput(state));
    return getInitialFormAction(fetching, tableData.length, selectedFilter, searchQuery, isSearchPage);
  });
}

export function getSubsequentFormAction(
  folderId: string,
  tableDataLength: number,
  userId: string,
  nextPageToken: string,
  selectedFilter?: string | null,
  searchQuery?: string,
  isSearchPage?: boolean,
) {
  if (!!searchQuery && isSearchPage) {
    return searchFormsSubsequent(tableDataLength, searchQuery);
  }

  if (selectedFilter) {
    switch (selectedFilter) {
      case quickFiltersEnum.ASSIGNED_TO_ME:
        return assignedToMeFilterSubsequentLoad(nextPageToken);
      case quickFiltersEnum.DRAFTS:
        return draftsFilterSubsequentLoad(nextPageToken);
      case quickFiltersEnum.SHARED:
        return sharedFilterSubsequentLoad(nextPageToken);
      case quickFiltersEnum.SUBMITTED:
        return submittedFilterSubsequentLoad(nextPageToken);
      case quickFiltersEnum.SIGNED_BY_ME:
        return signedByMeFilterSubsequentLoad(nextPageToken);
      case quickFiltersEnum.ALL:
      default:
        return allFilterSubsequentLoad(nextPageToken);
    }
  }
  return updateTable(folderId || LOBBY_ROOT_FOLDER, tableDataLength);
}

function subsequentFormLoad(action$, store) {
  const subsequentFormLoad$ = action$.ofType(REQUEST_SUBSEQUENT_FORM_LOAD);

  return subsequentFormLoad$.map(() => {
    const state = store.getState();
    const {
      lobbyTable: { folderId, tableData, nextPageToken, isSearchPage },
    } = state;
    const userId = getLoggedInUserId(state);
    const selectedFilter = getSelectedQuickFilter(state);
    const searchQuery = parsedToString(getFormSearchBarInput(state));
    return getSubsequentFormAction(
      folderId || LOBBY_ROOT_FOLDER,
      tableData.length,
      userId,
      nextPageToken,
      selectedFilter,
      searchQuery,
      isSearchPage,
    );
  });
}

function formSettingsLoad(action$) {
  const formLoadSuccess$ = action$.ofType(FORM_DETAILS).filter(success);

  return formLoadSuccess$.mergeMap(({ payload: { id } }) =>
    Observable.of(getPerFormCapabilities([id])).merge(Observable.of(getCanUpdateSettings([id])))
  );
}

export default [initialFormLoad, subsequentFormLoad, formSettingsLoad];
