import * as types from 'constants/types/authActionTypes';
import {receiveLogout} from 'actions/auth';
import {logout} from 'utils/auth';


export default function logoutEpic(
  action$,
  store
) {
  return action$
    .ofType(types.LOGOUT_REQUEST)
    .do(() => {
      const {useFederatedLogout} = store.getState().auth;
      logout(!!useFederatedLogout);
    })
    .map(receiveLogout);
}
