import React from 'react';
import ConfirmationModal from 'components/Modals/ConfirmationModal';


export default ({ onClose, onProceed, open }) => (
  <ConfirmationModal
    onClose={onClose}
    open={open}
    primaryButton={{
      onClick: onProceed,
      text: 'Proceed',
    }}>
    By removing yourself, you may lose access to the submission.
  </ConfirmationModal>
);
