import { ActionsObservable } from 'redux-observable';
import { Context } from '@datadog/browser-core/cjs/tools/context';
import { datadogLogs } from '@datadog/browser-logs';
import { noop } from 'lodash';
import { Observable } from 'rxjs/Observable';

import {
  GET_ASSIGNEES_OF_SUBMISSIONS,
  GET_PAYMENTS_OF_SUBMISSIONS,
  GET_PERMISSIONS_OF_SUBMISSIONS,
  GET_STAGE_OF_SUBMISSIONS,
  GET_TAGS_OF_SUBMISSIONS,
} from 'constants/types/submissionsTableActionTypes';
import { GET_DIR_JSON_API } from 'constants/types/lobbyFolderNavigationActionTypes';
import {
  GET_CAN_UPDATE_SETTINGS,
  GET_PER_FORM_CAPABILITIES,
  NEW_TABLE_API,
} from 'constants/types/lobbyTableActionTypes';
import {
  GET_ACCOUNT_KEY,
  GET_ACTIVE_DIRECTORY_CONNECTIONS,
  ORGANIZATION_INFO_API,
} from 'constants/types/authActionTypes';
import { GET_FEATURE_FLAGS } from 'constants/types/features';
import { MARK_BEGIN_MOUNT } from 'constants/types/measurePerformanceActionTypes';
import { success } from './utils/filters';


export function submissionManagerMeasureLoadEpic(
  action$: ActionsObservable<any>
): Observable<any> {
  const getTagsAction$ = action$.ofType(GET_TAGS_OF_SUBMISSIONS).filter(success);
  const getAssigneesAction$ = action$.ofType(GET_ASSIGNEES_OF_SUBMISSIONS).filter(success);
  const getStageAction$ = action$.ofType(GET_STAGE_OF_SUBMISSIONS).filter(success);
  const getPaymentsAction$ = action$.ofType(GET_PAYMENTS_OF_SUBMISSIONS).filter(success);
  const getPermissionsAction$ = action$.ofType(GET_PERMISSIONS_OF_SUBMISSIONS).filter(success);

  const name = 'SubmissionsManager';

  return action$
    .ofType(MARK_BEGIN_MOUNT)
    .filter(({ payload }) => payload?.component === name)
    .take(1)
    .mergeMap(
      () => Observable.combineLatest(
        getTagsAction$,
        getAssigneesAction$,
        getStageAction$,
        getPaymentsAction$,
        getPermissionsAction$,
      )
    )
    .take(1)
    .do(() => {
      try {
        window.performance.mark(`${name}InitialLoadEnd`);
        window.performance.measure(`${name}Mount`, `${name}MountStart`, `${name}InitialLoadEnd`);

        const performanceArray: PerformanceEntryList = window.performance.getEntriesByType('measure');
        const measure: PerformanceEntry = performanceArray[performanceArray.length - 1];
        const duration = measure.duration;

        const info: Context = {};
        info[`${name}.InitialLoad`] = duration;

        datadogLogs.logger.log(`${name}.InitialLoad`, info);
      } catch (e) {
        noop();
      }
    })
    .ignoreElements();
}

export function lobbyMeasureLoadEpic(
  action$: ActionsObservable<any>
): Observable<any> {
  const getDirectoryAction$ = action$.ofType(GET_DIR_JSON_API).filter(success);
  const getCanUpdateSettingsAction$ = action$.ofType(GET_CAN_UPDATE_SETTINGS).filter(success);
  const getPerFormCapabilitiesAction$ = action$.ofType(GET_PER_FORM_CAPABILITIES).filter(success);
  const newFormTableAction$ = action$.ofType(NEW_TABLE_API).filter(success);

  return action$
    .ofType(MARK_BEGIN_MOUNT)
    .filter(({ payload }) => payload?.component === 'Lobby')
    .take(1)
    .mergeMap(
      () => Observable.combineLatest(
        getDirectoryAction$,
        getCanUpdateSettingsAction$,
        getPerFormCapabilitiesAction$,
        newFormTableAction$,
      )
    )
    .take(1)
    .do(() => {
      try {
        const name = 'Lobby';
        window.performance.mark(`${name}InitialLoadEnd`);
        window.performance.measure(`${name}Mount`, `${name}MountStart`, `${name}InitialLoadEnd`);

        const performanceArray: PerformanceEntryList = window.performance.getEntriesByType('measure');
        const measure: PerformanceEntry = performanceArray[performanceArray.length - 1];
        const duration = measure.duration;

        const info: Context = {};
        info[`${name}.InitialLoad`] = duration;

        datadogLogs.logger.log(`${name}.InitialLoad`, info);
      } catch (e) {
        noop();
      }
    })
    .ignoreElements();
}

export function loginPageMeasureLoadEpic(
  action$: ActionsObservable<any>
): Observable<any> {
  const name = 'Login';

  const getAccountKeyAction$ = action$.ofType(GET_ACCOUNT_KEY).filter(success);
  const getActiveDirectoryConnectionsAction$ = action$.ofType(GET_ACTIVE_DIRECTORY_CONNECTIONS).filter(success);
  const getOrganizationInfoAction$ = action$.ofType(ORGANIZATION_INFO_API).filter(success);
  const getFeatureFlagsAction$ = action$.ofType(GET_FEATURE_FLAGS).filter(success);

  return action$
    .ofType(MARK_BEGIN_MOUNT)
    .filter(({ payload }) => payload?.component === name)
    .take(1)
    .mergeMap(
      () => Observable.combineLatest(
        getAccountKeyAction$,
        getActiveDirectoryConnectionsAction$,
        getOrganizationInfoAction$,
        getFeatureFlagsAction$,
      )
    )
    .take(1)
    .do(() => {
      try {
        window.performance.mark(`${name}InitialLoadEnd`);
        window.performance.measure(`${name}Mount`, `${name}MountStart`, `${name}InitialLoadEnd`);

        const performanceArray: PerformanceEntryList = window.performance.getEntriesByType('measure');
        const measure: PerformanceEntry = performanceArray[performanceArray.length - 1];
        const duration = measure.duration;

        const info: Context = {};
        info[`${name}.InitialLoad`] = duration;

        datadogLogs.logger.log(`${name}.InitialLoad`, info);
      } catch (e) {
        noop();
      }
    })
    .ignoreElements();
}

export default [
  submissionManagerMeasureLoadEpic,
  lobbyMeasureLoadEpic,
  loginPageMeasureLoadEpic,
];
