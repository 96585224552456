import RawComp from '../form-comps/RawComp/RawComp';
import AcuityScheduling from '../form-comps/AcuityScheduling/AcuityScheduling';
import FormCheckbox from '../form-comps/FormCheckbox/FormCheckbox';
import FormChoiceGroup from '../form-comps/FormChoiceGroup/FormChoiceGroup';
import FormDatePicker from '../form-comps/FormDatePicker/FormDatePicker';
import FormDropDown from '../form-comps/FormDropDown/FormDropDown';
import FormFullAddress from '../form-comps/FormFullAddress/FormFullAddress';
import FormFullDate from '../form-comps/FormFullDate/FormFullDate';
import FormFullName from '../form-comps/FormFullName/FormFullName';
import FormHeading from '../form-comps/FormHeading/FormHeading';
import FormImage from '../form-comps/FormImage/FormImage';
import FormInput from '../form-comps/FormInput/FormInput';
import FormMultiLine from '../form-comps/FormMultiLine/FormMultiLine';
import FormSignature from '../form-comps/FormSignature/FormSignature';
import FormSpacer from '../form-comps/FormSpacer/FormSpacer';
import FormUpload from '../form-comps/FormUpload/FormUpload';
import GoogleTranslate from '../form-comps/GoogleTranslate/GoogleTranslate';
import ReCAPTCHA from '../form-comps/ReCAPTCHA/ReCAPTCHA';
import StyledText from '../form-comps/StyledText/StyledText';
import SurveyMatrix from '../form-comps/SurveyMatrix/SurveyMatrix';
import DynamicList from '../form-comps/DynamicList/DynamicList';
import CanvasSketch from '../form-comps/CanvasSketch/CanvasSketch';
import Emoticon from '../form-comps/Emoticon/Emoticon';
import StarRating from '../form-comps/StarRating/StarRating';
import InternationalAddress from '../form-comps/InternationalAddress/InternationalAddress';
import FormToggle from '../form-comps/FormToggle/FormToggle';
import UserData from '../form-comps/UserData/UserData';
import CheckAndText from '../form-comps/CheckAndText/CheckAndText';
import UniqueString from '../form-comps/UniqueString/UniqueString';
import Entries from '../form-comps/Entries/Entries';
import EmbedVideo from '../form-comps/EmbedVideo/EmbedVideo';
import CurrencyFormat from '../form-comps/CurrencyFormat/CurrencyFormat';
import HtmlBox from '../form-comps/HtmlBox/HtmlBox';
import CountdownDateTime from '../form-comps/CountdownDateTime/CountdownDateTime';
import AutoComplete from '../form-comps/Autocomplete/Autocomplete';
import AddressAutocomplete from '../form-comps/AddressAutocomplete/AddressAutocomplete';
import QueryGoogleSheets from '../form-comps/QueryGoogleSheets/QueryGoogleSheets';
import AddThis from '../form-comps/AddThis/AddThis';
import AddToCalendar from '../form-comps/AddToCalendar/AddToCalendar';
import GoogleMaps from '../form-comps/GoogleMaps/GoogleMaps';
import AddThisEvent from '../form-comps/AddThisEvent/AddThisEvent';
import Receipt from '../form-comps/Receipt/Receipt';
import IdVerification from '../form-comps/IdVerification/IdVerification';

// contains elements supported for the preview mode
export const elementsForPreview: string[] = [
  'heading',
  'sub_heading',
  'multiline',
  'line',
  'dropdown',
  'checkbox',
  'toggle',
  'radio',
  'countdown',
  'date_picker',
  'auto_complete',
  'add_this_event',
];

/**
 * contains elements supported for the NG live view
 * [component, defaultProps]
 */
export const elementMap = {
  heading: [FormHeading, { tag: 'h1' }],
  sub_heading: [FormHeading, { tag: 'h2' }],
  label: [FormHeading, { tag: 'label', cssClass: 'form_label' }],
  line: [FormInput, { type: 'text' }],
  date_picker: [FormDatePicker, { type: 'date' }],
  email: [FormInput, { type: 'email' }],
  rich_text: [StyledText, {}],
  phone: [FormInput, { type: 'phone' }],
  number: [FormInput, { type: 'number' }],
  // url: FormInput,
  // text: FormInput,
  full_name: [FormFullName, {}],
  multiline: [FormMultiLine, {}],
  signature: [FormSignature, {}],
  dropdown: [FormDropDown, {}],
  upload: [FormUpload, {}],
  image: [FormImage, {}],
  acuity_scheduling: [AcuityScheduling, {}],
  full_address: [FormFullAddress, {}],
  checkbox: [FormChoiceGroup, { multiple: true }],
  radio: [FormChoiceGroup, { multiple: false }],
  full_date: [FormFullDate, {}],
  single_checkbox: [FormCheckbox, {}],
  spacer: [FormSpacer, {}],
  translate: [GoogleTranslate, {}],
  recaptcha: [ReCAPTCHA, {}],
  survey_matrix: [SurveyMatrix, {}],
  dynamic_list: [DynamicList, {}],
  canvas: [CanvasSketch, {}],
  emoticon: [Emoticon, {}],
  star_rating: [StarRating, {}],
  toggle: [FormToggle, {}],
  ng_checkboxes: [FormChoiceGroup, { multiple: true }],
  capture_user_session: [UserData, {}],
  unique_string: [UniqueString, {}],
  international_address: [InternationalAddress, {}],
  double_entry: [Entries, { fieldsCount: 2 }],
  triple_entry: [Entries, { fieldsCount: 3 }],
  embed_youtube: [EmbedVideo, { player: 'youtube' }],
  embed_vimeo: [EmbedVideo, { player: 'vimeo' }],
  check_and_text: [CheckAndText, {}],
  countdown: [CountdownDateTime, {}],
  currency_format: [CurrencyFormat, {}],
  html_box: [HtmlBox, {}],
  auto_complete: [AutoComplete, {}],
  address_autocomplete: [AddressAutocomplete, {}],
  query_google_sheets: [QueryGoogleSheets, {}],
  add_this: [AddThis, {}],
  add_to_calendar: [AddToCalendar, {}],
  add_this_event: [AddThisEvent, {}],
  google_maps: [GoogleMaps, {}],
  receipt: [Receipt, {}],
  id_verification: [IdVerification, {}],
  default: [RawComp, {}],
};

export const GOOGLE_TRANSLATE = 'translate';
export const COVER_LETTER_SHOWN_PREFIX = 'coverletter_shown_';
export const subContainerOverrideClass = 'form_subcontainer';

export const REQUIRED_MSG = 'This field is required.';

/* Components default prop values */

export const DYNAMIC_LIST_DEFAULT_VALUES = {
  label: 'Dynamic Lists',
  buttonLabel: 'Add a new input',
  inputLabel: '',
  inputPlaceholder: '',
  fieldLimit: '10',
  description: '',
  isLabelVisible: true,
  labelAlignment: 'auto',
};

export const CANVAS_SKETCH_DEFAULT_VALUES = {
  label: 'Canvas Sketch',
};

export const EMOTICON_DEFAULT_VALUES = {
  label: 'Emoticon',
};

export const EMOTICON_ITEMS = [
  {
    title: 'Very Sad',
    value: '1',
    iconCode: '\uD83D\uDE20',
  },
  {
    title: 'Sad',
    value: '2',
    iconCode: '\uD83D\uDE26',
  },
  {
    title: 'Indifferent',
    value: '3',
    iconCode: '\uD83D\uDE10',
  },
  {
    title: 'Happy',
    value: '4',
    iconCode: '\uD83D\uDE00',
  },
  {
    title: 'Very Happy',
    value: '5',
    iconCode: '\uD83D\uDE01',
  },
];

export const STAR_RATING_DEFAULT_VALUES = {
  label: 'Emoticon',
  numberOfStars: '5',
};

export const userDataNamesMap: { [fId: string]: string } = {
  ip: 'IP Address',
  referrer: 'Referrer URL',
  browserType: 'Browser Type',
  browserName: 'Browser Name',
  browserVersion: 'Browser Version',
  platformType: 'Platform Type',
  nameOs: 'OS Name',
  versionOs: 'OS Version',
};
